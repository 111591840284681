/* eslint-disable react/prop-types */

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Default Components
import { Checkbox } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormatDate from "../../_custom/functions/formatDate";

export default function data(
  jsonData,
  deleteJsonData,
  approveVersion,
  editVersion,
  viewDetails,
  canRevertLast,
  revertLast
) {
  const getRows = () => {
    const elements = [];

    for (let index = 0; index < jsonData.length; index += 1) {
      elements.push({
        number: jsonData[index].number,
        dateBuild: FormatDate(jsonData[index].dateBuild),
        approved: <Checkbox checked={jsonData[index].approved} disabled />,
        dateApproval: FormatDate(jsonData[index].dateApproval),
        isMandatory: <Checkbox checked={jsonData[index].isMandatory} disabled />,
        action: (
          <MDBox style={{ display: "flex", justifyContent: "space-between" }}>
            {jsonData[index].approved ? (
              <>
                {index === 0 && canRevertLast && (
                  <MDTypography color="text" onClick={() => revertLast(jsonData[index].number)}>
                    <Tooltip title="Revertir aprobación" disableInteractive>
                      <Icon>remove_done</Icon>
                    </Tooltip>
                  </MDTypography>
                )}
                <MDTypography color="text" onClick={() => viewDetails(jsonData[index])}>
                  <Tooltip title="Ver detalles" disableInteractive>
                    <Icon>description</Icon>
                  </Tooltip>
                </MDTypography>
                <MDTypography color="text" onClick={() => editVersion(jsonData[index])}>
                  <Tooltip title="Editar versión" disableInteractive>
                    <Icon>edit</Icon>
                  </Tooltip>
                </MDTypography>
              </>
            ) : (
              <MDTypography color="text" onClick={() => approveVersion(jsonData[index])}>
                <Tooltip title="Aprobar versión" disableInteractive>
                  <Icon>task_alt</Icon>
                </Tooltip>
              </MDTypography>
            )}

            <MDTypography color="text" onClick={() => deleteJsonData(jsonData[index].number)}>
              <Tooltip title="Eliminar Version" disableInteractive>
                <Icon>delete</Icon>
              </Tooltip>
            </MDTypography>
          </MDBox>
        ),
      });
    }

    return elements;
  };

  return {
    columns: [
      { Header: "Número", accessor: "number", align: "left" },
      { Header: "Fecha compilación", accessor: "dateBuild", align: "left" },
      { Header: "Aprobada", accessor: "approved", align: "left" },
      { Header: "Fecha aprobación", accessor: "dateApproval", align: "left" },
      { Header: "Mandatoria", accessor: "isMandatory", align: "left" },
      { Header: "Accion", accessor: "action", align: "left" },
    ],

    rows: getRows(),
  };
}

// Setting default values for the props of MDBadge
data.defaultProps = {
  jsonData: [],
};

// Typechecking props of the data
data.propTypes = {
  jsonData: PropTypes.objectOf(PropTypes.array),
  deleteJsonData: PropTypes.func.isRequired,
};
