// import US from "assets/images/icons/flags/usa-48.png";
// import CL from "assets/images/icons/flags/chile-48.png";

const data = [
  {
    date: "13/04/2023",
    estados: [
      {
        country: "Chile",
        activo: true,
      },
      {
        country: "Colombia",
        activo: false,
      },
      {
        country: "Costa rica",
        activo: false,
      },
      {
        country: "Perú",
        activo: true,
      },
      {
        country: "Uruguay",
        activo: true,
      },
      {
        country: "Panamá",
        activo: false,
      },
    ],
    documentos: [
      {
        country: "Chile",
        hora: "12 - 01 am",
        envios: 1000,
        aceptados: 800,
        rechazados: 200,
      },
      {
        country: "Colombia",
        hora: "01 - 02 am",
        envios: 1000,
        aceptados: 400,
        rechazados: 600,
      },
      {
        country: "Colombia",
        hora: "02 - 03 am",
        envios: 500,
        aceptados: 250,
        rechazados: 250,
      },
      {
        country: "Colombia",
        hora: "03 - 04 am",
        envios: 1000,
        aceptados: 1000,
        rechazados: 0,
      },
      {
        country: "Costa rica",
        hora: "04 - 05 am",
        envios: 2,
        aceptados: 0,
        rechazados: 2,
      },
      {
        country: "Costa rica",
        hora: "05 - 06 am",
        envios: 15,
        aceptados: 0,
        rechazados: 15,
      },
      {
        country: "Costa rica",
        hora: "06 - 07 am",
        envios: 1000,
        aceptados: 800,
        rechazados: 200,
      },
      {
        country: "Costa rica",
        hora: "007 - 08 am",
        envios: 1000,
        aceptados: 800,
        rechazados: 200,
      },
      {
        country: "Perú",
        hora: "08 - 09 am",
        envios: 1000,
        aceptados: 800,
        rechazados: 200,
      },
      {
        country: "Perú",
        hora: "09 - 10 am",
        envios: 1000,
        aceptados: 800,
        rechazados: 200,
      },
      {
        country: "Perú",
        hora: "10 - 11 am",
        envios: 1000,
        aceptados: 800,
        rechazados: 200,
      },
      {
        country: "Perú",
        hora: "11 - 12 am",
        envios: 1000,
        aceptados: 400,
        rechazados: 600,
      },
      {
        country: "Uruguay",
        hora: "12 - 01 pm",
        envios: 500,
        aceptados: 250,
        rechazados: 250,
      },
      {
        country: "Uruguay",
        hora: "01 - 02 pm",
        envios: 1000,
        aceptados: 1000,
        rechazados: 0,
      },
      {
        country: "Uruguay",
        hora: "02 - 03 pm",
        envios: 2,
        aceptados: 0,
        rechazados: 2,
      },
      {
        country: "Uruguay",
        hora: "03 - 04 pm",
        envios: 15,
        aceptados: 0,
        rechazados: 15,
      },
      {
        country: "Panamá",
        hora: "04 - 05 pm",
        envios: 1000,
        aceptados: 800,
        rechazados: 200,
      },
      {
        country: "Panamá",
        hora: "05 - 06 pm",
        envios: 1000,
        aceptados: 800,
        rechazados: 200,
      },
      {
        country: "Panamá",
        hora: "06 - 07 pm",
        envios: 1000,
        aceptados: 800,
        rechazados: 200,
      },
      {
        country: "Panamá",
        hora: "07 - 08 pm",
        envios: 1000,
        aceptados: 800,
        rechazados: 200,
      },
      {
        country: "Chile",
        hora: "08 - 09 pm",
        envios: 15,
        aceptados: 0,
        rechazados: 15,
      },
      {
        country: "Chile",
        hora: "09 - 10 pm",
        envios: 1000,
        aceptados: 800,
        rechazados: 200,
      },
      {
        country: "Chile",
        hora: "10 - 11 pm",
        envios: 1000,
        aceptados: 800,
        rechazados: 200,
      },
      {
        country: "Chile",
        hora: "11 - 12 pm",
        envios: 1000,
        aceptados: 800,
        rechazados: 200,
      },
    ],
  },
];

export default data;
