/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// Default Components
import MDTypography from "components/MDTypography";

export default function data(jsonData, deleteJsonData, setEditJsonData) {
  const getRows = () => {
    const elements = [];

    for (let index = 0; index < jsonData.length; index += 1) {
      elements.push({
        pais: jsonData[index].pais.nombre,
        taxId: jsonData[index].taxId,
        syncPoint: jsonData[index].syncPoint.nombre,
        xslt: jsonData[index].xslt,
        usuario: jsonData[index].usuario,
        fechaRegistro: jsonData[index].fechaRegistro,
        action: (
          <MDTypography color="text">
            <Tooltip
              title="Deshabilitar Registro"
              disableInteractive
              onClick={() => deleteJsonData(jsonData[index].id)}
            >
              <Icon>delete</Icon>
            </Tooltip>{" "}
            /{" "}
            <Tooltip
              title="Editar Registro"
              disableInteractive
              onClick={() => {
                setEditJsonData(jsonData[index].id);
              }}
            >
              <Icon>edit</Icon>
            </Tooltip>
          </MDTypography>
        ),
      });
    }

    return elements;
  };

  return {
    columns: [
      { Header: "Pais", accessor: "pais", align: "center" },
      { Header: "Tax Id", accessor: "taxId", align: "center" },
      { Header: "API Key", accessor: "syncPoint", align: "center" },
      { Header: "XSLT", accessor: "xslt", align: "center" },
      { Header: "Usuario", accessor: "usuario", align: "center" },
      { Header: "Fecha Registro", accessor: "fechaRegistro", align: "center" },
      { Header: "Accion", accessor: "action", align: "center" },
    ],

    rows: getRows(),
  };
}

// Setting default values for the props of MDBadge
data.defaultProps = {
  jsonData: [],
};

// Typechecking props of the data
data.propTypes = {
  jsonData: PropTypes.objectOf(PropTypes.array),
  deleteJsonData: PropTypes.func.isRequired,
  setEditJsonData: PropTypes.func.isRequired,
};
