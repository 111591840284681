/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
// @mui material components
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
// @mui material components
import MDTypography from "components/MDTypography";

export default function data(jsonData, setEditEstado, deleteJsonData) {
  const getRows = () => {
    const elements = [];

    for (let index = 0; index < jsonData.length; index += 1) {
      elements.push({
        // pais: jsonData[index].pais.nombre,
        taxId: jsonData[index].taxId,
        estado: jsonData[index].estado,
        usuario: jsonData[index].usuario,
        action_item: (
          <MDTypography color="text">
            {jsonData[index].estado === "Baja" ? (
              <Tooltip
                title="Habilitar"
                disableInteractive
                onClick={() => {
                  setEditEstado(jsonData[index].taxId);
                }}
              >
                <Icon>toggle_off</Icon>
              </Tooltip>
            ) : (
              <Tooltip
                title="Desabilitar"
                disableInteractive
                onClick={() => {
                  setEditEstado(jsonData[index].taxId);
                }}
              >
                <Icon>toggle_on</Icon>
              </Tooltip>
            )}{" "}
            /{" "}
            <Tooltip
              title="Eliminar Registro"
              disableInteractive
              onClick={() => {
                deleteJsonData(jsonData[index].taxId);
              }}
            >
              <Icon>delete</Icon>
            </Tooltip>
          </MDTypography>
        ),
        fechaUpdate: jsonData[index].fechaUpdate,
      });
    }

    return elements;
  };

  return {
    columns: [
      // { Header: "Pais", accessor: "pais", align: "center" },
      { Header: "Tax Id", accessor: "taxId", align: "center" },
      { Header: "Estado", accessor: "estado", align: "center" },
      { Header: "Usuario", accessor: "usuario", align: "center" },
      { Header: "Accion", accessor: "action_item", align: "center" },
      { Header: "Fecha Actualización", accessor: "fechaUpdate", align: "center" },
    ],

    rows: getRows(),
  };
}

// Setting default values for the props of MDBadge
data.defaultProps = {
  jsonData: [],
};

// Typechecking props of the data
data.propTypes = {
  jsonData: PropTypes.objectOf(PropTypes.array),
  setEditEstado: PropTypes.func.isRequired,
  deleteJsonData: PropTypes.func.isRequired,
};
