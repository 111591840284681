import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import Loading from "components/Loading";
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import BKShowModal from "components/BKShowModal";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DataTable from "examples/Tables/DataTable";

// Data
import dataColumns from "views/manager/Gadget/data";

// API Request
import APIRequest from "api/request";

function Gadget() {
  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  const [load, setLoad] = useState(false);
  const [modalDetail, setModalDetail] = useState(false);
  const [jsonData, setJsonData] = useState([]);
  const { pathname } = useLocation();
  const { state } = useLocation();
  const route = pathname;
  const navigate = useNavigate();

  const [newValuePais, setNewValuePais] = useState(null);
  const [newValueAlias, setNewValueAlias] = useState("");
  const [newValueDescription, setNewValueDescription] = useState("");
  const [newValueName, setNewValueName] = useState("");
  const [newValuePassword, setNewValuePassword] = useState("");
  const [newValueType, setNewValueType] = useState(null);
  const [newValueUrl, setNewValueUrl] = useState("");
  const [newValueActive, setNewValueActive] = useState(false);

  const [selectData, setSelectData] = useState({
    pais: [
      { id: "cr", nombre: "Costa rica" },
      { id: "ec", nombre: "Ecuador" },
      { id: "pe", nombre: "Perú" },
      { id: "cl", nombre: "Chile" },
      { id: "mx", nombre: "México" },
      { id: "gt", nombre: "Guatemala" },
      { id: "co", nombre: "Colombia" },
      { id: "pa", nombre: "Panamá" },
    ],
    type: [
      { id: 0, nombre: "Core" },
      { id: 1, nombre: "Gadget" },
    ],
  });

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };
  const showNotificationError = (message) => {
    configNotification("error", "warning", "Notificación", message, "", true);
  };
  const showNotificationSuccess = (message) => {
    configNotification("warning", "check", "Notificación", message, "", true);
  };
  const closeModalDetail = () => {
    setModalDetail(false);
    setNewValuePais(null);
    setNewValueAlias("");
    setNewValueDescription("");
    setNewValueName("");
    setNewValuePassword("");
    setNewValueType(null);
    setNewValueUrl("");
    setNewValueActive(false);
  };

  const setViewModalDetail = async (jsonElement) => {
    setLoad(true);
    try {
      setNewValuePais(jsonElement.country);
      setNewValueAlias(jsonElement.alias);
      setNewValueDescription(jsonElement.description);
      setNewValueName(jsonElement.name);
      setNewValuePassword(jsonElement.password);
      setNewValueType(jsonElement.type);
      setNewValueUrl(jsonElement.url);
      setNewValueActive(jsonElement.active);

      setModalDetail(true);
      showNotificationSuccess("Se encontró el detalle del gadget");
    } catch {
      showNotificationError("Error Inesperado");
    }
    setLoad(false);
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );
  const renderModalDetail = (
    <BKShowModal closeModal={closeModalDetail} open={modalDetail} title="Detalle del Gadget">
      <Card>
        <MDBox sx={{ position: "relative", mx: 3, py: 2, px: 2 }}>
          <Grid container spacing={3} justifyContent="left" alignItems="left" height="100%">
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
              <Autocomplete
                value={newValuePais}
                onChange={(event, newValue) => {
                  setNewValuePais(newValue);
                }}
                options={selectData.pais}
                getOptionLabel={(option) => option.nombre}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                disabled
                renderInput={(params) => (
                  <MDInput
                    variant="standard"
                    type="text"
                    label="Pais"
                    fullWidth
                    required
                    InputLabelProps={{ shrink: true }}
                    {...params}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
              <MDInput
                variant="standard"
                type="text"
                label="Alias"
                fullWidth
                required
                disabled
                InputLabelProps={{ shrink: true }}
                value={newValueAlias}
                onChange={(e) => {
                  setNewValueAlias(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
              <MDInput
                variant="standard"
                type="text"
                label="Nombre"
                fullWidth
                required
                disabled
                InputLabelProps={{ shrink: true }}
                value={newValueName}
                onChange={(e) => {
                  setNewValueName(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
              <MDInput
                variant="standard"
                type="text"
                label="Descripción"
                fullWidth
                required
                disabled
                InputLabelProps={{ shrink: true }}
                value={newValueDescription}
                onChange={(e) => {
                  setNewValueDescription(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
              <MDInput
                variant="standard"
                type="password"
                label="Contraseña"
                fullWidth
                required
                disabled
                InputLabelProps={{ shrink: true }}
                value={newValuePassword}
                onChange={(e) => {
                  setNewValuePassword(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
              <Autocomplete
                value={newValueType}
                onChange={(event, newValue) => {
                  setNewValueType(newValue);
                }}
                options={selectData.type}
                getOptionLabel={(option) => option.nombre}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                disabled
                renderInput={(params) => (
                  <MDInput
                    variant="standard"
                    type="text"
                    label="Tipo"
                    fullWidth
                    required
                    InputLabelProps={{ shrink: true }}
                    {...params}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
              <MDInput
                variant="standard"
                type="text"
                label="Url"
                fullWidth
                required
                disabled
                InputLabelProps={{ shrink: true }}
                value={newValueUrl}
                onChange={(e) => {
                  setNewValueUrl(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
              <Tooltip title={newValueActive ? "Deshabilitar" : "Habilitar"} disableInteractive>
                <Switch
                  disabled
                  checked={newValueActive}
                  onChange={() => setNewValueActive(!newValueActive)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </Tooltip>
            </Grid>
          </Grid>
        </MDBox>
      </Card>
    </BKShowModal>
  );

  const getJsonData = async (countries) => {
    setLoad(true);
    try {
      const apiResponse = await (await APIRequest("GET", "/api/Manager/Gadget/Todos", null)).json();

      const tempJsonData = [];
      if (apiResponse.length > 0) {
        for (let index = 0; index < apiResponse.length; index += 1) {
          if (apiResponse[index].countryId) {
            tempJsonData.push({
              applicationId: apiResponse[index].applicationId,
              active: apiResponse[index].active,
              alias: apiResponse[index].alias,
              country:
                countries[
                  countries
                    .map((item) => item.id)
                    .indexOf(apiResponse[index].countryId.toLowerCase())
                ],
              description: apiResponse[index].description,
              name: apiResponse[index].name,
              password: apiResponse[index].password,
              type: selectData.type[
                selectData.type.map((item) => item.id).indexOf(apiResponse[index].type)
              ],
              url: apiResponse[index].url,
            });
          }
        }

        setJsonData([...tempJsonData]);
      }
    } catch (error) {
      showNotificationError(error.data);
    }
    setLoad(false);
  };
  const setEditJsonData = async (element) => {
    sessionStorage.setItem("jsonDataManaGadget", JSON.stringify(jsonData));
    navigate("/Manager/Gadget/Form", { state: { route, element } });
  };
  const deleteJsonData = async (jsonElement) => {
    setLoad(true);
    try {
      const HttpUrl = `/api/Manager/Gadget?ApplicationId=${jsonElement.applicationId}`;
      const apiResponse = await APIRequest("DELETE", HttpUrl, null);

      if (apiResponse.status === 200 || apiResponse.status === 201 || apiResponse.ok) {
        showNotificationSuccess("Se eliminó el gadget exitosamente");
      } else {
        showNotificationError(`Error, estado: ${apiResponse.status}`);
      }
    } catch {
      showNotificationError("Error Inesperado");
    }

    setLoad(false);
  };
  const getCountriesData = async () => {
    const tempJsonData = [];
    const apiResponse = await (await APIRequest("GET", "/api/Pais/Todos", null)).json();

    if (apiResponse.length > 0) {
      for (let index = 0; index < apiResponse.length; index += 1) {
        tempJsonData.push({
          id: apiResponse[index].codigo.toLowerCase(),
          nombre: apiResponse[index].nombre,
        });
      }

      setSelectData({
        pais: tempJsonData,
        type: selectData.type,
      });
    }

    return tempJsonData;
  };
  const getElementChanges = async (countries) => {
    if (
      sessionStorage.getItem("jsonDataManaGadget") !== null &&
      sessionStorage.getItem("jsonDataManaGadget") !== undefined &&
      sessionStorage.getItem("jsonDataManaGadget") !== "null"
    ) {
      const jsonDataXml = JSON.parse(sessionStorage.getItem("jsonDataManaGadget"));
      sessionStorage.setItem("jsonDataManaGadget", null);

      if (state) {
        if (state.newElement) {
          setJsonData([state.newElement, ...jsonDataXml]);
        }
      } else if (jsonDataXml) {
        setJsonData([...jsonDataXml]);
      }
    } else {
      getJsonData(countries);
    }
  };

  useEffect(async () => {
    setLoad(true);
    const countries = await getCountriesData();
    await getElementChanges(countries);
    setLoad(false);
  }, []);

  const { columns, rows } = dataColumns(
    jsonData,
    setViewModalDetail,
    setEditJsonData,
    deleteJsonData
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={3}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <MDBox display="flex" justifyContent="space-between" alignItems="center">
              <MDBox>
                <MDTypography variant="subtitle" fontWeight="bold">
                  Administración Aplicaciones
                </MDTypography>
              </MDBox>
              <MDBox color="text" px={2}>
                <MDButton
                  variant="contained"
                  onClick={() => {
                    sessionStorage.setItem("jsonDataManaGadget", JSON.stringify(jsonData));
                    navigate("/Manager/Gadget/Form", { state: { route } });
                  }}
                  sx={{
                    background: "linear-gradient(45deg, #1971C2 30%, #1971C2 90%)",
                    color: "#FFFFFF",
                  }}
                >
                  <Icon>add_circle_outline</Icon>&nbsp;Nueva aplicación
                </MDButton>
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox>
                <DataTable
                  entriesPerPage={(false, { defaultValue: 10, entries: [5, 10, 25, 50] })}
                  canSearch
                  showTotalEntries
                  table={{ columns, rows }}
                  pagination={{ variant: "contained", color: "warning" }}
                  isSorted
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {renderNotificacion}
      {renderModalDetail}
      <Loading load={load} />
      <Footer />
    </DashboardLayout>
  );
}

export default Gadget;
