/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// Default Components
import MDTypography from "components/MDTypography";

export default function data(jsonData, setViewModalContacts, AccountId, CountryId) {
  const getRows = () => {
    const elements = [];

    for (let index = 0; index < jsonData.length; index += 1) {
      elements.push({
        rut: jsonData[index].rut,
        razon: jsonData[index].razon,
        action: (
          <MDTypography color="text">
            <Tooltip
              title="Ver Contactos"
              disableInteractive
              onClick={() => setViewModalContacts(AccountId, jsonData[index].id, CountryId, true)}
            >
              <Icon>call</Icon>
            </Tooltip>
          </MDTypography>
        ),
      });
    }

    return elements;
  };

  return {
    columnsClient: [
      { Header: "RUT de Cliente", accessor: "rut", align: "center" },
      { Header: "Razón social", accessor: "razon", align: "center" },
      { Header: "", accessor: "action", align: "center" },
    ],

    rowsClient: getRows(),
  };
}

// Setting default values for the props of MDBadge
data.defaultProps = {
  jsonData: [],
};

// Typechecking props of the data
data.propTypes = {
  jsonData: PropTypes.objectOf(PropTypes.array),
  setViewModalContacts: PropTypes.func.isRequired,
  AccountId: PropTypes.string.isRequired,
  CountryId: PropTypes.string.isRequired,
};
