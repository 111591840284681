/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// @mui material components
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import BKModal from "components/BKModal";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import data from "./data";

// API Request
// import APIRequest from "api/request";

function Estadisticas() {
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  const [menu, setMenu] = useState(null);
  const [modal, setModal] = useState(false);
  const [jsonData, setJsonData] = useState([]);
  const { pathname } = useLocation();

  const [elementIsNew, setElementIsNew] = useState(true);
  const [newValueId, setNewValueId] = useState("");
  const [newValuePais, setNewValuePais] = useState(null);
  const [newValueTaxId, setNewValueTaxId] = useState("");
  const [newValueAPIKey, setNewValueAPIKey] = useState(null);
  const [newValueXSLT, setNewValueXSLT] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [newValueFiles, setNewValueFiles] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [selectData, setSelectData] = useState({
    pais: [
      { id: 1, nombre: "Costa rica" },
      { id: 2, nombre: "Ecuador" },
      { id: 3, nombre: "Perú" },
      { id: 4, nombre: "Chile" },
      { id: 5, nombre: "México" },
      { id: 6, nombre: "Guatemala" },
      { id: 7, nombre: "Colombia" },
      { id: 8, nombre: "Panamá" },
    ],
    apiKey: [
      { id: 1, nombre: "Valor 1" },
      { id: 2, nombre: "Valor 2" },
    ],
  });

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };

  const showNotificationError = (message) => {
    configNotification("error", "warning", "Notificación", message, "", true);
  };

  const showNotificationSucces = (message) => {
    configNotification("warning", "check", "Notificación", message, "", true);
  };

  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);
  const openAddNewElementModal = () => {
    setElementIsNew(true);
    setMenu(null);
    setModal(true);
  };
  const closeModal = () => {
    setModal(false);
    setNewValueId("");
    setNewValuePais(null);
    setNewValueTaxId("");
    setNewValueAPIKey(null);
    setNewValueXSLT("");
    setNewValueFiles([]);
  };
  const saveDataModal = async () => {
    const tempJsonData = jsonData;
    const today = new Date();

    const newTempElement = {
      id: newValueId,
      pais: newValuePais,
      taxId: newValueTaxId,
      syncPoint: newValueAPIKey,
      xslt: newValueXSLT,
      usuario: userInfo.correo,
      fechaRegistro: `${today.getFullYear()}-${(today.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${today.getDate().toString().padStart(2, "0")}`,
    };

    if (elementIsNew) {
      tempJsonData.push(newTempElement);
    } else {
      const index = tempJsonData.map((item) => item.id).indexOf(newValueId);
      tempJsonData[index] = newTempElement;
    }

    setJsonData(tempJsonData);
    closeModal();
    showNotificationSucces("Se completó el registro exitosamente");

    /* const body = {
      nombre: newValueName,
      tipoPerfilId: newValueType.id,
      areaId: newValueArea.id,
    };
    let apiResponse = null;

    try {
      if (elementIsNew) {
        apiResponse = await APIRequest("POST", "/Api/Perfil", body);
      } else {
        apiResponse = await APIRequest("PATCH", `/Api/Perfil/${newValueId}`, body);
      }

      if (apiResponse.status === 200) {
        let dataResponse = null;

        if (elementIsNew) {
          dataResponse = await apiResponse.json();
          setNewValueId(dataResponse);
        }

        const newTempElement = {
          id: elementIsNew ? dataResponse : newValueId,
          nombre: newValueName,
          tipoPerfil: newValueType,
          area: newValueArea,
        };

        if (elementIsNew) {
          if (dataResponse !== 0) {
            tempJsonData.push(newTempElement);

            configNotification(
              "warning",
              "check",
              "Notificación",
              `Se creó el perfil '${newValueName}' exitosamente.`,
              "",
              true
            );
          } else {
            const errorMessage = {
              code: apiResponse.status,
              message: "No se creó el perfil, ya hay 5 perfiles administradores.",
            };
            throw errorMessage;
          }
        } else {
          const index = tempJsonData.map((item) => item.id).indexOf(newValueId);
          tempJsonData[index] = newTempElement;

          configNotification(
            "warning",
            "check",
            "Notificación",
            `Se actualizó el perfil '${newValueName}' exitosamente.`,
            "",
            true
          );
        }
      } else {
        const errorMessage = {
          code: apiResponse.status,
          message: `Error: ${apiResponse.status}`,
        };
        throw errorMessage;
      }

      setJsonData(tempJsonData);
    } catch (e) {
      configNotification(
        "error",
        "warning",
        "Notificación",
        `Ocurrió un error en el perfil: ${e.message}`,
        "",
        true
      );
    } */

    // closeModal();
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={openAddNewElementModal}>Agregar Valor</MenuItem>
    </Menu>
  );

  const renderModal = (
    <BKModal
      closeModal={closeModal}
      saveChanges={saveDataModal}
      open={modal}
      title={elementIsNew ? "Agregar RUT" : "Editar RUT"}
    >
      <MDBox sx={{ position: "relative", mx: 3, py: 2, px: 2 }}>
        <Grid container spacing={3} justifyContent="left" alignItems="left" height="100%">
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <MDInput
              variant="standard"
              type="text"
              label="ID"
              fullWidth
              required
              disabled={!elementIsNew}
              InputLabelProps={{ shrink: true }}
              value={newValueId}
              onChange={(e) => {
                setNewValueId(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <Autocomplete
              value={newValuePais}
              onChange={(event, newValue) => {
                setNewValuePais(newValue);
              }}
              options={selectData.pais}
              getOptionLabel={(option) => option.nombre}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <MDInput
                  variant="standard"
                  type="text"
                  label="Pais"
                  fullWidth
                  required
                  InputLabelProps={{ shrink: true }}
                  {...params}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <MDInput
              variant="standard"
              type="text"
              label="Tax ID"
              fullWidth
              required
              InputLabelProps={{ shrink: true }}
              value={newValueTaxId}
              onChange={(e) => {
                setNewValueTaxId(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <Autocomplete
              value={newValueAPIKey}
              onChange={(event, newValue) => {
                setNewValueAPIKey(newValue);
              }}
              options={selectData.apiKey}
              getOptionLabel={(option) => option.nombre}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <MDInput
                  variant="standard"
                  type="text"
                  label="API Key"
                  fullWidth
                  required
                  InputLabelProps={{ shrink: true }}
                  {...params}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <MDInput
              id="file-input"
              variant="standard"
              type="file"
              label="Seleccionar Archivo"
              sx={{ display: "none" }}
              fullWidth
              InputLabelProps={{ shrink: true }}
              inputProps={{ accept: ".xslt" }}
              onChange={(e) => {
                setNewValueFiles(e.target.files);
                setNewValueXSLT(e.target.files[0].name);
              }}
            />
            <MDButton
              variant="outlined"
              autoFocus
              color="warning"
              onClick={() => document.getElementById("labelFile").click()}
            >
              <label id="labelFile" htmlFor="file-input" style={{ display: "none" }} />
              Adjuntar Archivo <Icon>upload</Icon>
            </MDButton>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <MDInput
              variant="standard"
              type="text"
              label="XSLT"
              fullWidth
              required
              disabled
              InputLabelProps={{ shrink: true }}
              value={newValueXSLT}
              onChange={(e) => {
                setNewValueXSLT(e.target.value);
              }}
            />
          </Grid>
        </Grid>
      </MDBox>
    </BKModal>
  );

  const getJsonData = async () => {
    /* const tempJsonData = [];
    const perfilesApi = await (await APIRequest("GET", "/Api/Perfil/Todos", null)).json();

    for (let index = 0; index < perfilesApi.length; index += 1) {
      tempJsonData.push({
        id: perfilesApi[index].id,
        nombre: perfilesApi[index].nombre,
        tipoPerfil: {
          id: perfilesApi[index].tipoPerfil.id,
          nombre: perfilesApi[index].tipoPerfil.nombre,
        },
        area: {
          id: perfilesApi[index].area.id,
          nombre: perfilesApi[index].area.nombre,
        },
      });
    } */

    const tempJsonData = [
      {
        id: 1,
        pais: { id: 1, nombre: "Costa Rica" },
        taxId: "NNN",
        syncPoint: { id: 1, nombre: "Valor 1" },
        xslt: "archivo",
        usuario: "a@gosocket.net",
        fechaRegistro: "2023-04-01",
      },
      {
        id: 2,
        pais: { id: 2, nombre: "Ecuador" },
        taxId: "NNN",
        syncPoint: { id: 2, nombre: "Valor 2" },
        xslt: "archivo",
        usuario: "b@gosocket.net",
        fechaRegistro: "2023-04-01",
      },
    ];

    setJsonData(tempJsonData);
  };

  const deleteJsonData = async (id) => {
    if (window.confirm("¿Quiere eliminar el registro?")) {
      try {
        const index = jsonData.map((item) => item.id).indexOf(id);
        const elements = [...jsonData];
        const nombreElemento = elements[index].xslt;
        elements.splice(index, 1);
        setJsonData(elements);

        showNotificationError(`Se eliminó el registro '${nombreElemento}' exitosamente.`);

        /* const apiResponse = await APIRequest("DELETE", `/Api/Perfil/${id}`, null);

        if (apiResponse.status === 200) {
          const index = jsonData.map((item) => item.id).indexOf(id);
          const elements = [...jsonData];
          const nombrePerfil = elements[index].nombre;
          elements.splice(index, 1);
          setJsonData(elements);
          configNotification(
            "warning",
            "check",
            "Notificación",
            `Se eliminó el perfil '${nombrePerfil}' exitosamente.`,
            "",
            true
          );
        } else {
          const errorMessage = {
            code: apiResponse.status,
            message: `Error: ${apiResponse.status}`,
          };
          throw errorMessage;
        } */
      } catch (e) {
        configNotification(
          "error",
          "warning",
          "Notificación",
          `Ocurrió un error al deshabilitar el RUT: ${e.message}`,
          "",
          true
        );
      }
    }
  };

  const setEditJsonData = (valueId) => {
    setElementIsNew(false);
    setNewValueId(valueId);

    const index = jsonData.map((item) => item.id).indexOf(valueId);
    setNewValueId(jsonData[index].id);
    setNewValuePais(jsonData[index].pais);
    setNewValueTaxId(jsonData[index].taxId);
    setNewValueAPIKey(jsonData[index].syncPoint);
    setNewValueXSLT(jsonData[index].xslt);
    // setNewValueFiles([]);

    setModal(true);
  };

  useEffect(() => {
    getJsonData();
  }, [pathname]);

  const { columns, rows } = data(jsonData, deleteJsonData, setEditJsonData);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {renderModal}
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                <MDBox>
                  <MDTypography variant="h4" gutterBottom color="warning">
                    Cargar XSLT que será utilizado por xDoc Cloud
                  </MDTypography>
                </MDBox>
                <MDBox color="text" px={2}>
                  <Icon
                    sx={{ cursor: "pointer", fontWeight: "bold" }}
                    fontSize="small"
                    onClick={openMenu}
                  >
                    more_vert
                  </Icon>
                </MDBox>
                {renderMenu}
              </MDBox>
              <MDBox>
                <DataTable
                  entriesPerPage={(false, { defaultValue: 10, entries: [5, 10, 25, 50] })}
                  canSearch
                  showTotalEntries
                  table={{ columns, rows }}
                  pagination={{ variant: "contained", color: "warning" }}
                  isSorted
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {renderNotificacion}
      <Footer />
    </DashboardLayout>
  );
}

export default Estadisticas;
