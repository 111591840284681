/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Loading from "components/Loading";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Format
import TimeMaskCustom from "views/varios/ComunicacionPAC/format";

// API Request
import APIRequest from "api/request";
import APIRequestGeneric from "api/requestGeneric";

function ConfiguracionPacForm() {
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  const host = process.env.REACT_APP_API_URL;
  const apiKeyAdmin = process.env.REACT_APP_API_KEY_PAC_ADMIN;
  const idFuncionalidad = 18;

  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  const [load, setLoad] = useState(false);
  // const [jsonData, setJsonData] = useState([]);
  const { pathname } = useLocation();
  const {
    state: { route, element },
  } = useLocation();
  const navigate = useNavigate();
  const [enqueueMax, setEnqueueMax] = useState(0);
  const [enqueueTime, setEnqueueTime] = useState("00:00:00");
  const feConsFE = "feConsFE";
  const [feConsFEEndPoint, setFeConsFEEndPoint] = useState("");
  const [feConsFETime, setFeConsFETime] = useState("00:00:00");
  const feRecepFE = "feRecepFE";
  const [feRecepFEEndPoint, setFeRecepFEEndPoint] = useState("");
  const [feRecepFETime, setFeRecepFETime] = useState("00:00:00");
  const feRecepEventoFE = "feRecepEventoFE";
  const [feRecepEventEndPoint, setFeRecepEventEndPoint] = useState("");
  const [feRecepEventTime, setFeRecepEventTime] = useState("00:00:00");
  const [enqueueMaxError, setEnqueueMaxError] = useState(false);
  const [enqueueTimeError, setEnqueueTimeError] = useState(false);
  const [feConsFEEndPointError, setFeConsFEEndPointError] = useState(false);
  const [feConsFETimeError, setFeConsFETimeError] = useState(false);
  const [feRecepFEEndPointError, setFeRecepFEEndPointError] = useState(false);
  const [feRecepFETimeError, setFeRecepFETimeError] = useState(false);
  const [feRecepEventEndPointError, setFeRecepEventEndPointError] = useState(false);
  const [feRecepEventTimeError, setFeRecepEventTimeError] = useState(false);
  const [enqueueTimeMessage, setEnqueueTimeMessage] = useState("");
  const [feConsFETimeMessage, setFeConsFETimeMessage] = useState("");
  const [feRecepFETimeMessage, setFeRecepFETimeMessage] = useState("");
  const [feRecepEventTimeMessage, setFeRecepEventTimeMessage] = useState("");

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };
  const showNotificationError = (message) => {
    configNotification("error", "warning", "Notificación", message, "", true);
  };
  const showNotificationSuccess = (message) => {
    configNotification("warning", "check", "Notificación", message, "", true);
  };
  const registerUser = async (vLabel, vValue, vAccion) => {
    const bodyUsuario = {
      Usuario: userInfo.correo,
      FuncionalidadId: idFuncionalidad,
      Label: vLabel,
      Value: vValue,
      Accion: vAccion,
      Data: "{}",
    };

    await (await APIRequest("POST", "/Api/Historial", bodyUsuario)).json();
  };
  const rangoTimeMax = (valor) => {
    const time = valor.split(":");

    if (parseInt(time[0] + time[1] + time[2], 10) > 10000) {
      return true;
    }

    return false;
  };
  const rangoTimeMin = (valor) => {
    const time = valor.split(":");

    if (parseInt(time[0] + time[1] + time[2], 10) < 10) {
      return true;
    }

    return false;
  };
  const validatedData = () => {
    let withOutError = true;
    setEnqueueMaxError(false);
    setEnqueueTimeError(false);
    setFeConsFEEndPointError(false);
    setFeConsFETimeError(false);
    setFeRecepEventEndPointError(false);
    setFeRecepEventTimeError(false);
    setFeRecepFEEndPointError(false);
    setFeRecepFETimeError(false);
    setEnqueueTimeMessage("");
    setFeConsFETimeMessage("");
    setFeRecepEventTimeMessage("");
    setFeRecepFETimeMessage("");
    if (parseInt(enqueueMax, 10) === 0) {
      withOutError = false;
      setEnqueueMaxError(true);
    }
    if (enqueueTime.length < 8) {
      withOutError = false;
      setEnqueueTimeMessage("Formato Incorrecto");
      setEnqueueTimeError(true);
    }
    if (rangoTimeMin(enqueueTime) || rangoTimeMax(enqueueTime)) {
      withOutError = false;
      setEnqueueTimeMessage("Valor debe estar entre 10s y 1h ");
      setEnqueueTimeError(true);
    }
    if (feConsFEEndPoint === "" || feConsFEEndPoint === null) {
      withOutError = false;
      setFeConsFEEndPointError(true);
    }
    if (feConsFETime.length < 8) {
      withOutError = false;
      setFeConsFETimeMessage("Formato Incorrecto");
      setFeConsFETimeError(true);
    }
    if (rangoTimeMin(feConsFETime) || rangoTimeMin(feConsFETime)) {
      withOutError = false;
      setFeConsFETimeMessage("Valor debe estar entre 10s y 1h ");
      setFeConsFETimeError(true);
    }
    if (feRecepEventEndPoint === "" || feRecepEventEndPoint === null) {
      withOutError = false;
      setFeRecepEventEndPointError(true);
    }
    if (feRecepEventTime.length < 8) {
      withOutError = false;
      setFeRecepEventTimeMessage("Formato Incorrecto");
      setFeRecepEventTimeError(true);
    }
    if (rangoTimeMin(feRecepEventTime) || rangoTimeMax(feRecepEventTime)) {
      withOutError = false;
      setFeRecepEventTimeMessage("Valor debe estar entre 10s y 1h ");
      setFeRecepEventTimeError(true);
    }
    if (feRecepFEEndPoint === "" || feRecepFEEndPoint === null) {
      withOutError = false;
      setFeRecepFEEndPointError(true);
    }
    if (feRecepFETime.length < 8) {
      withOutError = false;
      setFeRecepFETimeMessage("Formato Incorrecto");
      setFeRecepFETimeError(true);
    }
    if (rangoTimeMin(feRecepFETime) || rangoTimeMax(feRecepFETime)) {
      withOutError = false;
      setFeRecepFETimeMessage("Valor debe estar entre 10s y 1h ");
      setFeRecepFETimeError(true);
    }
    return withOutError;
  };
  const saveDataModal = async () => {
    setLoad(true);
    if (validatedData()) {
      const urlComplete = `${host}/api/settings/integration/dgi?code=${apiKeyAdmin}`;

      const body = {
        enqueueMaxAttempts: enqueueMax,
        enqueueMessageVisibilityDelayTime: enqueueTime,
        taxAgencyService: {
          feConsFE: {
            endpointAddress: feConsFEEndPoint,
            timeout: feConsFETime,
          },
          feRecepFE: {
            endpointAddress: feRecepFEEndPoint,
            timeout: feRecepFETime,
          },
          feRecepEventoFE: {
            endpointAddress: feRecepEventEndPoint,
            timeout: feRecepEventTime,
          },
        },
        taxAgencyScheduleOffline: element ? element.taxAgencyScheduleOffline : null,
      };

      try {
        const apiResponse = await APIRequestGeneric("POST", urlComplete, body, true, null);
        if (apiResponse.status === 200 && apiResponse.ok) {
          registerUser("", "", 2);
          showNotificationSuccess("Se completó el registro exitosamente");
        } else {
          showNotificationError(`Error: ${apiResponse.Data}`);
        }
      } catch {
        showNotificationError("Ocurrió un error inesperado.");
      }
    }
    setLoad(false);
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );

  const setEditJsonData = (jsonElement) => {
    if (element) {
      setEnqueueMax(jsonElement.enqueueMaxAttempts);
      setEnqueueTime(jsonElement.enqueueMessageVisibilityDelayTime);
      setFeConsFEEndPoint(jsonElement.taxAgencyService.feConsFE.endpointAddress);
      setFeConsFETime(jsonElement.taxAgencyService.feConsFE.timeout);
      setFeRecepFEEndPoint(jsonElement.taxAgencyService.feRecepFE.endpointAddress);
      setFeRecepFETime(jsonElement.taxAgencyService.feRecepFE.timeout);
      setFeRecepEventEndPoint(jsonElement.taxAgencyService.feRecepEventoFE.endpointAddress);
      setFeRecepEventTime(jsonElement.taxAgencyService.feRecepEventoFE.timeout);
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    saveDataModal();
  };
  const resetValidator = async () => {
    const urlComplete = `${host}/management/cache/restart`;
    try {
      const apiResponse = await APIRequestGeneric("PATCH", urlComplete, null, false, null);

      if (apiResponse.status === 200 && apiResponse.ok) {
        showNotificationSuccess("Se completó la acción exitosamente");
      } else {
        showNotificationError(`Error: ${apiResponse.Data}`);
      }
    } catch {
      showNotificationError("Ocurrió un error inesperado.");
    }
  };
  const resetPlugin = async () => {
    const apiKey = process.env.REACT_APP_API_KEY_PAC_PLUGINS;
    const urlComplete = `${host}/api/management/cache/restart?code=${apiKey}`;
    try {
      const apiResponse = await APIRequestGeneric("PATCH", urlComplete, null, true, null);

      if (apiResponse.status === 200 && apiResponse.ok) {
        showNotificationSuccess("Se completó la acción exitosamente");
      } else {
        showNotificationError(`Error: ${apiResponse.Data}`);
      }
    } catch {
      showNotificationError("Ocurrió un error inesperado.");
    }
  };

  useEffect(async () => {
    try {
      setLoad(true);
      setEditJsonData(element);
      // eslint-disable-next-line no-empty
    } catch {}

    setLoad(false);
  }, [pathname]);

  return (
    <DashboardLayout>
      <DashboardNavbar breadCrumb={route.split("/").slice(1)} />
      <MDBox pt={3} pb={3} component="form" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MDBox pb={4}>
              <MDTypography variant="subtitle" fontWeight="bold">
                <Icon
                  sx={{ cursor: "pointer", fontWeight: "bold" }}
                  onClick={() => navigate(route)}
                >
                  arrow_back
                </Icon>
                {"   "}
                Se permite configurar parámetros de comunicación para los siguientes procesos:
              </MDTypography>
              <MDTypography variant="subtitle2">-Consulta de documentos a DGI</MDTypography>
              <MDTypography variant="subtitle2">-Envío de documentos a DGI</MDTypography>
              <MDTypography variant="subtitle2">-Envío de eventos a DGI</MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12}>
            <Grid
              item
              sm={12}
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              sx={{ "& > button": { m: 1 } }}
            >
              <MDButton
                variant="contained"
                onClick={() => resetValidator()}
                sx={{
                  background: "linear-gradient(45deg, #1971C2 30%, #1971C2 90%)",
                  color: "#FFFFFF",
                }}
              >
                <Icon>autorenew</Icon>&nbsp;Reset validador
              </MDButton>
              <MDButton
                variant="contained"
                onClick={() => resetPlugin()}
                sx={{
                  background: "linear-gradient(45deg, #1971C2 30%, #1971C2 90%)",
                  color: "#FFFFFF",
                }}
              >
                <Icon>autorenew</Icon>&nbsp;Reset plugin
              </MDButton>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox sx={{ position: "relative", mx: 3, py: 2, px: 2 }}>
                <Grid container spacing={3} justifyContent="left" alignItems="left" height="100%">
                  <Grid item xs={12} sm={12} md={6}>
                    <MDInput
                      variant="standard"
                      error={enqueueMaxError}
                      helperText={enqueueMaxError ? "Debe ser mayor que 0" : ""}
                      type="number"
                      label="Número de reintentos"
                      fullWidth
                      required
                      InputLabelProps={{ shrink: true }}
                      value={enqueueMax}
                      onChange={(e) => {
                        if (parseInt(e.target.value, 10) <= 10 && parseInt(e.target.value, 10) >= 1)
                          setEnqueueMax(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <MDInput
                      variant="standard"
                      error={enqueueTimeError}
                      helperText={enqueueTimeError ? enqueueTimeMessage : ""}
                      type="text"
                      label="Tº espera de reintentos"
                      fullWidth
                      required
                      InputLabelProps={{ shrink: true }}
                      value={enqueueTime}
                      onChange={(e) => {
                        if (rangoTimeMax(e.target.value)) {
                          setEnqueueTime("01:00:00");
                        } else if (rangoTimeMin(e.target.value)) {
                          setEnqueueTime("00:00:10");
                        } else {
                          setEnqueueTime(e.target.value);
                        }
                      }}
                      InputProps={{
                        inputComponent: TimeMaskCustom,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={5} lg={3}>
                    <MDInput
                      variant="standard"
                      type="text"
                      label="Componente"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      value={feConsFE}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={3}>
                    <MDInput
                      variant="standard"
                      error={feConsFETimeError}
                      helperText={feConsFETimeError ? feConsFETimeMessage : ""}
                      type="text"
                      label="Tº espera de reintentos"
                      fullWidth
                      required
                      InputLabelProps={{ shrink: true }}
                      value={feConsFETime}
                      onChange={(e) => {
                        if (rangoTimeMax(e.target.value)) {
                          setFeConsFETime("01:00:00");
                        } else if (rangoTimeMin(e.target.value)) {
                          setFeConsFETime("00:00:10");
                        } else {
                          setFeConsFETime(e.target.value);
                        }
                      }}
                      InputProps={{
                        inputComponent: TimeMaskCustom,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} lg={6}>
                    <MDInput
                      variant="standard"
                      error={feConsFEEndPointError}
                      helperText={feConsFEEndPointError ? "Debe tener valor" : ""}
                      type="text"
                      label="Endpoint"
                      fullWidth
                      required
                      InputLabelProps={{ shrink: true }}
                      value={feConsFEEndPoint}
                      onChange={(e) => {
                        setFeConsFEEndPoint(e.target.value);
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={6} lg={3}>
                    <MDInput
                      variant="standard"
                      type="text"
                      label="Componente"
                      fullWidth
                      required
                      InputLabelProps={{ shrink: true }}
                      value={feRecepFE}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={3}>
                    <MDInput
                      variant="standard"
                      error={feRecepFETimeError}
                      helperText={feRecepFETimeError ? feRecepFETimeMessage : ""}
                      type="text"
                      label="Tº espera de reintentos"
                      fullWidth
                      required
                      InputLabelProps={{ shrink: true }}
                      value={feRecepFETime}
                      onChange={(e) => {
                        if (rangoTimeMax(e.target.value)) {
                          setFeRecepFETime("01:00:00");
                        } else if (rangoTimeMin(e.target.value)) {
                          setFeRecepFETime("00:00:10");
                        } else {
                          setFeRecepFETime(e.target.value);
                        }
                      }}
                      InputProps={{
                        inputComponent: TimeMaskCustom,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} lg={6}>
                    <MDInput
                      variant="standard"
                      error={feRecepFEEndPointError}
                      helperText={feRecepFEEndPointError ? "Debe tener valor" : ""}
                      type="text"
                      label="Endpoint"
                      fullWidth
                      required
                      InputLabelProps={{ shrink: true }}
                      value={feRecepFEEndPoint}
                      onChange={(e) => {
                        setFeRecepFEEndPoint(e.target.value);
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={6} lg={3}>
                    <MDInput
                      variant="standard"
                      type="text"
                      label="Componente"
                      fullWidth
                      required
                      InputLabelProps={{ shrink: true }}
                      value={feRecepEventoFE}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={3}>
                    <MDInput
                      variant="standard"
                      error={feRecepEventTimeError}
                      helperText={feRecepEventTimeError ? feRecepEventTimeMessage : ""}
                      type="text"
                      label="Tº espera de reintentos"
                      fullWidth
                      required
                      InputLabelProps={{ shrink: true }}
                      value={feRecepEventTime}
                      onChange={(e) => {
                        if (rangoTimeMax(e.target.value)) {
                          setFeRecepEventTime("01:00:00");
                        } else if (rangoTimeMin(e.target.value)) {
                          setFeRecepEventTime("00:00:10");
                        } else {
                          setFeRecepEventTime(e.target.value);
                        }
                      }}
                      InputProps={{
                        inputComponent: TimeMaskCustom,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} lg={6}>
                    <MDInput
                      variant="standard"
                      error={feRecepEventEndPointError}
                      helperText={feRecepEventEndPointError ? "Debe tener valor" : ""}
                      type="text"
                      label="Endpoint"
                      fullWidth
                      required
                      InputLabelProps={{ shrink: true }}
                      value={feRecepEventEndPoint}
                      onChange={(e) => {
                        setFeRecepEventEndPoint(e.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="left" alignItems="left" height="100%">
              <Grid item xs={12} sm={12} md={6} lg={4} xl={10} />
              <Grid item xs={12} sm={12} md={6} lg={4} xl={2} container justifyContent="flex-end">
                <MDButton
                  variant="contained"
                  type="submit"
                  sx={{
                    background: "linear-gradient(45deg, #099268 30%, #099268 90%)",
                    color: "#FFFFFF",
                  }}
                >
                  <Icon>save</Icon>&nbsp;Guardar
                </MDButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
      {renderNotificacion}
      <Loading load={load} />
      <Footer />
    </DashboardLayout>
  );
}

export default ConfiguracionPacForm;
