import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import MDInput from "../../../../../components/MDInput";

function useCreateOrganizationCL({ organization }) {
  const certificates = ["Gosocket", "Propio"];
  const [numeroPRDValue, setNumeroPRDValue] = useState(organization?.resolutionNumberPRD ?? "");
  const [certificadoValue, setCertificadoValue] = useState(organization?.signingCertificate ?? "");

  const handleNumberPRD = (event) => {
    const regexNumber = /^[0-9]*$/;
    if (regexNumber.test(event)) {
      setNumeroPRDValue(event);
    }
  };

  const clearFields = () => {
    setCertificadoValue("");
    setNumeroPRDValue("");
  };

  const organizationData = {
    resolutionNumberPRD: numeroPRDValue === "" ? null : numeroPRDValue,
    signingCertificate: certificadoValue,
  };

  const buttonDisabled = certificadoValue === "";

  const getComponent = () => (
    <>
      <Grid item xs={12} md={5.5}>
        <Autocomplete
          options={certificates}
          size="medium"
          renderInput={(params) => (
            <MDInput
              {...params}
              label="Usar cert. para firma"
              placeholder="Seleccione"
              value={certificadoValue}
              variant="standard"
              fullWidth
              required
              InputLabelProps={{ shrink: true }}
            />
          )}
          value={certificadoValue}
          onChange={(event, newValue) => setCertificadoValue(newValue)}
        />
      </Grid>

      <Grid item xs={12} md={5.5}>
        <MDInput
          label="Número Resolución"
          placeholder="Ingrese"
          type="text"
          value={numeroPRDValue}
          onChange={(event) => handleNumberPRD(event.target.value)}
          size="medium"
          variant="standard"
          fullWidth
          // required
          InputLabelProps={{ shrink: true }}
          inputProps={{ maxLength: 10 }}
        />
      </Grid>
    </>
  );

  return {
    organizationData,
    clearFields,
    getComponent,
    buttonDisabled,
  };
}

export default useCreateOrganizationCL;
