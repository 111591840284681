import React, { useState } from "react";
import PropTypes from "prop-types";
// react-router components
import { Link, useLocation } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";
import ArrowBack from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import IconButton from "@mui/material/IconButton";

import InputLabel from "@mui/material/InputLabel";
import Notification from "../../_components/shared/Notification";

// Material Dashboard 2 React components
import MDBox from "../../../../../components/MDBox";
import MDInput from "../../../../../components/MDInput";
import MDButton from "../../../../../components/MDButton";
import Loading from "../../../../../components/Loading";

// Material Dashboard 2 React example components
import DashboardLayout from "../../../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../../../examples/Navbars/DashboardNavbar";
import Footer from "../../../../../examples/Footer";
import MDTypography from "../../../../../components/MDTypography";

// API
import xPosApiRequest from "../../../../../api/xPosApiRequest";

// Hook
import useNotification from "../../_hooks/useNotification";

import { CountryCodesToNames } from "../../_constants/general";
import createOrganizationByCountry from "../../_hooks/organizations";
import { isOk } from "../../_helpers/requestHelpers";
import SearchOrganization from "../../_components/shared/SearchOrganization";
import ConfigGlobal from "../../configGlobal";

function CrearOrganizacion({ country }) {
  const location = useLocation();
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  const { organization } = location.state || {};

  const [organizationState, setOrganizationState] = useState(organization);
  const [taxIDValue, setTaxIDValue] = useState(organizationState?.taxId ?? "");
  const [activePool, setActivePoolsValue] = useState(organizationState?.activePools ?? 0);
  const [nombreValue, setNombreValue] = useState(organizationState?.name ?? "");
  const [checked, setChecked] = useState(organizationState?.gs ?? true);
  const [customConfiguration, setCustomConfiguration] = useState(
    !!organizationState?.globalConfigUpdater
  );
  const [loading, setLoading] = useState(false);

  const { notification, showNotificationSuccess, showNotificationError, setNotification } =
    useNotification();

  const organizationByCountry = createOrganizationByCountry({
    country,
    organization: organizationState,
    showNotificationError,
  });

  const getPayload = () => {
    const { CertificateFile, ...payload } = organizationByCountry?.organizationData || {};
    const contentType = "multipart/form-data";

    let data = {
      country,
      taxID: taxIDValue,
      name: nombreValue,
      userLastAction: userInfo.correo,
      gs: checked,
      activePools: activePool,
      countryId: country,
      ...payload,
    };

    const params = `?${new URLSearchParams(data).toString()}`;

    if (CertificateFile) {
      const formFile = new FormData();
      formFile.append("CertificateFile", CertificateFile);
      data = formFile;
    }

    return {
      contentType,
      data,
      params,
    };
  };

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    const payload = getPayload();
    const { data } = payload;
    const headers = {
      "Content-Type": payload?.contentType,
    };

    if (organizationState === undefined) {
      const options = {
        method: "POST",
        url: `/organizations${payload?.params}`,
        headers,
        data,
      };

      try {
        const response = await xPosApiRequest(options);
        if (isOk(response.status)) {
          setOrganizationState(response.data.value);
          showNotificationSuccess("La organización se agregó exitosamente");
          setLoading(false);
        } else {
          showNotificationError(response.data);
          setLoading(false);
        }
      } catch (error) {
        showNotificationError("Ocurrio un error al crear la organización");
        setLoading(false);
      }
    } else {
      const options = {
        method: "PUT",
        url: `/organizations/${organizationState.guid}${payload?.params}`,
        headers,
        data,
      };

      try {
        const response = await xPosApiRequest(options);
        if (isOk(response.status)) {
          showNotificationSuccess("La organización se actualizó exitosamente");
          setLoading(false);
        } else {
          showNotificationError(response.data);
          setLoading(false);
        }
      } catch (error) {
        showNotificationError(`Ocurrio un error al actualizar la organización`);
        setLoading(false);
      }
    }
  };

  const bttonDisabled =
    taxIDValue === "" || nombreValue === "" || organizationByCountry?.buttonDisabled;

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleCustomConfiguration = (event) => setCustomConfiguration(event.target.checked);

  const countryName = CountryCodesToNames[country];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid display="flex" alignItems="center">
        <Tooltip title="Regresar" style={{ paddingLeft: 0 }} disableInteractive>
          <IconButton
            style={{ paddingLeft: 0 }}
            disableRipple
            component={Link}
            to={`/xPos/9${countryName}/9Organizaciones`}
          >
            <ArrowBack style={{ cursor: "pointer", color: "black" }} />
          </IconButton>
        </Tooltip>
        <MDTypography fontWeight="bold" variant="h5" color="dark" noWrap>
          {organizationState !== undefined ? "Editar organización" : "Crear organización"}
        </MDTypography>
      </Grid>
      <MDBox py={1}>
        <MDBox pt={3} pb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card>
                <MDBox px={4} pt={6} pb={8} style={{ display: "flex" }}>
                  <Grid container spacing={3}>
                    {organizationState ? (
                      <Grid item xs={12} md={5.5}>
                        <MDInput
                          label="Nombre de la Organización"
                          placeholder="Ingrese"
                          type="text"
                          value={nombreValue}
                          size="medium"
                          variant="standard"
                          fullWidth
                          disabled
                          InputLabelProps={{ shrink: true }}
                          inputProps={{ maxLength: 150 }}
                        />
                      </Grid>
                    ) : (
                      <Grid item xs={12} md={5.5}>
                        <SearchOrganization
                          country={country}
                          setTaxIDValue={setTaxIDValue}
                          showNotificationError={showNotificationError}
                          variant="standard"
                          required
                          searchInInbox
                          setTaxNameValue={setNombreValue}
                        />
                      </Grid>
                    )}

                    {organizationByCountry?.getComponent({ organizationState })}
                    <Grid item xs={12} md={5.5}>
                      <MDInput
                        label="xPos Activos"
                        placeholder="Ingrese"
                        type="text"
                        value={activePool}
                        onChange={(event) => setActivePoolsValue(event.target.value)}
                        size="medium"
                        variant="standard"
                        fullWidth
                        required
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ maxLength: 10 }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={3}
                    pl={3}
                    pr={3}
                    pb={3}
                    pt={3}
                    style={{
                      border: "1px dashed",
                      background: "#e9ecef",
                      borderRadius: 8,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <InputLabel
                      labelPlacement="top"
                      htmlFor="check-input"
                      style={{ display: "grid", justifyContent: "center" }}
                    >
                      <MDTypography
                        color="dark"
                        py={1}
                        style={{
                          marginLeft: 5,
                          paddingRight: 5,
                          fontSize: "small",
                          fontWeight: "700",
                          variant: "standard",
                          fontFamily: "Helvetica",
                        }}
                      >
                        Gestor de folios GS
                      </MDTypography>
                      <input
                        type="checkbox"
                        id="check-input"
                        checked={checked}
                        onChange={handleChange}
                      />
                    </InputLabel>
                    {organizationState?.organizationConfigGuid && (
                      <InputLabel
                        labelPlacement="top"
                        htmlFor="check-input"
                        style={{ display: "grid", justifyContent: "center" }}
                      >
                        <MDTypography
                          color="dark"
                          py={1}
                          style={{
                            marginLeft: 5,
                            paddingRight: 5,
                            fontSize: "small",
                            fontWeight: "700",
                            variant: "standard",
                            fontFamily: "Helvetica",
                          }}
                        >
                          Config. personalizada
                        </MDTypography>
                        <input
                          type="checkbox"
                          id="check-input"
                          checked={customConfiguration}
                          onChange={handleCustomConfiguration}
                        />
                      </InputLabel>
                    )}
                  </Grid>
                </MDBox>
                <MDBox display="flex" justifyContent="flex-end" my={4} mx={4}>
                  <MDButton
                    variant="contained"
                    color="primary"
                    disabled={bttonDisabled}
                    onClick={handleSubmit}
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "110px",
                      height: "35.5px",
                      borderRadius: 8,
                      backgroundColor: "#099268",
                    }}
                  >
                    <SaveIcon style={{ marginRight: 5, color: "white" }} />
                    <MDTypography fontWeight="bold" color="white" py={1} style={{ fontSize: 12 }}>
                      GUARDAR
                    </MDTypography>
                  </MDButton>
                </MDBox>
              </Card>
              {customConfiguration && (
                <MDBox mt={4}>
                  <ConfigGlobal
                    country={country}
                    organizationConfigGuid={organizationState?.organizationConfigGuid}
                  />
                </MDBox>
              )}
            </Grid>
          </Grid>
        </MDBox>
        <Notification notification={notification} setNotification={setNotification} />
        <Loading load={loading} />
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

CrearOrganizacion.propTypes = {
  country: PropTypes.string.isRequired,
};

export default CrearOrganizacion;
