// eslint-disable-next-line no-unused-vars
async function APIRequest(method, url, dataBody, isMultipartForm = false) {
  let response = null;

  try {
    const origin = "https://gosocket.net.react";
    // const origin = "http://localhost:3000";
    // const token = sessionStorage.getItem("token");
    const headers = new Headers();
    // const bearer = `Bearer ${token}`;
    // headers.append("Authorization", bearer);
    headers.append("Origin", origin);
    headers.append("Access-Control-Allow-Headers", "*");
    headers.append("Access-Control-Allow-Origin", origin);
    headers.append("Access-Control-Allow-Methods", "GET,PUT,POST,DELETE,PATCH,OPTIONS");
    let body = null;
    if (dataBody) {
      if (!isMultipartForm) {
        headers.append("Content-Type", "application/json");
      }
      body = isMultipartForm ? dataBody : JSON.stringify(dataBody);
    }

    const options = {
      method,
      headers,
      body,
    };

    const host = `${process.env.REACT_APP_API_URL}/Portal`;
    const urlComplete = `${host}${url}`;

    response = await fetch(urlComplete, options);
  } catch (error) {
    console.log(error);
  }

  return response;
}

export default APIRequest;
