/* eslint-disable react/prop-types */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  // IconButton,
} from "@mui/material";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Loading from "components/Loading";
import DataTable from "examples/Tables/DataTable";
// Data
import data from "views/admin/xdoc/installations/data";
// API Request
import APIRequest from "api/xDocApiRequest";

function InstallationsList() {
  const [clienteOptions, setClienteOptions] = useState([{ id: "", name: "", email: "" }]);
  const [selectCliente, setSelectCliente] = useState(null);
  const [jsonData, setJsonData] = useState([]);
  const [load, setLoad] = useState(false);
  const { pathname } = useLocation();
  const route = useLocation().pathname;
  const navigate = useNavigate();
  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };
  const showNotificationError = (message) => {
    configNotification("error", "warning", "Notificación", message, "", true);
  };
  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );
  const getJsonData = async () => {
    const tempJsonData = [];
    setLoad(true);
    const apiResponse = await (
      await APIRequest("GET", `/Installation/GetByClient?clientId=${selectCliente.id}`, null)
    ).json();

    apiResponse.forEach((item) => {
      tempJsonData.push(item);
    });
    setLoad(false);
    setJsonData(tempJsonData);
  };
  // const [taxIdFilter, setTaxIdFilter] = useState("");
  // const getJsonDataByTaxId = async (e) => {
  //   const filter = e.target.value;
  //   setTaxIdFilter(filter);

  //   const tempJsonData = [];
  //   if (!filter) {
  //     getJsonData();
  //     return;
  //   }

  //   const clientParam = selectCliente ? `&clientId=${selectCliente.id}` : "";
  //   const apiResponse = await (
  //     await APIRequest("GET", `/Installation/GetByTaxId?taxId=${filter}${clientParam}`, null)
  //   ).json();

  //   apiResponse.forEach((item) => {
  //     tempJsonData.push(item);
  //   });

  //   setJsonData(tempJsonData);
  // };
  // const clearTaxIdFilter = () => {
  //   setTaxIdFilter("");
  //   getJsonData();
  // };

  const [showConfirmDisable, setShowConfirmDisable] = useState(false);
  const [installationToDisable, setInstallationToDisable] = useState({});
  const [disableReason, setDisableReason] = useState("");
  const setEnabled = async (installation, confirmed) => {
    const enable = !installation.enabled;
    if (!enable && !confirmed) {
      setInstallationToDisable(installation);
      setShowConfirmDisable(true);
      return;
    }

    try {
      const apiResponse = await APIRequest("POST", "/Installation/SetEnabled", {
        installationGuid: installation.guid,
        enable,
        reason: disableReason,
      });

      if (apiResponse.status === 200) {
        setJsonData(
          jsonData.map((item) => ({
            ...item,
            enabled: item.guid === installation.guid ? enable : item.enabled,
            lastDisableReason:
              item.guid === installation.guid ? disableReason : item.lastDisableReason,
          }))
        );
        configNotification(
          "warning",
          "check",
          "Notificación",
          enable
            ? `Se habilitó la instalación '${installation.name}' exitosamente.`
            : `Se deshabilitó la instalación '${installation.name}' exitosamente.`,
          "",
          true
        );
      } else {
        const errorMessage = {
          code: apiResponse.status,
          message: `Error: ${apiResponse.status}`,
        };
        throw errorMessage;
      }
    } catch (e) {
      configNotification(
        "error",
        "warning",
        "Notificación",
        enable
          ? `Ocurrió un error al habilitar la instalación '${e.message}'.`
          : `Ocurrió un error al deshabilitar la instalación '${e.message}'.`,
        "",
        true
      );
    }
  };
  const handleCancelDisable = () => {
    setShowConfirmDisable(false);
  };
  const handleConfirmDisable = () => {
    setEnabled(installationToDisable, true);
    setShowConfirmDisable(false);
  };

  const [showConfirmEnableTest, setShowConfirmEnableTest] = useState(false);
  const [installationToEnableTest, setInstallationToEnableTest] = useState({});
  const setTestEnabled = async (installation, confirmed) => {
    if (!confirmed) {
      setInstallationToEnableTest(installation);
      setShowConfirmEnableTest(true);
      return;
    }

    const enable = !installation.testEnabled;
    try {
      const apiResponse = await APIRequest("POST", "/Installation/SetTestEnabled", {
        installationGuid: installation.guid,
        enableTest: enable,
      });

      if (apiResponse.status === 200) {
        setJsonData(
          jsonData.map((item) => ({
            ...item,
            testEnabled: item.guid === installation.guid ? enable : item.testEnabled,
          }))
        );
        configNotification(
          "warning",
          "check",
          "Notificación",
          enable
            ? `Se habilitó el ambiente de pruebas para la instalación '${installation.name}' exitosamente.`
            : `Se deshabilitó el ambiente de pruebas para la instalación '${installation.name}' exitosamente.`,
          "",
          true
        );
      } else {
        const errorMessage = {
          code: apiResponse.status,
          message: `Error: ${apiResponse.status}`,
        };
        throw errorMessage;
      }
    } catch (e) {
      configNotification(
        "error",
        "warning",
        "Notificación",
        enable
          ? `Ocurrió un error al habilitar el ambiente de pruebas para la instalación '${e.message}'.`
          : `Ocurrió un error al deshabilitar el ambiente de pruebas para la instalación '${e.message}'.`,
        "",
        true
      );
    }
  };
  const handleCancelEnableTest = () => {
    setShowConfirmEnableTest(false);
  };
  const handleConfirmEnableTest = () => {
    setTestEnabled(installationToEnableTest, true);
    setShowConfirmEnableTest(false);
  };

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [installationIdToDelete, setInstallationIdToDelete] = useState("");
  const deleteJsonData = async (id, confirmed) => {
    if (!confirmed) {
      setInstallationIdToDelete(id);
      setShowConfirmDelete(true);
      return;
    }

    try {
      const apiResponse = await APIRequest("DELETE", `/Installation/Delete?id=${id}`, null);

      if (apiResponse.status === 200) {
        const index = jsonData.map((item) => item.id).indexOf(id);
        const elements = [...jsonData];
        const nombreElemento = elements[index].name;
        elements.splice(index, 1);
        setJsonData(elements);
        configNotification(
          "warning",
          "check",
          "Notificación",
          `Se eliminó la instalación '${nombreElemento}' exitosamente.`,
          "",
          true
        );
      } else {
        const errorMessage = {
          code: apiResponse.status,
          message: `Error: ${apiResponse.status}`,
        };
        throw errorMessage;
      }
    } catch (e) {
      configNotification(
        "error",
        "warning",
        "Notificación",
        `Ocurrió un error al eliminar la instalación: ${e.message}`,
        "",
        true
      );
    }
  };
  const handleCancelDelete = () => {
    setShowConfirmDelete(false);
  };
  const handleConfirmDelete = () => {
    deleteJsonData(installationIdToDelete, true);
    setShowConfirmDelete(false);
  };

  const viewDetails = (installation) =>
    navigate("/AdministracionXDoc/Instalaciones/Detalles", {
      state: { installation, client: selectCliente, route },
    });

  const getClientesData = async () => {
    const tempJsonData = [];
    const apiResponse = await (await APIRequest("GET", "/Client/GetAll", null)).json();

    for (let index = 0; index < apiResponse.length; index += 1) {
      tempJsonData.push({
        id: apiResponse[index].id,
        name: `${apiResponse[index].name}-${apiResponse[index].email}`,
      });
    }
    setClienteOptions(tempJsonData);
  };
  useEffect(async () => {
    try {
      setLoad(true);
      await getClientesData();
    } catch {
      showNotificationError("Ocurrió un error inesperado.");
    }

    setLoad(false);
  }, [pathname]);

  const { columns, rows } = data(jsonData, deleteJsonData, setEnabled, setTestEnabled, viewDetails);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MDTypography variant="subtitle" fontWeight="bold">
              Instalaciones
            </MDTypography>
          </Grid>
          <Grid
            item
            xs={12}
            container
            spacing={3}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item sm={12} md={6} lg={5} alignItems="center">
              <Autocomplete
                value={selectCliente}
                size="medium"
                onChange={async (event, newValue) => {
                  setSelectCliente(newValue);
                }}
                options={clienteOptions}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <MDInput
                    variant="standard"
                    type="text"
                    label="Seleccione el cliente"
                    required
                    InputLabelProps={{ shrink: true }}
                    {...params}
                  />
                )}
              />
            </Grid>
            <Grid item sm={12} md={6} lg={2}>
              <Grid
                item
                sm={12}
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <MDButton
                  variant="contained"
                  onClick={() => getJsonData()}
                  sx={{
                    background: "linear-gradient(45deg, #1971C2 30%, #1971C2 90%)",
                    color: "#FFFFFF",
                    ml: "auto",
                  }}
                >
                  <Icon>search</Icon>&nbsp;Buscar
                </MDButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Card>
              {/* <MDBox style={{ marginLeft: "25px" }}>
                <TextField
                  onChange={getJsonDataByTaxId}
                  variant="outlined"
                  value={taxIdFilter}
                  label="Buscar por ID fiscal"
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        sx={{ visibility: taxIdFilter ? "visible" : "hidden" }}
                        onClick={clearTaxIdFilter}
                      >
                        <Icon>clear</Icon>
                      </IconButton>
                    ),
                  }}
                />
              </MDBox> */}
              <MDBox>
                <DataTable
                  entriesPerPage={(false, { defaultValue: 10, entries: [5, 10, 25, 50] })}
                  canSearch
                  showTotalEntries
                  table={{ columns, rows }}
                  pagination={{ variant: "contained", color: "warning" }}
                  isSorted
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {renderNotificacion}
      <Dialog
        open={showConfirmDelete}
        onClose={handleCancelDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">¿Quiere eliminar la instalación?</DialogTitle>
        <DialogActions>
          <Button onClick={handleCancelDelete}>Cancelar</Button>
          <Button onClick={handleConfirmDelete} autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showConfirmDisable}
        onClose={handleCancelDisable}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">¿Quiere deshabilitar la instalación?</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Motivo (opcional)"
            fullWidth
            variant="standard"
            onChange={(e) => setDisableReason(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDisable}>Cancelar</Button>
          <Button onClick={handleConfirmDisable} autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showConfirmEnableTest}
        onClose={handleCancelEnableTest}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {installationToEnableTest.testEnabled
            ? "¿Quiere deshabilitar el ambiente de pruebas para la instalación?"
            : "¿Quiere habilitar el ambiente de pruebas para la instalación?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleCancelEnableTest}>Cancelar</Button>
          <Button onClick={handleConfirmEnableTest} autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
      <Loading load={load} />
      <Footer />
    </DashboardLayout>
  );
}

export default InstallationsList;
