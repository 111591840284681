/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// Default Components
import MDTypography from "components/MDTypography";

export default function dataV(jsonData, setEditJsonData) {
  const getRows = () => {
    const elements = [];

    for (let index = 0; index < jsonData.length; index += 1) {
      elements.push({
        componente: jsonData[index].componente,
        endpointAddress: jsonData[index].endpointAddress,
        timeout: jsonData[index].timeout,
        action: (
          <MDTypography color="text" display="flex">
            <Tooltip
              title="Editar"
              disableInteractive
              onClick={() => {
                setEditJsonData();
              }}
            >
              <Icon>edit</Icon>
            </Tooltip>
          </MDTypography>
        ),
      });
    }

    return elements;
  };

  return {
    columns: [
      // { Header: "", accessor: "icon", width: "0%", align: "center" },
      { Header: "Componente", accessor: "componente", align: "center" },
      { Header: "Tº espera reintentos", accessor: "timeout", align: "center" },
      { Header: "Endpoint", accessor: "endpointAddress", align: "left" },
      { Header: "Editar", accessor: "action", align: "center", width: "10%" },
    ],

    rows: getRows(),
  };
}

// Setting default values for the props of MDBadge
dataV.defaultProps = {
  jsonData: [],
};

// Typechecking props of the data
dataV.propTypes = {
  jsonData: PropTypes.objectOf(PropTypes.array),
  setEditJsonData: PropTypes.func.isRequired,
};
