/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as React from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

export default function ResponsiveDialog({
  // editElement,
  saveChanges,
  children,
  title,
  titleButton,
  ...rest
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleSubmit = (event) => {
    event.preventDefault();
    saveChanges();
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      maxWidth="lg"
      {...rest}
      aria-labelledby="responsive-dialog-title"
    >
      <MDBox component="form" onSubmit={handleSubmit}>
        <DialogTitle id="responsive-dialog-title">
          <MDTypography variant="subtitle" fontWeight="bold">
            {title}
          </MDTypography>
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <MDButton
            variant="contained"
            type="submit"
            sx={{
              background: "linear-gradient(45deg, #099268 30%, #099268 90%)",
              color: "#FFFFFF",
            }}
          >
            <Icon>check_circle</Icon>&nbsp;{titleButton}
          </MDButton>
          {/* <MDButton
            variant="contained"
            onClick={editElement}
            sx={{
              background: "linear-gradient(45deg, #CC0000 30%, #CC0000 90%)",
              color: "#FFFFFF",
            }}
          >
            <Icon>edit</Icon>&nbsp;Editar
          </MDButton> */}
        </DialogActions>
      </MDBox>
    </Dialog>
  );
}

// Setting default values for the props of MDSnackbar
ResponsiveDialog.defaultProps = {
  title: "",
  titleButton: "Aceptar",
};

// Typechecking props for MDSnackbar
ResponsiveDialog.propTypes = {
  // editElement: PropTypes.func.isRequired,
  saveChanges: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  titleButton: PropTypes.string,
};
