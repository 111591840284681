/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// @mui material components
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDDatePicker from "components/MDDatePicker";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// import { v4 as uuidv4 } from "uuid";

// API Request
// import APIRequest from "api/request";
import APIRequestGeneric from "api/requestGeneric";
import APIRequest from "api/request";

function ReprocesarDocumentoForm() {
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  // const idFuncionalidad = 0;
  const host = process.env.REACT_APP_API_URL;
  const basicAuth = `${process.env.REACT_APP_API_ENEX_USER}:${process.env.REACT_APP_API_ENEX_PASSWORD}`;
  const basicAuthorization = `Basic ${btoa(basicAuth)}`;

  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  // const [jsonData, setJsonData] = useState([]);
  const { pathname } = useLocation();
  const {
    state: { route },
  } = useLocation();
  const navigate = useNavigate();

  const [displayValue, setDisplayValue] = useState("none");
  const [newValueValor, setNewValueValor] = useState("");
  const [newValueTipo, setNewValueTipo] = useState({ id: 1, nombre: "GlobalID" });
  const [newValuePais, setNewValuePais] = useState({ Name: "Chile", Code: "cl" });
  const [newValueDateStart, setNewValueDateStart] = useState(Date.now);
  const [newValueDateEnd, setNewValueDateEnd] = useState(Date.now);
  const [selectData] = useState({
    tipo: [
      { id: 1, nombre: "GlobalID" },
      { id: 2, nombre: "TaxID" },
    ],
  });
  const [selectPais, setSelectPais] = useState({
    pais: [{ Name: "Chile", Code: "cl" }],
  });
  const DateToYYYYmmDD = (DateVar) => {
    const NewDate = new Date(DateVar);
    const resultado = `${NewDate.getFullYear()}-${(NewDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${NewDate.getDate().toString().padStart(2, "0")}T00:00:00-06:00`;

    return resultado;
  };
  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };
  const showNotificationError = (message) => {
    configNotification("error", "warning", "Notificación", message, "", true);
  };
  const showNotificationSuccess = (message) => {
    configNotification("warning", "check", "Notificación", message, "", true);
  };

  const closeModal = () => {
    setNewValueValor("");
    setNewValueTipo({ id: 1, nombre: "GlobalID" });
    setNewValuePais({ Name: "Chile", Code: "cl" });
    setNewValueDateStart(Date.now);
    setNewValueDateEnd(Date.now);
  };
  const saveDataModal = async () => {
    let body = null;
    if (newValueTipo.id === 1) {
      body = {
        GlobalDocumentId: newValueValor,
        User: userInfo.correo,
      };
    } else {
      body = {
        GlobalDocumentId: "",
        TaxId: newValueValor,
        CountryId: newValuePais.Code,
        DateFrom: DateToYYYYmmDD(newValueDateStart),
        DateTo: DateToYYYYmmDD(newValueDateEnd),
        User: userInfo.correo,
      };
    }

    const urlComplete = `${host}/api/Document/DocumentReprocess`;
    const httpVerb = "POST";

    let apiResponse = await (
      await APIRequestGeneric(httpVerb, urlComplete, body, false, basicAuthorization)
    ).json();

    if (apiResponse.Code === 200 && apiResponse.IsSuccessful) {
      body = {
        tipoDocumentoId: newValueTipo.id,
        valor: newValueValor,
        usuario: userInfo.correo,
      };
      apiResponse = await APIRequest("POST", "/Api/Documento", body);
      showNotificationSuccess("Se completó el registro exitosamente");
      closeModal();
    } else {
      showNotificationError(`Error: ${apiResponse.Message}`);
    }
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );

  const handleSubmit = (event) => {
    event.preventDefault();
    saveDataModal();
  };

  const showForm = () => {
    if (newValueTipo.id === 2) {
      setDisplayValue("none");
    } else {
      setDisplayValue("flex");
    }
  };
  const getCountriesData = async () => {
    const tempJsonData = [];
    const hostUrl = process.env.REACT_APP_API_URL;
    const urlComplete = `${hostUrl}/api/CountrySettings/GetCountriesByFunctionality`;
    const httpVerb = "POST";
    const body = {
      Functionality: "DocumentReprocess",
      User: userInfo.correo,
    };
    const apiResponse = await (
      await APIRequestGeneric(httpVerb, urlComplete, body, false, basicAuthorization)
    ).json();
    if (apiResponse.Code === 200 && apiResponse.IsSuccessful) {
      const responseData = JSON.parse(apiResponse.Data);
      if (responseData != null) {
        for (let index = 0; index < responseData.length; index += 1) {
          tempJsonData.push({
            Code: responseData[index].Code.toLowerCase(),
            Name: responseData[index].Name,
          });
        }
      }
    }
    return tempJsonData;
  };

  useEffect(async () => {
    const paises = await getCountriesData();
    setSelectPais({
      pais: paises,
    });
  }, [pathname]);

  return (
    <DashboardLayout>
      <DashboardNavbar breadCrumb={route.split("/").slice(1)} />
      <MDBox pt={3} pb={3} component="form" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MDBox pb={4}>
              <MDTypography variant="subtitle" fontWeight="bold">
                <Icon
                  sx={{ cursor: "pointer", fontWeight: "bold" }}
                  onClick={() => navigate(route)}
                >
                  arrow_back
                </Icon>
                {"   "}
                Permite reprocesar documentos
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox sx={{ position: "relative", mx: 3, py: 2, px: 2 }}>
                <Grid container spacing={3} justifyContent="left" alignItems="left" height="100%">
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <Autocomplete
                      value={newValueTipo}
                      onChange={(event, newValue) => {
                        setNewValueTipo(newValue);
                        showForm();
                      }}
                      options={selectData.tipo}
                      getOptionLabel={(option) => option.nombre}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderInput={(params) => (
                        <MDInput
                          variant="standard"
                          type="text"
                          label="Informando"
                          fullWidth
                          required
                          InputLabelProps={{ shrink: true }}
                          {...params}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <MDInput
                      variant="standard"
                      type="text"
                      label="Valor"
                      fullWidth
                      required
                      InputLabelProps={{ shrink: true }}
                      value={newValueValor}
                      onChange={(e) => {
                        setNewValueValor(e.target.value);
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sx={{ display: displayValue }}>
                    <Grid
                      container
                      spacing={3}
                      justifyContent="left"
                      alignItems="left"
                      height="100%"
                    >
                      <Grid item xs={12} sm={12} md={4} lg={3}>
                        <Autocomplete
                          value={newValuePais}
                          onChange={(event, newValue) => {
                            setNewValuePais(newValue);
                          }}
                          options={selectPais.pais}
                          getOptionLabel={(option) => option.Name}
                          isOptionEqualToValue={(option, value) => option.Code === value.Code}
                          renderInput={(params) => (
                            <MDInput
                              variant="standard"
                              type="text"
                              label="País"
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                              {...params}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={4} lg={3}>
                        <MDDatePicker
                          fullWidth
                          options={{ enableTime: true, dateFormat: "d/m/Y H:i:S" }}
                          input={{
                            fullWidth: true,
                            variant: "standard",
                            label: "Desde",
                            required: true,
                          }}
                          InputLabelProps={{ shrink: true }}
                          value={newValueDateStart}
                          onChange={(e) => {
                            setNewValueDateStart(e[0]);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={4} lg={3}>
                        <MDDatePicker
                          fullWidth
                          options={{ enableTime: true, dateFormat: "d/m/Y H:i:S" }}
                          input={{
                            fullWidth: true,
                            variant: "standard",
                            label: "Hasta",
                            required: true,
                          }}
                          InputLabelProps={{ shrink: true }}
                          value={newValueDateEnd}
                          onChange={(e) => {
                            setNewValueDateEnd(e[0]);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="left" alignItems="left" height="100%">
              <Grid item xs={12} sm={12} md={6} lg={4} xl={10} />
              <Grid item xs={12} sm={12} md={6} lg={4} xl={2} container justifyContent="flex-end">
                <MDButton
                  variant="contained"
                  type="submit"
                  sx={{
                    background: "linear-gradient(45deg, #099268 30%, #099268 90%)",
                    color: "#FFFFFF",
                  }}
                >
                  <Icon>save</Icon>&nbsp;Guardar
                </MDButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
      {renderNotificacion}
      <Footer />
    </DashboardLayout>
  );
}

export default ReprocesarDocumentoForm;
