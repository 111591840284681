/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDButton from "components/MDButton";
import Loading from "components/Loading";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import data from "views/consultoria/ConfiguracionEmpresa/DocRestringidos/data";

// API Request
import APIRequest from "api/request";
import APIRequestGeneric from "api/requestGeneric";

function DocRestringidos() {
  const host = process.env.REACT_APP_API_URL;
  const basicAuth = `${process.env.REACT_APP_API_ENEX_USER}:${process.env.REACT_APP_API_ENEX_PASSWORD}`;
  const basicAuthorization = `Basic ${btoa(basicAuth)}`;

  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  const [load, setLoad] = useState(false);
  const [jsonData, setJsonData] = useState([]);
  const { pathname } = useLocation();
  const route = useLocation().pathname;
  const navigate = useNavigate();

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };
  const showNotificationError = (message) => {
    configNotification("error", "warning", "Notificación", message, "", true);
  };
  const showNotificationSuccess = (message) => {
    configNotification("warning", "check", "Notificación", message, "", true);
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );
  const getJsonData = async (countries) => {
    const urlComplete = `${host}/api/RestrictedDocument/GetRestrictedDocument`;
    const apiResponse = await (
      await APIRequestGeneric("GET", urlComplete, null, false, basicAuthorization)
    ).json();

    if (apiResponse.Code === 200 && apiResponse.IsSuccessful) {
      const tempJsonData = [];
      const responseData = JSON.parse(apiResponse.Data);

      for (let index = 0; index < responseData.length; index += 1) {
        const partitionKeys = responseData[index].PartitionKey.split("|");
        const documentType = partitionKeys.length > 2 ? partitionKeys[2] : partitionKeys[1];
        const tax = partitionKeys.length > 2 ? partitionKeys[1] : "";

        tempJsonData.push({
          partitionKey: responseData[index].PartitionKey,
          rowKey: responseData[index].RowKey,
          taxId: tax,
          docType: documentType, // responseData[index].PartitionKey.split("|")[1],
          pais: countries[
            countries
              .map((item) => item.id)
              .indexOf(responseData[index].PartitionKey.split("|")[0].toLowerCase())
          ],
          email: responseData[index].EnabledUserEmail,
          fechaAccion: responseData[index].Timestamp.substring(0, 10),
          usuario: responseData[index].ModifiedBy,
        });
      }

      setJsonData(tempJsonData);
    }
  };

  const deleteJsonData = async (id) => {
    if (window.confirm("¿Quiere eliminar el Tax ID?")) {
      try {
        const index = jsonData.map((item) => item.rowKey).indexOf(id);
        const elements = [...jsonData];

        const body = {
          PartitionKey: elements[index].partitionKey,
          RowKey: elements[index].rowKey,
        };
        setLoad(true);
        const urlComplete = `${host}/api/RestrictedDocument/DeleteRestrictedDocumentBy`;
        const apiResponse = await (
          await APIRequestGeneric("DELETE", urlComplete, body, false, basicAuthorization)
        ).json();

        if (apiResponse.Code === 200 && apiResponse.IsSuccessful) {
          // const nombreElemento = elements[index].taxId;
          elements.splice(index, 1);
          setJsonData(elements);

          showNotificationSuccess("Se eliminó el registro exitosamente.");
        } else {
          showNotificationSuccess(`Error: ${apiResponse.Message}`);
        }
      } catch (e) {
        showNotificationError(`Ocurrió un error al eliminar el Tax ID: ${e.message}`);
      }
      setLoad(false);
    }
  };

  const setEditJsonData = (element) => {
    navigate("/DocRestringidos/DocRestringidosForm", { state: { route, element } });
  };

  const getCountriesData = async () => {
    const tempJsonData = [];
    const apiResponse = await (await APIRequest("GET", "/api/Pais/Todos", null)).json();

    for (let index = 0; index < apiResponse.length; index += 1) {
      tempJsonData.push({
        id: apiResponse[index].codigo.toLowerCase(),
        nombre: apiResponse[index].nombre,
      });
    }

    return tempJsonData;
  };

  useEffect(async () => {
    try {
      setLoad(true);
      const tempCountries = await getCountriesData();
      await getJsonData(tempCountries);
    } catch {
      showNotificationError("Ocurrió un error");
    }
    setLoad(false);
  }, [pathname]);

  const { columns, rows } = data(jsonData, deleteJsonData, setEditJsonData);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MDTypography variant="subtitle" fontWeight="bold">
              Permite habilitar a un usuario para ver documentos restringidos en Inbox sobre un
              TaxID
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="left" alignItems="left" height="100%">
              <Grid item xs={12} sm={12} md={6} lg={4} xl={10} />
              <Grid item xs={12} sm={12} md={6} lg={4} xl={2} container justifyContent="flex-end">
                <MDButton
                  variant="contained"
                  onClick={() =>
                    navigate("/DocRestringidos/DocRestringidosForm", { state: { route } })
                  }
                  sx={{
                    background: "linear-gradient(45deg, #1971C2 30%, #1971C2 90%)",
                    color: "#FFFFFF",
                  }}
                >
                  <Icon>add_circle_outline</Icon>&nbsp;Agregar
                </MDButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox>
                <DataTable
                  entriesPerPage={(false, { defaultValue: 10, entries: [5, 10, 25, 50] })}
                  canSearch
                  showTotalEntries
                  table={{ columns, rows }}
                  pagination={{ variant: "contained", color: "warning" }}
                  isSorted
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {renderNotificacion}
      <Loading load={load} />
      <Footer />
    </DashboardLayout>
  );
}

export default DocRestringidos;
