/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
import PropTypes from "prop-types";

// react-router components
import { useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React example components
import MDButton from "components/MDButton";
import DashboardLayout from "../../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";
import Footer from "../../../../examples/Footer";
import NewDataTable from "../../../../examples/Tables/NewDataTable";

import { isOk } from "../_helpers/requestHelpers";
// Material Dashboard 2 React components
import MDInput from "../../../../components/MDInput";
import MDTypography from "../../../../components/MDTypography";
import MDBox from "../../../../components/MDBox";

// API
import xPosApiRequest from "../../../../api/xPosApiRequest";

// Data
import data from "./data";
import useNotification from "../_hooks/useNotification";
import Notification from "../_components/shared/Notification";
import SearchOrganization from "../_components/shared/SearchOrganization";
import { CountryCodesToNames } from "../_constants/general";

function Contingencias({ country }) {
  const navigator = useNavigate();
  const [searchTaxID, setSearchTaxID] = useState("");
  const [searchIDxPos, setSearchIDxPos] = useState("");
  const [loadingTable, setLoadingTable] = useState(false);
  const [dateValue, setDateValue] = useState("");

  const [jsonData, setJsonData] = useState([]);
  const { notification, showNotificationError, setNotification } = useNotification();

  const fetchData = async () => {
    setLoadingTable(true);
    const params = new URLSearchParams({
      countryId: country,
      taxId: searchTaxID,
    });

    if (searchIDxPos) {
      params.append("xposGuid", searchIDxPos);
    }

    if (dateValue) {
      params.append("contingencyStartDate", dateValue);
    }

    const options = {
      method: "GET",
      url: `/contingency?${params.toString()}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await xPosApiRequest(options);
      if (isOk(response.status)) {
        const logsData = await response.data;
        setJsonData(logsData);
      } else {
        showNotificationError("Ocurrio un error al cargar las contingencias.");
      }
      setLoadingTable(false);
    } catch (error) {
      showNotificationError(error.message);
      setLoadingTable(false);
    }
  };

  const handleSearchIDxPos = (xPosGuid) => {
    setSearchIDxPos(xPosGuid);
  };

  const setHistoryJsonData = (cont) => {
    navigator(`/XPos/${CountryCodesToNames[country]}/Contingencias/Historial`, { state: { cont } });
  };

  const currentDate = () => {
    const year = new Date().toLocaleString("default", { year: "numeric" });
    const month = new Date().toLocaleString("default", {
      month: "2-digit",
    });
    const day = new Date().toLocaleString("default", { day: "2-digit" });

    return [year, month, day].join("-");
  };

  const { columns, rows } = data(jsonData, setHistoryJsonData);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDTypography fontWeight="bold" variant="h5" color="dark" noWrap>
        Listado de contingencias
      </MDTypography>
      <MDBox py={3}>
        <MDBox pt={3} pb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} py={1}>
              <MDBox display="flex" justifyContent="space-between">
                <MDBox display="flex" alignItems="center" justifyContent="flex-start">
                  <MDBox width="12rem">
                    <SearchOrganization
                      country={country}
                      setTaxIDValue={setSearchTaxID}
                      showNotificationError={showNotificationError}
                      required
                      size="small"
                    />
                  </MDBox>
                  <MDBox width="12rem" ml={2}>
                    <MDInput
                      placeholder="Búsqueda por ID xPOS"
                      value={searchIDxPos}
                      label="ID xPOS"
                      type="search"
                      size="small"
                      onChange={(event) => handleSearchIDxPos(event.target.value)}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                    />
                  </MDBox>
                  <MDBox width="12rem" ml={2}>
                    <MDInput
                      label="Fecha inicio contingencia"
                      placeholder="Búsqueda por fecha"
                      type="date"
                      value={dateValue}
                      onChange={(event) => setDateValue(event.target.value)}
                      size="small"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ max: currentDate() }}
                    />
                  </MDBox>
                </MDBox>
                <MDBox display="flex" alignItems="center" justifyContent="flex-end">
                  <MDButton
                    variant="contained"
                    color="primary"
                    onClick={fetchData}
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "110px",
                      height: "35.5px",
                      borderRadius: 8,
                      backgroundColor: "#1971c2",
                    }}
                  >
                    <MDTypography fontWeight="bold" color="white" py={1} style={{ fontSize: 12 }}>
                      BUSCAR
                    </MDTypography>
                  </MDButton>
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <MDBox>
                  <NewDataTable
                    entriesPerPage={(false, { defaultValue: 10 })}
                    canSearch
                    tableType="contingencias"
                    showTotalEntries
                    loadingTable={loadingTable}
                    table={{ columns, rows }}
                    pagination={{ variant: "contained", color: "warning" }}
                    isSorted
                  />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Notification notification={notification} setNotification={setNotification} />
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

Contingencias.propTypes = {
  country: PropTypes.string.isRequired,
};

export default Contingencias;
