export function newQR(length) {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}
export function FormattDate(value) {
  let elemt = "";
  if (value < 10) {
    elemt = `0${value}`;
    return elemt;
  }
  return `${value}`;
}
