/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as React from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import Icon from "@mui/material/Icon";
import { useTheme } from "@mui/material/styles";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { Grid } from "@mui/material";

export default function ResponsiveDialog({
  closeModal,
  saveChanges,
  disabledSave,
  children,
  title,
  titleButton,
  ...rest
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleSubmit = (event) => {
    event.preventDefault();
    saveChanges();
  };

  const generateCSVTemplate = () => {
    let rows = [];

    rows = [
      {
        taxId: "TaxID",
        nombrexPos: "Nombre xPOS",
        tipoDTE: "Tipo DTE",
        tipoUmbral: "Tipo Umbral",
        cantidadUmbral: "Cantidad Umbral",
      },
      {
        taxId: "44444444-4",
        nombrexPos: "testing xPOS",
        tipoDTE: "33",
        tipoUmbral: "%",
        cantidadUmbral: "10",
      },
      {
        taxId: "44444444-4",
        nombrexPos: "test xPOS2",
        tipoDTE: "37",
        tipoUmbral: "N",
        cantidadUmbral: "20",
      },
    ];

    const csvContent = `data:text/csv;charset=utf-8,${rows
      .map((row) => Object.values(row).join(";"))
      .join("\n")}`;

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "ejemplo.csv");
    document.body.appendChild(link);
    link.click();
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      maxWidth="sm"
      // fullWidth
      {...rest}
      aria-labelledby="responsive-dialog-title"
    >
      <MDBox style={{ backgroundColor: "#f0f2f5" }} component="form" onSubmit={handleSubmit}>
        <DialogTitle
          id="responsive-dialog-title"
          style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
        >
          <MDTypography
            fontWeight="bold"
            style={{ justifyContent: "flex-start", display: "flex", color: "#344767" }}
          >
            {title}
          </MDTypography>
          <Grid
            backgroundColor="#1971c2"
            // width="50px"
            // height="30px"
            borderRadius={2}
            style={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => {
              generateCSVTemplate();
            }}
          >
            <Icon fontSize="small" style={{ color: "white", paddingLeft: 5 }}>
              download
            </Icon>
            <MDTypography
              color="white"
              py={1}
              style={{ marginLeft: 5, paddingRight: 10, fontSize: 12 }}
            >
              EJEMPLO
            </MDTypography>
          </Grid>
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions style={{ display: "flex", justifyContent: "flex-end" }}>
          <MDButton
            type="submit"
            variant="outlined"
            size="medium"
            onClick={closeModal}
            style={{ backgroundColor: "#e03131" }}
          >
            Cancelar
          </MDButton>
          <MDButton
            type="submit"
            variant="outlined"
            size="medium"
            disabled={disabledSave}
            onClick={(event) => handleSubmit(event)}
            style={{ backgroundColor: "#099268" }}
          >
            Guardar
          </MDButton>
        </DialogActions>
      </MDBox>
    </Dialog>
  );
}

// Setting default values for the props of MDSnackbar
ResponsiveDialog.defaultProps = {
  title: "",
  titleButton: "Guardar",
  disabledSave: false,
};

// Typechecking props for MDSnackbar
ResponsiveDialog.propTypes = {
  closeModal: PropTypes.func.isRequired,
  saveChanges: PropTypes.func.isRequired,
  disabledSave: PropTypes.bool,
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  titleButton: PropTypes.string,
};
