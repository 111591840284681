/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Tooltip from "@mui/material/Tooltip";
// import HistoryIcon from "@mui/icons-material/History";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

// Default Components
import MDTypography from "../../../../../../components/MDTypography";

export default function data(jsonData, handleDownload) {
  const getRows = () => {
    const elements = [];

    for (let index = 0; index < jsonData.length; index += 1) {
      elements.push({
        guid: jsonData[index].id,
        taxId: jsonData[index].taxId,
        xPosGuid: jsonData[index].xPosGuid,
        documentType: jsonData[index].documentType,
        contingencyStartDate: `${jsonData[index]?.contingencyStartDate?.split("T")[0]} ${
          jsonData[index]?.contingencyStartDate?.split("T")[1]?.split(".")[0]
        }`,
        contingencyEndDate: `${jsonData[index]?.contingencyEndDate?.split("T")[0]} ${
          jsonData[index]?.contingencyEndDate?.split("T")[1]?.split(".")[0]
        }`,
        action: (
          <MDTypography
            color="text"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Tooltip
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleDownload([jsonData[index].id]);
              }}
              title="Descargar"
              disableInteractive
            >
              <FileDownloadIcon
                fontSize="20px"
                style={{ fontSize: "20px", cursor: "pointer", marginRight: 10 }}
              />
            </Tooltip>
          </MDTypography>
        ),
      });
    }

    return elements;
  };

  return {
    columns: [
      { Header: "TaxID", accessor: "taxId", align: "center" },
      { Header: "ID xPOS", accessor: "xPosGuid", align: "center" },
      { Header: "Tipo de documento", accessor: "documentType", align: "center" },
      { Header: "Fecha inicio contingencia", accessor: "contingencyStartDate", align: "center" },
      { Header: "Fecha fin contingencia", accessor: "contingencyEndDate", align: "center" },
      { Header: "Descarga", accessor: "action", align: "center" },
    ],

    rows: getRows(),
  };
}

// Setting default values for the props of MDBadge
data.defaultProps = {
  jsonData: [],
};

// Typechecking props of the data
data.propTypes = {
  jsonData: PropTypes.objectOf(PropTypes.array),
  handleDownload: PropTypes.func.isRequired,
  setEditJsonData: PropTypes.func.isRequired,
};
