async function APIRequest(method, url, dataBody, useToken, basicAuthorization) {
  let response = null;

  try {
    const origin = "https://gosocket.net.react";
    const token = sessionStorage.getItem("token");
    const headers = new Headers();
    const bearer = `Bearer ${token}`;

    if (useToken) headers.append("Authorization", bearer);
    if (basicAuthorization) headers.append("Authorization", basicAuthorization);
    headers.append("Origin", origin);
    headers.append("Access-Control-Allow-Origin", origin);
    headers.append("Access-Control-Allow-Methods", "GET,PUT,POST,DELETE,PATCH,OPTIONS");

    if (dataBody) headers.append("Content-Type", "application/json");

    const options = {
      method,
      headers,
      body: dataBody ? JSON.stringify(dataBody) : null,
    };

    const urlComplete = url;

    response = await fetch(urlComplete, options);
  } catch (error) {
    console.log(error);
  }

  return response;
}

export default APIRequest;
