/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Loading from "components/Loading";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import base64ToArrayBuffer from "util/base64ToArrayBuffer";

// Data
import dataProveedor from "views/varios/Manager/CrearEmpresa/data/proveedor";
import dataCliente from "views/varios/Manager/CrearEmpresa/data/cliente";
import dataPlantilla from "views/varios/Manager/CrearEmpresa/data/plantilla";
import dataUsuario from "views/varios/Manager/CrearEmpresa/data/usuario";
import dataContacto from "views/varios/Manager/CrearEmpresa/data/contacto";
import BKModal from "views/varios/Manager/CrearEmpresa/modalContactos";

// API Request
import APIRequest from "api/request";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {/* <MDTypography>{children}</MDTypography> */}
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function ManagerCrearEmpresa() {
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  const [load, setLoad] = useState(false);
  const [modalContacts, setModalContacts] = useState(false);
  const [modalUpload, setModalUpload] = useState(false);
  const [jsonData, setJsonData] = useState({});
  const [jsonDataVendor, setJsonDataVendor] = useState([]);
  const [jsonDataClient, setJsonDataClient] = useState([]);
  const [jsonDataTemplate, setJsonDataTemplate] = useState([]);
  const [jsonDataUser, setJsonDataUser] = useState([]);
  const [jsonDataContact, setJsonDataContact] = useState([]);
  const { pathname } = useLocation();
  const { state } = useLocation();
  const route = pathname;
  const navigate = useNavigate();

  const [newValuePais, setNewValuePais] = useState(null);
  const [newValueTaxId, setNewValueTaxId] = useState("");

  const [activeDatosEmpresa, setActiveDatosEmpresa] = useState(true);
  const [newValueNombre, setNewValueNombre] = useState("");
  const [newValueEmail, setNewValueEmail] = useState("");
  const [newValueDireccion, setNewValueDireccion] = useState("");
  const [newValueCiudad, setNewValueCiudad] = useState("");
  const [newValueComuna, setNewValueComuna] = useState("");
  const [newValueTelefono, setNewValueTelefono] = useState("");
  const [newValueFax, setNewValueFax] = useState("");
  const [newValueDescripcion, setNewValueDescripcion] = useState("");

  const [activeDatosPago, setActiveDatosPago] = useState(true);
  const [newValueMeanPaymentId, setNewValueMeanPaymentId] = useState("");
  const [newValueCodigoComercio, setNewValueCodigoComercio] = useState("");
  const [newValueValorClave, setNewValueValorClave] = useState("");
  const [newValuePaymentStatus, setNewValuePaymentStatus] = useState(false);

  const [newValueXSLT, setNewValueXSLT] = useState("");
  const [newValueFiles, setNewValueFiles] = useState([]);
  const [newValueFileBase64, setNewValueFileBase64] = useState("");

  const [selectData, setSelectData] = useState({
    pais: [
      { id: "cr", nombre: "Costa rica" },
      { id: "ec", nombre: "Ecuador" },
      { id: "pe", nombre: "Perú" },
      { id: "cl", nombre: "Chile" },
      { id: "mx", nombre: "México" },
      { id: "gt", nombre: "Guatemala" },
      { id: "co", nombre: "Colombia" },
      { id: "pa", nombre: "Panamá" },
    ],
  });
  const [tabValue, setTabValue] = useState(0);

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };
  const showNotificationError = (message) => {
    configNotification("error", "warning", "Notificación", message, "", true);
  };
  const showNotificationSuccess = (message) => {
    configNotification("warning", "check", "Notificación", message, "", true);
  };
  const closeModalContacts = () => {
    setModalContacts(false);
    setJsonDataContact([]);
  };
  const closeModalUpload = () => {
    setModalUpload(false);
    setNewValueXSLT("");
    setNewValueFiles([]);
    setNewValueFileBase64("");
    setLoad(false);
  };

  const lector = new FileReader();
  lector.onload = async (file) => {
    if (file) {
      setNewValueFileBase64(
        file.target.result
          .toString()
          .replace("data:application/xml;base64,", "")
          .replace("data:text/xml;base64,", "")
          .replace("data:application/x-zip-compressed;base64,", "")
          .replace("data:application/octet-stream;base64,", "")
      );
    } else {
      console.log("No se leyó ningun archivo");
    }
  };

  const setViewModalContacts = async (AccountId, RelatedAccountId, CountryId, IsClient) => {
    setLoad(true);
    try {
      const apiResponse = await APIRequest(
        "GET",
        `/api/Manager/Empresa/Contactos?AccountId=${AccountId}&RelatedAccountId=${RelatedAccountId}&CountryId=${CountryId}&IsClient=${IsClient}`,
        null
      );

      if (apiResponse.status === 200 || apiResponse.status === 201 || apiResponse.ok) {
        const jsonResponse = await apiResponse.json();
        const tempElement = [];

        for (let index = 0; index < jsonResponse.length; index += 1) {
          tempElement.push({
            id: jsonResponse[index].contactId,
            nombre: jsonResponse[index].name,
            correo: jsonResponse[index].email,
            telefono: jsonResponse[index].phone,
          });
        }

        setJsonDataContact(tempElement);
        setModalContacts(true);
        showNotificationSuccess("Se encontraron los registros");
      } else {
        showNotificationError(`Error, estado: ${apiResponse.status}`);
      }
    } catch {
      showNotificationError("Error Inesperado");
    }

    setLoad(false);
  };

  const getJsonData = async () => {
    setLoad(true);
    try {
      const apiResponse = await APIRequest(
        "GET",
        `/api/Manager/Empresa?Code=${newValueTaxId}&CountryId=${newValuePais.id}`,
        null
      );

      if (apiResponse.status === 200 || apiResponse.status === 201 || apiResponse.ok) {
        const jsonResponse = await apiResponse.json();

        const tempElement = {
          id: jsonResponse.accountId,
          pais: newValuePais,
          taxId: jsonResponse.code,
          email: jsonResponse.contactEmail,
          nombre: jsonResponse.name,
          direccion: jsonResponse.address,
          ciudad: jsonResponse.city,
          comuna: jsonResponse.province,
          telefono: jsonResponse.phone,
          fax: jsonResponse.fax ? jsonResponse.fax : "",
          descripcion: jsonResponse.description,
          timestamp: jsonResponse.timestamp,
        };

        setNewValueNombre(jsonResponse.name);
        setNewValueEmail(jsonResponse.contactEmail);
        setNewValueDireccion(jsonResponse.address);
        setNewValueCiudad(jsonResponse.city);
        setNewValueComuna(jsonResponse.province);
        setNewValueTelefono(jsonResponse.phone);
        setNewValueFax(jsonResponse.fax ? jsonResponse.fax : "");
        setNewValueDescripcion(jsonResponse.description);

        setJsonData(tempElement);
        showNotificationSuccess("Se encontró registro");
      } else {
        showNotificationError(`Error, estado: ${apiResponse.status}`);
      }
    } catch {
      showNotificationError("Error Inesperado");
    }

    setLoad(false);
  };

  const getJsonDataVendor = async (AccountId, CountryId) => {
    setLoad(true);
    try {
      const apiResponse = await APIRequest(
        "GET",
        `/api/Manager/Empresa/Proveedores?AccountId=${AccountId}&CountryId=${CountryId}`,
        null
      );

      if (apiResponse.status === 200 || apiResponse.status === 201 || apiResponse.ok) {
        const jsonResponse = await apiResponse.json();
        const tempElement = [];

        for (let index = 0; index < jsonResponse.length; index += 1) {
          tempElement.push({
            id: jsonResponse[index].accountId,
            rut: jsonResponse[index].code,
            razon: jsonResponse[index].name,
          });
        }

        setJsonDataVendor(tempElement);
        showNotificationSuccess("Se encontraron los registros");
      } else {
        showNotificationError(`Error, estado: ${apiResponse.status}`);
      }
    } catch {
      showNotificationError("Error Inesperado");
    }

    setLoad(false);
  };

  const getJsonDataClient = async (AccountId, CountryId) => {
    setLoad(true);
    try {
      const apiResponse = await APIRequest(
        "GET",
        `/api/Manager/Empresa/Clientes?AccountId=${AccountId}&CountryId=${CountryId}`,
        null
      );

      if (apiResponse.status === 200 || apiResponse.status === 201 || apiResponse.ok) {
        const jsonResponse = await apiResponse.json();
        const tempElement = [];

        for (let index = 0; index < jsonResponse.length; index += 1) {
          tempElement.push({
            id: jsonResponse[index].accountId,
            rut: jsonResponse[index].code,
            razon: jsonResponse[index].name,
          });
        }

        setJsonDataClient(tempElement);
        showNotificationSuccess("Se encontraron los registros");
      } else {
        showNotificationError(`Error, estado: ${apiResponse.status}`);
      }
    } catch {
      showNotificationError("Error Inesperado");
    }

    setLoad(false);
  };

  const getJsonDataTemplate = async (AccountId, AccountCode, CountryId) => {
    setLoad(true);
    try {
      const apiResponse = await APIRequest(
        "GET",
        `/api/Manager/Empresa/Plantillas?AccountId=${AccountId}&AccountCode=${AccountCode}&CountryId=${CountryId}`,
        null
      );

      if (apiResponse.status === 200 || apiResponse.status === 201 || apiResponse.ok) {
        const jsonResponse = await apiResponse.json();
        const tempElement = [];

        for (let index = 0; index < jsonResponse.length; index += 1) {
          tempElement.push({
            id: jsonResponse[index].documentTypeId,
            code: jsonResponse[index].code,
            class: jsonResponse[index].class,
            tipoDoc: jsonResponse[index].description,
            plantilla: "",
          });
        }

        setJsonDataTemplate(tempElement);
        showNotificationSuccess("Se encontraron los registros");
      } else {
        showNotificationError(`Error, estado: ${apiResponse.status}`);
      }
    } catch {
      showNotificationError("Error Inesperado");
    }

    setLoad(false);
  };

  const getJsonDataUser = async (AccountId, CountryId) => {
    setLoad(true);
    try {
      const apiResponse = await APIRequest(
        "GET",
        `/api/Manager/Empresa/Usuarios?AccountId=${AccountId}&CountryId=${CountryId}`,
        null
      );

      if (apiResponse.status === 200 || apiResponse.status === 201 || apiResponse.ok) {
        const jsonResponse = await apiResponse.json();
        const tempElement = [];

        for (let index = 0; index < jsonResponse.length; index += 1) {
          if (jsonResponse[index].user) {
            tempElement.push({
              id: jsonResponse[index].user.userId,
              nombre: jsonResponse[index].user.name,
              correo: jsonResponse[index].user.email,
              esAdmin: jsonResponse[index].isAdmin,
            });
          } /* else {
            console.log("index", index);
            console.log("jsonResponse[index]", jsonResponse[index]);
          } */
        }

        setJsonDataUser(tempElement);
        showNotificationSuccess("Se encontraron los registros");
      } else {
        showNotificationError(`Error, estado: ${apiResponse.status}`);
      }
    } catch {
      showNotificationError("Error Inesperado");
    }

    setLoad(false);
  };

  const getJsonDataPayment = async (AccountId, CountryId) => {
    setLoad(true);
    try {
      const apiResponse = await APIRequest(
        "GET",
        `/api/Manager/Empresa/Pago?AccountId=${AccountId}&CountryId=${CountryId}`,
        null
      );

      if (apiResponse.status === 200 || apiResponse.status === 201 || apiResponse.ok) {
        const jsonResponse = await apiResponse.json();

        setNewValueMeanPaymentId(jsonResponse.accountMeanPaymentId);
        setNewValueCodigoComercio(jsonResponse.commerceCode);
        setNewValueValorClave(jsonResponse.password);
        setNewValuePaymentStatus(jsonResponse.active);

        showNotificationSuccess("Se encontró registro");
      } else {
        showNotificationError(`Error, estado: ${apiResponse.status}`);
      }
    } catch {
      showNotificationError("Error Inesperado");
    }

    setLoad(false);
  };

  const saveDataModalUpload = async () => {
    if (newValueFiles.length > 0 && newValuePais !== null) {
      if (newValuePais.id !== null) {
        setLoad(true);
        try {
          const body = {
            CountryId: newValuePais.id,
            Base64: newValueFileBase64,
          };
          const apiResponse = await APIRequest("POST", "/api/Manager/Empresa/Masivo", body);

          if (apiResponse.status === 200 || apiResponse.status === 201 || apiResponse.ok) {
            showNotificationSuccess("Se crearon las empresas exitosamente");
            closeModalUpload();
          } else {
            showNotificationError(`Error: ${apiResponse.status}`);
          }
        } catch {
          showNotificationError("Ocurrió un error inesperado.");
        }
        setLoad(false);
      } else {
        showNotificationError("Primero debe cargar el archivo csv y seleccionar un pais.");
      }
    } else {
      showNotificationError("Primero debe cargar el archivo csv y seleccionar un pais.");
    }
  };
  const downloadTemplateMassive = async () => {
    const nameFile = "templateMassive.csv";
    const arrBuffer = base64ToArrayBuffer(window.btoa("123-6;name"));
    const blob = new Blob([arrBuffer], { type: "application/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = nameFile;
    a.click();
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );

  const { columnsContact, rowsContact } = dataContacto(jsonDataContact);

  const renderModalContacts = (
    <BKModal saveChanges={closeModalContacts} open={modalContacts} title="Contactos">
      <MDBox>
        <DataTable
          entriesPerPage={(false, { defaultValue: 10, entries: [5, 10, 25, 50] })}
          canSearch
          showTotalEntries
          table={{ columns: columnsContact, rows: rowsContact }}
          pagination={{ variant: "contained", color: "warning" }}
          isSorted
          noEndBorder
        />
      </MDBox>
    </BKModal>
  );
  const renderModalUpload = (
    <BKModal
      closeModal={closeModalUpload}
      saveChanges={saveDataModalUpload}
      open={modalUpload}
      title="Crear Empresas Masivamente"
    >
      <MDBox sx={{ position: "relative", mx: 3, py: 2, px: 2 }}>
        <Grid container spacing={3} justifyContent="left" alignItems="left" height="100%">
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <MDInput
              id="file-input"
              variant="standard"
              type="file"
              label="Seleccionar Archivo"
              sx={{ display: "none" }}
              fullWidth
              InputLabelProps={{ shrink: true }}
              inputProps={{ accept: ".xslt" }}
              onChange={async (e) => {
                setNewValueFiles(e.target.files);
                const tempValueName = e.target.files[0] ? e.target.files[0].name : "";
                setNewValueXSLT(tempValueName.length > 0 ? tempValueName : newValueXSLT);
                // await lector.readAsText(e.target.files[0]);
                await lector.readAsDataURL(e.target.files[0]);
              }}
            />
            <MDButton
              variant="outlined"
              autoFocus
              color="warning"
              onClick={() => document.getElementById("labelFile").click()}
            >
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label id="labelFile" htmlFor="file-input" style={{ display: "none" }} />
              Adjuntar Archivo <Icon>upload</Icon>
            </MDButton>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <MDInput
              variant="standard"
              type="text"
              label="Empresas"
              fullWidth
              required
              disabled
              InputLabelProps={{ shrink: true }}
              value={newValueXSLT}
              onChange={(e) => {
                setNewValueXSLT(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <MDButton
              variant="contained"
              onClick={() => downloadTemplateMassive()}
              sx={{
                background: "linear-gradient(45deg, #1971C2 30%, #1971C2 90%)",
                color: "#FFFFFF",
              }}
            >
              <Icon>download</Icon>&nbsp;Descargar Plantilla
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>
    </BKModal>
  );

  const setEditJsonDataEmpresa = async () => {
    setLoad(true);
    try {
      if (jsonData.id) {
        if (!activeDatosEmpresa) {
          const HttpVerb = "PATCH";
          const HttpUrl = `/api/Manager/Empresa?AccountId=${jsonData.id}`;

          const body = {
            AccountId: jsonData.id,
            CountryId: jsonData.pais.id,
            Code: jsonData.taxId,
            Name: newValueNombre,
            Description: newValueDescripcion,
            Address: newValueDireccion,
            City: newValueCiudad,
            Province: newValueComuna,
            Phone: newValueTelefono,
            Fax: newValueFax,
            ContactEmail: newValueEmail,
            ShowInPdf: null,
            Timestamp: jsonData.timestamp,
            BankAccountNumber: null,
            BankId: null,
            TimestampUserUpdate: new Date(),
            TimestampSystemUpdate: null,
            EmailUserUpdate: userInfo.correo,
            Source: null,
            ShowDocumentsLastDays: null,
          };

          const apiResponse = await APIRequest(HttpVerb, HttpUrl, body);

          if (apiResponse.status === 200 || apiResponse.status === 201 || apiResponse.ok) {
            showNotificationSuccess("Se actualizó la empresa exitosamente");
          } else {
            showNotificationError(`Error, estado: ${apiResponse.status}`);
          }
        }

        setActiveDatosEmpresa(!activeDatosEmpresa);
      }
    } catch {
      showNotificationError("Error Inesperado");
    }

    setLoad(false);
  };

  const setEditJsonDataPago = async () => {
    setLoad(true);
    try {
      if (jsonData.id) {
        if (!activeDatosPago) {
          let HttpVerb = "POST";
          let HttpUrl = "/api/Manager/Empresa/Pago";
          if (newValueMeanPaymentId.trim().length > 0) {
            HttpVerb = "PATCH";
            HttpUrl = `${HttpUrl}?AccountMeanPaymentId=${newValueMeanPaymentId}`;
          }

          const body = {
            AccountMeanPaymentId: newValueMeanPaymentId,
            CountryId: jsonData.pais.id,
            AccountId: jsonData.id,
            Active: newValuePaymentStatus,
            CommerceCode: newValueCodigoComercio,
            Password: newValueValorClave,
            TermsAndConditions: null,
          };

          const apiResponse = await APIRequest(HttpVerb, HttpUrl, body);

          if (apiResponse.status === 200 || apiResponse.status === 201 || apiResponse.ok) {
            if (newValueMeanPaymentId.trim().length > 0) {
              showNotificationSuccess("Se actualizó el pago exitosamente");
            } else {
              showNotificationSuccess("Se creó el pago exitosamente");
              const jsonResponse = await apiResponse.json();
              setNewValueMeanPaymentId(jsonResponse);
            }
          } else {
            showNotificationError(`Error, estado: ${apiResponse.status}`);
          }
        }

        setActiveDatosPago(!activeDatosPago);
      }
    } catch {
      showNotificationError("Error Inesperado");
    }

    setLoad(false);
  };

  const getCountriesData = async () => {
    const tempJsonData = [];
    const apiResponse = await (await APIRequest("GET", "/api/Pais/Todos", null)).json();

    if (apiResponse.length > 0) {
      for (let index = 0; index < apiResponse.length; index += 1) {
        tempJsonData.push({
          id: apiResponse[index].codigo.toLowerCase(),
          nombre: apiResponse[index].nombre,
        });
      }

      setSelectData({
        pais: tempJsonData,
        // status: selectData.status,
      });
    }

    return tempJsonData;
  };

  const getElementChanges = async () => {
    if (sessionStorage.getItem("jsonDataManaEmpre")) {
      const jsonDataXml = JSON.parse(sessionStorage.getItem("jsonDataManaEmpre"));
      sessionStorage.setItem("jsonDataManaEmpre", null);

      if (state) {
        if (state.newElement) {
          const tempElement = {
            id: state.newElement.AccountId,
            pais: state.newElement.Country.id,
            taxId: state.newElement.Code,
            email: state.newElement.ContactEmail,
            nombre: state.newElement.Name,
            direccion: state.newElement.Address,
            ciudad: state.newElement.City,
            comuna: state.newElement.Province,
            telefono: state.newElement.Phone,
            fax: state.newElement.Fax ? state.newElement.Fax : "",
            descripcion: state.newElement.Description,
            timestamp: state.newElement.Timestamp,
          };

          setNewValueNombre(state.newElement.Name);
          setNewValueEmail(state.newElement.ContactEmail);
          setNewValueDireccion(state.newElement.Address);
          setNewValueCiudad(state.newElement.City);
          setNewValueComuna(state.newElement.Province);
          setNewValueTelefono(state.newElement.Phone);
          setNewValueFax(state.newElement.Fax ? state.newElement.Fax : "");
          setNewValueDescripcion(state.newElement.Description);

          // setJsonData([state.newElement, ...jsonDataXml]);
          setJsonData(...tempElement);
        }
      } else if (jsonDataXml) {
        // setJsonData([...jsonDataXml]);
        setJsonData(...jsonDataXml);
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    getJsonData();
  };

  const handleTabChange = (event, newValue) => {
    if (jsonData.id != null) {
      setTabValue(newValue);

      switch (newValue) {
        /* case 1: // Documentos
          // asfd
          break; */
        case 2: // Proveedores
          getJsonDataVendor(jsonData.id, jsonData.pais.id);
          break;
        case 3: // Clientes
          getJsonDataClient(jsonData.id, jsonData.pais.id);
          break;
        case 4: // Usuarios Vinculados
          getJsonDataUser(jsonData.id, jsonData.pais.id);
          break;
        case 5: // Plantillas de Visualización
          getJsonDataTemplate(jsonData.id, jsonData.taxId, jsonData.pais.id);
          break;
        case 6: // Pago
          getJsonDataPayment(jsonData.id, jsonData.pais.id);
          break;
        default:
          break;
      }
    }
  };

  useEffect(async () => {
    await getCountriesData();
    await getElementChanges();
  }, [pathname]);

  const { columnsVendor, rowsVendor } = dataProveedor(
    jsonDataVendor,
    setViewModalContacts,
    jsonData.id ? jsonData.id : "",
    jsonData.pais ? jsonData.pais.id : ""
  );
  const { columnsClient, rowsClient } = dataCliente(
    jsonDataClient,
    setViewModalContacts,
    jsonData.id ? jsonData.id : "",
    jsonData.pais ? jsonData.pais.id : ""
  );
  const { columnsTemplate, rowsTemplate } = dataPlantilla(jsonDataTemplate);
  const { columnsUser, rowsUser } = dataUsuario(jsonDataUser);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={3}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <MDTypography variant="subtitle" fontWeight="bold">
              Manager - Empresa
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox
                sx={{ position: "relative", mx: 3, py: 2, px: 2 }}
                component="form"
                onSubmit={handleSubmit}
              >
                <Grid container spacing={3} justifyContent="left" alignItems="left" height="100%">
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <Autocomplete
                      value={newValuePais}
                      onChange={(event, newValue) => {
                        setNewValuePais(newValue);
                      }}
                      options={selectData.pais}
                      getOptionLabel={(option) => option.nombre}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderInput={(params) => (
                        <MDInput
                          variant="standard"
                          type="text"
                          label="Pais"
                          fullWidth
                          required
                          InputLabelProps={{ shrink: true }}
                          {...params}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <MDInput
                      variant="standard"
                      type="text"
                      label="TaxId"
                      fullWidth
                      required
                      InputLabelProps={{ shrink: true }}
                      value={newValueTaxId}
                      onChange={(e) => {
                        setNewValueTaxId(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={4} xl={6} />
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    lg={2}
                    xl={2}
                    container
                    // justifyContent="flex-end"
                  >
                    <MDButton
                      variant="contained"
                      type="submit"
                      sx={{
                        background: "linear-gradient(45deg, #1971C2 30%, #1971C2 90%)",
                        color: "#FFFFFF",
                      }}
                    >
                      <Icon>search</Icon>&nbsp;Buscar
                    </MDButton>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={2} xl={2} container>
                    <MDButton
                      variant="contained"
                      // onClick={() => descargarLista()}
                      sx={{
                        background: "linear-gradient(45deg, #A0A0A0 30%, #A0A0A0 90%)",
                        color: "#FFFFFF",
                      }}
                    >
                      <Icon>cancel</Icon>&nbsp;Limpiar
                    </MDButton>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    lg={5}
                    xl={2}
                    container
                    justifyContent="flex-end"
                  >
                    <MDButton
                      variant="contained"
                      onClick={() => {
                        sessionStorage.setItem("jsonDataManaEmpre", JSON.stringify(jsonData));
                        navigate("/Manager/Empresa/Form", { state: { route } });
                      }}
                      sx={{
                        background: "linear-gradient(45deg, #099268 30%, #099268 90%)",
                        color: "#FFFFFF",
                      }}
                    >
                      <Icon>cancel</Icon>&nbsp;Nueva empresa
                    </MDButton>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    lg={3}
                    xl={2}
                    container
                    justifyContent="flex-end"
                  >
                    <MDButton
                      variant="contained"
                      onClick={() => setModalUpload(true)}
                      sx={{
                        background: "linear-gradient(45deg, #1971C2 30%, #1971C2 90%)",
                        color: "#FFFFFF",
                      }}
                    >
                      <Icon>cancel</Icon>&nbsp;Agregar desde archivo
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                    <Tab label="Datos Empresa" {...a11yProps(0)} />
                    {/*  <Tab label="Documentos" {...a11yProps(1)} /> */}
                    <Tab label="Proveedores" {...a11yProps(2)} />
                    <Tab label="Clientes" {...a11yProps(3)} />
                    <Tab label="Usuarios Vinculados" {...a11yProps(4)} />
                    <Tab label="Plantillas de Visualización" {...a11yProps(5)} />
                    <Tab label="Pago" {...a11yProps(6)} />
                  </Tabs>
                </Box>
                <TabPanel value={tabValue} index={0}>
                  <Card>
                    <MDBox
                      sx={{ position: "relative", mx: 3, py: 2, px: 2 }}
                      // component="form"
                      // onSubmit={handleSubmit}
                    >
                      <Grid
                        container
                        spacing={3}
                        justifyContent="left"
                        alignItems="left"
                        height="100%"
                      >
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          container
                          justifyContent="flex-end"
                        >
                          <MDButton
                            variant="contained"
                            onClick={() => setEditJsonDataEmpresa()}
                            sx={{
                              background: "linear-gradient(45deg, #FFC300 30%, #FFC300 90%)",
                              color: "#FFFFFF",
                            }}
                          >
                            <Icon>edit</Icon>&nbsp;Editar Empresa
                          </MDButton>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                          <MDInput
                            variant="standard"
                            type="text"
                            label="RUT de empresa"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            value={newValueTaxId}
                            onChange={(e) => {
                              setNewValueTaxId(e.target.value);
                            }}
                            disabled
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                          <MDInput
                            variant="standard"
                            type="text"
                            label="Nombre"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            value={newValueNombre}
                            onChange={(e) => {
                              setNewValueNombre(e.target.value);
                            }}
                            disabled={activeDatosEmpresa}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                          <MDInput
                            variant="standard"
                            type="text"
                            label="Email"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            value={newValueEmail}
                            onChange={(e) => {
                              setNewValueEmail(e.target.value);
                            }}
                            disabled={activeDatosEmpresa}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                          <MDInput
                            variant="standard"
                            type="text"
                            label="Dirección"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            value={newValueDireccion}
                            onChange={(e) => {
                              setNewValueDireccion(e.target.value);
                            }}
                            disabled={activeDatosEmpresa}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                          <MDInput
                            variant="standard"
                            type="text"
                            label="Ciudad"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            value={newValueCiudad}
                            onChange={(e) => {
                              setNewValueCiudad(e.target.value);
                            }}
                            disabled={activeDatosEmpresa}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                          <MDInput
                            variant="standard"
                            type="text"
                            label="Comuna"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            value={newValueComuna}
                            onChange={(e) => {
                              setNewValueComuna(e.target.value);
                            }}
                            disabled={activeDatosEmpresa}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                          <MDInput
                            variant="standard"
                            type="text"
                            label="Teléfono"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            value={newValueTelefono}
                            onChange={(e) => {
                              setNewValueTelefono(e.target.value);
                            }}
                            disabled={activeDatosEmpresa}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                          <MDInput
                            variant="standard"
                            type="text"
                            label="Fax"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            value={newValueFax}
                            onChange={(e) => {
                              setNewValueFax(e.target.value);
                            }}
                            disabled={activeDatosEmpresa}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                          <MDInput
                            variant="standard"
                            type="text"
                            label="Descripción"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            value={newValueDescripcion}
                            onChange={(e) => {
                              setNewValueDescripcion(e.target.value);
                            }}
                            multiline
                            rows={4}
                            disabled={activeDatosEmpresa}
                          />
                        </Grid>
                      </Grid>
                    </MDBox>
                  </Card>
                </TabPanel>
                {/* <TabPanel value={tabValue} index={1}>
                  Item Two
                </TabPanel> */}
                <TabPanel value={tabValue} index={2}>
                  <Card>
                    <MDBox>
                      <DataTable
                        entriesPerPage={(false, { defaultValue: 10, entries: [5, 10, 25, 50] })}
                        canSearch
                        showTotalEntries
                        table={{ columns: columnsVendor, rows: rowsVendor }}
                        pagination={{ variant: "contained", color: "warning" }}
                        isSorted
                        noEndBorder
                      />
                    </MDBox>
                  </Card>
                </TabPanel>
                <TabPanel value={tabValue} index={3}>
                  <Card>
                    <MDBox>
                      <DataTable
                        entriesPerPage={(false, { defaultValue: 10, entries: [5, 10, 25, 50] })}
                        canSearch
                        showTotalEntries
                        table={{ columns: columnsClient, rows: rowsClient }}
                        pagination={{ variant: "contained", color: "warning" }}
                        isSorted
                        noEndBorder
                      />
                    </MDBox>
                  </Card>
                </TabPanel>
                <TabPanel value={tabValue} index={4}>
                  <Card>
                    <MDBox>
                      <DataTable
                        entriesPerPage={(false, { defaultValue: 10, entries: [5, 10, 25, 50] })}
                        canSearch
                        showTotalEntries
                        table={{ columns: columnsUser, rows: rowsUser }}
                        pagination={{ variant: "contained", color: "warning" }}
                        isSorted
                        noEndBorder
                      />
                    </MDBox>
                  </Card>
                </TabPanel>
                <TabPanel value={tabValue} index={5}>
                  <Card>
                    <MDBox>
                      <DataTable
                        entriesPerPage={(false, { defaultValue: 10, entries: [5, 10, 25, 50] })}
                        canSearch
                        showTotalEntries
                        table={{ columns: columnsTemplate, rows: rowsTemplate }}
                        pagination={{ variant: "contained", color: "warning" }}
                        isSorted
                        noEndBorder
                      />
                    </MDBox>
                  </Card>
                </TabPanel>
                <TabPanel value={tabValue} index={6}>
                  <Card>
                    <MDBox
                      sx={{ position: "relative", mx: 3, py: 2, px: 2 }}
                      // component="form"
                      // onSubmit={handleSubmit}
                    >
                      <Grid
                        container
                        spacing={3}
                        justifyContent="left"
                        alignItems="left"
                        height="100%"
                      >
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          container
                          justifyContent="flex-end"
                        >
                          <MDButton
                            variant="contained"
                            onClick={() => setEditJsonDataPago()}
                            sx={{
                              background: "linear-gradient(45deg, #FFC300 30%, #FFC300 90%)",
                              color: "#FFFFFF",
                            }}
                          >
                            <Icon>edit</Icon>&nbsp;Editar Datos de Recaudación
                          </MDButton>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                          <MDInput
                            variant="standard"
                            type="text"
                            label="Codigo de Comercio"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            value={newValueCodigoComercio}
                            onChange={(e) => {
                              setNewValueCodigoComercio(e.target.value);
                            }}
                            disabled={activeDatosPago}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                          <MDInput
                            variant="standard"
                            type="text"
                            label="Valor de la Clave"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            value={newValueValorClave}
                            onChange={(e) => {
                              setNewValueValorClave(e.target.value);
                            }}
                            multiline
                            rows={4}
                            disabled={activeDatosPago}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                          <Tooltip
                            title={newValuePaymentStatus ? "Deshabilitar" : "Habilitar"}
                            disableInteractive
                          >
                            <Switch
                              disabled={activeDatosPago}
                              checked={newValuePaymentStatus}
                              onChange={() => setNewValuePaymentStatus(!newValuePaymentStatus)}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </MDBox>
                  </Card>
                </TabPanel>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {renderNotificacion}
      {renderModalContacts}
      {renderModalUpload}
      <Loading load={load} />
      <Footer />
    </DashboardLayout>
  );
}

export default ManagerCrearEmpresa;
