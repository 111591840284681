/* eslint-disable jsx-a11y/label-has-associated-control */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// @mui material components
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Loading from "components/Loading";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// API Request
import APIRequest from "api/request";

function XDocGlobalPermisoXSLTForm() {
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  const [load, setLoad] = useState(false);
  const [jsonData, setJsonData] = useState([]);
  const { pathname } = useLocation();
  const {
    state: { route, element, jsonTempData, country, xslt },
  } = useLocation();
  const navigate = useNavigate();

  const [elementIsNew, setElementIsNew] = useState(true);
  const [newValuePais, setNewValuePais] = useState(null);
  const [newValueXSLT, setNewValueXSLT] = useState("");
  const [newValueUser, setNewValueUser] = useState("");

  const [selectData, setSelectData] = useState({
    pais: [
      { id: "ar", nombre: "Argentina" },
      { id: "gt", nombre: "Guatemala" },
    ],
  });

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };
  const showNotificationError = (message) => {
    configNotification("error", "warning", "Notificación", message, "", true);
  };
  const showNotificationSuccess = (message) => {
    configNotification("warning", "check", "Notificación", message, "", true);
  };

  const closeModal = (tempElement) => {
    setNewValuePais(null);
    setNewValueXSLT("");
    setNewValueUser("");
    navigate(route, {
      state: {
        newElement: tempElement,
        country: newValuePais,
        xslt: newValueXSLT,
        isNew: elementIsNew,
      },
    });
  };

  const saveDataModal = async () => {
    const body = {
      XsltName: newValueXSLT, // "11111111-2222-1111-2222-031111111114"
      CountryId: newValuePais.id,
      User: userInfo.correo,
      AccountCode: "",
      UserNames: [newValueUser.trim()],
      Delete: false,
    };

    if (
      (jsonData.map((item) => item.user).indexOf(newValueUser.trim()) === -1 && !elementIsNew) ||
      elementIsNew
    ) {
      let nuevoId = 0;
      for (let index = 0; index < jsonData.length; index += 1) {
        if (!elementIsNew) {
          body.UserNames.push(jsonData[index].user.trim());
          nuevoId = jsonData[index].id > nuevoId ? jsonData[index].id : nuevoId;
        }
      }

      try {
        nuevoId += 1;
        const apiResponse = await (
          await APIRequest(
            "POST",
            `/api/xslt/${!elementIsNew ? "UpdateGlobalXslt" : "CreateGlobalXslt"}`,
            body
          )
        ).json();

        if (apiResponse.success) {
          const tempElement = {
            id: nuevoId,
            xslt: newValueXSLT,
            pais: newValuePais,
            user: newValueUser,
          };
          const elements = [tempElement, ...jsonData];

          setJsonData(elements);
          /* showNotificationSuccess(
            `Se agregó el usuario '${newValueUser}' para el XSLT '${newValueXSLT}'.`
          ); */
          closeModal(tempElement);
        } else {
          const apiData = await apiResponse.json();
          const errorMessage = {
            message: apiData.message,
          };
          throw errorMessage;
        }
      } catch (e) {
        showNotificationError(`Ocurrió un error al eliminar el usuario del registro: ${e.message}`);
      }
    } else {
      showNotificationSuccess("El usuario ya está autorizado");
    }
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );

  const setEditJsonData = (jsonElement) => {
    if (element) {
      setNewValuePais(jsonElement.pais);
      setNewValueXSLT(jsonElement.xslt);
      setNewValueUser(jsonElement.user);
    }
    setJsonData(jsonTempData);
    setNewValuePais(country);
    setNewValueXSLT(xslt);
  };

  const getCountriesData = async () => {
    const tempJsonData = [];
    const urlComplete = `/api/Pais/GetXsltCountries/${userInfo.correo}`;
    const apiResponse = await (await APIRequest("GET", urlComplete, null)).json();

    for (let index = 0; index < apiResponse.length; index += 1) {
      tempJsonData.push({
        id: apiResponse[index].codigo.toLowerCase(),
        nombre: apiResponse[index].nombre,
      });
    }

    setSelectData({
      pais: tempJsonData,
    });

    return tempJsonData;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoad(true);
    saveDataModal();
    setLoad(false);
  };

  useEffect(async () => {
    setLoad(true);
    try {
      await getCountriesData();
      setElementIsNew(element === null);
      setEditJsonData(element);
    } catch {
      showNotificationError(
        "Susedio un error inesperado al cargar la página, por favor carguela de nuevo"
      );
    }
    setLoad(false);
  }, [pathname]);

  return (
    <DashboardLayout>
      <DashboardNavbar breadCrumb={route.split("/").slice(1)} />
      <MDBox pt={3} pb={3} component="form" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MDBox pb={4}>
              <MDTypography variant="subtitle" fontWeight="bold">
                <Icon
                  sx={{ cursor: "pointer", fontWeight: "bold" }}
                  onClick={() =>
                    navigate(route, {
                      state: {
                        newElement: null,
                        country: newValuePais,
                        xslt: newValueXSLT,
                        isNew: elementIsNew,
                      },
                    })
                  }
                >
                  arrow_back
                </Icon>
                {"   "}
                Permite dar permiso a un usuario para un XSLT
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox sx={{ position: "relative", mx: 3, py: 2, px: 2 }}>
                <Grid container spacing={3} justifyContent="left" alignItems="left" height="100%">
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <Autocomplete
                      value={newValuePais}
                      onChange={(event, newValue) => {
                        setNewValuePais(newValue);
                      }}
                      disabled={!elementIsNew}
                      options={selectData.pais}
                      getOptionLabel={(option) => option.nombre}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderInput={(params) => (
                        <MDInput
                          variant="standard"
                          type="text"
                          label="Pais"
                          fullWidth
                          required
                          InputLabelProps={{ shrink: true }}
                          {...params}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <MDInput
                      variant="standard"
                      type="text"
                      label="XSLT"
                      fullWidth
                      required
                      disabled={!elementIsNew}
                      InputLabelProps={{ shrink: true }}
                      value={newValueXSLT}
                      onChange={(e) => {
                        setNewValueXSLT(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <MDInput
                      variant="standard"
                      type="text"
                      label="Usuario"
                      fullWidth
                      required
                      InputLabelProps={{ shrink: true }}
                      value={newValueUser}
                      onChange={(e) => {
                        setNewValueUser(e.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="left" alignItems="left" height="100%">
              <Grid item xs={12} sm={12} md={6} lg={4} xl={10} />
              <Grid item xs={12} sm={12} md={6} lg={4} xl={2} container justifyContent="flex-end">
                <MDButton
                  variant="contained"
                  type="submit"
                  sx={{
                    background: "linear-gradient(45deg, #099268 30%, #099268 90%)",
                    color: "#FFFFFF",
                  }}
                >
                  <Icon>save</Icon>&nbsp;Guardar
                </MDButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
      {renderNotificacion}
      <Loading load={load} />
      <Footer />
    </DashboardLayout>
  );
}

export default XDocGlobalPermisoXSLTForm;
