/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// @mui material components
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDInput from "components/MDInput";
import MDDatePicker from "components/MDDatePicker";
import MDButton from "components/MDButton";
import Loading from "components/Loading";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import data from "views/soporte/ConfiguracionEmpresa/BusquedaCorreosRecibidos/data";

// API Request
import APIRequest from "api/request";
import APIRequestGeneric from "api/requestGeneric";

function BusquedaCorreosRecibidos() {
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  const host = process.env.REACT_APP_API_URL;
  const basicAuth = `${process.env.REACT_APP_API_ENEX_USER}:${process.env.REACT_APP_API_ENEX_PASSWORD}`;
  const basicAuthorization = `Basic ${btoa(basicAuth)}`;

  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  const [load, setLoad] = useState(false);
  const [jsonData, setJsonData] = useState([]);
  const { pathname } = useLocation();

  const [newValuePais, setNewValuePais] = useState(null);
  const [newValueDateStart, setNewValueDateStart] = useState("");
  const [newValueDateEnd, setNewValueDateEnd] = useState("");
  const [newValueFrom, setNewValueFrom] = useState("");
  const [newValueTo, setNewValueTo] = useState("");

  const [selectData, setSelectData] = useState({
    pais: [
      { id: "cr", nombre: "Costa rica" },
      { id: "ec", nombre: "Ecuador" },
      { id: "pe", nombre: "Perú" },
      { id: "cl", nombre: "Chile" },
      { id: "mx", nombre: "México" },
      { id: "gt", nombre: "Guatemala" },
      { id: "co", nombre: "Colombia" },
      { id: "pa", nombre: "Panamá" },
    ],
  });

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };
  const showNotificationError = (message) => {
    configNotification("error", "warning", "Notificación", message, "", true);
  };
  const showNotificationSuccess = (message) => {
    configNotification("warning", "check", "Notificación", message, "", true);
  };

  const getJsonData = async () => {
    try {
      const dateFrom = newValueDateStart.length > 0 ? `dateFrom=${newValueDateStart}` : "dateFrom";
      const dateTo = newValueDateEnd.length > 0 ? `dateTo=${newValueDateEnd}` : "dateTo";
      const emailFrom = newValueFrom.length > 0 ? `emailFrom=${newValueFrom}` : "emailFrom";
      const emailTo = newValueTo.length > 0 ? `emailTo=${newValueTo}` : "emailTo";
      const urlComplete = `${host}/api/ReceptionAttachment/GetEmails?country=${newValuePais.id}&${dateFrom}&${dateTo}&${emailFrom}&${emailTo}`;
      const apiResponse = await APIRequestGeneric(
        "GET",
        urlComplete,
        null,
        false,
        basicAuthorization
      );

      if (apiResponse.status === 200) {
        const jsonResponse = await apiResponse.json();
        const dataResponse = JSON.parse(jsonResponse.Data);
        const tempJsonData = [];

        for (let index = 0; index < dataResponse.length; index += 1) {
          const tempElement = {
            id: dataResponse[index].IdReception,
            fechaRecepcion: dataResponse[index].DateTimeReception.substring(0, 10),
            from: dataResponse[index].EmailFrom,
            to: dataResponse[index].EmailTo,
            asunto: dataResponse[index].SubjectEmail,
            eml: dataResponse[index].EmlLnk,
            recibido: dataResponse[index].Received,
            almacenado: dataResponse[index].Stored,
            adjunto: dataResponse[index].HasAttachment,
            spam: dataResponse[index].Spam,
            adjuntos: [],
            mostrarAdjuntos: false,
          };

          for (let idx = 0; idx < dataResponse[index].ReceptionAttachments.length; idx += 1) {
            tempElement.adjuntos.push({
              nombre: dataResponse[index].ReceptionAttachments[idx].AttName,
              link: dataResponse[index].ReceptionAttachments[idx].DownloadLnk,
            });
          }

          tempJsonData.push(tempElement);
        }

        setJsonData(tempJsonData);
        showNotificationSuccess("Se completó la búsqueda exitosamente");
      } else if (apiResponse.status === 204) {
        showNotificationSuccess("No se encontro información");
      } else {
        showNotificationError(`Error, estado: ${apiResponse.status}`);
      }
    } catch {
      showNotificationError("Error Inesperado");
    }
  };
  const saveDataModal = async () => {
    if (new Date(newValueDateEnd) < new Date(newValueDateStart)) {
      showNotificationError("La fecha hasta no puede ser menor a la fecha desde.");
    } else {
      setLoad(true);
      await getJsonData();
      setLoad(false);
      showNotificationSuccess("Se completó la búsqueda exitosamente");
    }
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );

  const setEditJsonData = async (jsonElement) => {
    try {
      const urlComplete = `${host}/api/ReceptionAttachment/ReprocessEmail`;
      const body = {
        IdReception: jsonElement.id,
        User: userInfo.correo,
      };
      const apiResponse = await APIRequestGeneric(
        "POST",
        urlComplete,
        body,
        false,
        basicAuthorization
      );

      if (apiResponse.status === 200 || apiResponse.status === 201 || apiResponse.ok) {
        const jsonResponse = await apiResponse.json();

        if (jsonResponse.Code) {
          showNotificationSuccess("Se reenvió el correo exitosamente");
        } else {
          showNotificationSuccess(
            `No se reenvió el correo, codigo de respuesta: ${jsonResponse.Code}`
          );
        }
      } else {
        showNotificationError(
          `No se reenvió el correo, codigo de respuesta: ${apiResponse.status}`
        );
      }
    } catch {
      showNotificationError("Error Inesperado");
    }
  };

  const setMostrarAdjuntos = (valueId) => {
    const tempJsonData = [...jsonData];

    const index = jsonData.map((item) => item.id).indexOf(valueId);
    tempJsonData[index].mostrarAdjuntos = !tempJsonData[index].mostrarAdjuntos;

    setJsonData(tempJsonData);
  };

  const getCountriesData = async () => {
    const tempJsonData = [];
    const apiResponse = await (await APIRequest("GET", "/api/Pais/Todos", null)).json();

    if (apiResponse.length > 0) {
      for (let index = 0; index < apiResponse.length; index += 1) {
        tempJsonData.push({
          id: apiResponse[index].codigo.toLowerCase(),
          nombre: apiResponse[index].nombre,
        });
      }

      setSelectData({
        pais: tempJsonData,
        status: selectData.status,
      });
    }

    return tempJsonData;
  };

  const descargarEML = async (jsonElement) => {
    try {
      const nameFile = `${jsonElement.eml.split("/")[5]}`;
      const nameFolder = `${jsonElement.eml.split("/")[4]}`;
      const urlComplete = `${host}/api/ReceptionAttachment/GetEmailFile/${nameFolder}/${nameFile}`;
      const apiResponse = await APIRequestGeneric(
        "GET",
        urlComplete,
        null,
        false,
        basicAuthorization
      );

      if (!apiResponse.ok) {
        const errorMessage = {
          code: apiResponse.status,
          message: "La solicitud no pudo completarse con éxito",
        };
        throw errorMessage;
      }

      const blob = await apiResponse.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${nameFile}.eml`;
      document.body.appendChild(a);
      a.click();

      return true;
    } catch (exception) {
      showNotificationError(exception.message);
      return false;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    saveDataModal();
  };

  useEffect(() => {
    getCountriesData();
  }, [pathname]);

  const { columns, rows } = data(jsonData, setEditJsonData, setMostrarAdjuntos, descargarEML);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={3}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <MDTypography variant="subtitle" fontWeight="bold">
              Busqueda Correos Recibidos
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox
                sx={{ position: "relative", mx: 3, py: 2, px: 2 }}
                component="form"
                onSubmit={handleSubmit}
              >
                <Grid container spacing={3} justifyContent="left" alignItems="left" height="100%">
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <Autocomplete
                      value={newValuePais}
                      onChange={(event, newValue) => {
                        setNewValuePais(newValue);
                      }}
                      options={selectData.pais}
                      getOptionLabel={(option) => option.nombre}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderInput={(params) => (
                        <MDInput
                          variant="standard"
                          type="text"
                          label="Pais"
                          fullWidth
                          required
                          InputLabelProps={{ shrink: true }}
                          {...params}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <MDDatePicker
                      fullWidth
                      input={{
                        variant: "standard",
                        label: "Fecha Inicio",
                        fullWidth: true,
                        InputLabelProps: { shrink: true },
                      }}
                      value={newValueDateStart}
                      onChange={(e) => {
                        setNewValueDateStart(e[0]);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <MDDatePicker
                      fullWidth
                      input={{
                        variant: "standard",
                        label: "Fecha Fin",
                        fullWidth: true,
                        InputLabelProps: { shrink: true },
                      }}
                      value={newValueDateEnd}
                      onChange={(e) => {
                        setNewValueDateEnd(e[0]);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <MDInput
                      variant="standard"
                      type="text"
                      label="From"
                      fullWidth
                      required
                      InputLabelProps={{ shrink: true }}
                      value={newValueFrom}
                      onChange={(e) => {
                        setNewValueFrom(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <MDInput
                      variant="standard"
                      type="text"
                      label="To"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      value={newValueTo}
                      onChange={(e) => {
                        setNewValueTo(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={9}
                    container
                    justifyContent="flex-end"
                  >
                    <MDButton
                      variant="contained"
                      type="submit"
                      sx={{
                        background: "linear-gradient(45deg, #1971C2 30%, #1971C2 90%)",
                        color: "#FFFFFF",
                      }}
                    >
                      <Icon>search</Icon>&nbsp;Buscar
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox>
                <DataTable
                  entriesPerPage={(false, { defaultValue: 10, entries: [5, 10, 25, 50] })}
                  canSearch
                  showTotalEntries
                  table={{ columns, rows }}
                  pagination={{ variant: "contained", color: "warning" }}
                  isSorted
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {renderNotificacion}
      <Loading load={load} />
      <Footer />
    </DashboardLayout>
  );
}

export default BusquedaCorreosRecibidos;
