/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import MDAvatar from "components/MDAvatar";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
// import Icon from "@mui/material/Icon";

// // Default Components
// import MDTypography from "components/MDTypography";

export default function dataTable(data) {
  const getRows = () => {
    const elements = [];
    for (let index = 0; index < data?.length; index += 1) {
      elements.push({
        hora: data[index].hora,
        envios: data[index].envios,
        aceptados: data[index].aceptados,
        rechazados: data[index].rechazados,
      });
    }

    return elements;
  };

  return {
    columns: [
      { Header: "Hora", accessor: "hora", width: "60%", align: "left" },
      { Header: "Envios", accessor: "envios", align: "center" },
      { Header: "Aceptados", accessor: "aceptados", align: "center" },
      { Header: "Rechazados", accessor: "rechazados", align: "center" },
    ],

    rows: getRows(),
  };
}

// Setting default values for the props of MDBadge
dataTable.defaultProps = {
  jsonData: [],
};

// Typechecking props of the data
dataTable.propTypes = {
  jsonData: PropTypes.objectOf(PropTypes.array),
};
