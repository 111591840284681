/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import BKModal from "components/BKModal";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
// import MDInput from "components/MDInput";
import MDDatePicker from "components/MDDatePicker";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import data from "views/soporte/ContingenciaPY/data";

// API Request
import APIRequest from "api/request";

function ContigenciaPY() {
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  const [menu, setMenu] = useState(null);
  const [modal, setModal] = useState(false);
  const [jsonData, setJsonData] = useState([]);
  const { pathname } = useLocation();

  // const [elementIsNew, setElementIsNew] = useState(true);
  const pais = { Id: 2, Nombre: "Paraguay" };
  const [newValueDateStart, setNewValueDateStart] = useState(Date.now);
  const [newValueDateEnd, setNewValueDateEnd] = useState(Date.now);

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };

  const showNotificationError = (message) => {
    configNotification("error", "warning", "Notificación", message, "", true);
  };

  const showNotificationSucces = (message) => {
    configNotification("warning", "check", "Notificación", message, "", true);
  };

  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);
  const openAddNewElementModal = () => {
    // setElementIsNew(true);
    setMenu(null);
    setModal(true);
  };
  const closeModal = () => {
    setModal(false);
    // setNewValueId("");
    setNewValueDateStart(Date.now);
    setNewValueDateEnd(Date.now);
  };
  const saveDataModal = async () => {
    if (new Date(newValueDateEnd) >= new Date(newValueDateStart)) {
      const tempJsonData = jsonData;
      const today = new Date();
      const startDate = new Date(newValueDateStart);
      const endDate = new Date(newValueDateEnd);
      const newStatus = { id: 1, nombre: "Activado" };
      const body = {
        fechaAccion: today,
        fechaInicio: startDate,
        fechaFin: endDate,
        estado: newStatus.id,
        usuario: userInfo.correo,
        pais: pais.Id,
      };
      try {
        const apiResponse = await APIRequest("POST", "/Api/Contigencia", body);

        if (apiResponse.status === 200) {
          const dataResponse = await apiResponse.json();
          tempJsonData.push({
            id: dataResponse,
            estado: {
              id: 1,
              nombre: "Pendiente",
            },
            fechaInicio: startDate,
            fechaFin: endDate,
            usuario: userInfo.correo,
            fechaAccion: today,
          });

          showNotificationSucces("Se completó el registro exitosamente");
        } else {
          const errorMessage = {
            code: apiResponse.status,
            message: `Error: ${apiResponse.status}`,
          };
          throw errorMessage;
        }

        setJsonData(tempJsonData);
      } catch (e) {
        configNotification(
          "error",
          "warning",
          "Notificación",
          `Ocurrió un error al crear la contigencia: ${e.message}`,
          "",
          true
        );
      }
      closeModal();
    } else if (new Date(newValueDateEnd) < new Date(newValueDateStart)) {
      showNotificationError("La fecha hasta no puede ser menor a la fecha desde.");
    }
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={openAddNewElementModal}>Activar Contigencia</MenuItem>
    </Menu>
  );

  const renderModal = (
    <BKModal
      closeModal={closeModal}
      saveChanges={saveDataModal}
      open={modal}
      title="Activar Contigencia"
    >
      <MDBox sx={{ position: "relative", mx: 3, py: 2, px: 2 }}>
        <Grid container spacing={3} justifyContent="left" alignItems="left" height="100%">
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <MDDatePicker
              fullWidth
              options={{ enableTime: true, dateFormat: "d/m/Y H:i:S" }}
              input={{
                variant: "standard",
                label: "Desde",
                required: true,
              }}
              InputLabelProps={{ shrink: true }}
              value={newValueDateStart}
              onChange={(e) => {
                setNewValueDateStart(e[0]);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <MDDatePicker
              fullWidth
              options={{ enableTime: true, dateFormat: "d/m/Y H:i:S" }}
              input={{
                variant: "standard",
                label: "Hasta",
                required: true,
              }}
              InputLabelProps={{ shrink: true }}
              value={newValueDateEnd}
              onChange={(e) => {
                setNewValueDateEnd(e[0]);
              }}
            />
          </Grid>
        </Grid>
      </MDBox>
    </BKModal>
  );

  const getJsonData = async () => {
    const tempJsonData = [];
    const filtro = { fecha: null, estado: null, pais: pais.Id };
    const apiResponse = await (await APIRequest("Post", "/Api/Contigencia/Todos", filtro)).json();
    for (let index = 0; index < apiResponse.length; index += 1) {
      const fechaInicio = new Date(apiResponse[index].fechaInicio);
      const fechaFin = new Date(apiResponse[index].fechaFin);
      const fechaAccion = new Date(apiResponse[index].fechaAccion);
      let estado = "Desactivado";
      if (apiResponse[index].estado === 1) estado = "Pendiente";
      else if (apiResponse[index].estado === 2) estado = "Activado";
      tempJsonData.push({
        id: apiResponse[index].id,
        estado: {
          id: apiResponse[index].estado,
          nombre: estado,
        },
        fechaInicio,
        fechaFin,
        usuario: apiResponse[index].usuarios.join(),
        fechaAccion,
      });
    }

    setJsonData(tempJsonData);
  };

  const setEditJsonData = async (valueId) => {
    const today = new Date();
    const index = jsonData.map((item) => item.id).indexOf(valueId);

    const tempJsonData = jsonData;
    let startDate = new Date(tempJsonData[index].fechaInicio);
    let endtDate = new Date(tempJsonData[index].fechaFin);
    const newStatus = tempJsonData[index].estado.id === 1 ? 2 : 3;
    let message = "";
    if (newStatus === 2) {
      startDate = today;
      message = "Se activó exitosamente";
    } else if (newStatus === 3) {
      endtDate = today;
      message = "Se desactivó exitosamente";
    }
    const body = {
      fechaAccion: today,
      fechaInicio: startDate,
      fechaFin: endtDate,
      estado: newStatus,
      usuario: userInfo.correo,
    };
    let estado = "Desactivado";
    if (newStatus === 1) estado = "Pendiente";
    else if (newStatus === 2) estado = "Activado";

    let usuario = `${tempJsonData[index].usuario}`;
    if (usuario !== userInfo.correo) usuario = `${usuario}, ${userInfo.correo}`;
    const editElement = {
      id: tempJsonData[index].id,
      estado: {
        id: newStatus,
        nombre: estado,
      },
      fechaInicio: startDate,
      fechaFin: endtDate,
      usuario,
      fechaAccion: today,
    };

    try {
      const apiResponse = await APIRequest(
        "PATCH",
        `/Api/Contigencia/${tempJsonData[index].id}`,
        body
      );

      if (apiResponse.status === 200) {
        tempJsonData[index] = editElement;
        showNotificationSucces(message);
      } else {
        const errorMessage = {
          code: apiResponse.status,
          message: `Error: ${apiResponse.status}`,
        };
        throw errorMessage;
      }
      setJsonData(tempJsonData);
    } catch (e) {
      configNotification(
        "error",
        "warning",
        "Notificación",
        `Ocurrió un error en la contigencia: ${e.message}`,
        "",
        true
      );
    }
  };

  useEffect(() => {
    getJsonData();
  }, [pathname]);

  const { columns, rows } = data(jsonData, setEditJsonData);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {renderModal}
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                <MDBox>
                  <MDTypography variant="h4" gutterBottom color="warning">
                    Activar / Desactivar Contingencia para Paraguay
                  </MDTypography>
                </MDBox>
                <MDBox color="text" px={2}>
                  <Icon
                    sx={{ cursor: "pointer", fontWeight: "bold" }}
                    fontSize="small"
                    onClick={openMenu}
                  >
                    more_vert
                  </Icon>
                </MDBox>
                {renderMenu}
              </MDBox>
              <MDBox>
                <DataTable
                  entriesPerPage={(false, { defaultValue: 10, entries: [5, 10, 25, 50] })}
                  canSearch
                  showTotalEntries
                  table={{ columns, rows }}
                  pagination={{ variant: "contained", color: "warning" }}
                  isSorted
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {renderNotificacion}
      <Footer />
    </DashboardLayout>
  );
}

export default ContigenciaPY;
