/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import BKShowModal from "components/BKShowModal";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Loading from "components/Loading";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import data from "views/consultoria/ConfiguracionEmpresa/politicasContraseniaSBX/data";

// API Request
import APIRequestGeneric from "api/requestGeneric";

function PoliticasContraseniaSBX() {
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  const host = process.env.REACT_APP_API_URL;
  const basicAuth = `${process.env.REACT_APP_API_ADMIN_SBX_USER}:${process.env.REACT_APP_API_ADMIN_SBX_PASSWORD}`;
  const basicAuthorization = `Basic ${btoa(basicAuth)}`;
  const hostAdmin = process.env.REACT_APP_API_URL;

  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  const [modalPalClave, setModalPalClave] = useState(false);
  const [load, setLoad] = useState(false);
  const [jsonData, setJsonData] = useState([]);
  const { pathname } = useLocation();
  const route = pathname;
  const navigate = useNavigate();

  const [newValuePalabrasClaveString, setNewValuePalabrasClaveString] = useState("");

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };
  const showNotificationError = (message) => {
    configNotification("error", "warning", "Notificación", message, "", true);
  };
  const showNotificationSuccess = (message) => {
    configNotification("warning", "check", "Notificación", message, "", true);
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );

  const closeModal = () => {
    setModalPalClave(false);
  };

  const renderModalPalClave = (
    <BKShowModal closeModal={closeModal} open={modalPalClave} title="Palabras Clave">
      <MDBox sx={{ position: "relative", mx: 3, py: 2, px: 2 }}>
        <Grid container spacing={3} justifyContent="left" alignItems="left" height="100%">
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <MDInput
              variant="standard"
              type="text"
              label="Palabras Clave"
              fullWidth
              disabled
              inputProps={{ maxLength: 500 }}
              InputLabelProps={{ shrink: true }}
              value={newValuePalabrasClaveString}
              onChange={(e) => {
                const char = e.target.value.charAt(e.target.value.length - 1);
                const charCode = char.charCodeAt(0);

                if (
                  (charCode >= 65 && charCode <= 90) ||
                  (charCode >= 97 && charCode <= 122) ||
                  charCode === 59 ||
                  char === ""
                ) {
                  setNewValuePalabrasClaveString(e.target.value);
                }
              }}
            />
          </Grid>
        </Grid>
      </MDBox>
    </BKShowModal>
  );

  const getJsonData = async (countries) => {
    const tempJsonData = [];
    const urlComplete = `${host}/api/PasswordPolicies/GetInformationPasswordPolicies`;
    const apiResponse = await (
      await APIRequestGeneric("GET", urlComplete, null, false, basicAuthorization)
    ).json();

    if ((apiResponse.Code === 200 || apiResponse.Code === 201) && apiResponse.IsSuccessful) {
      const dataResponse = JSON.parse(apiResponse.Data);

      for (let index = 0; index < dataResponse.length; index += 1) {
        const dataValue =
          dataResponse[index].Value !== null
            ? dataResponse[index].Value.replaceAll('\\"', '"')
            : "";
        const values = dataValue !== "" && dataValue !== null ? JSON.parse(dataValue) : {};
        let passwordBlackList =
          dataResponse[index].PasswordBlackList !== "null" &&
          dataResponse[index].PasswordBlackList !== null
            ? dataResponse[index].PasswordBlackList
            : "";
        passwordBlackList = passwordBlackList.replaceAll(/(?:\\[rn]|[\r\n]+)+/g, ";");
        passwordBlackList = passwordBlackList.replaceAll('"', "");
        passwordBlackList =
          passwordBlackList[0] === ";" ? passwordBlackList.substring(1) : passwordBlackList;

        if (dataResponse[index].AccountCode !== "") {
          tempJsonData.push({
            id: dataResponse[index].PartitionKey,
            active: dataResponse[index].Active,
            taxId: dataResponse[index].AccountCode,
            pais: countries[
              countries.map((item) => item.id).indexOf(dataResponse[index].CountryId.toLowerCase())
            ],
            longitudMinima: values.PasswordLong ? values.PasswordLong : 0,
            noMayusculas: values.PasswordMinUppercase ? values.PasswordMinUppercase : 0,
            noMinusculas: values.PasswordMinLowercase ? values.PasswordMinLowercase : 0,
            noCaracteresEspe: values.PasswordMinSpecialChars ? values.PasswordMinSpecialChars : 0,
            noNumeros: values.PasswordNumbers ? values.PasswordNumbers : 0,
            vigencia: values.PasswordDaysToExpire ? values.PasswordDaysToExpire : 0,
            historial: values.PasswordDaysHistorical ? values.PasswordDaysHistorical : 0,
            bloqueoInactividad: values.PasswordUserInactive ? values.PasswordUserInactive : 0,
            palabrasClave: values.PasswordBlackList ? values.PasswordBlackList : false,
            palabrasClaveString: passwordBlackList,
            usuario: dataResponse[index].User ? dataResponse[index].User : "",
          });
        }
      }

      setJsonData(tempJsonData);
    } else {
      showNotificationError(`Error: ${apiResponse.Message}`);
    }
  };

  const deleteJsonData = async (id) => {
    if (window.confirm("¿Quiere eliminar el registro?")) {
      try {
        const index = jsonData.map((item) => item.id).indexOf(id);
        const elements = [...jsonData];
        const urlComplete = `${host}/api/PasswordPolicies/DeleteInformationPasswordPoliciesByCompany`;
        const body = {
          PartitionKey: elements[index].id,
          RowKey: "PasswordPolicies",
        };

        const apiResponse = await (
          await APIRequestGeneric("DELETE", urlComplete, body, false, basicAuthorization)
        ).json();

        if (apiResponse.Code === 200 && apiResponse.IsSuccessful) {
          const nombreElemento = elements[index].taxId;
          elements.splice(index, 1);
          setJsonData(elements);

          showNotificationSuccess(`Se desactivó el registro '${nombreElemento}' exitosamente.`);
        } else {
          showNotificationError(`Error: ${apiResponse.Message}`);
        }
      } catch (e) {
        showNotificationError(`Ocurrió un error al desactivar el Tax ID: ${e.message}`);
      }
    }
  };

  const setEditJsonData = (element) => {
    navigate("/PoliticasContrasenia/SBXForm", { state: { route, element } });
  };

  const getCountriesData = async () => {
    const tempJsonData = [];
    const apiResponse = await (
      await APIRequestGeneric("GET", `${hostAdmin}/api/Pais/Todos`, null, true, null)
    ).json();

    for (let index = 0; index < apiResponse.length; index += 1) {
      tempJsonData.push({
        id: apiResponse[index].codigo.toLowerCase(),
        nombre: apiResponse[index].nombre,
      });
    }

    return tempJsonData;
  };

  const updatePalClaveJsonData = async (valueId) => {
    const tempJsonData = jsonData;

    const index = tempJsonData.map((item) => item.id).indexOf(valueId);
    tempJsonData[index].palabrasClave = !tempJsonData[index].palabrasClave;
    tempJsonData[index].usuario = userInfo.correo;

    const urlComplete = `${host}/api/PasswordPolicies/UpdateInformationPasswordPoliciesByCompany`;
    const body = {
      PartitionKey: tempJsonData[index].id,
      RowKey: "PasswordPolicies", // Obligatorio
      AccountCode: tempJsonData[index].taxId,
      CountryId: tempJsonData[index].pais.id,
      Key: "PasswordPolicies",
      Value: `{"PasswordLong": ${tempJsonData[index].longitudMinima},"PasswordMinUppercase":${tempJsonData[index].noMayusculas},"PasswordMinLowercase":${tempJsonData[index].noMinusculas},"PasswordMinSpecialChars":${tempJsonData[index].noCaracteresEspe},"PasswordDaysToExpire":${tempJsonData[index].vigencia},"PasswordDaysHistorical":${tempJsonData[index].historial},"PasswordUserInactive":${tempJsonData[index].bloqueoInactividad},"PasswordNumbers":${tempJsonData[index].noNumeros},"PasswordBlackList":${tempJsonData[index].palabrasClave}}`,
      User: userInfo.correo,
      Active: true,
      PasswordBlackList: tempJsonData[index].palabrasClaveString.toString().replaceAll(";", "\r\n"),
    };

    const apiResponse = await (
      await APIRequestGeneric("PUT", urlComplete, body, false, basicAuthorization)
    ).json();

    if (apiResponse.Code === 200 && apiResponse.IsSuccessful) {
      setJsonData(tempJsonData);
      showNotificationSuccess(
        `Registro actualizó el estado de las palabras clave del key ${tempJsonData[index].id} exitosamente`
      );
    } else {
      showNotificationError(`Error: ${apiResponse.Message}`);
    }
  };

  const getPalClaveJsonData = (palabrasClaveString) => {
    setNewValuePalabrasClaveString(palabrasClaveString);

    setModalPalClave(true);
  };

  useEffect(async () => {
    setLoad(true);
    const tempCountries = await getCountriesData();
    await getJsonData(tempCountries);
    setLoad(false);
  }, [pathname]);

  const { columns, rows } = data(
    jsonData,
    deleteJsonData,
    setEditJsonData,
    updatePalClaveJsonData,
    getPalClaveJsonData
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {renderModalPalClave}
      <MDBox pt={3} pb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MDTypography variant="subtitle" fontWeight="bold">
              Permite registrar Políticas de contraseña para un TaxID
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="left" alignItems="left" height="100%">
              <Grid item xs={12} sm={12} md={6} lg={4} xl={10} />
              <Grid item xs={12} sm={12} md={6} lg={4} xl={2} container justifyContent="flex-end">
                <MDButton
                  variant="contained"
                  onClick={() => navigate("/PoliticasContrasenia/SBXForm", { state: { route } })}
                  sx={{
                    background: "linear-gradient(45deg, #1971C2 30%, #1971C2 90%)",
                    color: "#FFFFFF",
                  }}
                >
                  <Icon>add_circle_outline</Icon>&nbsp;Agregar
                </MDButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox>
                <DataTable
                  entriesPerPage={(false, { defaultValue: 10, entries: [5, 10, 25, 50] })}
                  canSearch
                  showTotalEntries
                  table={{ columns, rows }}
                  pagination={{ variant: "contained", color: "warning" }}
                  isSorted
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {renderNotificacion}
      <Loading load={load} />
      <Footer />
    </DashboardLayout>
  );
}

export default PoliticasContraseniaSBX;
