// eslint-disable-next-line import/prefer-default-export
export function reduceFun(state, action) {
  if (action.type === "INITIAL_STATE") {
    return action.data;
  }

  return state.map((item) =>
    item.id === state[action.ind].id ? { ...item, [action.type]: action.payload } : item
  );
}
