/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState, useReducer } from "react";
import { useNavigate, useLocation } from "react-router-dom";

// @mui material components
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import BKModal from "components/BKModal";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDInput from "components/MDInput";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// API Request
import APIRequest from "api/request";

// Data
import data from "views/admin/profileFunctionalities/data";
import INITIAL_STATE from "./components/initState";
import parseInitialState from "./components/parseInitialState";
import { reduceFun } from "./components/reduceFun";

function ProfileFunctionalities() {
  const areaSelected = JSON.parse(sessionStorage.getItem("idAreaSelected"));

  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  const [menu, setMenu] = useState(null);
  const [modalMoveFunctionality, setModalMoveFunctionality] = useState(false);
  const [jsonColumns, setJsonColumns] = useState([]);
  const [jsonData, setJsonData] = useReducer(reduceFun, INITIAL_STATE, parseInitialState);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  // eslint-disable-next-line no-unused-vars
  const [newValueId, setNewValueId] = useState("");
  const [newValueArea, setNewValueArea] = useState(null);
  const [selectData, setSelectData] = useState({
    area: [],
  });

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };
  const showNotificationError = (message) => {
    configNotification("error", "warning", "Notificación", message, "", true);
  };
  const showNotificationSuccess = (message) => {
    configNotification("warning", "check", "Notificación", message, "", true);
  };

  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);
  const closeMoveFunctionalityModal = () => {
    setModalMoveFunctionality(false);
    setNewValueId("");
    setNewValueArea(null);
  };
  const saveMoveFunctionalityModal = async () => {
    const body = [
      {
        // id: newValueId, // jsonData[index].id,
        areaId: newValueArea.id,
        Funcionalidades: [newValueId],
      },
    ];

    const areaUnoResponse = await (
      await APIRequest("GET", `/Api/Area/${newValueArea.id}`, null)
    ).json();

    for (let index = 0; index < areaUnoResponse.funcionalidades.length; index += 1) {
      if (!body[0].Funcionalidades.includes(areaUnoResponse.funcionalidades[index].id)) {
        body[0].Funcionalidades.push(areaUnoResponse.funcionalidades[index].id);
      }
    }

    try {
      const apiResponse = await APIRequest("POST", "/Api/Area/Funcionalidades", body);

      if (apiResponse.status === 200) {
        showNotificationSuccess(`Funcionalidad asignada al area exitosamente.`);
      } else {
        const errorMessage = {
          code: apiResponse.status,
          message: `Error: ${apiResponse.status}`,
        };
        throw errorMessage;
      }
    } catch (e) {
      showNotificationError(`Ocurrió un error inesperado: ${e.message}`);
    }

    closeMoveFunctionalityModal();
  };
  const saveChangesFunctionalities = async () => {
    setMenu(null);
    const body = [];

    for (let index = 0; index < jsonColumns.length; index += 1) {
      const element = {
        PerfilId: jsonColumns[index].id,
        Funcionalidades: [],
      };

      for (let funcIdx = 0; funcIdx < jsonData.length; funcIdx += 1) {
        if (jsonData[funcIdx][jsonColumns[index].interno]) {
          element.Funcionalidades.push(jsonData[funcIdx].id);
          /* console.log(
            `Perfil: ${jsonColumns[index].nombre}, Funcionalidad: ${
              jsonData[funcIdx].nombre
            }, IsChecked: ${jsonData[funcIdx][jsonColumns[index].interno]}`
          ); */
        }
      }

      body.push(element);
    }

    try {
      const apiResponse = await APIRequest("POST", `/Api/Area/AsignarFuncionalidades`, body);
      if (apiResponse.status === 200) {
        showNotificationSuccess("Se guardó la información exitosamente.");
      } else {
        const errorMessage = {
          code: apiResponse.status,
          message: `Error: ${apiResponse.status}`,
        };
        throw errorMessage;
      }
    } catch (e) {
      showNotificationError(`Ocurrió un error: ${e.message}`);
    }
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={saveChangesFunctionalities}>Guardar Datos</MenuItem>
    </Menu>
  );

  const renderMoveFunctionalityModal = (
    <BKModal
      closeModal={closeMoveFunctionalityModal}
      saveChanges={saveMoveFunctionalityModal}
      open={modalMoveFunctionality}
      title="Mover Funcionalidad"
    >
      <MDBox sx={{ position: "relative", mx: 3, py: 2, px: 2 }}>
        <Grid container spacing={3} justifyContent="left" alignItems="left" height="100%">
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <Autocomplete
              value={newValueArea}
              onChange={(event, newValue) => {
                setNewValueArea(newValue);
              }}
              options={selectData.area}
              getOptionLabel={(option) => option.nombre}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              // disabled={userType !== 1}
              renderInput={(params) => (
                <MDInput
                  variant="standard"
                  type="text"
                  label="Area"
                  fullWidth
                  required
                  InputLabelProps={{ shrink: true }}
                  {...params}
                />
              )}
            />
          </Grid>
        </Grid>
      </MDBox>
    </BKModal>
  );

  const getJsonColumns = async () => {
    const tempJsonColumn = [];

    const apiResponse = await (
      await APIRequest("GET", `/Api/Area/${areaSelected.id}`, null)
    ).json();

    for (let index = 0; index < apiResponse.perfiles.length; index += 1) {
      if (apiResponse.perfiles[index].tipoPerfil.id > 2) {
        tempJsonColumn.push({
          id: apiResponse.perfiles[index].id,
          nombre: apiResponse.perfiles[index].nombre,
          interno: `perfil${index}`,
          funcionalidades: apiResponse.perfiles[index].funcionalidades,
        });
      }
    }

    setJsonColumns(tempJsonColumn);

    const elements = [];

    for (let index = 0; index < apiResponse.funcionalidades.length; index += 1) {
      const element = {};

      const subMenu = apiResponse.funcionalidades[index].submenu;
      element.id = apiResponse.funcionalidades[index].id;
      element.subMenu = subMenu ? subMenu.nombre : "";
      element.nombre = apiResponse.funcionalidades[index].nombre;
      element.estado = apiResponse.funcionalidades[index].estado;
      element.action = "acción";

      for (let perfilI = 0; perfilI < tempJsonColumn.length; perfilI += 1) {
        let isChecked = false;

        for (let i = 0; i < tempJsonColumn[perfilI].funcionalidades.length; i += 1) {
          if (tempJsonColumn[perfilI].funcionalidades[i].funcionalidadId === element.id) {
            /* console.log(
              `Funcionalidad: '${element.id}', Perfil: ${tempJsonColumn[perfilI].interno}, Id Funcionalidad: '${tempJsonColumn[perfilI].funcionalidades[i].funcionalidadId}'`
            ); */

            isChecked = true;
            break;
          }
        }

        element[tempJsonColumn[perfilI].interno] = isChecked;
      }

      elements.push(element);
    }

    setJsonData({
      type: "INITIAL_STATE",
      data: elements,
    });
  };

  const saveChangesStatus = async (valueId) => {
    const index = jsonData.map((item) => item.id).indexOf(valueId);

    const body = {
      nombre: jsonData[index].nombre,
      estado: !jsonData[index].estado,
    };

    try {
      const apiResponse = await APIRequest("PATCH", `/Api/Funcionalidad/${valueId}`, body);
      if (apiResponse.status === 200) {
        showNotificationSuccess("Se actualizó el estado exitosamente.");
      } else {
        const errorMessage = {
          code: apiResponse.status,
          message: `Error: ${apiResponse.status}`,
        };
        throw errorMessage;
      }
    } catch (e) {
      showNotificationError(`Ocurrió un error: ${e.message}`);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const getSelectData = async (valueId) => {
    const area = await (await APIRequest("GET", "/Api/Area/Todos", null)).json();
    const areaResponse = [];

    for (let index = 0; index < area.length; index += 1) {
      areaResponse.push(area[index]);
    }

    setSelectData({
      area: areaResponse,
    });
  };

  const setEditMoveFunctionality = async (valueId) => {
    // setElementIsNew(false);
    setNewValueId(valueId);

    // const index = jsonData.map((item) => item.id).indexOf(valueId);
    // setNewValueId(jsonData[index].id);
    // setNewValueArea(jsonData[index].area);

    setModalMoveFunctionality(true);
  };

  useEffect(async () => {
    await getSelectData(1);
    await getJsonColumns();
  }, [pathname]);

  const { columns, rows } = data(
    jsonData,
    jsonColumns,
    setJsonData,
    saveChangesStatus,
    setEditMoveFunctionality
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {renderMoveFunctionalityModal}
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                <MDBox>
                  <MDTypography variant="h4" gutterBottom color="warning">
                    <Icon
                      sx={{ cursor: "pointer", fontWeight: "bold" }}
                      onClick={() => navigate("/Administracion/Areas")}
                    >
                      arrow_back
                    </Icon>
                    {"   "}
                    Funcionalidades Asignadas Al Area {areaSelected.nombre}
                  </MDTypography>
                </MDBox>
                <MDBox color="text" px={2}>
                  <Icon
                    sx={{ cursor: "pointer", fontWeight: "bold" }}
                    fontSize="small"
                    onClick={openMenu}
                  >
                    more_vert
                  </Icon>
                </MDBox>
                {renderMenu}
              </MDBox>
              <MDBox>
                <DataTable
                  entriesPerPage={(false, { defaultValue: 50, entries: [5, 10, 25, 50, 100] })}
                  canSearch
                  showTotalEntries
                  table={{ columns, rows }}
                  pagination={{ variant: "contained", color: "warning" }}
                  isSorted
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {renderNotificacion}
      <Footer />
    </DashboardLayout>
  );
}

export default ProfileFunctionalities;
