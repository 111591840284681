/* eslint-disable no-undef */
/* eslint-disable no-loop-func */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Switch from "@mui/material/Switch";

// Default Components
import MDTypography from "components/MDTypography";

export default function data(
  jsonData,
  deleteJsonData,
  setEditJsonData,
  updatePalClaveJsonData,
  getPalClaveJsonData
) {
  const getRows = () => {
    const elements = [];

    for (let index = 0; index < jsonData.length; index += 1) {
      elements.push({
        taxId: jsonData[index].taxId,
        pais: jsonData[index].pais ? jsonData[index].pais.nombre : "",
        longitudMinima: jsonData[index].longitudMinima,
        noMayusculas: jsonData[index].noMayusculas,
        noMinusculas: jsonData[index].noMinusculas,
        noCaracteresEspe: jsonData[index].noCaracteresEspe,
        noNumeros: jsonData[index].noNumeros,
        vigencia: jsonData[index].vigencia,
        historial: jsonData[index].historial,
        bloqueoInactividad: jsonData[index].bloqueoInactividad,
        palabrasClave: (
          <MDTypography color="text">
            <Tooltip
              title={jsonData[index].palabrasClave ? "Desactivar" : "Activar"}
              disableInteractive
            >
              <Switch
                checked={jsonData[index].palabrasClave}
                onChange={() => updatePalClaveJsonData(jsonData[index].id)}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Tooltip>{" "}
            <Tooltip
              title="Ver Palabras Clave"
              disableInteractive
              onClick={() => getPalClaveJsonData(jsonData[index].palabrasClaveString)}
            >
              <Icon>visibility</Icon>
            </Tooltip>
          </MDTypography>
        ),
        usuario: jsonData[index].usuario,
        action: (
          <MDTypography color="text">
            <Tooltip
              title="Eliminar"
              disableInteractive
              onClick={() => deleteJsonData(jsonData[index].id)}
            >
              <Icon>delete</Icon>
            </Tooltip>{" "}
            /{" "}
            <Tooltip
              title="Editar"
              disableInteractive
              onClick={() => {
                setEditJsonData(jsonData[index]);
              }}
            >
              <Icon>edit</Icon>
            </Tooltip>
          </MDTypography>
        ),
      });
    }

    return elements;
  };

  return {
    columns: [
      { Header: "Tax Id", accessor: "taxId", align: "center" },
      { Header: "Pais", accessor: "pais", align: "center" },
      { Header: "Longitud Minima", accessor: "longitudMinima", align: "center" },
      { Header: "No. Mayusculas", accessor: "noMayusculas", align: "center" },
      { Header: "No. Minusculas", accessor: "noMinusculas", align: "center" },
      { Header: "No. Caracteres Espe.", accessor: "noCaracteresEspe", align: "center" },
      { Header: "Cantidad Numeros", accessor: "noNumeros", align: "center" },
      { Header: "Vigencia", accessor: "vigencia", align: "center" },
      { Header: "Historial", accessor: "historial", align: "center" },
      { Header: "Bloqueo Inactividad", accessor: "bloqueoInactividad", align: "center" },
      { Header: "Palabras Clave", accessor: "palabrasClave", align: "center" },
      { Header: "Usuario", accessor: "usuario", align: "center" },
      { Header: "Acción", accessor: "action", align: "center" },
    ],

    rows: getRows(),
  };
}

// Setting default values for the props of MDBadge
data.defaultProps = {
  jsonData: [],
};

// Typechecking props of the data
data.propTypes = {
  jsonData: PropTypes.objectOf(PropTypes.array),
  deleteJsonData: PropTypes.func.isRequired,
  setEditJsonData: PropTypes.func.isRequired,
  updatePalClaveJsonData: PropTypes.func.isRequired,
  getPalClaveJsonData: PropTypes.func.isRequired,
};
