/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// API Request PacPanama
import APIRequestPacSandbox from "api/requestGeneric";

function AltaEmpresaSandboxForm() {
  const hostAdmin = process.env.REACT_APP_API_URL;
  const apiKeyAdmin = process.env.REACT_APP_API_KEY_PAC_ADMIN_SBX;
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  // const [jsonData, setJsonData] = useState([]);
  // const { pathname } = useLocation();
  const {
    state: { route },
  } = useLocation();
  const navigate = useNavigate();

  const [newValueDv, setNewValueDv] = useState("");
  const [newValueRuc, setNewValueRuc] = useState("");

  const newQR = (length) => {
    let result = "";
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  };

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };
  const showNotificationError = (message) => {
    configNotification("error", "warning", "Notificación", message, "", true);
  };
  const showNotificationSuccess = (message) => {
    configNotification("warning", "check", "Notificación", message, "", true);
  };
  const setAfiliacion = async () => {
    let urlComplete = `${hostAdmin}/api/affiliates/taxpayer?code=${apiKeyAdmin}`;
    let body = {
      taxpayerNumber: newValueRuc,
      checkDigit: newValueDv,
      taxpayerType: "Juridico",
      securityCode:
        "DA847452FBAC8505E05349D1950A246CDA847452FBAD8505E05349D1950A246CDA847452FBAE8505E05349D1950A246CDA847452FBAF8505E05349D1950A246C",
      createdBy: userInfo.correo,
    };
    let apiResponse = await APIRequestPacSandbox("POST", urlComplete, body, true, null);

    if (apiResponse === null) {
      return false;
    }
    if (apiResponse.status === 200) {
      const dataResponse = await apiResponse.json();

      console.log(dataResponse);
      return true;
    }
    if (apiResponse.status === 409) {
      urlComplete = `${hostAdmin}/api/affiliates/taxpayer/enabled?code=${apiKeyAdmin}`;
      body = {
        taxpayerNumber: newValueRuc,
        taxpayerType: "Juridico",
        updatedBy: userInfo.correo,
      };
      apiResponse = await APIRequestPacSandbox("PATCH", urlComplete, body, true, null);
      if (apiResponse === null) {
        return false;
      }
      if (apiResponse.status === 200) {
        // const dataResponse = await apiResponse.json();
        // console.log("200");
        return true;
      }
    }
    // console.log("Error");
    const dataResponse = await apiResponse.json();

    const errorMessage = {
      code: apiResponse.status,
      message: `Error: ${dataResponse.message}`,
    };
    throw errorMessage;
    // return false;
  };
  const saveDataModal = async () => {
    // const tempJsonData = jsonData;
    const newQr = newQR(128);
    try {
      if (await setAfiliacion(newQr)) {
        /* const today = new Date();
        const newTempElement = {
          taxId: newValueRuc,
          issuerRuc: newValueRuc,
          IssuerCheckDigit: newValueDv,
          estado: "Alta",
          usuario: userInfo.correo,
          qr: newQr,
          fechaUpdate: `${today.getFullYear()}-${(today.getMonth() + 1)
            .toString()
            .padStart(2, "0")}-${today.getDate().toString().padStart(2, "0")}`,
        };
        console.log("Nuevo Elemeto", newTempElement);
        tempJsonData.push(newTempElement); */

        showNotificationSuccess(`Se afilió el '${newValueRuc}' exitosamente.`);
      } else {
        showNotificationError(`Error al realizar la afiliación`);
      }
      // setJsonData(tempJsonData);
    } catch (e) {
      showNotificationError(`Ocurrió un error: ${e.message}`);
    }
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );

  const handleSubmit = (event) => {
    event.preventDefault();
    saveDataModal();
  };

  return (
    <DashboardLayout>
      <DashboardNavbar breadCrumb={route.split("/").slice(1)} />
      <MDBox pt={3} pb={3} component="form" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MDBox pb={4}>
              <MDTypography variant="subtitle" fontWeight="bold">
                <Icon
                  sx={{ cursor: "pointer", fontWeight: "bold" }}
                  onClick={() => navigate(route)}
                >
                  arrow_back
                </Icon>
                {"   "}
                Dar Alta
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox sx={{ position: "relative", mx: 3, py: 2, px: 2 }}>
                <Grid container spacing={3} justifyContent="left" alignItems="left" height="100%">
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <MDInput
                      variant="standard"
                      type="text"
                      label="RUC: "
                      fullWidth
                      required
                      InputLabelProps={{ shrink: true }}
                      value={newValueRuc}
                      onChange={(e) => {
                        setNewValueRuc(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <MDInput
                      variant="standard"
                      type="text"
                      label="Digito Verificador"
                      fullWidth
                      required
                      InputLabelProps={{ shrink: true }}
                      value={newValueDv}
                      onChange={(e) => {
                        setNewValueDv(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <MDInput
                      fullWidth
                      disabled
                      label="Código de Seguridad"
                      multiline
                      rows={3}
                      value="DA847452FBAC8505E05349D1950A246CDA847452FBAD8505E05349D1950A246CDA847452FBAE8505E05349D1950A246CDA847452FBAF8505E05349D1950A246C"
                    />
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="left" alignItems="left" height="100%">
              <Grid item xs={12} sm={12} md={6} lg={4} xl={10} />
              <Grid item xs={12} sm={12} md={6} lg={4} xl={2} container justifyContent="flex-end">
                <MDButton
                  variant="contained"
                  type="submit"
                  sx={{
                    background: "linear-gradient(45deg, #099268 30%, #099268 90%)",
                    color: "#FFFFFF",
                  }}
                >
                  <Icon>save</Icon>&nbsp;Guardar
                </MDButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
      {renderNotificacion}
      <Footer />
    </DashboardLayout>
  );
}

export default AltaEmpresaSandboxForm;
