/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// @mui material components
// eslint-disable-next-line no-unused-vars
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDInput from "components/MDInput";
// eslint-disable-next-line no-unused-vars
import MDDatePicker from "components/MDDatePicker";
import MDButton from "components/MDButton";
import Loading from "components/Loading";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import data from "views/soporte/GlobalIdDuplicados/data";

// API Request
import APIRequest from "api/request";
import APIRequestGeneric from "api/requestGeneric";

function GlobalIdDuplicados() {
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  const host = process.env.REACT_APP_API_URL;
  const basicAuth = `${process.env.REACT_APP_API_ENEX_USER}:${process.env.REACT_APP_API_ENEX_PASSWORD}`;
  const basicAuthorization = `Basic ${btoa(basicAuth)}`;

  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  const [load, setLoad] = useState(false);
  const [jsonData, setJsonData] = useState([]);
  const { pathname } = useLocation();

  const [newValueTaxId, setNewValueTaxId] = useState("");
  const [newValuePais, setNewValuePais] = useState(null);
  const [newValueFechaInicio, setNewValueFechaInicio] = useState("");
  const [newValueFechaFin, setNewValueFechaFin] = useState("");
  const [newValueCanal, setNewValueCanal] = useState(null);
  const [newValueGlobalId, setNewValueGlobalId] = useState("");

  const [selectData, setSelectData] = useState({
    pais: [
      { id: "cr", nombre: "Costa rica" },
      { id: "gt", nombre: "Guatemala" },
      { id: "mx", nombre: "México" },
    ],
    canal: [
      { id: 1, nombre: "Emision" },
      { id: 2, nombre: "Receptor" },
    ],
  });

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };
  const showNotificationError = (message) => {
    configNotification("error", "warning", "Notificación", message, "", true);
  };
  const showNotificationSuccess = (message) => {
    configNotification("warning", "check", "Notificación", message, "", true);
  };

  const closeModal = () => {
    setNewValueTaxId("");
    setNewValuePais(null);
    setNewValueFechaInicio("");
    setNewValueFechaFin("");
    setNewValueCanal(null);
  };
  const closeModalGlobalId = () => {
    setNewValueGlobalId("");
  };

  const DateToYYYYmmDD = (DateVar) => {
    if (DateVar) {
      const NewDate = new Date(DateVar);
      const resultado = `${NewDate.getFullYear()}-${(NewDate.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${NewDate.getDate().toString().padStart(2, "0")}T00:00:00-06:00`;

      return resultado;
    }

    return "";
  };

  const saveDataModal = async (body) => {
    setLoad(true);
    try {
      const urlComplete = `${host}/api/Document/DocumentFixDuplicated`;

      const apiResponse = await (
        await APIRequestGeneric("POST", urlComplete, body, false, basicAuthorization)
      ).json();

      if ((apiResponse.Code === 201 || apiResponse.Code === 200) && apiResponse.IsSuccessful) {
        const tempJsonData = jsonData;

        const bodyAddRecord = {
          GlobalId: newValueGlobalId,
          TaxId: newValueTaxId,
          Pais: newValuePais ? newValuePais.id : "",
          FechaInicio:
            newValueFechaInicio !== ""
              ? DateToYYYYmmDD(newValueFechaInicio).substring(0, 10)
              : null,
          FechaFin:
            newValueFechaFin !== "" ? DateToYYYYmmDD(newValueFechaFin).substring(0, 10) : null,
          Canal: newValueCanal ? newValueCanal.id : null, // true: Emisor
          UsuarioId: userInfo.id,
        };
        const addRecordResponse = await APIRequest(
          "POST",
          "/Api/GlobalIdDuplicados",
          bodyAddRecord
        );
        let dataResponse = null;

        // if (elementIsNew) {
        dataResponse = await addRecordResponse.json();
        // setNewValueId(dataResponse);
        // }

        const newTempElement = {
          // id: elementIsNew ? dataResponse : newValueId,
          id: dataResponse,
          globalId: newValueGlobalId,
          taxId: newValueTaxId,
          pais: newValuePais,
          fechaInicio: DateToYYYYmmDD(newValueFechaInicio),
          fechaFin: DateToYYYYmmDD(newValueFechaFin),
          canal: newValueCanal,
          usuario: userInfo.correo,
        };

        setJsonData([newTempElement, ...tempJsonData]);
        showNotificationSuccess("Se envío a la cola para reprocesar.");
        closeModal();
        closeModalGlobalId();
      } else {
        showNotificationError(`Ocurrió un error: ${apiResponse.Message}`);
      }
    } catch (e) {
      showNotificationError(`Ocurrió un error: ${e.message}`);
    }
    setLoad(false);
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );

  const getJsonData = async (countries) => {
    const apiResponse = await (
      await APIRequest("GET", "/Api/GlobalIdDuplicados/Todos", null)
    ).json();

    const tempJsonData = [];
    for (let index = 0; index < apiResponse.length; index += 1) {
      tempJsonData.push({
        id: apiResponse[index].id,
        globalId: apiResponse[index].globalId,
        taxId: apiResponse[index].taxId,
        pais: apiResponse[index].pais
          ? countries[
              countries.map((item) => item.id).indexOf(apiResponse[index].pais.toLowerCase())
            ]
          : null,
        fechaInicio: apiResponse[index].fechaInicio // .includes("0001")
          ? apiResponse[index].fechaInicio.substring(0, 10)
          : "",
        fechaFin: apiResponse[index].fechaFin // .includes("0001")
          ? apiResponse[index].fechaFin.substring(0, 10)
          : "",
        canal:
          selectData.canal[
            selectData.canal.map((item) => item.id).indexOf(apiResponse[index].canal)
          ],
        usuario: apiResponse[index].usuario,
      });
    }

    setJsonData(tempJsonData);
  };

  const getCountriesData = async () => {
    const tempJsonData = [];
    const apiResponse = await (await APIRequest("GET", "/api/Pais/Todos", null)).json();

    for (let index = 0; index < apiResponse.length; index += 1) {
      tempJsonData.push({
        id: apiResponse[index].codigo.toLowerCase(),
        nombre: apiResponse[index].nombre,
      });
    }

    setSelectData({
      pais: tempJsonData,
      canal: selectData.canal,
    });

    return tempJsonData;
  };

  const deleteJsonData = async (id) => {
    if (window.confirm("¿Quiere eliminar el Tax ID?")) {
      try {
        const index = jsonData.map((item) => item.taxId).indexOf(id);
        const elements = [...jsonData];
        const nombreElemento = elements[index].taxId;
        elements.splice(index, 1);
        setJsonData(elements);

        showNotificationError(`Se eliminó el Tax ID '${nombreElemento}' exitosamente.`);

        /* const apiResponse = await APIRequest("DELETE", `/Api/Perfil/${id}`, null);

        if (apiResponse.status === 200) {
          const index = jsonData.map((item) => item.id).indexOf(id);
          const elements = [...jsonData];
          const nombrePerfil = elements[index].nombre;
          elements.splice(index, 1);
          setJsonData(elements);
          configNotification(
            "warning",
            "check",
            "Notificación",
            `Se eliminó el perfil '${nombrePerfil}' exitosamente.`,
            "",
            true
          );
        } else {
          const errorMessage = {
            code: apiResponse.status,
            message: `Error: ${apiResponse.status}`,
          };
          throw errorMessage;
        } */
      } catch (e) {
        configNotification(
          "error",
          "warning",
          "Notificación",
          `Ocurrió un error al eliminar el Tax ID: ${e.message}`,
          "",
          true
        );
      }
    }
  };

  const handleSubmitOne = (event) => {
    event.preventDefault();

    const body = {
      GlobalDocumentId: newValueGlobalId,
      User: userInfo.correo,
    };

    saveDataModal(body);
  };

  // eslint-disable-next-line no-unused-vars
  const handleSubmitTwo = (event) => {
    event.preventDefault();

    if (newValueFechaInicio === "" || newValueFechaFin === "") {
      showNotificationError("Ingrese las dos fechas.");
    } else if (new Date(newValueFechaFin) >= new Date(newValueFechaInicio)) {
      const startDate = new Date(newValueFechaInicio);
      const endDate = new Date(newValueFechaFin);
      const body = {
        TaxId: newValueTaxId,
        CountryId: newValuePais.id,
        StartDate:
          newValueFechaInicio !== ""
            ? `${startDate.getFullYear()}-${(startDate.getMonth() + 1)
                .toString()
                .padStart(2, "0")}-${startDate.getDate().toString().padStart(2, "0")}`
            : null,
        EndDate:
          newValueFechaFin !== ""
            ? `${endDate.getFullYear()}-${(endDate.getMonth() + 1)
                .toString()
                .padStart(2, "0")}-${endDate.getDate().toString().padStart(2, "0")}`
            : null,
        IsSender: newValueCanal ? newValueCanal.id === 1 : null, // true: Emisor
        User: userInfo.correo,
      };

      saveDataModal(body);
    } else if (new Date(newValueFechaFin) < new Date(newValueFechaInicio)) {
      showNotificationError("La fecha fin no puede ser menor a la fecha de inicio.");
    }
  };

  useEffect(async () => {
    setLoad(true);
    const countries = await getCountriesData();
    await getJsonData(countries);
    setLoad(false);
  }, [pathname]);

  const { columns, rows } = data(jsonData, deleteJsonData);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={3}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <MDTypography variant="subtitle" fontWeight="bold">
              Permite buscar y corregir globalID duplicados.
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDBox
              // sx={{ position: "relative", mx: 3, py: 2, px: 2 }}
              component="form"
              onSubmit={handleSubmitOne}
            >
              <Grid container spacing={3} justifyContent="left" alignItems="left" height="100%">
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                  <MDInput
                    variant="standard"
                    type="text"
                    label="Global Id"
                    fullWidth
                    required
                    InputLabelProps={{ shrink: true }}
                    value={newValueGlobalId}
                    onChange={(e) => {
                      setNewValueGlobalId(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                  <MDButton
                    variant="contained"
                    type="submit"
                    sx={{
                      background: "linear-gradient(45deg, #1971C2 30%, #1971C2 90%)",
                      color: "#FFFFFF",
                    }}
                  >
                    <Icon>search</Icon>&nbsp;Buscar
                  </MDButton>
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
          {/* <Grid item xs={12}>
            <MDTypography variant="subtitle" fontWeight="bold">
              Buscar Por TaxID
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox
                sx={{ position: "relative", mx: 3, py: 2, px: 2 }}
                component="form"
                onSubmit={handleSubmitTwo}
              >
                <Grid container spacing={3} justifyContent="left" alignItems="left" height="100%">
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <Autocomplete
                      value={newValuePais}
                      onChange={(event, newValue) => {
                        setNewValuePais(newValue);
                      }}
                      options={selectData.pais}
                      getOptionLabel={(option) => option.nombre}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderInput={(params) => (
                        <MDInput
                          variant="standard"
                          type="text"
                          label="Pais"
                          fullWidth
                          required
                          InputLabelProps={{ shrink: true }}
                          {...params}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <MDInput
                      variant="standard"
                      type="text"
                      label="Tax ID"
                      fullWidth
                      required
                      InputLabelProps={{ shrink: true }}
                      value={newValueTaxId}
                      onChange={(e) => {
                        setNewValueTaxId(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <MDDatePicker
                      input={{
                        variant: "standard",
                        label: "Fecha Inicio",
                        fullWidth: true,
                        required: true,
                        InputLabelProps: { shrink: true },
                      }}
                      value={newValueFechaInicio}
                      onChange={(e) => {
                        setNewValueFechaInicio(e[0]);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <MDDatePicker
                      input={{
                        variant: "standard",
                        label: "Fecha Fin",
                        fullWidth: true,
                        required: true,
                        InputLabelProps: { shrink: true },
                      }}
                      value={newValueFechaFin}
                      onChange={(e) => {
                        setNewValueFechaFin(e[0]);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <Autocomplete
                      value={newValueCanal}
                      onChange={(event, newValue) => {
                        setNewValueCanal(newValue);
                      }}
                      options={selectData.canal}
                      getOptionLabel={(option) => option.nombre}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderInput={(params) => (
                        <MDInput
                          variant="standard"
                          type="text"
                          label="Canal"
                          fullWidth
                          required
                          InputLabelProps={{ shrink: true }}
                          {...params}
                        />
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={9}
                    container
                    justifyContent="flex-end"
                  >
                    <MDButton
                      variant="contained"
                      type="submit"
                      sx={{
                        background: "linear-gradient(45deg, #1971C2 30%, #1971C2 90%)",
                        color: "#FFFFFF",
                      }}
                    >
                      <Icon>search</Icon>&nbsp;Buscar
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid> */}
          <Grid item xs={12}>
            <Card>
              <MDBox>
                <DataTable
                  entriesPerPage={(false, { defaultValue: 10, entries: [5, 10, 25, 50] })}
                  canSearch
                  showTotalEntries
                  table={{ columns, rows }}
                  pagination={{ variant: "contained", color: "warning" }}
                  isSorted
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {renderNotificacion}
      <Loading load={load} />
      <Footer />
    </DashboardLayout>
  );
}

export default GlobalIdDuplicados;
