/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

export default function data(jsonData) {
  const getRows = () => {
    const elements = [];

    for (let index = 0; index < jsonData.length; index += 1) {
      const fecha = new Date(jsonData[index].fecha);
      elements.push({
        valor: jsonData[index].valor,
        tipo: jsonData[index].tipo,
        fecha:
          jsonData[index].fecha === null
            ? ""
            : `${fecha.getDate()}/${fecha.getMonth() + 1}/${fecha.getFullYear()}`,
        user: jsonData[index].user,
      });
    }

    return elements;
  };

  return {
    columns: [
      { Header: "Tipo", accessor: "tipo", align: "center" },
      { Header: "Aplica a", accessor: "valor", align: "center" },
      { Header: "Fecha", accessor: "fecha", align: "center" },
      { Header: "Usuario", accessor: "user", align: "center" },
    ],

    rows: getRows(),
  };
}

// Setting default values for the props of MDBadge
data.defaultProps = {
  jsonData: [],
};

// Typechecking props of the data
data.propTypes = {
  jsonData: PropTypes.objectOf(PropTypes.array),
};
