/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as React from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

export default function ResponsiveDialog({
  closeModal,
  saveChanges,
  children,
  title,
  titleButton,
  styleModal,
  sxButton,
  ...rest
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { titleColor, acceptButtonColor, cancelButtonColor, variantButton } = styleModal;

  const handleSubmit = (event) => {
    event.preventDefault();
    saveChanges();
  };

  return (
    <Dialog fullScreen={fullScreen} fullWidth {...rest} aria-labelledby="responsive-dialog-title">
      <MDBox component="form" onSubmit={handleSubmit}>
        <DialogTitle id="responsive-dialog-title">
          <MDTypography gutterBottom color={titleColor}>
            {title}
          </MDTypography>
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <MDButton
            variant={variantButton}
            autoFocus
            onClick={closeModal}
            color={cancelButtonColor}
            sx={sxButton}
          >
            Cancelar
          </MDButton>
          <MDButton
            variant={variantButton}
            autoFocus
            type="submit"
            color={acceptButtonColor}
            sx={sxButton}
          >
            {titleButton}
          </MDButton>
        </DialogActions>
      </MDBox>
    </Dialog>
  );
}

// Setting default values for the props of MDSnackbar
ResponsiveDialog.defaultProps = {
  title: "",
  titleButton: "Guardar",
  maxWidth: "lg",
  styleModal: {
    titleColor: "warning",
    acceptButtonColor: "warning",
    cancelButtonColor: "warning",
    variantButton: "outlined",
  },
  sxButton: {},
};

// Typechecking props for MDSnackbar
ResponsiveDialog.propTypes = {
  closeModal: PropTypes.func.isRequired,
  saveChanges: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  titleButton: PropTypes.string,
  maxWidth: PropTypes.string,
  styleModal: PropTypes.objectOf({
    titleColor: PropTypes.string,
    acceptButtonColor: PropTypes.string,
    cancelButtonColor: PropTypes.string,
    variantButton: PropTypes.string,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  sxButton: PropTypes.object,
};
