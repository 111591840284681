import { CUSTOM_UPLOAD_COMPONENT, EXTENSIONS_FILE } from "./showContent";
import {
  CUSTOMIZED,
  ROOT_TO_XML_LABEL,
  SCHEMATRON,
  TYPE_PUBLISH,
  XML_TO_DTE,
  XSD,
} from "./transformation";

const CONFIG_GLOBAL_DATA = (configGlobal) => ({
  transformation: [
    {
      id: 0,
      fileName: ROOT_TO_XML_LABEL,
      version: configGlobal?.inputToDte?.version ?? "",
      updatedBy: configGlobal?.inputToDte?.updatedBy ?? "",
      propsThreeDotsMenu: {
        keyResponse: "inputToDte",
        fileName: "input",
      },
    },
    {
      id: 1,
      fileName: XML_TO_DTE,
      version: configGlobal?.dteToFiscal?.version ?? "",
      updatedBy: configGlobal?.dteToFiscal?.updatedBy ?? "",
      propsThreeDotsMenu: {
        keyResponse: "dteToFiscal",
        fileName: "dte",
      },
    },
    {
      id: 2,
      fileName: XSD,
      version: configGlobal?.xsd?.version ?? "",
      updatedBy: configGlobal?.xsd?.updatedBy ?? "",
      propsThreeDotsMenu: {
        keyResponse: "xsd",
        fileName: "xsd",
        fileExtension: EXTENSIONS_FILE.ZIP,
      },
    },
    {
      id: 3,
      fileName: CUSTOMIZED,
      version: configGlobal?.customResponse?.version ?? "",
      updatedBy: configGlobal?.customResponse?.updatedBy ?? "",
      propsThreeDotsMenu: {
        keyResponse: "customResponse",
        fileName: "custom",
      },
    },
    {
      id: 4,
      fileName: SCHEMATRON,
      version: configGlobal?.schematron?.version ?? "",
      updatedBy: configGlobal?.schematron?.updatedBy ?? "",
      propsThreeDotsMenu: {
        keyResponse: "schematron",
        fileName: "schematron",
      },
    },
  ],
  url: [
    {
      title: TYPE_PUBLISH,
      component: CUSTOM_UPLOAD_COMPONENT.CUSTOM_UPLOAD,
      data: {
        publishType: configGlobal?.publishType ?? "",
        endpointSDX: configGlobal?.endpointSDX ?? "",
        endpointPRD: configGlobal?.endpointPRD ?? "",
        publishUpdatedBy: configGlobal?.publishUpdatedBy ?? "",
        publishUpdatedAt: configGlobal?.publishUpdatedAt ?? "",
      },
    },
  ],
  configuration: {
    xpos: {
      xPosUpdateType: configGlobal?.xPosUpdateType ?? "",
      xPosUpdateDate: configGlobal?.xPosUpdateDate ?? "",
      xPosUpdatedBy: configGlobal?.xPosUpdatedBy ?? "",
      xposUpdatedAt: configGlobal?.xposUpdatedAt ?? "",
    },
    persistency: configGlobal?.xPosLogsPersistencyDays ?? "",
  },
});

export default CONFIG_GLOBAL_DATA;
