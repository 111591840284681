import { useState } from "react";

const useNotification = () => {
  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });

  const showNotificationSuccess = (message) => {
    setNotification({
      color: "success",
      icon: "check",
      title: "Notificación",
      content: message,
      dateTime: "",
      show: true,
    });
  };

  const showNotificationError = (message) => {
    setNotification({
      color: "error",
      icon: "warning",
      title: "Notificación",
      content: message,
      dateTime: "",
      show: true,
    });
  };

  return {
    notification,
    showNotificationSuccess,
    showNotificationError,
    setNotification,
  };
};

export default useNotification;
