import axios from "axios";
import { camelizeKeys } from "humps";

async function xPosApiRequest(params, extraOptions = {}) {
  const { method, url, data, headers, responseType } = params;
  let response = null;

  try {
    const endpoint = process.env.REACT_APP_XPOS_API_URL;
    const basicAuth = `${process.env.REACT_APP_XPOS_API_USER}:${process.env.REACT_APP_XPOS_API_PASSWORD}`;

    const options = {
      method,
      url: `${endpoint}${url}`,
      headers: { Authorization: `Basic ${btoa(basicAuth)}`, ...headers },
      responseType,
      data: data || null,
      ...extraOptions,
    };

    response = await axios.request(options);

    if (response.data && response.headers["content-type"].includes("application/json")) {
      response.data = camelizeKeys(response.data);
    }
  } catch (error) {
    return error.response ? error.response : error.message;
  }

  return response;
}

export default xPosApiRequest;
