export const optionsGlobalConfiguration = (country) => ({
  method: "GET",
  url: "/global-configuration",
  headers: {
    "Content-Type": "application/json",
    countryId: country,
  },
});

export const optionsConfiguration = (organizationConfigGuid) => ({
  method: "GET",
  url: "/configuration",
  headers: {
    "Content-Type": "application/json",
    organizationConfigGuid,
  },
});

export const optionsConfigurationFields = (country) => ({
  method: "GET",
  url: `/countries/IsoCode/${country}`,
  headers: {
    "Content-Type": "application/json",
  },
});
