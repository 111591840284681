/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// @mui material components
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Loading from "components/Loading";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// API Request
import APIRequest from "api/request";

function ActualizarRespuesta() {
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  const [load, setLoad] = useState(false);
  const { pathname } = useLocation();
  const {
    state: { route, element },
  } = useLocation();
  const navigate = useNavigate();

  const [elementIsNew, setElementIsNew] = useState(true);
  const [newValuePais, setNewValuePais] = useState(null);
  const [newValueTaxId, setNewValueTaxId] = useState("");
  const [newValueParametro, setNewValueParametro] = useState("");
  const [newValueXPath, setNewValueXPath] = useState("");
  // const [newValueMetodo, setNewValueMetodo] = useState(null);
  const [selectData, setSelectData] = useState({
    pais: [
      { id: "cr", nombre: "Costa rica" },
      { id: "ec", nombre: "Ecuador" },
      { id: "pe", nombre: "Perú" },
      { id: "cl", nombre: "Chile" },
      { id: "mx", nombre: "México" },
      { id: "gt", nombre: "Guatemala" },
      { id: "co", nombre: "Colombia" },
      { id: "pa", nombre: "Panamá" },
    ],
    metodo: [
      { id: 1, nombre: "SendDocumentToAuthority" },
      { id: 2, nombre: "GetDocument" },
    ],
  });

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };
  const showNotificationError = (message) => {
    configNotification("error", "warning", "Notificación", message, "", true);
  };
  const showNotificationSuccess = (message) => {
    configNotification("warning", "check", "Notificación", message, "", true);
  };
  const saveDataModal = async () => {
    setLoad(true);
    const body = {
      TaxId: newValueTaxId,
      Pais: newValuePais.id,
      Metodo: 1, // "SendDocumentToAuthority" // newValueMetodo.id,
      Parametro: newValueParametro,
      Xpath: newValueXPath,
      Estado: true,
      Usuario: userInfo.correo,
      EsNuevo: elementIsNew,
      GetDocumentCustomFields: true,
      GetBarCodeText: true,
    };

    const apiResponse = await APIRequest("POST", "/Api/ActualizarRespuesta", body);

    if (apiResponse.status === 200) {
      if (elementIsNew) {
        setNewValuePais(null);
        setNewValueTaxId("");
        setNewValueParametro("");
        setNewValueXPath("");
        // setNewValueMetodo(null);
      }
      showNotificationSuccess("Se completó el registro exitosamente");
    } else {
      showNotificationError("Error al realizar el registro");
    }
    setLoad(false);
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );

  const setEditJsonData = (jsonElement) => {
    if (element) {
      setNewValueTaxId(jsonElement.taxId);
      setNewValuePais(jsonElement.pais);
      setNewValueParametro(jsonElement.parametro);
      // setNewValueMetodo(jsonElement.metodo);
      setNewValueXPath(jsonElement.xPath);
    }
  };
  const getCountriesData = async () => {
    const tempJsonData = [];
    const apiResponse = await (await APIRequest("GET", "/api/Pais/Todos", null)).json();

    if (apiResponse.length > 0) {
      for (let index = 0; index < apiResponse.length; index += 1) {
        tempJsonData.push({
          id: apiResponse[index].codigo.toLowerCase(),
          nombre: apiResponse[index].nombre,
        });
      }

      setSelectData({
        pais: tempJsonData,
        status: selectData.status,
      });
    }

    return tempJsonData;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    saveDataModal();
  };

  useEffect(async () => {
    await getCountriesData();
    setElementIsNew(!element);
    setEditJsonData(element);
  }, [pathname]);

  return (
    <DashboardLayout>
      <DashboardNavbar breadCrumb={route.split("/").slice(1)} />
      <MDBox pt={3} pb={3} component="form" onSubmit={handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <MDBox pb={2}>
              <MDTypography variant="subtitle" fontWeight="bold">
                <Icon
                  sx={{ cursor: "pointer", fontWeight: "bold" }}
                  onClick={() => navigate(route)}
                >
                  arrow_back
                </Icon>
                {"   "}
                Permite incluir parámetros en el response del método SendDocumentToAuthority
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox sx={{ position: "relative", mx: 3, py: 2, px: 2 }}>
                <Grid container spacing={3} justifyContent="left" alignItems="left" height="100%">
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <Autocomplete
                      value={newValuePais}
                      onChange={(event, newValue) => {
                        setNewValuePais(newValue);
                      }}
                      options={selectData.pais}
                      disabled={!elementIsNew}
                      getOptionLabel={(option) => option.nombre}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderInput={(params) => (
                        <MDInput
                          variant="standard"
                          type="text"
                          label="Pais"
                          fullWidth
                          required
                          disabled={!elementIsNew}
                          InputLabelProps={{ shrink: true }}
                          {...params}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <MDInput
                      variant="standard"
                      type="text"
                      label="Tax ID"
                      fullWidth
                      required
                      disabled={!elementIsNew}
                      InputLabelProps={{ shrink: true }}
                      value={newValueTaxId}
                      onChange={(e) => {
                        setNewValueTaxId(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <MDInput
                      variant="standard"
                      type="text"
                      label="Nombre Parametro"
                      fullWidth
                      required
                      disabled={!elementIsNew}
                      inputProps={{ maxLength: 30 }}
                      InputLabelProps={{ shrink: true }}
                      value={newValueParametro}
                      onChange={(e) => {
                        const char = e.target.value.charAt(e.target.value.length - 1);
                        const charCode = char.charCodeAt(0);

                        if (
                          (charCode >= 65 && charCode <= 90) ||
                          (charCode >= 97 && charCode <= 122) ||
                          char === ""
                        ) {
                          setNewValueParametro(e.target.value);
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <MDInput
                      variant="standard"
                      type="text"
                      label="XPath"
                      fullWidth
                      inputProps={{ maxLength: 1000 }}
                      InputLabelProps={{ shrink: true }}
                      value={newValueXPath}
                      onChange={(e) => {
                        setNewValueXPath(e.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="left" alignItems="left" height="100%">
              <Grid item xs={12} sm={12} md={6} lg={4} xl={10} />
              <Grid item xs={12} sm={12} md={6} lg={4} xl={2} container justifyContent="flex-end">
                <MDButton
                  variant="contained"
                  type="submit"
                  sx={{
                    background: "linear-gradient(45deg, #099268 30%, #099268 90%)",
                    color: "#FFFFFF",
                  }}
                >
                  <Icon>save</Icon>&nbsp;Guardar
                </MDButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
      {renderNotificacion}
      <Loading load={load} />
      <Footer />
    </DashboardLayout>
  );
}

export default ActualizarRespuesta;
