/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import PropTypes from "prop-types";

// react-router components
import { useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React example components
import DashboardLayout from "../../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";
import Footer from "../../../../examples/Footer";
import NewDataTable from "../../../../examples/Tables/NewDataTable";

// Data
import data from "./data";

// Material Dashboard 2 React components
import MDBox from "../../../../components/MDBox";
import MDInput from "../../../../components/MDInput";
import BKModalImport from "../../../../components/BKModalImport";
import MDTypography from "../../../../components/MDTypography";

// API
import xPosApiRequest from "../../../../api/xPosApiRequest";
import useNotification from "../_hooks/useNotification";
import Notification from "../_components/shared/Notification";
import { CountryCodesToNames } from "../_constants/general";
import { isOk } from "../_helpers/requestHelpers";

function Logs({ country }) {
  const navigator = useNavigate();
  const { notification, showNotificationError, showNotificationSuccess, setNotification } =
    useNotification();

  const [searchTaxID, setSearchTaxID] = useState("");
  const [searchIDxPos, setSearchIDxPos] = useState("");
  const [searchNombrexPos, setSearchNombrexPos] = useState("");
  const [loadingTable, setLoadingTable] = useState(false);

  const [jsonData, setJsonData] = useState([]);
  const [modal, setModal] = useState(false);
  const [filteredData, setFilteredData] = useState(undefined);

  const fetchData = async () => {
    setLoadingTable(true);
    const options = {
      method: "GET",
      url: `/logs?countryId=${country}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await xPosApiRequest(options);
      if (isOk(response.status)) {
        const logsData = await response.data;
        setJsonData(logsData);
      } else {
        showNotificationError("Ocurrio un error al cargar los logs.");
      }
      setLoadingTable(false);
    } catch (error) {
      showNotificationError(error.message);
      setLoadingTable(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [country]);

  const handleSearchTaxID = (taxId) => {
    setSearchTaxID(taxId);
    const filteredlogs = jsonData.filter((log) =>
      log.taxId?.toLowerCase().includes(taxId.toLowerCase())
    );
    setFilteredData(filteredlogs);
  };

  const handleSearchNombrexPos = (xPosName) => {
    setSearchNombrexPos(xPosName);
    const filteredlogs = jsonData.filter((log) =>
      log.xPosName?.toLowerCase().includes(xPosName.toLowerCase())
    );
    setFilteredData(filteredlogs);
  };

  const handleSearchIDxPos = (xPosGuid) => {
    setSearchIDxPos(xPosGuid);
    const filteredlogs = jsonData.filter((log) =>
      log.xPosGuid?.toLowerCase().includes(xPosGuid.toLowerCase())
    );
    setFilteredData(filteredlogs);
  };

  const setEditJsonData = (log) => {
    navigator(`/XPos/${CountryCodesToNames[country]}/Logs/Historial`, { state: { log } });
  };

  const handleDownload = async (arrayIds) => {
    const options = {
      method: "POST",
      url: "/logs/download/last",
      headers: {
        "Content-Type": "application/json",
        countryId: country,
      },
      responseType: "blob",
      data: arrayIds,
    };

    try {
      const response = await xPosApiRequest(options);
      if (isOk(response.status)) {
        const blob = new Blob([response.data], { type: "application/zip" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "logs.zip");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        showNotificationSuccess(
          `${
            arrayIds.length > 1 ? "Archivos descargados" : "Archivo descargado"
          } satisfactoriamente.`
        );
      } else {
        showNotificationError(`Error al descargar archivo.`);
      }
    } catch (error) {
      showNotificationError(`Error al descargar archivo.`);
    }
  };

  const { columns, rows } = data(filteredData || jsonData, setEditJsonData, handleDownload);

  const closeModal = () => {
    setModal(false);
  };

  const renderModal = (
    <BKModalImport
      closeModal={closeModal}
      saveChanges={closeModal}
      open={modal}
      title="Importar xPOS"
      titleButton="Guardar"
    >
      <MDBox px={2} sx={{ position: "relative" }}>
        <Grid container justifyContent="center" alignItems="center" height="100%">
          <div style={{ margin: "1em" }}>
            <div className="file-upload">
              <div style={{ paddingLeft: 30, paddingRight: 35, paddingTop: 20, paddingBottom: 10 }}>
                <input type="file" accept=".csv, .txt" id="file-input" hidden />
                <label
                  style={{ display: "flex", justifyContent: "space-between" }}
                  htmlFor="file-input"
                >
                  <Grid
                    backgroundColor="#1971c2"
                    width="50px"
                    height="30px"
                    borderRadius={2}
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginRight: 10,
                    }}
                  >
                    <Icon fontSize="medium" style={{ color: "white" }}>
                      upload
                    </Icon>
                  </Grid>
                  <p
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "#344767",
                    }}
                  >
                    Seleccione el archivo a importar
                  </p>
                </label>
              </div>
            </div>
          </div>
        </Grid>
      </MDBox>
    </BKModalImport>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {renderModal}
      <MDTypography fontWeight="bold" variant="h5" color="dark" noWrap>
        Listado de logs
      </MDTypography>
      <MDBox py={3}>
        <MDBox pt={3} pb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} py={1}>
              <MDBox display="flex" justifyContent="space-between">
                <MDBox display="flex" alignItems="center" justifyContent="flex-start">
                  <MDBox width="12rem">
                    <MDInput
                      style={{ color: "#344767", borderColor: "#344767" }}
                      placeholder="Búsqueda por TaxID"
                      label="TaxID"
                      value={searchTaxID}
                      type="search"
                      size="small"
                      onChange={(event) => handleSearchTaxID(event.target.value)}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                    />
                  </MDBox>
                  <MDBox width="12rem" ml={2}>
                    <MDInput
                      placeholder="Búsqueda por ID xPOS"
                      value={searchIDxPos}
                      label="ID xPOS"
                      type="search"
                      size="small"
                      onChange={(event) => handleSearchIDxPos(event.target.value)}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                    />
                  </MDBox>
                  <MDBox width="12rem" ml={2}>
                    <MDInput
                      placeholder="Búsqueda por Nombre xPOS"
                      value={searchNombrexPos}
                      label="Nombre xPOS"
                      type="search"
                      size="small"
                      onChange={(event) => handleSearchNombrexPos(event.target.value)}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                    />
                  </MDBox>
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <MDBox>
                  <NewDataTable
                    entriesPerPage={(false, { defaultValue: 10 })}
                    canSearch
                    checkbox
                    tableType="logs"
                    showTotalEntries
                    downloadFile={handleDownload}
                    loadingTable={loadingTable}
                    table={{ columns, rows }}
                    pagination={{ variant: "contained", color: "warning" }}
                    isSorted
                  />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Notification notification={notification} setNotification={setNotification} />
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

Logs.propTypes = {
  country: PropTypes.string.isRequired,
};

export default Logs;
