/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import BKModal from "components/BKModal";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDInput from "components/MDInput";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import data from "views/admin/areas/data";

// API Request
import APIRequest from "api/request";

function Areas() {
  const userType = JSON.parse(sessionStorage.getItem("userType"));

  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  const [menu, setMenu] = useState(null);
  const [modal, setModal] = useState(false);
  const [jsonData, setJsonData] = useState([]);
  const { pathname } = useLocation();

  const [elementIsNew, setElementIsNew] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [newValueId, setNewValueId] = useState("");
  const [newValueName, setNewValueName] = useState("");

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };
  const showNotificationError = (message) => {
    configNotification("error", "warning", "Notificación", message, "", true);
  };
  const showNotificationSuccess = (message) => {
    configNotification("warning", "check", "Notificación", message, "", true);
  };

  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);
  const openAddNewElementModal = () => {
    setElementIsNew(true);
    setMenu(null);
    setModal(true);
  };
  const closeModal = () => {
    setModal(false);
    setNewValueId("");
    setNewValueName("");
  };
  const saveDataModal = async () => {
    const tempJsonData = jsonData;
    const body = {
      nombre: newValueName,
      configAreaId: 1,
    };
    let apiResponse = null;

    try {
      if (elementIsNew) {
        apiResponse = await APIRequest("POST", "/Api/Area", body);
      } else {
        apiResponse = await APIRequest("PATCH", `/Api/Area/${newValueId}`, body);
      }

      if (apiResponse.status === 200) {
        let dataResponse = null;

        if (elementIsNew) {
          dataResponse = await apiResponse.json();
          setNewValueId(dataResponse);
        }

        const newTempElement = {
          id: elementIsNew ? dataResponse : newValueId,
          nombre: newValueName,
        };

        if (elementIsNew) {
          if (dataResponse !== 0) {
            tempJsonData.push(newTempElement);

            showNotificationSuccess(`Se creó el area '${newValueName}' exitosamente.`);
          } else {
            const errorMessage = {
              code: apiResponse.status,
              message: `No se creó el area, error: ${apiResponse.status}`,
            };
            throw errorMessage;
          }
        } else {
          const index = tempJsonData.map((item) => item.id).indexOf(newValueId);
          tempJsonData[index] = newTempElement;

          showNotificationSuccess(`Se actualizó el area '${newValueName}' exitosamente.`);
        }
      } else {
        const errorMessage = {
          code: apiResponse.status,
          message: `Error: ${apiResponse.status}`,
        };
        throw errorMessage;
      }

      setJsonData(tempJsonData);
    } catch (e) {
      showNotificationError(`Ocurrió un error en el perfil: ${e.message}`);
    }

    closeModal();
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={openAddNewElementModal}>Agregar Area</MenuItem>
    </Menu>
  );

  const renderModal = (
    <BKModal
      closeModal={closeModal}
      saveChanges={saveDataModal}
      open={modal}
      title={elementIsNew ? "Agregar Area" : "Editar Area"}
    >
      <MDBox sx={{ position: "relative", mx: 3, py: 2, px: 2 }}>
        <Grid container spacing={3} justifyContent="left" alignItems="left" height="100%">
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <MDInput
              variant="standard"
              type="text"
              label="Area"
              fullWidth
              required
              inputProps={{ maxLength: 20 }}
              InputLabelProps={{ shrink: true }}
              value={newValueName}
              onChange={(e) => {
                setNewValueName(e.target.value);
              }}
            />
          </Grid>
        </Grid>
      </MDBox>
    </BKModal>
  );

  const getJsonData = async () => {
    const tempJsonData = [];
    const apiResponse = await (await APIRequest("GET", "/Api/Area/Todos", null)).json();

    for (let index = 0; index < apiResponse.length; index += 1) {
      tempJsonData.push({
        id: apiResponse[index].id,
        nombre: apiResponse[index].nombre,
        /* estado: {
          id: apiResponse[index].estado.id,
          nombre: apiResponse[index].estado.nombre,
        }, */
      });
    }

    setJsonData(tempJsonData);
  };

  const deleteJsonData = async (id) => {
    if (window.confirm("¿Quiere eliminar el area?")) {
      try {
        const apiResponse = await APIRequest("DELETE", `/Api/Area/${id}`, null);

        if (apiResponse.status === 200) {
          const index = jsonData.map((item) => item.id).indexOf(id);
          const elements = [...jsonData];
          const nombreElemento = elements[index].nombre;
          elements.splice(index, 1);
          setJsonData(elements);
          showNotificationSuccess(`Se eliminó el area '${nombreElemento}' exitosamente.`);
        } else {
          const errorMessage = {
            code: apiResponse.status,
            message: `Error: ${apiResponse.status}`,
          };
          throw errorMessage;
        }
      } catch (e) {
        showNotificationError(`Ocurrió un error al eliminar el area: ${e.message}`);
      }
    }
  };

  const setEditJsonData = (valueId) => {
    setElementIsNew(false);
    setNewValueId(valueId);

    const index = jsonData.map((item) => item.id).indexOf(valueId);
    setNewValueId(jsonData[index].id);
    setNewValueName(jsonData[index].nombre);

    setModal(true);
  };

  useEffect(() => {
    getJsonData();
  }, [pathname]);

  const { columns, rows } = data(jsonData, deleteJsonData, setEditJsonData);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {renderModal}
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                <MDBox>
                  <MDTypography variant="h4" gutterBottom color="warning">
                    Areas
                  </MDTypography>
                </MDBox>
                <MDBox color="text" px={2}>
                  <Icon
                    sx={{
                      cursor: "pointer",
                      fontWeight: "bold",
                      display: userType === 1 ? "flex" : "none",
                    }}
                    fontSize="small"
                    onClick={openMenu}
                  >
                    more_vert
                  </Icon>
                </MDBox>
                {renderMenu}
              </MDBox>
              <MDBox>
                <DataTable
                  entriesPerPage={(false, { defaultValue: 10, entries: [5, 10, 25, 50] })}
                  canSearch
                  showTotalEntries
                  table={{ columns, rows }}
                  pagination={{ variant: "contained", color: "warning" }}
                  isSorted
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {renderNotificacion}
      <Footer />
    </DashboardLayout>
  );
}

export default Areas;
