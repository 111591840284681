/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// Default Components
import MDTypography from "components/MDTypography";

export default function data(jsonData, deleteJsonData, setEditJsonData) {
  const getRows = () => {
    const elements = [];

    for (let index = 0; index < jsonData.length; index += 1) {
      elements.push({
        nombreOrga: jsonData[index].nombreOrga,
        dominios: jsonData[index].dominios,
        ambiente: jsonData[index].ambiente.nombre,
        saml2EntityId: jsonData[index].saml2EntityId,
        saml2MetadataUrl: jsonData[index].saml2MetadataUrl,
        saml2SingleSignOnServiceUrl: jsonData[index].saml2SingleSignOnServiceUrl,
        usuario: jsonData[index].usuario,
        action: (
          <MDTypography color="text">
            <Tooltip
              title="Deshabilitar"
              disableInteractive
              onClick={() => deleteJsonData(jsonData[index].id)}
            >
              <Icon>delete</Icon>
            </Tooltip>{" "}
            /{" "}
            <Tooltip
              title="Editar"
              disableInteractive
              onClick={() => {
                setEditJsonData(jsonData[index]);
              }}
            >
              <Icon>edit</Icon>
            </Tooltip>
          </MDTypography>
        ),
      });
    }

    return elements;
  };

  return {
    columns: [
      // { Header: "", accessor: "icon", width: "0%", align: "center" },
      { Header: "Nombre Organización", accessor: "nombreOrga", align: "center" },
      { Header: "Dominios", accessor: "dominios", align: "center" },
      { Header: "Ambiente", accessor: "ambiente", align: "center" },
      { Header: "Saml2 EntityId", accessor: "saml2EntityId", align: "center" },
      { Header: "Saml2 MetadataUrl", accessor: "saml2MetadataUrl", align: "center" },
      {
        Header: "Saml2 SingleSignOnServiceUrl",
        accessor: "saml2SingleSignOnServiceUrl",
        align: "center",
      },
      { Header: "Usuario", accessor: "usuario", align: "center" },
      { Header: "Accion", accessor: "action", align: "center" },
    ],

    rows: getRows(),
  };
}

// Setting default values for the props of MDBadge
data.defaultProps = {
  jsonData: [],
};

// Typechecking props of the data
data.propTypes = {
  jsonData: PropTypes.objectOf(PropTypes.array),
  deleteJsonData: PropTypes.func.isRequired,
  setEditJsonData: PropTypes.func.isRequired,
};
