/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Default Components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

export default function data(jsonData, deleteJsonData, editData) {
  // const userAreaAdmin = JSON.parse(sessionStorage.getItem("userAreaAdmin"));
  // const userType = JSON.parse(sessionStorage.getItem("userType"));

  const getRows = () => {
    const elements = [];

    for (let index = 0; index < jsonData.length; index += 1) {
      elements.push({
        name: jsonData[index].name,
        blobName: jsonData[index].blobName,
        versionMin: jsonData[index].versionMin,
        versionMax: jsonData[index].versionMax,
        action: (
          <MDBox style={{ display: "flex", justifyContent: "space-between" }}>
            <MDTypography color="text" onClick={() => editData(jsonData[index])}>
              <Tooltip title="Editar prerrequisito" disableInteractive>
                <Icon>edit</Icon>
              </Tooltip>
            </MDTypography>

            <MDTypography color="text" onClick={() => deleteJsonData(jsonData[index].id)}>
              <Tooltip title="Eliminar prerrequisito" disableInteractive>
                <Icon>delete</Icon>
              </Tooltip>
            </MDTypography>
          </MDBox>
        ),
      });
    }

    return elements;
  };

  return {
    columns: [
      { Header: "Nombre", accessor: "name", align: "left" },
      { Header: "Fichero", accessor: "blobName", align: "left" },
      { Header: "Versión Mínima", accessor: "versionMin", align: "left" },
      { Header: "Versión Máxima", accessor: "versionMax", align: "left" },
      { Header: "Accion", accessor: "action", width: "100px", align: "left" },
    ],

    rows: getRows(),
  };
}

// Setting default values for the props of MDBadge
data.defaultProps = {
  jsonData: [],
};

// Typechecking props of the data
data.propTypes = {
  jsonData: PropTypes.objectOf(PropTypes.array),
  deleteJsonData: PropTypes.func.isRequired,
};
