import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import InputLabel from "@mui/material/InputLabel";
import IconButton from "@mui/material/IconButton";
import MDInput from "../../../../../components/MDInput";
import MDTypography from "../../../../../components/MDTypography";

function useCertificate(showNotificationError) {
  const [showPassword, setShowPassword] = useState(false);
  const [certificateFile, setCertificateFile] = useState("");
  const [passwordValue, setPasswordValue] = useState("");

  const handleFileUpload = (event) => {
    if (event.target.files && event.target.files.length !== 0) {
      const file = event.target.files[0];
      const validExtensions = [`.pfx`, `.p12`];
      const extension = file.name.substring(file.name.lastIndexOf(".")).toLowerCase();
      if (validExtensions.indexOf(extension) === -1) {
        showNotificationError(`La extensión del archivo es incorrecta`);
      } else {
        setCertificateFile(file);
      }
    }
  };

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const clearFields = () => {
    setShowPassword("");
    setCertificateFile("");
  };

  const organizationData = {
    CertificateFile: certificateFile,
    CertificatePassword: passwordValue,
  };

  const buttonDisabled = passwordValue === "" || certificateFile === "";

  const endAdornment = (
    <InputAdornment position="end">
      <IconButton onClick={handlePasswordVisibility}>
        {showPassword ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </InputAdornment>
  );

  const getComponent = () => (
    <>
      <Grid item xs={12} md={5.5}>
        <input
          type="file"
          accept=".pfx, .p12"
          id="file-input"
          hidden
          onChange={(event) => handleFileUpload(event)}
        />
        <InputLabel
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          htmlFor="file-input"
        >
          <Grid
            backgroundColor="#fd7e14"
            borderRadius={2}
            px={1}
            height={40}
            style={{
              color: "white",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: 10,
              fontSize: 12,
              fontWeight: "bold",
            }}
          >
            <FileUploadIcon fontSize="small" style={{ cursor: "pointer", color: "white" }} />
            <MDTypography
              fontWeight="bold"
              color="white"
              py={1}
              style={{ marginLeft: 5, paddingRight: 5, fontSize: 12 }}
            >
              SELECCIONE
            </MDTypography>
          </Grid>
          <MDInput
            label="Certificado digital"
            type="text"
            size="medium"
            variant="standard"
            fullWidth
            value={certificateFile !== "" ? certificateFile.name : "Nombre del archivo"}
            disabled
            required
            InputLabelProps={{ shrink: true }}
          />
        </InputLabel>
      </Grid>

      <Grid item xs={12} md={5.5}>
        <MDInput
          label="Contraseña cert. digital"
          placeholder="Ingrese"
          type={showPassword ? "text" : "password"}
          onChange={(event) => setPasswordValue(event.target.value)}
          size="medium"
          variant="standard"
          fullWidth
          required
          InputLabelProps={{ shrink: true }}
          InputProps={{
            inputProps: {
              maxLength: 30,
            },
            endAdornment,
          }}
        />
      </Grid>
    </>
  );

  return {
    organizationData,
    clearFields,
    getComponent,
    buttonDisabled,
  };
}

export default useCertificate;
