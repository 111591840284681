import SignInBasic from "layouts/authentication/sign-in/basic";
import Profiles from "views/admin/profiles";
import ProfileUserAdd from "views/admin/profileUsers";
import Areas from "views/admin/areas";
import ProfileFunctionalities from "views/admin/profileFunctionalities";
import EntidadesTributarias from "views/dashboards/entidadTributaria";
import GrafanaInbox from "views/dashboards/grafana";
import ListadoContribuyentes from "views/soporte/EntidadesTributarias";

// Formularios Sin Menú
import AltaEmpresaProdForm from "views/consultoria/AltaEmpresaProd/form";
import AltaEmpresaSandboxForm from "views/consultoria/AltaEmpresaSandbox/form";
import ApiKeyPRDForm from "views/soporte/ConfiguracionEmpresa/ApiKey/form";
import ApiKeySBXForm from "views/soporte/ApiKeySandBox/form";
import ActualizarRespuestaForm from "views/consultoria/API/ActualizarRespuesta/form";
import ConfiguracionPacForm from "views/varios/ComunicacionPAC/form";
import ConfiguracionPacProgramarEnvio from "views/varios/ComunicacionPAC/programarEnvio";
import InboxCargaXSLTPRDForm from "views/soporte/XDocGlobal_CargaXSLTPRD/form";
// import InboxCargaXSLTSBXForm from "views/soporte/XDocGlobal_CargaXSLTSBX/form";
import PoliticasContraseniaPRDForm from "views/consultoria/ConfiguracionEmpresa/politicasContraseniaPRD/form";
import PoliticasContraseniaSBXForm from "views/consultoria/ConfiguracionEmpresa/politicasContraseniaSBX/form";
import SingleSingOnPRDForm from "views/varios/SingleSingOnPRD/form";
import SingleSingOnSBXForm from "views/varios/SingleSingOnSBX/form";
import DocRestringidosForm from "views/consultoria/ConfiguracionEmpresa/DocRestringidos/form";
import PlantillasJasperCrystalSBXcompare from "views/producto/PlantillasJasperCrystalSBX/compare";
import PlantillasJasperCrystalPRDcompare from "views/producto/PlantillasJasperCrystal/compare";
import XMLHistoricosForm from "views/producto/XMLHistoricos/form";
import ReprocesarDocumentoForm from "views/soporte/ReprocesarDocumentos/form";
import XMLHistoricosLog from "views/producto/XMLHistoricos/log";
import XDocGlobalPermisoXSLTForm from "views/soporte/XDocGlobal_PermisosXSLT/form";
import ManagerEmpresaForm from "views/varios/Manager/CrearEmpresa/form";
import ManagerGadgetForm from "views/manager/Gadget/form";
import ManagerShortcutForm from "views/manager/shortcut/form";
import ManagerUserForm from "views/manager/usuarios/form";

//  Administración XPOS
import CrearOrganizacion from "views/admin/xpos/organizaciones/crear-organizacion";
import CrearXPOS from "views/admin/xpos/pool/crear-pool";
import LogsHistory from "views/admin/xpos/logsConfig/historyLogs";

import ContingenciasHistory from "views/admin/xpos/contingencias/history";

import CrearVersion from "views/admin/xpos/versiones/crear-version";

// Administración XDOC
import AreaDetails from "views/admin/xdoc/areas/detail";

import InstallationDetails from "views/admin/xdoc/installations/detail";

import ClientDetails from "views/admin/xdoc/clients/detail";

import VersionApproveForm from "views/admin/xdoc/versions/approve";
import VersionDetails from "views/admin/xdoc/versions/detail";
import VersionEditForm from "views/admin/xdoc/versions/edit";
import VersionsSettings from "views/admin/xdoc/versions/settings";
import PrerequisiteInstallerCreation from "views/admin/xdoc/prerequisites/add";
import PrerequisiteInstallerEdit from "views/admin/xdoc/prerequisites/edit";

// @mui icons
import Icon from "@mui/material/Icon";

// API Request
import APIRequest from "api/request";
import GetComponent from "util/getComponent";

async function routesDBv2() {
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  const response = [];

  const publicAccessStart = [
    {
      id: -1,
      type: "collapse",
      name: "Home",
      key: "home",
      icon: <Icon fontSize="medium">home_two_tone</Icon>,
      collapse: [
        {
          id: -1,
          name: "Entidades Tributarias",
          key: "entidadesTributarias",
          route: "/dashboards/entidadesTributarias",
          component: <EntidadesTributarias />,
        },
        {
          id: -1,
          name: "Colas",
          key: "grafanaInbox",
          route: "/home/grafanaInbox",
          component: <GrafanaInbox />,
        },
      ],
    },
  ];

  const adminAccess = [
    {
      id: 0,
      type: "collapse",
      name: "Gestión Usuarios",
      key: "Administracion",
      icon: <Icon fontSize="small">manage_accounts_two_tone</Icon>,
      collapse: [
        {
          id: 0,
          name: "Perfiles",
          key: "Perfiles",
          route: "/Administracion/Perfiles",
          component: <Profiles />,
        },
        {
          id: 0,
          name: "Areas",
          key: "Areas",
          route: "/Administracion/Areas",
          component: <Areas />,
        },
      ],
    },
  ];

  const eMail = userInfo ? userInfo.correo : "a@a.net";

  const standardAccess = await (await APIRequest("GET", `/api/Menu/Todos/${eMail}`, null)).json();

  const publicAccessEnd = [
    {
      id: -1,
      type: "collapse",
      name: "Listado Contribuyentes",
      key: "ListadoContribuyentes",
      route: "/ListadoContribuyentes",
      icon: <Icon fontSize="small">checklist_rtl_two_tone</Icon>,
      component: <ListadoContribuyentes />,
      noCollapse: true,
    },
  ];

  const withOutMenuAccess = [
    {
      type: "none",
      name: "basic",
      key: "BasicLogin",
      route: "/authentication/sign-in/basic",
      component: <SignInBasic />,
    },
    {
      type: "none",
      name: "PerfilUsuario",
      key: "PerfilUsuario",
      route: "/PerfilUsuario",
      component: <ProfileUserAdd />,
    },
    {
      type: "none",
      name: "PerfilFuncionalidad",
      key: "PerfilFuncionalidad",
      route: "/PerfilFuncionalidad",
      component: <ProfileFunctionalities />,
    },
    {
      type: "none",
      name: "Crear Versión",
      key: "CrearVersion",
      route: "/XPos/Versiones/CrearVersion",
      component: <CrearVersion />,
    },
    {
      type: "none",
      name: "Crear Organización",
      key: "CrearOrganizacion",
      route: "/XPos/Chile/Organizaciones/CrearOrganizacion",
      component: <CrearOrganizacion country="cl" />,
    },
    {
      type: "none",
      name: "Crear xPOS",
      key: "CrearxPOS",
      route: "/XPos/Chile/Pools/CrearxPOS",
      component: <CrearXPOS country="cl" />,
    },
    {
      type: "none",
      name: "Logs Históricos",
      key: "LogsHistoricos",
      route: "/XPos/Chile/Logs/Historial",
      component: <LogsHistory country="cl" />,
    },
    {
      type: "none",
      name: "Crear Organización",
      key: "CrearOrganizacion",
      route: "/XPos/Colombia/Organizaciones/CrearOrganizacion",
      component: <CrearOrganizacion country="co" />,
    },
    {
      type: "none",
      name: "Crear xPOS",
      key: "CrearxPOS",
      route: "/XPos/Colombia/Pools/CrearxPOS",
      component: <CrearXPOS country="co" />,
    },
    {
      type: "none",
      name: "Logs Históricos",
      key: "LogsHistoricos",
      route: "/XPos/Colombia/Logs/Historial",
      component: <LogsHistory country="co" />,
    },
    {
      type: "none",
      name: "Contingencias",
      key: "Contingencias",
      route: "/XPos/Colombia/Contingencias/Historial",
      component: <ContingenciasHistory country="co" />,
    },
    {
      type: "none",
      name: "Crear Organización",
      key: "CrearOrganizacion",
      route: "/XPos/Paraguay/Organizaciones/CrearOrganizacion",
      component: <CrearOrganizacion country="py" />,
    },
    {
      type: "none",
      name: "Crear xPOS",
      key: "CrearxPOS",
      route: "/XPos/Paraguay/Pools/CrearxPOS",
      component: <CrearXPOS country="py" />,
    },
    {
      type: "none",
      name: "Logs Históricos",
      key: "LogsHistoricos",
      route: "/XPos/Paraguay/Logs/Historial",
      component: <LogsHistory country="py" />,
    },
    {
      type: "none",
      name: "Crear Organización",
      key: "CrearOrganizacion",
      route: "/XPos/Guatemala/Organizaciones/CrearOrganizacion",
      component: <CrearOrganizacion country="gt" />,
    },
    {
      type: "none",
      name: "Crear xPOS",
      key: "CrearxPOS",
      route: "/XPos/Guatemala/Pools/CrearxPOS",
      component: <CrearXPOS country="gt" />,
    },
    {
      type: "none",
      name: "Logs Históricos",
      key: "LogsHistoricos",
      route: "/XPos/Guatemala/Logs/Historial",
      component: <LogsHistory country="gt" />,
    },
    {
      type: "none",
      name: "Detalles",
      key: "AreaDetails",
      route: "/AdministracionXDoc/Areas/Detalles",
      component: <AreaDetails />,
    },
    {
      type: "none",
      name: "Detalles",
      key: "InstallationDetails",
      route: "/AdministracionXDoc/Instalaciones/Detalles",
      component: <InstallationDetails />,
    },
    {
      type: "none",
      name: "Detalles",
      key: "ClientDetails",
      route: "/AdministracionXDoc/Clientes/Detalles",
      component: <ClientDetails />,
    },
    {
      type: "none",
      name: "Abrobar versión'",
      key: "ApproveVersion",
      route: "/AdministracionXDoc/Versiones/Aprobar",
      component: <VersionApproveForm />,
    },
    {
      type: "none",
      name: "Configuraciones de versionado",
      key: "VersionSettings",
      route: "/AdministracionXDoc/Versiones/Configuraciones",
      component: <VersionsSettings />,
    },
    {
      type: "none",
      name: "Editar versión'",
      key: "VersionEdit",
      route: "/AdministracionXDoc/Versiones/Editar",
      component: <VersionEditForm />,
    },
    {
      type: "none",
      name: "Detalles",
      key: "VersionDetails",
      route: "/AdministracionXDoc/Versiones/Detalles",
      component: <VersionDetails />,
    },
    {
      type: "none",
      name: "Nuevo prerrequisito",
      key: "AddPrerequisite",
      route: "/AdministracionXDoc/Versiones/Prerrequisitos/Crear",
      component: <PrerequisiteInstallerCreation />,
    },
    {
      type: "none",
      name: "Editar prerrequisito",
      key: "EditPrerequisite",
      route: "/AdministracionXDoc/Versiones/Prerrequisitos/Editar",
      component: <PrerequisiteInstallerEdit />,
    },
    {
      type: "none",
      name: "AltaProduccionForm",
      key: "AltaProduccionForm",
      route: "/PACPanama/AltaProduccionForm",
      component: <AltaEmpresaProdForm />,
    },
    {
      type: "none",
      name: "AltaSandboxForm",
      key: "AltaSandboxForm",
      route: "/PACPanama/AltaSandboxForm",
      component: <AltaEmpresaSandboxForm />,
    },
    {
      type: "none",
      name: "ApiKeyPRDForm",
      key: "ApiKeyPRDForm",
      route: "/ApiKey/ApiKeyPRDForm",
      component: <ApiKeyPRDForm />,
    },
    {
      type: "none",
      name: "ApiKeySBXForm",
      key: "ApiKeySBXForm",
      route: "/ApiKey/ApiKeySBXForm",
      component: <ApiKeySBXForm />,
    },
    {
      type: "none",
      name: "ActualizarRespuestaForm",
      key: "ActualizarRespuestaForm",
      route: "/Api/SendDocumentToAuthorityForm",
      component: <ActualizarRespuestaForm />,
    },
    {
      type: "none",
      name: "ConfiguracionPacForm",
      key: "ConfiguracionPacForm",
      route: "/ComunicacionPAC/ConfiguracionPacForm",
      component: <ConfiguracionPacForm />,
    },
    {
      type: "none",
      name: "ConfiguracionPacProgramarEnvio",
      key: "ConfiguracionPacProgramarEnvio",
      route: "/ComunicacionPAC/ProgramarEnvio",
      component: <ConfiguracionPacProgramarEnvio />,
    },
    {
      type: "none",
      name: "InboxCargaXSLTPRDForm",
      key: "InboxCargaXSLTPRDForm",
      route: "/Inbox-CargaXSLT/ProduccionForm",
      component: <InboxCargaXSLTPRDForm />,
    },
    /* {
      type: "none",
      name: "InboxCargaXSLTSBXForm",
      key: "InboxCargaXSLTSBXForm",
      route: "/Inbox-CargaXSLT/SandboxForm",
      component: <InboxCargaXSLTSBXForm />,
    }, */
    {
      type: "none",
      name: "PoliticasContraseniaPRDForm",
      key: "PoliticasContraseniaPRDForm",
      route: "/PoliticasContrasenia/PRDForm",
      component: <PoliticasContraseniaPRDForm />,
    },
    {
      type: "none",
      name: "PoliticasContraseniaSBXForm",
      key: "PoliticasContraseniaSBXForm",
      route: "/PoliticasContrasenia/SBXForm",
      component: <PoliticasContraseniaSBXForm />,
    },
    {
      type: "none",
      name: "SingleSingOnPRDForm",
      key: "SingleSingOnPRDForm",
      route: "/SingleSingOn/PRDForm",
      component: <SingleSingOnPRDForm />,
    },
    {
      type: "none",
      name: "SingleSingOnSBXForm",
      key: "SingleSingOnSBXForm",
      route: "/SingleSingOn/SBXForm",
      component: <SingleSingOnSBXForm />,
    },
    {
      type: "none",
      name: "DocRestringidosForm",
      key: "DocRestringidosForm",
      route: "/DocRestringidos/DocRestringidosForm",
      component: <DocRestringidosForm />,
    },
    {
      type: "none",
      name: "PlantillasJasperCrystalPRDcompare",
      key: "PlantillasJasperCrystalPRDcompare",
      route: "/PlantillasJasperCrystal/compare",
      component: <PlantillasJasperCrystalPRDcompare />,
    },
    {
      type: "none",
      name: "PlantillasJasperCrystalSBXcompare",
      key: "PlantillasJasperCrystalSBXcompare",
      route: "/PlantillasJasperCrystal/SBXcompare",
      component: <PlantillasJasperCrystalSBXcompare />,
    },
    {
      type: "none",
      name: "XMLHistoricosForm",
      key: "XMLHistoricosForm",
      route: "/XMLHistoricos/Form",
      component: <XMLHistoricosForm />,
    },
    {
      type: "none",
      name: "ReprocesarDocumentoForm",
      key: "ReprocesarDocumentoForm",
      route: "/ReprocesarDocumentos/ReprocesarDocumentoForm",
      component: <ReprocesarDocumentoForm />,
    },
    {
      type: "none",
      name: "XMLHistoricosLog",
      key: "XMLHistoricosLog",
      route: "/XMLHistoricos/Log",
      component: <XMLHistoricosLog />,
    },
    {
      type: "none",
      name: "InboxPermisoXSLTForm",
      key: "InboxPermisoXSLTForm",
      route: "/Inbox-PermisoXSLT/Form",
      component: <XDocGlobalPermisoXSLTForm />,
    },
    {
      type: "none",
      name: "ManagerEmpresaForm",
      key: "ManagerEmpresaForm",
      route: "/Manager/Empresa/Form",
      component: <ManagerEmpresaForm />,
    },
    {
      type: "none",
      name: "ManagerGadgetForm",
      key: "ManagerGadgetForm",
      route: "/Manager/Gadget/Form",
      component: <ManagerGadgetForm />,
    },
    {
      type: "none",
      name: "ManagerShortcutForm",
      key: "ManagerShortcutForm",
      route: "/Manager/Shortcut/Form",
      component: <ManagerShortcutForm />,
    },
    {
      type: "none",
      name: "ManagerUserForm",
      key: "ManagerUserForm",
      route: "/Manager/User/Form",
      component: <ManagerUserForm />,
    },
  ];

  const getIconByAreaId = (areaId) => {
    let iconResult = "dashboard";

    switch (areaId) {
      case 1: // "General"
        iconResult = "dashboard";
        break;
      case 2: // "Soporte"
        iconResult = "support_agent_two_tone";
        break;
      case 3: // "Consultoria"
        iconResult = "engineering_two_tone";
        break;
      case 4: // "Producto"
        iconResult = "tips_and_updates_sharp';";
        break;
      case 5: // "Finanzas"
        iconResult = "monetization_on_two_tone";
        break;
      case 6: // "Tecnologia"
        iconResult = "code_two_tone";
        break;
      case 7: // "Test Area"
        iconResult = "dashboard";
        break;
      case 8: // "xDoc"
        iconResult = "computer_two_tone";
        break;
      case 9: // "xPOS"
        iconResult = "devices";
        break;
      case 11: // Comercial
        iconResult = "handshake";
        break;
      case 12: // "Partners"
        iconResult = "business";
        break;
      case 13: // "Manager"
        iconResult = "hub";
        break;
      default:
        iconResult = "dashboard";
        break;
    }

    return iconResult;
  };

  for (let index = 0; index < standardAccess.length; index += 1) {
    standardAccess[index].icon = (
      <Icon fontSize="small">{getIconByAreaId(standardAccess[index].icon)}</Icon>
    );

    for (let idx = 0; idx < standardAccess[index].collapse.length; idx += 1) {
      if (standardAccess[index].collapse[idx].collapse) {
        for (let idx2 = 0; idx2 < standardAccess[index].collapse[idx].collapse.length; idx2 += 1) {
          if (standardAccess[index].collapse[idx].collapse[idx2].component) {
            standardAccess[index].collapse[idx].collapse[idx2].component = GetComponent(
              standardAccess[index].collapse[idx].collapse[idx2].component
            );
          }
        }
      }

      if (standardAccess[index].collapse[idx].component) {
        standardAccess[index].collapse[idx].component = GetComponent(
          standardAccess[index].collapse[idx].component
        );
      }
    }
  }

  response.push(
    ...publicAccessStart,
    ...adminAccess,
    ...standardAccess,
    ...publicAccessEnd,
    ...withOutMenuAccess
  );

  return response;
}

export default routesDBv2;
