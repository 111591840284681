/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
// import Tooltip from "@mui/material/Tooltip";
// import Icon from "@mui/material/Icon";
// import Link from "@mui/material/Link";

// Default Components
// import MDTypography from "components/MDTypography";

export default function data(jsonData) {
  const getRows = () => {
    const elements = [];

    for (let index = 0; index < jsonData.length; index += 1) {
      elements.push({
        accountCode: jsonData[index].accountCode,
        pais: jsonData[index].pais.nombre,
        funcionality: jsonData[index].funcionalityName,
        yearMonth: jsonData[index].yearMonth,
        startMonthlyLimit: jsonData[index].startMonthlyLimit,
        endMonthlyLimit: jsonData[index].endMonthlyLimit,
        requestCounter: jsonData[index].requestCounter,
      });
    }

    return elements;
  };

  return {
    columns: [
      // { Header: "", accessor: "icon", width: "0%", align: "center" },
      { Header: "TaxId", accessor: "accountCode", align: "center" },
      { Header: "Pais", accessor: "pais", align: "center" },
      { Header: "Funcionalidad", accessor: "funcionality", align: "center" },
      { Header: "Fecha Request", accessor: "yearMonth", align: "center" },
      { Header: "N° Inicial", accessor: "startMonthlyLimit", align: "center" },
      { Header: "N° Final", accessor: "endMonthlyLimit", align: "center" },
      { Header: "Cantidad Request", accessor: "requestCounter", align: "center" },
      // { Header: "Accion", accessor: "action", align: "center" },
    ],

    rows: getRows(),
  };
}

// Setting default values for the props of MDBadge
data.defaultProps = {
  jsonData: [],
};

// Typechecking props of the data
data.propTypes = {
  jsonData: PropTypes.objectOf(PropTypes.array),
  // descargarPLantilla: PropTypes.func.isRequired,
};
