import * as React from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

export default function Loading({ load }) {
  return (
    <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10000 }} open={load}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

Loading.defaultProps = {
  // title: "",
};

// Typechecking props for MDSnackbar
Loading.propTypes = {
  load: PropTypes.bool.isRequired,
};
