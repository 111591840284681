/* eslint-disable react/prop-types */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// @mui material components
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  TextField,
} from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DataTable from "examples/Tables/DataTable";

// Data
import data from "views/admin/xdoc/areastates/data";

// API Request
import APIRequest from "api/xDocApiRequest";
import MDButton from "components/MDButton";

function AreaStates(props) {
  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  const [jsonData, setJsonData] = useState([]);
  const { pathname } = useLocation();
  const { area, installationGuid, clientId } = props;

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );

  const getJsonData = async () => {
    const tempJsonData = [];

    const apiResponse = await (
      await APIRequest("POST", "/AreaState/GetByArea", {
        areaName: area.name,
        installationGuid,
      })
    ).json();

    apiResponse.forEach((item) => {
      tempJsonData.push(item);
    });

    setJsonData(tempJsonData);
  };

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [stateIdToDelete, setStateIdToDelete] = useState("");
  const deleteJsonData = async (id, confirmed) => {
    if (!confirmed) {
      setStateIdToDelete(id);
      setShowConfirmDelete(true);
      return;
    }

    try {
      const apiResponse = await APIRequest("DELETE", `/AreaState/Delete?id=${id}`, null);

      if (apiResponse.status === 200) {
        const index = jsonData.map((item) => item.id).indexOf(id);
        const elements = [...jsonData];
        elements.splice(index, 1);
        setJsonData(elements);
        configNotification(
          "warning",
          "check",
          "Notificación",
          `Se eliminó el estado exitosamente.`,
          "",
          true
        );
      } else {
        const errorMessage = {
          code: apiResponse.status,
          message: `Error: ${apiResponse.status}`,
        };
        throw errorMessage;
      }
    } catch (e) {
      configNotification(
        "error",
        "warning",
        "Notificación",
        `Ocurrió un error al eliminar el estado: ${e.message}`,
        "",
        true
      );
    }
  };
  const handleCancelDelete = () => {
    setShowConfirmDelete(false);
  };
  const handleConfirmDelete = () => {
    deleteJsonData(stateIdToDelete, true);
    setShowConfirmDelete(false);
  };

  const [showConfirmBackup, setShowConfirmBackup] = useState(false);
  const [backupComments, setBackupComments] = useState("");
  const backupArea = async (confirmed) => {
    if (!confirmed) {
      setShowConfirmBackup(true);
      return;
    }

    try {
      const apiResponse = await APIRequest("POST", "/Area/Backup", {
        clientId,
        installationGuid,
        areaName: area.name,
        comments: backupComments,
      });

      if (apiResponse.status === 200) {
        getJsonData();
        configNotification(
          "warning",
          "check",
          "Notificación",
          `Se respaldó el estado exitosamente.`,
          "",
          true
        );
      } else {
        const errorMessage = {
          code: apiResponse.status,
          message: `Error: ${apiResponse.status}`,
        };
        throw errorMessage;
      }
    } catch (e) {
      configNotification(
        "error",
        "warning",
        "Notificación",
        `Ocurrió un error al respaldar el estado: ${e.message}`,
        "",
        true
      );
    }
  };
  const handleCancelBackup = () => {
    setShowConfirmBackup(false);
  };
  const handleConfirmBackup = () => {
    backupArea(true);
    setShowConfirmBackup(false);
  };

  useEffect(() => {
    getJsonData();
  }, [pathname]);

  const { columns, rows } = data(jsonData, deleteJsonData);

  return (
    <>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox>
          <MDTypography variant="h5" gutterBottom color="warning">
            Estados
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox>
        <MDButton
          variant="outlined"
          onClick={() => backupArea()}
          color="success"
          style={{ marginLeft: "25px" }}
        >
          <Icon style={{ marginRight: "5px" }}>backup</Icon>
          Respaldar estado actual
        </MDButton>
      </MDBox>
      <MDBox>
        <DataTable
          entriesPerPage={(false, { defaultValue: 10, entries: [5, 10, 25, 50] })}
          canSearch
          showTotalEntries
          table={{ columns, rows }}
          pagination={{ variant: "contained", color: "warning" }}
          isSorted
          noEndBorder
        />
      </MDBox>
      {renderNotificacion}
      <Dialog
        open={showConfirmDelete}
        onClose={handleCancelDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">¿Quiere eliminar el área?</DialogTitle>
        <DialogActions>
          <Button onClick={handleCancelDelete}>Cancelar</Button>
          <Button onClick={handleConfirmDelete} autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showConfirmBackup}
        onClose={handleCancelBackup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">¿Quiere respaldar el área?</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Comentarios (opcional)"
            fullWidth
            variant="standard"
            onChange={(e) => setBackupComments(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelBackup}>Cancelar</Button>
          <Button onClick={handleConfirmBackup} autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AreaStates;
