export const ROOT_TO_XML_LABEL = "<root> a XML";
export const XML_TO_DTE = "XML a DTE";
export const XSD = "XSD";
export const CUSTOMIZED = "Customized Response";
export const SCHEMATRON = "Schematron";
export const EQUIVALENT_DOCUMENT = "Doc Equivalente";
export const ATTACHED_DOCUMENT = "Attached Document";
export const TYPE_PUBLISH = "Tipo de Publicación";
export const ENDPOINT_STORAGE = "Endpoint Storage";
export const OTHER = "<other> a XML";

const columns = [
  { Header: "Nombre del archivo", accessor: "fileName", align: "center" },
  { Header: "Versión", accessor: "version", align: "center" },
  { Header: "Actualizado por", accessor: "updatedBy", align: "center" },
  { Header: "", accessor: "actions", align: "right" },
];

export const TYPE_FIELDS = {
  GLOBAL: 0,
  CUSTOMIZE: 1,
};

export default columns;
