/* eslint-disable react/prop-types */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import { Button, Checkbox, Dialog, DialogActions, DialogTitle } from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// API Request
import APIRequest from "api/xDocApiRequest";

// Custom components
import MDButton from "components/MDButton";
import MDEditor from "components/MDEditor";
import { useLocation, useNavigate } from "react-router-dom";

function VersionApproveForm() {
  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  const {
    state: { version, route },
  } = useLocation();
  const navigate = useNavigate();

  const [versionToApprove, setVersionToApprove] = useState(version);
  const approvedNumber = version.number.substring(0, version.number.lastIndexOf("."));
  const [spanishNotes, setSpanishNotes] = useState("");
  // eslint-disable-next-line no-unused-vars
  const handleChangeSpanish = (content, delta, source, editor) => {
    const text = editor.getText(content);
    setSpanishNotes(text);
  };
  const [portugueseNotes, setPortugueseNotes] = useState("");
  // eslint-disable-next-line no-unused-vars
  const handleChangePortuguese = (content, delta, source, editor) => {
    const text = editor.getText(content);
    setPortugueseNotes(text);
  };

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };

  const [showConfirmApprove, setShowConfirmApprove] = useState(false);
  const approveVersion = async (confirmed) => {
    if (!confirmed) {
      setShowConfirmApprove(true);
      return;
    }

    try {
      const apiResponse = await APIRequest("POST", "/Version/Approve", {
        ...versionToApprove,
        releaseNotes: [
          { culture: "es-MX", content: spanishNotes },
          { culture: "pt-BR", content: portugueseNotes },
        ],
      });

      if (apiResponse.status === 200) {
        navigate(route, {
          state: {
            notificationParams: [
              "warning",
              "check",
              "Notificación",
              `Se aprobó la versión '${approvedNumber}' exitosamente.`,
              "",
              true,
            ],
          },
        });
      } else {
        const errorMessage = {
          code: apiResponse.status,
          message: `Error: ${apiResponse.status}`,
        };
        throw errorMessage;
      }
    } catch (e) {
      configNotification(
        "error",
        "warning",
        "Notificación",
        `Ocurrió un error al aprobar la versión: ${e.message}`,
        "",
        true
      );
    }
  };
  const handleCancelApprove = () => {
    setShowConfirmApprove(false);
  };
  const handleConfirmApprove = () => {
    approveVersion(true);
    setShowConfirmApprove(false);
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                <MDBox>
                  <MDTypography variant="h4" gutterBottom color="warning">
                    Aprobar versión
                  </MDTypography>
                </MDBox>
              </MDBox>
              <MDBox style={{ margin: "20px" }}>
                <MDBox>
                  <MDTypography display="flex" variant="h6" gutterBottom color="text">
                    <strong>Número: </strong>
                    <p style={{ color: "#a59f9f", marginLeft: "5px" }}>
                      {approvedNumber} ({versionToApprove.number})
                    </p>
                  </MDTypography>
                </MDBox>
                <MDBox>
                  <MDTypography variant="h6" gutterBottom color="text">
                    Mandatoria:
                    <Checkbox
                      checked={versionToApprove.isMandatory}
                      onClick={() =>
                        setVersionToApprove({
                          ...versionToApprove,
                          isMandatory: !versionToApprove.isMandatory,
                        })
                      }
                      color="primary"
                      fontSize="medium"
                    />
                  </MDTypography>
                </MDBox>
                <MDBox>
                  <MDBox>
                    <MDTypography variant="h6" gutterBottom color="text">
                      Notas en español (es-MX):
                    </MDTypography>
                    <MDEditor
                      placeholder="Escriba las notas en español..."
                      modules={{ toolbar: false }}
                      onChange={handleChangeSpanish}
                    />
                  </MDBox>
                  <MDBox>
                    <MDTypography variant="h6" gutterBottom color="text">
                      Notas en portugués (pt-BR):
                    </MDTypography>
                    <MDEditor
                      placeholder="Escriba las notas en portugués..."
                      modules={{ toolbar: false }}
                      onChange={handleChangePortuguese}
                    />
                  </MDBox>
                </MDBox>
                <MDBox style={{ marginTop: "20px" }}>
                  <MDButton
                    style={{ marginRight: "10px" }}
                    variant="outlined"
                    onClick={() => approveVersion()}
                    color="success"
                  >
                    Aprobar
                  </MDButton>
                  <MDButton variant="outlined" onClick={() => navigate(route)} color="warning">
                    Cancelar
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {renderNotificacion}

      <Dialog
        open={showConfirmApprove}
        onClose={handleCancelApprove}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">¿Quiere aprobar la versión?</DialogTitle>
        <DialogActions>
          <Button onClick={handleCancelApprove}>Cancelar</Button>
          <Button onClick={handleConfirmApprove} autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
      <Footer />
    </DashboardLayout>
  );
}

export default VersionApproveForm;
