import React, { useState } from "react";
import PropTypes from "prop-types";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, Menu } from "@mui/material";
import { EXTENSIONS_FILE } from "views/admin/xpos/_constants/configGlobal/showContent";
import DownloadFile from "../DownloadFile";
import UpdateFile from "../UpdateFile";
import DeleteFile from "../DeleteFile";
// import History from "../History";

// TODO: props que se puede llevar a un estado global organizationConfigGuid, country,
// TODO: actions reducers que se pueden ser globales setLoading, showNotificationError, showNotificationSuccess, setData

function ThreeDotsMenu({
  country,
  fileName,
  keyResponse,
  setLoading,
  organizationConfigGuid,
  showNotificationError,
  showNotificationSuccess,
  setData,
  fileExtension,
  processName,
  documentVersion,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {fileName && (
          <div>
            {documentVersion !== "" && (
              <DownloadFile
                handleClose={handleClose}
                country={country}
                fileName={fileName}
                setLoading={setLoading}
                organizationConfigGuid={organizationConfigGuid}
                showNotificationSuccess={showNotificationSuccess}
                showNotificationError={showNotificationError}
              />
            )}
            <UpdateFile
              handleClose={handleClose}
              country={country}
              fileName={fileName}
              setLoading={setLoading}
              organizationConfigGuid={organizationConfigGuid}
              showNotificationSuccess={showNotificationSuccess}
              showNotificationError={showNotificationError}
              setData={setData}
              keyResponse={keyResponse}
              fileExtension={fileExtension}
            />
          </div>
        )}
        {/* TODO: estos componentes quedan pendientes para un siguiente interación
          <History handleClose={handleClose} />
        */}
        {organizationConfigGuid && documentVersion !== "" && (
          <DeleteFile
            handleClose={handleClose}
            country={country}
            fileName={fileName}
            setLoading={setLoading}
            organizationConfigGuid={organizationConfigGuid}
            showNotificationSuccess={showNotificationSuccess}
            showNotificationError={showNotificationError}
            setData={setData}
            keyResponse={keyResponse}
            processName={processName}
            documentVersion={documentVersion}
          />
        )}
      </Menu>
    </div>
  );
}

ThreeDotsMenu.defaultProps = {
  organizationConfigGuid: "",
  fileExtension: EXTENSIONS_FILE.XSLT,
  processName: "",
  documentVersion: "",
};

ThreeDotsMenu.propTypes = {
  country: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  setLoading: PropTypes.func.isRequired,
  showNotificationError: PropTypes.func.isRequired,
  showNotificationSuccess: PropTypes.func.isRequired,
  setData: PropTypes.func.isRequired,
  keyResponse: PropTypes.string.isRequired,
  organizationConfigGuid: PropTypes.string,
  fileExtension: PropTypes.string,
  processName: PropTypes.string,
  documentVersion: PropTypes.string,
};

export default ThreeDotsMenu;
