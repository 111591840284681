/* eslint-disable react/prop-types */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// @mui material components

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DataTable from "examples/Tables/DataTable";

// Data
import data from "views/admin/xdoc/updates/data";

// API Request
import APIRequest from "api/xDocApiRequest";

function Updates(props) {
  const [jsonData, setJsonData] = useState([]);
  const { pathname } = useLocation();
  const { version } = props;

  const getJsonData = async () => {
    const tempJsonData = [];

    let versionParam = "";
    if (version) {
      versionParam = `?version=${version.number}`;
    }
    const apiResponse = await (
      await APIRequest("GET", `/Version/GetUpdates${versionParam}`, null)
    ).json();

    apiResponse.forEach((item) => {
      tempJsonData.push(item);
    });

    setJsonData(tempJsonData);
  };

  useEffect(() => {
    getJsonData();
  }, [pathname]);

  const { columns, rows } = data(jsonData);

  return (
    <MDBox>
      <DataTable
        entriesPerPage={(false, { defaultValue: 10, entries: [5, 10, 25, 50] })}
        canSearch
        showTotalEntries
        table={{ columns, rows }}
        pagination={{ variant: "contained", color: "warning" }}
        isSorted
        noEndBorder
      />
    </MDBox>
  );
}

export default Updates;
