/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
// import Icon from "@mui/material/Icon";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";

import { styled } from "@mui/material/styles";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import BKModal from "components/BKModal";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDInput from "components/MDInput";
import MDDatePicker from "components/MDDatePicker";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import data from "views/soporte/ContingenciaDIAN/data";

// API Request
import APIRequest from "api/request";

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));
function useInterval(callback, delay) {
  const savedCallback = useRef();
  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    const tick = () => {
      savedCallback.current();
    };
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
    return () => {};
  }, [delay]);
}
function ContingenciaDIAN() {
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  const process = JSON.parse(sessionStorage.getItem("disabledReprocess"));
  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  const [modal, setModal] = useState(false);
  const [modalI, setModalI] = useState(false);
  const [newValueNit, setNewValueNit] = useState("");
  const [jsonData, setJsonData] = useState([]);
  const [jsonDataI, setJsonDataI] = useState([]);
  const { pathname } = useLocation();

  const [elementIsNew, setElementIsNew] = useState(true);
  const [newValueId, setNewValueId] = useState("");
  const pais = { Id: 1, Nombre: "Colombia" };
  const [newValueDateStart, setNewValueDateStart] = useState(Date.now);
  const [newValueDateEnd, setNewValueDateEnd] = useState(Date.now);
  const [disableDates, setDisableDates] = useState([]);
  const [count, setCount] = useState(0);
  const [countActive, setCountActive] = useState(0);
  const [loadReprocess, setLoadReprocess] = useState(0);
  // const [disabledReprocess, setDisabledReprocess] = useState(false);
  const [isRunning, setIsRunning] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [estadoDocument, setEstadoDocument] = useState("");
  const [key, setKey] = useState(0);
  const [load, setLoad] = useState(false);
  const [current, setCurrent] = useState(null);
  const [desactiveProgramar, setDesactiveProgramar] = useState(false);
  const [desactiveForzar, setDesactiveForzar] = useState(false);
  const [contingencia, setContingencia] = useState(null);
  const [estadoContingencia, setEstadoContingencia] = useState(false);
  const [countCheckEstado, setCountCheckEstado] = useState(0);
  // const [enCola, setEnCola] = useState(false);
  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };
  const loading = (
    <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={load}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
  const handleDelete = (chipToDelete) => async () => {
    setJsonDataI((values) => values.filter((value) => value.id !== chipToDelete.id));
    if (window.confirm("¿Quieres eliminar esta empresa?")) {
      try {
        const apiResponse = await APIRequest(
          "DELETE",
          `/Api/Contingencia/EmpresaConPrioridad/${chipToDelete.id}`,
          null
        );

        if (apiResponse.status === 200) {
          const index = jsonDataI.map((item) => item.id).indexOf(chipToDelete.id);
          const elements = [...jsonDataI];
          const nombreElemento = elements[index].nit;
          elements.splice(index, 1);
          setJsonDataI(elements);
          configNotification(
            "warning",
            "check",
            "Notificación",
            `Se eliminó el area '${nombreElemento}' exitosamente.`,
            "",
            true
          );
        } else {
          const errorMessage = {
            code: apiResponse.status,
            message: `Error: ${apiResponse.status}`,
          };
          throw errorMessage;
        }
      } catch (e) {
        configNotification(
          "error",
          "warning",
          "Notificación",
          `Ocurrió un error al eliminar el area: ${e.message}`,
          "",
          true
        );
      }
    }
  };
  const showNotificationError = (message) => {
    configNotification("error", "warning", "Notificación", message, "", true);
  };

  const showNotificationSucces = (message) => {
    configNotification("warning", "check", "Notificación", message, "", true);
  };
  const deleteJsonData = async (id) => {
    if (window.confirm("¿Quiere eliminar el registro?")) {
      setLoad(true);
      try {
        const apiResponse = await APIRequest("DELETE", `/Api/Contingencia/${id}`, null);

        if (apiResponse.status === 200) {
          const index = jsonData.map((item) => item.id).indexOf(id);
          const elements = [...jsonData];
          elements.splice(index, 1);
          setJsonData(elements);
          configNotification(
            "warning",
            "check",
            "Notificación",
            `Se deshabilitó la contingencia exitosamente.`,
            "",
            true
          );
        } else {
          const errorMessage = {
            code: apiResponse.status,
            message: `Error: ${apiResponse.status}`,
          };
          throw errorMessage;
        }
      } catch (e) {
        showNotificationError(`Ocurrió un error al deshabilitar el registro: ${e.message}`);
      }
      setLoad(false);
    }
  };
  const setEditEstado = async (valueId) => {
    setLoad(true);
    const today = new Date();
    const index = jsonData.map((item) => item.id).indexOf(valueId);

    const tempJsonData = jsonData;
    if (!tempJsonData[index].disableActive) {
      let startDate = new Date(tempJsonData[index].fechaInicio);
      let endtDate = new Date(tempJsonData[index].fechaFin);
      const newStatus = tempJsonData[index].estado.id === 1 ? 2 : 3;
      let message = "";
      if (newStatus === 2) {
        startDate = today;
        message = "Se activó exitosamente";
        setDesactiveForzar(true);
        setDesactiveProgramar(true);
      } else if (newStatus === 3) {
        endtDate = today;
        message = "Se desactivó exitosamente";
      }
      const body = {
        fechaAccion: today,
        fechaInicio: startDate,
        fechaFin: endtDate,
        estado: newStatus,
        usuario: userInfo.correo,
        pais: 1,
      };
      let estado = "Desactivado";
      if (newStatus === 1) estado = "Pendiente";
      else if (newStatus === 2) estado = "Activado";
      let usuario = `${tempJsonData[index].usuario}`;
      if (usuario !== userInfo.correo) usuario = `${usuario}, ${userInfo.correo}`;
      const editElement = {
        id: tempJsonData[index].id,
        estado: {
          id: newStatus,
          nombre: estado,
        },
        fechaInicio: startDate,
        fechaFin: endtDate,
        usuario,
        fechaAccion: today,
      };

      try {
        const apiResponse = await APIRequest(
          "PATCH",
          `/Api/Contingencia/${tempJsonData[index].id}`,
          body
        );
        if (apiResponse.status === 200) {
          tempJsonData[index] = editElement;
          showNotificationSucces(message);
        } else {
          const errorMessage = {
            code: apiResponse.status,
            message: `Error: ${apiResponse.status}`,
          };
          throw errorMessage;
        }
        setJsonData(tempJsonData);
      } catch (e) {
        configNotification(
          "error",
          "warning",
          "Notificación",
          `Ocurrió un error en la contingencia: ${e.message}`,
          "",
          true
        );
      }
    }
    setLoad(false);
  };
  const setEstadosReprocesadoDocumentos = async () => {
    try {
      if (count % 30 === 0) {
        const body = current;
        const tempJsonData = jsonData;
        const index = jsonData.map((item) => item.id).indexOf(key);
        const apiResponse = await APIRequest(
          "POST",
          `/Api/Contingencia/EstadoReproceso/${key}`,
          current
        );
        if (apiResponse.status === 200) {
          const dataResponse = await apiResponse.json();
          setEstadoDocument(dataResponse);
          setLoadReprocess(0);
          if (Number(dataResponse.procesados) === Number(dataResponse.total)) {
            sessionStorage.setItem("disabledReprocess", true);
            const disabledReprocess = true;
            setIsRunning(false);
            setCount(0);
            body.estado = 5;
            setCurrent(body);
            const today = new Date();
            let usuario = `${tempJsonData[index].usuario}`;
            if (usuario !== userInfo.correo) usuario = `${usuario}, ${userInfo.correo}`;
            const editElement = {
              id: tempJsonData[index].id,
              estado: {
                id: 5,
                nombre: "Reprocesado",
              },
              fechaInicio: tempJsonData[index].fechaInicio,
              fechaFin: tempJsonData[index].fechaFin,
              usuario,
              fechaAccion: today,
              disabledReprocess,
            };
            await APIRequest("PATCH", `/Api/Contingencia/${key}`, current);
            tempJsonData[index] = editElement;
            configNotification(
              "warning",
              "check",
              "Notificación",
              `Se Reprocesó los documentos exitosamente.`,
              "",
              true
            );
            setDesactiveForzar(false);
            setDesactiveProgramar(false);
          }
          if (count === 60 && tempJsonData[index].estado.id === 4) {
            sessionStorage.setItem("disabledReprocess", false);
            const disabledReprocess = false;
            const editElement = {
              id: tempJsonData[index].id,
              estado: tempJsonData[index].estado,
              fechaInicio: tempJsonData[index].fechaInicio,
              fechaFin: tempJsonData[index].fechaFin,
              usuario: tempJsonData[index].usuario,
              fechaAccion: tempJsonData[index].fechaAccion,
              disabledReprocess,
            };
            tempJsonData[index] = editElement;
          }
        } else {
          setIsRunning(false);
          setCount(0);
          body.estado = 3;
          setCurrent(body);
          const today = new Date();
          let usuario = `${tempJsonData[index].usuario}`;
          if (usuario !== userInfo.correo) usuario = `${usuario}, ${userInfo.correo}`;
          const editElement = {
            id: tempJsonData[index].id,
            estado: {
              id: 3,
              nombre: "Desactivado",
            },
            fechaInicio: tempJsonData[index].fechaInicio,
            fechaFin: tempJsonData[index].fechaFin,
            usuario,
            fechaAccion: today,
          };
          await APIRequest("PATCH", `/Api/Contingencia/${key}`, current);
          tempJsonData[index] = editElement;
          const errorMessage = {
            code: apiResponse.status,
            message: `Error: ${apiResponse.status}`,
          };
          throw errorMessage;
        }
        setJsonData(tempJsonData);
      }
    } catch (e) {
      console.log(`Ocurrió un error en el reproceso de documentos: ${e.message}`);
    }
  };

  useInterval(
    async () => {
      // Your custom logic here
      setCount(count + 1);
      setLoadReprocess(loadReprocess + 1);
      await setEstadosReprocesadoDocumentos();
    },
    isRunning ? 1000 : null
  );

  const reprocesarDocumentos = async (id) => {
    if (window.confirm("¿Quiere reprocesar los documentos de la contingencia?")) {
      setLoad(true);
      try {
        const today = new Date();
        const index = jsonData.map((item) => item.id).indexOf(id);
        const tempJsonData = jsonData;
        const body = {
          fechaAccion: tempJsonData[index].fechaAccion,
          fechaInicio: tempJsonData[index].fechaInicio,
          fechaFin: tempJsonData[index].fechaFin,
          estado: 4,
          usuario: tempJsonData[index].usuario,
          pais: pais.Id,
        };
        const apiResponse = await APIRequest("POST", `/Api/Contingencia/${id}`, body);
        let usuario = `${tempJsonData[index].usuario}`;
        if (usuario !== userInfo.correo) usuario = `${usuario}, ${userInfo.correo}`;
        sessionStorage.setItem("disabledReprocess", true);
        const disabledReprocess = true;
        const editElement = {
          id: tempJsonData[index].id,
          estado: {
            id: 4,
            nombre: "Procesando",
          },
          fechaInicio: tempJsonData[index].fechaInicio,
          fechaFin: tempJsonData[index].fechaFin,
          usuario,
          fechaAccion: today,
          disabledReprocess,
        };
        if (apiResponse.status === 200) {
          await APIRequest("PATCH", `/Api/Contingencia/${id}`, body);
          tempJsonData[index] = editElement;
          configNotification(
            "warning",
            "check",
            "Notificación",
            `Se habilitó el reproceso de documentos exitosamente.`,
            "",
            true
          );
          setKey(id);
          setCurrent(body);
          setIsRunning(true);
        } else {
          const errorMessage = {
            code: apiResponse.status,
            message: `Error: ${apiResponse.status}`,
          };
          throw errorMessage;
        }
        setJsonData(tempJsonData);
      } catch (e) {
        showNotificationError(
          `Ocurrió un error al habilitar el reproceso de documentos: ${e.message}`
        );
      }
      setLoad(false);
    }
  };
  const startTime = () => {
    const currentD = new Date();
    const startHappyHourD = new Date();
    startHappyHourD.setHours(6, 0, 0); // 6.00 am
    const endHappyHourD = new Date();
    endHappyHourD.setHours(22, 30, 0); // 10.30 pm
    if (currentD >= startHappyHourD && currentD < endHappyHourD) {
      currentD.setTime(currentD.getTime() + 60 * 60 * 1000);
    } else {
      currentD.setHours(6, 0, 0);
      currentD.setTime(currentD.getTime() + 60 * 60 * 1000);
    }
    return currentD;
  };
  const setEditJsonData = (valueId) => {
    setElementIsNew(false);
    setNewValueId(valueId);
    const index = jsonData.map((item) => item.id).indexOf(valueId);
    setNewValueDateStart(jsonData[index].fechaInicio);
    setNewValueDateEnd(jsonData[index].fechaFin);

    setModal(true);
  };
  const openAddNewElementModal = () => {
    const pendiente = jsonData.filter((x) => x.estado.id === 1);
    if (pendiente.length > 0) {
      setEditJsonData(pendiente[0].id);
    } else {
      setElementIsNew(true);
      setNewValueDateStart(startTime);
      setNewValueDateEnd(startTime);
      setModal(true);
    }
  };
  const openAddNewElementModalI = () => {
    setModalI(true);
  };
  const closeModal = () => {
    setModal(false);
    setNewValueId("");
    setNewValueDateStart(startTime);
    setNewValueDateEnd(startTime);
  };
  const closeModalI = () => {
    setModalI(false);
    setNewValueNit("");
  };
  const saveDataModal = async () => {
    setLoad(true);
    if (new Date(newValueDateEnd) > new Date(newValueDateStart)) {
      let tempJsonData = jsonData;
      const today = new Date();
      const startDate = new Date(newValueDateStart);
      const endDate = new Date(newValueDateEnd);
      const newStatus = { id: 1, nombre: "Pendiente" };
      const body = {
        fechaAccion: today,
        fechaInicio: startDate,
        fechaFin: endDate,
        estado: newStatus.id,
        usuario: userInfo.correo,
        pais: pais.Id,
      };
      let apiResponse = null;
      try {
        if (elementIsNew) {
          apiResponse = await APIRequest("POST", "/Api/Contingencia", body);
        } else {
          apiResponse = await APIRequest("PATCH", `/Api/Contingencia/${newValueId}`, body);
        }

        if (apiResponse.status === 200) {
          if (elementIsNew) {
            const dataResponse = await apiResponse.json();
            const disableActive = true;
            const newElement = {
              id: dataResponse,
              estado: {
                id: 1,
                nombre: "Pendiente",
              },
              fechaInicio: startDate,
              fechaFin: endDate,
              usuario: userInfo.correo,
              fechaAccion: today,
              disableActive,
            };
            const datatemp = [newElement, ...tempJsonData];
            tempJsonData = [...datatemp];
            setContingencia(newElement);
            setEstadoContingencia(true);
          } else {
            const index = jsonData.map((item) => item.id).indexOf(newValueId);
            const disableActive = true;
            let usuario = `${tempJsonData[index].usuario}`;
            if (usuario !== userInfo.correo) usuario = `${usuario}, ${userInfo.correo}`;
            const element = {
              id: newValueId,
              estado: tempJsonData[index].estado,
              fechaInicio: startDate,
              fechaFin: endDate,
              usuario,
              fechaAccion: today,
              disableActive,
            };
            tempJsonData[index] = element;
          }

          showNotificationSucces("Se completó el registro exitosamente");
        } else {
          const errorMessage = {
            code: apiResponse.status,
            message: `Error: ${apiResponse.status}`,
          };
          throw errorMessage;
        }

        setJsonData(tempJsonData);
      } catch (e) {
        configNotification(
          "error",
          "warning",
          "Notificación",
          `Ocurrió un error al crear la contingencia: ${e.message}`,
          "",
          true
        );
      }
      closeModal();
    } else {
      showNotificationError("Las fechas están incorrectas.");
    }
    setLoad(false);
  };
  const saveDataModalI = async () => {
    const tempJsonData = jsonDataI;
    const body = {
      nit: newValueNit,
      usuario: userInfo.correo,
      pais: pais.Id,
    };
    try {
      const apiResponse1 = await APIRequest(
        "Get",
        `/Api/Contingencia/EmpresaConPrioridad/Existe/${newValueNit}`,
        null
      );
      if (apiResponse1.status === 200) {
        const dataResponse1 = await apiResponse1.json();
        if (!dataResponse1) {
          const apiResponse = await APIRequest(
            "POST",
            "/Api/Contingencia/EmpresaConPrioridad",
            body
          );

          if (apiResponse.status === 200) {
            const dataResponse = await apiResponse.json();
            tempJsonData.push({
              id: dataResponse,
              nit: newValueNit,
            });

            showNotificationSucces("Se completó el registro exitosamente");
          } else {
            const errorMessage = {
              code: apiResponse.status,
              message: `Error: ${apiResponse.status}`,
            };
            throw errorMessage;
          }

          setJsonDataI(tempJsonData);
          closeModalI();
        } else {
          showNotificationError("Esta empresa ya existe");
        }
      }
    } catch (e) {
      configNotification(
        "error",
        "warning",
        "Notificación",
        `Ocurrió un error: ${e.message}`,
        "",
        true
      );
      closeModalI();
    }
  };

  const forzarContingencia = async () => {
    setLoad(true);
    let tempJsonData = jsonData;
    const existe = tempJsonData.filter((x) => x.estado.id === 1);
    const today = new Date();
    const startDate = new Date(newValueDateStart);
    const newStatus = { id: 2, nombre: "Activado" };
    const body = {
      fechaAccion: today,
      fechaInicio: today,
      fechaFin: null,
      estado: newStatus.id,
      usuario: userInfo.correo,
      pais: pais.Id,
    };
    let apiResponse = null;
    try {
      if (!existe || existe.length === 0) {
        apiResponse = await APIRequest("POST", "/Api/Contingencia", body);
      } else {
        setNewValueId(existe[0].id);
        apiResponse = await APIRequest("PATCH", `/Api/Contingencia/${existe[0].id}`, body);
      }

      if (apiResponse.status === 200) {
        if (!existe || existe.length === 0) {
          const dataResponse = await apiResponse.json();
          const disableActive = false;
          const newElement = {
            id: dataResponse,
            estado: {
              id: 2,
              nombre: "Activado",
            },
            fechaInicio: startDate,
            fechaFin: "",
            usuario: userInfo.correo,
            fechaAccion: today,
            disableActive,
          };
          const datatemp = [newElement, ...tempJsonData];
          tempJsonData = [...datatemp];
        } else {
          const index = jsonData.map((item) => item.id).indexOf(existe[0].id);
          const disableActive = false;
          let usuario = `${tempJsonData[index].usuario}`;
          if (usuario !== userInfo.correo) usuario = `${usuario}, ${userInfo.correo}`;
          const element = {
            id: existe[0].id,
            estado: {
              id: 2,
              nombre: "Activado",
            },
            fechaInicio: startDate,
            fechaFin: "",
            usuario,
            fechaAccion: today,
            disableActive,
          };
          tempJsonData[index] = element;
        }
        showNotificationSucces("Se completó el registro exitosamente");
        setDesactiveForzar(true);
        setDesactiveProgramar(true);
      } else {
        const errorMessage = {
          code: apiResponse.status,
          message: `Error: ${apiResponse.status}`,
        };
        throw errorMessage;
      }

      setJsonData(tempJsonData);
    } catch (e) {
      configNotification(
        "error",
        "warning",
        "Notificación",
        `Ocurrió un error al crear la contingencia: ${e.message}`,
        "",
        true
      );
    }
    setLoad(false);
  };
  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );

  const renderModalI = (
    <BKModal
      closeModal={closeModalI}
      saveChanges={saveDataModalI}
      open={modalI}
      title="Agregar Empresa"
    >
      <MDBox sx={{ position: "relative", mx: 3, py: 2, px: 2 }}>
        <Grid container spacing={3} justifyContent="left" alignItems="left" height="100%">
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <MDInput
              variant="standard"
              type="text"
              label="Nit"
              fullWidth
              required
              InputLabelProps={{ shrink: true }}
              value={newValueNit}
              onChange={(e) => {
                setNewValueNit(e.target.value);
              }}
            />
          </Grid>
        </Grid>
      </MDBox>
    </BKModal>
  );

  const renderModal = (
    <BKModal
      closeModal={closeModal}
      saveChanges={saveDataModal}
      open={modal}
      title="Crear Contingencia"
    >
      <MDBox sx={{ position: "relative", mx: 3, py: 2, px: 2 }}>
        <Grid container spacing={3} justifyContent="left" alignItems="left" height="100%">
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <MDDatePicker
              fullWidth
              options={{
                disable: disableDates,
                enableTime: true,
                dateFormat: "d/m/Y H:i:S",
                minDate: "today",
                maxDate: new Date().fp_incr(14),
              }}
              input={{
                variant: "standard",
                label: "Desde",
                required: true,
              }}
              InputLabelProps={{ shrink: true }}
              value={newValueDateStart}
              onChange={(e) => {
                setNewValueDateStart(e[0]);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <MDDatePicker
              fullWidth
              options={{
                enableTime: true,
                dateFormat: "d/m/Y H:i:S",
                minDate: "today",
                maxDate: new Date().fp_incr(14),
                disable: [disableDates],
              }}
              input={{
                variant: "standard",
                label: "Hasta",
                required: true,
              }}
              InputLabelProps={{ shrink: true }}
              value={newValueDateEnd}
              onChange={(e) => {
                setNewValueDateEnd(e[0]);
              }}
            />
          </Grid>
        </Grid>
      </MDBox>
    </BKModal>
  );

  const getJsonData = async () => {
    const tempJsonData = [];
    const filtro = { fecha: null, estado: null, pais: pais.Id };
    setLoad(true);
    const apiResponse = await (await APIRequest("Post", "/Api/Contingencia/Todos", filtro)).json();
    const disableDate = [];
    if (apiResponse.filter((x) => x.estado === 2 || x.estado === 3 || x.estado === 4).length > 0) {
      setDesactiveForzar(true);
      setDesactiveProgramar(true);
    }
    if (apiResponse.filter((x) => x.estado === 4).length > 0) {
      const body = {
        fechaAccion: apiResponse[0].fechaAccion,
        fechaInicio: apiResponse[0].fechaInicio,
        fechaFin: apiResponse[0].fechaFin,
        estado: 4,
        usuario: apiResponse[0].usuarios.join(),
        pais: pais.Id,
      };
      setKey(apiResponse[0].id);
      setCurrent(body);
      setIsRunning(true);
    }
    for (let index = 0; index < apiResponse.length; index += 1) {
      const fechaInicio = new Date(apiResponse[index].fechaInicio);
      const fechaFin = apiResponse[index].fechaFin ? new Date(apiResponse[index].fechaFin) : null;
      const fechaAccion = new Date(apiResponse[index].fechaAccion);
      let disabledReprocess = true;
      let disableActive = true;
      let estado = "Reprocesado";
      if (apiResponse[index].estado === 1) {
        estado = "Pendiente";
      } else if (apiResponse[index].estado === 2) {
        estado = "Activado";
        disableActive = false;
      } else if (apiResponse[index].estado === 3) {
        estado = "Desactivado";
        disabledReprocess = false;
      } else if (apiResponse[index].estado === 4) {
        estado = "Procesando";
        disabledReprocess = process;
      }
      const element = {
        id: apiResponse[index].id,
        estado: {
          id: apiResponse[index].estado,
          nombre: estado,
        },
        fechaInicio,
        fechaFin,
        usuario: apiResponse[index].usuarios.join(),
        fechaAccion,
        disabledReprocess,
        disableActive,
        enCola: apiResponse[index].enCola,
      };
      tempJsonData.push(element);
      if (apiResponse[index].estado === 1 || apiResponse[index].estado === 2) {
        setContingencia(element);
        setEstadoContingencia(true);
      }
      disableDate.push({ from: apiResponse[index].fechaInicio, to: apiResponse[index].fechaFin });
    }
    setDisableDates(disableDate);
    setJsonData(tempJsonData);
    setLoad(false);
  };
  useInterval(
    async () => {
      // Your custom logic here
      setCountActive(countActive + 1);
      console.log("ACTIVE", countActive);
      // await getJsonData();
    },
    isActive ? 1000 : null
  );
  const getJsonDataI = async () => {
    const tempJsonData = [];
    const apiResponse = await (
      await APIRequest("Get", "/Api/Contingencia/EmpresaConPrioridad/Todos", null)
    ).json();
    for (let index = 0; index < apiResponse.length; index += 1) {
      tempJsonData.push({
        id: apiResponse[index].id,
        nit: apiResponse[index].nit,
      });
    }

    setJsonDataI(tempJsonData);
  };

  useEffect(() => {
    getJsonData();
    getJsonDataI();
    if (jsonData.filter((x) => x.estado.id === 4).length === 1) {
      setIsRunning(true);
    }
  }, [pathname]);

  const { columns, rows } = data(
    jsonData,
    setEditEstado,
    setEditJsonData,
    deleteJsonData,
    reprocesarDocumentos,
    estadoDocument,
    loadReprocess
  );
  const getContingencia = async () => {
    if (countCheckEstado !== 0 && countCheckEstado % 10 === 0) {
      const nowDate = Date.now();
      if (
        (contingencia.estado.id === 1 && new Date(nowDate).getMinutes() % 5 === 0) ||
        (contingencia.estado.id === 1 && new Date(contingencia.fechaInicio) <= nowDate) ||
        (contingencia.estado.id >= 2 && new Date(contingencia.fechaFin) <= nowDate)
      ) {
        const tempJsonData = jsonData;
        const index = jsonData.map((item) => item.id).indexOf(contingencia.id);
        const apiResponse = await (
          await APIRequest("GET", `/Api/Contingencia/${contingencia.id}`, null)
        ).json();
        if (apiResponse.estado === 4) {
          setCountCheckEstado(0);
          setEstadoContingencia(false);
        }
        if (
          tempJsonData[index].estado.id !== apiResponse.estado ||
          tempJsonData[index].enCola !== apiResponse.enCola
        ) {
          const fechaInicio = new Date(apiResponse.fechaInicio);
          const fechaFin = apiResponse.fechaFin ? new Date(apiResponse.fechaFin) : null;
          const fechaAccion = new Date(apiResponse.fechaAccion);
          let disabledReprocess = true;
          const disableActive = true;
          let estado = "Reprocesado";
          if (apiResponse.estado === 2) {
            estado = "Activado";
          } else if (apiResponse.estado === 4) {
            const body = {
              fechaAccion: apiResponse.fechaAccion,
              fechaInicio: apiResponse.fechaInicio,
              fechaFin: apiResponse.fechaFin,
              estado: 4,
              usuario: apiResponse.usuarios.join(),
              pais: pais.Id,
            };
            estado = "Procesando";
            disabledReprocess = process;
            setKey(apiResponse.id);
            setCurrent(body);
            setIsRunning(true);
          }
          const element = {
            id: apiResponse.id,
            estado: {
              id: apiResponse.estado,
              nombre: estado,
            },
            fechaInicio,
            fechaFin,
            usuario: apiResponse.usuarios.join(),
            fechaAccion,
            disabledReprocess,
            disableActive,
            enCola: apiResponse.enCola,
          };
          tempJsonData[index] = element;
          setContingencia(element);
          setJsonData(tempJsonData);
        }
      }
    }
  };
  const checkContingencia = async () => {
    if (countActive === 0) {
      const nowDate = Date.now();
      if (
        new Date(contingencia.fechaInicio) <= nowDate ||
        new Date(contingencia.fechaFin) <= nowDate
      )
        setIsActive(true);
    }
    if (countActive === 60 || countActive === 120) {
      const tempJsonData = jsonData;
      const index = jsonData.map((item) => item.id).indexOf(contingencia.id);
      setIsActive(false);
      tempJsonData[index].disableActive = false;
      setJsonData(tempJsonData);
    }
  };
  useInterval(
    async () => {
      setCountCheckEstado(countCheckEstado + 1);
      if (countActive <= 120) await checkContingencia();
      await getContingencia();
      console.log("CountActive", countActive);
      console.log("CHECKCONTINGENCIA", countCheckEstado);
    },
    estadoContingencia ? 1000 : null
  );
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {renderModal}
      {renderModalI}
      <MDBox pt={6} pb={1}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                <MDBox>
                  <MDTypography variant="h4" gutterBottom color="warning">
                    Nits priorizados
                  </MDTypography>
                </MDBox>
                <MDBox>
                  <MDButton variant="outlined" color="warning" onClick={openAddNewElementModalI}>
                    Agregar Nit
                  </MDButton>
                </MDBox>
              </MDBox>
              <MDBox>
                <Paper
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    listStyle: "none",
                    p: 0.5,
                    m: 2,
                  }}
                  component="ul"
                >
                  {jsonDataI.map((value) => {
                    let icon;

                    return (
                      <ListItem key={value.id}>
                        <Chip icon={icon} label={value.nit} onDelete={handleDelete(value)} />
                      </ListItem>
                    );
                  })}
                </Paper>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                <MDBox>
                  <MDTypography variant="h4" gutterBottom color="warning">
                    Contingencias
                  </MDTypography>
                </MDBox>
                <MDBox sx={{ ml: 8 }} display="flex" justifyContent="flex-end">
                  <MDBox>
                    <MDButton
                      variant="outlined"
                      color={desactiveProgramar ? "light" : "warning"}
                      disabled={desactiveProgramar}
                      onClick={openAddNewElementModal}
                    >
                      Programar Contingencia
                    </MDButton>
                  </MDBox>
                  <MDBox sx={{ ml: 1 }}>
                    <MDButton
                      variant="contained"
                      color={desactiveForzar ? "light" : "error"}
                      disabled={desactiveForzar}
                      onClick={forzarContingencia}
                    >
                      Forzar Contingencia
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
              <MDBox>
                <DataTable
                  entriesPerPage={(false, { defaultValue: 10, entries: [5, 10, 25, 50] })}
                  canSearch
                  showTotalEntries
                  table={{ columns, rows }}
                  pagination={{ variant: "contained", color: "warning" }}
                  isSorted
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {renderNotificacion}
      {loading}
      <Footer />
    </DashboardLayout>
  );
}

export default ContingenciaDIAN;
