/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react";

// react-router components
import { Link, useLocation } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Autocomplete from "@mui/material/Autocomplete";
import ArrowBack from "@mui/icons-material/ArrowBack";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import SaveIcon from "@mui/icons-material/Save";

// Material Dashboard 2 React components
import { isObject } from "formik";
import MDBox from "../../../../../components/MDBox";
import MDInput from "../../../../../components/MDInput";
import MDButton from "../../../../../components/MDButton";
import MDTypography from "../../../../../components/MDTypography";
import MDSnackbar from "../../../../../components/MDSnackbar";
import Loading from "../../../../../components/Loading";

// Material Dashboard 2 React example components
import DashboardLayout from "../../../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../../../examples/Navbars/DashboardNavbar";
import Footer from "../../../../../examples/Footer";

// API
import xPosApiRequest from "../../../../../api/xPosApiRequest";
import { isOk } from "../../_helpers/requestHelpers";

function CrearVersion() {
  const location = useLocation();
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  const { version } = location.state || {};
  const [tipoActualizacionValue, setTipoActualizacionValue] = useState("");
  const [versionXPOSValue, setVersionXPOSValue] = useState(version ? version.versionXPOS : "");
  const [plataformaValue, setPlataformaValue] = useState("");
  const [productoValue, setProductoValue] = useState("");
  const [releaseValue, setReleaseValue] = useState("");
  const [releaseNoteValue, setReleaseNoteValue] = useState("");
  const [releaseName, setReleaseName] = useState("");
  const [releaseNoteName, setReleaseNoteName] = useState("");
  const [loading, setLoading] = useState(false);

  const [fieldsErrors, setFieldsErrors] = useState(undefined);

  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );

  const showNotificationSuccess = (message) => {
    setNotification({
      color: "success",
      icon: "check",
      title: "Notificación",
      content: message,
      dateTime: "",
      show: true,
    });
  };

  const showNotificationError = (message) => {
    setNotification({
      color: "error",
      icon: "warning",
      title: "Notificación",
      content: message,
      dateTime: "",
      show: true,
    });
  };

  const handleFileUploadExe = (event) => {
    if (event.target.files) {
      const file = event.target.files[0];
      const validExtensions = ".zip";
      const extension = file.name.substring(file.name.lastIndexOf(".")).toLowerCase();
      if (validExtensions.indexOf(extension) === -1) {
        showNotificationError(`La extensión del archivo es incorrecta`);
      } else {
        setReleaseValue(file);
      }
    }
  };

  const handleFileUploadXlst = (event) => {
    if (event.target.files) {
      const filex = event.target.files[0];
      const validExtensions = ".txt";
      const extension = filex.name.substring(filex.name.lastIndexOf(".")).toLowerCase();
      if (validExtensions.indexOf(extension) === -1) {
        showNotificationError(`La extensión del archivo es incorrecta`);
      } else {
        setReleaseNoteValue(filex);
      }
    }
  };

  const handleSubmitVersion = async () => {
    setLoading(true);
    if (version === undefined) {
      const formFile = new FormData();
      formFile.append("ReleaseNote", releaseNoteValue);
      formFile.append("XPosFile", releaseValue);
      formFile.append("VersionNumber", versionXPOSValue);
      formFile.append("Platform", plataformaValue);
      formFile.append("Product", productoValue);
      formFile.append("UpdatedBy", userInfo.correo);

      const options = {
        method: "POST",
        url: `/version`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formFile,
      };

      try {
        const response = await xPosApiRequest(options);
        if (isOk(response.status)) {
          showNotificationSuccess("La versión se actualizó exitosamente");
          setLoading(false);
        } else if (isObject(response.data)) {
          setFieldsErrors(response.data);
          showNotificationError(`Por favor revise los datos introducidos`);
          setLoading(false);
        } else {
          showNotificationError(response.data);
          setLoading(false);
        }
      } catch (error) {
        showNotificationError(`Ocurrio un error al actualizar la versión`);
        setLoading(false);
      }
    }
  };

  const arrayProducto = [
    { label: "xPos", key: 0 },
    { label: "consola", key: 1 },
  ];

  const arrayPlataforma = [
    { label: "windows", key: 0 },
    { label: "unix", key: 1 },
    { label: "android", key: 2 },
    { label: "iOS", key: 3 },
  ];

  const bttonDisabled =
    tipoActualizacionValue === "" ||
    plataformaValue === "" ||
    productoValue === "" ||
    versionXPOSValue === "" ||
    releaseNoteValue === "" ||
    releaseNoteName === "";

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid display="flex" alignItems="center">
        <Tooltip title="Regresar" style={{ paddingLeft: 0 }} disableInteractive>
          <IconButton
            style={{ paddingLeft: 0 }}
            disableRipple
            component={Link}
            to="/xPos/9Versiones"
          >
            <ArrowBack style={{ cursor: "pointer", color: "black" }} />
          </IconButton>
        </Tooltip>
        <MDTypography fontWeight="bold" variant="h5" color="dark" noWrap>
          Crear versión
        </MDTypography>
      </Grid>
      <MDBox py={1}>
        <MDBox pt={3} pb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card>
                <MDBox px={4} pt={6} pb={8}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        options={["Mayor", "Menor"]}
                        size="medium"
                        renderInput={(params) => (
                          <MDInput
                            {...params}
                            label="Tipo Actualización"
                            placeholder="Seleccione"
                            value={tipoActualizacionValue}
                            variant="standard"
                            onSelect={(event) => setTipoActualizacionValue(event.target.value)}
                            fullWidth
                            required
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <MDInput
                        label="Versión"
                        placeholder="Ej: 1.X"
                        type="text"
                        onChange={(event) => {
                          setVersionXPOSValue(event.target.value);
                          setFieldsErrors({ ...fieldsErrors, VersionNumber: "" });
                        }}
                        size="medium"
                        variant="standard"
                        fullWidth
                        required
                        InputLabelProps={{ shrink: true }}
                        error={fieldsErrors && fieldsErrors.VersionNumber}
                        helperText={
                          fieldsErrors && fieldsErrors.VersionNumber
                            ? fieldsErrors.VersionNumber
                            : ""
                        }
                        FormHelperTextProps={{
                          style: { fontSize: "11px", fontWeight: "bold", color: "red" },
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <Autocomplete
                        options={arrayProducto}
                        size="medium"
                        renderInput={(params) => (
                          <MDInput
                            {...params}
                            label="Producto"
                            placeholder="Seleccione"
                            value={productoValue}
                            variant="standard"
                            onSelect={(event) => {
                              setProductoValue(event.target.value);
                              setFieldsErrors({ ...fieldsErrors, Product: "" });
                            }}
                            fullWidth
                            required
                            InputLabelProps={{ shrink: true }}
                            error={fieldsErrors && fieldsErrors.Product}
                            helperText={
                              fieldsErrors && fieldsErrors.Product ? fieldsErrors.Product : ""
                            }
                            FormHelperTextProps={{
                              style: { fontSize: "11px", fontWeight: "bold", color: "red" },
                            }}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <Autocomplete
                        options={arrayPlataforma}
                        size="medium"
                        renderInput={(params) => (
                          <MDInput
                            {...params}
                            label="Plataforma"
                            placeholder="Seleccione"
                            value={plataformaValue}
                            variant="standard"
                            onSelect={(event) => {
                              setPlataformaValue(event.target.value);
                              setFieldsErrors({ ...fieldsErrors, Platform: "" });
                            }}
                            fullWidth
                            required
                            InputLabelProps={{ shrink: true }}
                            error={fieldsErrors && fieldsErrors.Platform}
                            helperText={
                              fieldsErrors && fieldsErrors.Platform ? fieldsErrors.Platform : ""
                            }
                            FormHelperTextProps={{
                              style: { fontSize: "11px", fontWeight: "bold", color: "red" },
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      my={3}
                      onChange={(event) => {
                        setReleaseName(event.target.files[0].name);
                      }}
                      item
                      xs={12}
                      md={6}
                    >
                      <label
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        htmlFor="fileExe-input"
                      >
                        <input
                          type="file"
                          accept=".zip"
                          id="fileExe-input"
                          onChange={(event) => {
                            handleFileUploadExe(event);
                            setFieldsErrors({ ...fieldsErrors, XPosFile: "" });
                          }}
                          hidden
                        />
                        <Grid
                          backgroundColor="#fd7e14"
                          borderRadius={2}
                          px={1}
                          height={40}
                          style={{
                            color: "white",
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginRight: 10,
                            fontSize: 12,
                            fontWeight: "bold",
                          }}
                        >
                          <FileUploadIcon
                            fontSize="small"
                            style={{ cursor: "pointer", color: "white" }}
                          />
                          <MDTypography
                            fontWeight="bold"
                            color="white"
                            py={1}
                            style={{ marginLeft: 5, paddingRight: 5, fontSize: 12 }}
                          >
                            SELECCIONE
                          </MDTypography>
                        </Grid>
                        <MDInput
                          label="Subir Release"
                          placeholder="Archivo formato .zip"
                          type="text"
                          value={releaseName}
                          size="medium"
                          variant="standard"
                          disabled
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          error={fieldsErrors && fieldsErrors.XPosFile}
                          helperText={
                            fieldsErrors && fieldsErrors.XPosFile ? fieldsErrors.XPosFile : ""
                          }
                          FormHelperTextProps={{
                            style: { fontSize: "11px", fontWeight: "bold", color: "red" },
                          }}
                        />
                      </label>
                    </Grid>

                    <Grid
                      my={3}
                      // Para que tome el nombre
                      onChange={(event) => {
                        setReleaseNoteName(event.target.files[0].name);
                      }}
                      item
                      xs={12}
                      md={6}
                    >
                      <label
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        htmlFor="fileTxt-input"
                      >
                        <input
                          type="file"
                          accept=".txt"
                          id="fileTxt-input"
                          onChange={(event) => {
                            handleFileUploadXlst(event);
                            setFieldsErrors({ ...fieldsErrors, ReleaseNote: "" });
                          }}
                          hidden
                        />
                        <Grid
                          backgroundColor="#fd7e14"
                          borderRadius={2}
                          px={1}
                          height={40}
                          style={{
                            color: "white",
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginRight: 10,
                            fontSize: 12,
                            fontWeight: "bold",
                          }}
                        >
                          <FileUploadIcon
                            fontSize="small"
                            style={{ cursor: "pointer", color: "white" }}
                          />
                          <MDTypography
                            fontWeight="bold"
                            color="white"
                            py={1}
                            style={{ marginLeft: 5, paddingRight: 5, fontSize: 12 }}
                          >
                            SELECCIONE
                          </MDTypography>
                        </Grid>
                        <MDInput
                          label="Subir Release Note"
                          placeholder="Archivo formato .txt"
                          type="text"
                          value={releaseNoteName}
                          size="medium"
                          variant="standard"
                          fullWidth
                          disabled
                          InputLabelProps={{ shrink: true }}
                          error={fieldsErrors && fieldsErrors.ReleaseNote}
                          helperText={
                            fieldsErrors && fieldsErrors.ReleaseNote ? fieldsErrors.ReleaseNote : ""
                          }
                          FormHelperTextProps={{
                            style: { fontSize: "11px", fontWeight: "bold", color: "red" },
                          }}
                        />
                      </label>
                    </Grid>
                  </Grid>
                </MDBox>
              </Card>
              <MDBox display="flex" justifyContent="flex-end" mt={4}>
                <MDButton
                  variant="contained"
                  color="primary"
                  disabled={bttonDisabled}
                  onClick={handleSubmitVersion}
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "110px",
                    height: "35.5px",
                    borderRadius: 8,
                    backgroundColor: "#099268",
                  }}
                >
                  <SaveIcon style={{ marginRight: 5, color: "white" }} />
                  <MDTypography fontWeight="bold" color="white" py={1} style={{ fontSize: 12 }}>
                    GUARDAR
                  </MDTypography>
                </MDButton>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        {renderNotificacion}
        <Loading load={loading} />
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CrearVersion;
