/* eslint-disable react/prop-types */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// API Request
import APIRequest from "api/xDocApiRequest";

// Custom components
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

function PrerequisiteInstallerCreation() {
  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  const [name, setName] = useState("");
  const [nameWarning, setNameWarning] = useState(false);
  const [installer, setInstaller] = useState({});
  const [installerWarning, setInstallerWarning] = useState(false);
  const [versionMin, setVersionMin] = useState("");
  const [versionMax, setVersionMax] = useState("");
  const navigate = useNavigate();
  const {
    state: { versions, route },
  } = useLocation();

  const onChangeName = (e) => {
    setName(e.target.value);
    setNameWarning(false);
  };
  const onSelectVersionMin = (e) => {
    setVersionMin(e.target.value);
  };
  const onSelectVersionMax = (e) => {
    setVersionMax(e.target.value);
  };

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );

  const onFileChange = (e) => {
    const fileSelected = e.target.files[0];
    if (!fileSelected) {
      setInstallerWarning(true);
      return;
    }
    const nameArray = fileSelected.name.split(".");
    if (
      nameArray.length < 2 ||
      (nameArray[nameArray.length - 1].toLowerCase() !== "msi" &&
        nameArray[nameArray.length - 1].toLowerCase() !== "exe")
    ) {
      configNotification(
        "error",
        "warning",
        "Notificación",
        `Ha intentado cargar un instalador con formato inválido.`,
        "",
        true
      );
      setInstallerWarning(true);
      return;
    }
    setInstaller(fileSelected);
    setInstallerWarning(false);
  };

  const addPrerrequisite = async () => {
    try {
      if (!name) {
        setNameWarning(true);
        return;
      }
      if (!installer.name) {
        setInstallerWarning(true);
        return;
      }
      const formData = new FormData();
      formData.append("name", name);
      formData.append("installer", installer);
      formData.append("versionMin", versionMin);
      formData.append("versionMax", versionMax);
      const apiResponse = await APIRequest("POST", "/Prerequisite/Add", formData, true);

      if (apiResponse.status === 200) {
        navigate("/Administracion/xDoc/Versiones/Prerrequisitos", {
          state: {
            notificationParams: [
              "warning",
              "check",
              "Notificación",
              `Se creó el prerrequisito '${name}' exitosamente.`,
              "",
              true,
            ],
          },
        });
      } else {
        const errorMessage = {
          code: apiResponse.status,
          message: `Error: ${apiResponse.status}`,
        };
        throw errorMessage;
      }
    } catch (e) {
      configNotification(
        "error",
        "warning",
        "Notificación",
        `Ocurrió un error al crear el prerrequisito las configuraciones: ${e.message}`,
        "",
        true
      );
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar breadCrumb={route.split("/").slice(1)} />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                <MDBox>
                  <MDTypography variant="h4" gutterBottom color="warning">
                    Nuevo prerrequisito de instalación
                  </MDTypography>
                </MDBox>
                <MDBox>
                  <MDTypography color="text" onClick={() => navigate(route)}>
                    <Tooltip title="Regresar" disableInteractive>
                      <Icon fontSize="large">arrow_back</Icon>
                    </Tooltip>
                  </MDTypography>
                </MDBox>
              </MDBox>
              <MDBox style={{ margin: "20px" }}>
                <MDBox style={{ width: "350px" }}>
                  <MDInput
                    label="Nombre"
                    onChange={onChangeName}
                    fullWidth
                    required
                    error={nameWarning}
                  />
                </MDBox>
                <MDBox style={{ marginTop: "15px" }}>
                  <MDTypography variant="h6" gutterBottom color={installer.name ? "text" : "error"}>
                    <MDButton
                      variant="outlined"
                      component="label"
                      color="info"
                      style={{ marginRight: "10px" }}
                    >
                      <Icon style={{ marginRight: "5px" }}>upload</Icon>
                      Cargar instalador
                      <input type="file" onChange={onFileChange} hidden />
                    </MDButton>
                    {installer.name}
                    {installerWarning &&
                      !installer.name &&
                      "Debe seleccionar un instalador para el prerrequisito."}
                  </MDTypography>
                </MDBox>
                <MDBox
                  style={{
                    marginTop: "15px",
                    width: "300px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <FormControl
                    variant="outlined"
                    margin="1"
                    style={{ width: "100%", marginBottom: 32 }}
                  >
                    <InputLabel id="versionmin-select-label">Versión mínima</InputLabel>
                    <Select
                      style={{ height: "44px", width: "140px" }}
                      variant="outlined"
                      onChange={onSelectVersionMin}
                      labelId="versionmin-select-label"
                      label="Versión mínima"
                    >
                      {versions
                        .filter((x) => !versionMax || x.number < versionMax)
                        .map((element) => (
                          <MenuItem value={element.number} key={element.id}>
                            {element.number}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <FormControl
                    variant="outlined"
                    margin="1"
                    style={{ width: "100%", marginBottom: 32 }}
                  >
                    <InputLabel id="versionmax-select-label">Versión máxima</InputLabel>
                    <Select
                      style={{ height: "44px", width: "140px" }}
                      variant="outlined"
                      onChange={onSelectVersionMax}
                      labelId="versionmax-select-label"
                      label="Versión máxima"
                    >
                      {versions
                        .filter((x) => x.number > versionMin)
                        .map((element) => (
                          <MenuItem value={element.number} key={element.id}>
                            {element.number}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </MDBox>
                <MDBox style={{ marginTop: "20px" }}>
                  <MDButton
                    style={{ marginRight: "10px" }}
                    variant="outlined"
                    onClick={addPrerrequisite}
                    color="success"
                  >
                    Crear
                  </MDButton>
                  <MDButton
                    variant="outlined"
                    onClick={() => navigate("/Administracion/xDoc/Versiones/Prerrequisitos")}
                    color="warning"
                  >
                    Cancelar
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {renderNotificacion}
      <Footer />
    </DashboardLayout>
  );
}

export default PrerequisiteInstallerCreation;
