/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import BKModal from "components/BKModal";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDInput from "components/MDInput";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import data from "views/consultoria/API/ChileDesbloquear/data";

// API Request
// import APIRequest from "api/request";

function ChileDesbloquear() {
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  const [menu, setMenu] = useState(null);
  const [modal, setModal] = useState(false);
  const [jsonData, setJsonData] = useState([]);
  const { pathname } = useLocation();

  const [newValueId, setNewValueId] = useState("");
  const [newValueTaxId, setNewValueTaxId] = useState("");
  const [newValueApiKey, setNewValueApiKey] = useState("");

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };
  // eslint-disable-next-line no-unused-vars
  const showNotificationError = (message) => {
    configNotification("error", "warning", "Notificación", message, "", true);
  };
  const showNotificationSuccess = (message) => {
    configNotification("warning", "check", "Notificación", message, "", true);
  };

  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);
  const openAddNewElementModal = () => {
    setMenu(null);
    setModal(true);
  };
  const closeModal = () => {
    setModal(false);
    setNewValueId("");
    setNewValueTaxId("");
    setNewValueApiKey("");
  };
  const saveDataModal = async () => {
    const tempJsonData = jsonData;

    const newTempElement = {
      id: newValueId,
      taxId: newValueTaxId,
      apiKey: newValueApiKey,
      estado: { id: 2, nombre: "Desbloqueado" },
      usuario: userInfo.correo,
    };

    tempJsonData.push(newTempElement);

    setJsonData(tempJsonData);

    /* const body = {
      nombre: newValueName,
      tipoPerfilId: newValueType.id,
      areaId: newValueArea.id,
    };
    let apiResponse = null;

    try {
      if (elementIsNew) {
        apiResponse = await APIRequest("POST", "/Api/Perfil", body);
      } else {
        apiResponse = await APIRequest("PATCH", `/Api/Perfil/${newValueId}`, body);
      }

      if (apiResponse.status === 200) {
        let dataResponse = null;

        if (elementIsNew) {
          dataResponse = await apiResponse.json();
          setNewValueId(dataResponse);
        }

        const newTempElement = {
          id: elementIsNew ? dataResponse : newValueId,
          nombre: newValueName,
          tipoPerfil: newValueType,
          area: newValueArea,
        };

        if (elementIsNew) {
          if (dataResponse !== 0) {
            tempJsonData.push(newTempElement);

            configNotification(
              "warning",
              "check",
              "Notificación",
              `Se creó el perfil '${newValueName}' exitosamente.`,
              "",
              true
            );
          } else {
            const errorMessage = {
              code: apiResponse.status,
              message: "No se creó el perfil, ya hay 5 perfiles administradores.",
            };
            throw errorMessage;
          }
        } else {
          const index = tempJsonData.map((item) => item.id).indexOf(newValueId);
          tempJsonData[index] = newTempElement;

          configNotification(
            "warning",
            "check",
            "Notificación",
            `Se actualizó el perfil '${newValueName}' exitosamente.`,
            "",
            true
          );
        }
      } else {
        const errorMessage = {
          code: apiResponse.status,
          message: `Error: ${apiResponse.status}`,
        };
        throw errorMessage;
      }

      setJsonData(tempJsonData);
    } catch (e) {
      configNotification(
        "error",
        "warning",
        "Notificación",
        `Ocurrió un error en el perfil: ${e.message}`,
        "",
        true
      );
    } */

    closeModal();
    showNotificationSuccess("Se completó el registro exitosamente");
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={openAddNewElementModal}>Desbloquear Tax Id</MenuItem>
    </Menu>
  );

  const renderModal = (
    <BKModal
      closeModal={closeModal}
      saveChanges={saveDataModal}
      open={modal}
      title="Buscar Correo Recibido"
      titleButton="Buscar"
    >
      <MDBox sx={{ position: "relative", mx: 3, py: 2, px: 2 }}>
        <Grid container spacing={3} justifyContent="left" alignItems="left" height="100%">
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <MDInput
              variant="standard"
              type="text"
              label="ID"
              fullWidth
              required
              // disabled={!elementIsNew}
              InputLabelProps={{ shrink: true }}
              value={newValueId}
              onChange={(e) => {
                setNewValueId(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <MDInput
              variant="standard"
              type="text"
              label="Tax ID"
              fullWidth
              required
              InputLabelProps={{ shrink: true }}
              value={newValueTaxId}
              onChange={(e) => {
                setNewValueTaxId(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <MDInput
              variant="standard"
              type="text"
              label="ApiKey"
              fullWidth
              required
              InputLabelProps={{ shrink: true }}
              value={newValueApiKey}
              onChange={(e) => {
                setNewValueApiKey(e.target.value);
              }}
            />
          </Grid>
        </Grid>
        <MDTypography variant="subtitle2" color="text">
          <br />* Nota: si desea desbloquear todas las ApiKey del TaxID debe ingresar all
        </MDTypography>
      </MDBox>
    </BKModal>
  );

  const getJsonData = async () => {
    /* const tempJsonData = [];
    const apiResponse = await (await APIRequest("GET", "/Api/EnexImpuesto/Todos", null)).json();

    for (let index = 0; index < apiResponse.length; index += 1) {
      tempJsonData.push({
        // tipoPerfil: {
        //   id: apiResponse[index].tipoPerfil.id,
        //   nombre: apiResponse[index].tipoPerfil.nombre,
        // },
        id: apiResponse[index].id,
        partitionKey: apiResponse[index].partitionKey,
        funcionalidad: apiResponse[index].funcionalidad,
        fechaRegistro: apiResponse[index].fechaRegistro,
        fechaDesde: apiResponse[index].fechaDesde,
        fechaHasta: apiResponse[index].fechaHasta,
        estado: apiResponse[index].estado, // { id: 1, nombre: "Positivo" },
        usuario: apiResponse[index].usuario,
        impuesto: apiResponse[index].impuesto, // [{ id: 35, nombre: "35" }],
      });
    } */

    const tempJsonData = [
      {
        id: 1,
        taxId: "ABC",
        apiKey: "xyz",
        estado: { id: 2, nombre: "Desbloqueado" },
        usuario: "a@gosocket.net",
      },
      {
        id: 2,
        taxId: "BCD",
        apiKey: "xyz",
        estado: { id: 2, nombre: "Desbloqueado" },
        usuario: "b@gosocket.net",
      },
    ];

    setJsonData(tempJsonData);
  };

  useEffect(() => {
    getJsonData();
  }, [pathname]);

  const { columns, rows } = data(jsonData);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {renderModal}
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox display="flex" justifyContent="space-between" alignItems="center" px={3}>
                <MDBox pt={3}>
                  <MDTypography variant="h4" gutterBottom color="warning">
                    Desbloquear TaxID
                  </MDTypography>
                </MDBox>
                <MDBox color="text" px={3} pt={3}>
                  <Icon
                    sx={{ cursor: "pointer", fontWeight: "bold" }}
                    fontSize="small"
                    onClick={openMenu}
                  >
                    more_vert
                  </Icon>
                </MDBox>
                {renderMenu}
              </MDBox>
              <MDBox>
                <DataTable
                  entriesPerPage={(false, { defaultValue: 10, entries: [5, 10, 25, 50] })}
                  canSearch
                  showTotalEntries
                  table={{ columns, rows }}
                  pagination={{ variant: "contained", color: "warning" }}
                  isSorted
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {renderNotificacion}
      <Footer />
    </DashboardLayout>
  );
}

export default ChileDesbloquear;
