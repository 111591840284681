/* eslint-disable react/prop-types */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// API Request
import Areas from "../areas";

function InstallationDetails() {
  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  const {
    state: { installation, client, route },
  } = useLocation();
  const navigate = useNavigate();

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );

  const installationDetails = [
    { label: "Nombre", value: installation.name },
    { label: "Versión", value: installation.version },
    { label: "Ambiente", value: installation.testEnabled ? "Pruebas" : "Producción" },
    { label: "Estado", value: installation.enabled ? "Habilitado" : "Deshabilitado" },
    {
      label: "Motivo de deshabilitación",
      value: installation.lastDisableReason,
      hide: installation.enabled,
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar breadCrumb={route.split("/").slice(1)} />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                <MDBox>
                  <MDTypography variant="h4" gutterBottom color="warning">
                    Detalles
                  </MDTypography>
                </MDBox>
                <MDBox>
                  <MDTypography color="text" onClick={() => navigate(route, { state: { client } })}>
                    <Tooltip title="Regresar" disableInteractive>
                      <Icon fontSize="large">arrow_back</Icon>
                    </Tooltip>
                  </MDTypography>
                </MDBox>
              </MDBox>
              <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                <MDBox>
                  {installationDetails
                    .filter((x) => !x.hide)
                    .map((element) => (
                      <MDTypography
                        key={element.label}
                        display="flex"
                        variant="h6"
                        gutterBottom
                        color="text"
                      >
                        <strong>{element.label}: </strong>
                        <p style={{ color: "#a59f9f", marginLeft: "5px" }}>{element.value}</p>
                      </MDTypography>
                    ))}
                </MDBox>
              </MDBox>

              <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                <MDBox>
                  <MDTypography variant="h5" gutterBottom color="warning">
                    Areas
                  </MDTypography>
                </MDBox>
              </MDBox>
              <MDBox>
                <Areas installation={installation} client={client} />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {renderNotificacion}
      <Footer />
    </DashboardLayout>
  );
}

export default InstallationDetails;
