import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";
// import Switch from "@mui/material/Switch";
// import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import Loading from "components/Loading";
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import BKShowModal from "components/BKShowModal";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DataTable from "examples/Tables/DataTable";

// Data
import dataColumns from "views/manager/shortcut/data";

// API Request
import APIRequest from "api/request";

function Shortcut() {
  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });

  const [load, setLoad] = useState(false);
  const [modalDetail, setModalDetail] = useState(false);
  const [jsonData, setJsonData] = useState([]);
  const { pathname } = useLocation();
  // const { state } = useLocation();
  const route = pathname;
  const navigate = useNavigate();

  const [newFilterPais, setNewFilterPais] = useState(null);
  const [newFilterApplicationId, setNewFilterApplicationId] = useState(null);
  const [newFilterUserId, setNewFilterUserId] = useState("");
  const [newFilterAccountId, setNewFilterAccountId] = useState("");

  const [newValueShortcutId, setNewValueShortcutId] = useState("");
  const [newValueApplicationId, setNewValueApplicationId] = useState("");
  const [newValueApplicationName, setNewValueApplicationName] = useState("");
  const [newValueAccountId, setNewValueAccountId] = useState("");
  const [newValueCode, setNewValueCode] = useState("");
  const [newValueAccountName, setNewValueAccountName] = useState("");
  const [newValueUserId, setNewValueUserId] = useState("");
  const [newValueUserName, setNewValueUserName] = useState("");
  const [newValueEmail, setNewValueEmail] = useState("");
  const [newValueOrder, setNewValueOrder] = useState("");

  const [selectData, setSelectData] = useState({
    pais: [
      { id: "cr", nombre: "Costa rica" },
      { id: "ec", nombre: "Ecuador" },
      { id: "pe", nombre: "Perú" },
      { id: "cl", nombre: "Chile" },
      { id: "mx", nombre: "México" },
      { id: "gt", nombre: "Guatemala" },
      { id: "co", nombre: "Colombia" },
      { id: "pa", nombre: "Panamá" },
    ],
    application: [],
    account: [],
    user: [],
  });

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };
  const showNotificationError = (message) => {
    configNotification("error", "warning", "Notificación", message, "", true);
  };
  const showNotificationSuccess = (message) => {
    configNotification("warning", "check", "Notificación", message, "", true);
  };
  const closeModalDetail = () => {
    setModalDetail(false);
    setNewValueShortcutId("");
    setNewValueApplicationId("");
    setNewValueApplicationName("");
    setNewValueAccountId("");
    setNewValueCode("");
    setNewValueAccountName("");
    setNewValueUserId("");
    setNewValueUserName("");
    setNewValueEmail("");
    setNewValueOrder("");
  };

  const setViewModalDetail = async (jsonElement) => {
    setLoad(true);
    try {
      setNewValueShortcutId(jsonElement.shortcutId);
      setNewValueApplicationId(jsonElement.applicationId);
      setNewValueApplicationName(jsonElement.applicationName);
      setNewValueAccountId(jsonElement.accountId);
      setNewValueCode(jsonElement.code);
      setNewValueAccountName(jsonElement.accountName);
      setNewValueUserId(jsonElement.userId);
      setNewValueUserName(jsonElement.userName);
      setNewValueEmail(jsonElement.email);
      setNewValueOrder(jsonElement.order);

      setModalDetail(true);
      showNotificationSuccess("Se encontró el detalle del shortcut");
    } catch {
      showNotificationError("Error Inesperado");
    }
    setLoad(false);
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );
  const renderModalDetail = (
    <BKShowModal closeModal={closeModalDetail} open={modalDetail} title="Detalle del Shortcut">
      <Card>
        <MDBox sx={{ position: "relative", mx: 3, py: 2, px: 2 }}>
          <Grid container spacing={3} justifyContent="left" alignItems="left" height="100%">
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <MDInput
                variant="standard"
                type="text"
                label="Shortcut Id"
                fullWidth
                required
                disabled
                InputLabelProps={{ shrink: true }}
                value={newValueShortcutId}
                onChange={(e) => {
                  setNewValueShortcutId(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <MDInput
                variant="standard"
                type="text"
                label="Id Gadget"
                fullWidth
                required
                disabled
                InputLabelProps={{ shrink: true }}
                value={newValueApplicationId}
                onChange={(e) => {
                  setNewValueApplicationId(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <MDInput
                variant="standard"
                type="text"
                label="Nombre Gadget"
                fullWidth
                required
                disabled
                InputLabelProps={{ shrink: true }}
                value={newValueApplicationName}
                onChange={(e) => {
                  setNewValueApplicationName(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <MDInput
                variant="standard"
                type="text"
                label="Id Account"
                fullWidth
                required
                disabled
                InputLabelProps={{ shrink: true }}
                value={newValueAccountId}
                onChange={(e) => {
                  setNewValueAccountId(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <MDInput
                variant="standard"
                type="text"
                label="Código"
                fullWidth
                required
                disabled
                InputLabelProps={{ shrink: true }}
                value={newValueCode}
                onChange={(e) => {
                  setNewValueCode(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <MDInput
                variant="standard"
                type="text"
                label="Nombre Cuenta"
                fullWidth
                required
                disabled
                InputLabelProps={{ shrink: true }}
                value={newValueAccountName}
                onChange={(e) => {
                  setNewValueAccountName(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <MDInput
                variant="standard"
                type="text"
                label="Id Usuario"
                fullWidth
                required
                disabled
                InputLabelProps={{ shrink: true }}
                value={newValueUserId}
                onChange={(e) => {
                  setNewValueUserId(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <MDInput
                variant="standard"
                type="text"
                label="Nombre Usuario"
                fullWidth
                required
                disabled
                InputLabelProps={{ shrink: true }}
                value={newValueUserName}
                onChange={(e) => {
                  setNewValueUserName(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <MDInput
                variant="standard"
                type="text"
                label="Email"
                fullWidth
                required
                disabled
                InputLabelProps={{ shrink: true }}
                value={newValueEmail}
                onChange={(e) => {
                  setNewValueEmail(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
              <MDInput
                variant="standard"
                type="text"
                label="Orden"
                fullWidth
                required
                disabled
                InputLabelProps={{ shrink: true }}
                value={newValueOrder}
                onChange={(e) => {
                  setNewValueOrder(e.target.value);
                }}
              />
            </Grid>
          </Grid>
        </MDBox>
      </Card>
    </BKShowModal>
  );

  const getJsonData = async () => {
    setLoad(true);
    try {
      const applicationId =
        newFilterApplicationId === null ? "" : `&applicationId=${newFilterApplicationId.id}`;
      const userId = newFilterUserId === "" ? "" : `&applicationId=${newFilterUserId}`;
      const accountId = newFilterAccountId === "" ? "" : `&applicationId=${newFilterAccountId}`;
      const apiResponse = await (
        await APIRequest(
          "GET",
          `/api/Manager/UserGadget/Todos?countryId=${newFilterPais.id}${applicationId}${userId}${accountId}`,
          null
        )
      ).json();

      const tempJsonData = [];

      if (apiResponse.length > 0) {
        for (let index = 0; index < apiResponse.length; index += 1) {
          tempJsonData.push({
            shortcutId: apiResponse[index].shortcutId,
            applicationId: apiResponse[index].applicationId,
            applicationName: apiResponse[index].applicationName,
            accountId: apiResponse[index].accountId,
            code: apiResponse[index].code,
            accountName: apiResponse[index].accountName,
            userId: apiResponse[index].userId,
            userName: apiResponse[index].userName,
            email: apiResponse[index].email,
            order: apiResponse[index].order,
            /* country:
                countries[
                  countries
                    .map((item) => item.id)
                    .indexOf(apiResponse[index].countryId.toLowerCase())
                ], */
          });
        }

        setJsonData([...tempJsonData]);
      }
    } catch (error) {
      showNotificationError(error.data);
    }
    setLoad(false);
  };
  const deleteJsonData = async (jsonElement) => {
    setLoad(true);
    try {
      const HttpUrl = `/api/Manager/UserGadget?userGatgedId=${jsonElement.shortcutId}`;
      const apiResponse = await APIRequest("DELETE", HttpUrl, null);

      if (apiResponse.status === 200 || apiResponse.status === 201 || apiResponse.ok) {
        showNotificationSuccess("Se eliminó el shortcut exitosamente");
      } else {
        showNotificationError(`Error, estado: ${apiResponse.status}`);
      }
    } catch {
      showNotificationError("Error Inesperado");
    }

    setLoad(false);
  };
  const getCountriesData = async () => {
    const tempJsonData = [];
    const apiResponse = await (await APIRequest("GET", "/api/Pais/Todos", null)).json();

    if (apiResponse.length > 0) {
      for (let index = 0; index < apiResponse.length; index += 1) {
        tempJsonData.push({
          id: apiResponse[index].codigo.toLowerCase(),
          nombre: apiResponse[index].nombre,
        });
      }

      setSelectData({
        pais: tempJsonData,
        application: selectData.application,
        account: selectData.account,
        user: selectData.user,
      });
    }

    return tempJsonData;
  };
  const getApplicationsByCountry = async (country) => {
    const tempJsonData = [];
    if (country) {
      const apiResponse = await (await APIRequest("GET", "/api/Manager/Gadget/Todos", null)).json();

      if (apiResponse.length > 0) {
        for (let index = 0; index < apiResponse.length; index += 1) {
          if (apiResponse[index].countryId === country.id) {
            tempJsonData.push({
              id: apiResponse[index].applicationId,
              nombre: apiResponse[index].name,
            });
          }
        }

        setSelectData({
          pais: selectData.pais,
          application: tempJsonData,
          account: selectData.account,
          user: selectData.user,
        });
      }
    }

    return tempJsonData;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    getJsonData();
  };

  useEffect(async () => {
    setLoad(true);
    await getCountriesData();
    setLoad(false);
  }, []);

  const { columns, rows } = dataColumns(jsonData, deleteJsonData, setViewModalDetail);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={3}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <MDBox display="flex" justifyContent="space-between" alignItems="center">
              <MDBox>
                <MDTypography variant="subtitle" fontWeight="bold">
                  Administración Shortcuts
                </MDTypography>
              </MDBox>
              <MDBox color="text" px={2}>
                <MDButton
                  variant="contained"
                  onClick={() => {
                    navigate("/Manager/Shortcut/Form", { state: { route } });
                  }}
                  sx={{
                    background: "linear-gradient(45deg, #1971C2 30%, #1971C2 90%)",
                    color: "#FFFFFF",
                  }}
                >
                  <Icon>add_circle_outline</Icon>&nbsp;Nuevo Shortcut
                </MDButton>
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox
                sx={{ position: "relative", mx: 3, py: 2, px: 2 }}
                component="form"
                onSubmit={handleSubmit}
              >
                <Grid container spacing={3} justifyContent="left" alignItems="left" height="100%">
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <Autocomplete
                      value={newFilterPais}
                      onChange={(event, newValue) => {
                        setNewFilterPais(newValue);
                        getApplicationsByCountry(newValue);
                      }}
                      options={selectData.pais}
                      getOptionLabel={(option) => option.nombre}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderInput={(params) => (
                        <MDInput
                          variant="standard"
                          type="text"
                          label="Pais"
                          fullWidth
                          required
                          InputLabelProps={{ shrink: true }}
                          {...params}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <Autocomplete
                      value={newFilterApplicationId}
                      onChange={(event, newValue) => {
                        setNewFilterApplicationId(newValue);
                      }}
                      options={selectData.application}
                      getOptionLabel={(option) => option.nombre}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderInput={(params) => (
                        <MDInput
                          variant="standard"
                          type="text"
                          label="Gadget"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          {...params}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <MDInput
                      variant="standard"
                      type="text"
                      label="Account Code"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      value={newFilterAccountId}
                      onChange={(e) => {
                        setNewFilterAccountId(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <MDInput
                      variant="standard"
                      type="text"
                      label="Email Usuario"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      value={newFilterUserId}
                      onChange={(e) => {
                        setNewFilterUserId(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={4} xl={9} />
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={3}
                    container
                    justifyContent="flex-end"
                  >
                    <MDButton
                      variant="contained"
                      type="submit"
                      sx={{
                        background: "linear-gradient(45deg, #1971C2 30%, #1971C2 90%)",
                        color: "#FFFFFF",
                      }}
                    >
                      <Icon>search</Icon>&nbsp;Buscar
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <DataTable
              entriesPerPage={(false, { defaultValue: 10, entries: [5, 10, 25, 50] })}
              canSearch
              showTotalEntries
              table={{ columns, rows }}
              pagination={{ variant: "contained", color: "warning" }}
              isSorted
              noEndBorder
            />
          </Grid>
        </Grid>
      </MDBox>
      {renderNotificacion}
      {renderModalDetail}
      <Loading load={load} />
      <Footer />
    </DashboardLayout>
  );
}

export default Shortcut;
