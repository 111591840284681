/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import data from "views/consultoria/AltaEmpresaSandbox/data";

// API Request PacPanama
import APIRequestPacSandbox from "api/requestGeneric";

function AltaEmpresaSandbox() {
  const hostAdmin = process.env.REACT_APP_API_URL;
  const apiKeyAdmin = process.env.REACT_APP_API_KEY_PAC_ADMIN_SBX;
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  const [jsonData, setJsonData] = useState([]);
  const { pathname } = useLocation();
  const route = useLocation().pathname;
  const navigate = useNavigate();

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };
  const showNotificationError = (message) => {
    configNotification("error", "warning", "Notificación", message, "", true);
  };
  const showNotificationSuccess = (message) => {
    configNotification("warning", "check", "Notificación", message, "", true);
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );

  const getJsonData = async () => {
    const tempJsonData = [];
    const urlComplete = `${hostAdmin}/api/affiliates/taxpayer/all?code=${apiKeyAdmin}`;
    const empresasApi = await (
      await APIRequestPacSandbox("GET", urlComplete, null, true, null)
    ).json();

    for (let index = 0; index < empresasApi.length; index += 1) {
      if (!empresasApi[index].deleted) {
        tempJsonData.push({
          taxId: empresasApi[index].issuerRuc,
          issuerRuc: empresasApi[index].issuerRuc,
          taxpayerType: empresasApi[index].taxpayerType,
          IssuerCheckDigit: empresasApi[index].issuerCheckDigit,
          estado: empresasApi[index].enabled ? "Alta" : "Baja",
          usuario: empresasApi[index].createdBy,
          fechaUpdate: empresasApi[index].lastUpdateAt.substring(0, 10),
        });
      }
    }
    setJsonData(tempJsonData);
  };
  const setEditEstado = async (taxId) => {
    const index = jsonData.map((item) => item.taxId).indexOf(taxId);
    const tempJsonData = jsonData;
    let url = "/api/affiliates/taxpayer/enabled";
    let message = "Habilitó";
    if (tempJsonData[index].estado === "Alta") {
      url = "/api/affiliates/taxpayer/disabled";
      message = "Desabilitó";
    }
    const urlComplete = `${hostAdmin}${url}?code=${apiKeyAdmin}`;

    const body = {
      taxpayerNumber: taxId,
      taxpayerType: "Juridico",
      updatedBy: userInfo.correo,
    };
    try {
      const apiResponse = await APIRequestPacSandbox("PATCH", urlComplete, body, true, null);
      if (apiResponse.status === 200) {
        const today = new Date();
        tempJsonData[index].estado = tempJsonData[index].estado === "Alta" ? "Baja" : "Alta";
        tempJsonData[index].fechaUpdate = `${today.getFullYear()}-${(today.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${today.getDate().toString().padStart(2, "0")}`;
        showNotificationSuccess(`Se ${message} la empresa.`);
      } else {
        const errorMessage = {
          code: apiResponse.status,
          message: `Error: ${apiResponse.status}`,
        };
        throw errorMessage;
      }
      setJsonData(tempJsonData);
    } catch (e) {
      configNotification(
        "error",
        "warning",
        "Notificación",
        `Ocurrió un error: ${e.message}`,
        "",
        true
      );
    }
  };
  const deleteJsonData = async (taxId) => {
    if (window.confirm("¿Quiere deshabilitar el registro?")) {
      const index = jsonData.map((item) => item.taxId).indexOf(taxId);
      const urlComplete = `${hostAdmin}/api/affiliates/taxpayer?code=${apiKeyAdmin}`;
      const body = {
        taxpayerNumber: taxId,
        taxpayerType: "Juridico",
        deletedBy: userInfo.correo,
      };
      try {
        const apiResponse = await APIRequestPacSandbox("DELETE", urlComplete, body, true, null);

        if (apiResponse.status === 200) {
          const elements = [...jsonData];
          elements.splice(index, 1);
          setJsonData(elements);
          configNotification(
            "warning",
            "check",
            "Notificación",
            `Se deshabilitó la empresa exitosamente.`,
            "",
            true
          );
        } else {
          const errorMessage = {
            code: apiResponse.status,
            message: `Error: ${apiResponse.status}`,
          };
          throw errorMessage;
        }
      } catch (e) {
        showNotificationError(`Ocurrió un error al deshabilitar el registro: ${e.message}`);
      }
    }
  };

  useEffect(() => {
    getJsonData();
  }, [pathname]);

  const { columns, rows } = data(jsonData, setEditEstado, deleteJsonData);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MDTypography variant="subtitle" fontWeight="bold">
              Empresas Existentes en SandBox
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="left" alignItems="left" height="100%">
              <Grid item xs={12} sm={12} md={6} lg={4} xl={10} />
              <Grid item xs={12} sm={12} md={6} lg={4} xl={2} container justifyContent="flex-end">
                <MDButton
                  variant="contained"
                  onClick={() => navigate("/PACPanama/AltaSandboxForm", { state: { route } })}
                  sx={{
                    background: "linear-gradient(45deg, #1971C2 30%, #1971C2 90%)",
                    color: "#FFFFFF",
                  }}
                >
                  <Icon>add_circle_outline</Icon>&nbsp;Agregar
                </MDButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox>
                <DataTable
                  entriesPerPage={(false, { defaultValue: 10, entries: [5, 10, 25, 50] })}
                  canSearch
                  showTotalEntries
                  table={{ columns, rows }}
                  pagination={{ variant: "contained", color: "warning" }}
                  isSorted
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {renderNotificacion}
      <Footer />
    </DashboardLayout>
  );
}

export default AltaEmpresaSandbox;
