import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// @mui material components
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import BKModal from "components/BKModal";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDInput from "components/MDInput";
import MDDatePicker from "components/MDDatePicker";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import data from "views/soporte/Enex_GestionImpuestoNegativ/data";

// API Request
import APIRequest from "api/request";

function EnexGestionImpuestoNegativo() {
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  const [menu, setMenu] = useState(null);
  const [modal, setModal] = useState(false);
  const [jsonData, setJsonData] = useState([]);
  const { pathname } = useLocation();

  const [elementIsNew, setElementIsNew] = useState(true);
  const [newValueId, setNewValueId] = useState("");
  const [newValueDateStart, setNewValueDateStart] = useState(Date.now());
  const [newValueDateEnd, setNewValueDateEnd] = useState(Date.now());
  const [newValueImpu, setNewValueImpu] = useState([]);
  const [newValueStatus, setNewValueStatus] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [selectData, setSelectData] = useState({
    estado: [
      { id: 1, nombre: "Positivo" },
      { id: 2, nombre: "Negativo" },
    ],
    impuesto: [
      { id: "28", nombre: "28" },
      { id: "35", nombre: "35" },
      { id: "51", nombre: "51" },
      { id: "52", nombre: "52" },
    ],
  });

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };
  const showNotificationError = (message) => {
    configNotification("error", "warning", "Notificación", message, "", true);
  };
  const showNotificationSuccess = (message) => {
    configNotification("warning", "check", "Notificación", message, "", true);
  };

  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);
  const openAddNewElementModal = () => {
    setElementIsNew(true);
    setMenu(null);
    setModal(true);
  };
  const closeModal = () => {
    setModal(false);
    setNewValueId("");
    setNewValueDateStart(Date.now());
    setNewValueDateEnd(Date.now());
    setNewValueImpu([]);
    setNewValueStatus(null);
  };
  const DateToYYYYmmDD = (DateVar) => {
    const NewDate = new Date(DateVar);
    const resultado = `${NewDate.getFullYear()}-${(NewDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${NewDate.getDate().toString().padStart(2, "0")}T00:00:00-06:00`;

    return resultado;
  };
  const saveDataModal = async () => {
    if (newValueImpu.length > 0 && new Date(newValueDateEnd) >= new Date(newValueDateStart)) {
      const tempJsonData = jsonData;
      const today = new Date();
      const startDate = new Date(newValueDateStart);
      const endDate = new Date(newValueDateEnd);
      const impuestos = [];

      let partitionKeyS = `${startDate.getFullYear()}${(startDate.getMonth() + 1)
        .toString()
        .padStart(2, "0")}${startDate.getDate().toString().padStart(2, "0")}|`;

      for (let index = 0; index < newValueImpu.length; index += 1) {
        partitionKeyS += newValueImpu[index].nombre;
        impuestos.push(newValueImpu[index].id);
      }

      const body = {
        Desde: `${startDate.getFullYear()}-${(startDate.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${startDate.getDate().toString().padStart(2, "0")}`,
        Hasta: `${endDate.getFullYear()}-${(endDate.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${endDate.getDate().toString().padStart(2, "0")}`,
        Impuesto: impuestos,
        Estado: newValueStatus.id,
        Usuario: userInfo.correo,
      };
      let apiResponse = null;

      try {
        if (elementIsNew) {
          apiResponse = await APIRequest("POST", "/Api/EnexImpuesto", body);
        } else {
          apiResponse = await APIRequest("PATCH", `/Api/EnexImpuesto/${newValueId}`, body);
        }

        if (apiResponse.status === 200) {
          let dataResponse = null;

          if (elementIsNew) {
            dataResponse = await apiResponse.json();
            setNewValueId(dataResponse);
          }

          const newTempElement = {
            id: elementIsNew ? dataResponse : newValueId,
            partitionKey: partitionKeyS,
            funcionalidad: "ImptoRetenEnex",
            fechaRegistro: `${today.getFullYear()}-${(today.getMonth() + 1)
              .toString()
              .padStart(2, "0")}-${today.getDate().toString().padStart(2, "0")}`,
            fechaDesde: DateToYYYYmmDD(newValueDateStart),
            fechaHasta: DateToYYYYmmDD(newValueDateEnd),
            /* fechaDesde: `${startDate.getFullYear()}-${(startDate.getMonth() + 1)
              .toString()
              .padStart(2, "0")}-${startDate.getDate().toString().padStart(2, "0")}`,
            fechaHasta: `${endDate.getFullYear()}-${(endDate.getMonth() + 1)
              .toString()
              .padStart(2, "0")}-${endDate.getDate().toString().padStart(2, "0")}`, */
            estado: newValueStatus,
            usuario: userInfo.correo,
            impuesto: newValueImpu,
          };

          if (elementIsNew) {
            if (dataResponse !== 0) {
              tempJsonData.push(newTempElement);

              showNotificationSuccess("Se creó el registro exitosamente.");
            } else {
              const errorMessage = {
                code: apiResponse.status,
                message: "No se creó el registro, no se obtuvo el ID de creación",
              };
              throw errorMessage;
            }
          } else {
            const index = tempJsonData.map((item) => item.id).indexOf(newValueId);
            tempJsonData[index] = newTempElement;

            showNotificationSuccess("Se actualizó el registro exitosamente.");
          }
        } else {
          const errorMessage = {
            code: apiResponse.status,
            message: `Error inesperado: ${apiResponse.status}`,
          };
          throw errorMessage;
        }

        setJsonData(tempJsonData);
        closeModal();
        showNotificationSuccess("Se completó el registro exitosamente");
      } catch (e) {
        showNotificationError(`Ocurrió el siguiente error: ${e.message}`);
      }
    } else if (newValueImpu.length <= 0) {
      showNotificationError("Debe seleccionar al menos un impuesto");
    } else if (new Date(newValueDateEnd) < new Date(newValueDateStart)) {
      showNotificationError("La fecha hasta no puede ser menor a la fecha desde.");
    }
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={openAddNewElementModal}>Agregar Valor</MenuItem>
    </Menu>
  );

  const renderModal = (
    <BKModal
      closeModal={closeModal}
      saveChanges={saveDataModal}
      open={modal}
      title={elementIsNew ? "" : ""}
    >
      <MDBox sx={{ position: "relative", mx: 3, py: 2, px: 2 }}>
        <Grid container spacing={3} justifyContent="left" alignItems="left" height="100%">
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <MDDatePicker
              fullWidth
              input={{
                variant: "standard",
                label: "Desde",
                required: true,
              }}
              InputLabelProps={{ shrink: true }}
              value={newValueDateStart}
              onChange={(e) => {
                setNewValueDateStart(e[0]);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <MDDatePicker
              fullWidth
              input={{
                variant: "standard",
                label: "Hasta",
                required: true,
              }}
              InputLabelProps={{ shrink: true }}
              value={newValueDateEnd}
              onChange={(e) => {
                setNewValueDateEnd(e[0]);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <Autocomplete
              multiple
              value={newValueImpu}
              onChange={(event, newValue) => {
                setNewValueImpu(newValue);
              }}
              options={selectData.impuesto}
              getOptionLabel={(option) => option.nombre}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <MDInput
                  variant="standard"
                  type="text"
                  label="Impuesto"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  {...params}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <Autocomplete
              value={newValueStatus}
              onChange={(event, newValue) => {
                setNewValueStatus(newValue);
              }}
              options={selectData.estado}
              getOptionLabel={(option) => option.nombre}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <MDInput
                  variant="standard"
                  type="text"
                  label="Estado"
                  fullWidth
                  required
                  InputLabelProps={{ shrink: true }}
                  {...params}
                />
              )}
            />
          </Grid>
        </Grid>
      </MDBox>
    </BKModal>
  );

  const getJsonData = async () => {
    const tempJsonData = [];
    const apiResponse = await (await APIRequest("GET", "/Api/EnexImpuesto/Todos", null)).json();

    for (let index = 0; index < apiResponse.length; index += 1) {
      const tempImpuesto = [];
      for (let idx = 0; idx < apiResponse[index].impuesto.length; idx += 1) {
        tempImpuesto.push({
          id: apiResponse[index].impuesto[idx].toString(),
          nombre: apiResponse[index].impuesto[idx].toString(),
        });
      }

      tempJsonData.push({
        id: apiResponse[index].id,
        partitionKey: "20221222|35",
        funcionalidad: "ImptoRetenEnex",
        fechaRegistro: apiResponse[index].fechaRegistro,
        fechaDesde: apiResponse[index].desde, // stringYYYYMMDDToDateDDMMYYYY(apiResponse[index].Desde),
        fechaHasta: apiResponse[index].hasta, // stringYYYYMMDDToDateDDMMYYYY(apiResponse[index].Hasta),
        estado: {
          id: apiResponse[index].estado,
          nombre: apiResponse[index].estado === 1 ? "Positivo" : "Negativo",
        },
        usuario: apiResponse[index].usuario,
        impuesto: tempImpuesto, // getImpuestos(apiResponse[index].PartitionKey.split("|")[1]), // tempImpuesto,
      });
    }

    setJsonData(tempJsonData);
  };

  const deleteJsonData = async (id) => {
    if (window.confirm("¿Quiere deshabilitar el registro?")) {
      try {
        const apiResponse = await APIRequest("DELETE", `/Api/EnexImpuesto/${id}`, null);

        if (apiResponse.status === 200) {
          const index = jsonData.map((item) => item.id).indexOf(id);
          const elements = [...jsonData];
          elements.splice(index, 1);
          setJsonData(elements);

          showNotificationSuccess("Se eliminó el registro exitosamente.");
        } else {
          const errorMessage = {
            code: apiResponse.status,
            message: `Error inesperado: ${apiResponse.status}`,
          };
          throw errorMessage;
        }
      } catch (e) {
        showNotificationError(`Ocurrió un error al deshabilitar el registro: ${e.message}`);
      }
    }
  };

  const setEditJsonData = (valueId) => {
    setElementIsNew(false);
    setNewValueId(valueId);

    const index = jsonData.map((item) => item.id).indexOf(valueId);

    setNewValueId(jsonData[index].id);
    setNewValueDateStart(jsonData[index].fechaDesde); // (stringDDMMYYYYToDate(jsonData[index].fechaDesde));
    setNewValueDateEnd(jsonData[index].fechaHasta); // (stringDDMMYYYYToDate(jsonData[index].fechaHasta));
    setNewValueImpu(jsonData[index].impuesto);
    setNewValueStatus(jsonData[index].estado);

    setModal(true);
  };

  useEffect(() => {
    getJsonData();
  }, [pathname]);

  const { columns, rows } = data(jsonData, deleteJsonData, setEditJsonData);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {renderModal}
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                <MDBox>
                  <MDTypography variant="h4" gutterBottom color="warning">
                    Agregar valor negativo o positivo al rango de fecha seleccionado
                  </MDTypography>
                </MDBox>
                <MDBox color="text" px={2}>
                  <Icon
                    sx={{ cursor: "pointer", fontWeight: "bold" }}
                    fontSize="small"
                    onClick={openMenu}
                  >
                    more_vert
                  </Icon>
                </MDBox>
                {renderMenu}
              </MDBox>
              <MDBox>
                <DataTable
                  entriesPerPage={(false, { defaultValue: 10, entries: [5, 10, 25, 50] })}
                  canSearch
                  showTotalEntries
                  table={{ columns, rows }}
                  pagination={{ variant: "contained", color: "warning" }}
                  isSorted
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {renderNotificacion}
      <Footer />
    </DashboardLayout>
  );
}

export default EnexGestionImpuestoNegativo;
