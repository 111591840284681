/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// Default Components
import MDTypography from "components/MDTypography";

export default function data(jsonData, setEditJsonData, setMostrarAdjuntos, descargarEML) {
  const getRows = () => {
    const elements = [];

    for (let index = 0; index < jsonData.length; index += 1) {
      // eslint-disable-next-line array-callback-return
      const adjuntos = jsonData[index].adjuntos.map((item) => (
        <>
          <br />
          {/* <a href={item.link} target="_blank" rel="noopener noreferrer" download={item.nombre}>
            Descargar {item.nombre}
          </a> */}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a>{item.nombre}</a>
        </>
      ));

      elements.push({
        icon: (
          <MDTypography
            color="text"
            display={jsonData[index].adjuntos.length < 1 ? "none" : "flex"}
          >
            <Tooltip
              title={jsonData[index].mostrarAdjuntos ? "Ocultar Adjuntos" : "Mostrar Adjuntos"}
              disableInteractive
              onClick={() => {
                setMostrarAdjuntos(jsonData[index].id);
              }}
            >
              <Icon>
                {jsonData[index].mostrarAdjuntos ? "arrow_back_ios" : "arrow_forward_ios"}
              </Icon>
            </Tooltip>
          </MDTypography>
        ),
        fechaRecepcion: jsonData[index].fechaRecepcion,
        from: jsonData[index].from,
        to: jsonData[index].to,
        asunto: jsonData[index].asunto,
        eml: (
          <>
            <MDTypography
              variant="body2"
              color="text"
              onClick={() => {
                descargarEML(jsonData[index]);
              }}
            >
              Descargar EML
            </MDTypography>
            {/* eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid */}
            {jsonData[index].mostrarAdjuntos ? adjuntos : <a />}
          </>
        ),
        recibido: (
          <MDTypography
            variant="body2"
            color="text"
            display={!jsonData[index].recibido ? "none" : "flex"}
          >
            <Icon>circle</Icon>
          </MDTypography>
        ),
        almacenado: (
          <MDTypography
            variant="body2"
            color="text"
            display={!jsonData[index].almacenado ? "none" : "flex"}
          >
            <Icon>circle</Icon>
          </MDTypography>
        ),
        adjunto: (
          <MDTypography
            variant="body2"
            color="text"
            display={!jsonData[index].adjunto ? "none" : "flex"}
          >
            <Icon>circle</Icon>
          </MDTypography>
        ),
        spam: (
          <MDTypography
            variant="body2"
            color="text"
            display={!jsonData[index].spam ? "none" : "flex"}
          >
            <Icon>circle</Icon>
          </MDTypography>
        ),
        action: (
          <MDTypography color="text">
            <Tooltip
              title="Reenviar a reproceso"
              disableInteractive
              onClick={() => {
                setEditJsonData(jsonData[index]);
              }}
            >
              <Icon>info</Icon>
            </Tooltip>
          </MDTypography>
        ),
      });
    }

    return elements;
  };

  return {
    columns: [
      { Header: "", accessor: "icon", width: "0%", align: "center" },
      { Header: "fechaRecepcion", accessor: "fechaRecepcion", align: "center" },
      { Header: "from", accessor: "from", align: "center" },
      { Header: "to", accessor: "to", align: "center" },
      { Header: "asunto", accessor: "asunto", align: "center" },
      { Header: "eml", accessor: "eml", align: "center" },
      { Header: "recibido", accessor: "recibido", align: "center" },
      { Header: "almacenado", accessor: "almacenado", align: "center" },
      { Header: "adjunto", accessor: "adjunto", align: "center" },
      { Header: "spam", accessor: "spam", align: "center" },
      { Header: "Accion", accessor: "action", align: "center" },
    ],

    rows: getRows(),
  };
}

// Setting default values for the props of MDBadge
data.defaultProps = {
  jsonData: [],
};

// Typechecking props of the data
data.propTypes = {
  jsonData: PropTypes.objectOf(PropTypes.array),
  setEditJsonData: PropTypes.func.isRequired,
  setMostrarAdjuntos: PropTypes.func.isRequired,
  descargarEML: PropTypes.func.isRequired,
};
