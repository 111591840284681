import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Grid, Autocomplete } from "@mui/material";
import { isOk } from "views/admin/xpos/_helpers/requestHelpers";
import xPosApiRequest from "../../../../../../api/xPosApiRequest";
import MDTypography from "../../../../../../components/MDTypography";
import MDInput from "../../../../../../components/MDInput";

function UpdateXpos({ setLoading, showNotificationError, showNotificationSuccess, country, xpos }) {
  const [tipoUpdateValue, setTipoUpdateValue] = useState("");
  const [horarioUpdateValue, setHorarioUpdateValue] = useState("");
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

  useEffect(() => {
    if (xpos) {
      setTipoUpdateValue(xpos.xPosUpdateType);
      setHorarioUpdateValue(xpos.xPosUpdateDate);
    }
  }, [xpos]);

  const handleUpdateXpos = async () => {
    setLoading(true);
    const body = {
      xPosUpdateType: tipoUpdateValue,
      xPosUpdateDate: horarioUpdateValue,
      user: userInfo.correo,
    };
    const options = {
      method: "PUT",
      url: `/global-configuration/xpos-update`,
      headers: {
        "Content-Type": "application/json",
        countryId: country,
      },
      data: body,
    };

    try {
      const response = await xPosApiRequest(options);
      if (isOk(response.status)) {
        showNotificationSuccess("Se actualizó exitosamente");
      } else {
        const errorMessage =
          response.data && response.data.length < 100
            ? response.data
            : "Ocurrió un error al actualizar.";
        showNotificationError(errorMessage);
      }
    } catch (error) {
      showNotificationError(`Ocurrio un error al actualizar`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid container spacing={4} pb={2} px={1}>
      <Grid item xs={6}>
        <MDTypography fontWeight="bold" fontSize="14px" color="dark" noWrap>
          Seleccione el tipo de Update que ejecutara el xPOS ante una nueva versión.
        </MDTypography>
      </Grid>
      <Grid item xs={6}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Autocomplete
              options={["Auto", "Manual"]}
              size="medium"
              value={tipoUpdateValue}
              onChange={(event, newValue) => setTipoUpdateValue(newValue)}
              renderInput={(params) => (
                <MDInput
                  {...params}
                  label="Tipo update xPOS"
                  placeholder="Seleccione"
                  variant="outlined"
                  fullWidth
                  required
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <MDInput
              label="Horario update xPOS"
              type="time"
              value={horarioUpdateValue}
              onChange={(event) => setHorarioUpdateValue(`${event.target.value}:00`)}
              size="medium"
              variant="outlined"
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="flex-end">
            <Grid
              backgroundColor="#099268"
              width="110px"
              borderRadius={5}
              style={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => {
                handleUpdateXpos();
              }}
            >
              <MDTypography
                fontWeight="bold"
                color="white"
                py={1}
                style={{ marginLeft: 5, paddingRight: 5, fontSize: 12 }}
              >
                Guardar
              </MDTypography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

UpdateXpos.defaultProps = {
  xpos: {},
};

UpdateXpos.propTypes = {
  setLoading: PropTypes.func.isRequired,
  country: PropTypes.string.isRequired,
  showNotificationError: PropTypes.func.isRequired,
  showNotificationSuccess: PropTypes.func.isRequired,
  xpos: PropTypes.shape({
    xPosUpdateDate: PropTypes.string,
    xPosUpdateType: PropTypes.string,
    xPosUpdatedBy: PropTypes.string,
    xposUpdatedAt: PropTypes.string,
  }),
};
export default UpdateXpos;
