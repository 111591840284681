/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

function GrafanaImbox() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={1}>
        <MDBox pt={1} pb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card>
                <CardMedia
                  component="iframe"
                  sx={{ height: "75vh" }}
                  src="https://gs-monitoring-prd.gosocket.net/public-dashboards/bcd31baa829f4b90beb32e41534ff852?orgId=1"
                  title="Grafana Imbox"
                />
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default GrafanaImbox;
