/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// @mui material components
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Loading from "components/Loading";
// import BKModal from "components/BKModal";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import data from "views/soporte/XDocGlobal_PermisosXSLT/data";

// API Request
import APIRequest from "api/request";

function PermisosXSLT() {
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  // const [modalUpdateUser, setModalUpdateUser] = useState(false);
  const [load, setLoad] = useState(false);
  const [jsonData, setJsonData] = useState([]);
  const { pathname } = useLocation();
  const { state } = useLocation();
  const navigate = useNavigate();

  const [elementIsNew, setElementIsNew] = useState(true);
  const [newValuePais, setNewValuePais] = useState(null);
  const [newValueXSLT, setNewValueXSLT] = useState("");
  // const [newValueUser, setNewValueUser] = useState("");

  const [selectData, setSelectData] = useState({
    pais: [
      { id: "ar", nombre: "Argentina" },
      { id: "gt", nombre: "Guatemala" },
    ],
  });

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };
  const showNotificationError = (message) => {
    configNotification("error", "warning", "Notificación", message, "", true);
  };
  const showNotificationSuccess = (message) => {
    configNotification("warning", "check", "Notificación", message, "", true);
  };

  /* const closeModalUpdateUser = () => {
    setModalUpdateUser(false);
    setNewValueUser("");
  }; */

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );

  /* const setViewModalUpdateUser = () => {
    setModalUpdateUser(true);
  };

  const saveDataModal = async () => {
    const body = {
      XsltName: newValueXSLT, // "11111111-2222-1111-2222-031111111114"
      CountryId: newValuePais.id,
      User: userInfo.correo,
      AccountCode: "",
      UserNames: [newValueUser.trim()],
      Delete: false,
    };

    if (
      (jsonData.map((item) => item.user).indexOf(newValueUser.trim()) === -1 && !elementIsNew) ||
      elementIsNew
    ) {
      let nuevoId = 0;
      for (let index = 0; index < jsonData.length; index += 1) {
        if (!elementIsNew) {
          body.UserNames.push(jsonData[index].user.trim());
          nuevoId = jsonData[index].id > nuevoId ? jsonData[index].id : nuevoId;
        }
      }

      try {
        nuevoId += 1;
        const apiResponse = await (
          await APIRequest(
            "POST",
            `/api/xslt/${!elementIsNew ? "UpdateGlobalXslt" : "CreateGlobalXslt"}`,
            body
          )
        ).json();

        if (apiResponse.success) {
          const elements = [
            {
              id: nuevoId,
              xslt: newValueXSLT,
              pais: newValuePais,
              user: newValueUser,
            },
            ...jsonData,
          ];

          setJsonData(elements);
          showNotificationSuccess(
            `Se agregó el usuario '${newValueUser}' para el XSLT '${newValueXSLT}'.`
          );
          closeModalUpdateUser();
        } else {
          const apiData = await apiResponse.json();
          const errorMessage = {
            message: apiData.message,
          };
          throw errorMessage;
        }
      } catch (e) {
        showNotificationError(`Ocurrió un error al eliminar el usuario del registro: ${e.message}`);
      }
    } else {
      showNotificationSuccess("El usuario ya está autorizado");
    }
  };

  const renderModalUpdateUser = (
    <BKModal
      closeModal={closeModalUpdateUser}
      saveChanges={saveDataModal}
      open={modalUpdateUser}
      title="Agregar Usuario"
    >
      <MDBox sx={{ position: "relative", mx: 3, py: 2, px: 2 }}>
        <Grid container spacing={3} justifyContent="left" alignItems="left" height="100%">
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <Autocomplete
              value={newValuePais}
              onChange={(event, newValue) => {
                setNewValuePais(newValue);
              }}
              disabled={!elementIsNew}
              options={selectData.pais}
              getOptionLabel={(option) => option.nombre}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <MDInput
                  variant="standard"
                  type="text"
                  label="Pais"
                  fullWidth
                  required
                  InputLabelProps={{ shrink: true }}
                  {...params}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <MDInput
              variant="standard"
              type="text"
              label="XSLT"
              fullWidth
              required
              disabled={!elementIsNew}
              InputLabelProps={{ shrink: true }}
              value={newValueXSLT}
              onChange={(e) => {
                setNewValueXSLT(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <MDInput
              variant="standard"
              type="text"
              label="Usuario"
              fullWidth
              required
              InputLabelProps={{ shrink: true }}
              value={newValueUser}
              onChange={(e) => {
                setNewValueUser(e.target.value);
              }}
            />
          </Grid>
        </Grid>
      </MDBox>
    </BKModal>
  ); */

  const getJsonData = async () => {
    const tempJsonData = [];
    const response = { success: false, message: "" };
    const body = {
      XsltName: newValueXSLT, // "11111111-2222-1111-2222-031111111114"
      CountryId: newValuePais.id, // "bo"
      User: userInfo.correo, // "ronald.piedra@gosocket.net"
    };

    const apiResponse = await (await APIRequest("POST", "/api/xslt/ReadGlobalXslt", body)).json();
    // console.log("apiResponse", apiResponse);

    if (apiResponse.success) {
      const jsonResponse = JSON.parse(apiResponse.values);

      if (jsonResponse.Enable) {
        const tempNames = [];
        for (let index = 0; index < jsonResponse.UserNames.split("|").length; index += 1) {
          if (jsonResponse.UserNames.split("|")[index].trim().length > 0) {
            tempNames.push(jsonResponse.UserNames.split("|")[index].trim());
          }
        }

        for (let index = 0; index < tempNames.length; index += 1) {
          tempJsonData.push({
            id: index,
            xslt: body.XsltName,
            pais: newValuePais,
            user: tempNames[index],
          });
        }

        setElementIsNew(false);
        response.success = true;
        response.message = `Registro encontrado exitosamente, estos usuarios están autorizados para ${body.XsltName}.`;
        setJsonData(tempJsonData);
      } else {
        setElementIsNew(true);
        setJsonData([]);
        response.success = true;
        response.message = `No hay registros activos para ${body.XsltName}, por favor cree uno nuevo.`;
      }
    } else if (
      apiResponse.message.toLowerCase().includes(`el registro ${body.XsltName} no existe`)
    ) {
      setElementIsNew(true);
      setJsonData([]);
      response.success = true;
      response.message = `El registro ${body.XsltName} no existe, por favor cree uno nuevo.`;
    } else {
      setElementIsNew(true);
      setJsonData([]);
      response.message = apiResponse.message;
    }

    if (response.success) {
      showNotificationSuccess(response.message);
    } else {
      showNotificationError(response.message);
    }
  };

  const deleteJsonData = async (element) => {
    if (window.confirm("¿Quiere eliminar el registro?")) {
      try {
        const body = {
          XsltName: element.xslt, // "11111111-2222-1111-2222-031111111114"
          CountryId: element.pais.id,
          User: userInfo.correo,
          AccountCode: "",
          UserNames: [element.user.trim()],
          Delete: true,
        };

        const apiResponse = await (
          await APIRequest("POST", "/api/xslt/UpdateGlobalXslt", body)
        ).json();

        if (apiResponse.success) {
          const index = jsonData.map((item) => item.id).indexOf(element.id);
          const elements = [...jsonData];
          elements.splice(index, 1);
          setJsonData(elements);

          showNotificationSuccess(
            `Se eliminó el usuario '${body.UserNames[0]}' para el XSLT '${body.XsltName}'.`
          );
        } else {
          const apiData = await apiResponse.json();
          const errorMessage = {
            message: apiData.message,
          };
          throw errorMessage;
        }
      } catch (e) {
        showNotificationError(`Ocurrió un error al eliminar el usuario del registro: ${e.message}`);
      }
    }
  };

  const setNewJsonData = () => {
    setElementIsNew(true);
    setJsonData([]);
    setNewValuePais(null);
    setNewValueXSLT("");
    // setViewModalUpdateUser();
    sessionStorage.setItem("jsonData", JSON.stringify([]));
    navigate("/Inbox-PermisoXSLT/Form", {
      state: { route: pathname, element: null, jsonTempData: [], country: null, xslt: "" },
    });
  };
  const setEditJsonData = (element) => {
    setElementIsNew(false);
    // setViewModalUpdateUser();
    sessionStorage.setItem("jsonData", JSON.stringify(jsonData));
    navigate("/Inbox-PermisoXSLT/Form", {
      state: {
        route: pathname,
        element,
        jsonTempData: jsonData,
        country: newValuePais,
        xslt: newValueXSLT,
      },
    });
  };
  const getCountriesData = async () => {
    const tempJsonData = [];
    const urlComplete = `/api/Pais/GetXsltCountries/${userInfo.correo}`;
    const apiResponse = await (await APIRequest("GET", urlComplete, null)).json();

    for (let index = 0; index < apiResponse.length; index += 1) {
      tempJsonData.push({
        id: apiResponse[index].codigo.toLowerCase(),
        nombre: apiResponse[index].nombre,
      });
    }

    setSelectData({
      pais: tempJsonData,
    });

    return tempJsonData;
  };
  const getElementChanges = async () => {
    if (sessionStorage.getItem("jsonData")) {
      const jsonDataXml = JSON.parse(sessionStorage.getItem("jsonData"));

      if (state) {
        if (state.newElement) {
          setJsonData([state.newElement, ...jsonDataXml]);
          showNotificationSuccess(
            `Se agregó el usuario '${state.newElement.user}' para el XSLT '${state.newElement.xslt}'.`
          );
          setElementIsNew(false);
        } else {
          setJsonData([...jsonDataXml]);
          setElementIsNew(state.isNew);
        }
        setNewValuePais(state.country ? state.country : newValuePais);
        setNewValueXSLT(state.xslt ? state.xslt : newValueXSLT);
        sessionStorage.setItem("jsonData", null);
      } else if (jsonDataXml) {
        setJsonData([...jsonDataXml]);
        sessionStorage.setItem("jsonData", null);
        setElementIsNew(state.isNew);
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoad(true);
    await getJsonData();
    setLoad(false);
  };

  useEffect(async () => {
    setLoad(true);
    try {
      await getCountriesData();
      await getElementChanges();
    } catch {
      showNotificationError(
        "Susedio un error inesperado al cargar la página, por favor carguela de nuevo"
      );
    }
    setLoad(false);
  }, [pathname]);

  const { columns, rows } = data(jsonData, setEditJsonData, deleteJsonData);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* renderModalUpdateUser */}
      <MDBox pt={3} pb={3} component="form" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MDTypography variant="subtitle" fontWeight="bold">
              Se permite agregar permisos para editar un XSLT
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3} justifyContent="left" alignItems="left" height="100%">
              <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                <Autocomplete
                  value={newValuePais}
                  onChange={(event, newValue) => {
                    setNewValuePais(newValue);
                  }}
                  options={selectData.pais}
                  getOptionLabel={(option) => option.nombre}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  renderInput={(params) => (
                    <MDInput
                      variant="standard"
                      type="text"
                      label="Pais"
                      fullWidth
                      required
                      InputLabelProps={{ shrink: true }}
                      {...params}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                <MDInput
                  variant="standard"
                  type="text"
                  label="XSLT"
                  fullWidth
                  required
                  InputLabelProps={{ shrink: true }}
                  value={newValueXSLT}
                  onChange={(e) => {
                    setNewValueXSLT(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={2} xl={2} container>
                <MDButton
                  variant="contained"
                  type="submit"
                  sx={{
                    background: "linear-gradient(45deg, #1971C2 30%, #1971C2 90%)",
                    color: "#FFFFFF",
                  }}
                >
                  <Icon>search</Icon>&nbsp;Buscar
                </MDButton>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={2} xl={2} container justifyContent="flex-end">
                <MDButton
                  variant="contained"
                  onClick={() => {
                    // sessionStorage.setItem("jsonData", JSON.stringify(jsonData));
                    setNewJsonData();
                  }}
                  sx={{
                    background: "linear-gradient(45deg, #1971C2 30%, #1971C2 90%)",
                    color: "#FFFFFF",
                  }}
                >
                  <Icon>add_circle_outline</Icon>&nbsp;Nuevo Registro
                </MDButton>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={2} xl={2} container justifyContent="flex-end">
                <MDButton
                  variant="contained"
                  disabled={elementIsNew}
                  onClick={() => {
                    setEditJsonData();
                  }}
                  sx={{
                    background: "linear-gradient(45deg, #1971C2 30%, #1971C2 90%)",
                    color: "#FFFFFF",
                  }}
                >
                  <Icon>add</Icon>&nbsp;Agregar Usuario
                </MDButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox>
                <DataTable
                  entriesPerPage={(false, { defaultValue: 10, entries: [5, 10, 25, 50] })}
                  canSearch
                  showTotalEntries
                  table={{ columns, rows }}
                  pagination={{ variant: "contained", color: "warning" }}
                  isSorted
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {renderNotificacion}
      <Loading load={load} />
      <Footer />
    </DashboardLayout>
  );
}

export default PermisosXSLT;
