/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
// import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// Default Components
import MDTypography from "components/MDTypography";

export default function data(jsonData) {
  const getRows = () => {
    const elements = [];

    for (let index = 0; index < jsonData.length; index += 1) {
      elements.push({
        nombre: jsonData[index].nombre,
        correo: jsonData[index].correo,
        esAdmin: (
          <MDTypography color="text">
            <Icon>{jsonData[index].esAdmin ? "check_box" : "check_box_outline_blank"}</Icon>
          </MDTypography>
        ),
      });
    }

    return elements;
  };

  return {
    columnsUser: [
      { Header: "Nombre", accessor: "nombre", align: "center" },
      { Header: "Correo", accessor: "correo", align: "center" },
      { Header: "Administrador", accessor: "esAdmin", align: "center" },
    ],

    rowsUser: getRows(),
  };
}

// Setting default values for the props of MDBadge
data.defaultProps = {
  jsonData: [],
};

// Typechecking props of the data
data.propTypes = {
  jsonData: PropTypes.objectOf(PropTypes.array),
};
