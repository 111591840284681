/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Switch from "@mui/material/Switch";

export default function data(jsonData, setEditJsonData) {
  const getRows = () => {
    const elements = [];

    for (let index = 0; index < jsonData.length; index += 1) {
      const startDate = new Date(jsonData[index].fechaInicio);
      const endDate = new Date(jsonData[index].fechaFin);
      const actionDate = new Date(jsonData[index].fechaAccion);
      elements.push({
        estado: jsonData[index].estado?.nombre,
        fechaInicio: `${startDate.getDate()}/${
          startDate.getMonth() + 1
        }/${startDate.getFullYear()} ${startDate.getHours()}:${startDate.getMinutes()}:${startDate.getSeconds()}`,
        fechaFin: `${endDate.getDate()}/${
          endDate.getMonth() + 1
        }/${endDate.getFullYear()} ${endDate.getHours()}:${endDate.getMinutes()}:${endDate.getSeconds()}`,
        action: (
          <Switch
            onClick={() => {
              setEditJsonData(jsonData[index].id);
            }}
            checked={jsonData[index].estado?.id === 2}
            disabled={jsonData[index].estado?.id === 3}
          />
        ),
        usuario: jsonData[index].usuario,
        fechaAccion: `${actionDate.getDate()}/${
          actionDate.getMonth() + 1
        }/${actionDate.getFullYear()} ${actionDate.getHours()}:${actionDate.getMinutes()}:${actionDate.getSeconds()}`,
      });
    }

    return elements;
  };

  return {
    columns: [
      { Header: "Estado", accessor: "estado", align: "center" },
      { Header: "Fecha Inicio", accessor: "fechaInicio", align: "center" },
      { Header: "Fecha Fin", accessor: "fechaFin", align: "center" },
      { Header: "Deshabilitar", accessor: "action", align: "center" },
      { Header: "Usuario", accessor: "usuario", align: "center" },
      { Header: "Fecha Accion", accessor: "fechaAccion", align: "center" },
    ],

    rows: getRows(),
  };
}

// Setting default values for the props of MDBadge
data.defaultProps = {
  jsonData: [],
};

// Typechecking props of the data
data.propTypes = {
  jsonData: PropTypes.objectOf(PropTypes.array),
  setEditJsonData: PropTypes.func.isRequired,
};
