/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import axios from "axios";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { useState } from "react";

// @mui material components
import Tooltip from "@mui/material/Tooltip";
import { Box } from "@mui/material";

// Icon from assets
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

// Default Components
import MDProgress from "components/MDProgress";
import MDTypography from "../../../../../components/MDTypography";
import MDSnackbar from "../../../../../components/MDSnackbar";

export default function data(
  jsonData,
  openAddNewElementModal,
  handleDownload,
  deleteJsonData,
  downloadProgress
) {
  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );

  const getRows = () => {
    const elements = [];

    for (let pos = 0; pos < jsonData.length; pos += 1) {
      elements.push({
        versionXPOS: jsonData[pos].versionNumber,
        fechaActualizacion:
          jsonData[pos].updatedAt === undefined
            ? ""
            : `${jsonData[pos].updatedAt.split("T")[0]} ${jsonData[pos].updatedAt.split("T")[1]}`,
        plataforma: jsonData[pos].platform,
        producto: jsonData[pos].product,
        usuario: jsonData[pos].updatedBy,
        action: downloadProgress[jsonData[pos].guid] ? (
          <Box style={{ width: "100%", height: "100%" }}>
            <MDProgress
              style={{ width: "70px" }}
              width="100%"
              variant="determinate"
              value={downloadProgress[jsonData[pos].guid]}
              color="warning"
            />
          </Box>
        ) : (
          <MDTypography
            color="text"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            {renderNotificacion}

            <Tooltip style={{ cursor: "pointer" }} title="Descargar" disableInteractive>
              <FileDownloadIcon
                size="20px"
                fontSize="20px"
                style={{ fontSize: "20px", cursor: "pointer", marginRight: 10 }}
                // onClick={() => downloadJsonData(jsonData[pos].guid)}
                onClick={() => {
                  handleDownload(jsonData[pos].guid);
                }}
              />
            </Tooltip>
            <Tooltip
              title="Release note"
              disableInteractive
              style={{ cursor: "pointer" }}
              onClick={() => {
                openAddNewElementModal(jsonData[pos]);
              }}
            >
              <ManageSearchIcon fontSize="22px" style={{ fontSize: "22px", cursor: "pointer" }} />
            </Tooltip>
            <Tooltip
              title="Delete"
              disableInteractive
              style={{ cursor: "pointer" }}
              onClick={() => {
                deleteJsonData(jsonData[pos].guid);
              }}
            >
              <DeleteIcon fontSize="22px" style={{ fontSize: "22px", cursor: "pointer" }} />
            </Tooltip>
          </MDTypography>
        ),
      });
    }
    return elements;
  };

  return {
    columns: [
      { Header: "Versión xPOS", accessor: "versionXPOS", align: "center" },
      { Header: "Fecha actualización", accessor: "fechaActualizacion", align: "center" },
      { Header: "Plataforma", accessor: "plataforma", align: "center" },
      { Header: "Producto", accessor: "producto", align: "center" },
      { Header: "Usuario última acción", accessor: "usuario", align: "center" },
      { Header: "Acciones", accessor: "action", align: "center" },
    ],

    rows: getRows(),
  };
}

// Setting default values for the props of MDBadge
data.defaultProps = {
  jsonData: [],
};

// Typechecking props of the data
data.propTypes = {
  jsonData: PropTypes.objectOf(PropTypes.array),
  deleteJsonData: PropTypes.func.isRequired,
  handleDownload: PropTypes.func.isRequired,
  openAddNewElementModal: PropTypes.func.isRequired,
};
