/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Tooltip from "@mui/material/Tooltip";
import HistoryIcon from "@mui/icons-material/History";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Icon from "@mui/material/Icon";

// Default Components
import MDTypography from "../../../../../components/MDTypography";
import useNotification from "../../_hooks/useNotification";
import Notification from "../../_components/shared/Notification";

export default function data(jsonData, setEditJsonData, handleDownload) {
  const { notification, showNotificationSuccess, setNotification } = useNotification();

  const handleCopy = (event, value) => {
    const textToCopy = typeof value === "string" ? value.toString() : value.props.children;
    navigator.clipboard.writeText(textToCopy);
    showNotificationSuccess("El elemento se ha copiado exitosamente");
  };

  const getRows = () => {
    const elements = [];

    for (let index = 0; index < jsonData.length; index += 1) {
      const { logs } = jsonData[index];

      elements.push({
        taxId: jsonData[index].taxId,
        xPosId: (
          <div className="copy-container" style={{ alignItems: "center" }}>
            <Tooltip title={jsonData[index].xPosGuid} disableInteractive>
              <span style={{ marginRight: 4 }}>{`${jsonData[index].xPosGuid.split("-")[0]}-${
                jsonData[index].xPosGuid.split("-")[1]
              }...`}</span>
            </Tooltip>
            <Icon
              fontSize="small"
              style={{ cursor: "pointer" }}
              onClick={(event) => handleCopy(event, jsonData[index].xPosGuid)}
            >
              copy
            </Icon>
          </div>
        ),
        guid: jsonData[index].xPosGuid,
        xPosName: jsonData[index].xPosName,
        lastLogDate: `${logs[logs.length - 1].date.split("T")[0]} ${
          logs[logs.length - 1].date.split("T")[1]
        }`,
        action: (
          <MDTypography
            color="text"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            {notification && (
              <Notification notification={notification} setNotification={setNotification} />
            )}
            <Tooltip style={{ cursor: "pointer" }} title="Descargar" disableInteractive>
              <FileDownloadIcon
                fontSize="20px"
                style={{ fontSize: "20px", cursor: "pointer", marginRight: 10 }}
                onClick={() => {
                  handleDownload([jsonData[index].xPosGuid]);
                }}
              />
            </Tooltip>
            <Tooltip
              title="Histórico"
              disableInteractive
              style={{ cursor: "pointer" }}
              onClick={() => {
                setEditJsonData(jsonData[index]);
              }}
            >
              <HistoryIcon fontSize="22px" style={{ fontSize: "22px", cursor: "pointer" }} />
            </Tooltip>
          </MDTypography>
        ),
      });
    }

    return elements;
  };

  return {
    columns: [
      { Header: "TaxID", accessor: "taxId", align: "center" },
      { Header: "ID xPOS", accessor: "xPosId", align: "center" },
      { Header: "Nombre xPOS", accessor: "xPosName", align: "center" },
      { Header: "Últimos logs", accessor: "lastLogDate", align: "center" },
      { Header: "Acciones", accessor: "action", align: "center" },
    ],

    rows: getRows(),
  };
}

// Setting default values for the props of MDBadge
data.defaultProps = {
  jsonData: [],
};

// Typechecking props of the data
data.propTypes = {
  jsonData: PropTypes.objectOf(PropTypes.array),
  setEditJsonData: PropTypes.func.isRequired,
  handleDownload: PropTypes.func.isRequired,
};
