/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// @mui material components
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import data from "views/finanzas/GestionCliente/BloquearEmision/data";

// API Request
import APIRequest from "api/request";
import APIRequestGeneric from "api/requestGeneric";

function BloquearEmision() {
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  const host = process.env.REACT_APP_API_URL;
  const basicAuth = `${process.env.REACT_APP_API_ENEX_USER}:${process.env.REACT_APP_API_ENEX_PASSWORD}`;
  const basicAuthorization = `Basic ${btoa(basicAuth)}`;

  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  const [jsonData, setJsonData] = useState([]);
  const { pathname } = useLocation();

  const [newValuePais, setNewValuePais] = useState(null);
  const [newValueTaxId, setNewValueTaxId] = useState("");
  const [selectData, setSelectData] = useState({
    pais: [
      { id: "cr", nombre: "Costa rica" },
      { id: "ec", nombre: "Ecuador" },
      { id: "pr", nombre: "Perú" },
      { id: "cl", nombre: "Chile" },
      { id: "mx", nombre: "México" },
      { id: "gt", nombre: "Guatemala" },
      { id: "co", nombre: "Colombia" },
      { id: "pa", nombre: "Panamá" },
    ],
    estado: [
      { id: 1, nombre: "Bloqueado" },
      { id: 0, nombre: "Desbloqueado" },
    ],
  });

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };
  const showNotificationError = (message) => {
    configNotification("error", "warning", "Notificación", message, "", true);
  };
  const showNotificationSuccess = (message) => {
    configNotification("warning", "check", "Notificación", message, "", true);
  };

  const closeModal = () => {
    setNewValuePais(null);
    setNewValueTaxId("");
  };
  const saveDataModal = async () => {
    const today = new Date();

    const body = {
      TaxId: newValueTaxId,
      CountryId: newValuePais.id,
      Value: "False", // Bloqueado
      User: userInfo.correo,
    };
    const urlComplete = `${host}/api/AccountSettings/CreateEnableSendBillDocument`; // /api/AccountSettings/CreateBloqueoDesbloqueoEmision
    const apiResponse = await (
      await APIRequestGeneric("POST", urlComplete, body, false, basicAuthorization)
    ).json();

    if ((apiResponse.Code === 201 || apiResponse.Code === 200) && apiResponse.IsSuccessful) {
      const element = {
        pais: newValuePais,
        taxId: newValueTaxId,
        estado: { id: 1, nombre: "Bloqueado" },
        fechaAccion: `${today.getFullYear()}-${(today.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${today.getDate().toString().padStart(2, "0")}`,
        usuarioAccion: userInfo.correo,
      };

      setJsonData([element, ...jsonData]);
      showNotificationSuccess(`Se bloqueó el tax ${newValueTaxId} exitosamente`);
    } else {
      showNotificationError(`Error: ${apiResponse.Message}`);
    }

    closeModal();
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );

  const getJsonData = async (countries) => {
    const tempJsonData = [];
    const body = {
      User: userInfo.correo,
    };
    const urlComplete = `${host}/api/AccountSettings/GetEnableSendBillDocument`; // /api/AccountSettings/GetBloqueoDesbloqueoEmision
    const apiResponse = await (
      await APIRequestGeneric("POST", urlComplete, body, false, basicAuthorization)
    ).json();

    const responseData = JSON.parse(apiResponse.Data);

    if (apiResponse.Code === 200 && apiResponse.IsSuccessful) {
      for (let index = 0; index < responseData.length; index += 1) {
        let UserUpCreate = responseData[index].UserUpdated ? responseData[index].UserUpdated : "";
        UserUpCreate = UserUpCreate === "" ? responseData[index].UserCreated : UserUpCreate;

        tempJsonData.push({
          pais: countries[
            countries.map((item) => item.id).indexOf(responseData[index].CountryId.toLowerCase())
          ],
          taxId: responseData[index].AccountCode,
          estado:
            responseData[index].Value.toLowerCase() === "true" || responseData[index].Value === "1"
              ? { id: 0, nombre: "Desbloqueado" }
              : { id: 1, nombre: "Bloqueado" },
          fechaAccion: responseData[index].Timestamp.toString().substring(0, 10),
          usuarioAccion: UserUpCreate,
        });
      }
    }

    setJsonData(tempJsonData);
  };

  const updateJsonData = async (valueId, status) => {
    const tempJsonData = jsonData;
    const today = new Date();
    const index = tempJsonData.map((item) => item.taxId).indexOf(valueId);

    const body = {
      TaxId: jsonData[index].taxId,
      CountryId: jsonData[index].pais.id,
      Value: status ? "True" : "False", // 0:Desbloqueado / 1:Bloqueado
      User: userInfo.correo,
    };
    const urlComplete = `${host}/api/AccountSettings/UpdateEnableSendBillDocument`; // /api/AccountSettings/UpdateBloqueoDesbloqueoEmision
    const apiResponse = await (
      await APIRequestGeneric("PUT", urlComplete, body, false, basicAuthorization)
    ).json();

    if ((apiResponse.Code === 201 || apiResponse.Code === 200) && apiResponse.IsSuccessful) {
      const element = {
        pais: jsonData[index].pais,
        taxId: jsonData[index].taxId,
        estado: status ? { id: 0, nombre: "Desbloqueado" } : { id: 1, nombre: "Bloqueado" },
        fechaAccion: `${today.getFullYear()}-${(today.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${today.getDate().toString().padStart(2, "0")}`,
        usuarioAccion: userInfo.correo,
      };
      tempJsonData[index] = element;

      setJsonData(tempJsonData);
      showNotificationSuccess(`Se actualizó el tax Id ${jsonData[index].taxId} exitosamente`);
    } else {
      showNotificationError(`Error: ${apiResponse.Message}`);
    }
  };

  const getCountriesData = async () => {
    const tempJsonData = [];
    const apiResponse = await (await APIRequest("GET", "/api/Pais/Todos", null)).json();

    for (let index = 0; index < apiResponse.length; index += 1) {
      tempJsonData.push({
        id: apiResponse[index].codigo.toLowerCase(),
        nombre: apiResponse[index].nombre,
      });
    }

    setSelectData({
      pais: tempJsonData,
      estado: selectData.estado,
    });

    return tempJsonData;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    saveDataModal();
  };

  useEffect(async () => {
    const countries = await getCountriesData();
    await getJsonData(countries);
  }, [pathname]);

  const { columns, rows } = data(jsonData, updateJsonData);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={3}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <MDTypography variant="subtitle" fontWeight="bold">
              Se permite bloquear emisión de documentos para un TaxID particular
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDBox
              // sx={{ position: "relative", mx: 3, py: 2, px: 2 }}
              component="form"
              onSubmit={handleSubmit}
            >
              <Grid container spacing={3} justifyContent="left" alignItems="left" height="100%">
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                  <Autocomplete
                    value={newValuePais}
                    onChange={(event, newValue) => {
                      setNewValuePais(newValue);
                    }}
                    options={selectData.pais}
                    getOptionLabel={(option) => option.nombre}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderInput={(params) => (
                      <MDInput
                        variant="standard"
                        type="text"
                        label="Pais"
                        fullWidth
                        required
                        InputLabelProps={{ shrink: true }}
                        {...params}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                  <MDInput
                    variant="standard"
                    type="text"
                    label="Tax ID"
                    fullWidth
                    required
                    InputLabelProps={{ shrink: true }}
                    value={newValueTaxId}
                    onChange={(e) => {
                      setNewValueTaxId(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                  <MDButton
                    variant="contained"
                    type="submit"
                    sx={{
                      background: "linear-gradient(45deg, #CC0000 30%, #CC0000 90%)",
                      color: "#FFFFFF",
                    }}
                  >
                    <Icon>lock</Icon>&nbsp;Bloquear
                  </MDButton>
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox>
                <DataTable
                  entriesPerPage={(false, { defaultValue: 10, entries: [5, 10, 25, 50] })}
                  canSearch
                  showTotalEntries
                  table={{ columns, rows }}
                  pagination={{ variant: "contained", color: "warning" }}
                  isSorted
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {renderNotificacion}
      <Footer />
    </DashboardLayout>
  );
}

export default BloquearEmision;
