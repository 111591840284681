export const CUSTOM_UPLOAD_COMPONENT = Object.freeze({
  UPDATE_XPOS: "UpdateXpos",
  CUSTOM_UPLOAD: "CustomUpload",
  STORAGE: "Storage",
});

export const EXTENSIONS_FILE = Object.freeze({
  ZIP: "zip",
  XSLT: "xslt",
});

export const NODE_ENV = process.env.REACT_APP_NODE_ENV || "SBX";

export const CUSTOM_UPLOAD_TITLE = "Ingrese los endpoints según el tipo de publicación.";
