/* eslint-disable react/prop-types */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Loading from "components/Loading";
import DataTable from "examples/Tables/DataTable";
// Data
import data from "views/admin/xdoc/areas/data";
// API Request
import APIRequest from "api/xDocApiRequest";

function AreasList() {
  const [clienteOptions, setClienteOptions] = useState([{ id: "", name: "", email: "" }]);
  const [instalacionOptions, setInstalacionOptions] = useState([]);
  const [selectCliente, setSelectCliente] = useState(null);
  const [selectInstalacion, setSelectInstalacion] = useState(null);
  const [jsonData, setJsonData] = useState([]);
  const [load, setLoad] = useState(false);
  const { pathname } = useLocation();
  const route = useLocation().pathname;
  const navigate = useNavigate();

  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };
  const showNotificationError = (message) => {
    configNotification("error", "warning", "Notificación", message, "", true);
  };
  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );
  const getJsonData = async () => {
    const tempJsonData = [];
    setLoad(true);
    const apiResponse = await (
      await APIRequest("POST", "/Area/GetByInstallation", {
        installationGuid: selectInstalacion.guid,
      })
    ).json();

    apiResponse.forEach((item) => {
      tempJsonData.push(item);
    });
    setLoad(false);
    setJsonData(tempJsonData);
  };

  const [showConfirmDisable, setShowConfirmDisable] = useState(false);
  const [areaToDisable, setAreaToDisable] = useState({});
  const [disableReason, setDisableReason] = useState("");
  const setEnabled = async (area, disableConfirmed) => {
    const enable = !area.enabled;
    if (!enable && !disableConfirmed) {
      setAreaToDisable(area);
      setShowConfirmDisable(true);
      return;
    }

    try {
      // if (enable) {
      //   const isTaxEntityEnabled = await (
      //     await APIRequest("POST", "/TaxEntity/IsEnabled", {
      //       taxId: area.taxId,
      //       country: area.country,
      //     })
      //   ).json();
      //   if (!isTaxEntityEnabled) {
      //     configNotification(
      //       "error",
      //       "warning",
      //       "Notificación",
      //       "La entidad tributaria asociada se encuentra deshabilitada o no se ha configurado.",
      //       "",
      //       true
      //     );
      //     return;
      //   }
      // }

      const apiResponse = await APIRequest("POST", "/Area/SetEnabled", {
        areaId: area.id,
        enable,
        reason: disableReason,
      });

      if (apiResponse.status === 200) {
        setJsonData(
          jsonData.map((item) => ({
            ...item,
            enabled: item.id === area.id ? enable : item.enabled,
            lastDisableReason: item.id === area.id ? disableReason : item.lastDisableReason,
          }))
        );
        configNotification(
          "warning",
          "check",
          "Notificación",
          enable
            ? `Se habilitó el area '${area.name}' exitosamente.`
            : `Se deshabilitó el area '${area.name}' exitosamente.`,
          "",
          true
        );
      } else {
        const errorMessage = {
          code: apiResponse.status,
          message: `Error: ${apiResponse.status}`,
        };
        throw errorMessage;
      }
    } catch (e) {
      configNotification(
        "error",
        "warning",
        "Notificación",
        `Ocurrió un error al deshabilitar el area: ${e.message}`,
        "",
        true
      );
    }
  };
  const handleCancelDisable = () => {
    setShowConfirmDisable(false);
  };
  const handleConfirmDisable = () => {
    setEnabled(areaToDisable, true);
    setShowConfirmDisable(false);
  };

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [areaIdToDelete, setAreaIdToDelete] = useState(0);
  const deleteJsonData = async (areaId, confirmed) => {
    if (!confirmed) {
      setAreaIdToDelete(areaId);
      setShowConfirmDelete(true);
      return;
    }

    try {
      const apiResponse = await APIRequest("DELETE", `/Area/Delete?areaId=${areaId}`, null);

      if (apiResponse.status === 200) {
        const index = jsonData.map((item) => item.id).indexOf(areaId);
        const elements = [...jsonData];
        const nombreElemento = elements[index].name;
        elements.splice(index, 1);
        setJsonData(elements);
        configNotification(
          "warning",
          "check",
          "Notificación",
          `Se eliminó el area '${nombreElemento}' exitosamente.`,
          "",
          true
        );
      } else {
        const errorMessage = {
          code: apiResponse.status,
          message: `Error: ${apiResponse.status}`,
        };
        throw errorMessage;
      }
    } catch (e) {
      configNotification(
        "error",
        "warning",
        "Notificación",
        `Ocurrió un error al eliminar el area: ${e.message}`,
        "",
        true
      );
    }
  };
  const handleCancelDelete = () => {
    setShowConfirmDelete(false);
  };
  const handleConfirmDelete = () => {
    deleteJsonData(areaIdToDelete, true);
    setShowConfirmDelete(false);
  };

  const viewDetails = (area) =>
    navigate("/AdministracionXDoc/Areas/Detalles", {
      state: { client: selectCliente, installation: selectInstalacion, area, route },
    });
  const changedCliente = async (newCliente) => {
    const tempJsonData = [];
    setSelectCliente(newCliente);
    const apiResponse = await (
      await APIRequest("GET", `/Installation/GetByClient?clientId=${newCliente.id}`)
    ).json();

    for (let index = 0; index < apiResponse.length; index += 1) {
      tempJsonData.push({
        id: apiResponse[index].id,
        guid: apiResponse[index].guid,
        name: apiResponse[index].name,
      });
    }
    setInstalacionOptions(tempJsonData);
    setSelectInstalacion(tempJsonData[0]);
  };

  const getClientesData = async () => {
    const tempJsonData = [];
    const apiResponse = await (await APIRequest("GET", "/Client/GetAll", null)).json();

    for (let index = 0; index < apiResponse.length; index += 1) {
      tempJsonData.push({
        id: apiResponse[index].id,
        name: `${apiResponse[index].name}-${apiResponse[index].email}`,
      });
    }
    setClienteOptions(tempJsonData);
    return tempJsonData[0];
  };
  useEffect(async () => {
    try {
      setLoad(true);
      const firstClientOption = await getClientesData();
      await changedCliente(firstClientOption);
    } catch {
      showNotificationError("Ocurrió un error inesperado.");
    }

    setLoad(false);
  }, [pathname]);
  const { columns, rows } = data(jsonData, deleteJsonData, setEnabled, viewDetails);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MDTypography variant="subtitle" fontWeight="bold">
              Areas
            </MDTypography>
          </Grid>
          <Grid
            item
            xs={12}
            container
            spacing={3}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item sm={12} md={6} lg={5} alignItems="center">
              <Autocomplete
                value={selectCliente}
                size="medium"
                onChange={async (event, newValue) => {
                  changedCliente(newValue);
                }}
                options={clienteOptions}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <MDInput
                    variant="standard"
                    type="text"
                    label="Seleccione el cliente"
                    required
                    InputLabelProps={{ shrink: true }}
                    {...params}
                  />
                )}
              />
            </Grid>
            <Grid item sm={12} md={6} lg={4} alignItems="center">
              <Autocomplete
                value={selectInstalacion}
                onChange={async (event, newValue) => {
                  setSelectInstalacion(newValue);
                }}
                options={instalacionOptions}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <MDInput
                    variant="standard"
                    type="text"
                    label="Seleccione la instalación"
                    required
                    InputLabelProps={{ shrink: true }}
                    {...params}
                  />
                )}
              />
            </Grid>
            <Grid item sm={12} md={6} lg={2}>
              <Grid
                item
                sm={12}
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <MDButton
                  variant="contained"
                  onClick={() => getJsonData()}
                  sx={{
                    background: "linear-gradient(45deg, #1971C2 30%, #1971C2 90%)",
                    color: "#FFFFFF",
                    ml: "auto",
                  }}
                >
                  <Icon>search</Icon>&nbsp;Buscar
                </MDButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox>
                <DataTable
                  entriesPerPage={(false, { defaultValue: 10, entries: [5, 10, 25, 50] })}
                  canSearch
                  showTotalEntries
                  table={{ columns, rows }}
                  pagination={{ variant: "contained", color: "warning" }}
                  isSorted
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {renderNotificacion}
      <Dialog
        open={showConfirmDelete}
        onClose={handleCancelDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">¿Quiere eliminar el cliente?</DialogTitle>
        <DialogActions>
          <Button onClick={handleCancelDelete}>Cancelar</Button>
          <Button onClick={handleConfirmDelete} autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showConfirmDisable}
        onClose={handleCancelDisable}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">¿Quiere deshabilitar el área?</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Motivo (opcional)"
            fullWidth
            variant="standard"
            onChange={(e) => setDisableReason(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDisable}>Cancelar</Button>
          <Button onClick={handleConfirmDisable} autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
      <Loading load={load} />
      <Footer />
    </DashboardLayout>
  );
}

export default AreasList;
