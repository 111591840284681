/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as React from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

export default function ResponsiveDialog({
  closeModal,
  saveChanges,
  children,
  title,
  titleButton,
  ...rest
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleSubmit = (event) => {
    event.preventDefault();
    saveChanges();
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      maxWidth="xs"
      {...rest}
      aria-labelledby="responsive-dialog-title"
    >
      <MDBox component="form" onSubmit={handleSubmit}>
        <DialogTitle
          id="responsive-dialog-title"
          style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
        >
          <MDTypography
            fontWeight="bold"
            style={{ justifyContent: "flex-start", display: "flex", color: "#344767" }}
          >
            {title}
          </MDTypography>
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions style={{ display: "flex", justifyContent: "flex-end" }}>
          <MDButton
            type="submit"
            variant="outlined"
            size="medium"
            onClick={closeModal}
            style={{ backgroundColor: "#e03131" }}
          >
            Cancelar
          </MDButton>
          <MDButton
            type="submit"
            variant="outlined"
            size="medium"
            onClick={(event) => handleSubmit(event)}
            style={{ backgroundColor: "#099268" }}
          >
            CONFIRMAR
          </MDButton>
        </DialogActions>
      </MDBox>
    </Dialog>
  );
}

// Setting default values for the props of MDSnackbar
ResponsiveDialog.defaultProps = {
  title: "",
  titleButton: "Guardar",
};

// Typechecking props for MDSnackbar
ResponsiveDialog.propTypes = {
  closeModal: PropTypes.func.isRequired,
  saveChanges: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  titleButton: PropTypes.string,
};
