/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// Default Components
import MDTypography from "components/MDTypography";

export default function data(jsonData, viewUploadModal, downloadTemplate, viewModalComment) {
  const route = useLocation().pathname;
  const navigate = useNavigate();

  const setJsonPathSessionStorage = () => {
    sessionStorage.setItem("jsonDataPlantillasSBX", JSON.stringify(jsonData));
  };

  const getRows = () => {
    const elements = [];

    for (let index = 0; index < jsonData.length; index += 1) {
      const element = jsonData[index];
      elements.push({
        taxId: jsonData[index].taxId,
        docType: jsonData[index].docType,
        templateId: jsonData[index].templateId,
        templateType: jsonData[index].templateType,
        country: jsonData[index].country.nombre,
        user: jsonData[index].user,
        date: jsonData[index].date,
        action: (
          <>
            <MDTypography
              variant="body2"
              color="text"
              // display={!jsonData[index].recibido ? "none" : "flex"}
            >
              <Tooltip
                title="Comparar"
                disableInteractive
                onClick={() => {
                  setJsonPathSessionStorage();
                  navigate("/PlantillasJasperCrystal/SBXcompare", { state: { route, element } });
                }}
              >
                <Icon>image_search</Icon>
              </Tooltip>{" "}
              <Tooltip
                title="Ver comentarios Rechazo"
                disableInteractive
                onClick={() => viewModalComment(jsonData[index])}
                sx={{ display: jsonData[index].approved === false ? "inline" : "none" }}
              >
                <Icon color="error">announcement</Icon>
              </Tooltip>
              <Tooltip
                title="Plantilla aprobada"
                disableInteractive
                sx={{ display: !jsonData[index].approved ? "none" : "inline" }}
              >
                <Icon color="success">check_circle</Icon>
              </Tooltip>
              <Tooltip
                title="Descargar"
                disableInteractive
                onClick={() => downloadTemplate(jsonData[index])}
              >
                <Icon>file_download</Icon>
              </Tooltip>
              <Tooltip
                title="Cargar"
                disableInteractive
                onClick={() => viewUploadModal(jsonData[index])}
              >
                <Icon>file_upload</Icon>
              </Tooltip>
            </MDTypography>
            {/* !jsonData[index].templatePreview && (
              <MDTypography
                variant="body2"
                color="text"
                // display={!jsonData[index].recibido ? "none" : "flex"}
              >
                <Tooltip
                  title="Comparar"
                  disableInteractive
                  onClick={() =>
                    navigate("/PlantillasJasperCrystal/SBXcompare", { state: { route, element } })
                  }
                >
                  <Icon>image_search</Icon>
                </Tooltip>{" "}
                <Tooltip
                  title="Desactivar"
                  disableInteractive
                  onClick={() => setJasperStatus(jsonData[index].id, true)}
                >
                  <Icon>block</Icon>
                </Tooltip>
              </MDTypography>
            ) */}
          </>
        ),
      });
    }

    return elements;
  };

  return {
    columns: [
      // { Header: "", accessor: "icon", width: "0%", align: "center" },
      { Header: "Tax ID", accessor: "taxId", align: "center" },
      { Header: "Tipo Documento", accessor: "docType", align: "center" },
      { Header: "ID Plantilla", accessor: "templateId", align: "center" },
      { Header: "Tipo Plantilla", accessor: "templateType", align: "center" },
      { Header: "Pais", accessor: "country", align: "center" },
      { Header: "Usuario", accessor: "user", align: "center" },
      { Header: "Fecha", accessor: "date", align: "center" },
      { Header: "Accion", accessor: "action", align: "center" },
    ],

    rows: getRows(),
  };
}

// Setting default values for the props of MDBadge
data.defaultProps = {
  jsonData: [],
};

// Typechecking props of the data
data.propTypes = {
  jsonData: PropTypes.objectOf(PropTypes.array),
  viewUploadModal: PropTypes.func.isRequired,
  downloadTemplate: PropTypes.func.isRequired,
  viewModalComment: PropTypes.func.isRequired,
};
