/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Custom Views
import Profiles from "views/admin/profiles";
import ProfileUserAdd from "views/admin/profileUsers";
import Areas from "views/admin/areas";
import ProfileFunctionalities from "views/admin/profileFunctionalities";
import SignInBasic from "layouts/authentication/sign-in/basic";
/* import EnexRUTImpuestoNegativo from "views/soporte/Enex_RUTImpuestoNegativo";
import EnexGestionImpuestoNegativo from "views/soporte/Enex_GestionImpuestoNegativ"; */
import EntidadesTributarias from "views/dashboards/entidadTributaria";
import GrafanaInbox from "views/dashboards/grafana";
/* import ContingenciaDIAN from "views/soporte/ContingenciaDIAN";
import ContingenciaPY from "views/soporte/ContingenciaPY";
import XDocGlobalCargaXSLT from "views/soporte/XDocGlobal_CargaXSLT"; */
import ListadoContribuyentes from "views/soporte/EntidadesTributarias";
/* import HabilitarDescargaXML from "views/consultoria/HabilitarDescargaXML";
import AltaEmpresaProd from "views/consultoria/AltaEmpresaProd";
import AltaEmpresaSandbox from "views/consultoria/AltaEmpresaSandbox";
import BloquearEmision from "views/finanzas/GestionCliente/BloquearEmision";
import SingleSingOn from "views/varios/SingleSingOn";
import BusquedaCorreosRecibidos from "views/soporte/ConfiguracionEmpresa/BusquedaCorreosRecibidos";
import BusquedaAdjuntosRecibidos from "views/soporte/ConfiguracionEmpresa/BusquedaAdjuntosRecibidos";
import ChileDesbloquear from "views/consultoria/API/ChileDesbloquear";
import ReprocesarDocumento from "views/soporte/ReprocesarDocumentos";
import ApiKey from "views/soporte/ConfiguracionEmpresa/ApiKey";
import ApiKeySandbox from "views/soporte/ApiKeySandBox";
import ConciliacionChile from "views/consultoria/ConfiguracionEmpresa/conciliacion";
import ActualizarRespuesta from "views/consultoria/API/ActualizarRespuesta"; */
// import PoliticasContrasenia from "views/consultoria/ConfiguracionEmpresa/politicasContrasenia";
// @mui icons
import Icon from "@mui/material/Icon";

const routes = [
  {
    id: -1,
    type: "collapse",
    name: "Home",
    key: "home",
    icon: <Icon fontSize="medium">home_two_tone</Icon>,
    collapse: [
      {
        id: -1,
        name: "Entidades Tributarias",
        key: "entidadesTributarias",
        route: "/dashboards/entidadesTributarias",
        component: <EntidadesTributarias />,
      },
      {
        id: -1,
        name: "Colas",
        key: "grafanaInbox",
        route: "/home/grafanaInbox",
        component: <GrafanaInbox />,
      },
    ],
  },
  {
    id: 0,
    type: "collapse",
    name: "Gestión Usuarios",
    key: "Administracion",
    icon: <Icon fontSize="small">manage_accounts_two_tone</Icon>,
    collapse: [
      {
        id: 0,
        name: "Perfiles",
        key: "Perfiles",
        route: "/Administracion/Perfiles",
        component: <Profiles />,
      },
      {
        id: 0,
        name: "Areas",
        key: "Areas",
        route: "/Administracion/Areas",
        component: <Areas />,
      },
    ],
  },
  /* {
    id: -1,
    type: "collapse",
    name: "Monitor",
    key: "Monitor",
    icon: <Icon fontSize="small">dashboard</Icon>,
    collapse: [],
  }, */
  /* {
    ids: "1|2|5|11|12|15|17|18|21|29|32",
    type: "collapse",
    name: "Soporte",
    key: "Soporte",
    icon: <Icon fontSize="small">dashboard</Icon>,
    collapse: [
      {
        ids: "1|2",
        name: "Enex",
        key: "Enex",
        collapse: [
          {
            id: 1,
            name: "RUT Impuesto Negativo",
            key: "EnexRUTImpuestoNegativo",
            route: "/Soporte/Enex/RUTImpuestoNegativo",
            component: <EnexRUTImpuestoNegativo />,
          },
          {
            id: 2,
            name: "Gestion Impuesto Negativo",
            key: "EnexGestionImpuestoNegativo",
            route: "/Soporte/Enex/GestionImpuestoNegativo",
            component: <EnexGestionImpuestoNegativo />,
          },
        ],
      },
      {
        ids: "5|11",
        name: "Contingencia",
        key: "Contingencia",
        collapse: [
          {
            id: 5,
            name: "Colombia",
            key: "ContingenciaColombia",
            route: "/Soporte/Contingencia/ContingenciaColombia",
            component: <ContingenciaDIAN />,
          },
          {
            id: 11,
            name: "Paraguay",
            key: "ContingenciaParaguay",
            route: "/Soporte/Contingencia/ContingenciaParaguay",
            component: <ContingenciaPY />,
          },
        ],
      },
      {
        ids: "15|29",
        name: "API",
        key: "API1",
        collapse: [
          {
            id: 15,
            name: "Desbloquear",
            key: "ChileDesbloquear1",
            route: "/Soporte/API1/ChileDesbloquear1",
            component: <ChileDesbloquear />,
          },
          {
            id: 29,
            name: "Actualizar Response",
            key: "ActualizarResponse1",
            route: "/Soporte/API1/ActualizarResponse1",
            component: <ActualizarRespuesta />,
          },
        ],
      },
      {
        ids: "17|17",
        name: "Entidad Tributaria",
        key: "EntidadTributaria",
        collapse: [
          {
            id: 17,
            name: "Reprocesar Documento",
            key: "ReprocesarDocumento",
            route: "/Soporte/EntidadTributaria/ReprocesarDocumento",
            component: <ReprocesarDocumento />,
          },
        ],
      },
      {
        ids: "12|18",
        name: "Sparkpost",
        key: "Sparkpost01",
        collapse: [
          {
            id: 12,
            name: "Busqueda Correos Recibidos",
            key: "BusquedaCorreosRecibidos1",
            route: "/Soporte/Sparkpost01/BusquedaCorreosRecibidos1",
            component: <BusquedaCorreosRecibidos />,
          },
          {
            id: 18,
            name: "Busqueda Adjuntos Recibidos",
            key: "BusquedaAdjuntosRecibidos1",
            route: "/Soporte/Sparkpost01/BusquedaAdjuntosRecibidos1",
            component: <BusquedaAdjuntosRecibidos />,
          },
        ],
      },
      {
        ids: "21|32",
        name: "ApiKey",
        key: "ApiKey4",
        collapse: [
          {
            id: 21,
            name: "Producción",
            key: "Produccion4",
            route: "/Soporte/ApiKey4/Produccion4",
            component: <ApiKey />,
          },
          {
            id: 32,
            name: "Sandbox",
            key: "Sandbox4",
            route: "/Soporte/ApiKey4/Sandbox4",
            component: <ApiKeySandbox />,
          },
        ],
      },
    ],
  },
  {
    ids: "3|7|8|10|16|24|25|28|30|35",
    type: "collapse",
    name: "Consultoria",
    key: "Consultoria",
    icon: <Icon fontSize="small">dashboard</Icon>,
    collapse: [
      {
        ids: "3|3",
        name: "Configuracion Empresa",
        key: "ConfiguracionEmpresa1",
        collapse: [
          {
            id: 3,
            name: "Inbox - Carga XSLT",
            key: "CargaXSLT1",
            route: "/Consultoria/ConfiguracionEmpresa1/CargaXSLT1",
            component: <XDocGlobalCargaXSLT />,
          },
        ],
      },
      {
        ids: "7|7",
        name: "xDoc",
        key: "xDoc",
        collapse: [
          {
            id: 7,
            name: "Habilitar Descarga XML",
            key: "HabilitarDescargaXML",
            route: "/Consultoria/xDoc/HabilitarDescargaXML",
            component: <HabilitarDescargaXML />,
          },
        ],
      },
      {
        ids: "8|10",
        name: "PAC Panama",
        key: "pacPanama",
        collapse: [
          {
            id: 8,
            name: "Alta Producción",
            key: "AltaEmpresaProduccion",
            route: "/Consultoria/pacPanama/AltaEmpresaProduccion",
            component: <AltaEmpresaProd />,
          },
          {
            id: 10,
            name: "Alta Sandbox",
            key: "AltaEmpresaSandbox",
            route: "/Consultoria/pacPanama/AltaEmpresaSandbox",
            component: <AltaEmpresaSandbox />,
          },
        ],
      },
      {
        ids: "16|30",
        name: "API",
        key: "API2",
        collapse: [
          {
            id: 16,
            name: "Desbloquear",
            key: "ChileDesbloquear2",
            route: "/Consultoria/API2/ChileDesbloquear2",
            component: <ChileDesbloquear />,
          },
          {
            id: 30,
            name: "Actualizar Response",
            key: "ActualizarResponse2",
            route: "/Consultoria/API2/ActualizarResponse2",
            component: <ActualizarRespuesta />,
          },
        ],
      },
      {
        ids: "25|25",
        name: "Conciliación",
        key: "Conciliacion1",
        collapse: [
          {
            id: 25,
            name: "Chile",
            key: "Chile1",
            route: "/Consultoria/Conciliacion1/Chile1",
            component: <ConciliacionChile />,
          },
        ],
      },
      {
        ids: "24|35",
        name: "ApiKey",
        key: "ApiKey1",
        collapse: [
          {
            id: 24,
            name: "Producción",
            key: "Produccion1",
            route: "/Consultoria/ApiKey1/Produccion1",
            component: <ApiKey />,
          },
          {
            id: 35,
            name: "Sandbox",
            key: "Sandbox1",
            route: "/Consultoria/ApiKey1/Sandbox1",
            component: <ApiKeySandbox />,
          },
        ],
      },
      {
        id: 28,
        name: "Single Sing On",
        key: "SingleSingOn",
        route: "/Consultoria/SingleSingOn",
        component: <SingleSingOn />,
      },
    ],
  },
  {
    ids: "4|14|19|23|26|31|34",
    type: "collapse",
    name: "Producto",
    key: "Producto",
    icon: <Icon fontSize="small">dashboard</Icon>,
    collapse: [
      {
        ids: "4|4",
        name: "Configuracion Empresa",
        key: "ConfiguracionEmpresa2",
        collapse: [
          {
            id: 4,
            name: "Inbox - Carga XSLT",
            key: "CargaXSLT2",
            route: "/Producto/ConfiguracionEmpresa2/CargaXSLT2",
            component: <XDocGlobalCargaXSLT />,
          },
        ],
      },
      {
        ids: "14|19",
        name: "Sparkpost",
        key: "Sparkpost02",
        collapse: [
          {
            id: 14,
            name: "Busqueda Correos Recibidos",
            key: "BusquedaCorreosRecibidos3",
            route: "/Producto/Sparkpost02/BusquedaCorreosRecibidos3",
            component: <BusquedaCorreosRecibidos />,
          },
          {
            id: 19,
            name: "Busqueda Adjuntos Recibidos",
            key: "BusquedaAdjuntosRecibidos2",
            route: "/Producto/Sparkpost02/BusquedaAdjuntosRecibidos2",
            component: <BusquedaAdjuntosRecibidos />,
          },
        ],
      },
      {
        ids: "26|26",
        name: "Conciliación",
        key: "Conciliacion2",
        collapse: [
          {
            id: 26,
            name: "Chile",
            key: "Chile2",
            route: "/Producto/Conciliacion2/Chile2",
            component: <ConciliacionChile />,
          },
        ],
      },
      {
        ids: "31|31",
        name: "API",
        key: "API3",
        collapse: [
          {
            id: 31,
            name: "Actualizar Response",
            key: "ActualizarResponse3",
            route: "/Producto/API3/ActualizarResponse3",
            component: <ActualizarRespuesta />,
          },
        ],
      },
      {
        ids: "23|34",
        name: "ApiKey",
        key: "ApiKey2",
        collapse: [
          {
            id: 23,
            name: "Producción",
            key: "Produccion2",
            route: "/Producto/ApiKey2/Produccion2",
            component: <ApiKey />,
          },
          {
            id: 34,
            name: "Sandbox",
            key: "Sandbox2",
            route: "/Producto/ApiKey2/Sandbox2",
            component: <ApiKeySandbox />,
          },
        ],
      },
    ],
  },
  {
    ids: "6|13|20|22|27|33",
    type: "collapse",
    name: "Tecnologia",
    key: "Tecnologia",
    icon: <Icon fontSize="small">dashboard</Icon>,
    collapse: [
      {
        ids: "6|6",
        name: "Configuracion Empresa",
        key: "ConfiguracionEmpresa3",
        collapse: [
          {
            id: 6,
            name: "Inbox - Carga XSLT",
            key: "CargaXSLT3",
            route: "/Tecnologia/ConfiguracionEmpresa3/CargaXSLT3",
            component: <XDocGlobalCargaXSLT />,
          },
        ],
      },
      {
        ids: "13|20",
        name: "Sparkpost",
        key: "Sparkpost03",
        collapse: [
          {
            id: 13,
            name: "Busqueda Correos Recibidos",
            key: "BusquedaCorreosRecibidos2",
            route: "/Tecnologia/Sparkpost03/BusquedaCorreosRecibidos2",
            component: <BusquedaCorreosRecibidos />,
          },
          {
            id: 20,
            name: "Busqueda Adjuntos Recibidos",
            key: "BusquedaAdjuntosRecibidos3",
            route: "/Tecnologia/Sparkpost03/BusquedaAdjuntosRecibidos3",
            component: <BusquedaAdjuntosRecibidos />,
          },
        ],
      },
      {
        ids: "27|27",
        name: "Conciliación",
        key: "Conciliacion3",
        collapse: [
          {
            id: 27,
            name: "Chile",
            key: "Chile3",
            route: "/Tecnologia/Conciliacion3/Chile3",
            component: <ConciliacionChile />,
          },
        ],
      },
      {
        ids: "22|33",
        name: "ApiKey",
        key: "ApiKey3",
        collapse: [
          {
            id: 22,
            name: "Producción",
            key: "Produccion3",
            route: "/Tecnologia/ApiKey3/Produccion3",
            component: <ApiKey />,
          },
          {
            id: 33,
            name: "Sandbox",
            key: "Sandbox3",
            route: "/Tecnologia/ApiKey3/Sandbox3",
            component: <ApiKeySandbox />,
          },
        ],
      },
    ],
  },
  {
    ids: "9|9",
    type: "collapse",
    name: "Finanzas",
    key: "Finanzas",
    icon: <Icon fontSize="small">dashboard</Icon>,
    collapse: [
      {
        ids: "9|9",
        name: "Gestión Cliente",
        key: "GestionCliente",
        collapse: [
          {
            id: 9,
            name: "Bloquear Emision",
            key: "BloquearEmision",
            route: "/Finanzas/GestionCliente/BloquearEmision",
            component: <BloquearEmision />,
          },
        ],
      },
    ],
  }, */
  {
    id: -1,
    type: "collapse",
    name: "Listado Contribuyentes",
    key: "ListadoContribuyentes",
    route: "/ListadoContribuyentes",
    icon: <Icon fontSize="small">checklist_rtl_two_tone</Icon>,
    component: <ListadoContribuyentes />,
    noCollapse: true,
  },
  /* {
    id: -1,
    type: "collapse",
    name: "Politicas Contraseña",
    key: "PoliticasContrasenia",
    route: "/PoliticasContrasenia",
    icon: <Icon fontSize="small">reorder</Icon>,
    component: <PoliticasContrasenia />,
    noCollapse: true,
  }, */
  {
    type: "none",
    name: "basic",
    key: "BasicLogin",
    route: "/authentication/sign-in/basic",
    component: <SignInBasic />,
  },
  {
    type: "none",
    name: "PerfilUsuario",
    key: "PerfilUsuario",
    route: "/PerfilUsuario",
    component: <ProfileUserAdd />,
  },
  {
    type: "none",
    name: "PerfilFuncionalidad",
    key: "PerfilFuncionalidad",
    route: "/PerfilFuncionalidad",
    component: <ProfileFunctionalities />,
  },
];

export default routes;
