/* eslint-disable react/prop-types */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// @mui material components
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  TextField,
} from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// Data
import data from "views/admin/xdoc/clients/data";

// API Request
import APIRequest from "api/xDocApiRequest";

import ClientEditDialog from "./edit";

function Clients() {
  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  const [jsonData, setJsonData] = useState([]);
  const { pathname, state } = useLocation();
  const route = useLocation().pathname;
  const navigate = useNavigate();

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );

  const getJsonData = async () => {
    const tempJsonData = [];

    const apiResponse = await (await APIRequest("GET", "/Client/GetAll", null)).json();

    apiResponse.forEach((item) => {
      tempJsonData.push(item);
    });

    setJsonData(tempJsonData);
  };

  const [taxIdFilter, setTaxIdFilter] = useState("");
  const getJsonDataByTaxId = async (e) => {
    const filter = e.target.value;
    setTaxIdFilter(filter);

    const tempJsonData = [];
    if (!filter) {
      getJsonData();
      return;
    }

    const apiResponse = await (
      await APIRequest("GET", `/Client/GetByTaxId?taxId=${filter}`, null)
    ).json();

    apiResponse.forEach((item) => {
      tempJsonData.push(item);
    });

    setJsonData(tempJsonData);
  };
  const clearTaxIdFilter = () => {
    setTaxIdFilter("");
    getJsonData();
  };

  const [showConfirmDisable, setShowConfirmDisable] = useState(false);
  const [clientToDisable, setClientToDisable] = useState({});
  const [disableReason, setDisableReason] = useState("");
  const setEnabled = async (client, disableConfirmed) => {
    const enable = !client.enabled;
    if (!enable && !disableConfirmed) {
      setClientToDisable(client);
      setShowConfirmDisable(true);
      return;
    }

    try {
      const apiResponse = await APIRequest("POST", "/Client/SetEnabled", {
        clientId: client.id,
        enable,
        reason: disableReason,
      });

      if (apiResponse.status === 200) {
        setJsonData(
          jsonData.map((item) => ({
            ...item,
            enabled: item.id === client.id ? enable : item.enabled,
            lastDisableReason: item.id === client.id ? disableReason : item.lastDisableReason,
          }))
        );
        configNotification(
          "warning",
          "check",
          "Notificación",
          enable
            ? `Se habilitó el cliente '${client.name}' exitosamente.`
            : `Se deshabilitó el cliente '${client.name}' exitosamente.`,
          "",
          true
        );
      } else {
        const errorMessage = {
          code: apiResponse.status,
          message: `Error: ${apiResponse.status}`,
        };
        throw errorMessage;
      }
    } catch (e) {
      configNotification(
        "error",
        "warning",
        "Notificación",
        enable
          ? `Ocurrió un error al habilitar el cliente '${e.message}'.`
          : `Ocurrió un error al deshabilitar el cliente '${e.message}'.`,
        "",
        true
      );
    }
  };
  const handleCancelDisable = () => {
    setShowConfirmDisable(false);
  };
  const handleConfirmDisable = () => {
    setEnabled(clientToDisable, true);
    setShowConfirmDisable(false);
  };

  const [showEditForm, setShowEditForm] = useState(false);
  const [clientToEdit, setClientToEdit] = useState({});
  const editClient = async (client) => {
    setClientToEdit(client);
    setShowEditForm(true);
  };
  const onEditSuccess = () => {
    const index = jsonData.map((item) => item.id).indexOf(clientToEdit.id);
    const elements = [...jsonData];
    elements.splice(index, 1);
    setJsonData([...elements, clientToEdit]);
  };

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [clientIdToDelete, setClientIdToDelete] = useState("");
  const deleteJsonData = async (id, confirmed) => {
    if (!confirmed) {
      setClientIdToDelete(id);
      setShowConfirmDelete(true);
      return;
    }

    try {
      const apiResponse = await APIRequest("DELETE", `/Client/Delete?id=${id}`, null);

      if (apiResponse.status === 200) {
        const index = jsonData.map((item) => item.id).indexOf(id);
        const elements = [...jsonData];
        const nombreElemento = elements[index].name;
        elements.splice(index, 1);
        setJsonData(elements);
        configNotification(
          "warning",
          "check",
          "Notificación",
          `Se eliminó el cliente '${nombreElemento}' exitosamente.`,
          "",
          true
        );
      } else {
        const errorMessage = {
          code: apiResponse.status,
          message: `Error: ${apiResponse.status}`,
        };
        throw errorMessage;
      }
    } catch (e) {
      configNotification(
        "error",
        "warning",
        "Notificación",
        `Ocurrió un error al eliminar el cliente: ${e.message}`,
        "",
        true
      );
    }
  };
  const handleCancelDelete = () => {
    setShowConfirmDelete(false);
  };
  const handleConfirmDelete = () => {
    deleteJsonData(clientIdToDelete, true);
    setShowConfirmDelete(false);
  };

  const viewDetails = (client) =>
    navigate("/AdministracionXDoc/Clientes/Detalles", { state: { client, route } });

  useEffect(() => {
    if (state && state.notificationParams) {
      configNotification(...state.notificationParams);
      window.history.replaceState({}, document.title);
    }
    getJsonData();
  }, [pathname]);

  const { columns, rows } = data(jsonData, deleteJsonData, setEnabled, viewDetails, editClient);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                <MDBox>
                  <MDTypography variant="h4" gutterBottom color="warning">
                    Clientes
                  </MDTypography>
                </MDBox>
              </MDBox>
              <MDBox style={{ marginLeft: "25px" }}>
                <TextField
                  onChange={getJsonDataByTaxId}
                  variant="outlined"
                  value={taxIdFilter}
                  label="Buscar por ID fiscal"
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        sx={{ visibility: taxIdFilter ? "visible" : "hidden" }}
                        onClick={clearTaxIdFilter}
                      >
                        <Icon>clear</Icon>
                      </IconButton>
                    ),
                  }}
                />
              </MDBox>
              <MDBox>
                <DataTable
                  entriesPerPage={(false, { defaultValue: 10, entries: [5, 10, 25, 50] })}
                  canSearch
                  showTotalEntries
                  table={{ columns, rows }}
                  pagination={{ variant: "contained", color: "warning" }}
                  isSorted
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {renderNotificacion}
      <Dialog
        open={showConfirmDelete}
        onClose={handleCancelDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">¿Quiere eliminar el cliente?</DialogTitle>
        <DialogActions>
          <Button onClick={handleCancelDelete}>Cancelar</Button>
          <Button onClick={handleConfirmDelete} autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showConfirmDisable}
        onClose={handleCancelDisable}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">¿Quiere deshabilitar el cliente?</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Motivo (opcional)"
            fullWidth
            variant="standard"
            onChange={(e) => setDisableReason(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDisable}>Cancelar</Button>
          <Button onClick={handleConfirmDisable} autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>

      <ClientEditDialog
        client={clientToEdit}
        setClient={setClientToEdit}
        show={showEditForm}
        setShow={setShowEditForm}
        notify={configNotification}
        onSuccess={onEditSuccess}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default Clients;
