/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// @mui material components
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDInput from "components/MDInput";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import data from "views/soporte/EntidadesTributarias/data";

// API Request
import APIRequestGeneric from "api/requestGeneric";

function EntidadesTributarias() {
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  const host = process.env.REACT_APP_API_URL;
  const basicAuth = `${process.env.REACT_APP_API_ENEX_USER}:${process.env.REACT_APP_API_ENEX_PASSWORD}`;
  const basicAuthorization = `Basic ${btoa(basicAuth)}`;

  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  const [jsonData, setJsonData] = useState([]);
  const { pathname } = useLocation();

  const [newValuePais, setNewValuePais] = useState(null);
  const [newValueTaxId, setNewValueTaxId] = useState("");
  const [selectData, setSelectData] = useState({
    pais: [
      { id: 1, nombre: "Costa rica" },
      { id: 2, nombre: "Ecuador" },
      { id: 3, nombre: "Perú" },
      { id: 4, nombre: "Chile" },
      { id: 5, nombre: "México" },
      { id: 6, nombre: "Guatemala" },
      { id: 7, nombre: "Colombia" },
      { id: 8, nombre: "Panamá" },
    ],
  });

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };

  const showNotificationError = (message) => {
    configNotification("error", "warning", "Notificación", message, "", true);
  };

  const showNotificationSuccess = (message) => {
    configNotification("warning", "check", "Notificación", message, "", true);
  };

  const saveDataModal = async (event) => {
    event.preventDefault();
    const tempJsonData = [];
    const body = {
      Country: newValuePais.id,
      TaxId: newValueTaxId,
      User: userInfo.correo,
    };
    const urlComplete = `${host}/api/ManagerContributors/GetCountriesByTaxId`;
    const apiResponse = await (
      await APIRequestGeneric("POST", urlComplete, body, false, basicAuthorization)
    ).json();

    if (apiResponse.Code === 200 && apiResponse.IsSuccessful) {
      const jsonResponse = JSON.parse(apiResponse.Data);

      for (let index = 0; index < jsonResponse.length; index += 1) {
        tempJsonData.push({
          pais: selectData.pais[
            selectData.pais.map((item) => item.id).indexOf(jsonResponse[index].ContryId)
          ],
          taxId: jsonResponse[index].TaxId,
          mailEntidad: jsonResponse[index].EmailDistribution,
          mailGosocket: "---",
        });
      }

      setJsonData(tempJsonData);
      showNotificationSuccess("Se completó la búsqueda exitosamente");
    } else {
      showNotificationError(apiResponse.Message);
    }
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );

  const getCountriesData = async () => {
    const tempJsonData = [];
    const body = { User: userInfo.correo };
    const urlComplete = `${host}/api/ManagerContributors/GetCountries`;
    const apiResponse = await (
      await APIRequestGeneric("POST", urlComplete, body, false, basicAuthorization)
    ).json();

    if (apiResponse.Code === 200) {
      const jsonResponse = JSON.parse(apiResponse.Data);

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(jsonResponse)) {
        tempJsonData.push({
          id: key,
          nombre: value,
        });
      }

      setSelectData({
        pais: tempJsonData,
      });
    }
  };

  useEffect(() => {
    getCountriesData();
  }, [pathname]);

  const { columns, rows } = data(jsonData);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                <MDBox>
                  <MDTypography variant="h4" gutterBottom color="warning">
                    Mails registrados en Gosocket y Entidad Tributaria
                  </MDTypography>
                </MDBox>
              </MDBox>
              <MDBox>
                <Card>
                  <MDBox
                    sx={{ position: "relative", mx: 3, py: 2, px: 2 }}
                    component="form"
                    onSubmit={saveDataModal}
                  >
                    <Grid
                      container
                      spacing={3}
                      justifyContent="left"
                      alignItems="left"
                      height="100%"
                    >
                      <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                        <Autocomplete
                          value={newValuePais}
                          onChange={(event, newValue) => {
                            setNewValuePais(newValue);
                          }}
                          options={selectData.pais}
                          getOptionLabel={(option) => option.nombre}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          renderInput={(params) => (
                            <MDInput
                              variant="standard"
                              type="text"
                              label="Pais"
                              fullWidth
                              required
                              InputLabelProps={{ shrink: true }}
                              {...params}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                        <MDInput
                          variant="standard"
                          type="text"
                          label="Tax ID"
                          fullWidth
                          required
                          InputLabelProps={{ shrink: true }}
                          value={newValueTaxId}
                          onChange={(e) => {
                            setNewValueTaxId(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                        <MDButton variant="outlined" autoFocus type="submit" color="warning">
                          Buscar
                        </MDButton>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <MDTypography variant="subtitle2" color="error">
                          NIT Colombia sin dígito verificador
                          <br />
                          RUT Chile sin puntos, con guion y dígito verificador
                        </MDTypography>
                      </Grid>
                    </Grid>
                  </MDBox>
                </Card>
              </MDBox>
              <MDBox>
                <DataTable
                  entriesPerPage={(false, { defaultValue: 10, entries: [5, 10, 25, 50] })}
                  canSearch
                  showTotalEntries
                  table={{ columns, rows }}
                  pagination={{ variant: "contained", color: "warning" }}
                  isSorted
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {renderNotificacion}
      <Footer />
    </DashboardLayout>
  );
}

export default EntidadesTributarias;
