const orderIds = (array) =>
  array.map((value, index) => ({
    ...value,
    id: index,
  }));

export function insertAndUpdateIds(array, item, position, order = true) {
  const updatedData = [
    ...array.slice(0, position),
    ...(Array.isArray(item) ? item : [item]),
    ...array.slice(position),
  ];
  return order ? orderIds(updatedData) : updatedData;
}

export function insertAttributesAtPosition(obj, newAttributes, position) {
  const entries = Object.entries(obj);
  const before = entries.slice(0, position);
  const after = entries.slice(position);

  const newEntries = [...before, ...Object.entries(newAttributes), ...after];

  return Object.fromEntries(newEntries);
}

export const moveItemToPosition = (arr, fromIndex, toIndex) => {
  const newArray = [...arr];
  const [item] = newArray.splice(fromIndex, 1);
  newArray.splice(toIndex, 0, item);
  return orderIds(newArray);
};
