/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Switch from "@mui/material/Switch";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import data from "views/consultoria/ConfiguracionEmpresa/conciliacion/data";

// API Request
import APIRequest from "api/request";

function Conciliacion() {
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  const pais = { Id: 3, Nombre: "Chile", Codigo: "CL" };
  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  const [jsonData, setJsonData] = useState([]);
  const { pathname } = useLocation();

  // eslint-disable-next-line no-unused-vars
  const [elementIsNew, setElementIsNew] = useState(true);
  const [newValueTaxId, setNewValueTaxId] = useState("");
  const [newValueDocSII, setNewValueDocSII] = useState(false);
  const [newValueDTERecibidos, setNewValueDTERecibidos] = useState(false);
  const [load, setLoad] = useState(false);

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };
  const loading = (
    <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10000 }} open={load}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
  const showNotificationError = (message) => {
    configNotification("error", "warning", "Notificación", message, "", true);
  };
  const showNotificationSuccess = (message) => {
    configNotification("warning", "check", "Notificación", message, "", true);
  };
  const setFuncionalities = () => {
    setNewValueDocSII(!newValueDocSII);
    setNewValueDTERecibidos(!newValueDTERecibidos);
  };
  const saveDataModal = async () => {
    setLoad(true);

    try {
      const index = jsonData.map((item) => item.taxId).indexOf(newValueTaxId);

      if (index < 0) {
        const tempJsonData = jsonData;
        const body = {
          TaxId: newValueTaxId,
          DocSII: newValueDocSII,
          DTERecibidos: newValueDTERecibidos,
          Usuario: userInfo.correo,
          Pais: pais.Id,
        };
        const apiResponse = await APIRequest("POST", "/Api/Conciliacion", body);
        if (apiResponse.status === 200) {
          const element = {
            taxId: newValueTaxId,
            docSII: newValueDocSII,
            dTERecibidos: newValueDTERecibidos,
            usuario: userInfo.correo,
          };
          setNewValueTaxId("");
          setNewValueDocSII(false);
          setNewValueDTERecibidos(false);
          tempJsonData.push(element);
        } else {
          const errorMessage = {
            code: apiResponse.status,
            message: `Error: ${apiResponse.status}`,
          };
          throw errorMessage;
        }
        setJsonData(tempJsonData);
        showNotificationSuccess(`Se agregó el tax ${newValueTaxId} exitosamente`);
      } else {
        showNotificationSuccess("Elemento ya registrado");
      }
    } catch (e) {
      showNotificationError(`Ocurrió un error en el perfil: ${e.message}`);
    }
    setLoad(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    saveDataModal();
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );

  const getJsonData = async () => {
    const tempJsonData = [];
    const filter = {
      User: userInfo.correo,
      Functionality: "",
      CountryId: pais.Codigo,
    };
    setLoad(true);
    try {
      const conciliacionesesApi = await (
        await APIRequest("Post", "/Api/Conciliacion/Todos", filter)
      ).json();

      for (let index = 0; index < conciliacionesesApi.length; index += 1) {
        tempJsonData.push({
          taxId: conciliacionesesApi[index].taxId,
          docSII: conciliacionesesApi[index].docSII,
          dTERecibidos: conciliacionesesApi[index].dteRecibidos,
          usuario: conciliacionesesApi[index].usuario,
        });
      }
      setJsonData(tempJsonData);
    } catch (e) {
      console.log(`Ocurrió un error : ${e.message}`);
    }
    setLoad(false);
  };

  const updateDocSIIJsonData = async (taxId) => {
    setLoad(true);
    const tempJsonData = jsonData;
    const index = tempJsonData.map((item) => item.taxId).indexOf(taxId);
    const body = {
      TaxId: tempJsonData[index].taxId, // s
      DocSII: !tempJsonData[index].docSII,
      DTERecibidos: !tempJsonData[index].docSII,
      Usuario: userInfo.correo,
      Pais: pais.Id,
    };
    try {
      const apiResponse = await APIRequest("PATCH", "/Api/Conciliacion", body);
      if (apiResponse.status === 200) {
        tempJsonData[index].docSII = !tempJsonData[index].docSII;
        tempJsonData[index].dTERecibidos = tempJsonData[index].docSII;
        tempJsonData[index].usuario = userInfo.correo;
      } else {
        const errorMessage = {
          code: apiResponse.status,
          message: `Error: ${apiResponse.status}`,
        };
        throw errorMessage;
      }
      setJsonData(tempJsonData);
    } catch (e) {
      configNotification(
        "error",
        "warning",
        "Notificación",
        `Ocurrió un error en el perfil: ${e.message}`,
        "",
        true
      );
    }
    setLoad(false);
    showNotificationSuccess(`Se actualizó el tax ${tempJsonData[index].taxId} exitosamente`);
  };

  const updateDTERecibidosJsonData = async (taxId) => {
    setLoad(true);
    const tempJsonData = jsonData;
    const index = tempJsonData.map((item) => item.taxId).indexOf(taxId);
    const body = {
      TaxId: tempJsonData[index].taxId,
      DocSII: !tempJsonData[index].dTERecibidos,
      DTERecibidos: !tempJsonData[index].dTERecibidos,
      Usuario: userInfo.correo,
      Pais: pais.Id,
    };
    try {
      const apiResponse = await APIRequest("PATCH", "/Api/Conciliacion", body);
      if (apiResponse.status === 200) {
        tempJsonData[index].dTERecibidos = !tempJsonData[index].dTERecibidos;
        tempJsonData[index].docSII = tempJsonData[index].dTERecibidos;
        tempJsonData[index].usuario = userInfo.correo;
      } else {
        const errorMessage = {
          code: apiResponse.status,
          message: `Error: ${apiResponse.status}`,
        };
        throw errorMessage;
      }
      setJsonData(tempJsonData);
    } catch (e) {
      configNotification(
        "error",
        "warning",
        "Notificación",
        `Ocurrió un error en el perfil: ${e.message}`,
        "",
        true
      );
    }
    setLoad(false);
    showNotificationSuccess(`Se actualizó el tax ${tempJsonData[index].taxId} exitosamente`);
  };

  useEffect(() => {
    getJsonData();
  }, [pathname]);

  const { columns, rows } = data(jsonData, updateDocSIIJsonData, updateDTERecibidosJsonData);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MDTypography variant="subtitle" fontWeight="bold">
              Permite conciliar Documentos con SII y DTE Recibidos para un TaxID en Chile
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDBox pt={3} pb={3} component="form" onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Card>
                    <MDBox sx={{ position: "relative", mx: 3, py: 2, px: 2 }}>
                      <Grid
                        container
                        spacing={3}
                        justifyContent="left"
                        alignItems="left"
                        height="100%"
                      >
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                          <MDInput
                            variant="standard"
                            type="text"
                            label="Tax ID"
                            fullWidth
                            required
                            InputLabelProps={{ shrink: true }}
                            value={newValueTaxId}
                            onChange={(e) => {
                              setNewValueTaxId(e.target.value);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                          <MDBox
                            display="flex"
                            justifyContent="flex-end"
                            alignItems="center"
                            width={{ xs: "100%", sm: "auto" }}
                            mt={{ xs: 1, sm: 0 }}
                          >
                            <MDBox lineHeight={0} mx={2}>
                              <MDTypography variant="button" color="text">
                                Documentos con SII
                              </MDTypography>
                            </MDBox>
                            <MDBox mr={1}>
                              <Switch
                                checked={newValueDocSII}
                                onChange={() => setFuncionalities()}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            </MDBox>
                          </MDBox>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                          <MDBox
                            display="flex"
                            justifyContent="flex-end"
                            alignItems="center"
                            width={{ xs: "100%", sm: "auto" }}
                            mt={{ xs: 1, sm: 0 }}
                          >
                            <MDBox lineHeight={0} mx={2}>
                              <MDTypography variant="button" color="text">
                                DTE Recibidos
                              </MDTypography>
                            </MDBox>
                            <MDBox mr={1}>
                              <Switch
                                checked={newValueDTERecibidos}
                                onChange={() => setFuncionalities()}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            </MDBox>
                          </MDBox>
                        </Grid>
                      </Grid>
                    </MDBox>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent="left" alignItems="left" height="100%">
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={10} />
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={4}
                      xl={2}
                      container
                      justifyContent="flex-end"
                    >
                      <MDButton
                        variant="contained"
                        type="submit"
                        sx={{
                          background: "linear-gradient(45deg, #099268 30%, #099268 90%)",
                          color: "#FFFFFF",
                        }}
                      >
                        <Icon>save</Icon>&nbsp;Guardar
                      </MDButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox>
                <DataTable
                  entriesPerPage={(false, { defaultValue: 10, entries: [5, 10, 25, 50] })}
                  canSearch
                  showTotalEntries
                  table={{ columns, rows }}
                  pagination={{ variant: "contained", color: "warning" }}
                  isSorted
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {renderNotificacion}
      {loading}
      <Footer />
    </DashboardLayout>
  );
}

export default Conciliacion;
