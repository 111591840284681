import { useState } from "react";
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";
import HistoryIcon from "@mui/icons-material/History";
import Icon from "@mui/material/Icon";
import MDTypography from "../../../../../components/MDTypography";
import MDSnackbar from "../../../../../components/MDSnackbar";

export default function data(jsonData, setHistoryJsonData) {
  const [notification, setNotification] = useState(null);

  const showNotificationSuccess = (message) => {
    setNotification({
      color: "success",
      icon: "check",
      title: "Notificación",
      content: message,
      dateTime: "",
      show: true,
    });
  };

  const handleCopy = (event, value) => {
    const text = typeof value === "string" ? value.toString() : value.props.children;
    navigator.clipboard.writeText(text);
    showNotificationSuccess("El elemento se ha copiado exitosamente");
  };

  const getRows = () =>
    jsonData.map((dataMap) => ({
      taxId: dataMap.taxId,
      guid: dataMap.id,
      idXPos: (
        <div className="copy-container" style={{ alignItems: "center" }}>
          <Tooltip title={dataMap.xPosGuid} disableInteractive>
            <span style={{ marginRight: 4 }}>{`${dataMap.xPosGuid.split("-")[0]}-${
              dataMap.xPosGuid.split("-")[1]
            }...`}</span>
          </Tooltip>
          <Icon
            fontSize="small"
            style={{ cursor: "pointer" }}
            onClick={(event) => handleCopy(event, dataMap.xPosGuid)}
          >
            copy
          </Icon>
        </div>
      ),
      xPosName: dataMap.xPosName,
      maxContingencyStartDate: `${dataMap.maxContingencyStartDate.split("T")[0]} ${
        dataMap.contingencyStartDate.split("T")[1].split(".")[0]
      }`,
      action: (
        <MDTypography
          color="text"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          {notification && (
            <MDSnackbar
              color={notification.color}
              icon={notification.icon}
              title={notification.title}
              content={notification.content}
              dateTime={notification.dateTime}
              open={notification.show}
              close={() => setNotification(null)}
              onClose={() => setNotification(null)}
              bgWhite
            />
          )}
          <Tooltip
            title="Histórico"
            disableInteractive
            style={{ cursor: "pointer" }}
            onClick={() => {
              setHistoryJsonData(dataMap);
            }}
          >
            <HistoryIcon fontSize="22px" style={{ fontSize: "22px", cursor: "pointer" }} />
          </Tooltip>
        </MDTypography>
      ),
    }));

  return {
    columns: [
      { Header: "TaxID", accessor: "taxId", align: "center" },
      { Header: "ID xPOS", accessor: "idXPos", align: "center" },
      { Header: "Nombre xPOS", accessor: "xPosName", align: "center" },
      { Header: "Fecha última contingencia", accessor: "maxContingencyStartDate", align: "center" },
      { Header: "Acciones", accessor: "action", align: "center" },
    ],
    rows: getRows(),
  };
}

data.defaultProps = {
  jsonData: [],
};

data.propTypes = {
  jsonData: PropTypes.arrayOf(PropTypes.object),
  setHistoryJsonData: PropTypes.func.isRequired,
  handleDownload: PropTypes.func.isRequired,
};
