/* eslint-disable react/prop-types */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// @mui material components
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  TextField,
} from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDSnackbar from "components/MDSnackbar";

// Material Dashboard 2 React example components
import DataTable from "examples/Tables/DataTable";

// Data
import data from "views/admin/xdoc/installations/data";

// API Request
import APIRequest from "api/xDocApiRequest";

function Installations(props) {
  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  const [jsonData, setJsonData] = useState([]);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { client } = props;

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );

  const getJsonData = async () => {
    const tempJsonData = [];

    let apiResponse;
    if (client) {
      apiResponse = await (
        await APIRequest("GET", `/Installation/GetByClient?clientId=${client.id}`, null)
      ).json();
    } else {
      apiResponse = await (await APIRequest("GET", "/Installation/GetAll", null)).json();
    }

    apiResponse.forEach((item) => {
      tempJsonData.push(item);
    });

    setJsonData(tempJsonData);
  };

  const [taxIdFilter, setTaxIdFilter] = useState("");
  const getJsonDataByTaxId = async (e) => {
    const filter = e.target.value;
    setTaxIdFilter(filter);

    const tempJsonData = [];
    if (!filter) {
      getJsonData();
      return;
    }

    const clientParam = client ? `&clientId=${client.id}` : "";
    const apiResponse = await (
      await APIRequest("GET", `/Installation/GetByTaxId?taxId=${filter}${clientParam}`, null)
    ).json();

    apiResponse.forEach((item) => {
      tempJsonData.push(item);
    });

    setJsonData(tempJsonData);
  };
  const clearTaxIdFilter = () => {
    setTaxIdFilter("");
    getJsonData();
  };

  const [showConfirmDisable, setShowConfirmDisable] = useState(false);
  const [installationToDisable, setInstallationToDisable] = useState({});
  const [disableReason, setDisableReason] = useState("");
  const setEnabled = async (installation, confirmed) => {
    const enable = !installation.enabled;
    if (!enable && !confirmed) {
      setInstallationToDisable(installation);
      setShowConfirmDisable(true);
      return;
    }

    try {
      const apiResponse = await APIRequest("POST", "/Installation/SetEnabled", {
        installationGuid: installation.guid,
        enable,
        reason: disableReason,
      });

      if (apiResponse.status === 200) {
        setJsonData(
          jsonData.map((item) => ({
            ...item,
            enabled: item.guid === installation.guid ? enable : item.enabled,
            lastDisableReason:
              item.guid === installation.guid ? disableReason : item.lastDisableReason,
          }))
        );
        configNotification(
          "warning",
          "check",
          "Notificación",
          enable
            ? `Se habilitó la instalación '${installation.name}' exitosamente.`
            : `Se deshabilitó la instalación '${installation.name}' exitosamente.`,
          "",
          true
        );
      } else {
        const errorMessage = {
          code: apiResponse.status,
          message: `Error: ${apiResponse.status}`,
        };
        throw errorMessage;
      }
    } catch (e) {
      configNotification(
        "error",
        "warning",
        "Notificación",
        enable
          ? `Ocurrió un error al habilitar la instalación '${e.message}'.`
          : `Ocurrió un error al deshabilitar la instalación '${e.message}'.`,
        "",
        true
      );
    }
  };
  const handleCancelDisable = () => {
    setShowConfirmDisable(false);
  };
  const handleConfirmDisable = () => {
    setEnabled(installationToDisable, true);
    setShowConfirmDisable(false);
  };

  const [showConfirmEnableTest, setShowConfirmEnableTest] = useState(false);
  const [installationToEnableTest, setInstallationToEnableTest] = useState({});
  const setTestEnabled = async (installation, confirmed) => {
    if (!confirmed) {
      setInstallationToEnableTest(installation);
      setShowConfirmEnableTest(true);
      return;
    }

    const enable = !installation.testEnabled;
    try {
      const apiResponse = await APIRequest("POST", "/Installation/SetTestEnabled", {
        installationGuid: installation.guid,
        enableTest: enable,
      });

      if (apiResponse.status === 200) {
        setJsonData(
          jsonData.map((item) => ({
            ...item,
            testEnabled: item.guid === installation.guid ? enable : item.testEnabled,
          }))
        );
        configNotification(
          "warning",
          "check",
          "Notificación",
          enable
            ? `Se habilitó el ambiente de pruebas para la instalación '${installation.name}' exitosamente.`
            : `Se deshabilitó el ambiente de pruebas para la instalación '${installation.name}' exitosamente.`,
          "",
          true
        );
      } else {
        const errorMessage = {
          code: apiResponse.status,
          message: `Error: ${apiResponse.status}`,
        };
        throw errorMessage;
      }
    } catch (e) {
      configNotification(
        "error",
        "warning",
        "Notificación",
        enable
          ? `Ocurrió un error al habilitar el ambiente de pruebas para la instalación '${e.message}'.`
          : `Ocurrió un error al deshabilitar el ambiente de pruebas para la instalación '${e.message}'.`,
        "",
        true
      );
    }
  };
  const handleCancelEnableTest = () => {
    setShowConfirmEnableTest(false);
  };
  const handleConfirmEnableTest = () => {
    setTestEnabled(installationToEnableTest, true);
    setShowConfirmEnableTest(false);
  };

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [installationIdToDelete, setInstallationIdToDelete] = useState("");
  const deleteJsonData = async (id, confirmed) => {
    if (!confirmed) {
      setInstallationIdToDelete(id);
      setShowConfirmDelete(true);
      return;
    }

    try {
      const apiResponse = await APIRequest("DELETE", `/Installation/Delete?id=${id}`, null);

      if (apiResponse.status === 200) {
        const index = jsonData.map((item) => item.id).indexOf(id);
        const elements = [...jsonData];
        const nombreElemento = elements[index].name;
        elements.splice(index, 1);
        setJsonData(elements);
        configNotification(
          "warning",
          "check",
          "Notificación",
          `Se eliminó la instalación '${nombreElemento}' exitosamente.`,
          "",
          true
        );
      } else {
        const errorMessage = {
          code: apiResponse.status,
          message: `Error: ${apiResponse.status}`,
        };
        throw errorMessage;
      }
    } catch (e) {
      configNotification(
        "error",
        "warning",
        "Notificación",
        `Ocurrió un error al eliminar la instalación: ${e.message}`,
        "",
        true
      );
    }
  };
  const handleCancelDelete = () => {
    setShowConfirmDelete(false);
  };
  const handleConfirmDelete = () => {
    deleteJsonData(installationIdToDelete, true);
    setShowConfirmDelete(false);
  };

  const viewDetails = (installation) =>
    navigate("/AdministracionXDoc/Instalaciones/Detalles", { state: { installation, client } });

  useEffect(() => {
    getJsonData();
  }, [pathname]);

  const { columns, rows } = data(jsonData, deleteJsonData, setEnabled, setTestEnabled, viewDetails);

  return (
    <>
      <MDBox style={{ marginLeft: "25px" }}>
        <TextField
          onChange={getJsonDataByTaxId}
          variant="outlined"
          value={taxIdFilter}
          label="Buscar por ID fiscal"
          InputProps={{
            endAdornment: (
              <IconButton
                sx={{ visibility: taxIdFilter ? "visible" : "hidden" }}
                onClick={clearTaxIdFilter}
              >
                <Icon>clear</Icon>
              </IconButton>
            ),
          }}
        />
      </MDBox>
      <MDBox>
        <DataTable
          entriesPerPage={(false, { defaultValue: 10, entries: [5, 10, 25, 50] })}
          canSearch
          showTotalEntries
          table={{ columns, rows }}
          pagination={{ variant: "contained", color: "warning" }}
          isSorted
          noEndBorder
        />
      </MDBox>
      {renderNotificacion}
      <Dialog
        open={showConfirmDelete}
        onClose={handleCancelDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">¿Quiere eliminar la instalación?</DialogTitle>
        <DialogActions>
          <Button onClick={handleCancelDelete}>Cancelar</Button>
          <Button onClick={handleConfirmDelete} autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showConfirmDisable}
        onClose={handleCancelDisable}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">¿Quiere deshabilitar la instalación?</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Motivo (opcional)"
            fullWidth
            variant="standard"
            onChange={(e) => setDisableReason(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDisable}>Cancelar</Button>
          <Button onClick={handleConfirmDisable} autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showConfirmEnableTest}
        onClose={handleCancelEnableTest}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {installationToEnableTest.testEnabled
            ? "¿Quiere deshabilitar el ambiente de pruebas para la instalación?"
            : "¿Quiere habilitar el ambiente de pruebas para la instalación?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleCancelEnableTest}>Cancelar</Button>
          <Button onClick={handleConfirmEnableTest} autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Installations;
