/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// @mui material components
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Loading from "components/Loading";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// API Request
import APIRequest from "api/request";
import APIRequestGeneric from "api/requestGeneric";

function ApiKeyPRDForm() {
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  const host = process.env.REACT_APP_API_URL;
  const basicAuth = `${process.env.REACT_APP_API_ENEX_USER}:${process.env.REACT_APP_API_ENEX_PASSWORD}`;
  const basicAuthorization = `Basic ${btoa(basicAuth)}`;
  const idFuncionalidad = 14;

  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  const [load, setLoad] = useState(false);
  // const [jsonData, setJsonData] = useState([]);
  const { pathname } = useLocation();
  const {
    state: { route, element },
  } = useLocation();
  const navigate = useNavigate();

  const [elementIsNew, setElementIsNew] = useState(true);
  const [newValueId, setNewValueId] = useState("");
  const [newValuePais, setNewValuePais] = useState(null);
  const [newValueTaxId, setNewValueTaxId] = useState("");
  const [newValueAutenticacion, setNewValueAutenticacion] = useState(null);
  const [selectData, setSelectData] = useState({
    autenticacion: [
      // { id: 0, nombre: "---" },
      { id: 1, nombre: "Basic Authentication" },
      { id: 2, nombre: "OAuth 2.0" },
    ],
    pais: [
      { id: "ar", nombre: "Argentina" },
      { id: "gt", nombre: "Guatemala" },
    ],
  });

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };
  const showNotificationError = (message) => {
    configNotification("error", "warning", "Notificación", message, "", true);
  };
  const showNotificationSuccess = (message) => {
    configNotification("warning", "check", "Notificación", message, "", true);
  };

  const getIdApiKey = async (usuarioApiKey) => {
    const body = {
      TaxId: newValueTaxId,
      Country: newValuePais.id,
      User: userInfo.correo,
    };
    const urlComplete = `${host}/api/AccountSettings/GetApiKeysByAccount`;
    const apiResponse = await (
      await APIRequestGeneric("POST", urlComplete, body, false, basicAuthorization)
    ).json();

    if (apiResponse.Code === 200 && apiResponse.IsSuccessful) {
      const responseData = JSON.parse(apiResponse.Data);

      for (let index = 0; index < responseData.length; index += 1) {
        if (responseData[index].ApiUser === usuarioApiKey) {
          return responseData[index].id;
        }
      }
    }

    return null;
  };
  const registerUser = async (vLabel, vValue, vAccion) => {
    const bodyUsuario = {
      Usuario: userInfo.correo,
      FuncionalidadId: idFuncionalidad,
      Label: vLabel,
      Value: vValue,
      Accion: vAccion,
      Data: "{}",
    };

    await (await APIRequest("POST", "/Api/Historial", bodyUsuario)).json();
  };
  const saveDataModal = async () => {
    setLoad(true);
    const urlComplete = `${host}/api/AccountSettings/${
      elementIsNew ? "CreateApiKey" : "UpdateApiKey"
    }`;
    let body;
    if (elementIsNew) {
      body = {
        TaxId: newValueTaxId,
        Country: newValuePais.id, // Sólo cuando es nuevo
        User: userInfo.correo,
        Method: newValueAutenticacion.id - 1,
      };
    } else {
      body = {
        Id: newValueId, // *****          Obligatorio
        // TaxId: newValueTaxId, // *****          Opcional
        Method: newValueAutenticacion.id - 1, //          Opcional
        // TaxIds: newValueTaxIds, // *****          Opcional
        // Block: true, // *****          Opcional
        // Comentario: "", // *****          Opcional
        // PasswordApiKey: "", // *****          Opcional
        User: userInfo.correo, //       Obligatorio
      };
    }

    try {
      const apiResponse = await (
        await APIRequestGeneric("POST", urlComplete, body, false, basicAuthorization)
      ).json();

      if (apiResponse.Code === 200 && apiResponse.IsSuccessful) {
        /* const tempJsonData = jsonData;
        const today = new Date();
        const fechaActual = `${today.getFullYear()}-${(today.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${today.getDate().toString().padStart(2, "0")}`; */

        if (elementIsNew) {
          const usuarioApiKey = apiResponse.Data.split(":")[1]
            .trim()
            .padStart(36, "0")
            .substring(0, 36);
          // const passwordApiKey = apiResponse.Data.split(":")[2].trim().replace('"', "");
          const idApiKey = await getIdApiKey(usuarioApiKey);

          if (idApiKey !== null) {
            registerUser("id", idApiKey, 1);

            /* const newTempElement = {
              id: idApiKey,
              taxId: newValueTaxId,
              fecha: fechaActual,
              autenticacion: newValueAutenticacion,
              usuario: usuarioApiKey,
              password: passwordApiKey,
              verPassword: false,
              ip: "",
              usado: "",
              usuarioCrea: `${userInfo.correo} ${fechaActual}`,
              ultimaAccion: `${userInfo.correo} ${fechaActual}`,
              comentario: "",
              taxIds: [],
              bloqueado: false,
              componente: null,
            };

            tempJsonData.push(newTempElement); */
          } else {
            // getJsonData();
          }
        } else {
          /* const index = tempJsonData.map((item) => item.id).indexOf(newValueId);
          tempJsonData[index].taxId = newValueTaxId;
          tempJsonData[index].autenticacion = newValueAutenticacion;
          tempJsonData[index].ultimaAccion = `${userInfo.correo} ${fechaActual}`; */

          registerUser("idU", newValueId, 2);
          // setJsonData(tempJsonData);
        }
        showNotificationSuccess("Se completó el registro exitosamente");
      } else {
        showNotificationError(`Error: ${apiResponse.Data}`);
      }
    } catch {
      showNotificationError("Ocurrió un error inesperado.");
    }

    setLoad(false);
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );

  const setEditJsonData = (jsonElement) => {
    if (element) {
      setNewValueId(jsonElement.id);
      setNewValueTaxId(jsonElement.taxId);
      setNewValueAutenticacion(jsonElement.autenticacion);
    }
  };

  const getCountriesData = async () => {
    const tempJsonData = [];
    const apiResponse = await (await APIRequest("GET", "/api/Pais/Todos", null)).json();

    for (let index = 0; index < apiResponse.length; index += 1) {
      tempJsonData.push({
        id: apiResponse[index].codigo.toLowerCase(),
        nombre: apiResponse[index].nombre,
      });
    }

    setSelectData({
      pais: tempJsonData,
      autenticacion: selectData.autenticacion,
    });

    return tempJsonData;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    saveDataModal();
  };

  useEffect(async () => {
    try {
      setLoad(true);
      setElementIsNew(!element);
      setEditJsonData(element);
      await getCountriesData();
      // eslint-disable-next-line no-empty
    } catch {}

    setLoad(false);
  }, [pathname]);

  return (
    <DashboardLayout>
      <DashboardNavbar breadCrumb={route.split("/").slice(1)} />
      <MDBox pt={3} pb={3} component="form" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MDBox pb={4}>
              <MDTypography variant="subtitle" fontWeight="bold">
                <Icon
                  sx={{ cursor: "pointer", fontWeight: "bold" }}
                  onClick={() => navigate(route)}
                >
                  arrow_back
                </Icon>
                {"   "}
                {element ? "Editar" : "Crear"} ApiKey
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox sx={{ position: "relative", mx: 3, py: 2, px: 2 }}>
                <Grid container spacing={3} justifyContent="left" alignItems="left" height="100%">
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <MDInput
                      variant="standard"
                      type="text"
                      label="Tax ID"
                      fullWidth
                      required
                      InputLabelProps={{ shrink: true }}
                      value={newValueTaxId}
                      onChange={(e) => {
                        setNewValueTaxId(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <Autocomplete
                      value={newValuePais}
                      onChange={async (event, newValue) => {
                        setNewValuePais(newValue);
                      }}
                      options={selectData.pais}
                      getOptionLabel={(option) => option.nombre}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      sx={{ display: elementIsNew ? "flex" : "none" }}
                      renderInput={(params) => (
                        <MDInput
                          variant="standard"
                          type="text"
                          label="Pais"
                          fullWidth
                          required={elementIsNew}
                          InputLabelProps={{ shrink: true }}
                          {...params}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <Autocomplete
                      value={newValueAutenticacion}
                      onChange={(event, newValue) => {
                        setNewValueAutenticacion(newValue);
                      }}
                      options={selectData.autenticacion}
                      getOptionLabel={(option) => option.nombre}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderInput={(params) => (
                        <MDInput
                          variant="standard"
                          type="text"
                          label="Método Autenticación"
                          fullWidth
                          required
                          InputLabelProps={{ shrink: true }}
                          {...params}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="left" alignItems="left" height="100%">
              <Grid item xs={12} sm={12} md={6} lg={4} xl={10} />
              <Grid item xs={12} sm={12} md={6} lg={4} xl={2} container justifyContent="flex-end">
                <MDButton
                  variant="contained"
                  type="submit"
                  sx={{
                    background: "linear-gradient(45deg, #099268 30%, #099268 90%)",
                    color: "#FFFFFF",
                  }}
                >
                  <Icon>save</Icon>&nbsp;Guardar
                </MDButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
      {renderNotificacion}
      <Loading load={load} />
      <Footer />
    </DashboardLayout>
  );
}

export default ApiKeyPRDForm;
