/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDButton from "components/MDButton";
import Loading from "components/Loading";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent, { timelineContentClasses } from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";

// API Request
import APIRequestGeneric from "api/requestGeneric";

function XMLHistoricosLog() {
  const host = process.env.REACT_APP_API_URL;
  // const host = "https://apihistoricaldocssbx.blackhill-79102964.centralus.azurecontainerapps.io";
  const basicAuth = `${process.env.REACT_APP_API_ENEX_USER}:${process.env.REACT_APP_API_ENEX_PASSWORD}`;
  const basicAuthorization = `Basic ${btoa(basicAuth)}`;

  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  const [load, setLoad] = useState(false);
  const [jsonData, setJsonData] = useState({ months: [], status: 0 });
  const [jsonDataMonth, setJsonDataMonth] = useState({ days: [], expand: false });
  const { pathname } = useLocation();
  const {
    state: { route, element },
  } = useLocation();
  const navigate = useNavigate();

  // eslint-disable-next-line no-unused-vars
  const [elementIsNew, setElementIsNew] = useState(true);

  // eslint-disable-next-line no-unused-vars
  const [selectData, setSelectData] = useState({
    errorRequest: [
      { id: 0, nombre: "En espera" },
      { id: 1, nombre: "En proceso" },
      { id: 2, nombre: "Completado" },
      { id: 3, nombre: "Error" },
      { id: 4, nombre: "Consolidado" },
    ],
    errorMonth: [
      { id: 0, nombre: "En espera" },
      { id: 1, nombre: "En proceso" },
      { id: 2, nombre: "Completado" },
      { id: 3, nombre: "Error" },
      { id: 4, nombre: "Completado con error" },
    ],
  });

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };
  // eslint-disable-next-line no-unused-vars
  const showNotificationError = (message) => {
    configNotification("error", "warning", "Notificación", message, "", true);
  };
  // eslint-disable-next-line no-unused-vars
  const showNotificationSuccess = (message) => {
    configNotification("warning", "check", "Notificación", message, "", true);
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );

  const getJsonData = async () => {
    // const urlComplete = `https://apihistoricaldocssbx.blackhill-79102964.centralus.azurecontainerapps.io/api/RequestBackup/RequestBackupTracking?id=4745e90d-e72c-4318-91ff-5a31c08d8b05&country=cl&taxId=77586860-0&includeDays=true`;
    const urlComplete = `${host}/api/RequestBackup/RequestBackupTracking?id=${element.id}&country=${element.pais.id}&taxId=${element.taxId}&includeDays=false`;
    const apiResponse = await (
      await APIRequestGeneric("GET", urlComplete, null, false, basicAuthorization)
    ).json();

    const tempJsonData = {
      requestId: apiResponse.data.requestId,
      months: [],
      status:
        selectData.errorRequest[
          selectData.errorRequest.map((item) => item.id).indexOf(apiResponse.data.status)
        ],
      errors: apiResponse.data.errors,
    };

    for (let index = 0; index < apiResponse.data.months.length; index += 1) {
      tempJsonData.months.push({
        id: apiResponse.data.months[index].id,
        dateRange: apiResponse.data.months[index].dateRange,
        errorDayIds: apiResponse.data.months[index].errorDayIds,
        days: apiResponse.data.months[index].days,
        status:
          selectData.errorMonth[
            selectData.errorMonth
              .map((item) => item.id)
              .indexOf(apiResponse.data.months[index].status)
          ],
        count: apiResponse.data.months[index].count ? apiResponse.data.months[index].count : 0,
        expand: false,
        index,
      });
    }

    setJsonData(tempJsonData);
  };

  const getDetalleMesData = async (item) => {
    const tempJsonData = jsonData;

    tempJsonData.months[item.index].expand = !tempJsonData.months[item.index].expand;

    setJsonData({
      requestId: tempJsonData.requestId,
      months: [...tempJsonData.months],
      status: tempJsonData.status,
      errors: tempJsonData.errors,
    });

    if (tempJsonData.months[item.index].expand) {
      // const urlComplete = `https://apihistoricaldocssbx.blackhill-79102964.centralus.azurecontainerapps.io/api/RequestBackup/RequestBackupMonthTracking?id=4745e90d-e72c-4318-91ff-5a31c08d8b05&country=cl&taxId=77586860-0&monthId=e8ecb9da-18e0-4c42-865d-e9201134d416`;
      const urlComplete = `${host}/api/RequestBackup/RequestBackupMonthTracking?id=${element.id}&country=${element.pais.id}&taxId=${element.taxId}&monthId=${item.id}`;
      const apiResponse = await (
        await APIRequestGeneric("GET", urlComplete, null, false, basicAuthorization)
      ).json();

      apiResponse.data.status = tempJsonData.months[item.index].status;
      apiResponse.data.expand = true;

      setJsonDataMonth(apiResponse.data);
    } else {
      setJsonDataMonth({ days: [], expand: false });
    }
  };

  const getStatusDay = (id) => {
    switch (id) {
      case 0:
        return "En espera";
      case 1:
        return "En proceso";
      case 2:
        return "Completado";
      case 3:
        return "Error";
      case 4:
        return "Completado con error";
      default:
        return "default";
    }
  };

  useEffect(async () => {
    setLoad(true);
    try {
      await getJsonData();
      setElementIsNew(!element);
      // setEditJsonData(element);
    } catch {
      showNotificationError("Ocurrió un error inesperado al buscar la información.");
    }
    setLoad(false);
  }, [pathname]);

  return (
    <DashboardLayout>
      <DashboardNavbar breadCrumb={route.split("/").slice(1)} />
      <MDBox pt={3} pb={3} component="form">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MDBox pb={4}>
              <MDTypography variant="subtitle" fontWeight="bold">
                <Icon
                  sx={{ cursor: "pointer", fontWeight: "bold" }}
                  onClick={() => navigate(route)}
                >
                  arrow_back
                </Icon>
                {"   "}
                Se permite crear respaldo de XML históricos emitidos por clientes.
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox sx={{ position: "relative", mx: 3, py: 2, px: 2 }}>
                <Grid container spacing={3} justifyContent="left" alignItems="left" height="100%">
                  <Grid item xs={6}>
                    <Timeline
                      position="left"
                      sx={{
                        [`& .${timelineContentClasses.root}`]: {
                          flex: 15,
                        },
                      }}
                    >
                      {jsonData.months.map((item) => (
                        <TimelineItem key={item.id}>
                          <TimelineOppositeContent
                            sx={{ m: "auto 0" }}
                            align="right"
                            variant="body2"
                            color="text.secondary"
                          >
                            {item.expand ? "Ocultar Detalle" : "Ver Detalle"}
                          </TimelineOppositeContent>
                          <TimelineSeparator>
                            <TimelineConnector />
                            <TimelineDot>
                              <Tooltip
                                title={item.expand ? "Ocultar Detalle" : "Ver Detalle"}
                                disableInteractive
                                onClick={() => {
                                  getDetalleMesData(item);
                                }}
                              >
                                <Icon>pix</Icon>
                              </Tooltip>{" "}
                              {/* <Icon sx={{ cursor: "pointer", fontWeight: "bold" }}>pix</Icon> */}
                            </TimelineDot>
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent sx={{ py: "12px", px: 2 }}>
                            <Typography variant="h6" component="span">
                              {item.dateRange}
                            </Typography>
                            <MDBox>
                              <Typography variant="body2">Estado: {item.status.nombre}</Typography>
                            </MDBox>
                            <MDBox>
                              <MDTypography variant="body2">Documentos: {item.count}</MDTypography>
                            </MDBox>
                            <MDBox>
                              <MDTypography variant="body2">
                                Días con error: {item.errorDayIds ? item.errorDayIds.length : 0}
                              </MDTypography>
                            </MDBox>
                          </TimelineContent>
                        </TimelineItem>
                      ))}
                    </Timeline>
                  </Grid>
                  <Grid item xs={6}>
                    <MDBox sx={{ display: jsonDataMonth.expand ? "inherit" : "none" }}>
                      <Typography variant="h6" component="span">
                        {jsonDataMonth.dateRange}
                      </Typography>
                      <br />
                      <Timeline>
                        {jsonDataMonth.days.map((item) => (
                          <TimelineItem key={item.id}>
                            <TimelineOppositeContent
                              sx={{ m: "auto 0" }}
                              align="right"
                              variant="body2"
                              color="text.secondary"
                            >
                              <Typography variant="body2">
                                Estado: {getStatusDay(item.status)}
                              </Typography>
                              <Typography variant="body2">Documentos: {item.count}</Typography>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                              <TimelineConnector />
                              <TimelineDot>
                                <Tooltip disableInteractive>
                                  <Icon>chevron_right</Icon>
                                </Tooltip>{" "}
                              </TimelineDot>
                              <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: "12px", px: 2 }}>
                              <Typography variant="h6" component="span">
                                {item.date}
                              </Typography>
                            </TimelineContent>
                          </TimelineItem>
                        ))}
                      </Timeline>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="left" alignItems="left" height="100%">
              <Grid item xs={12} sm={12} md={6} lg={9} xl={10} />
              <Grid item xs={12} sm={12} md={6} lg={3} xl={2} container justifyContent="flex-end">
                <MDButton
                  variant="contained"
                  onClick={() => navigate(route)}
                  sx={{
                    background: "linear-gradient(45deg, #099268 30%, #099268 90%)",
                    color: "#FFFFFF",
                  }}
                >
                  <Icon>arrow_back</Icon>&nbsp;Regresar
                </MDButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
      {renderNotificacion}
      <Loading load={load} />
      <Footer />
    </DashboardLayout>
  );
}

export default XMLHistoricosLog;
