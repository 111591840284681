/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDButton from "components/MDButton";
import Loading from "components/Loading";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import data from "views/soporte/XDocGlobal_CargaXSLTPRD/data";

import base64ToArrayBuffer from "util/base64ToArrayBuffer";

// API Request
import APIRequest from "api/request";

function XDocGlobalCargaXSLT() {
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  const [jsonData, setJsonData] = useState([]);
  const [load, setLoad] = useState(false);
  const { pathname } = useLocation();
  const route = pathname;
  const navigate = useNavigate();

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };
  const showNotificationError = (message) => {
    configNotification("error", "warning", "Notificación", message, "", true);
  };
  const showNotificationSuccess = (message) => {
    configNotification("warning", "check", "Notificación", message, "", true);
  };

  const descargarPLantilla = async (id) => {
    try {
      const index = jsonData.map((item) => item.id).indexOf(id);
      const nameFile = jsonData[index].xslt; // "48de1c6e-e3be-40d6-8ccc-d058f1eed5cb";
      const body = {
        User: userInfo.correo,
        CountryId: jsonData[index].pais.id,
        NameMapping: nameFile,
      };
      const apiResponse = await APIRequest("POST", "/api/xslt/GetXsltByCountry", body);

      if (!apiResponse.ok) {
        const errorMessage = {
          code: apiResponse.status,
          message: "La solicitud no pudo completarse con éxito",
        };
        throw errorMessage;
      }

      const jsonResponse = await apiResponse.json();
      const arrBuffer = base64ToArrayBuffer(jsonResponse.values);
      const blob = new Blob([arrBuffer], { type: "application/xml" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${nameFile}.xslt`;
      document.body.appendChild(a);
      a.click();

      return true;
    } catch (exception) {
      showNotificationError(exception.message);
      return false;
    }
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );

  const getJsonData = async (countries) => {
    const tempJsonData = [];
    const responseApi = await (await APIRequest("GET", "/api/xslt/Todos", null)).json();

    for (let index = 0; index < responseApi.length; index += 1) {
      tempJsonData.push({
        id: responseApi[index].id,
        pais: countries[
          countries.map((item) => item.id).indexOf(responseApi[index].pais.toLowerCase())
        ],
        xslt: responseApi[index].xslt,
        usuario: responseApi[index].usuario,
        fechaRegistro: responseApi[index].fechaRegistro.toString().substring(0, 10),
        tipo: responseApi[index].tipo.length === 0 ? null : responseApi[index].tipo,
      });
    }

    setJsonData(tempJsonData);
  };

  const deleteJsonData = async (id) => {
    if (window.confirm("¿Quiere eliminar el registro?")) {
      try {
        const apiResponse = await APIRequest("DELETE", `/Api/xslt/${id}`, null);

        if (apiResponse.status === 200) {
          const index = jsonData.map((item) => item.id).indexOf(id);
          const elements = [...jsonData];
          const nombreElemento = elements[index].xslt;
          elements.splice(index, 1);
          setJsonData(elements);

          showNotificationSuccess(`Se eliminó el registro '${nombreElemento}' exitosamente.`);
        } else {
          const errorMessage = {
            code: apiResponse.status,
            message: `Error: ${apiResponse.status}`,
          };
          throw errorMessage;
        }
      } catch (e) {
        showNotificationError(`Ocurrió un error eliminar el registro: ${e.message}`);
      }
    }
  };

  const setEditJsonData = (element) => {
    navigate("/Inbox-CargaXSLT/ProduccionForm", { state: { route, element } });
  };

  const getCountriesData = async () => {
    const tempJsonData = [];
    const urlComplete = `/api/Pais/GetXsltCountries/${userInfo.correo}`;
    const apiResponse = await (await APIRequest("GET", urlComplete, null)).json();

    for (let index = 0; index < apiResponse.length; index += 1) {
      tempJsonData.push({
        id: apiResponse[index].codigo.toLowerCase(),
        nombre: apiResponse[index].nombre,
      });
    }

    return tempJsonData;
  };

  useEffect(async () => {
    setLoad(true);
    try {
      const tempCountries = await getCountriesData();
      await getJsonData(tempCountries);
    } catch {
      // Error
    }
    setLoad(false);
  }, [pathname]);

  const { columns, rows } = data(jsonData, deleteJsonData, setEditJsonData, descargarPLantilla);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MDTypography variant="subtitle" fontWeight="bold">
              Permite cargar XSLT que será utilizado para la transformación
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="left" alignItems="left" height="100%">
              <Grid item xs={12} sm={12} md={6} lg={4} xl={10} />
              <Grid item xs={12} sm={12} md={6} lg={4} xl={2} container justifyContent="flex-end">
                <MDButton
                  variant="contained"
                  onClick={() => navigate("/Inbox-CargaXSLT/ProduccionForm", { state: { route } })}
                  sx={{
                    background: "linear-gradient(45deg, #1971C2 30%, #1971C2 90%)",
                    color: "#FFFFFF",
                  }}
                >
                  <Icon>add_circle_outline</Icon>&nbsp;Agregar
                </MDButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox>
                <DataTable
                  entriesPerPage={(false, { defaultValue: 10, entries: [5, 10, 25, 50] })}
                  canSearch
                  showTotalEntries
                  table={{ columns, rows }}
                  pagination={{ variant: "contained", color: "warning" }}
                  isSorted
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {renderNotificacion}
      <Loading load={load} />
      <Footer />
    </DashboardLayout>
  );
}

export default XDocGlobalCargaXSLT;
