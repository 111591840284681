/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import PropTypes from "prop-types";

// react-router components
import { Link, useLocation } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ArrowBack from "@mui/icons-material/ArrowBack";

// Data
import data from "./data";

// Material Dashboard 2 React example components
import DashboardLayout from "../../../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../../../examples/Navbars/DashboardNavbar";
import Footer from "../../../../../examples/Footer";
import NewDataTable from "../../../../../examples/Tables/NewDataTable";

// Material Dashboard 2 React components
import MDBox from "../../../../../components/MDBox";
import MDTypography from "../../../../../components/MDTypography";

// API
import xPosApiRequest from "../../../../../api/xPosApiRequest";
import useNotification from "../../_hooks/useNotification";
import Notification from "../../_components/shared/Notification";
import { CountryCodesToNames } from "../../_constants/general";
import { isOk } from "../../_helpers/requestHelpers";

function LogsHistory({ country }) {
  const location = useLocation();
  const { log } = location.state || {};
  const { notification, showNotificationError, showNotificationSuccess, setNotification } =
    useNotification();

  const [jsonData, setJsonData] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    setJsonData(log.logs);
  }, [pathname]);

  const handleDownload = async (arrayIds) => {
    const options = {
      method: "POST",
      url: "/logs/download",
      headers: {
        "Content-Type": "application/json",
      },
      responseType: "blob",
      data: arrayIds,
    };

    try {
      const response = await xPosApiRequest(options);
      if (isOk(response.status)) {
        const blob = new Blob([response.data], { type: "application/zip" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "logs.zip");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        showNotificationSuccess(
          `${
            arrayIds.length > 1 ? "Archivos descargados" : "Archivo descargado"
          } satisfactoriamente.`
        );
      } else {
        showNotificationError(`Error al descargar archivo.`);
      }
    } catch (error) {
      showNotificationError(`Error al descargar archivo.`);
    }
  };

  const { columns, rows } = data(jsonData, handleDownload);

  const countryName = CountryCodesToNames[country];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid display="flex" alignItems="center">
        <Tooltip title="Regresar" style={{ paddingLeft: 0 }} disableInteractive>
          <IconButton
            style={{ paddingLeft: 0 }}
            disableRipple
            component={Link}
            to={`/xPos/9${countryName}/9Logs`}
          >
            <ArrowBack style={{ cursor: "pointer", color: "black" }} />
          </IconButton>
        </Tooltip>
        <MDTypography fontWeight="bold" variant="h5" color="dark" noWrap>
          Historial
        </MDTypography>
      </Grid>
      <MDBox py={2}>
        <MDTypography fontWeight="bold" variant="h6" color="dark" noWrap>
          Nombre xPOS: {log.xPosName}
        </MDTypography>
        <MDTypography fontWeight="bold" variant="h6" color="dark" noWrap>
          ID xPOS: {log.xPosGuid}
        </MDTypography>
        <MDBox pt={2} pb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card>
                <MDBox>
                  <NewDataTable
                    entriesPerPage={(false, { defaultValue: 10 })}
                    canSearch
                    checkbox
                    tableType="logs"
                    showTotalEntries
                    downloadFile={handleDownload}
                    loadingTable={false}
                    table={{ columns, rows }}
                    pagination={{ variant: "contained", color: "warning" }}
                    isSorted
                  />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Notification notification={notification} setNotification={setNotification} />
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
LogsHistory.propTypes = {
  country: PropTypes.string.isRequired,
};

export default LogsHistory;
