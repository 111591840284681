/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

// @mui material components
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import BKModal from "components/BKModal";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDInput from "components/MDInput";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import data from "views/admin/profileUsers/data";

// API Request
import APIRequest from "api/request";

function ProfileUser() {
  const perfilSelected = JSON.parse(sessionStorage.getItem("idPerfilSelected"));

  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  const [menu, setMenu] = useState(null);
  const [opcionAsignar, setOpcionAsignar] = useState(true);
  const [modal, setModal] = useState(false);
  const [jsonData, setJsonData] = useState([]);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [newUser, setNewUser] = useState(null);
  const [newValueNoMaxPerfilesAdmin, setNewValueNoMaxPerfilesAdmin] = useState(0);
  const [newValueNoMaxPerfilesNormales, setNewValueNoMaxPerfilesNormales] = useState(0);
  const [selectData, setSelectData] = useState({
    user: [],
  });

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };
  const showNotificationError = (message) => {
    configNotification("error", "warning", "Notificación", message, "", true);
  };
  const showNotificationSuccess = (message) => {
    configNotification("warning", "check", "Notificación", message, "", true);
  };

  const validarOpcionAsignar = (array, NoMaxPerfilesAdmin, NoMaxPerfilesNormales) => {
    if (array.length >= NoMaxPerfilesAdmin && perfilSelected.tipoPerfil.id === 2) {
      // 2:Administrador
      setOpcionAsignar(false);
    } else if (array.length >= NoMaxPerfilesNormales && perfilSelected.tipoPerfil.id === 3) {
      // 3:Perfil Normal
      setOpcionAsignar(false);
    } else {
      setOpcionAsignar(true);
    }
  };

  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);
  const openAddNewElementModal = () => {
    setMenu(null);
    setModal(true);
  };
  const closeModal = () => {
    setModal(false);
    setNewUser(null);
  };
  const saveDataModal = async () => {
    const tempJsonData = jsonData;
    const body = {
      usuarioId: newUser.id,
      perfilId: perfilSelected.id,
    };

    try {
      const apiResponse = await APIRequest("POST", "/Api/Perfil/AsignarUsuario", body);

      if (apiResponse.status === 200) {
        tempJsonData.push(newUser);

        showNotificationSuccess(`Se asignó el usuario '${newUser.nombre}' exitosamente.`);
      } else {
        const errorMessage = {
          code: apiResponse.status,
          message: `Error: ${apiResponse.status}`,
        };
        throw errorMessage;
      }

      setJsonData(tempJsonData);
      validarOpcionAsignar(tempJsonData, newValueNoMaxPerfilesAdmin, newValueNoMaxPerfilesNormales);
    } catch (e) {
      showNotificationError(`Ocurrió un error al asignar el usuario: ${e.message}`);
    }
    closeModal();
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={openAddNewElementModal} sx={{ display: opcionAsignar ? "flex" : "none" }}>
        Agregar Usuario
      </MenuItem>
    </Menu>
  );

  const renderModal = (
    <BKModal
      closeModal={closeModal}
      saveChanges={saveDataModal}
      open={modal}
      title="Agregar Usuario"
    >
      <MDBox sx={{ position: "relative", mx: 3, py: 2, px: 2 }}>
        <Grid container spacing={3} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Autocomplete
              value={newUser}
              onChange={(event, newValue) => {
                setNewUser(newValue);
              }}
              options={selectData.user}
              getOptionLabel={(option) => option.correo}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <MDInput
                  variant="standard"
                  type="text"
                  label="Usuario"
                  fullWidth
                  required
                  InputLabelProps={{ shrink: true }}
                  {...params}
                />
              )}
            />
          </Grid>
        </Grid>
      </MDBox>
    </BKModal>
  );

  const getJsonData = async () => {
    const body = { perfilId: perfilSelected.id };
    const apiResponse = await (await APIRequest("POST", "/Api/Usuario/Todos", body)).json();

    setJsonData(apiResponse);

    const configResponse = await (await APIRequest("GET", "/Api/ConfigArea/1", null)).json();

    setNewValueNoMaxPerfilesAdmin(configResponse.numeroMaximoPerfilesAdmin);
    setNewValueNoMaxPerfilesNormales(configResponse.numeroMaximoPerfilesNormales);

    validarOpcionAsignar(
      apiResponse,
      configResponse.numeroMaximoPerfilesAdmin,
      configResponse.numeroMaximoPerfilesNormales
    );
  };

  const deleteJsonData = async (id) => {
    if (window.confirm("¿Quiere eliminar el usuario?")) {
      try {
        const body = {
          usuarioId: id,
          perfilId: perfilSelected.id,
        };

        const apiResponse = await APIRequest("DELETE", "/Api/Perfil/DesasignarUsuario", body);

        if (apiResponse.status === 200) {
          const index = jsonData.map((item) => item.id).indexOf(id);
          const elements = [...jsonData];
          const nombreElemento = elements[index].nombre;
          elements.splice(index, 1);
          setJsonData(elements);
          validarOpcionAsignar(elements, newValueNoMaxPerfilesAdmin, newValueNoMaxPerfilesNormales);
          showNotificationSuccess(`Se desasignó el usuario '${nombreElemento}' exitosamente.`);
        } else {
          const errorMessage = {
            code: apiResponse.status,
            message: `Error: ${apiResponse.status}`,
          };
          throw errorMessage;
        }
      } catch (e) {
        showNotificationError(`Ocurrió un error al desasignar el usuario: ${e.message}`);
      }
    }
  };

  const getSelectData = async () => {
    const body = { filtro: {} };
    const apiResponse = await (await APIRequest("POST", "/Api/Usuario/Todos", body)).json();

    setSelectData({
      user: apiResponse,
    });
  };

  useEffect(() => {
    getJsonData();
    getSelectData();
  }, [pathname]);

  const { columns, rows } = data(jsonData, deleteJsonData);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {renderModal}
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                <MDBox>
                  <MDTypography variant="h4" gutterBottom color="warning">
                    <Icon
                      sx={{ cursor: "pointer", fontWeight: "bold" }}
                      onClick={() => navigate("/Administracion/Perfiles")}
                    >
                      arrow_back
                    </Icon>
                    {"   "}
                    Usuarios Asignados Al Perfil {perfilSelected.nombre}
                  </MDTypography>
                </MDBox>
                <MDBox color="text" px={2}>
                  <Icon
                    sx={{ cursor: "pointer", fontWeight: "bold" }}
                    fontSize="small"
                    onClick={openMenu}
                  >
                    more_vert
                  </Icon>
                </MDBox>
                {renderMenu}
              </MDBox>
              <MDBox>
                <DataTable
                  entriesPerPage={(false, { defaultValue: 10, entries: [5, 10, 25, 50] })}
                  canSearch
                  showTotalEntries
                  table={{ columns, rows }}
                  pagination={{ variant: "contained", color: "warning" }}
                  isSorted
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {renderNotificacion}
      <Footer />
    </DashboardLayout>
  );
}

export default ProfileUser;
