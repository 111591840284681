/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// eslint-disable-next-line no-unused-vars
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Loading from "components/Loading";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import base64ToArrayBuffer from "util/base64ToArrayBuffer";
import BKModal from "views/producto/PlantillasJasperCrystal/modalComment";

// API Request
import APIRequest from "api/request";
import APIRequestGeneric from "api/requestGeneric";

function PlantillasJasperCrystalCompare() {
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  const host = process.env.REACT_APP_API_URL;
  const basicAuth = `${process.env.REACT_APP_API_ENEX_USER}:${process.env.REACT_APP_API_ENEX_PASSWORD}`;
  const basicAuthorization = `Basic ${btoa(basicAuth)}`;

  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  const [load, setLoad] = useState(false);
  const [modalComment, setModalComment] = useState(false);
  const { pathname } = useLocation();
  const {
    state: { route, element },
  } = useLocation();
  const navigate = useNavigate();

  const [newValueComment, setNewValueComment] = useState("");
  const [newValueError, setNewValueError] = useState("");

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };
  const showNotificationError = (message) => {
    configNotification("error", "warning", "Notificación", message, "", true);
  };
  const showNotificationSuccess = (message) => {
    configNotification("warning", "check", "Notificación", message, "", true);
  };

  const openCommentModal = () => {
    setModalComment(true);
  };
  const closeCommentModal = () => {
    setModalComment(false);
    setNewValueComment("");
  };

  const saveCompareResult = async (jsonElement) => {
    const bodyPostgre = {
      TemplateId: jsonElement.templateId,
      TaxId: jsonElement.taxId,
      Pais: jsonElement.country.id,
      Aprobado: jsonElement.approved,
      Comentario: jsonElement.comment,
      UsuarioId: userInfo.id,
    };
    const apiResponsePostgre = await APIRequest("POST", "/api/Plantillas", bodyPostgre);

    if (apiResponsePostgre.status === 200) {
      return true;
    }

    const errorMessage = {
      code: apiResponsePostgre.status,
      message: `Error al guardar el resultado de comparación: ${apiResponsePostgre.status}`,
    };
    throw errorMessage;
  };
  const saveData = async (event) => {
    event.preventDefault();

    element.approved = true;
    element.modified = true;

    try {
      let newTemplatePath = "";

      if (element.approved) {
        if (element.templatePath.includes("_preview")) {
          newTemplatePath = element.templatePath;
        } else {
          const initialExtension = `.${
            element.templatePath.split(".")[element.templatePath.split(".").length - 1]
          }`;
          const newExtension = "_preview.jrxml";
          newTemplatePath = element.templatePath.replace(initialExtension, newExtension); // Agregar el preview
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (element.templatePath.includes("_preview")) {
          newTemplatePath = element.templatePath.replace("_preview", ""); // Quitar
        } else {
          // eslint-disable-next-line no-unused-vars
          newTemplatePath = element.templatePath;
        }
        newTemplatePath = newTemplatePath.replace(".rpt", ".jrxml");
      }

      const body = {
        CountryId: element.country.id,
        TaxId: element.taxId,
        CheckPreview: element.approved,
        TemplatePath: newTemplatePath,
      };
      const urlComplete = `${host}/api/ManagerTemplate/RenameTemplatePreview`;
      const apiResponse = await (
        await APIRequestGeneric("POST", urlComplete, body, false, basicAuthorization)
      ).json();

      if (apiResponse.Code === 200 && apiResponse.IsSuccessful) {
        if (await saveCompareResult(element)) {
          showNotificationSuccess("Datos guardados exitosamente");

          const elementChanges = element;
          navigate(route, { state: { elementChanges } });
        }
      } else {
        showNotificationError(`Error al aprobar una plantilla: ${apiResponse.Message}`);
      }
    } catch (error) {
      showNotificationError(`Error inesperado: ${error.message}`);
    }
  };
  const saveDataCommentModal = async () => {
    const oldStatusApproved = element.approved;
    element.approved = false;
    element.comment = newValueComment;
    element.modified = true;

    try {
      if (oldStatusApproved) {
        let newTemplatePath = "";

        if (element.approved) {
          if (element.templatePath.includes("_preview")) {
            newTemplatePath = element.templatePath;
          } else {
            const initialExtension = `.${
              element.templatePath.split(".")[element.templatePath.split(".").length - 1]
            }`;
            const newExtension = "_preview.jrxml";
            newTemplatePath = element.templatePath.replace(initialExtension, newExtension); // Agregar el preview
          }
        } else {
          // eslint-disable-next-line no-lonely-if
          if (element.templatePath.includes("_preview")) {
            newTemplatePath = element.templatePath.replace("_preview", ""); // Quitar
          } else {
            // eslint-disable-next-line no-unused-vars
            newTemplatePath = element.templatePath;
          }
          newTemplatePath = newTemplatePath.replace(".rpt", ".jrxml");
        }

        const body = {
          CountryId: element.country.id,
          TaxId: element.taxId,
          CheckPreview: element.approved,
          TemplatePath: newTemplatePath,
        };
        const urlComplete = `${host}/api/ManagerTemplate/RenameTemplatePreview`;
        const apiResponse = await (
          await APIRequestGeneric("POST", urlComplete, body, false, basicAuthorization)
        ).json();

        if (apiResponse.Code === 200 && apiResponse.IsSuccessful) {
          if (await saveCompareResult(element)) {
            showNotificationSuccess("Rechazado exitosamente y comentarios enviados");

            const elementChanges = element;
            navigate(route, { state: { elementChanges } });
          }
        } else {
          showNotificationError(`Error al aprobar una plantilla: ${apiResponse.Message}`);
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (await saveCompareResult(element)) {
          showNotificationSuccess("Rechazado exitosamente y comentarios enviados");

          const elementChanges = element;
          navigate(route, { state: { elementChanges } });
        }
      }
    } catch (error) {
      showNotificationError(`Error inesperado: ${error.message}`);
    }
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );

  const renderModalComment = (
    <BKModal
      closeModal={closeCommentModal}
      saveChanges={saveDataCommentModal}
      open={modalComment}
      title="Comentarios"
    >
      <MDBox sx={{ position: "relative", mx: 3, py: 2, px: 2 }}>
        <Grid container spacing={3} justifyContent="left" alignItems="left" height="100%">
          <Grid item xs={12}>
            <MDInput
              // variant="standard"
              type="text"
              // label="Comentario"
              fullWidth
              required
              multiline
              rows={5}
              InputLabelProps={{ shrink: true }}
              value={newValueComment}
              onChange={(e) => {
                setNewValueComment(e.target.value);
              }}
            />
          </Grid>
        </Grid>
      </MDBox>
    </BKModal>
  );

  const renderPDFToCompare = async () => {
    setLoad(true);
    try {
      const body = JSON.parse(element.pdfSerializeObjectrequest);
      body.RegeneratePDF = true;

      /* body.XMLStorageCS = null;
      body.XMLStorageContainer = null;
      body.XMLStoragePath = null;
      body.PDFStorageCS = null;
      body.PDFStorageContainer = null;
      body.PDFStoragePath = null;
      body.RPTStorageCS = null;
      body.RPTStorageContainer = null;
      body.RPTStoragePath = null;
      body.RegeneratePDF = true;

      const urlCompleteCrystal = `${host}/api/Render/DowloadPDF?isPartialJson=true`; */
      const urlCompleteCrystal = `${host}/api/Render/DowloadPDF?isPartialJson=false`;
      const apiResponseCrystal = await (
        await APIRequestGeneric("POST", urlCompleteCrystal, body, false, basicAuthorization)
      ).json();
      // console.log("apiResponseCrystal", apiResponseCrystal);

      if (apiResponseCrystal.item1 === "200") {
        const arrBuffer = base64ToArrayBuffer(apiResponseCrystal.item3);
        // const blob = await apiResponseCrystal.blob();
        const blob = new Blob([arrBuffer], { type: "application/pdf" });
        const file = window.URL.createObjectURL(blob);
        // const iframe = document.querySelector("iframeCrystal");
        const iframe = document.getElementById("iframeCrystal");
        if (iframe?.src) iframe.src = file;
      } else if (apiResponseCrystal.item1) {
        const errorMessage = {
          code: apiResponseCrystal.item1,
          message: apiResponseCrystal.item2,
        };
        throw errorMessage;
      } else if (apiResponseCrystal.Code) {
        const errorMessage = {
          code: apiResponseCrystal.Code,
          message: apiResponseCrystal.Message,
        };
        throw errorMessage;
      } else {
        const errorMessage = {
          code: "-500",
          message: "Error inesperado al leer el error en la respuesta",
        };
        throw errorMessage;
      }

      // const urlCompleteJasper = `${host}/api/Render/DowloadPDF?isPartialJson=true&isJasperPreview=true`;
      const urlCompleteJasper = `${host}/api/Render/DowloadPDF?isPartialJson=false&isJasperPreview=true`;
      const apiResponseJasper = await (
        await APIRequestGeneric("POST", urlCompleteJasper, body, false, basicAuthorization)
      ).json();
      // console.log("apiResponseJasper", apiResponseJasper);

      if (apiResponseJasper.item1 === "200") {
        const arrBufferJasper = base64ToArrayBuffer(apiResponseJasper.item3);
        // const blobJasper = await apiResponseJasper.blob();
        const blobJasper = new Blob([arrBufferJasper], { type: "application/pdf" });
        const fileJasper = window.URL.createObjectURL(blobJasper);
        // const iframeJasper = document.querySelector("iframeJasper");
        const iframeJasper = document.getElementById("iframeJasper");
        if (iframeJasper?.src) iframeJasper.src = fileJasper;
      } else if (apiResponseJasper.item1) {
        const errorMessage = {
          code: apiResponseJasper.item1,
          message: apiResponseJasper.item2,
        };
        throw errorMessage;
      } else if (apiResponseJasper.Code) {
        const errorMessage = {
          code: apiResponseJasper.Code,
          message: apiResponseJasper.Message,
        };
        throw errorMessage;
      } else {
        const errorMessage = {
          code: "-500",
          message: "Error inesperado al leer el error en la respuesta",
        };
        throw errorMessage;
      }
    } catch (exception) {
      setNewValueError(exception.message);
      showNotificationError(exception.message);
    }
    setLoad(false);
  };

  useEffect(async () => {
    await renderPDFToCompare();
  }, [pathname]);

  return (
    <DashboardLayout>
      <DashboardNavbar breadCrumb={route.split("/").slice(1)} />
      {renderModalComment}
      <MDBox pt={3} pb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MDBox pb={4}>
              <MDTypography variant="subtitle" fontWeight="bold">
                <Icon
                  sx={{ cursor: "pointer", fontWeight: "bold" }}
                  onClick={() => navigate(route)}
                >
                  arrow_back
                </Icon>
                {"   "}
                Se permite buscar por TaxID y seleccionar entre plantillas Jasper y Crystal
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3} height="100%">
              <Grid item xs={6}>
                <Grid container justifyContent="center" alignItems="center" alignContent="center">
                  <Grid item xs={5} />
                  <Grid item xs={2}>
                    <MDTypography variant="subtitle" fontWeight="bold">
                      Jasper
                    </MDTypography>
                  </Grid>
                  <Grid item xs={5} />
                  <Grid item xs={12}>
                    <Card>
                      <MDBox
                        // component="img"
                        // src="https://cdn.download.ams.birds.cornell.edu/api/v1/asset/44411751/1800"
                        // alt="Jasper"
                        borderRadius="lg"
                        shadow="md"
                        width="100%"
                        height="500px"
                        position="relative"
                        zIndex={1}
                      >
                        {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
                        <iframe id="iframeJasper" src="" width="100%" height="100%" />
                      </MDBox>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container justifyContent="center" alignItems="center" alignContent="center">
                  <Grid item xs={5} />
                  <Grid item xs={2}>
                    <MDTypography variant="subtitle" fontWeight="bold">
                      Crystal
                    </MDTypography>
                  </Grid>
                  <Grid item xs={5} />
                  <Grid item xs={12}>
                    <Card>
                      <MDBox
                        // component="img"
                        // src="https://www.prensalibre.com/wp-content/uploads/2021/09/RD-290821-ETNOBOTANICAph01_62109751.jpg?quality=52&w=691&h=430&crop=1"
                        // alt="Crystal"
                        borderRadius="lg"
                        shadow="md"
                        width="100%"
                        height="500px"
                        position="relative"
                        zIndex={1}
                      >
                        {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
                        <iframe id="iframeCrystal" src="" width="100%" height="100%" />
                      </MDBox>
                      {/* <MDBox
                        component="img"
                        src="https://www.prensalibre.com/wp-content/uploads/2021/09/RD-290821-ETNOBOTANICAph01_62109751.jpg?quality=52&w=691&h=430&crop=1"
                        alt="Crystal"
                        width="35rem"
                      /> */}
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <MDInput
                  type="text"
                  // label="Error en plantilla"
                  fullWidth
                  multiline
                  rows={3}
                  // disabled
                  sx={{ display: newValueError.length > 0 ? "flex" : "none" }}
                  InputLabelProps={{ shrink: true }}
                  value={newValueError}
                  /* onChange={(e) => {
                    setNewError(e.target.value);
                  }} */
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="left" alignItems="left" height="100%">
              <Grid item xs={12} sm={12} md={6} lg={8} xl={10} container justifyContent="flex-end">
                <MDButton
                  variant="contained"
                  autoFocus
                  onClick={saveData}
                  sx={{
                    background: "linear-gradient(45deg, #099268 30%, #099268 90%)",
                    color: "#FFFFFF",
                  }}
                >
                  <Icon>check_circle</Icon>&nbsp;Aceptar
                </MDButton>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={2} container justifyContent="flex-end">
                <MDButton
                  variant="contained"
                  autoFocus
                  onClick={openCommentModal}
                  sx={{
                    background: "linear-gradient(45deg, #CC0000 30%, #CC0000 90%)",
                    color: "#FFFFFF",
                  }}
                >
                  <Icon>cancel</Icon>&nbsp;Rechazar
                </MDButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
      {renderNotificacion}
      <Loading load={load} />
      <Footer />
    </DashboardLayout>
  );
}

export default PlantillasJasperCrystalCompare;
