/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Checkbox from "@mui/material/Checkbox";
import MDTypography from "components/MDTypography";

export default function data(jsonData, setViewModalDetail, setEditJsonData, deleteJsonData) {
  const getRows = () => {
    const elements = [];

    for (let index = 0; index < jsonData.length; index += 1) {
      elements.push({
        aplicacion: jsonData[index].applicationId,
        nombre: jsonData[index].name,
        tipo: jsonData[index].type ? jsonData[index].type.nombre : "",
        pais: jsonData[index].country ? jsonData[index].country.nombre : "",
        activa: <Checkbox disabled checked={jsonData[index].active} />,
        action_item: (
          <MDTypography color="text">
            <>
              {" "}
              <Tooltip
                title="Detalle Registro"
                disableInteractive
                onClick={() => {
                  setViewModalDetail(jsonData[index]);
                }}
              >
                <Icon color="info">info</Icon>
              </Tooltip>{" "}
              /{" "}
              <Tooltip
                title="Editar Registro"
                disableInteractive
                onClick={() => {
                  setEditJsonData(jsonData[index]);
                }}
              >
                <Icon color="info">edit</Icon>
              </Tooltip>{" "}
              /{" "}
              <Tooltip
                title="Eliminar Registro"
                disableInteractive
                onClick={() => {
                  deleteJsonData(jsonData[index]);
                }}
              >
                <Icon color="error">delete</Icon>
              </Tooltip>
            </>
          </MDTypography>
        ),
      });
    }

    return elements;
  };

  return {
    columns: [
      // { Header: "", accessor: "icon", width: "0%", align: "center" },
      { Header: "ID Aplicacion", accessor: "aplicacion", align: "center" },
      { Header: "Nombre", accessor: "nombre", align: "center" },
      { Header: "Tipo", accessor: "tipo", align: "center" },
      { Header: "Pais", accessor: "pais", align: "center" },
      { Header: "Activa", accessor: "activa", align: "center" },
      { Header: "Accion", accessor: "action_item", align: "center" },
    ],

    rows: getRows(),
  };
}

// Setting default values for the props of MDBadge
data.defaultProps = {
  jsonData: [],
};

// Typechecking props of the data
data.propTypes = {
  jsonData: PropTypes.objectOf(PropTypes.array),
  setViewModalDetail: PropTypes.func.isRequired,
  setEditJsonData: PropTypes.func.isRequired,
  deleteJsonData: PropTypes.func.isRequired,
};
