/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props
// import PropTypes from "prop-types";

// @mui material components
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Box from "@mui/material/Box";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Loading from "components/Loading";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// API Request
import APIRequest from "api/request";

function ManagerCrearEmpresa() {
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  const [load, setLoad] = useState(false);
  const { pathname } = useLocation();
  const {
    state: { route },
  } = useLocation();
  const navigate = useNavigate();

  const [newValuePais, setNewValuePais] = useState(null);
  const [newValueTaxId, setNewValueTaxId] = useState("");
  const [newValueNombre, setNewValueNombre] = useState("");
  const [newValueEmail, setNewValueEmail] = useState("");
  const [newValueDireccion, setNewValueDireccion] = useState("");
  const [newValueCiudad, setNewValueCiudad] = useState("");
  const [newValueComuna, setNewValueComuna] = useState("");
  const [newValueTelefono, setNewValueTelefono] = useState("");
  const [newValueFax, setNewValueFax] = useState("");
  const [newValueDescripcion, setNewValueDescripcion] = useState("");

  const [selectData, setSelectData] = useState({
    pais: [
      { id: "cr", nombre: "Costa rica" },
      { id: "ec", nombre: "Ecuador" },
      { id: "pe", nombre: "Perú" },
      { id: "cl", nombre: "Chile" },
      { id: "mx", nombre: "México" },
      { id: "gt", nombre: "Guatemala" },
      { id: "co", nombre: "Colombia" },
      { id: "pa", nombre: "Panamá" },
    ],
  });

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };
  const showNotificationError = (message) => {
    configNotification("error", "warning", "Notificación", message, "", true);
  };
  const showNotificationSuccess = (message) => {
    configNotification("warning", "check", "Notificación", message, "", true);
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );

  const saveData = async () => {
    setLoad(true);
    try {
      const HttpVerb = "POST";
      const HttpUrl = `/api/Manager/Empresa`;

      const body = {
        AccountId: "",
        CountryId: newValuePais.id,
        Country: newValuePais,
        Code: newValueTaxId,
        Name: newValueNombre,
        Description: newValueDescripcion,
        Address: newValueDireccion,
        City: newValueCiudad,
        Province: newValueComuna,
        Phone: newValueTelefono,
        Fax: newValueFax,
        ContactEmail: newValueEmail,
        ShowInPdf: null,
        Timestamp: new Date(),
        BankAccountNumber: null,
        BankId: null,
        TimestampUserUpdate: null,
        TimestampSystemUpdate: null,
        EmailUserUpdate: userInfo.correo,
        Source: null,
        ShowDocumentsLastDays: null,
      };

      const apiResponse = await APIRequest(HttpVerb, HttpUrl, body);

      if (apiResponse.status === 200 || apiResponse.status === 201 || apiResponse.ok) {
        showNotificationSuccess("Se guardó la empresa exitosamente");
        const jsonResponse = await apiResponse.json();
        body.AccountId = jsonResponse;
        navigate(route, { state: { newElement: body } });
      } else {
        showNotificationError(`Error, estado: ${apiResponse.status}`);
      }
    } catch {
      showNotificationError("Error Inesperado");
    }

    setLoad(false);
  };

  const getCountriesData = async () => {
    const tempJsonData = [];
    const apiResponse = await (await APIRequest("GET", "/api/Pais/Todos", null)).json();

    if (apiResponse.length > 0) {
      for (let index = 0; index < apiResponse.length; index += 1) {
        tempJsonData.push({
          id: apiResponse[index].codigo.toLowerCase(),
          nombre: apiResponse[index].nombre,
        });
      }

      setSelectData({
        pais: tempJsonData,
        // status: selectData.status,
      });
    }

    return tempJsonData;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    saveData();
  };

  useEffect(async () => {
    await getCountriesData();
  }, [pathname]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={3}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <MDBox pb={4}>
              <MDTypography variant="subtitle" fontWeight="bold">
                <Icon
                  sx={{ cursor: "pointer", fontWeight: "bold" }}
                  onClick={() => navigate(route)}
                >
                  arrow_back
                </Icon>
                {"   "}
                Manager - Empresa
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <Box sx={{ width: "100%" }}>
                <Card>
                  <MDBox
                    sx={{ position: "relative", mx: 3, py: 2, px: 2 }}
                    component="form"
                    onSubmit={handleSubmit}
                  >
                    <Grid
                      container
                      spacing={3}
                      justifyContent="left"
                      alignItems="left"
                      height="100%"
                    >
                      <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                        <Autocomplete
                          value={newValuePais}
                          onChange={(event, newValue) => {
                            setNewValuePais(newValue);
                          }}
                          options={selectData.pais}
                          getOptionLabel={(option) => option.nombre}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          renderInput={(params) => (
                            <MDInput
                              variant="standard"
                              type="text"
                              label="Pais"
                              fullWidth
                              required
                              InputLabelProps={{ shrink: true }}
                              {...params}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                        <MDInput
                          variant="standard"
                          type="text"
                          label="TaxId"
                          fullWidth
                          required
                          InputLabelProps={{ shrink: true }}
                          value={newValueTaxId}
                          onChange={(e) => {
                            setNewValueTaxId(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                        <MDInput
                          variant="standard"
                          type="text"
                          label="Nombre"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          value={newValueNombre}
                          onChange={(e) => {
                            setNewValueNombre(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                        <MDInput
                          variant="standard"
                          type="text"
                          label="Email"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          value={newValueEmail}
                          onChange={(e) => {
                            setNewValueEmail(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                        <MDInput
                          variant="standard"
                          type="text"
                          label="Dirección"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          value={newValueDireccion}
                          onChange={(e) => {
                            setNewValueDireccion(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                        <MDInput
                          variant="standard"
                          type="text"
                          label="Ciudad"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          value={newValueCiudad}
                          onChange={(e) => {
                            setNewValueCiudad(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                        <MDInput
                          variant="standard"
                          type="text"
                          label="Comuna"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          value={newValueComuna}
                          onChange={(e) => {
                            setNewValueComuna(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                        <MDInput
                          variant="standard"
                          type="text"
                          label="Teléfono"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          value={newValueTelefono}
                          onChange={(e) => {
                            setNewValueTelefono(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                        <MDInput
                          variant="standard"
                          type="text"
                          label="Fax"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          value={newValueFax}
                          onChange={(e) => {
                            setNewValueFax(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                        <MDInput
                          variant="standard"
                          type="text"
                          label="Descripción"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          value={newValueDescripcion}
                          onChange={(e) => {
                            setNewValueDescripcion(e.target.value);
                          }}
                          multiline
                          rows={4}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={4}
                        lg={5}
                        xl={2}
                        container
                        justifyContent="flex-end"
                      >
                        <MDButton
                          variant="contained"
                          type="submit"
                          sx={{
                            background: "linear-gradient(45deg, #099268 30%, #099268 90%)",
                            color: "#FFFFFF",
                          }}
                        >
                          <Icon>save</Icon>&nbsp;Guardar empresa
                        </MDButton>
                      </Grid>
                    </Grid>
                  </MDBox>
                </Card>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {renderNotificacion}
      <Loading load={load} />
      <Footer />
    </DashboardLayout>
  );
}

export default ManagerCrearEmpresa;
