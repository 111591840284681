export default function FormatDate(dateString) {
  const date = new Date(dateString);

  const year = date.getFullYear();
  if (year === 1) {
    return null;
  }
  let month = date.getMonth() + 1;
  let day = date.getDate();

  if (day < 10) {
    day = `0${day}`;
  }
  if (month < 10) {
    month = `0${month}`;
  }

  const hours = date.getHours();
  let minutes = date.getMinutes();
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }

  return `${day}-${month}-${year} ${hours}:${minutes}`;
}
