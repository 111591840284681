import React, { useState } from "react";
import PropTypes from "prop-types";
import { ListItemIcon, MenuItem } from "@mui/material";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import BKModal from "components/BKModal";
import xPosApiRequest from "api/xPosApiRequest";
import { isOk } from "views/admin/xpos/_helpers/requestHelpers";
import MDTypography from "../../../../../../components/MDTypography";

function DeleteFile({
  handleClose,
  country,
  fileName,
  keyResponse,
  organizationConfigGuid,
  setLoading,
  showNotificationError,
  showNotificationSuccess,
  setData,
  processName,
  documentVersion,
}) {
  const [modal, setModal] = useState(false);
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

  const handleOpenModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
    handleClose();
  };

  const handleDelete = async () => {
    const baseURL = `/configuration/document/${fileName}`;
    const headers = {
      "Content-Type": "multipart/form-data",
      countryId: country,
      configGuid: organizationConfigGuid,
      userLastAction: userInfo.correo,
    };

    const options = {
      method: "DELETE",
      url: baseURL,
      headers,
    };

    const response = await xPosApiRequest(options);
    if (isOk(response.status)) {
      const { version, updatedAt, updatedBy } = response.data;
      const body = {
        [keyResponse]: {
          version,
          updatedBy,
          updatedAt,
        },
      };

      showNotificationSuccess("Eliminado exitosamente");
      setData(body);
    } else {
      const errorMessage =
        response.data && response.data.length < 100
          ? response.data
          : "Ocurrió un error al eliminar el documento";
      showNotificationError(errorMessage);
    }
    setLoading(false);
    closeModal();
  };

  return (
    <>
      <MenuItem onClick={handleOpenModal}>
        <ListItemIcon>
          <ClearOutlinedIcon fontSize="small" />
        </ListItemIcon>
        Eliminar
      </MenuItem>
      <BKModal
        closeModal={closeModal}
        saveChanges={handleDelete}
        open={modal}
        title="¿Está seguro de eliminar el archivo?"
        maxWidth="xs"
        titleButton="Confirmar"
        styleModal={{
          titleColor: "inherit",
          acceptButtonColor: "success",
          cancelButtonColor: "error",
          variantButton: "gradient",
        }}
        sxButton={{ textTransform: "none", borderRadius: "20px" }}
      >
        <MDTypography style={{ marginLeft: 5, paddingRight: 5, fontSize: 12 }}>
          Está seguro de eliminar el archivo {documentVersion} del {processName}?
        </MDTypography>
      </BKModal>
    </>
  );
}

DeleteFile.defaultProps = {
  organizationConfigGuid: "",
};

DeleteFile.propTypes = {
  country: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  fileName: PropTypes.string.isRequired,
  setLoading: PropTypes.func.isRequired,
  showNotificationError: PropTypes.func.isRequired,
  showNotificationSuccess: PropTypes.func.isRequired,
  setData: PropTypes.func.isRequired,
  keyResponse: PropTypes.string.isRequired,
  organizationConfigGuid: PropTypes.string,
  processName: PropTypes.string.isRequired,
  documentVersion: PropTypes.string.isRequired,
};

export default DeleteFile;
