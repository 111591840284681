/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDebounce } from "use-debounce";
// react-router components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Autocomplete from "@mui/material/Autocomplete";

// API
import xPosApiRequest from "../../../../api/xPosApiRequest";

// Material Dashboard 2 React components
import MDBox from "../../../../components/MDBox";
import MDInput from "../../../../components/MDInput";
import MDButton from "../../../../components/MDButton";
import BKModalImport from "../../../../components/BKModalImport";
import BKModalConfirm from "../../../../components/BKModalConfirm";
import MDTypography from "../../../../components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "../../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";
import Footer from "../../../../examples/Footer";
import NewDataTable from "../../../../examples/Tables/NewDataTable";

// Data
import data from "./data";
import useNotification from "../_hooks/useNotification";
import Notification from "../_components/shared/Notification";
import ModalLiberarFolios from "../_hooks/pool/ModalLiberarFolios";

import { CountryCodesToNames } from "../_constants/general";
import { isOk } from "../_helpers/requestHelpers";

function Pool({ country }) {
  const navigator = useNavigate();
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  const [searchTaxID, setSearchTaxID] = useState("");
  const [searchIDxPos, setSearchIDxPos] = useState("");
  const [searchNombrexPos, setSearchNombrexPos] = useState("");
  const [searchConfPersonalizada, setSearchConfPersonalizada] = useState(""); // Nuevo filtro
  const [searchStatus, setSearchStatus] = useState(""); // Nuevo filtro
  const [searchDTE, setSearchDTE] = useState(""); // Nuevo filtro
  const [arrayTipoDTE, setArrayTipoDTE] = useState([]);
  const [sugerenciasNombrexPos, setSugerenciasNombrexPos] = useState([]); // Estado para las sugerencias

  const [reloadTable, setReloadTable] = useState(0);
  const [fileContent, setFileContent] = useState("");
  const [inputContent, setInputContent] = useState();
  const [deletePool, setDeletePool] = useState();
  const [poolName, setPoolName] = useState("");
  const [whereValue, setWhereValue] = useState("");

  const [jsonData, setJsonData] = useState([]);
  const [modal, setModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [filteredData, setFilteredData] = useState(undefined);
  const [enviarXPOS, setEnviarXPOS] = useState(null);
  const [showEnviarModal, setShowEnviarModal] = useState(false);
  const [showModalLiberar, setShowModalLiberar] = useState(false);
  const [xposSeleccionado, setXposSeleccionado] = useState(null);

  const { notification, showNotificationError, showNotificationSuccess, setNotification } =
    useNotification();

  // Debounce para el input de búsqueda
  const [valorDebounced] = useDebounce(searchNombrexPos, 300); // 300ms de delay

  const fetchData = async () => {
    setLoadingTable(true);
    const options = {
      method: "GET",
      url: `/xpos?countryId=${country}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await xPosApiRequest(options);
      if (isOk(response.status)) {
        const xPOSData = await response.data;
        setJsonData(xPOSData);
      } else {
        showNotificationError("Ocurrio un error al cargar los xpos.");
      }
      setLoadingTable(false);
    } catch (error) {
      showNotificationError(error.message);
      setLoadingTable(false);
    }
  };

  useEffect(() => {
    const getDataCountry = async () => {
      try {
        const { default: response } = await import(
          `./crear-pool/arrayTipoDTE_${country.toUpperCase()}`
        );
        setArrayTipoDTE(response);
      } catch (error) {
        setArrayTipoDTE([]);
      }
    };

    getDataCountry();
    fetchData();
  }, [country]);

  useEffect(() => {
    // Realiza la búsqueda solo cuando el valor debounced cambia
    if (valorDebounced) {
      const sugerencias = jsonData
        .filter((pool) => pool.name.toLowerCase().includes(valorDebounced.toLowerCase()))
        .map((pool) => pool.name);
      setSugerenciasNombrexPos(sugerencias);
    } else {
      // Limpia las sugerencias si el input está vacío
      setSugerenciasNombrexPos([]);
    }
  }, [valorDebounced, jsonData]);

  const handleFileUpload = (event) => {
    if (event.target.files) {
      const file = event.target.files[0];
      const validExtensions = ".csv,.txt";
      const extension = file.name.substring(file.name.lastIndexOf(".")).toLowerCase();
      if (validExtensions.indexOf(extension) === -1) {
        showNotificationError(`La extensión del archivo es incorrecta`);
      } else {
        setFileContent(file);
      }
    }
  };

  // Para manejar las búsquedas
  const handleSearch = () => {
    let filteredPools = jsonData;

    if (searchTaxID) {
      filteredPools = filteredPools.filter((pool) =>
        pool.taxId?.toLowerCase().includes(searchTaxID.toLowerCase())
      );
    }

    if (searchIDxPos) {
      filteredPools = filteredPools.filter((pool) =>
        pool.guid?.toLowerCase().includes(searchIDxPos.toLowerCase())
      );
    }

    if (searchNombrexPos) {
      filteredPools = filteredPools.filter((pool) =>
        pool.name?.toLowerCase().includes(searchNombrexPos.toLowerCase())
      );
    }

    // Filtro por Configuración Personalizada
    if (searchConfPersonalizada) {
      if (searchConfPersonalizada === "Si") {
        filteredPools = filteredPools.filter((pool) => pool.globalConfigUpdater === 2); // Compara con 2 (con configuración personalizada)
      } else {
        filteredPools = filteredPools.filter((pool) => pool.globalConfigUpdater === 0); // Compara con 0 (sin configuración personalizada)
      }
    }

    // Filtro por Estado
    if (searchStatus) {
      filteredPools = filteredPools.filter((pool) => {
        let statusString = "";
        switch (pool.status) {
          case 0:
            statusString = "Inactivo";
            break;
          case 1:
            statusString = "Activo";
            break;
          case 2:
            statusString = "Bloqueado";
            break;
          default:
            statusString = "";
        }
        return statusString === searchStatus;
      });
    }

    // Filtro por DTE (asumiendo que quieres buscar en el array de 'folios')
    if (searchDTE !== null && searchDTE.value) {
      filteredPools = filteredPools.filter((pool) =>
        pool.folios.some((folio) =>
          folio.typeDte.toString().toLowerCase().includes(searchDTE.value.toString().toLowerCase())
        )
      );
    }

    setFilteredData(filteredPools);
  };

  const handleClearFilters = () => {
    setSearchTaxID("");
    setSearchIDxPos("");
    setSearchNombrexPos("");
    setSearchConfPersonalizada("");
    setSearchStatus("");
    setSearchDTE("");
    setFilteredData(jsonData); // Restaura la tabla a su estado original
  };

  const countryName = CountryCodesToNames[country];

  const setEditJsonData = (pool) => {
    navigator(`/XPos/${countryName}/Pools/CrearxPOS`, { state: { pool } });
  };

  const setDeleteJsonData = async () => {
    if (deletePool !== undefined) {
      const { guid, name } = deletePool;

      const options = {
        method: "DELETE",
        url: `/xpos/${guid}`,
        headers: {
          "Content-Type": "application/json",
          countryId: country,
        },
      };

      try {
        const response = await xPosApiRequest(options);
        if (isOk(response.status)) {
          fetchData();
          showNotificationSuccess(`Se eliminó el xPOS '${name}' exitosamente.`);
          setReloadTable(reloadTable + 1);
        } else {
          showNotificationError(`No se pudo eliminar el xPOS.`);
        }
      } catch (error) {
        showNotificationError(`No se pudo eliminar el xPOS.`);
      }
      setDeleteModal(false);
    }
  };

  const setDisabledJsonData = async (pool) => {
    if (!pool) return;
    const { guid, name } = pool;

    const options = {
      method: "POST",
      url: `/xpos/block/${guid}`,
      headers: {
        "Content-Type": "application/json",
        countryId: country,
      },
    };

    try {
      const response = await xPosApiRequest(options);
      if (isOk(response.status)) {
        fetchData();
        showNotificationSuccess(`Se bloqueó el xPOS '${name}' exitosamente.`);
        setReloadTable(reloadTable + 1);
      } else {
        showNotificationError(`No se pudo bloquear el xPOS.`);
      }
    } catch (error) {
      showNotificationError(`No se pudo bloquear el xPOS.`);
    }
    setDeleteModal(false);
  };

  const setResetJsonData = async (pool) => {
    if (pool !== undefined) {
      const { guid, name } = pool;

      const options = {
        method: "POST",
        url: `/xpos/reset-onboarding/${guid}`,
        headers: {
          "Content-Type": "application/json",
          countryId: country,
        },
      };
      try {
        const response = await xPosApiRequest(options);
        if (isOk(response.status)) {
          fetchData();
          showNotificationSuccess(`Se reinició el xPOS '${name}' exitosamente.`);
          setReloadTable(reloadTable + 1);
        } else {
          showNotificationError(`No se pudo reiniciar el xPOS.`);
        }
      } catch (error) {
        showNotificationError(`No se pudo reiniciar el xPOS.`);
      }
      setDeleteModal(false);
    }
  };
  const setEnabledJsonData = async (pool) => {
    if (pool !== undefined) {
      const { guid, name } = pool;

      const options = {
        method: "POST",
        url: `/xpos/unblock/${guid}`,
        headers: {
          "Content-Type": "application/json",
          countryId: country,
        },
      };

      try {
        const response = await xPosApiRequest(options);
        if (isOk(response.status)) {
          fetchData();
          showNotificationSuccess(`Se desbloqueó el xPOS '${name}' exitosamente.`);
          setReloadTable(reloadTable + 1);
        } else {
          showNotificationError(`No se pudo desbloquear el xPOS.`);
        }
      } catch (error) {
        showNotificationError(`No se pudo desbloquear el xPOS.`);
      }
      setDeleteModal(false);
    }
  };

  const handleInputFile = (input) => {
    setInputContent(input);
    setWhereValue(input.where);
    setConfirmModal(true);
  };

  const handleDeleteXPOS = (pool) => {
    setDeletePool(pool);
    setPoolName(pool.name);
    setDeleteModal(true);
  };

  const handleEnviarXPOS = (pool) => {
    setEnviarXPOS(pool);
    setShowEnviarModal(true);
  };

  const handleConfirmarEnvio = async () => {
    // Aquí va la lógica para manejar la acción de enviar el xPOS
    const options = {
      method: "POST",
      url: `/xpos/xposClone/${enviarXPOS.guid}/${userInfo.correo}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await xPosApiRequest(options);
      if (isOk(response.status)) {
        showNotificationSuccess("El xPos se clono exitosamente entre ambientes.");
      } else {
        showNotificationError("Ocurrio un error al clonar el xPos.");
      }
      setLoadingTable(false);
    } catch (error) {
      showNotificationError(error.message);
      setLoadingTable(false);
    }

    // Cierra la modal después de procesar la acción
    setShowEnviarModal(false);
  };

  const abrirModalLiberarFolios = (xpos) => {
    setXposSeleccionado(xpos);
    setShowModalLiberar(true);
  };

  const uploadDocument = async () => {
    if (inputContent !== undefined) {
      const { where, file, idsArray } = inputContent;
      const validExtensions = ".xslt";
      const extension = file.name.substring(file.name.lastIndexOf(".")).toLowerCase();
      if (validExtensions.indexOf(extension) !== -1) {
        const formData = new FormData();
        formData.append("XposGuids", idsArray);
        formData.append(where, file);
        formData.append("userLastAction", userInfo.correo);

        const options = {
          method: "PUT",
          url: `/xpos/update-documents`,
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        };

        try {
          const response = await xPosApiRequest(options);
          if (isOk(response.status)) {
            showNotificationSuccess("El archivo se importó exitosamente");
            fetchData();
            setReloadTable(reloadTable + 1);
          } else {
            showNotificationError(`Ocurrio un error al importar`);
          }
        } catch (error) {
          showNotificationError(`Ocurrio un error al importar`);
        }
      } else {
        showNotificationError(`La extensión del archivo es incorrecta`);
      }
      setConfirmModal(false);
      setInputContent(undefined);
    }
  };

  const { columns, rows } = data(
    filteredData || jsonData,
    setEditJsonData,
    handleDeleteXPOS,
    setDisabledJsonData,
    setEnabledJsonData,
    setResetJsonData,
    handleEnviarXPOS,
    abrirModalLiberarFolios
  );

  const openAddNewElementModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  const closeConfirmModal = () => {
    setConfirmModal(false);
    setInputContent(undefined);
  };

  const closeConfirmDeleteModal = () => {
    setDeleteModal(false);
    setDeletePool(undefined);
  };

  const importFile = async () => {
    if (fileContent !== "") {
      const formData = new FormData();
      formData.append("importFile", fileContent);
      formData.append("userLastAction", userInfo.correo);
      formData.append("country", country);

      const options = {
        method: "POST",
        url: "/xpos/import",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      try {
        const response = await xPosApiRequest(options);
        if (isOk(response.status)) {
          showNotificationSuccess("El archivo se importó exitosamente");
          fetchData();
          setReloadTable(reloadTable + 1);
          closeModal();
        } else if (response.data.errors) {
          showNotificationError("Ocurrieron errores al intentar validar el folio");
        } else {
          showNotificationError(response.data);
        }
      } catch (error) {
        showNotificationError(`Ocurrio un error al importar`);
      }
    }
  };

  const renderEnviarModal = (
    <BKModalConfirm
      closeModal={() => setShowEnviarModal(false)}
      saveChanges={handleConfirmarEnvio}
      open={showEnviarModal}
      title={`Enviar xPOS ${enviarXPOS ? enviarXPOS.name : ""}`}
      titleButton="Confirmar"
    >
      <MDBox px={2} sx={{ position: "relative" }}>
        <Grid container justifyContent="center" alignItems="center" height="100%">
          <div style={{ margin: "1em" }}>
            ¿Está seguro que desea clonar este xPos entre ambientes?
          </div>
        </Grid>
      </MDBox>
    </BKModalConfirm>
  );

  const renderModalLiberarFolios = showModalLiberar && (
    <ModalLiberarFolios
      xpos={xposSeleccionado}
      onClose={() => setShowModalLiberar(false)}
      showNotificationError={showNotificationError}
      showNotificationSuccess={showNotificationSuccess}
    />
  );

  const renderModal = (
    <BKModalImport
      closeModal={closeModal}
      saveChanges={importFile}
      disabledSave={fileContent === ""}
      open={modal}
      title="Importar xPOS"
      titleButton="Guardar"
    >
      <MDBox px={2} sx={{ position: "relative" }}>
        <Grid container justifyContent="center" alignItems="center" height="100%">
          <div style={{ margin: "1em" }}>
            <div className="file-upload">
              <div style={{ paddingLeft: 30, paddingRight: 35, paddingTop: 20, paddingBottom: 10 }}>
                <input
                  type="file"
                  accept=".csv,.txt"
                  id="file-input"
                  onChange={(event) => handleFileUpload(event)}
                  hidden
                />
                <label
                  style={{ display: "flex", justifyContent: "space-between" }}
                  htmlFor="file-input"
                >
                  <Grid
                    backgroundColor="#1971c2"
                    width="50px"
                    height="30px"
                    borderRadius={2}
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginRight: 10,
                    }}
                  >
                    <Icon fontSize="medium" style={{ color: "white" }}>
                      upload
                    </Icon>
                  </Grid>
                  <p
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "#344767",
                    }}
                  >
                    Seleccione el archivo a importar
                  </p>
                </label>
              </div>
            </div>
          </div>
        </Grid>
      </MDBox>
    </BKModalImport>
  );

  const renderConfirmModal = (
    <BKModalConfirm
      closeModal={closeConfirmModal}
      saveChanges={uploadDocument}
      open={confirmModal}
      title={`Cargar archivo ${whereValue}`}
      titleButton="Confirmar"
    >
      <MDBox px={2} sx={{ position: "relative" }}>
        <Grid container justifyContent="center" alignItems="center" height="100%">
          <div style={{ margin: "1em" }}>¿Está seguro que desea cargar este archivo?</div>
        </Grid>
      </MDBox>
    </BKModalConfirm>
  );

  const renderConfirmDeleteModal = (
    <BKModalConfirm
      closeModal={closeConfirmDeleteModal}
      saveChanges={setDeleteJsonData}
      open={deleteModal}
      title={`Eliminar xPOS ${poolName}`}
      titleButton="Confirmar"
    >
      <MDBox px={2} sx={{ position: "relative" }}>
        <Grid container justifyContent="center" alignItems="center" height="100%">
          <div style={{ margin: "1em" }}>¿Está seguro que desea eliminar este xPOS?</div>
        </Grid>
      </MDBox>
    </BKModalConfirm>
  );

  // Función de exportar los datos filtrados a .csv
  const exportar = () => {
    // función para acceder a solo los datos necesarios
    const constructor = (object) => {
      const selectedKeys = [
        "taxId",
        "guid",
        "name",
        "creationDate",
        "folios",
        "lastRecharge",
        "signingCertificate",
        "userLastAction",
        "status",
      ];

      // verificación de coincidencia entre selectKeys y
      const selectedData = {};
      selectedKeys.forEach((elemento) => {
        if (elemento in object) {
          selectedData[elemento] = object[elemento];
        }
      });

      if (selectedData.status === 0) {
        selectedData.status = "Inactivo";
      } else if (selectedData.status === 1) {
        selectedData.status = "Activo";
      } else {
        selectedData.status = "Bloqueado";
      }
      return selectedData;
    };

    // arreglo para guardar cada uno de los nuevos objetos
    const dual = [];
    let newObject = {};
    filteredData.forEach((elemento) => {
      newObject = constructor(elemento);

      dual.push(newObject);
    });

    let columnas = [
      {
        taxId: "TaxId",
        guid: "Id xPOS",
        name: "Nombre xPOS",
        creationDate: "Fecha de Creacion",
        umbral: "Umbral",
        ultimaRecarga: "Ultima Recarga",
        signingCertificate: "Certificado de Firma",
        userLastAction: "Usuario Ultima Accion",
        status: "Estado",
      },
    ];

    // sustitución de [object object] dentro de folio por cadena "typeDte:Umbral:umbralValue"
    const dualDos = [];
    dual.forEach((e) => {
      const miCadena = e.folios
        .map(
          (elemento) =>
            `${elemento.typeDte}: Umbral: ${elemento.umbralValue}${
              elemento.typeUmbral === 1 ? "" : "%"
            }`
        )
        .join(" ");

      const date = e.creationDate.split("T")[0];

      const sustituido = { ...e, folios: miCadena, creationDate: date };
      dualDos.push(sustituido);
    });

    // Unión del arreglo de encabezado con datos dinámicos
    columnas = [...columnas, ...dualDos];

    // construcción y descarga de .csv
    const csvContent = `data:text/csv;charset=utf-8,${columnas
      .map((row) => Object.values(row).join(";"))
      .join("\n")}`;

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "exported_xPos.csv");
    document.body.appendChild(link);
    link.click();
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {renderModal}
      {renderConfirmModal}
      {renderConfirmDeleteModal}
      {renderEnviarModal}
      {renderModalLiberarFolios}
      <MDTypography fontWeight="bold" variant="h5" color="dark" noWrap>
        Listado de xPOS
      </MDTypography>
      <MDBox py={3}>
        <MDBox pt={3} pb={3}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} py={1}>
              <MDBox display="flex" justifyContent="space-between">
                <MDBox display="flex" alignItems="center" justifyContent="flex-start">
                  <MDBox width="18rem">
                    <MDInput
                      style={{ color: "#344767", borderColor: "#344767" }}
                      placeholder="Búsqueda por TaxID"
                      label="TaxID"
                      value={searchTaxID}
                      type="search"
                      size="small"
                      onChange={(event) => setSearchTaxID(event.target.value)}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                    />
                  </MDBox>
                  <MDBox width="18rem" ml={2}>
                    <MDInput
                      placeholder="Búsqueda por ID xPOS"
                      value={searchIDxPos}
                      label="ID xPOS"
                      type="search"
                      size="small"
                      onChange={(event) => setSearchIDxPos(event.target.value)}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                    />
                  </MDBox>
                  <MDBox width="18rem" ml={2}>
                    <Autocomplete // Usa Autocomplete para mostrar las sugerencias
                      options={sugerenciasNombrexPos}
                      getOptionLabel={(option) => option}
                      size="medium"
                      value={searchNombrexPos}
                      onChange={(event, newValue) => setSearchNombrexPos(newValue)}
                      renderInput={(params) => (
                        <MDInput
                          {...params}
                          label="Nombre xPOS"
                          placeholder="Búsqueda por Nombre xPOS"
                          variant="standard"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          onChange={(event) => setSearchNombrexPos(event.target.value)} // Actualiza el estado del input
                        />
                      )}
                    />
                  </MDBox>
                  <MDBox width="19rem" ml={2}>
                    <Autocomplete
                      options={["Si", "No"]}
                      getOptionLabel={(option) => option}
                      size="medium"
                      value={searchConfPersonalizada}
                      onChange={(event, newValue) => setSearchConfPersonalizada(newValue)}
                      renderInput={(params) => (
                        <MDInput
                          {...params}
                          label="Conf. Personalizada"
                          placeholder="Seleccione una opción"
                          variant="standard"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          onChange={(event, newValue) => setSearchConfPersonalizada(newValue)}
                        />
                      )}
                    />
                  </MDBox>
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item xs={12} py={1}>
              <MDBox display="flex" justifyContent="space-between">
                <MDBox display="flex" alignItems="center" justifyContent="flex-start">
                  <MDBox width="18rem">
                    <Autocomplete
                      options={["Activo", "Inactivo", "Bloqueado"]}
                      getOptionLabel={(option) => option}
                      size="medium"
                      value={searchStatus}
                      onChange={(event, newValue) => setSearchStatus(newValue)}
                      renderInput={(params) => (
                        <MDInput
                          {...params}
                          label="Estado"
                          placeholder="Todos"
                          variant="standard"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </MDBox>
                  <MDBox width="18rem" ml={2}>
                    <Autocomplete
                      options={arrayTipoDTE}
                      getOptionLabel={(option) => option.label}
                      size="medium"
                      onChange={(event, newValue) => setSearchDTE(newValue)}
                      renderInput={(params) => (
                        <MDInput
                          {...params}
                          label="Búsqueda por DTE"
                          placeholder="Todos"
                          variant="standard"
                          fullWidth
                          value={searchDTE ? searchDTE.label : ""}
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </MDBox>
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item xs={12} py={1}>
              <MDBox display="flex" justifyContent="space-between">
                <MDBox display="flex" justifyContent="space-between">
                  <MDButton
                    variant="contained"
                    color="primary"
                    onClick={handleSearch} // <-- Llama a la función handleSearch al hacer clic
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "110px",
                      height: "35.5px",
                      borderRadius: 8,
                      backgroundColor: "#1971c2",
                      marginRight: "10px",
                    }}
                  >
                    <Icon fontSize="medium" style={{ color: "white", marginRight: 5 }}>
                      search
                    </Icon>
                    <MDTypography fontWeight="bold" color="white" py={1} style={{ fontSize: 12 }}>
                      BUSCAR
                    </MDTypography>
                  </MDButton>
                  <MDButton
                    variant="contained"
                    color="primary"
                    onClick={handleClearFilters}
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "110px",
                      height: "35.5px",
                      borderRadius: 8,
                      backgroundColor: "#1971c2",
                      marginRight: "10px",
                    }}
                  >
                    <Icon fontSize="medium" style={{ color: "white", marginRight: 5 }}>
                      clear
                    </Icon>
                    <MDTypography fontWeight="bold" color="white" py={1} style={{ fontSize: 12 }}>
                      LIMPIAR
                    </MDTypography>
                  </MDButton>
                </MDBox>
                <MDBox display="flex" alignItems="center" justifyContent="flex-end">
                  <MDButton
                    variant="contained"
                    color="primary"
                    onClick={exportar}
                    disabled={filteredData === undefined || filteredData.length === 0}
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "110px",
                      height: "35.5px",
                      borderRadius: 8,
                      backgroundColor: "#1971c2",
                      marginRight: "10px",
                    }}
                  >
                    <FileDownloadIcon style={{ marginRight: 5, color: "white" }} />
                    <MDTypography fontWeight="bold" color="white" py={1} style={{ fontSize: 12 }}>
                      EXPORTAR
                    </MDTypography>
                  </MDButton>
                  <MDButton
                    variant="contained"
                    color="primary"
                    onClick={openAddNewElementModal}
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "110px",
                      height: "35.5px",
                      borderRadius: 8,
                      backgroundColor: "#00913f",
                      marginRight: "10px",
                    }}
                  >
                    <FileUploadIcon style={{ marginRight: 5, color: "white" }} />
                    <MDTypography fontWeight="bold" color="white" py={1} style={{ fontSize: 12 }}>
                      IMPORTAR
                    </MDTypography>
                  </MDButton>

                  <MDButton
                    variant="contained"
                    color="primary"
                    component={Link}
                    to={`/XPos/${countryName}/Pools/CrearxPOS`}
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "110px",
                      height: "35.5px",
                      borderRadius: 8,
                      backgroundColor: "#00913f",
                    }}
                  >
                    <AddCircleOutlineIcon style={{ marginRight: 5, color: "white" }} />
                    <MDTypography fontWeight="bold" color="white" py={1} style={{ fontSize: 12 }}>
                      AGREGAR
                    </MDTypography>
                  </MDButton>
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <MDBox>
                  <NewDataTable
                    entriesPerPage={(false, { defaultValue: 10 })}
                    canSearch
                    checkbox
                    tableType="xPOS"
                    showTotalEntries
                    inputFile={handleInputFile}
                    loadingTable={loadingTable}
                    table={{ columns, rows }}
                    pagination={{ variant: "contained", color: "warning" }}
                    isSorted
                  />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Notification notification={notification} setNotification={setNotification} />
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

Pool.propTypes = {
  country: PropTypes.string.isRequired,
};
export default Pool;
