/* eslint-disable react/prop-types */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import { Checkbox } from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// API Request
import APIRequest from "api/xDocApiRequest";

// Custom components
import MDButton from "components/MDButton";
import MDEditor from "components/MDEditor";
import { useLocation, useNavigate } from "react-router-dom";

function VersionEditForm() {
  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  const {
    state: { version, releaseNotes, route },
  } = useLocation();
  const navigate = useNavigate();

  const [versionToUpdate, setVersionToUpdate] = useState(version);
  const [spanishNotes, setSpanishNotes] = useState(
    releaseNotes.filter((x) => x.culture === "es-MX")[0].content
  );
  // eslint-disable-next-line no-unused-vars
  const handleChangeSpanish = (content, delta, source, editor) => {
    const text = editor.getText(content);
    setSpanishNotes(text);
  };
  const [portugueseNotes, setPortugueseNotes] = useState(
    releaseNotes.filter((x) => x.culture === "pt-BR")[0].content
  );
  // eslint-disable-next-line no-unused-vars
  const handleChangePortuguese = (content, delta, source, editor) => {
    const text = editor.getText(content);
    setPortugueseNotes(text);
  };
  const formatNote = (text) => {
    const lines = text.split("\n");
    let output = "";
    lines.forEach((line) => {
      output += `<p>${line}</p>`;
    });
    return output;
  };

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };

  const updateVersion = async () => {
    try {
      const apiResponse = await APIRequest("POST", "/Version/Update", {
        ...versionToUpdate,
        releaseNotes: [
          { culture: "es-MX", content: spanishNotes },
          { culture: "pt-BR", content: portugueseNotes },
        ],
      });

      if (apiResponse.status === 200) {
        navigate(route, {
          state: {
            notificationParams: [
              "warning",
              "check",
              "Notificación",
              `Se modificó la versión '${version.number}' exitosamente.`,
              "",
              true,
            ],
          },
        });
      } else {
        const errorMessage = {
          code: apiResponse.status,
          message: `Error: ${apiResponse.status}`,
        };
        throw errorMessage;
      }
    } catch (e) {
      configNotification(
        "error",
        "warning",
        "Notificación",
        `Ocurrió un error al modificar la versión: ${e.message}`,
        "",
        true
      );
    }
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );

  return (
    <DashboardLayout>
      <DashboardNavbar breadCrumb={route.split("/").slice(1)} />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                <MDBox>
                  <MDTypography variant="h4" gutterBottom color="warning">
                    Editar versión
                  </MDTypography>
                </MDBox>
              </MDBox>
              <MDBox style={{ margin: "20px" }}>
                <MDBox>
                  <MDTypography display="flex" variant="h6" gutterBottom color="text">
                    <strong>Número: </strong>
                    <p style={{ color: "#a59f9f", marginLeft: "5px" }}>{versionToUpdate.number}</p>
                  </MDTypography>
                </MDBox>
                <MDBox>
                  <MDTypography variant="h6" gutterBottom color="text">
                    Mandatoria:
                    <Checkbox
                      checked={versionToUpdate.isMandatory}
                      onClick={() =>
                        setVersionToUpdate({
                          ...versionToUpdate,
                          isMandatory: !versionToUpdate.isMandatory,
                        })
                      }
                      color="primary"
                      fontSize="medium"
                    />
                  </MDTypography>
                </MDBox>
                <MDBox>
                  <MDBox>
                    <MDTypography variant="h6" gutterBottom color="text">
                      Notas en español (es-MX):
                    </MDTypography>
                    <MDEditor
                      placeholder="Escriba las notas en español..."
                      modules={{ toolbar: false }}
                      defaultValue={formatNote(spanishNotes)}
                      onChange={handleChangeSpanish}
                    />
                  </MDBox>
                  <MDBox>
                    <MDTypography variant="h6" gutterBottom color="text">
                      Notas en portugués (pt-BR):
                    </MDTypography>
                    <MDEditor
                      placeholder="Escriba las notas en portugués..."
                      modules={{ toolbar: false }}
                      defaultValue={formatNote(portugueseNotes)}
                      onChange={handleChangePortuguese}
                    />
                  </MDBox>
                </MDBox>
                <MDBox style={{ marginTop: "20px" }}>
                  <MDButton
                    style={{ marginRight: "10px" }}
                    variant="outlined"
                    onClick={updateVersion}
                    color="success"
                  >
                    Guardar
                  </MDButton>
                  <MDButton variant="outlined" onClick={() => navigate(route)} color="warning">
                    Cancelar
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {renderNotificacion}
      <Footer />
    </DashboardLayout>
  );
}

export default VersionEditForm;
