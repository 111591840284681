/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as React from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import Icon from "@mui/material/Icon";
import { useTheme } from "@mui/material/styles";

// Material Dashboard 2 React components
import { Grid } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

// import MDButton from "components/MDButton";
import MDTypography from "../MDTypography";
import MDBox from "../MDBox";

export default function ResponsiveDialog({ closeModal, children, title, ...rest }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog
      fullScreen={fullScreen}
      maxWidth="sm"
      // fullWidth
      {...rest}
      aria-labelledby="responsive-dialog-title"
    >
      <MDBox style={{ backgroundColor: "#f0f2f5" }}>
        <DialogTitle
          id="responsive-dialog-title"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: 5,
          }}
        >
          <MDTypography
            fontWeight="bold"
            style={{
              justifyContent: "flex-start",
              display: "flex",
              color: "#344767",
            }}
          >
            {title}
          </MDTypography>
          <Grid onClick={closeModal}>
            <Tooltip title="Cerrar" disableInteractive>
              <Icon fontSize="medium" style={{ color: "#e03131", cursor: "pointer" }}>
                close
              </Icon>
            </Tooltip>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ paddingBottom: 8 }}>{children}</DialogContent>
      </MDBox>
    </Dialog>
  );
}

// Setting default values for the props of MDSnackbar
ResponsiveDialog.defaultProps = {
  title: "",
};

// Typechecking props for MDSnackbar
ResponsiveDialog.propTypes = {
  closeModal: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};
