/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// Default Components
import MDTypography from "components/MDTypography";

export default function data(
  jsonData,
  setEditJsonData,
  getSecurityCodeQR,
  openModalShowQr,
  copyTextToClipboard
) {
  const getRows = () => {
    const elements = [];

    for (let index = 0; index < jsonData.length; index += 1) {
      elements.push({
        ruc: jsonData[index].ruc,
        status: jsonData[index].status.nombre,
        user: jsonData[index].user,
        qr:
          jsonData[index].qr.length === 0 || jsonData[index].qr.includes("Manual") ? (
            jsonData[index].qr
          ) : (
            <MDTypography color="text">
              <Tooltip
                title="Ver QR"
                disableInteractive
                onClick={() => {
                  openModalShowQr(jsonData[index].qr);
                }}
              >
                <Icon>visibility</Icon>
              </Tooltip>{" "}
              /{" "}
              <Tooltip
                title="Copiar QR al portapapeles"
                disableInteractive
                onClick={() => {
                  copyTextToClipboard(jsonData[index].qr);
                }}
              >
                <Icon>content_copy</Icon>
              </Tooltip>
            </MDTypography>
          ),
        action: (
          <>
            <MDTypography color="text" display={jsonData[index].qr === "" ? "flex" : "none"}>
              <Tooltip
                title="Obtener QR"
                disableInteractive
                onClick={() => {
                  getSecurityCodeQR(jsonData[index].ruc);
                }}
              >
                <Icon>download</Icon>
              </Tooltip>
            </MDTypography>
            <MDTypography
              color="text"
              display={jsonData[index].qr === "Actualizacion Manual" ? "flex" : "none"}
            >
              <Tooltip
                title="Actualización Manual QR"
                disableInteractive
                onClick={() => {
                  setEditJsonData(jsonData[index]);
                }}
              >
                <Icon>edit</Icon>
              </Tooltip>
            </MDTypography>
          </>
        ),
        fechaUpdate: jsonData[index].fechaUpdate,
      });
    }

    return elements;
  };

  return {
    columns: [
      // { Header: "", accessor: "icon", width: "0%", align: "center" },
      { Header: "RUC", accessor: "ruc", align: "center" },
      { Header: "Estado", accessor: "status", align: "center" },
      { Header: "Usuario", accessor: "user", align: "center" },
      { Header: "QR", accessor: "qr", align: "center" },
      { Header: "Obtener QR", accessor: "action", align: "center" },
      { Header: "Fecha Actualización", accessor: "fechaUpdate", align: "center" },
    ],

    rows: getRows(),
  };
}

// Setting default values for the props of MDBadge
data.defaultProps = {
  jsonData: [],
};

// Typechecking props of the data
data.propTypes = {
  jsonData: PropTypes.objectOf(PropTypes.array),
  setEditJsonData: PropTypes.func.isRequired,
  getSecurityCodeQR: PropTypes.func.isRequired,
  openModalShowQr: PropTypes.func.isRequired,
  copyTextToClipboard: PropTypes.func.isRequired,
};
