/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// Default Components
import { useEffect, useState } from "react";
import MDTypography from "../../../../../components/MDTypography";
import baseColumns from "./baseColumns";

export default function data(jsonData, setEditJsonData, setDeleteJsonData, country) {
  const [dataCountry, setDataCountry] = useState(null);

  useEffect(() => {
    const getDataCountry = async () => {
      try {
        const { default: response } = await import(`./data${country.toUpperCase()}`);
        setDataCountry(response);
      } catch (error) {
        setDataCountry(null);
      }
    };

    getDataCountry();
  }, [country]);

  const getRows = () => {
    const elements = [];
    for (let index = 0; index < jsonData.length; index += 1) {
      let row = {
        taxId: jsonData[index].taxId,
        guid: jsonData[index].guid,
        organization: jsonData[index].name,
        certificate: jsonData[index].signingCertificate,
        fechaCreacion:
          jsonData[index].creationDate === undefined
            ? ""
            : jsonData[index].creationDate.split("T")[0],
        usuario: jsonData[index].userLastAction,
        gs: jsonData[index].gs ? "Si" : "No",
        globalConfigUpdater: jsonData[index].globalConfigUpdater !== 0 ? "Si" : "No",
        action: (
          <MDTypography color="text" display="flex" justifyContent="space-between">
            <Tooltip
              style={{ marginRight: 2, cursor: "pointer" }}
              title="Deshabilitar Registro"
              disableInteractive
              onClick={() => {
                setDeleteJsonData(jsonData[index]);
              }}
            >
              <Icon>delete</Icon>
            </Tooltip>
            <Tooltip
              title="Editar Registro"
              disableInteractive
              style={{ cursor: "pointer" }}
              onClick={() => {
                setEditJsonData(jsonData[index]);
              }}
            >
              <Icon>edit</Icon>
            </Tooltip>
          </MDTypography>
        ),
      };

      row = dataCountry?.getRow ? dataCountry?.getRow(row, jsonData, index) : row;

      elements.push(row);
    }

    return elements;
  };

  const getColumns = () => dataCountry?.getColumns(baseColumns) ?? baseColumns;

  return {
    columns: getColumns(),
    rows: getRows(),
  };
}

// Setting default values for the props of MDBadge
data.defaultProps = {
  jsonData: [],
  country: "",
};

// Typechecking props of the data
data.propTypes = {
  jsonData: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  setEditJsonData: PropTypes.func.isRequired,
  setDeleteJsonData: PropTypes.func.isRequired,
  country: PropTypes.string,
};
