/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// @mui material components
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Loading from "components/Loading";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// API Request
import APIRequest from "api/request";

function GadgetForm() {
  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  const [load, setLoad] = useState(false);
  const { pathname } = useLocation();
  const {
    state: { route, element },
  } = useLocation();
  const navigate = useNavigate();

  const [elementIsNew, setElementIsNew] = useState(true);
  const [newValueApplicationId, setNewValueApplicationId] = useState("");
  const [newValuePais, setNewValuePais] = useState(null);
  const [newValueAlias, setNewValueAlias] = useState("");
  const [newValueDescription, setNewValueDescription] = useState("");
  const [newValueName, setNewValueName] = useState("");
  const [newValuePassword, setNewValuePassword] = useState("");
  const [newValueType, setNewValueType] = useState(null);
  const [newValueUrl, setNewValueUrl] = useState("");
  const [newValueActive, setNewValueActive] = useState(false);

  const [selectData, setSelectData] = useState({
    pais: [
      { id: "cr", nombre: "Costa rica" },
      { id: "ec", nombre: "Ecuador" },
      { id: "pe", nombre: "Perú" },
      { id: "cl", nombre: "Chile" },
      { id: "mx", nombre: "México" },
      { id: "gt", nombre: "Guatemala" },
      { id: "co", nombre: "Colombia" },
      { id: "pa", nombre: "Panamá" },
    ],
    type: [
      { id: 0, nombre: "Core" },
      { id: 1, nombre: "Gadget" },
    ],
  });

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };
  const showNotificationError = (message) => {
    configNotification("error", "warning", "Notificación", message, "", true);
  };
  const showNotificationSuccess = (message) => {
    configNotification("warning", "check", "Notificación", message, "", true);
  };

  const saveData = async () => {
    setLoad(true);
    try {
      const HttpVerb = elementIsNew ? "POST" : "PATCH";
      const HttpUrl = elementIsNew
        ? "/api/Manager/Gadget"
        : `/api/Manager/Gadget?ApplicationId=${newValueApplicationId}`;

      const body = {
        ApplicationId: newValueApplicationId,
        Name: newValueName,
        Description: newValueDescription,
        Url: newValueUrl,
        Active: newValueActive,
        Password: newValuePassword,
        Alias: newValueAlias,
        CountryId: newValuePais.id,
        Type: newValueType.id,
      };

      const apiResponse = await APIRequest(HttpVerb, HttpUrl, body);

      if (apiResponse.status === 200 || apiResponse.status === 201 || apiResponse.ok) {
        showNotificationSuccess("Se guardó el gadget exitosamente");

        const tempElement = {
          applicationId: newValueApplicationId,
          active: newValueActive,
          alias: newValueAlias,
          country: newValuePais,
          description: newValueDescription,
          name: newValueName,
          password: newValuePassword,
          type: newValueType,
          url: newValueUrl,
        };

        navigate(route, { state: { newElement: tempElement } });
      } else {
        showNotificationError(`Error, estado: ${apiResponse.status}`);
      }
    } catch {
      showNotificationError("Error Inesperado");
    }

    setLoad(false);
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );

  const setEditJsonData = (jsonElement) => {
    if (element) {
      setNewValueApplicationId(jsonElement.applicationId);
      setNewValuePais(jsonElement.country);
      setNewValueAlias(jsonElement.alias);
      setNewValueDescription(jsonElement.description);
      setNewValueName(jsonElement.name);
      setNewValuePassword(jsonElement.password);
      setNewValueType(jsonElement.type);
      setNewValueUrl(jsonElement.url);
      setNewValueActive(jsonElement.active);
    }
  };

  const getCountriesData = async () => {
    const tempJsonData = [];
    const apiResponse = await (await APIRequest("GET", "/api/Pais/Todos", null)).json();

    if (apiResponse.length > 0) {
      for (let index = 0; index < apiResponse.length; index += 1) {
        tempJsonData.push({
          id: apiResponse[index].codigo.toLowerCase(),
          nombre: apiResponse[index].nombre,
        });
      }

      setSelectData({
        pais: tempJsonData,
        type: selectData.type,
      });
    }

    return tempJsonData;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    saveData();
  };

  useEffect(async () => {
    await getCountriesData();
    setElementIsNew(!element);
    setEditJsonData(element);
  }, [pathname]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={3}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <MDBox>
              <MDTypography variant="subtitle" fontWeight="bold">
                <Icon
                  sx={{ cursor: "pointer", fontWeight: "bold" }}
                  onClick={() => navigate(route)}
                >
                  arrow_back
                </Icon>
                {"   "}
                Manager - Gadget
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <Box sx={{ width: "100%" }}>
                <Card>
                  <MDBox
                    sx={{ position: "relative", mx: 3, py: 2, px: 2 }}
                    component="form"
                    onSubmit={handleSubmit}
                  >
                    <Grid
                      container
                      spacing={3}
                      justifyContent="left"
                      alignItems="left"
                      height="100%"
                    >
                      <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                        <Autocomplete
                          value={newValuePais}
                          onChange={(event, newValue) => {
                            setNewValuePais(newValue);
                          }}
                          options={selectData.pais}
                          getOptionLabel={(option) => option.nombre}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          // disabled={!elementIsNew}
                          renderInput={(params) => (
                            <MDInput
                              variant="standard"
                              type="text"
                              label="Pais"
                              fullWidth
                              required
                              InputLabelProps={{ shrink: true }}
                              {...params}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                        <MDInput
                          variant="standard"
                          type="text"
                          label="Alias"
                          fullWidth
                          required
                          // disabled={!elementIsNew}
                          InputLabelProps={{ shrink: true }}
                          value={newValueAlias}
                          onChange={(e) => {
                            setNewValueAlias(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                        <MDInput
                          variant="standard"
                          type="text"
                          label="Nombre"
                          fullWidth
                          required
                          // disabled={!elementIsNew}
                          InputLabelProps={{ shrink: true }}
                          value={newValueName}
                          onChange={(e) => {
                            setNewValueName(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                        <MDInput
                          variant="standard"
                          type="text"
                          label="Descripción"
                          fullWidth
                          required
                          // disabled={!elementIsNew}
                          InputLabelProps={{ shrink: true }}
                          value={newValueDescription}
                          onChange={(e) => {
                            setNewValueDescription(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                        <MDInput
                          variant="standard"
                          type="password"
                          label="Contraseña"
                          fullWidth
                          required
                          // disabled={!elementIsNew}
                          InputLabelProps={{ shrink: true }}
                          value={newValuePassword}
                          onChange={(e) => {
                            setNewValuePassword(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                        <Autocomplete
                          value={newValueType}
                          onChange={(event, newValue) => {
                            setNewValueType(newValue);
                          }}
                          options={selectData.type}
                          getOptionLabel={(option) => option.nombre}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          // disabled={!elementIsNew}
                          renderInput={(params) => (
                            <MDInput
                              variant="standard"
                              type="text"
                              label="Tipo"
                              fullWidth
                              required
                              InputLabelProps={{ shrink: true }}
                              {...params}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                        <MDInput
                          variant="standard"
                          type="text"
                          label="Url"
                          fullWidth
                          required
                          // disabled={!elementIsNew}
                          InputLabelProps={{ shrink: true }}
                          value={newValueUrl}
                          onChange={(e) => {
                            setNewValueUrl(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                        <Tooltip
                          title={newValueActive ? "Deshabilitar" : "Habilitar"}
                          disableInteractive
                        >
                          <Switch
                            // disabled={!elementIsNew}
                            checked={newValueActive}
                            onChange={() => setNewValueActive(!newValueActive)}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        </Tooltip>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={4}
                        lg={5}
                        xl={2}
                        container
                        justifyContent="flex-end"
                      >
                        <MDButton
                          variant="contained"
                          type="submit"
                          sx={{
                            background: "linear-gradient(45deg, #099268 30%, #099268 90%)",
                            color: "#FFFFFF",
                          }}
                        >
                          <Icon>save</Icon>&nbsp;Guardar Gadget
                        </MDButton>
                      </Grid>
                    </Grid>
                  </MDBox>
                </Card>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {renderNotificacion}
      <Loading load={load} />
      <Footer />
    </DashboardLayout>
  );
}

export default GadgetForm;
