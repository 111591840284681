import React, { useState } from "react";
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";
import Collapse from "@mui/material/Collapse";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Card } from "@mui/material";

function XposCollapse({ title, open, children, ...rest }) {
  const [styleCard, setStyleCard] = useState({});
  return (
    <MDBox mb={2}>
      <Card style={styleCard}>
        <MDBox
          {...rest}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          px={3}
          py={2}
          color="black"
          opacity={1}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h5" color={open ? "dark" : "text"} sx={{ userSelect: "none" }}>
            {title}
          </MDTypography>
          <MDBox color={open ? "dark" : "text"}>
            <Icon sx={{ fontWeight: "bold" }} fontSize="small">
              {open ? "keyboard_arrow_up" : "keyboard_arrow_down"}
            </Icon>
          </MDBox>
        </MDBox>
      </Card>
      <Collapse
        in={open}
        onExited={() => setStyleCard({})}
        onEntering={() =>
          setStyleCard({
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          })
        }
      >
        <Card
          style={{
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }}
        >
          <MDBox p={2} lineHeight={1}>
            <MDTypography variant="button" color="text" opacity={0.8} fontWeight="regular">
              {children}
            </MDTypography>
          </MDBox>
        </Card>
      </Collapse>
    </MDBox>
  );
}

XposCollapse.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default XposCollapse;
