import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import { InputAdornment } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import MDInput from "../../../../../components/MDInput";
import useCertificate from "./useCertificate";

function useCreateOrganizationPY({ organization }) {
  const [codigoValue, setCodigoValue] = useState(organization?.code ?? "");
  const [codigoSeguridadValue, setCodigoSeguridadValue] = useState("");
  const [showSecurityCode, setShowSecurityCode] = useState(false);
  const certificate = useCertificate();

  const clearFields = () => {
    setCodigoValue("");
    setCodigoSeguridadValue("");
    certificate?.clearFields();
  };

  const organizationData = {
    SecurityCode: codigoSeguridadValue,
    Code: codigoValue,
    ...certificate?.organizationData,
  };

  const buttonDisabled =
    codigoValue === "" || codigoSeguridadValue === "" || certificate?.buttonDisabled;

  const handleSecurityCodeVisibility = () => {
    setShowSecurityCode(!showSecurityCode);
  };

  const endAdornmentSc = (
    <InputAdornment position="end">
      <IconButton onClick={handleSecurityCodeVisibility}>
        {showSecurityCode ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </InputAdornment>
  );

  const getComponent = () => (
    <>
      <Grid item xs={12} md={5.5}>
        <MDInput
          label="Id Código"
          placeholder="Ingrese"
          type="text"
          value={codigoValue}
          onChange={(event) => setCodigoValue(event.target.value)}
          size="medium"
          variant="standard"
          fullWidth
          required
          InputLabelProps={{ shrink: true }}
          inputProps={{ maxLength: 4 }}
        />
      </Grid>

      <Grid item xs={12} md={5.5}>
        <MDInput
          label="Código de seguridad (CSC)"
          placeholder="Ingrese"
          type={showSecurityCode ? "text" : "password"}
          onChange={(event) => setCodigoSeguridadValue(event.target.value)}
          size="medium"
          variant="standard"
          fullWidth
          required
          InputLabelProps={{ shrink: true }}
          InputProps={{
            inputProps: {
              maxLength: 32,
            },
            endAdornment: endAdornmentSc,
          }}
        />
      </Grid>
      {certificate?.getComponent()}
    </>
  );

  return {
    organizationData,
    clearFields,
    getComponent,
    buttonDisabled,
  };
}

export default useCreateOrganizationPY;
