/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { useState } from "react";

// @mui material components
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Chip from "@mui/material/Chip";
import { Button, Menu, MenuItem, Select } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

// Default Components
import useNotification from "../../_hooks/useNotification";
import Notification from "../../_components/shared/Notification";

const statusType = Object.freeze({
  active: "Activo",
  blocked: "Bloqueado",
  inactive: "Inactivo",
});

export default function data(
  jsonData,
  setEditJsonData,
  setDeleteJsonData,
  setDisabledJsonData,
  setEnabledJsonData,
  setResetJsonData,
  handleEnviarXPOS,
  abrirModalLiberarFolios
) {
  const [selectedUmbral, setSelectedUmbral] = useState(undefined);
  const [menuActive, setMenuActive] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const { notification, showNotificationSuccess, setNotification } = useNotification();

  const handleCopy = (event, value) => {
    const textToCopy = typeof value === "string" ? value.toString() : value.props.children;
    navigator.clipboard.writeText(textToCopy);
    showNotificationSuccess("El elemento se ha copiado exitosamente");
  };

  const handleClick = (id, event) => {
    setMenuActive(id);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => setMenuActive("");

  const getRows = () => {
    const elements = [];

    for (let pos = 0; pos < jsonData.length; pos += 1) {
      const options = jsonData[pos].folios.map((elemento) => ({
        label: `${elemento.typeDte}: Umbral: ${elemento.umbralValue}${elemento.typeUmbral === "quantity" ? "" : "%"
          }`,
        value: elemento,
        key: `${jsonData[pos].guid}`,
      }));

      let status;
      let color;
      const menuId = `user-menu-${jsonData[pos].guid}-${status}`;

      if (jsonData[pos].status === 0) {
        status = statusType.inactive;
        color = "#fab005";
      } else if (jsonData[pos].status === 1) {
        status = statusType.active;
        color = "#2f9e44";
      } else {
        status = statusType.blocked;
        color = "#228be6";
      }

      elements.push({
        id: jsonData[pos].guid,
        taxId: jsonData[pos].taxId,
        idXPos: (
          <div className="copy-container" style={{ alignItems: "center" }}>
            <Tooltip title={jsonData[pos].guid} disableInteractive>
              <span style={{ marginRight: 4 }}>{`${jsonData[pos].guid.split("-")[0]}-${jsonData[pos].guid.split("-")[1]
                }...`}</span>
            </Tooltip>
            <Icon
              fontSize="small"
              style={{ cursor: "pointer" }}
              onClick={(event) => handleCopy(event, jsonData[pos].guid)}
            >
              copy
            </Icon>
          </div>
        ),
        nombrexPos: jsonData[pos].name,
        fechaCreacion: jsonData[pos].creationDate.split("T")[0],
        umbral: !!options.length && (
          <div className="form-item">
            {options.length === 1 ? (
              options[0].label
            ) : (
              <Select
                variant="standard"
                fullWidth
                size="small"
                onChange={(event) => setSelectedUmbral(event.target.value)}
                value={selectedUmbral === undefined ? options[0].label : selectedUmbral}
                SelectDisplayProps={{
                  style: {
                    display: "flex",
                    justifyContent: "left",
                    paddingBottom: "10px",
                    paddingTop: "10px",
                    color: "black",
                  },
                }}
                defaultValue={options[0].label}
              >
                {options.map((item) => (
                  <MenuItem
                    disabled
                    key={`${item.key}-${item.label}`}
                    style={{
                      backgroundColor: "none",
                      color: "black",
                      display: "flex",
                      justifyContent: "center",
                      opacity: "100%",
                    }}
                    value={item.label}
                  >
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            )}
          </div>
        ),
        certificate: jsonData[pos].signingCertificate,
        usuario: jsonData[pos].userLastAction,
        globalConfigUpdater: jsonData[pos].globalConfigUpdater === 2 ? "Si" : "No",
        status: (
          <Chip
            label={status}
            style={{ backgroundColor: `${color}`, color: "white", width: "90px" }}
          />
        ),
        action: (
          <div>
            {notification && (
              <Notification notification={notification} setNotification={setNotification} />
            )}
            <Tooltip
              key={`${jsonData[pos].guid}-${status}-tooltip`}
              style={{ cursor: "pointer" }}
              title="Acciones"
              disableInteractive
            >
              <Button
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={(event) => handleClick(menuId, event)}
                size="large"
              >
                <MoreVertIcon style={{ color: "#344767" }} />
              </Button>
            </Tooltip>
            <Menu
              key={`${jsonData[pos].guid}-${status}`}
              id={menuId}
              anchorEl={anchorEl}
              open={menuActive === menuId}
              onClose={() => handleClose()}
            >
              {status === statusType.active && (
                <MenuItem
                  className="MuiMenuItem-root"
                  onClick={() => {
                    setResetJsonData(jsonData[pos]);
                    setMenuActive(null);
                  }}
                >
                  <Icon
                    fontSize="10px"
                    style={{
                      paddingRight: "30px",
                      paddingLeft: "10px",
                    }}
                  >
                    restart_alt
                  </Icon>
                  Reiniciar
                </MenuItem>
              )}

              {status === statusType.active ? (
                <MenuItem
                  className="MuiMenuItem-root"
                  onClick={() => {
                    setDisabledJsonData(jsonData[pos]);
                    setMenuActive(null);
                  }}
                >
                  <Icon
                    fontSize="10px"
                    style={{
                      paddingRight: "30px",
                      paddingLeft: "10px",
                    }}
                  >
                    phonelink_off
                  </Icon>
                  Bloquear
                </MenuItem>
              ) : (
                status === statusType.blocked && (
                  <MenuItem
                    className="MuiMenuItem-root"
                    onClick={() => {
                      setEnabledJsonData(jsonData[pos]);
                      setMenuActive(null);
                    }}
                  >
                    <Icon
                      fontSize="10px"
                      style={{
                        paddingRight: "30px",
                        paddingLeft: "10px",
                      }}
                    >
                      phonelink
                    </Icon>
                    Desbloquear
                  </MenuItem>
                )
              )}

              <MenuItem
                className="MuiMenuItem-root"
                onClick={() => {
                  setDeleteJsonData(jsonData[pos]);
                  setMenuActive(null);
                }}
              >
                <Icon
                  fontSize="10px"
                  style={{
                    paddingRight: "30px",
                    paddingLeft: "10px",
                  }}
                >
                  delete
                </Icon>
                Eliminar
              </MenuItem>

              {status !== statusType.blocked && (
                <MenuItem
                  className="MuiMenuItem-root"
                  onClick={() => {
                    setEditJsonData(jsonData[pos]);
                    setMenuActive(null);
                  }}
                >
                  <Icon
                    fontSize="10px"
                    style={{
                      paddingRight: "30px",
                      paddingLeft: "10px",
                    }}
                  >
                    edit
                  </Icon>
                  Editar
                </MenuItem>
              )}
              <MenuItem
                className="MuiMenuItem-root"
                onClick={() => {
                  handleEnviarXPOS(jsonData[pos]);
                  setMenuActive(null);
                }}
              >
                <Icon
                  fontSize="10px"
                  style={{
                    paddingRight: "30px",
                    paddingLeft: "10px",
                  }}
                >
                  content_copy
                </Icon>
                Clonar entre ambientes
              </MenuItem>
              <MenuItem
                className="MuiMenuItem-root"
                onClick={() => {
                  abrirModalLiberarFolios(jsonData[pos]);
                  handleClose();
                }}
              >
                <Icon
                  fontSize="10px"
                  style={{
                    paddingRight: "30px",
                    paddingLeft: "10px",
                  }}
                >
                  receipt_long
                </Icon>
                Liberar Folios
              </MenuItem>
            </Menu>
          </div>
        ),
      });
    }

    return elements;
  };

  return {
    columns: [
      { Header: "TaxID", accessor: "taxId", align: "center" },
      { Header: "ID xPOS", accessor: "idXPos", align: "center" },
      { Header: "Nombre xPOS", accessor: "nombrexPos", align: "center" },
      { Header: "Fecha creación", accessor: "fechaCreacion", align: "center" },
      { Header: "Umbral", accessor: "umbral", align: "center" },
      { Header: "Certificado de firma", accessor: "certificate", align: "center" },
      { Header: "Usuario ultima acción", accessor: "usuario", align: "center" },
      { Header: "Configuración Personalizada", accessor: "globalConfigUpdater", align: "center" },
      { Header: "Estado", accessor: "status", align: "center" },
      { Header: "Acciones", accessor: "action", align: "center" },
    ],

    rows: getRows(),
  };
}

// Setting default values for the props of MDBadge
data.defaultProps = {
  jsonData: [],
};

// Typechecking props of the data
data.propTypes = {
  jsonData: PropTypes.objectOf(PropTypes.array),
  setEditJsonData: PropTypes.func.isRequired,
  setDeleteJsonData: PropTypes.func.isRequired,
  setDisabledJsonData: PropTypes.func.isRequired,
  setEnabledJsonData: PropTypes.func.isRequired,
  setResetJsonData: PropTypes.func.isRequired,
};
