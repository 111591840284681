/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// @mui material components
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// API Request
import APIRequestGeneric from "api/requestGeneric";

function AltaEmpresaProdForm() {
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  // const [jsonData, setJsonData] = useState([]);
  const { pathname } = useLocation();
  const {
    state: { route, element },
  } = useLocation();
  const navigate = useNavigate();

  const [elementIsNew, setElementIsNew] = useState(true);
  const [newValueRuc, setNewValueRuc] = useState("");
  const [newValueDigitoVerificador, setNewValueDigitoVerificador] = useState("");
  const [newValueQR, setNewValueQR] = useState("");
  const [newValueTipo, setNewValueTipo] = useState({ id: "Juridico", nombre: "Jurídico" });
  // eslint-disable-next-line no-unused-vars
  const [selectData, setSelectData] = useState({
    status: [
      { id: 1, nombre: "Alta" },
      { id: 2, nombre: "" },
    ],
    type: [
      { id: "Juridico", nombre: "Jurídico" },
      { id: "Natural", nombre: "Natural" },
    ],
  });

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };
  const showNotificationError = (message) => {
    configNotification("error", "warning", "Notificación", message, "", true);
  };
  const showNotificationSuccess = (message) => {
    configNotification("warning", "check", "Notificación", message, "", true);
  };

  const setAfiliacion = async () => {
    const host = process.env.REACT_APP_API_URL;
    const url = "/api/taxAgency/taxpayer/affiliation";
    const apiKey = process.env.REACT_APP_API_KEY_PAC_URL;
    const urlComplete = `${host}${url}?code=${apiKey}`;
    const body = {
      issuerRuc: newValueRuc,
      issuerCheckDigit: newValueDigitoVerificador,
      taxpayerType: newValueTipo.id, // "Juridico",
    };
    const apiResponse = await APIRequestGeneric("POST", urlComplete, body, true, null);

    if (apiResponse === null) {
      return false;
    }
    if (apiResponse.status === 200) {
      // 200 -  0828 - Solicitud recibida, el contribuyente debe aprobar o rechazar por medio de la sección “afiliación contribuyente -confirmación de PAC” dentro de Factura Electrónica en eTax2.0.
      const dataResponse = await apiResponse.json();

      console.log(dataResponse);
      return true;
      // eslint-disable-next-line no-else-return
    } else {
      // 400 - Uno o varios datos de la solicitud son inválidos. Proporcione los valores esperados y luego intente la solicitud nuevamente.
      // 406 - La Dirección General de Ingresos (DGI) no aceptó la solicitud por el siguiente motivo: Datos de entrada Invalidos. Código del motivo: 0820
      // 0825 - RUC no posee resolución de autorización de uso de factura electrónica.
      // 500 - Uno o varios errores se detectaron durante el procesamiento de la solicitud. Intente nuevamente en unos minutos. Si la respuesta no ha cambiado, informe al equipo de soporte para verificar el caso.
      // 502 - No se pudo establecer comunicación con el servidor remoto de los servicios de la DGI. Es posible que los servicios estén disponibles en los próximos minutos.
      // 504 - Se agotó el tiempo de espera para la respuesta por parte de los servicios de la DGI. Intente realizar la solicitud nuevamente en unos minutos.
      const dataResponse = await apiResponse.json();

      const errorMessage = {
        code: apiResponse.status,
        message: `Error: ${dataResponse.message}`,
      };
      throw errorMessage;
      // return false;
    }
  };
  const updateQRCode = async (RUC, codeQR, tipo) => {
    const host = process.env.REACT_APP_API_URL;
    // const url = `/api/affiliates/taxpayer/number/${RUC}/type/Juridico`;
    const url = `/api/affiliates/taxpayer/number/${RUC}/type/${tipo}`;
    const apiKey = process.env.REACT_APP_API_KEY_PAC_ADMIN;
    const urlComplete = `${host}${url}?code=${apiKey}`;
    const body = {
      securityCode: codeQR,
      updatedBy: userInfo.correo,
    };
    const apiResponse = await APIRequestGeneric("PUT", urlComplete, body, true, null);
    // const dataResponse = await apiResponse.json();

    if (apiResponse === null) {
      return false;
    }
    if (apiResponse.status === 200) {
      return true;
    }

    return false;
  };
  const saveDataModal = async () => {
    // const tempJsonData = jsonData;

    try {
      // const today = new Date();
      if (elementIsNew) {
        if (await setAfiliacion()) {
          /* const newTempElement = {
            ruc: newValueRuc,
            digitoVerificador: newValueDigitoVerificador,
            status: { id: 2, nombre: "" },
            user: userInfo.correo,
            qr: "",
            tipo: newValueTipo,
            fechaUpdate: `${today.getFullYear()}-${(today.getMonth() + 1)
              .toString()
              .padStart(2, "0")}-${today.getDate().toString().padStart(2, "0")}`,
          };

          tempJsonData.push(newTempElement); */

          showNotificationSuccess(`Se afilió el '${newValueRuc}' exitosamente.`);
        } else {
          showNotificationError(`Error al realizar la afiliación`);
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (await updateQRCode(newValueRuc, newValueQR, newValueTipo.id)) {
          /* const index = tempJsonData.map((item) => item.ruc).indexOf(newValueRuc);

          const newTempElement = {
            ruc: newValueRuc,
            digitoVerificador: newValueDigitoVerificador,
            status: { id: 1, nombre: "Alta" },
            user: userInfo.correo,
            qr: newValueQR,
            tipo: newValueTipo,
            fechaUpdate: `${today.getFullYear()}-${(today.getMonth() + 1)
              .toString()
              .padStart(2, "0")}-${today.getDate().toString().padStart(2, "0")}`,
          };

          tempJsonData[index] = newTempElement; */

          showNotificationSuccess(`Se actualizó el código de seguridad exitosamente`);
        } else {
          showNotificationError(`Ocurrió un error al actualizar el código de seguridad`);
        }
      }

      // setJsonData(tempJsonData);
    } catch (e) {
      showNotificationError(`Ocurrió un error: ${e.message}`);
    }
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );

  const setEditJsonData = (jsonElement) => {
    if (element) {
      setNewValueRuc(jsonElement.ruc);
      setNewValueDigitoVerificador(jsonElement.digitoVerificador);
      setNewValueQR(jsonElement.qr);
      setNewValueTipo(jsonElement.tipo);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    saveDataModal();
  };

  useEffect(() => {
    setElementIsNew(!element);
    setEditJsonData(element);
  }, [pathname]);

  return (
    <DashboardLayout>
      <DashboardNavbar breadCrumb={route.split("/").slice(1)} />
      <MDBox pt={3} pb={3} component="form" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MDBox pb={4}>
              <MDTypography variant="subtitle" fontWeight="bold">
                <Icon
                  sx={{ cursor: "pointer", fontWeight: "bold" }}
                  onClick={() => navigate(route)}
                >
                  arrow_back
                </Icon>
                {"   "}
                Dar Alta
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox sx={{ position: "relative", mx: 3, py: 2, px: 2 }}>
                <Grid container spacing={3} justifyContent="left" alignItems="left" height="100%">
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <MDInput
                      variant="standard"
                      type="text"
                      label="RUC"
                      fullWidth
                      required
                      disabled={!elementIsNew}
                      InputLabelProps={{ shrink: true }}
                      value={newValueRuc}
                      onChange={(e) => {
                        setNewValueRuc(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <MDInput
                      variant="standard"
                      type="text"
                      label="Digito Verificador"
                      fullWidth
                      required
                      disabled={!elementIsNew}
                      InputLabelProps={{ shrink: true }}
                      value={newValueDigitoVerificador}
                      onChange={(e) => {
                        setNewValueDigitoVerificador(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <Autocomplete
                      value={newValueTipo}
                      onChange={(event, newValue) => {
                        setNewValueTipo(newValue);
                      }}
                      options={selectData.type}
                      disabled={!elementIsNew}
                      getOptionLabel={(option) => option.nombre}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderInput={(params) => (
                        <MDInput
                          variant="standard"
                          type="text"
                          label="Tipo de Contribuyente"
                          fullWidth
                          required
                          disabled={!elementIsNew}
                          InputLabelProps={{ shrink: true }}
                          {...params}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <MDInput
                      variant="standard"
                      type="text"
                      label="Codigo Seguridad QR"
                      fullWidth
                      disabled={elementIsNew}
                      InputLabelProps={{ shrink: true }}
                      value={newValueQR}
                      onChange={(e) => {
                        setNewValueQR(e.target.value);
                      }}
                      sx={{ display: elementIsNew ? "none" : "flex" }}
                    />
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="left" alignItems="left" height="100%">
              <Grid item xs={12} sm={12} md={6} lg={4} xl={10} />
              <Grid item xs={12} sm={12} md={6} lg={4} xl={2} container justifyContent="flex-end">
                <MDButton
                  variant="contained"
                  type="submit"
                  sx={{
                    background: "linear-gradient(45deg, #099268 30%, #099268 90%)",
                    color: "#FFFFFF",
                  }}
                >
                  <Icon>save</Icon>&nbsp;Guardar
                </MDButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
      {renderNotificacion}
      <Footer />
    </DashboardLayout>
  );
}

export default AltaEmpresaProdForm;
