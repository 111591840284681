import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Switch from "@mui/material/Switch";
import Autocomplete from "@mui/material/Autocomplete";
// import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import Loading from "components/Loading";
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import BKModal from "components/BKModal";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DataTable from "examples/Tables/DataTable";

// Data
import dataColumns from "views/manager/vinculo/data";

// API
import APIRequest from "api/request";

function VinculoEmpresa() {
  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  const [modal, setModal] = useState(false);
  const { pathname } = useLocation();

  const [userEmpresa, setUserEmpresa] = useState([]);
  const [elementIsNew, setElementIsNew] = useState(true);
  const [newUserEmail, setNewUserEmail] = useState("");
  const [newEmpresaCodigo, setNewEmpresaCodigo] = useState("");
  const [newPrimario, setNewPrimario] = useState(false);
  const [newAdministrador, setNewAdministrador] = useState(false);
  const [newUserEmpresaId, setNewUserEmpresaId] = useState("");

  const [newFilterPais, setNewFilterPais] = useState({ id: "cl", nombre: "Chile" });
  const [newFilterUser, setNewFilterUser] = useState("");
  const [newFilterEmpresa, setNewFilterEmpresa] = useState("");

  const [selectData, setSelectData] = useState({
    pais: [
      { id: "cr", nombre: "Costa rica" },
      { id: "ec", nombre: "Ecuador" },
      { id: "pe", nombre: "Perú" },
      { id: "cl", nombre: "Chile" },
      { id: "mx", nombre: "México" },
      { id: "gt", nombre: "Guatemala" },
      { id: "co", nombre: "Colombia" },
      { id: "pa", nombre: "Panamá" },
    ],
  });

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };
  const showNotificationSuccess = (message) => {
    setNotification({
      color: "success",
      icon: "check",
      title: "Notificación",
      content: message,
      dateTime: "",
      show: true,
    });
  };
  const showNotificationError = (message) => {
    setNotification({
      color: "error",
      icon: "warning",
      title: "Notificación",
      content: message,
      dateTime: "",
      show: true,
    });
  };
  const closeModal = () => {
    setModal(false);
    setElementIsNew(true);
    setNewUserEmail("");
    setNewEmpresaCodigo("");
    setNewPrimario(false);
    setNewAdministrador(false);
    setNewUserEmpresaId("");
  };

  // Datos por pestaña
  const [loading, setLoading] = useState(false);
  const SearchVinculo = async () => {
    setLoading(true);

    try {
      const apiEmpresa = await APIRequest(
        "GET",
        `/api/Manager/Empresa?Code=${newFilterEmpresa}&CountryId=${newFilterPais.id}`,
        null
      );
      const users = await (
        await APIRequest("POST", "/api/Manager/User/Todos", { email: newFilterUser })
      ).json();
      if (apiEmpresa.status !== 204 && users.length > 0) {
        const empresa = await apiEmpresa.json();
        const apiResponse = await (
          await APIRequest("POST", `/api/Manager/UserEmpresa/Todos?countryId=${newFilterPais.id}`, {
            UserId: users[0].userId,
            AccountId: empresa.accountId,
          })
        ).json();
        if (apiResponse.length > 0) {
          setUserEmpresa(apiResponse);
        }
      }
      setLoading(false);
    } catch (error) {
      showNotificationError(error.data);
      setLoading(false);
    }
  };
  const saveDataModal = async () => {
    setLoading(true);
    try {
      const users = await (
        await APIRequest("POST", "/api/Manager/User/Todos", { email: newUserEmail })
      ).json();
      if (users == null || users.length === 0) {
        const errorMessage = {
          code: 400,
          message: `No se encontró el usuario`,
        };
        throw errorMessage;
      }
      const apiEmpresa = await APIRequest(
        "GET",
        `/api/Manager/Empresa?Code=${newEmpresaCodigo}&CountryId=${newFilterPais.id}`,
        // `/api/Manager/Empresa?Code=${newEmpresaCodigo}&CountryId=${users[0].currentCountryId}`,
        null
      );
      if (apiEmpresa.status === 204) {
        const errorMessage = {
          code: 400,
          message: `No se encontró la empresa`,
        };
        throw errorMessage;
      }
      const empresa = await apiEmpresa.json();
      let tempJsonData = userEmpresa;
      const body = {
        userId: users[0].userId,
        accountId: empresa.accountId,
        isPrimary: newPrimario,
        isAdmin: newAdministrador,
      };
      let apiResponse = null;

      if (elementIsNew) {
        apiResponse = await APIRequest(
          "POST",
          `/api/Manager/UserEmpresa?countryId=${newFilterPais.id}`,
          // `/api/Manager/UserEmpresa?countryId=${users[0].currentCountryId}`,
          body
        );
      } else {
        apiResponse = await APIRequest(
          "PATCH",
          `/api/Manager/UserEmpresa/${newUserEmpresaId}?countryId=${newFilterPais.id}`,
          // `/api/Manager/UserEmpresa/${newUserEmpresaId}?countryId=${users[0].currentCountryId}`,
          body
        );
      }

      if (apiResponse.status === 200) {
        const dataResponse = await apiResponse.json();
        if (elementIsNew) {
          const newElement = {
            userAccountId: dataResponse,
            code: empresa.code,
            accountName: empresa.name,
            email: newUserEmail,
            userName: users[0].name,
            isPrimary: newPrimario,
            isAdmin: newAdministrador,
          };
          const datatemp = [newElement, ...tempJsonData];
          tempJsonData = [...datatemp];
        } else {
          const index = userEmpresa.map((item) => item.userAccountId).indexOf(newUserEmpresaId);
          const element = {
            userAccountId: dataResponse,
            code: empresa.code,
            accountName: empresa.name,
            email: newUserEmail,
            userName: users[0].name,
            isPrimary: newPrimario,
            isAdmin: newAdministrador,
          };
          tempJsonData[index] = element;
        }
        showNotificationSuccess("Se completó el registro exitosamente");
      } else {
        const errorMessage = {
          code: apiResponse.status,
          message: `Error: ${apiResponse.status}`,
        };
        throw errorMessage;
      }

      setUserEmpresa(tempJsonData);
      closeModal();
    } catch (e) {
      showNotificationError(`Ocurrió un error: ${e.message}`);
    }
    setLoading(false);
  };
  const openAddNewElementModal = () => {
    setElementIsNew(true);
    setNewUserEmail("");
    setNewEmpresaCodigo("");
    setNewPrimario(false);
    setNewAdministrador(false);
    setModal(true);
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );
  const renderModal = (
    <BKModal closeModal={closeModal} saveChanges={saveDataModal} open={modal} title="Crear Vínculo">
      <MDBox sx={{ position: "relative", mx: 3, py: 2, px: 2 }}>
        <Grid container spacing={3} justifyContent="left" alignItems="left" height="100%">
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <Autocomplete
              value={newFilterPais}
              onChange={(event, newValue) => {
                setNewFilterPais(newValue);
              }}
              options={selectData.pais}
              getOptionLabel={(option) => option.nombre}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              disabled={!elementIsNew}
              renderInput={(params) => (
                <MDInput
                  variant="standard"
                  type="text"
                  label="Pais"
                  fullWidth
                  required
                  InputLabelProps={{ shrink: true }}
                  disabled={!elementIsNew}
                  {...params}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <MDInput
              fullWidth
              variant="standard"
              type="text"
              label="Correo usuario"
              required
              InputLabelProps={{ shrink: true }}
              disabled={!elementIsNew}
              value={newUserEmail}
              onChange={(e) => {
                setNewUserEmail(e.target.value);
              }}
            />
          </Grid>
          {/* <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <MDInput
                      fullWidth
                      variant="standard"
                      type="text"
                      label="Nombre usuario"
                      required
                      InputLabelProps={{ shrink: true }}
                    />
          </Grid> */}
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <MDInput
              fullWidth
              variant="standard"
              type="text"
              label="RUT Empresa"
              required
              InputLabelProps={{ shrink: true }}
              value={newEmpresaCodigo}
              disabled={!elementIsNew}
              onChange={(e) => {
                setNewEmpresaCodigo(e.target.value);
              }}
            />
          </Grid>
          {/* <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <MDInput
                      fullWidth
                      variant="standard"
                      type="text"
                      label="Nombre de empresa"
                      required
                      InputLabelProps={{ shrink: true }}
                    />
          </Grid> */}
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <MDBox
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              width={{ xs: "100%", sm: "auto" }}
              mt={{ xs: 1, sm: 0 }}
            >
              <MDBox lineHeight={0} mx={2}>
                <MDTypography variant="button" color="text">
                  Primario
                </MDTypography>
              </MDBox>
              <MDBox mr={1}>
                <Switch
                  checked={newPrimario}
                  onChange={() => {
                    setNewPrimario(!newPrimario);
                    if (newAdministrador && !newPrimario) {
                      setNewAdministrador(false);
                    }
                  }}
                  // onChange={(e) => setNewPrimario(e.target.value)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <MDBox
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              width={{ xs: "100%", sm: "auto" }}
              mt={{ xs: 1, sm: 0 }}
            >
              <MDBox lineHeight={0} mx={2}>
                <MDTypography variant="button" color="text">
                  Administrador
                </MDTypography>
              </MDBox>
              <MDBox mr={1}>
                <Switch
                  checked={newAdministrador}
                  onChange={() => {
                    setNewAdministrador(!newAdministrador);
                    if (!newAdministrador && newPrimario) {
                      setNewPrimario(false);
                    }
                  }}
                  // onChange={(e) => setNewAdministrador(e.target.value)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </MDBox>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </BKModal>
  );

  const getCountriesData = async () => {
    const tempJsonData = [];
    const apiResponse = await (await APIRequest("GET", "/api/Pais/Todos", null)).json();

    if (apiResponse.length > 0) {
      for (let index = 0; index < apiResponse.length; index += 1) {
        tempJsonData.push({
          id: apiResponse[index].codigo.toLowerCase(),
          nombre: apiResponse[index].nombre,
        });
      }

      setSelectData({
        pais: tempJsonData,
      });
    }

    return tempJsonData;
  };
  const setEditJsonData = (valueId) => {
    setElementIsNew(false);
    setNewUserEmpresaId(valueId);
    const index = userEmpresa.map((item) => item.userAccountId).indexOf(valueId);
    setNewUserEmail(userEmpresa[index].email);
    setNewEmpresaCodigo(userEmpresa[index].code);
    setNewPrimario(userEmpresa[index].isPrimary);
    setNewAdministrador(userEmpresa[index].isAdmin);
    setModal(true);
  };
  const deleteJsonData = async ({ id, email }) => {
    if (window.confirm("¿Quiere eliminar el registro?")) {
      setLoading(true);
      try {
        const users = await (await APIRequest("POST", "/api/Manager/User/Todos", { email })).json();
        if (users == null || users.length === 0) {
          const errorMessage = {
            code: 400,
            message: `Error: No se encontró el usuario`,
          };
          setLoading(false);
          throw errorMessage;
        }
        const apiResponse = await APIRequest(
          "DELETE",
          `/api/Manager/UserEmpresa/${id}?countryId=${users[0].currentCountryId}`,
          null
        );

        if (apiResponse.status === 200) {
          const index = userEmpresa.map((item) => item.userAccountId).indexOf(id);
          const elements = [...userEmpresa];
          elements.splice(index, 1);
          setUserEmpresa(elements);
          showNotificationSuccess("Se eliminó el registro exitosamente.");
        } else {
          const errorMessage = {
            code: apiResponse.status,
            message: `Error: ${apiResponse.status}`,
          };
          throw errorMessage;
        }
      } catch (e) {
        showNotificationError(`Ocurrió un error al eliminar el registro: ${e.message}`);
      }
      setLoading(false);
    }
  };

  useEffect(async () => {
    await getCountriesData();
  }, [pathname]);

  const { columns, rows } = dataColumns(userEmpresa, setEditJsonData, deleteJsonData);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {renderModal}
      <MDBox mb={4}>
        {renderNotificacion}
        <Loading load={loading} />
        <MDBox display="flex" justifyContent="space-between" alignItems="center">
          <Grid>
            <MDTypography fontWeight="bold" variant="h5" color="dark" noWrap>
              Administración Vínculos Usuarios-Empresas
            </MDTypography>
          </Grid>
        </MDBox>
      </MDBox>
      <MDBox py={1}>
        <Card>
          <MDBox px={4} pt={3} pb={4}>
            <MDBox sx={{ position: "relative", mx: 3, py: 2, px: 2 }}>
              <Grid container spacing={3} justifyContent="left" alignItems="left" height="100%">
                <Grid item xs={12}>
                  <Grid
                    item
                    sm={12}
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    sx={{ "& > button": { m: 1 } }}
                  >
                    <MDButton
                      variant="contained"
                      sx={{
                        background: "linear-gradient(45deg, #1971C2 30%, #1971C2 90%)",
                        color: "#FFFFFF",
                      }}
                      onClick={openAddNewElementModal}
                    >
                      <Icon>add</Icon>&nbsp;Nuevo vinculo
                    </MDButton>
                    <MDButton
                      variant="contained"
                      disabled
                      sx={{
                        background: "linear-gradient(45deg, #1971C2 30%, #1971C2 90%)",
                        color: "#FFFFFF",
                        display: "none",
                      }}
                    >
                      <Icon>uploadfile</Icon>&nbsp;Agregar desde el archivo
                    </MDButton>
                  </Grid>
                </Grid>
                <Grid item xs={12} container spacing={3} direction="row">
                  <Grid item sm={12} md={6} alignItems="center">
                    <Autocomplete
                      value={newFilterPais}
                      onChange={(event, newValue) => {
                        setNewFilterPais(newValue);
                      }}
                      options={selectData.pais}
                      getOptionLabel={(option) => option.nombre}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderInput={(params) => (
                        <MDInput
                          variant="standard"
                          type="text"
                          label="Pais"
                          fullWidth
                          required
                          InputLabelProps={{ shrink: true }}
                          {...params}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item sm={12} md={6} alignItems="center">
                    <MDInput
                      fullWidth
                      variant="standard"
                      type="text"
                      label="Email usuario"
                      required
                      InputLabelProps={{ shrink: true }}
                      value={newFilterUser}
                      onChange={(e) => {
                        setNewFilterUser(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item sm={12} md={6} alignItems="center">
                    <MDInput
                      fullWidth
                      variant="standard"
                      type="text"
                      label="RUT Empresa"
                      required
                      InputLabelProps={{ shrink: true }}
                      value={newFilterEmpresa}
                      onChange={(e) => {
                        setNewFilterEmpresa(e.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    item
                    sm={12}
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    sx={{ "& > button": { m: 1 } }}
                  >
                    <MDButton variant="contained" color="info" onClick={() => SearchVinculo()}>
                      <Icon>search</Icon>&nbsp;Buscar
                    </MDButton>
                    <MDButton
                      variant="contained"
                      sx={{
                        background: "linear-gradient(45deg, #FA4343 30%, #FA4343 90%)",
                        color: "#FFFFFF",
                      }}
                    >
                      <Icon>clear</Icon>&nbsp;Limpiar
                    </MDButton>
                  </Grid>
                </Grid>
              </Grid>
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>
      <MDBox py={1}>
        <DataTable
          entriesPerPage={(false, { defaultValue: 10, entries: [5, 10, 25, 50] })}
          canSearch
          showTotalEntries
          table={{ columns, rows }}
          pagination={{ variant: "contained", color: "warning" }}
          isSorted
          noEndBorder
        />
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default VinculoEmpresa;
