import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

export default function SkeletonTable() {
  return (
    <Stack spacing={1}>
      <Skeleton variant="rectangular" width="100%" height={45} />
      <Skeleton variant="rectangular" width="100%" height={45} />
      <Skeleton variant="rectangular" width="100%" height={45} />
    </Stack>
  );
}
