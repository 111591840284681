/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// Default Components
import MDTypography from "components/MDTypography";

export default function data(
  jsonData,
  jsonColumns,
  setJsonData,
  saveChangesStatus,
  setEditMoveFunctionality
) {
  const userType = JSON.parse(sessionStorage.getItem("userType"));

  const getColumnsRows = (index) => {
    const element = {};

    const keyList = Object.keys(jsonData[index]);
    for (let i = 0; i < keyList.length; i += 1) {
      const key = keyList[i];

      if (key.includes("perfil")) {
        element[key] = (
          <Checkbox
            checked={jsonData[index][key]}
            onChange={(e) =>
              setJsonData({
                type: key,
                ind: index,
                payload: e.target.checked,
              })
            }
          />
        );
      } else if (key.includes("estado")) {
        element[key] = (
          <>
            <MDTypography color="text" display={userType === 1 ? "flex" : "none"}>
              <Tooltip
                title={jsonData[index][key] ? "Deshabilitar" : "Habilitar"}
                disableInteractive
                onClick={() => {
                  saveChangesStatus(jsonData[index].id);
                  setJsonData({
                    type: key,
                    ind: index,
                    payload: !jsonData[index][key],
                  });
                }}
              >
                <Icon>
                  {jsonData[index][key] ? "radio_button_checked" : "radio_button_unchecked"}
                </Icon>
              </Tooltip>
            </MDTypography>
            <MDTypography color="text" display={userType === 1 ? "none" : "flex"}>
              <Tooltip
                title={jsonData[index][key] ? "Habilitado" : "Deshabilitado"}
                disableInteractive
              >
                <Icon>
                  {jsonData[index][key] ? "radio_button_checked" : "radio_button_unchecked"}
                </Icon>
              </Tooltip>
            </MDTypography>
          </>
        );
      } else if (key.includes("action")) {
        element[key] = (
          <MDTypography color="text">
            <Tooltip
              title="Copiar a"
              disableInteractive
              onClick={() => {
                setEditMoveFunctionality(jsonData[index].id);
              }}
            >
              <Icon>content_copy</Icon>
            </Tooltip>
          </MDTypography>
        );
      } else {
        element[key] = jsonData[index][key];
      }
    }

    return element;
  };

  const getColumns = () => {
    const elements = [];

    elements.push({ Header: "Estado", accessor: "estado", width: "0%", align: "center" });

    elements.push({ Header: "SubMenu", accessor: "subMenu", align: "left" });
    elements.push({ Header: "Nombre", accessor: "nombre", align: "left" });
    for (let index = 0; index < jsonColumns.length; index += 1) {
      elements.push({
        Header: jsonColumns[index].nombre,
        accessor: jsonColumns[index].interno,
        align: "center",
      });
    }

    if (userType === 1)
      elements.push({ Header: "Accion", accessor: "action", width: "0%", align: "center" });

    return elements;
  };

  const getRows = () => {
    const elements = [];

    for (let index = 0; index < jsonData.length; index += 1) {
      elements.push(getColumnsRows(index));
    }

    return elements;
  };

  return {
    columns: getColumns(),

    rows: getRows(),
  };
}

// Setting default values for the props of MDBadge
data.defaultProps = {
  jsonData: [],
  jsonColumns: [],
};

// Typechecking props of the data
data.propTypes = {
  jsonData: PropTypes.objectOf(PropTypes.array),
  jsonColumns: PropTypes.objectOf(PropTypes.array),
  setJsonData: PropTypes.func.isRequired,
  saveChangesStatus: PropTypes.func.isRequired,
  setEditMoveFunctionality: PropTypes.func.isRequired,
};
