/* eslint-disable react/prop-types */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// @mui material components
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDSnackbar from "components/MDSnackbar";

// Material Dashboard 2 React example components
import DataTable from "examples/Tables/DataTable";

// Data
import data from "views/admin/xdoc/areas/data";

// API Request
import APIRequest from "api/xDocApiRequest";

function Areas(props) {
  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  const [jsonData, setJsonData] = useState([]);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { installation, client } = props;

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );

  const getJsonData = async () => {
    const tempJsonData = [];

    let apiResponse;
    if (installation) {
      apiResponse = await (
        await APIRequest("POST", "/Area/GetByInstallation", { installationGuid: installation.guid })
      ).json();
    } else {
      apiResponse = await (await APIRequest("GET", "/Area/GetAll", null)).json();
    }

    apiResponse.forEach((item) => {
      tempJsonData.push(item);
    });

    setJsonData(tempJsonData);
  };

  const [showConfirmDisable, setShowConfirmDisable] = useState(false);
  const [areaToDisable, setAreaToDisable] = useState({});
  const [disableReason, setDisableReason] = useState("");
  const setEnabled = async (area, disableConfirmed) => {
    const enable = !area.enabled;
    if (!enable && !disableConfirmed) {
      setAreaToDisable(area);
      setShowConfirmDisable(true);
      return;
    }

    try {
      if (enable) {
        const isTaxEntityEnabled = await (
          await APIRequest("POST", "/TaxEntity/IsEnabled", {
            taxId: area.taxId,
            country: area.country,
          })
        ).json();
        if (!isTaxEntityEnabled) {
          configNotification(
            "error",
            "warning",
            "Notificación",
            "La entidad tributaria asociada se encuentra deshabilitada o no se ha configurado.",
            "",
            true
          );
          return;
        }
      }

      const apiResponse = await APIRequest("POST", "/Area/SetEnabled", {
        areaId: area.id,
        enable,
        reason: disableReason,
      });

      if (apiResponse.status === 200) {
        setJsonData(
          jsonData.map((item) => ({
            ...item,
            enabled: item.id === area.id ? enable : item.enabled,
            lastDisableReason: item.id === area.id ? disableReason : item.lastDisableReason,
          }))
        );
        configNotification(
          "warning",
          "check",
          "Notificación",
          enable
            ? `Se habilitó el area '${area.name}' exitosamente.`
            : `Se deshabilitó el area '${area.name}' exitosamente.`,
          "",
          true
        );
      } else {
        const errorMessage = {
          code: apiResponse.status,
          message: `Error: ${apiResponse.status}`,
        };
        throw errorMessage;
      }
    } catch (e) {
      configNotification(
        "error",
        "warning",
        "Notificación",
        `Ocurrió un error al deshabilitar el area: ${e.message}`,
        "",
        true
      );
    }
  };
  const handleCancelDisable = () => {
    setShowConfirmDisable(false);
  };
  const handleConfirmDisable = () => {
    setEnabled(areaToDisable, true);
    setShowConfirmDisable(false);
  };

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [areaIdToDelete, setAreaIdToDelete] = useState(0);
  const deleteJsonData = async (areaId, confirmed) => {
    if (!confirmed) {
      setAreaIdToDelete(areaId);
      setShowConfirmDelete(true);
      return;
    }

    try {
      const apiResponse = await APIRequest("DELETE", `/Area/Delete?areaId=${areaId}`, null);

      if (apiResponse.status === 200) {
        const index = jsonData.map((item) => item.id).indexOf(areaId);
        const elements = [...jsonData];
        const nombreElemento = elements[index].name;
        elements.splice(index, 1);
        setJsonData(elements);
        configNotification(
          "warning",
          "check",
          "Notificación",
          `Se eliminó el area '${nombreElemento}' exitosamente.`,
          "",
          true
        );
      } else {
        const errorMessage = {
          code: apiResponse.status,
          message: `Error: ${apiResponse.status}`,
        };
        throw errorMessage;
      }
    } catch (e) {
      configNotification(
        "error",
        "warning",
        "Notificación",
        `Ocurrió un error al eliminar el area: ${e.message}`,
        "",
        true
      );
    }
  };
  const handleCancelDelete = () => {
    setShowConfirmDelete(false);
  };
  const handleConfirmDelete = () => {
    deleteJsonData(areaIdToDelete, true);
    setShowConfirmDelete(false);
  };

  const viewDetails = (area) =>
    navigate("/Administracion/xDoc/Areas/Detalles", { state: { client, installation, area } });

  useEffect(() => {
    getJsonData();
  }, [pathname]);

  const { columns, rows } = data(jsonData, deleteJsonData, setEnabled, viewDetails);

  return (
    <>
      <MDBox>
        <DataTable
          entriesPerPage={(false, { defaultValue: 10, entries: [5, 10, 25, 50] })}
          canSearch
          showTotalEntries
          table={{ columns, rows }}
          pagination={{ variant: "contained", color: "warning" }}
          isSorted
          noEndBorder
        />
      </MDBox>
      {renderNotificacion}
      <Dialog
        open={showConfirmDelete}
        onClose={handleCancelDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">¿Quiere eliminar el cliente?</DialogTitle>
        <DialogActions>
          <Button onClick={handleCancelDelete}>Cancelar</Button>
          <Button onClick={handleConfirmDelete} autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showConfirmDisable}
        onClose={handleCancelDisable}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">¿Quiere deshabilitar el área?</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Motivo (opcional)"
            fullWidth
            variant="standard"
            onChange={(e) => setDisableReason(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDisable}>Cancelar</Button>
          <Button onClick={handleConfirmDisable} autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Areas;
