/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// @mui material components
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Loading from "components/Loading";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import data from "views/producto/ListadoFuncionalidades/data";

// API Request
import APIRequest from "api/request";
import APIRequestGeneric from "api/requestGeneric";

function ListadoFuncionalidades() {
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  const host = process.env.REACT_APP_API_URL;
  const basicAuth = `${process.env.REACT_APP_API_ENEX_USER}:${process.env.REACT_APP_API_ENEX_PASSWORD}`;
  const basicAuthorization = `Basic ${btoa(basicAuth)}`;

  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  const [load, setLoad] = useState(false);
  const [jsonData, setJsonData] = useState([]);
  const { pathname } = useLocation();

  const [newValueTaxId, setNewValueTaxId] = useState("");
  const [newValuePais, setNewValuePais] = useState(null);
  const [newValuePartitionKey, setNewValuePartitionKey] = useState(null);
  const [newValueDescription, setNewValueDescription] = useState("");

  const [selectData, setSelectData] = useState({
    pais: [
      { id: "cl", nombre: "Chile" },
      { id: "gt", nombre: "Guatemala" },
    ],
    // partitionKey: [{ id: "getbarcodetext", nombre: "getbarcodetext", descripcion: "Prueba" }],
    partitionKey: [],
  });

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };
  const showNotificationError = (message) => {
    configNotification("error", "warning", "Notificación", message, "", true);
  };
  const showNotificationSuccess = (message) => {
    configNotification("warning", "check", "Notificación", message, "", true);
  };

  const saveDataModal = async () => {
    const index = jsonData
      .map((item) => item.rowKey)
      .indexOf(`${newValuePais.id}|${newValueTaxId}`);
    if (index < 0) {
      setLoad(true);

      const body = {
        User: userInfo.correo,
        Functionality: newValuePartitionKey.nombre,
        State: true,
        CountryId: newValuePais.id.toLowerCase(),
        TaxId: newValueTaxId,
      };

      const urlComplete = `${host}/api/AccountFunctionality/CreateFunctionality`;
      const apiResponse = await (
        await APIRequestGeneric("POST", urlComplete, body, false, basicAuthorization)
      ).json();

      if ((apiResponse.Code === 201 || apiResponse.Code === 200) && apiResponse.IsSuccessful) {
        const today = new Date();
        const newTempElement = {
          partitionKey: newValuePartitionKey.nombre,
          taxId: newValueTaxId,
          country: newValuePais,
          date: `${today.getFullYear()}-${(today.getMonth() + 1)
            .toString()
            .padStart(2, "0")}-${today.getDate().toString().padStart(2, "0")}`,
          user: userInfo.correo,
          state: true,
          rowKey: `${newValuePais.id}|${newValueTaxId}`,
        };
        setJsonData([newTempElement, ...jsonData]);
        showNotificationSuccess("Se Activó Satisfactoriamente");
      } else {
        showNotificationError(`Error al consultar: ${apiResponse.Message}`);
      }
      setLoad(false);
    } else {
      showNotificationSuccess("Elemento ya registrado");
    }
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );

  const getJsonData = async (partitionKey) => {
    setLoad(true);

    const tempJsonData = [];
    const body = {
      User: userInfo.correo,
      Functionality: partitionKey,
    };
    const urlComplete = `${host}/api/AccountFunctionality/GetFunctionality`;
    const apiResponse = await (
      await APIRequestGeneric("POST", urlComplete, body, false, basicAuthorization)
    ).json();

    const responseData = JSON.parse(apiResponse.Data);

    for (let index = 0; index < responseData.length; index += 1) {
      try {
        if (!responseData[index].Deleted) {
          tempJsonData.push({
            partitionKey: responseData[index].PartitionKey,
            taxId: responseData[index].AccountCode,
            country:
              selectData.pais[
                selectData.pais
                  .map((item) => item.id)
                  .indexOf(responseData[index].CountryId.toLowerCase())
              ],
            date: responseData[index].Timestamp.substring(0, 10),
            user: responseData[index].UserUpdated
              ? responseData[index].UserUpdated
              : responseData[index].UserCreated,
            state: responseData[index].Enable,
            rowKey: responseData[index].RowKey,
          });
        }
      } catch {
        console.log(`error en linea: ${index}`);
      }
    }

    setJsonData([...tempJsonData]);
    setLoad(false);
  };

  const getCountriesData = async () => {
    const tempJsonData = [];
    const apiResponse = await (await APIRequest("GET", "/api/Pais/Todos", null)).json();

    for (let index = 0; index < apiResponse.length; index += 1) {
      tempJsonData.push({
        id: apiResponse[index].codigo.toLowerCase(),
        nombre: apiResponse[index].nombre,
      });
    }

    setSelectData({
      pais: tempJsonData,
      partitionKey: selectData.partitionKey,
    });

    return tempJsonData;
  };

  const getPartitionKeys = async (countries) => {
    const tempJsonData = [];
    const body = {
      User: userInfo.correo,
    };
    const urlComplete = `${host}/api/AccountFunctionality/GetFunctionalityDescription`;
    const apiResponse = await (
      await APIRequestGeneric("POST", urlComplete, body, false, basicAuthorization)
    ).json();

    const responseData = JSON.parse(apiResponse.Data);

    for (let index = 0; index < responseData.length; index += 1) {
      if (responseData[index].Enabled && !responseData[index].Deleted) {
        tempJsonData.push({
          id: responseData[index].RowKey,
          nombre: responseData[index].PartitionKey,
          descripcion: responseData[index].Description,
        });
      }
    }

    setSelectData({
      pais: countries,
      partitionKey: tempJsonData,
    });

    return tempJsonData;
  };

  const updateState = async (jsonElement) => {
    setLoad(true);
    const body = {
      User: userInfo.correo,
      Functionality: jsonElement.partitionKey,
      State: !jsonElement.state,
      CountryId: jsonElement.country.id,
      TaxId: jsonElement.taxId,
    };

    const urlComplete = `${host}/api/AccountFunctionality/UpdateFunctionality`;
    const apiResponse = await (
      await APIRequestGeneric("PUT", urlComplete, body, false, basicAuthorization)
    ).json();

    if ((apiResponse.Code === 201 || apiResponse.Code === 200) && apiResponse.IsSuccessful) {
      const tempJsonData = jsonData;
      const index = tempJsonData.map((item) => item.rowKey).indexOf(jsonElement.rowKey);
      const today = new Date();

      tempJsonData[index].state = !tempJsonData[index].state;
      tempJsonData[index].date = `${today.getFullYear()}-${(today.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${today.getDate().toString().padStart(2, "0")}`;
      tempJsonData[index].user = userInfo.correo;

      setJsonData([...tempJsonData]);
      showNotificationSuccess("Se Actualizó El Estado Satisfactoriamente");
    } else {
      showNotificationError(`Error al consultar: ${apiResponse.Message}`);
    }
    setLoad(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    saveDataModal();
  };

  useEffect(async () => {
    setLoad(true);
    const countries = await getCountriesData();
    await getPartitionKeys(countries);
    setLoad(false);
  }, [pathname]);

  const { columns, rows } = data(jsonData, updateState);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={3} component="form" onSubmit={handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <MDTypography variant="subtitle" fontWeight="bold">
              Permite activar/desactivar funcionalidades en API
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3} justifyContent="left" alignItems="left" height="100%">
              <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                <Autocomplete
                  value={newValuePartitionKey}
                  onChange={(event, newValue) => {
                    if (newValue != null) {
                      setNewValuePartitionKey(newValue);
                      setNewValueDescription(newValue.descripcion);
                      getJsonData(newValue.nombre);
                    }
                  }}
                  options={selectData.partitionKey}
                  getOptionLabel={(option) => option.nombre}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  renderInput={(params) => (
                    <MDInput
                      variant="standard"
                      type="text"
                      label="Seleccione Funcionalidad"
                      fullWidth
                      required
                      InputLabelProps={{ shrink: true }}
                      {...params}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                <MDInput
                  variant="standard"
                  type="text"
                  // label="Descripción"
                  fullWidth
                  disabled
                  multiline
                  maxRows={4}
                  InputLabelProps={{ shrink: true }}
                  value={newValueDescription}
                  onChange={(e) => {
                    setNewValueDescription(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="subtitle" fontWeight="bold">
              Activar Funcionalidad
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox sx={{ position: "relative", mx: 3, py: 2, px: 2 }}>
                <Grid container spacing={3} justifyContent="left" alignItems="left" height="100%">
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <Autocomplete
                      value={newValuePais}
                      onChange={(event, newValue) => {
                        setNewValuePais(newValue);
                      }}
                      options={selectData.pais}
                      getOptionLabel={(option) => option.nombre}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderInput={(params) => (
                        <MDInput
                          variant="standard"
                          type="text"
                          label="Pais"
                          fullWidth
                          required
                          InputLabelProps={{ shrink: true }}
                          {...params}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <MDInput
                      variant="standard"
                      type="text"
                      label="Tax ID"
                      fullWidth
                      required
                      InputLabelProps={{ shrink: true }}
                      value={newValueTaxId}
                      onChange={(e) => {
                        setNewValueTaxId(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4} />
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={2}
                    container
                    justifyContent="flex-end"
                  >
                    <MDButton
                      variant="contained"
                      type="submit"
                      sx={{
                        background: "linear-gradient(45deg, #099268 30%, #099268 90%)",
                        color: "#FFFFFF",
                      }}
                    >
                      <Icon>check_circle</Icon>&nbsp;Activar
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox>
                <DataTable
                  entriesPerPage={
                    (false, { defaultValue: 10, entries: [5, 10, 25, 50], show: false })
                  }
                  // hideEntriesPerPage
                  canSearch
                  showTotalEntries // ={false}
                  table={{ columns, rows }}
                  pagination={{ variant: "contained", color: "warning" }}
                  isSorted // ={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {renderNotificacion}
      <Loading load={load} />
      <Footer />
    </DashboardLayout>
  );
}

export default ListadoFuncionalidades;
