const baseColumns = [
  { Header: "TaxID", accessor: "taxId", align: "center" },
  { Header: "Organización", accessor: "organization", align: "center" },
  { Header: "Certificado de firma", accessor: "certificate", align: "center" },
  { Header: "Fecha creación", accessor: "fechaCreacion", align: "center" },
  { Header: "Usuario última acción", accessor: "usuario", align: "center" },
  { Header: "Gestor de folios", accessor: "gs", align: "center" },
  { Header: "Configuración Personalizada", accessor: "globalConfigUpdater", align: "center" },
  { Header: "Acciones", accessor: "action", align: "center" },
];
export default baseColumns;
