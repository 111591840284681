/* eslint-disable react/prop-types */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components

// API Request
import APIRequest from "api/xDocApiRequest";

// Custom components
import MDInput from "components/MDInput";

function ClientEditDialog(props) {
  const { client, setClient, show, setShow, notify, onSuccess } = props;

  const [errorName, setErrorName] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);

  const onChangeName = (e) => {
    const name = e.target.value;
    setErrorName(!name);
    setClient({ ...client, name });
  };

  const cultureOptions = [
    { key: 1, value: "es-MX" },
    { key: 2, value: "pt-BR" },
  ];
  const onSelectCulture = (e) => {
    setClient({ ...client, culture: e.target.value });
  };

  const onChangeEmail = (e) => {
    const isValidEmail = String(e.target.value)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    setErrorEmail(!isValidEmail);
    setClient({ ...client, email: e.target.value });
  };

  const foundInputErrors = () => {
    if (!client.name) {
      setErrorName(true);
      return true;
    }
    if (!client.email) {
      setErrorEmail(true);
      return true;
    }
    return errorName || errorEmail;
  };

  const updateClient = async () => {
    try {
      if (foundInputErrors()) return;
      const apiResponse = await APIRequest("POST", "/Client/Update", client);

      if (apiResponse.status === 200) {
        notify(
          "warning",
          "check",
          "Notificación",
          `Se modificó el cliente '${client.name}' exitosamente.`,
          "",
          true
        );
        setShow(false);
        onSuccess();
      } else {
        const errorMessage = {
          code: apiResponse.status,
          message: `Error: ${apiResponse.status}`,
        };
        throw errorMessage;
      }
    } catch (e) {
      notify(
        "error",
        "warning",
        "Notificación",
        `Ocurrió un error al modificar el cliente: ${e.message}`,
        "",
        true
      );
    }
  };
  const handleCancelEdit = () => {
    setShow(false);
  };

  return (
    <Dialog
      open={show}
      onClose={handleCancelEdit}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Editar cliente</DialogTitle>
      <DialogContent>
        <MDBox style={{ margin: "20px" }}>
          <MDBox>
            <MDInput
              label="Nombre"
              value={client.name}
              onChange={onChangeName}
              style={{ width: "300px" }}
              required
              error={errorName}
            />
          </MDBox>
          <MDBox style={{ marginTop: "15px" }}>
            <MDInput
              label="Email"
              type="email"
              error={errorEmail}
              value={client.email}
              onChange={onChangeEmail}
              style={{ width: "300px" }}
              required
            />
          </MDBox>
          <MDBox style={{ marginTop: "15px" }}>
            <FormControl variant="outlined" style={{ width: "100%", marginBottom: 32 }}>
              <InputLabel id="culture-select-label">Idioma</InputLabel>
              <Select
                style={{ height: "44px", width: "180px" }}
                variant="outlined"
                defaultValue={client.culture}
                value={client.culture}
                onChange={onSelectCulture}
                labelId="culture-select-label"
                label="Idioma"
              >
                {cultureOptions.map((element) => (
                  <MenuItem value={element.value} key={element.key}>
                    {element.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </MDBox>
        </MDBox>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancelEdit}>Cancelar</Button>
        <Button onClick={updateClient} autoFocus>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ClientEditDialog;
