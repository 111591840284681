/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// @mui material components
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// API Request
import APIRequest from "api/request";
import APIRequestGeneric from "api/requestGeneric";

function PoliticasContraseniaForm() {
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  const host = process.env.REACT_APP_API_URL;
  const basicAuth = `${process.env.REACT_APP_API_ENEX_USER}:${process.env.REACT_APP_API_ENEX_PASSWORD}`;
  const basicAuthorization = `Basic ${btoa(basicAuth)}`;

  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  // const [jsonData, setJsonData] = useState([]);
  const { pathname } = useLocation();
  const {
    state: { route, element },
  } = useLocation();
  const navigate = useNavigate();

  const [elementIsNew, setElementIsNew] = useState(true);
  const [newValueId, setNewValueId] = useState("");
  const [newValueTaxId, setNewValueTaxId] = useState("");
  const [newValuePais, setNewValuePais] = useState(null);
  const [newValueLongitudMinima, setNewValueLongitudMinima] = useState(0);
  const [newValueNoMayusculas, setNewValueNoMayusculas] = useState(0);
  const [newValueNoMinusculas, setNewValueNoMinusculas] = useState(0);
  const [newValueNoCaracteresEspe, setNewValueNoCaracteresEspe] = useState(0);
  const [newValueNoNumeros, setNewValueNoNumeros] = useState(0);
  const [newValueVigencia, setNewValueVigencia] = useState(0);
  const [newValueHistorial, setNewValueHistorial] = useState(0);
  const [newValueBloqueoInactividad, setNewValueBloqueoInactividad] = useState(0);
  const [newValuePalabrasClave, setValuePalabrasClave] = useState(false);
  const [newValuePalabrasClaveString, setNewValuePalabrasClaveString] = useState("");

  const [selectData, setSelectData] = useState({
    pais: [
      { id: "cl", nombre: "Chile" },
      { id: "gt", nombre: "Guatemala" },
      { id: "mx", nombre: "México" },
    ],
  });

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };
  const showNotificationError = (message) => {
    configNotification("error", "warning", "Notificación", message, "", true);
  };
  const showNotificationSuccess = (message) => {
    configNotification("warning", "check", "Notificación", message, "", true);
  };

  const closeModal = () => {
    setNewValueId("");
    setNewValueTaxId("");
    setNewValuePais(null);
    setNewValueLongitudMinima(0);
    setNewValueNoMayusculas(0);
    setNewValueNoMinusculas(0);
    setNewValueNoCaracteresEspe(0);
    setNewValueNoNumeros(0);
    setNewValueVigencia(0);
    setNewValueHistorial(0);
    setNewValueBloqueoInactividad(0);
    setValuePalabrasClave(false);
    setNewValuePalabrasClaveString("");
  };
  const saveDataModal = async () => {
    try {
      const urlComplete = `${host}/api/PasswordPolicies/UpdateInformationPasswordPoliciesByCompany`;
      const body = {
        PartitionKey: elementIsNew ? `${newValuePais.id}|${newValueTaxId}` : newValueId,
        // PartitionKey: `${newValuePais.id}|${newValueTaxId}`, // Obligatorio
        RowKey: "PasswordPolicies", // Obligatorio
        AccountCode: newValueTaxId,
        CountryId: newValuePais.id,
        Key: "PasswordPolicies",
        Value: `{"PasswordLong": ${newValueLongitudMinima},"PasswordMinUppercase":${newValueNoMayusculas},"PasswordMinLowercase":${newValueNoMinusculas},"PasswordMinSpecialChars":${newValueNoCaracteresEspe},"PasswordDaysToExpire":${newValueVigencia},"PasswordDaysHistorical":${newValueHistorial},"PasswordUserInactive":${newValueBloqueoInactividad},"PasswordNumbers":${newValueNoNumeros},"PasswordBlackList":${newValuePalabrasClave}}`, // "PasswordUserLocked":0,"PasswordUserUnlocked":0,
        User: userInfo.correo,
        Active: true,
        PasswordBlackList: newValuePalabrasClaveString.toString().replaceAll(";", "\r\n"),
        // Name: null,
        // RNC: null,
        // UrlAceptation: null,
        // UrlOptional: null,
        // UrlReception: null,
        // MigrationDatePAC: null,
      };

      const apiResponse = await (
        await APIRequestGeneric("PUT", urlComplete, body, false, basicAuthorization)
      ).json();

      if (apiResponse.Code === 200 && apiResponse.IsSuccessful) {
        /* const tempJsonData = jsonData;

        const element = {
          id: elementIsNew ? `${newValuePais.id}|${newValueTaxId}` : newValueId,
          active: true,
          taxId: newValueTaxId,
          pais: newValuePais,
          longitudMinima: newValueLongitudMinima,
          noMayusculas: newValueNoMayusculas,
          noMinusculas: newValueNoMinusculas,
          noCaracteresEspe: newValueNoCaracteresEspe,
          noNumeros: newValueNoNumeros,
          vigencia: newValueVigencia,
          historial: newValueHistorial,
          bloqueoInactividad: newValueBloqueoInactividad,
          palabrasClave: newValuePalabrasClave,
          palabrasClaveString: newValuePalabrasClaveString,
          usuario: userInfo.correo,
        }; */

        if (elementIsNew) {
          // tempJsonData.push(element);
          // setJsonData([element, ...tempJsonData]);
        } else {
          // const index = tempJsonData.map((item) => item.taxId).indexOf(newValueTaxId);
          // tempJsonData[index] = element;
          // setJsonData(tempJsonData);
        }

        closeModal();
        showNotificationSuccess(`Registro del Tax ${newValueTaxId} exitoso`);
      } else {
        showNotificationError(`Error: ${apiResponse.Message}`);
      }
    } catch (exs) {
      showNotificationError(`Ocurrió un error en inesperado: ${exs.message}`);
    }
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );

  const setEditJsonData = (jsonElement) => {
    if (element) {
      setNewValueId(jsonElement.id);
      setNewValueTaxId(jsonElement.taxId);
      setNewValuePais(jsonElement.pais);
      setNewValueLongitudMinima(jsonElement.longitudMinima);
      setNewValueNoMayusculas(jsonElement.noMayusculas);
      setNewValueNoMinusculas(jsonElement.noMinusculas);
      setNewValueNoCaracteresEspe(jsonElement.noCaracteresEspe);
      setNewValueNoNumeros(jsonElement.noNumeros);
      setNewValueVigencia(jsonElement.vigencia);
      setNewValueHistorial(jsonElement.historial);
      setNewValueBloqueoInactividad(jsonElement.bloqueoInactividad);
      setValuePalabrasClave(jsonElement.palabrasClave);
      setNewValuePalabrasClaveString(jsonElement.palabrasClaveString);
    }
  };

  const getCountriesData = async () => {
    const tempJsonData = [];
    const apiResponse = await (await APIRequest("GET", "/api/Pais/Todos", null)).json();

    for (let index = 0; index < apiResponse.length; index += 1) {
      tempJsonData.push({
        id: apiResponse[index].codigo.toLowerCase(),
        nombre: apiResponse[index].nombre,
      });
    }

    setSelectData({
      pais: tempJsonData,
    });

    return tempJsonData;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    saveDataModal();
  };

  useEffect(async () => {
    await getCountriesData();
    setElementIsNew(!element);
    setEditJsonData(element);
  }, [pathname]);

  return (
    <DashboardLayout>
      <DashboardNavbar breadCrumb={route.split("/").slice(1)} />
      <MDBox pt={3} pb={3} component="form" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MDBox pb={4}>
              <MDTypography variant="subtitle" fontWeight="bold">
                <Icon
                  sx={{ cursor: "pointer", fontWeight: "bold" }}
                  onClick={() => navigate(route)}
                >
                  arrow_back
                </Icon>
                {"   "}
                Permite registrar Políticas de contraseña para un TaxID
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox sx={{ position: "relative", mx: 3, py: 2, px: 2 }}>
                <Grid container spacing={3} justifyContent="left" alignItems="left" height="100%">
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <Autocomplete
                      value={newValuePais}
                      onChange={(event, newValue) => {
                        setNewValuePais(newValue);
                      }}
                      disabled={!elementIsNew}
                      options={selectData.pais}
                      getOptionLabel={(option) => option.nombre}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderInput={(params) => (
                        <MDInput
                          variant="standard"
                          type="text"
                          label="Pais"
                          fullWidth
                          required
                          disabled={!elementIsNew}
                          InputLabelProps={{ shrink: true }}
                          {...params}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <MDInput
                      variant="standard"
                      type="text"
                      label="Tax ID"
                      fullWidth
                      required
                      disabled={!elementIsNew}
                      InputLabelProps={{ shrink: true }}
                      value={newValueTaxId}
                      onChange={(e) => {
                        setNewValueTaxId(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <MDInput
                      variant="standard"
                      type="number"
                      label="Longitud Minima"
                      fullWidth
                      required
                      inputProps={{ min: 0, max: 99 }}
                      InputLabelProps={{ shrink: true }}
                      value={newValueLongitudMinima}
                      onChange={(e) => {
                        if (e.target.value >= 0 && e.target.value <= 99) {
                          setNewValueLongitudMinima(parseInt(e.target.value, 10).toString());
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <MDInput
                      variant="standard"
                      type="number"
                      label="Número de Mayusculas"
                      fullWidth
                      required
                      inputProps={{ min: 0, max: 99 }}
                      InputLabelProps={{ shrink: true }}
                      value={newValueNoMayusculas}
                      onChange={(e) => {
                        if (e.target.value >= 0 && e.target.value <= 99) {
                          setNewValueNoMayusculas(parseInt(e.target.value, 10).toString());
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <MDInput
                      variant="standard"
                      type="number"
                      label="Número de Minusculas"
                      fullWidth
                      required
                      inputProps={{ min: 0, max: 99 }}
                      InputLabelProps={{ shrink: true }}
                      value={newValueNoMinusculas}
                      onChange={(e) => {
                        if (e.target.value >= 0 && e.target.value <= 99) {
                          setNewValueNoMinusculas(parseInt(e.target.value, 10).toString());
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <MDInput
                      variant="standard"
                      type="number"
                      label="Número de Caracteres Especiales"
                      fullWidth
                      required
                      inputProps={{ min: 0, max: 99 }}
                      InputLabelProps={{ shrink: true }}
                      value={newValueNoCaracteresEspe}
                      onChange={(e) => {
                        if (e.target.value >= 0 && e.target.value <= 99) {
                          setNewValueNoCaracteresEspe(parseInt(e.target.value, 10).toString());
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <MDInput
                      variant="standard"
                      type="number"
                      label="Cantidad de Numeros"
                      fullWidth
                      required
                      inputProps={{ min: 0, max: 99 }}
                      InputLabelProps={{ shrink: true }}
                      value={newValueNoNumeros}
                      onChange={(e) => {
                        if (e.target.value >= 0 && e.target.value <= 99) {
                          setNewValueNoNumeros(parseInt(e.target.value, 10).toString());
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <Tooltip title="Ingrese cantidad de días" disableInteractive>
                      <MDInput
                        variant="standard"
                        type="number"
                        label="Vigencia"
                        fullWidth
                        required
                        inputProps={{ min: 0, max: 999 }}
                        InputLabelProps={{ shrink: true }}
                        value={newValueVigencia}
                        onChange={(e) => {
                          if (e.target.value >= 0 && e.target.value <= 999) {
                            setNewValueVigencia(parseInt(e.target.value, 10).toString());
                          }
                        }}
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <Tooltip title="Cantidad de contraseñas ya utilizadas" disableInteractive>
                      <MDInput
                        variant="standard"
                        type="number"
                        label="Historial de contraseñas"
                        fullWidth
                        required
                        inputProps={{ min: 0, max: 99 }}
                        InputLabelProps={{ shrink: true }}
                        value={newValueHistorial}
                        onChange={(e) => {
                          if (e.target.value >= 0 && e.target.value <= 99) {
                            setNewValueHistorial(parseInt(e.target.value, 10).toString());
                          }
                        }}
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <Tooltip title="Ingrese cantidad de días" disableInteractive>
                      <MDInput
                        variant="standard"
                        type="number"
                        label="Bloqueo de usuario por inactividad"
                        fullWidth
                        required
                        inputProps={{ min: 0, max: 999 }}
                        InputLabelProps={{ shrink: true }}
                        value={newValueBloqueoInactividad}
                        onChange={(e) => {
                          if (e.target.value >= 0 && e.target.value <= 999) {
                            setNewValueBloqueoInactividad(parseInt(e.target.value, 10).toString());
                          }
                        }}
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <MDBox
                      display="flex"
                      justifyContent="flex-end"
                      alignItems="center"
                      width={{ xs: "100%", sm: "auto" }}
                      mt={{ xs: 1, sm: 0 }}
                    >
                      <MDBox lineHeight={0} mx={2}>
                        <MDTypography variant="button" color="text">
                          Palabras Clave
                        </MDTypography>
                      </MDBox>
                      <MDBox mr={1}>
                        <Switch
                          checked={newValuePalabrasClave}
                          onChange={() => setValuePalabrasClave(!newValuePalabrasClave)}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </MDBox>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <MDInput
                      variant="standard"
                      type="text"
                      label="Palabras Clave"
                      fullWidth
                      disabled={!newValuePalabrasClave}
                      inputProps={{ maxLength: 500 }}
                      InputLabelProps={{ shrink: true }}
                      value={newValuePalabrasClaveString}
                      onChange={(e) => {
                        const char = e.target.value.charAt(e.target.value.length - 1);
                        const charCode = char.charCodeAt(0);

                        if (
                          (charCode >= 65 && charCode <= 90) ||
                          (charCode >= 97 && charCode <= 122) ||
                          (charCode >= 48 && charCode <= 57) ||
                          charCode === 59 ||
                          char === ""
                        ) {
                          setNewValuePalabrasClaveString(e.target.value);
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="left" alignItems="left" height="100%">
              <Grid item xs={12} sm={12} md={6} lg={4} xl={10} />
              <Grid item xs={12} sm={12} md={6} lg={4} xl={2} container justifyContent="flex-end">
                <MDButton
                  variant="contained"
                  type="submit"
                  sx={{
                    background: "linear-gradient(45deg, #099268 30%, #099268 90%)",
                    color: "#FFFFFF",
                  }}
                >
                  <Icon>save</Icon>&nbsp;Guardar
                </MDButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
      {renderNotificacion}
      <Footer />
    </DashboardLayout>
  );
}

export default PoliticasContraseniaForm;
