import { insertAndUpdateIds } from "../../_helpers/arrayHelpers";
import CONFIG_GLOBAL_DATA from "../configGlobal/configGlobalGeneral";
import { OTHER } from "../configGlobal/transformation";

export const itemOther = (configGlobal) => ({
  id: 1,
  fileName: OTHER,
  version: configGlobal?.other?.version ?? "",
  updatedBy: configGlobal?.other?.updatedBy ?? "",
  propsThreeDotsMenu: {
    keyResponse: "other",
    fileName: "other",
  },
});

const CUSTOM_CONFIG_DATA = (configGlobal) => {
  const data = CONFIG_GLOBAL_DATA(configGlobal);

  data.transformation = insertAndUpdateIds(data.transformation, itemOther(configGlobal), 1);

  return data;
};

export default CUSTOM_CONFIG_DATA;
