/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import MDAvatar from "components/MDAvatar";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
// import Icon from "@mui/material/Icon";
import US from "assets/images/icons/flags/usa-48.png";
import CL from "assets/images/icons/flags/chile-48.png";
import COL from "assets/images/icons/flags/colombia-48.png";
import COS from "assets/images/icons/flags/costa-rica-48.png";
import PER from "assets/images/icons/flags/peru-48.png";
import URG from "assets/images/icons/flags/uruguay-48.png";
import PAN from "assets/images/icons/flags/panama-48.png";

export default function dataGrid(jsonData) {
  const groupByArray = (xs, key) =>
    xs?.reduce((rv, x) => {
      const v = key instanceof Function ? key(x) : x[key];
      const el = rv.find((r) => r && r.key === v);
      if (el) {
        el.values.push(x);
      } else {
        rv.push({ key: v, values: [x] });
      }
      return rv;
    }, []);
  const getRows = () => {
    const elements = [];
    const data = groupByArray(jsonData?.documentos, "country");

    for (let index = 0; index < data?.length; index += 1) {
      let bandera = "";
      if (data[index].key === "Chile") bandera = CL;
      else if (data[index].key === "Colombia") bandera = COL;
      else if (data[index].key === "Costa rica") bandera = COS;
      else if (data[index].key === "Perú") bandera = PER;
      else if (data[index].key === "Uruguay") bandera = URG;
      else if (data[index].key === "Panamá") bandera = PAN;
      else bandera = US;
      elements.push({
        pais: [bandera, data[index].key],
        envios: data[index].values?.reduce(
          (accumulator, currentValue) => accumulator + currentValue.envios,
          0
        ),
        aceptados: data[index].values?.reduce(
          (accumulator, currentValue) => accumulator + currentValue.aceptados,
          0
        ),
        rechazados: data[index].values?.reduce(
          (accumulator, currentValue) => accumulator + currentValue.rechazados,
          0
        ),
      });
    }

    return elements;
  };

  return {
    rows: getRows(),
  };
}

// Setting default values for the props of MDBadge
dataGrid.defaultProps = {
  jsonData: [],
};

// Typechecking props of the data
dataGrid.propTypes = {
  jsonData: PropTypes.objectOf(PropTypes.array),
};
