/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// @mui material components
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import BKModal from "components/BKModal";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDInput from "components/MDInput";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import data from "views/soporte/Enex_RUTImpuestoNegativo/data";

// API Request
// import APIRequest from "api/request";
import APIRequestGeneric from "api/requestGeneric";

function EnexRUTImpuestoNegativo() {
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  const host = process.env.REACT_APP_API_URL;
  const basicAuth = `${process.env.REACT_APP_API_ENEX_USER}:${process.env.REACT_APP_API_ENEX_PASSWORD}`;
  const basicAuthorization = `Basic ${btoa(basicAuth)}`;

  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  const [menu, setMenu] = useState(null);
  const [modal, setModal] = useState(false);
  const [jsonData, setJsonData] = useState([]);
  const { pathname } = useLocation();

  const [elementIsNew, setElementIsNew] = useState(true);
  // const [newValueId, setNewValueId] = useState("");
  const [newValueRut, setNewValueRut] = useState("");
  const [newValueStatus, setNewValueStatus] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [selectData, setSelectData] = useState({
    status: [
      { id: 1, nombre: "Habilitado" },
      { id: 0, nombre: "Deshabilitado" },
    ],
  });

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };
  const showNotificationError = (message) => {
    configNotification("error", "warning", "Notificación", message, "", true);
  };
  const showNotificationSuccess = (message) => {
    configNotification("warning", "check", "Notificación", message, "", true);
  };

  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);
  const openAddNewElementModal = () => {
    setElementIsNew(true);
    setMenu(null);
    setModal(true);
  };
  const closeModal = () => {
    setModal(false);
    // setNewValueId("");
    setNewValueRut("");
    setNewValueStatus(null);
  };
  const saveDataModal = async () => {
    const body = {
      RUT: newValueRut,
      State: newValueStatus.id,
      User: userInfo.correo,
    };
    let apiResponse = null;

    try {
      const urlCreate = `${host}/api/AccountFunctionality/CreateAccountNegativeTax`;
      const urlUpdate = `${host}/api/AccountFunctionality/UpdateAccountNegativeTax`;

      if (elementIsNew) {
        // eslint-disable-next-line prettier/prettier
        apiResponse = await APIRequestGeneric("POST", urlCreate, body, false, basicAuthorization);
      } else {
        // eslint-disable-next-line prettier/prettier
        apiResponse = await APIRequestGeneric("PUT", urlUpdate, body, false, basicAuthorization);
      }

      if (apiResponse.status === 201) {
        const today = new Date();

        const newTempElement = {
          // id: elementIsNew ? dataResponse : newValueId,
          accountCode: newValueRut,
          countryId: "cl",
          timeStamp: `${today.getDate().toString().padStart(2, "0")}-${(today.getMonth() + 1)
            .toString()
            .padStart(2, "0")}-${today.getFullYear()}`,
          key: "ValidateDiesel",
          status: newValueStatus,
          user: userInfo.correo,
        };

        const tempJsonData = jsonData;

        if (elementIsNew) {
          tempJsonData.push(newTempElement);

          showNotificationSuccess(`Se creó el RUT '${newValueRut}' exitosamente.`);
        } else {
          const index = tempJsonData.map((item) => item.accountCode).indexOf(newValueRut);
          tempJsonData[index] = newTempElement;

          showNotificationSuccess(`Se actualizó el RUT '${newValueRut}' exitosamente.`);
        }

        setJsonData(tempJsonData);
      } else {
        const apiData = await apiResponse.json();
        const errorMessage = {
          code: apiResponse.status,
          // message: `Error: ${apiResponse.status}`,
          message: apiData.Data,
        };
        throw errorMessage;
      }
    } catch (e) {
      showNotificationError(`Ocurrió un error: ${e.message}`);
    }

    closeModal();
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={openAddNewElementModal}>Agregar RUT</MenuItem>
    </Menu>
  );

  const renderModal = (
    <BKModal
      closeModal={closeModal}
      saveChanges={saveDataModal}
      open={modal}
      title={elementIsNew ? "Agregar RUT" : "Editar RUT"}
    >
      <MDBox sx={{ position: "relative", mx: 3, py: 2, px: 2 }}>
        <Grid container spacing={3} justifyContent="left" alignItems="left" height="100%">
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <MDInput
              variant="standard"
              type="text"
              label="RUT"
              fullWidth
              required
              disabled={!elementIsNew}
              InputLabelProps={{ shrink: true }}
              value={newValueRut}
              onChange={(e) => {
                setNewValueRut(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <Autocomplete
              value={newValueStatus}
              onChange={(event, newValue) => {
                setNewValueStatus(newValue);
              }}
              options={selectData.status}
              getOptionLabel={(option) => option.nombre}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <MDInput
                  variant="standard"
                  type="text"
                  label="Estado"
                  fullWidth
                  required
                  InputLabelProps={{ shrink: true }}
                  {...params}
                />
              )}
            />
          </Grid>
        </Grid>
      </MDBox>
    </BKModal>
  );

  const getJsonData = async () => {
    const tempJsonData = [];
    const body = { User: userInfo.correo };
    const urlComplete = `${host}/api/AccountFunctionality/GetAccountNegativeTax`;
    const apiResponse = await (
      await APIRequestGeneric("POST", urlComplete, body, false, basicAuthorization)
    ).json();

    const jsonResponse = JSON.parse(apiResponse.Data);

    for (let index = 0; index < jsonResponse.length; index += 1) {
      tempJsonData.push({
        // id: apiResponse[index].id,
        accountCode: jsonResponse[index].RUT,
        countryId: "cl",
        timeStamp: jsonResponse[index].FechaRegistro,
        key: "ValidateDiesel",
        status: {
          id: jsonResponse[index].Estado.toUpperCase() === "TRUE" ? 1 : 0,
          nombre:
            jsonResponse[index].Estado.toUpperCase() === "TRUE" ? "Habilitado" : "Deshabilitado",
        },
        user: jsonResponse[index].Usuario,
      });
    }

    setJsonData(tempJsonData);
  };

  const deleteJsonData = async (id) => {
    if (window.confirm("¿Quiere eliminar el RUT?")) {
      try {
        const urlComplete = `${host}/api/AccountFunctionality/DeleteAccountNegativeTax`;
        const body = {
          RUT: id,
          User: userInfo.correo,
        };
        // eslint-disable-next-line prettier/prettier
        const apiResponse = await APIRequestGeneric("POST", urlComplete, body, false, basicAuthorization);

        if (apiResponse.status === 200) {
          const index = jsonData.map((item) => item.accountCode).indexOf(id);
          const elements = [...jsonData];
          const nombreElemento = elements[index].accountCode;
          elements.splice(index, 1);
          setJsonData(elements);

          showNotificationSuccess(`Se eliminó el RUT '${nombreElemento}' exitosamente.`);
        } else {
          const apiData = await apiResponse.json();
          const errorMessage = {
            code: apiResponse.status,
            // message: `Error: ${apiResponse.status}`,
            message: apiData.Data,
          };
          throw errorMessage;
        }
      } catch (e) {
        showNotificationError(`Ocurrió un error al eliminar el RUT: ${e.message}`);
      }
    }
  };

  const setEditJsonData = (valueId) => {
    setElementIsNew(false);
    // setNewValueId(valueId);
    setNewValueRut(valueId);

    const index = jsonData.map((item) => item.accountCode).indexOf(valueId);
    // setNewValueId(jsonData[index].id);
    setNewValueRut(jsonData[index].accountCode);
    setNewValueStatus(jsonData[index].status);

    setModal(true);
  };

  useEffect(() => {
    getJsonData();
  }, [pathname]);

  const { columns, rows } = data(jsonData, deleteJsonData, setEditJsonData);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {renderModal}
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                <MDBox>
                  <MDTypography variant="h4" gutterBottom color="warning">
                    Agregar RUT a la funcionalidad de impuesto negativo
                  </MDTypography>
                </MDBox>
                <MDBox color="text" px={2}>
                  <Icon
                    sx={{ cursor: "pointer", fontWeight: "bold" }}
                    fontSize="small"
                    onClick={openMenu}
                  >
                    more_vert
                  </Icon>
                </MDBox>
                {renderMenu}
              </MDBox>
              <MDBox>
                <DataTable
                  entriesPerPage={(false, { defaultValue: 10, entries: [5, 10, 25, 50] })}
                  canSearch
                  showTotalEntries
                  table={{ columns, rows }}
                  pagination={{ variant: "contained", color: "warning" }}
                  isSorted
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {renderNotificacion}
      <Footer />
    </DashboardLayout>
  );
}

export default EnexRUTImpuestoNegativo;
