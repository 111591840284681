import React, { useState } from "react";
import PropTypes from "prop-types";
import XposCollapse from "../../shared/XposCollapse";
import Persistence from "../Persistence";
import UpdateXpos from "../UpdateXpos";

function Configuration({
  country,
  showNotificationError,
  showNotificationSuccess,
  setLoading,
  data,
}) {
  const [openUpdateXposState, setOpenUpdateXposState] = useState(false);
  const [openPersistencesState, setOpenPersistenceState] = useState(false);

  return (
    <div>
      <XposCollapse
        title="Persistencia"
        open={openPersistencesState}
        onClick={() => setOpenPersistenceState(!openPersistencesState)}
      >
        <Persistence
          country={country}
          showNotificationError={showNotificationError}
          showNotificationSuccess={showNotificationSuccess}
          setLoading={setLoading}
          persistency={data.persistency || ""}
        />
      </XposCollapse>
      <XposCollapse
        title="Update xPOS"
        open={openUpdateXposState}
        onClick={() => setOpenUpdateXposState(!openUpdateXposState)}
      >
        <UpdateXpos
          country={country}
          showNotificationError={showNotificationError}
          showNotificationSuccess={showNotificationSuccess}
          setLoading={setLoading}
          xpos={data.xpos}
        />
      </XposCollapse>
    </div>
  );
}

Configuration.propTypes = {
  country: PropTypes.string.isRequired,
  showNotificationError: PropTypes.func.isRequired,
  showNotificationSuccess: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  data: PropTypes.shape({
    xpos: PropTypes.shape({
      xPosUpdateType: PropTypes.string,
      xPosUpdateDate: PropTypes.string,
      xPosUpdatedBy: PropTypes.string,
      xposUpdatedAt: PropTypes.string,
    }),
    persistency: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
};
export default Configuration;
