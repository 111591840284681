/* eslint-disable no-eval */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// @mui material components
import Grid from "@mui/material/Grid";
// Components
import ReusableTabs from "../../../../examples/Tabs";
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import Loading from "../../../../components/Loading";
import Footer from "../../../../examples/Footer";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../../../examples/LayoutContainers/DashboardLayout";
// API
import xPosApiRequest from "../../../../api/xPosApiRequest";
import Notification from "../_components/shared/Notification";
import useNotification from "../_hooks/useNotification";
import {
  optionsConfiguration,
  optionsConfigurationFields,
  optionsGlobalConfiguration,
} from "../_constants/configGlobal/optionsGlobalConfiguration";
import { isOk } from "../_helpers/requestHelpers";
import Transformation from "../_components/configGlobal/Transformation";
import Url from "../_components/configGlobal/Url";
import CONFIG_GLOBAL_DATA from "../_constants/configGlobal/configGlobalGeneral";
import Configuration from "../_components/configGlobal/Configuration";
import CUSTOM_CONFIG_DATA from "../_constants/customConfiguration/customConfigGeneral";
import { TYPE_FIELDS } from "../_constants/configGlobal/transformation";

function ConfigGlobal({ country, organizationConfigGuid }) {
  const [loading, setLoading] = useState(false);
  const [configGlobal, setConfigGlobal] = useState({});
  const [data, setData] = useState({});
  const { notification, showNotificationError, showNotificationSuccess, setNotification } =
    useNotification();

  const optionsFetchData = () => {
    if (organizationConfigGuid) {
      return optionsConfiguration(organizationConfigGuid);
    }
    return optionsGlobalConfiguration(country);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await xPosApiRequest(optionsFetchData());
      if (isOk(response.status)) {
        const responseConfigGlobal = await response.data;
        setConfigGlobal(responseConfigGlobal);
      } else {
        showNotificationError("Ocurrio un error al cargar la configuración global");
      }
    } catch (error) {
      showNotificationError(error.data);
    } finally {
      setLoading(false);
    }
  };

  const getConfigurationFields = async () => {
    const options = optionsConfigurationFields(country);
    const response = await xPosApiRequest(options);
    if (isOk(response.status)) {
      return response.data.configurationFields;
    }
    return {};
  };

  const addFields = (globalConfigData, configurationFields) => {
    const cont = globalConfigData.transformation.length;
    const filterFields = organizationConfigGuid
      ? [TYPE_FIELDS.GLOBAL, TYPE_FIELDS.CUSTOMIZE]
      : [TYPE_FIELDS.GLOBAL];

    const newFields = configurationFields?.transformation
      ?.filter((item) => filterFields.includes(item.type))
      ?.map((item, index) => ({
        id: cont + index + 1,
        fileName: item.fileName,
        version: configGlobal?.[item.name]?.version ?? "",
        updatedBy: configGlobal?.[item.name]?.updatedBy ?? "",
        propsThreeDotsMenu: {
          keyResponse: item.name,
          fileName: item.name,
          fileExtension: item.fileDownloadName.split(".").pop(),
        },
      }));

    return {
      ...globalConfigData,
      transformation: [...globalConfigData.transformation, ...(newFields || [])],
    };
  };

  const getDataCountry = async () => {
    const configurationFields = await getConfigurationFields();
    try {
      const { default: response } = await import(
        `views/admin/xpos/_constants/configGlobal/configGlobal${country.toUpperCase()}`
      );
      const responseData = response(configGlobal);
      setData(addFields(responseData, configurationFields));
    } catch (error) {
      const response = CONFIG_GLOBAL_DATA(configGlobal);
      setData(addFields(response, configurationFields));
    }
  };

  const getDataOrganization = async () => {
    const configurationFields = await getConfigurationFields();
    try {
      const { default: response } = await import(
        `views/admin/xpos/_constants/customConfiguration/customConfig${country.toUpperCase()}`
      );
      const responseData = response(configGlobal);
      setData(addFields(responseData, configurationFields));
    } catch (error) {
      const response = CUSTOM_CONFIG_DATA(configGlobal);
      setData(addFields(response, configurationFields));
    }
  };

  useEffect(() => {
    fetchData();
  }, [country]);

  useEffect(() => {
    if (organizationConfigGuid) {
      getDataOrganization();
    } else {
      getDataCountry();
    }
  }, [configGlobal]);

  const renderContent = () => (
    <MDBox mb={3}>
      <Notification notification={notification} setNotification={setNotification} />
      <Loading load={loading} />
      <MDBox display="flex" justifyContent="space-between" alignItems="center" py={3}>
        <Grid>
          <MDTypography fontWeight="bold" variant="h5" color="dark" noWrap>
            {organizationConfigGuid ? "Configuración Personalizada" : "Configuración Global"}
          </MDTypography>
        </Grid>
      </MDBox>
      <MDBox py={1}>
        <ReusableTabs
          key={country}
          isEditing={false}
          tabs={[
            {
              id: 0,
              label: "Transformación",
              content: (
                <Transformation
                  country={country}
                  data={data?.transformation ?? []}
                  setLoading={setLoading}
                  showNotificationSuccess={showNotificationSuccess}
                  showNotificationError={showNotificationError}
                  setConfigGlobal={setConfigGlobal}
                  organizationConfigGuid={organizationConfigGuid}
                />
              ),
            },
            {
              id: 1,
              label: "URLs",
              content: (
                <Url
                  country={country}
                  setLoading={setLoading}
                  showNotificationSuccess={showNotificationSuccess}
                  showNotificationError={showNotificationError}
                  items={data?.url ?? []}
                  organizationConfigGuid={organizationConfigGuid}
                />
              ),
            },
            {
              id: 2,
              label: "Configuraciones",
              content: (
                <Configuration
                  country={country}
                  setLoading={setLoading}
                  showNotificationSuccess={showNotificationSuccess}
                  showNotificationError={showNotificationError}
                  data={data?.configuration ?? {}}
                />
              ),
            },
          ]}
        />
      </MDBox>
    </MDBox>
  );

  return organizationConfigGuid === "" ? (
    <DashboardLayout>
      <DashboardNavbar />
      {renderContent()}
      <Footer />
    </DashboardLayout>
  ) : (
    renderContent()
  );
}

ConfigGlobal.defaultProps = {
  organizationConfigGuid: "",
};

ConfigGlobal.propTypes = {
  country: PropTypes.string.isRequired,
  organizationConfigGuid: PropTypes.string,
};
export default ConfigGlobal;
