/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
// import Tooltip from "@mui/material/Tooltip";
// import Icon from "@mui/material/Icon";

// Default Components
// import MDTypography from "components/MDTypography";

export default function data(jsonData) {
  const getRows = () => {
    const elements = [];

    for (let index = 0; index < jsonData.length; index += 1) {
      elements.push({
        taxId: jsonData[index].taxId,
        apiKey: jsonData[index].apiKey,
        estado: jsonData[index].estado.nombre,
        usuario: jsonData[index].usuario,
      });
    }

    return elements;
  };

  return {
    columns: [
      // { Header: "", accessor: "icon", width: "0%", align: "center" },
      { Header: "Tax ID", accessor: "taxId", align: "center" },
      { Header: "Api Key", accessor: "apiKey", align: "center" },
      { Header: "Estado", accessor: "estado", align: "center" },
      { Header: "Usuario", accessor: "usuario", align: "center" },
      // { Header: "Accion", accessor: "action", align: "center" },
    ],

    rows: getRows(),
  };
}

// Setting default values for the props of MDBadge
data.defaultProps = {
  jsonData: [],
};

// Typechecking props of the data
data.propTypes = {
  jsonData: PropTypes.objectOf(PropTypes.array),
};
