// eslint-disable-next-line no-unused-vars
export default function parseInitialState(initialState) {
  const tempJsonData = [
    {
      id: 1,
      subMenu: "",
      nombre: "",
      estado: true,
      action: "acción",
      perfil1: true,
      perfil2: true,
    },
  ];

  return tempJsonData;
}
