/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// @mui material components
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import BKModal from "components/BKModal";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDInput from "components/MDInput";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import data from "views/admin/profiles/data";

// API Request
import APIRequest from "api/request";

function Profiles() {
  const userType = JSON.parse(sessionStorage.getItem("userType"));

  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  const [menu, setMenu] = useState(null);
  const [modal, setModal] = useState(false);
  const [modalConfiguration, setModalConfiguration] = useState(false);
  const [jsonData, setJsonData] = useState([]);
  const { pathname } = useLocation();

  const [elementIsNew, setElementIsNew] = useState(true);
  const [newValueId, setNewValueId] = useState("");
  const [newValueName, setNewValueName] = useState("");
  const [newValueType, setNewValueType] = useState(null);
  const [newValueArea, setNewValueArea] = useState(null);
  const [defaultValueType, setDefaultValueType] = useState(null);
  const [defaultValueArea, setDefaultValueArea] = useState(null);
  const [differentElements, setDifferentElements] = useState([]);
  const [newValueNoMaxPerfilesAdmin, setNewValueNoMaxPerfilesAdmin] = useState(0);
  const [newValueNoMaxPerfilesNormales, setNewValueNoMaxPerfilesNormales] = useState(0);
  const [newValueNoMaxPerfiles, setNewValueNoMaxPerfiles] = useState(0);
  const [selectData, setSelectData] = useState({
    type: [{ id: 3, nombre: "Normal" }],
    area: [],
  });

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };
  const showNotificationError = (message) => {
    configNotification("error", "warning", "Notificación", message, "", true);
  };
  const showNotificationSuccess = (message) => {
    configNotification("warning", "check", "Notificación", message, "", true);
  };

  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);
  const openAddNewElementModal = () => {
    setElementIsNew(true);
    setNewValueType(defaultValueType);
    setNewValueArea(defaultValueArea);
    setMenu(null);
    setModal(true);
  };
  const closeModal = () => {
    setModal(false);
    setNewValueId("");
    setNewValueName("");
    setNewValueType(null);
    setNewValueArea(null);
  };
  const saveDataModal = async () => {
    const tempJsonData = jsonData;
    const tempNames = differentElements;
    const body = {
      nombre: newValueName,
      tipoPerfilId: newValueType.id,
      areaId: newValueArea.id,
    };
    let apiResponse = null;

    try {
      if (differentElements.indexOf(newValueName.toUpperCase()) >= 0) {
        const errorMessage = {
          code: "500",
          message: "El nombre ya existe.",
        };
        throw errorMessage;
      }

      if (elementIsNew) {
        apiResponse = await APIRequest("POST", "/Api/Perfil", body);
      } else {
        apiResponse = await APIRequest("PATCH", `/Api/Perfil/${newValueId}`, body);
      }

      if (apiResponse.status === 200) {
        let dataResponse = null;

        if (elementIsNew) {
          dataResponse = await apiResponse.json();
          setNewValueId(dataResponse);
        }

        const newTempElement = {
          id: elementIsNew ? dataResponse : newValueId,
          nombre: newValueName,
          tipoPerfil: newValueType,
          area: newValueArea,
        };

        if (elementIsNew) {
          if (dataResponse !== 0) {
            tempJsonData.push(newTempElement);
            tempNames.push(newValueName.toUpperCase());

            showNotificationSuccess(`Se creó el perfil '${newValueName}' exitosamente.`);
          } else {
            const errorMessage = {
              code: apiResponse.status,
              message: "No se creó el perfil, se alcanzó el máximo de administradores.",
            };
            throw errorMessage;
          }
        } else {
          const index = tempJsonData.map((item) => item.id).indexOf(newValueId);
          tempJsonData[index] = newTempElement;
          tempNames[index] = newValueName.toUpperCase();

          showNotificationSuccess(`Se actualizó el perfil '${newValueName}' exitosamente.`);
        }
      } else {
        const errorMessage = {
          code: apiResponse.status,
          message: `Error: ${apiResponse.status}`,
        };
        throw errorMessage;
      }

      setJsonData(tempJsonData);
      setDifferentElements(tempNames);
    } catch (e) {
      showNotificationError(`Ocurrió un error en el perfil: ${e.message}`);
    }
    closeModal();
  };

  const openAddNewElementModalConfiguration = () => {
    setMenu(null);
    setModalConfiguration(true);
  };
  const closeModalConfiguration = () => {
    setModalConfiguration(false);
  };
  const saveDataModalConfiguration = async () => {
    const body = {
      NumeroMaximoPerfilesAdmin: newValueNoMaxPerfilesAdmin,
      NumeroMaximoPerfilesNormales: newValueNoMaxPerfilesNormales,
    };

    try {
      const apiResponse = await APIRequest("PATCH", "/Api/ConfigArea/1", body);

      if (apiResponse.status === 200) {
        setNewValueNoMaxPerfiles(newValueNoMaxPerfilesNormales);
        showNotificationSuccess(`Se actualizó la configuración exitosamente.`);
      } else {
        const errorMessage = {
          code: apiResponse.status,
          message: `Error: ${apiResponse.status}`,
        };
        throw errorMessage;
      }
    } catch (e) {
      showNotificationError(`Ocurrió un error inesperado: ${e.message}`);
    }
    closeModalConfiguration();
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={openAddNewElementModal}>Agregar Perfil</MenuItem>
      <MenuItem
        onClick={openAddNewElementModalConfiguration}
        sx={{ display: userType === 1 ? "flex" : "none" }}
      >
        Configuración
      </MenuItem>
    </Menu>
  );

  const renderModal = (
    <BKModal
      closeModal={closeModal}
      saveChanges={saveDataModal}
      open={modal}
      title={elementIsNew ? "Agregar Perfil" : "Editar Perfil"}
    >
      <MDBox sx={{ position: "relative", mx: 3, py: 2, px: 2 }}>
        <Grid container spacing={3} justifyContent="left" alignItems="left" height="100%">
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <MDInput
              variant="standard"
              type="text"
              label="Perfil"
              fullWidth
              required
              InputLabelProps={{ shrink: true }}
              value={newValueName}
              onChange={(e) => {
                setNewValueName(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <Autocomplete
              value={newValueType}
              onChange={(event, newValue) => {
                setNewValueType(newValue);
              }}
              options={selectData.type}
              getOptionLabel={(option) => option.nombre}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              disabled={userType !== 1}
              renderInput={(params) => (
                <MDInput
                  variant="standard"
                  type="text"
                  label="Tipo Perfil"
                  fullWidth
                  required
                  InputLabelProps={{ shrink: true }}
                  {...params}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <Autocomplete
              value={newValueArea}
              onChange={(event, newValue) => {
                setNewValueArea(newValue);
              }}
              options={selectData.area}
              getOptionLabel={(option) => option.nombre}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              disabled={userType !== 1 && selectData.area.length <= 1}
              renderInput={(params) => (
                <MDInput
                  variant="standard"
                  type="text"
                  label="Area"
                  fullWidth
                  required
                  InputLabelProps={{ shrink: true }}
                  {...params}
                />
              )}
            />
          </Grid>
        </Grid>
      </MDBox>
    </BKModal>
  );

  const renderModalConfiguration = (
    <BKModal
      closeModal={closeModalConfiguration}
      saveChanges={saveDataModalConfiguration}
      open={modalConfiguration}
      title="Configuración"
    >
      <MDBox sx={{ position: "relative", mx: 3, py: 2, px: 2 }}>
        <Grid container spacing={3} justifyContent="left" alignItems="left" height="100%">
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <MDInput
              variant="standard"
              type="number"
              label="Número Máximo Administradores"
              fullWidth
              required
              inputProps={{ min: 1, max: 10 }}
              InputLabelProps={{ shrink: true }}
              value={newValueNoMaxPerfilesAdmin}
              onChange={(e) => {
                setNewValueNoMaxPerfilesAdmin(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <MDInput
              variant="standard"
              type="number"
              label="Número Máximo Perfiles"
              fullWidth
              required
              inputProps={{ min: 1, max: 10 }}
              InputLabelProps={{ shrink: true }}
              value={newValueNoMaxPerfilesNormales}
              onChange={(e) => {
                setNewValueNoMaxPerfilesNormales(e.target.value);
              }}
            />
          </Grid>
        </Grid>
      </MDBox>
    </BKModal>
  );

  const getJsonData = async () => {
    const tempJsonData = [];
    const tempNames = [];
    const perfilesApi = await (await APIRequest("GET", "/Api/Perfil/Todos", null)).json();

    for (let index = 0; index < perfilesApi.length; index += 1) {
      tempJsonData.push({
        id: perfilesApi[index].id,
        nombre: perfilesApi[index].nombre,
        tipoPerfil: {
          id: perfilesApi[index].tipoPerfil.id,
          nombre: perfilesApi[index].tipoPerfil.nombre,
        },
        area: {
          id: perfilesApi[index].area.id,
          nombre: perfilesApi[index].area.nombre,
        },
      });
      tempNames.push(perfilesApi[index].nombre.toUpperCase());
    }

    setDifferentElements(tempNames);
    setJsonData(tempJsonData);
  };

  const deleteJsonData = async (id) => {
    if (window.confirm("¿Quiere eliminar el perfil?")) {
      try {
        const apiResponse = await APIRequest("DELETE", `/Api/Perfil/${id}`, null);

        if (apiResponse.status === 200) {
          const index = jsonData.map((item) => item.id).indexOf(id);
          const elements = [...jsonData];
          const nombrePerfil = elements[index].nombre;
          elements.splice(index, 1);
          setJsonData(elements);

          const tempNames = differentElements;
          tempNames.splice(index, 1);
          setDifferentElements(tempNames);

          showNotificationSuccess(`Se eliminó el perfil '${nombrePerfil}' exitosamente.`);
        } else {
          const errorMessage = {
            code: apiResponse.status,
            message: `Error: ${apiResponse.status}`,
          };
          throw errorMessage;
        }
      } catch (e) {
        showNotificationError(`Ocurrió un error al eliminar el perfil: ${e.message}`);
      }
    }
  };

  const setEditJsonData = (valueId) => {
    setElementIsNew(false);
    setNewValueId(valueId);

    const index = jsonData.map((item) => item.id).indexOf(valueId);
    setNewValueId(jsonData[index].id);
    setNewValueName(jsonData[index].nombre);
    setNewValueType(jsonData[index].tipoPerfil);
    setNewValueArea(jsonData[index].area);

    setModal(true);
  };

  const getSelectData = async () => {
    const tipoPerfil = await (await APIRequest("GET", "/Api/TipoPerfil/Todos", null)).json();
    const area = await (await APIRequest("GET", "/Api/Area/Todos", null)).json();

    const tipoPerfilResponse = [];

    for (let index = 0; index < tipoPerfil.length; index += 1) {
      if (userType === 1 && tipoPerfil[index].id !== 1) {
        tipoPerfilResponse.push(tipoPerfil[index]);
      } else if (userType === 2 && tipoPerfil[index].id === 3) {
        tipoPerfilResponse.push(tipoPerfil[index]);
        setDefaultValueType(tipoPerfil[index]);
      }
    }

    const userAreaAdmin = JSON.parse(sessionStorage.getItem("userAreaAdmin"));
    const areaResponse = [];

    for (let index = 0; index < area.length; index += 1) {
      if (userAreaAdmin.indexOf(area[index].id) >= 0 || userType === 1) {
        areaResponse.push(area[index]);
      }

      if (userAreaAdmin.indexOf(area[index].id) >= 0 && userType === 2) {
        setDefaultValueArea(area[index]);
      }
    }

    setSelectData({
      type: tipoPerfilResponse,
      area: areaResponse,
    });
  };

  const getMaxProfiles = async () => {
    const apiResponse = await (await APIRequest("GET", "/Api/ConfigArea/1", null)).json();

    setNewValueNoMaxPerfilesAdmin(apiResponse.numeroMaximoPerfilesAdmin);
    setNewValueNoMaxPerfilesNormales(apiResponse.numeroMaximoPerfilesNormales);
    setNewValueNoMaxPerfiles(apiResponse.numeroMaximoPerfilesNormales);
  };

  useEffect(async () => {
    getSelectData();
    getJsonData();
    getMaxProfiles();
  }, [pathname]);

  const { columns, rows } = data(jsonData, deleteJsonData, setEditJsonData);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {renderModal}
      {renderModalConfiguration}
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                <MDBox>
                  <MDTypography variant="h4" gutterBottom color="warning">
                    Perfiles
                  </MDTypography>
                  <MDTypography color="text">
                    Como administrador podrás crear hasta {newValueNoMaxPerfiles} perfiles
                  </MDTypography>
                </MDBox>
                <MDBox color="text" px={2}>
                  <Icon
                    sx={{ cursor: "pointer", fontWeight: "bold" }}
                    fontSize="small"
                    onClick={openMenu}
                  >
                    more_vert
                  </Icon>
                </MDBox>
                {renderMenu}
              </MDBox>
              <MDBox>
                <DataTable
                  entriesPerPage={(false, { defaultValue: 10, entries: [5, 10, 25, 50] })}
                  canSearch
                  showTotalEntries
                  table={{ columns, rows }}
                  pagination={{ variant: "contained", color: "warning" }}
                  isSorted
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {renderNotificacion}
      <Footer />
    </DashboardLayout>
  );
}

export default Profiles;
