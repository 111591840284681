import * as React from "react";
import { IMaskInput } from "react-imask";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

const TimeMaskCustom = React.forwardRef((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="00:#0:#0"
      definitions={{
        "#": /[0-5]/,
      }}
      placeholderChar=""
      placeholder="hh:mm:ss"
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});
TimeMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
export default TimeMaskCustom;
