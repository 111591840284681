/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDButton from "components/MDButton";
import Loading from "components/Loading";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import data from "views/consultoria/API/ActualizarRespuesta/data";

// API Request
import APIRequest from "api/request";

function ActualizarRespuesta() {
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  const [load, setLoad] = useState(false);
  const [jsonData, setJsonData] = useState([]);
  const { pathname } = useLocation();
  const route = useLocation().pathname;
  const navigate = useNavigate();

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };
  const showNotificationError = (message) => {
    configNotification("error", "warning", "Notificación", message, "", true);
  };
  const showNotificationSuccess = (message) => {
    configNotification("warning", "check", "Notificación", message, "", true);
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );
  // const renderModalGetDocument = (
  //   <BKModal
  //     closeModal={closeModalGetDocument}
  //     saveChanges={saveDataModal}
  //     open={modalGetDocument}
  //     title="GetDocument"
  //   >
  //     <MDBox sx={{ position: "relative", mx: 3, py: 2, px: 2 }}>
  //       <Grid container spacing={3} justifyContent="left" alignItems="left" height="100%">
  //         <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
  //           <Autocomplete
  //             value={newValuePais}
  //             onChange={(event, newValue) => {
  //               setNewValuePais(newValue);
  //             }}
  //             options={selectData.pais}
  //             disabled={!elementIsNew}
  //             getOptionLabel={(option) => option.nombre}
  //             isOptionEqualToValue={(option, value) => option.id === value.id}
  //             renderInput={(params) => (
  //               <MDInput
  //                 variant="standard"
  //                 type="text"
  //                 label="Pais"
  //                 fullWidth
  //                 required
  //                 disabled={!elementIsNew}
  //                 InputLabelProps={{ shrink: true }}
  //                 {...params}
  //               />
  //             )}
  //           />
  //         </Grid>
  //         <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
  //           <MDInput
  //             variant="standard"
  //             type="text"
  //             label="Tax ID"
  //             fullWidth
  //             required
  //             disabled={!elementIsNew}
  //             InputLabelProps={{ shrink: true }}
  //             value={newValueTaxId}
  //             onChange={(e) => {
  //               setNewValueTaxId(e.target.value);
  //             }}
  //           />
  //         </Grid>
  //       </Grid>
  //     </MDBox>
  //   </BKModal>
  // );

  const getJsonData = async (countries) => {
    const tempJsonData = [];
    const apiResponse = await (
      await APIRequest("GET", `/Api/ActualizarRespuesta/Todos/${userInfo.correo}`, null)
    ).json();

    for (let index = 0; index < apiResponse.length; index += 1) {
      tempJsonData.push({
        id: apiResponse[index].id,
        taxId: apiResponse[index].taxId,
        pais: countries[
          countries.map((item) => item.id).indexOf(apiResponse[index].pais.toLowerCase())
        ],
        metodo: {
          id: apiResponse[index].metodo,
          nombre: apiResponse[index].metodo === 1 ? "SendDocumentToAuthority" : "GetDocument",
        },
        parametro: apiResponse[index].parametro,
        xPath: apiResponse[index].xpath,
        estado: apiResponse[index].estado,
        fecha: apiResponse[index].fecha,
        usuario: apiResponse[index].usuario,
        documentCustomFields: apiResponse[index].getDocumentCustomFields,
        barCodeText: apiResponse[index].getBarCodeText,
      });
    }

    setJsonData(tempJsonData);
  };

  const deleteJsonData = async (jsonElement) => {
    if (window.confirm("¿Quiere eliminar el registro?")) {
      setLoad(true);
      try {
        const body = {
          TaxId: jsonElement.taxId,
          Pais: jsonElement.pais.id,
          Metodo: jsonElement.metodo.id,
          Parametro: jsonElement.parametro,
          Xpath: jsonElement.xPath,
          Estado: true,
          Usuario: userInfo.correo,
          EsNuevo: false,
          GetDocumentCustomFields: true,
          GetBarCodeText: true,
        };
        const apiResponse = await APIRequest("DELETE", `/Api/ActualizarRespuesta/0`, body);

        if (apiResponse.status === 200) {
          const index = jsonData.map((item) => item.id).indexOf(jsonElement.id);
          const elements = [...jsonData];
          const nombreElemento = elements[index].parametro;
          elements.splice(index, 1);
          setJsonData(elements);

          showNotificationSuccess(`Se eliminó el registro '${nombreElemento}' exitosamente.`);
        } else {
          const errorMessage = {
            code: apiResponse.status,
            message: `Error: ${apiResponse.status}`,
          };
          throw errorMessage;
        }
      } catch (e) {
        showNotificationError(`Ocurrió un error eliminar el registro: ${e.message}`);
      }
      setLoad(false);
    }
  };

  const changeStateJsonData = async (valueId) => {
    const tempJsonData = jsonData;
    const today = new Date();

    const index = tempJsonData.map((item) => item.id).indexOf(valueId);
    tempJsonData[index].estado = !tempJsonData[index].estado;
    tempJsonData[index].usuario = userInfo.correo;
    tempJsonData[index].fecha = `${today.getFullYear()}-${(today.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${today.getDate().toString().padStart(2, "0")}`;

    const body = {
      TaxId: tempJsonData[index].taxId,
      Pais: tempJsonData[index].pais.id,
      Metodo: tempJsonData[index].metodo.id,
      Parametro: tempJsonData[index].parametro,
      Xpath: tempJsonData[index].xPath,
      Estado: tempJsonData[index].estado,
      Usuario: userInfo.correo,
      EsNuevo: false,
      GetDocumentCustomFields: tempJsonData[index].documentCustomFields,
      GetBarCodeText: tempJsonData[index].barCodeText,
    };

    const apiResponse = await APIRequest("POST", "/Api/ActualizarRespuesta", body);

    if (apiResponse.status === 200) {
      setJsonData(tempJsonData);
      showNotificationSuccess(
        `Se actualizó el estado del tax ${tempJsonData[index].taxId} exitosamente`
      );
    } else {
      showNotificationError(`Error al actualizar el estado del tax ${tempJsonData[index].taxId}`);
    }
  };

  const setEditJsonData = (element) => {
    navigate("/Api/SendDocumentToAuthorityForm", { state: { route, element } });
  };

  const getCountriesData = async () => {
    const tempJsonData = [];
    const apiResponse = await (await APIRequest("GET", "/api/Pais/Todos", null)).json();

    if (apiResponse.length > 0) {
      for (let index = 0; index < apiResponse.length; index += 1) {
        tempJsonData.push({
          id: apiResponse[index].codigo.toLowerCase(),
          nombre: apiResponse[index].nombre,
        });
      }
    }

    return tempJsonData;
  };

  useEffect(async () => {
    setLoad(true);
    const tempCountries = await getCountriesData();
    await getJsonData(tempCountries);
    setLoad(false);
  }, [pathname]);

  const { columns, rows } = data(jsonData, deleteJsonData, setEditJsonData, changeStateJsonData);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={3}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <MDTypography variant="subtitle" fontWeight="bold">
              Permite incluir parámetros en el response del método SendDocumentToAuthority
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="left" alignItems="left" height="100%">
              <Grid item xs={12} sm={12} md={6} lg={4} xl={10} />
              <Grid item xs={12} sm={12} md={6} lg={4} xl={2} container justifyContent="flex-end">
                <MDButton
                  variant="contained"
                  onClick={() => navigate("/Api/SendDocumentToAuthorityForm", { state: { route } })}
                  sx={{
                    background: "linear-gradient(45deg, #1971C2 30%, #1971C2 90%)",
                    color: "#FFFFFF",
                  }}
                >
                  <Icon>add_circle_outline</Icon>&nbsp;Agregar
                </MDButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox>
                <DataTable
                  entriesPerPage={(false, { defaultValue: 10, entries: [5, 10, 25, 50] })}
                  canSearch
                  showTotalEntries
                  table={{ columns, rows }}
                  pagination={{ variant: "contained", color: "warning" }}
                  isSorted
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {renderNotificacion}
      <Loading load={load} />
      <Footer />
    </DashboardLayout>
  );
}

export default ActualizarRespuesta;
