/* eslint-disable react/prop-types */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// API Request
import APIRequest from "api/xDocApiRequest";

// Custom components
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

function PrerequisiteInstallerEdit() {
  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  const {
    state: { prerequisite, route },
  } = useLocation();
  const [name, setName] = useState(prerequisite.name);
  const [installer, setInstaller] = useState({});
  const [versionMin, setVersionMin] = useState(prerequisite.versionMin);
  const [versionMax, setVersionMax] = useState(prerequisite.versionMax);
  const navigate = useNavigate();

  const onChangeName = (e) => {
    setName(e.target.value);
  };
  const onChangeVersionMin = (e) => {
    setVersionMin(e.target.value);
  };
  const onChangeVersionMax = (e) => {
    setVersionMax(e.target.value);
  };

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );

  const onFileChange = (e) => {
    const fileSelected = e.target.files[0];
    if (!fileSelected) {
      return;
    }
    const nameArray = fileSelected.name.split(".");
    if (
      nameArray.length < 2 ||
      (nameArray[nameArray.length - 1].toLowerCase() !== "msi" &&
        nameArray[nameArray.length - 1].toLowerCase() !== "exe")
    ) {
      configNotification(
        "error",
        "warning",
        "Notificación",
        `Ha intentado cargar un instalador con formato inválido.`,
        "",
        true
      );
      return;
    }
    setInstaller(fileSelected);
  };

  const updatePrerrequisite = async () => {
    try {
      const formData = new FormData();
      formData.append("id", prerequisite.id);
      formData.append("name", name);
      formData.append("installer", installer);
      formData.append("versionMin", versionMin);
      formData.append("versionMax", versionMax);
      const apiResponse = await APIRequest("POST", "/Prerequisite/Update", formData, true);

      if (apiResponse.status === 200) {
        navigate("/AdministracionXDoc/Versiones/Prerrequisitos", {
          state: {
            notificationParams: [
              "warning",
              "check",
              "Notificación",
              `Se modificó el prerrequisito '${name}' exitosamente.`,
              "",
              true,
            ],
          },
        });
      } else {
        const errorMessage = {
          code: apiResponse.status,
          message: `Error: ${apiResponse.status}`,
        };
        throw errorMessage;
      }
    } catch (e) {
      configNotification(
        "error",
        "warning",
        "Notificación",
        `Ocurrió un error al modificar el prerrequisito las configuraciones: ${e.message}`,
        "",
        true
      );
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar breadCrumb={route.split("/").slice(1)} />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                <MDBox>
                  <MDTypography variant="h4" gutterBottom color="warning">
                    Editar prerrequisito de instalación
                  </MDTypography>
                </MDBox>
                <MDBox>
                  <MDTypography color="text" onClick={() => navigate(route)}>
                    <Tooltip title="Regresar" disableInteractive>
                      <Icon fontSize="large">arrow_back</Icon>
                    </Tooltip>
                  </MDTypography>
                </MDBox>
              </MDBox>
              <MDBox style={{ margin: "20px" }}>
                <MDBox style={{ width: "350px" }}>
                  <MDInput label="Nombre" onChange={onChangeName} defaultValue={name} fullWidth />
                </MDBox>
                <MDBox style={{ marginTop: "15px" }}>
                  <MDTypography variant="h6" gutterBottom color="text">
                    <MDButton
                      variant="outlined"
                      component="label"
                      color="info"
                      style={{ marginRight: "10px" }}
                    >
                      <Icon style={{ marginRight: "5px" }}>upload</Icon>
                      Cargar instalador
                      <input type="file" onChange={onFileChange} hidden />
                    </MDButton>
                    {installer.name || prerequisite.blobName}
                  </MDTypography>
                </MDBox>
                <MDBox
                  style={{
                    marginTop: "15px",
                    width: "300px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <MDBox style={{ width: "140px" }}>
                    <MDInput
                      label="Versión mínima"
                      onChange={onChangeVersionMin}
                      defaultValue={versionMin}
                      fullWidth
                    />
                  </MDBox>
                  <MDBox style={{ width: "140px" }}>
                    <MDInput
                      label="Versión máxima"
                      onChange={onChangeVersionMax}
                      defaultValue={versionMax}
                      fullWidth
                    />
                  </MDBox>
                </MDBox>
                <MDBox style={{ marginTop: "20px" }}>
                  <MDButton
                    style={{ marginRight: "10px" }}
                    variant="outlined"
                    onClick={updatePrerrequisite}
                    color="success"
                  >
                    Guardar
                  </MDButton>
                  <MDButton
                    variant="outlined"
                    onClick={() => navigate("/AdministracionXDoc/Versiones/Prerrequisitos")}
                    color="warning"
                  >
                    Cancelar
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {renderNotificacion}
      <Footer />
    </DashboardLayout>
  );
}

export default PrerequisiteInstallerEdit;
