/* eslint-disable react/prop-types */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// @mui material components
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// Data
import data from "views/admin/xdoc/versions/data";

// API Request
import APIRequest from "api/xDocApiRequest";

function Versions() {
  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  const [jsonData, setJsonData] = useState([]);
  const [canRevertLast, setCanRevertLast] = useState(false);
  const { pathname, state } = useLocation();
  const route = useLocation().pathname;
  const navigate = useNavigate();

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );

  const checkCanRevertLast = async () => {
    const canRevert = await (
      await APIRequest("GET", "/Version/LastVersionReversable", null)
    ).json();
    return canRevert;
  };

  const getJsonData = async () => {
    const tempJsonData = [];

    const apiResponse = await (await APIRequest("GET", "/Version/GetAll", null)).json();

    apiResponse.forEach((item) => {
      tempJsonData.push(item);
    });

    setJsonData(tempJsonData);

    setCanRevertLast(await checkCanRevertLast());
  };

  const approveVersion = (version) =>
    navigate("/AdministracionXDoc/Versiones/Aprobar", { state: { version, route } });

  const [showConfirmRevert, setShowConfirmRevert] = useState(false);
  const [versionToRevert, setVersionToRevert] = useState("");
  const revertLast = async (versionNumber, confirmed) => {
    if (!confirmed) {
      setVersionToRevert(versionNumber);
      setShowConfirmRevert(true);
      return;
    }

    try {
      if (!(await checkCanRevertLast())) {
        configNotification(
          "warning",
          "check",
          "Notificación",
          `No se puede revertir la aprobación la versión '${versionNumber}' porque ya se encuentra en uso.`,
          "",
          true
        );
        getJsonData();
        return;
      }

      const apiResponse = await APIRequest("GET", "/Version/RevertLastApproved", null);

      if (apiResponse.status === 200) {
        getJsonData();
        configNotification(
          "warning",
          "check",
          "Notificación",
          `Se revirtió la aprobación la versión '${versionNumber}' exitosamente.`,
          "",
          true
        );
      } else {
        const errorMessage = {
          code: apiResponse.status,
          message: `Error: ${apiResponse.status}`,
        };
        throw errorMessage;
      }
    } catch (e) {
      configNotification(
        "error",
        "warning",
        "Notificación",
        `Ocurrió un error al revertir la aprobación de la versión: ${e.message}`,
        "",
        true
      );
    }
  };
  const handleCancelRevert = () => {
    setShowConfirmRevert(false);
  };
  const handleConfirmRevert = () => {
    revertLast(versionToRevert, true);
    setShowConfirmRevert(false);
  };

  const getReleaseNotes = async (versionNumber) => {
    try {
      return await (
        await APIRequest("GET", `/Version/GetReleaseNotes?version=${versionNumber}`, null)
      ).json();
    } catch (e) {
      configNotification(
        "error",
        "warning",
        "Notificación",
        `Ocurrió un error al obtener las notas de versión '${e.message}'.`,
        "",
        true
      );
      return false;
    }
  };

  const editVersion = async (version) => {
    const releaseNotes = await getReleaseNotes(version.number);
    if (!releaseNotes) {
      return;
    }
    navigate("/AdministracionXDoc/Versiones/Editar", { state: { version, releaseNotes, route } });
  };

  const viewDetails = async (version) => {
    const releaseNotes = await getReleaseNotes(version.number);
    if (!releaseNotes) {
      return;
    }
    navigate("/AdministracionXDoc/Versiones/Detalles", { state: { version, releaseNotes, route } });
  };

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [versionToDelete, setVersionToDelete] = useState({});
  const deleteJsonData = async (versionNumber, confirmed) => {
    if (!confirmed) {
      setVersionToDelete(versionNumber);
      setShowConfirmDelete(true);
      return;
    }

    try {
      const apiResponse = await APIRequest(
        "DELETE",
        `/Version/Delete?version=${versionNumber}`,
        null
      );

      if (apiResponse.status === 200) {
        const index = jsonData.map((item) => item.number).indexOf(versionNumber);
        const elements = [...jsonData];
        elements.splice(index, 1);
        setJsonData(elements);
        configNotification(
          "warning",
          "check",
          "Notificación",
          `Se eliminó la versión '${versionNumber}' exitosamente.`,
          "",
          true
        );
      } else {
        const errorMessage = {
          code: apiResponse.status,
          message: `Error: ${apiResponse.status}`,
        };
        throw errorMessage;
      }
    } catch (e) {
      configNotification(
        "error",
        "warning",
        "Notificación",
        `Ocurrió un error al eliminar la versión: ${e.message}`,
        "",
        true
      );
    }
  };
  const handleCancelDelete = () => {
    setShowConfirmDelete(false);
  };
  const handleConfirmDelete = () => {
    deleteJsonData(versionToDelete, true);
    setShowConfirmDelete(false);
  };

  useEffect(() => {
    if (state && state.notificationParams) {
      configNotification(...state.notificationParams);
      window.history.replaceState({}, document.title);
    }
    getJsonData();
  }, [pathname]);

  const { columns, rows } = data(
    jsonData,
    deleteJsonData,
    approveVersion,
    editVersion,
    viewDetails,
    canRevertLast,
    revertLast
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                <MDBox>
                  <MDTypography variant="h4" gutterBottom color="warning">
                    Versiones
                  </MDTypography>
                </MDBox>
                <MDBox>
                  <MDTypography
                    color="text"
                    onClick={() =>
                      navigate("/AdministracionXDoc/Versiones/Configuraciones", {
                        state: { route },
                      })
                    }
                  >
                    <Tooltip title="Configuraciones" disableInteractive>
                      <Icon fontSize="large">settings</Icon>
                    </Tooltip>
                  </MDTypography>
                </MDBox>
              </MDBox>
              <MDBox>
                <DataTable
                  entriesPerPage={(false, { defaultValue: 10, entries: [5, 10, 25, 50] })}
                  canSearch
                  showTotalEntries
                  table={{ columns, rows }}
                  pagination={{ variant: "contained", color: "warning" }}
                  isSorted
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {renderNotificacion}
      <Dialog
        open={showConfirmDelete}
        onClose={handleCancelDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">¿Quiere eliminar la versión?</DialogTitle>
        <DialogActions>
          <Button onClick={handleCancelDelete}>Cancelar</Button>
          <Button onClick={handleConfirmDelete} autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showConfirmRevert}
        onClose={handleCancelRevert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">¿Quiere revertir la aprobación?</DialogTitle>
        <DialogActions>
          <Button onClick={handleCancelRevert}>Cancelar</Button>
          <Button onClick={handleConfirmRevert} autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
      <Footer />
    </DashboardLayout>
  );
}

export default Versions;
