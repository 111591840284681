import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";

function ReusableTabs({ tabs, isEditing }) {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <MDBox sx={{ width: "100%" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="tabs"
        style={{
          marginBottom: 10,
          boxShadow:
            "0rem 0.25rem 0.375rem -0.0625rem rgba(0, 0, 0, 0.1), 0rem 0.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 0.06)",
        }}
      >
        {tabs.map((tab) => (
          <Tab
            key={tab.id}
            label={tab.label}
            disabled={isEditing && value !== tab.id} // Deshabilitar las pestañas si isEditing es true y no es la pestaña actual
            sx={{
              backgroundColor: value === tab.id ? "#53CFB3" : "none",
              color: isEditing && value !== tab.id ? "gray" : "#344767",
              fontWeight: 500,
            }}
          />
        ))}
      </Tabs>
      {tabs.map((tab) => (
        <Typography
          key={tab.id}
          component="div"
          role="tabpanel"
          hidden={value !== tab.id}
          id={`tabpanel-${tab.id}`}
          aria-labelledby={`tab-${tab.id}`}
        >
          {tab.content}
        </Typography>
      ))}
    </MDBox>
  );
}

ReusableTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      content: PropTypes.node.isRequired,
    })
  ).isRequired,
  isEditing: PropTypes.bool.isRequired,
};

export default ReusableTabs;
