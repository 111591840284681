/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
// eslint-disable-next-line no-unused-vars
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import { v4 as uuidv4 } from "uuid";

// Data
import data from "views/varios/SingleSingOnSBX/data";

// API Request
// import APIRequest from "api/request";
import APIRequestGeneric from "api/requestGeneric";

function SingleSingOnSBX() {
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  // const idFuncionalidad = 0;
  const host = process.env.REACT_APP_API_URL;
  const basicAuth = `${process.env.REACT_APP_API_ADMIN_SBX_USER}:${process.env.REACT_APP_API_ADMIN_SBX_PASSWORD}`;
  const basicAuthorization = `Basic ${btoa(basicAuth)}`;

  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  const [jsonData, setJsonData] = useState([]);
  const { pathname } = useLocation();
  const route = pathname;
  const navigate = useNavigate();

  const [expandAccordion, setExpandAccordion] = useState("panel1");
  const [newValueSingleSingOnSandbox, setNewValueSingleSingOnSandbox] = useState("");
  const [newValueAudienceRestrictionSandbox, setNewValueAudienceRestrictionSandbox] = useState("");

  // eslint-disable-next-line no-unused-vars
  const [selectData, setSelectData] = useState({
    estado: [
      { id: 1, nombre: "Positivo" },
      { id: 2, nombre: "Negativo" },
    ],
    ambiente: [{ id: 1, nombre: "Sandbox" }],
  });

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };
  const showNotificationError = (message) => {
    configNotification("error", "warning", "Notificación", message, "", true);
  };
  const showNotificationSuccess = (message) => {
    configNotification("warning", "check", "Notificación", message, "", true);
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );

  const getJsonData = async () => {
    const tempJsonData = [];
    const body = {
      UsuarioUltimaAccion: userInfo.correo,
      Ambiente: 1, // 0:Todos, 1: Sandbox, 2: Producción
    };
    const urlComplete = `${host}/api/ManagerSSO/GetInformationSSO`;
    const apiResponse = await (
      await APIRequestGeneric("POST", urlComplete, body, false, basicAuthorization)
    ).json();

    const responseData = JSON.parse(apiResponse.Data);

    for (let index = 0; index < responseData.length; index += 1) {
      tempJsonData.push({
        id: uuidv4(),
        nombreOrga: responseData[index].RowKey,
        dominios: responseData[index].Domains,
        // ambiente: { id: 1, nombre: "Sandbox" },
        ambiente:
          selectData.ambiente[
            selectData.ambiente.map((item) => item.id).indexOf(responseData[index].Ambiente)
          ],
        saml2EntityId: !responseData[index].Saml2EntityId ? "" : responseData[index].Saml2EntityId,
        saml2MetadataUrl: responseData[index].Saml2MetadataUri,
        saml2SingleSignOnServiceUrl: responseData[index].Saml2SingleSignOnServiceUrl,
        usuario: responseData[index].UsuarioUltimaAccion,
      });
    }

    setJsonData(tempJsonData);
  };

  const deleteJsonData = async (id) => {
    if (window.confirm("¿Quiere deshabilitar el registro?")) {
      try {
        const index = jsonData.map((item) => item.id).indexOf(id);
        const elements = [...jsonData];

        const body = {
          RowKey: elements[index].nombreOrga,
          PartitionKey: "sso",
          UsuarioUltimaAccion: userInfo.correo,
          Ambiente: elements[index].ambiente.id, // 1: Sandbox, 2: Producción
        };
        const urlComplete = `${host}/api/ManagerSSO/DeleteInformationSSOByCompany`;
        const apiResponse = await (
          await APIRequestGeneric("DELETE", urlComplete, body, false, basicAuthorization)
        ).json();

        if (apiResponse.Code === 200 && apiResponse.IsSuccessful) {
          const nombreElemento = elements[index].nombreOrga;
          elements.splice(index, 1);
          setJsonData(elements);

          showNotificationSuccess(`Se deshabilitó el registro '${nombreElemento}' exitosamente.`);
        } else {
          showNotificationError(`Error: ${apiResponse.Message}`);
        }
      } catch (e) {
        showNotificationError(`Ocurrió un error al deshabilitar el registro: ${e.message}`);
      }
    }
  };

  const setEditJsonData = (element) => {
    navigate("/SingleSingOn/SBXForm", { state: { route, element } });
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpandAccordion(newExpanded ? panel : false);
  };

  useEffect(() => {
    setNewValueSingleSingOnSandbox("https://auth-sbx.gosocket.net/core/Saml2/Acs");
    setNewValueAudienceRestrictionSandbox("https://auth-sbx.gosocket.net");

    getJsonData();
  }, [pathname]);

  const { columns, rows } = data(jsonData, deleteJsonData, setEditJsonData);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MDTypography variant="subtitle" fontWeight="bold">
              Permite habilitar Single Sing On para un TaxID
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDBox sx={{ position: "relative" /* , mx: 3, py: 2, px: 2 */ }}>
              <Accordion
                aria-controls="panel1a-content"
                id="panel1a-header"
                expanded={expandAccordion === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <MDTypography variant="h4" gutterBottom color="text">
                    Información a entregar al cliente
                  </MDTypography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid
                    container
                    spacing={3}
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                  >
                    <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                      <MDTypography variant="h5" color="text">
                        Sandbox
                      </MDTypography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                      <MDInput
                        variant="standard"
                        type="text"
                        label="Single Sing On"
                        fullWidth
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={newValueSingleSingOnSandbox}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                      <MDInput
                        variant="standard"
                        type="text"
                        label="Audience Restriction"
                        fullWidth
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={newValueAudienceRestrictionSandbox}
                      />
                    </Grid>
                  </Grid>
                  <MDTypography variant="subtitle2" color="error">
                    Importante
                    <br />
                    <br />
                    Autenticación SSO por email
                    <br />
                    Si el cliente no realiza esta configuración, SSO no podrá funcionar
                    <br />
                    Comunicación bajo protocolo SAML 2.0
                  </MDTypography>
                </AccordionDetails>
              </Accordion>
            </MDBox>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="left" alignItems="left" height="100%">
              <Grid item xs={12} sm={12} md={6} lg={4} xl={10} />
              <Grid item xs={12} sm={12} md={6} lg={4} xl={2} container justifyContent="flex-end">
                <MDButton
                  variant="contained"
                  onClick={() => navigate("/SingleSingOn/SBXForm", { state: { route } })}
                  sx={{
                    background: "linear-gradient(45deg, #1971C2 30%, #1971C2 90%)",
                    color: "#FFFFFF",
                  }}
                >
                  <Icon>add_circle_outline</Icon>&nbsp;Agregar
                </MDButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox>
                <DataTable
                  entriesPerPage={(false, { defaultValue: 10, entries: [5, 10, 25, 50] })}
                  canSearch
                  showTotalEntries
                  table={{ columns, rows }}
                  pagination={{ variant: "contained", color: "warning" }}
                  isSorted
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {renderNotificacion}
      <Footer />
    </DashboardLayout>
  );
}

export default SingleSingOnSBX;
