/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// @mui material components
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Loading from "components/Loading";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import data from "views/partners/CrearEmpresa/data";

// API Request
import APIRequest from "api/request";

function PartnersCrearEmpresa() {
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  const [load, setLoad] = useState(false);
  const [jsonData, setJsonData] = useState([]);
  const { pathname } = useLocation();

  const [newValuePais, setNewValuePais] = useState(null);
  const [newValueTaxId, setNewValueTaxId] = useState("");
  const [newValueNombre, setNewValueNombre] = useState("");
  const [newValueEmailAdmin, setNewValueEmailAdmin] = useState("");

  const [selectData, setSelectData] = useState({
    pais: [
      { id: "cr", nombre: "Costa rica" },
      { id: "ec", nombre: "Ecuador" },
      { id: "pe", nombre: "Perú" },
      { id: "cl", nombre: "Chile" },
      { id: "mx", nombre: "México" },
      { id: "gt", nombre: "Guatemala" },
      { id: "co", nombre: "Colombia" },
      { id: "pa", nombre: "Panamá" },
    ],
  });

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };
  const showNotificationError = (message) => {
    configNotification("error", "warning", "Notificación", message, "", true);
  };
  const showNotificationSuccess = (message) => {
    configNotification("warning", "check", "Notificación", message, "", true);
  };

  const getJsonData = async (countries) => {
    const tempJsonData = [];
    const apiResponse = await (await APIRequest("GET", "/Api/Empresa/Todos", null)).json();

    for (let index = 0; index < apiResponse.length; index += 1) {
      tempJsonData.push({
        accountId: apiResponse[index].accountId,
        userId: apiResponse[index].userId,
        partitionKey: `${apiResponse[index].taxId}_${apiResponse[index].pais}`,
        pais: countries[
          countries.map((item) => item.id).indexOf(apiResponse[index].pais.trim().toLowerCase()) // Country,
        ],
        taxId: apiResponse[index].taxId,
        nombre: apiResponse[index].nombre,
        usuarioCreado: apiResponse[index].usuarioCreado,
        fechaCreacion: apiResponse[index].fechaCreacion.substring(0, 10),
        usuarioQueCreo: apiResponse[index].usuarioQueCreo,
      });
    }

    setJsonData(tempJsonData);
  };

  const saveDataModal = async () => {
    setLoad(true);
    try {
      if (
        jsonData.map((item) => item.partitionKey).indexOf(`${newValueTaxId}_${newValuePais.id}`) ===
        -1
      ) {
        const tempJsonData = jsonData;
        const today = new Date();

        const bodyBD = {
          AccountId: "", // jsonResponse.AccountId,
          UserId: "", // jsonResponse.UserId,
          Pais: newValuePais.id,
          TaxId: newValueTaxId,
          Nombre: newValueNombre,
          UsuarioCreado: newValueEmailAdmin,
          FechaCreacion: `${today.getFullYear()}-${(today.getMonth() + 1)
            .toString()
            .padStart(2, "0")}-${today.getDate().toString().padStart(2, "0")}`,
          UsuarioQueCreo: userInfo.correo,
        };
        const apiBDResponse = await APIRequest("POST", "/Api/Empresa", bodyBD);

        if (apiBDResponse.status === 200 || apiBDResponse.status === 201 || apiBDResponse.ok) {
          const jsonResponse = await apiBDResponse.json();

          const tempElement = {
            accountId: jsonResponse.accountId,
            userId: jsonResponse.userId,
            partitionKey: `${newValueTaxId}_${newValuePais.id}`,
            pais: newValuePais,
            taxId: newValueTaxId,
            nombre: newValueNombre,
            usuarioCreado: newValueEmailAdmin,
            fechaCreacion: `${today.getFullYear()}-${(today.getMonth() + 1)
              .toString()
              .padStart(2, "0")}-${today.getDate().toString().padStart(2, "0")}`,
            usuarioQueCreo: userInfo.correo,
          };

          tempJsonData.push(tempElement);

          setJsonData(tempJsonData);
          showNotificationSuccess("Se agregó exitosamente el registro");
        } else {
          showNotificationError(`Error al guardar el registro, estado: ${apiBDResponse.status}`);
        }
      } else {
        showNotificationSuccess("Elemento ya registrado");
      }
    } catch {
      showNotificationError("Error Inesperado");
    }

    setLoad(false);
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );

  const getCountriesData = async () => {
    const tempJsonData = [];
    const apiResponse = await (await APIRequest("GET", "/api/Pais/Todos", null)).json();

    if (apiResponse.length > 0) {
      for (let index = 0; index < apiResponse.length; index += 1) {
        tempJsonData.push({
          id: apiResponse[index].codigo.toLowerCase(),
          nombre: apiResponse[index].nombre,
        });
      }

      setSelectData({
        pais: tempJsonData,
        // status: selectData.status,
      });
    }

    return tempJsonData;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    saveDataModal();
  };

  useEffect(async () => {
    const countries = await getCountriesData();
    await getJsonData(countries);
  }, [pathname]);

  const { columns, rows } = data(jsonData);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={3}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <MDTypography variant="subtitle" fontWeight="bold">
              Permite crear empresa en Inbox y asociar usuario primario tipo administrador
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox
                sx={{ position: "relative", mx: 3, py: 2, px: 2 }}
                component="form"
                onSubmit={handleSubmit}
              >
                <Grid container spacing={3} justifyContent="left" alignItems="left" height="100%">
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <Autocomplete
                      value={newValuePais}
                      onChange={(event, newValue) => {
                        setNewValuePais(newValue);
                      }}
                      options={selectData.pais}
                      getOptionLabel={(option) => option.nombre}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderInput={(params) => (
                        <MDInput
                          variant="standard"
                          type="text"
                          label="Pais"
                          fullWidth
                          required
                          InputLabelProps={{ shrink: true }}
                          {...params}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <MDInput
                      variant="standard"
                      type="text"
                      label="TaxID Ortanización"
                      fullWidth
                      required
                      InputLabelProps={{ shrink: true }}
                      value={newValueTaxId}
                      onChange={(e) => {
                        setNewValueTaxId(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <MDInput
                      variant="standard"
                      type="text"
                      label="Nombre Organización"
                      fullWidth
                      required
                      inputProps={{ maxLength: 150 }}
                      InputLabelProps={{ shrink: true }}
                      value={newValueNombre}
                      onChange={(e) => {
                        setNewValueNombre(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <MDInput
                      variant="standard"
                      type="text"
                      label="Ingrese email usuario Admin"
                      fullWidth
                      required
                      InputLabelProps={{ shrink: true }}
                      value={newValueEmailAdmin}
                      onChange={(e) => {
                        setNewValueEmailAdmin(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={3}
                    container
                    // justifyContent="flex-end"
                  >
                    <MDButton
                      variant="contained"
                      type="submit"
                      sx={{
                        background: "linear-gradient(45deg, #099268 30%, #099268 90%)",
                        color: "#FFFFFF",
                      }}
                    >
                      <Icon>save</Icon>&nbsp;Guardar
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox>
                <DataTable
                  entriesPerPage={(false, { defaultValue: 10, entries: [5, 10, 25, 50] })}
                  canSearch
                  showTotalEntries
                  table={{ columns, rows }}
                  pagination={{ variant: "contained", color: "warning" }}
                  isSorted
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {renderNotificacion}
      <Loading load={load} />
      <Footer />
    </DashboardLayout>
  );
}

export default PartnersCrearEmpresa;
