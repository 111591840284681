/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";

// Default Components
import MDTypography from "components/MDTypography";

export default function data(jsonData, descargarPLantilla) {
  const getRows = () => {
    const elements = [];

    for (let index = 0; index < jsonData.length; index += 1) {
      elements.push({
        tipo: jsonData[index].tipo,
        docType: jsonData[index].docType,
        serie: jsonData[index].serie,
        folio: jsonData[index].folio,
        docKey: jsonData[index].docKey,
        parseOk: (
          <MDTypography
            variant="body2"
            color="text"
            display={!jsonData[index].parseOk ? "none" : "flex"}
          >
            <Icon>circle</Icon>
          </MDTypography>
        ),
        globalDocId: jsonData[index].globalDocId,
        adjunto: (
          <MDTypography
            variant="subtitle2"
            fontWeight="light"
            color="text"
            display={jsonData[index].linkAdjunto.length > 0 ? "flex" : "none"}
          >
            <Tooltip
              title="Descargar"
              disableInteractive
              onClick={() => {
                descargarPLantilla(jsonData[index].id);
              }}
            >
              <Icon>download</Icon>
            </Tooltip>
          </MDTypography>
        ),
        inbox: (
          <Link
            href={jsonData[index].linkInbox}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <Tooltip title="Ir a Inbox" disableInteractive>
              <Icon>send</Icon>
            </Tooltip>
          </Link>
        ),
      });
    }

    return elements;
  };

  return {
    columns: [
      // { Header: "", accessor: "icon", width: "0%", align: "center" },
      { Header: "Tipo", accessor: "tipo", align: "center" },
      { Header: "DocType", accessor: "docType", align: "center" },
      { Header: "Serie", accessor: "serie", align: "center" },
      { Header: "Folio", accessor: "folio", align: "center" },
      { Header: "DocKey", accessor: "docKey", align: "center" },
      { Header: "Parse Ok", accessor: "parseOk", align: "center" },
      { Header: "Global DocId", accessor: "globalDocId", align: "center" },
      { Header: "Adjunto", accessor: "adjunto", align: "center" },
      { Header: "Inbox", accessor: "inbox", align: "center" },
      // { Header: "Accion", accessor: "action", align: "center" },
    ],

    rows: getRows(),
  };
}

// Setting default values for the props of MDBadge
data.defaultProps = {
  jsonData: [],
};

// Typechecking props of the data
data.propTypes = {
  jsonData: PropTypes.objectOf(PropTypes.array),
  descargarPLantilla: PropTypes.func.isRequired,
};
