import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { CUSTOM_UPLOAD_COMPONENT } from "views/admin/xpos/_constants/configGlobal/showContent";
import XposCollapse from "../../shared/XposCollapse";
import CustomUpload from "../CustomUpload";
import Storage from "../Storage";

function Url({
  country,
  items,
  showNotificationError,
  showNotificationSuccess,
  setLoading,
  organizationConfigGuid,
}) {
  const [openState, setOpenState] = useState([]);

  useEffect(() => {
    if (items?.length) {
      setOpenState(new Array(items.length).fill(false));
    }
  }, [items]);

  const handleToggle = (index) => {
    setOpenState((prevState) => prevState.map((isOpen, i) => (i === index ? !isOpen : isOpen)));
  };

  return (
    <div>
      {items.map((item, index) => (
        <XposCollapse
          title={item.title}
          open={openState[index] ?? false}
          onClick={() => handleToggle(index)}
          key={item.title}
        >
          {item.component === CUSTOM_UPLOAD_COMPONENT.CUSTOM_UPLOAD && (
            <CustomUpload
              {...item?.propsComponent}
              country={country}
              showNotificationError={showNotificationError}
              showNotificationSuccess={showNotificationSuccess}
              setLoading={setLoading}
              organizationConfigGuid={organizationConfigGuid}
              data={item?.data}
            />
          )}

          {item.component === CUSTOM_UPLOAD_COMPONENT.STORAGE && (
            <Storage
              country={country}
              showNotificationError={showNotificationError}
              showNotificationSuccess={showNotificationSuccess}
              setLoading={setLoading}
              organizationConfigGuid={organizationConfigGuid}
              data={item?.data}
            />
          )}
        </XposCollapse>
      ))}
    </div>
  );
}

Url.defaultProps = {
  organizationConfigGuid: "",
};

Url.propTypes = {
  country: PropTypes.string.isRequired,
  showNotificationError: PropTypes.func.isRequired,
  showNotificationSuccess: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  items: PropTypes.array.isRequired,
  organizationConfigGuid: PropTypes.string,
};
export default Url;
