/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDButton from "components/MDButton";
import Loading from "components/Loading";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import data from "views/varios/ComunicacionPAC/data";
import dataV from "views/varios/ComunicacionPAC/dataV";

// API Request
import APIRequestGeneric from "api/requestGeneric";

function ComunicacionPac() {
  const host = process.env.REACT_APP_API_URL;
  const apiKeyAdmin = process.env.REACT_APP_API_KEY_PAC_ADMIN;

  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  const [load, setLoad] = useState(false);
  const [element, setElement] = useState(null);
  const [jsonData, setJsonData] = useState([]);
  const [jsonDataVariables, setJsonDataVariables] = useState([]);

  const [colorSchedule, setColorSchedule] = useState(null);
  const [nameSchedule, setNameSchedule] = useState(null);
  const [iconSchedule, setIconSchedule] = useState(null);

  const { pathname } = useLocation();
  const route = useLocation().pathname;
  const navigate = useNavigate();

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };
  const showNotificationError = (message) => {
    configNotification("error", "warning", "Notificación", message, "", true);
  };
  const showNotificationSuccess = (message) => {
    configNotification("warning", "check", "Notificación", message, "", true);
  };
  const getJsonData = async () => {
    const urlComplete = `${host}/api/settings/integration/dgi?code=${apiKeyAdmin}`;
    const apiResponse = await APIRequestGeneric("GET", urlComplete, null, true, null);
    if (apiResponse.status === 200 && apiResponse.ok) {
      let tempJsonData = [];
      const responseData = await apiResponse.json();
      setElement(responseData);
      tempJsonData.push({
        enqueueMaxAttempts: responseData.enqueueMaxAttempts,
        enqueueMessageVisibilityDelayTime: responseData.enqueueMessageVisibilityDelayTime,
      });
      // console.log("tempJsonData", tempJsonData);
      setJsonData(tempJsonData);

      tempJsonData = [];
      tempJsonData.push(
        {
          componente: "feConsFE",
          endpointAddress: responseData.taxAgencyService.feConsFE.endpointAddress,
          timeout: responseData.taxAgencyService.feConsFE.timeout,
        },
        {
          componente: "feRecepFE",
          endpointAddress: responseData.taxAgencyService.feRecepFE.endpointAddress,
          timeout: responseData.taxAgencyService.feRecepFE.timeout,
        },
        {
          componente: "feRecepEventoFE",
          endpointAddress: responseData.taxAgencyService.feRecepEventoFE.endpointAddress,
          timeout: responseData.taxAgencyService.feRecepEventoFE.timeout,
        }
      );
      setJsonDataVariables(tempJsonData);

      // INICIO Programar Envío
      setColorSchedule(responseData.taxAgencyScheduleOffline ? "099268" : "1971C2");
      setNameSchedule(
        responseData.taxAgencyScheduleOffline ? "Ver Programación" : "Programar Envío"
      );
      setIconSchedule(
        responseData.taxAgencyScheduleOffline ? "visibility_rounded" : "add_circle_outline"
      );
      // FIN Programar Envío
    }
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );
  const setEditJsonData = () => {
    navigate("/ComunicacionPAC/ConfiguracionPacForm", { state: { route, element } });
  };
  const resetValidator = async () => {
    const urlComplete = `${host}/management/cache/restart`;
    try {
      const apiResponse = await APIRequestGeneric("PATCH", urlComplete, null, false, null);
      if (apiResponse.status === 200 && apiResponse.ok) {
        showNotificationSuccess("Se completó la acción exitosamente");
      } else {
        showNotificationError(`Error: ${apiResponse.Data}`);
      }
    } catch {
      showNotificationError("Ocurrió un error inesperado.");
    }
  };
  const resetPlugin = async () => {
    const apiKey = process.env.REACT_APP_API_KEY_PAC_PLUGINS;
    const urlComplete = `${host}/api/management/cache/restart?code=${apiKey}`;
    try {
      const apiResponse = await APIRequestGeneric("PATCH", urlComplete, null, true, null);
      if (apiResponse.status === 200 && apiResponse.ok) {
        showNotificationSuccess("Se completó la acción exitosamente");
      } else {
        showNotificationError(`Error: ${apiResponse.Data}`);
      }
    } catch {
      showNotificationError("Ocurrió un error inesperado.");
    }
  };
  const scheduleShipping = async () => {
    navigate("/ComunicacionPAC/ProgramarEnvio", { state: { route, element } });
  };

  useEffect(async () => {
    try {
      setLoad(true);
      await getJsonData();
      // eslint-disable-next-line no-empty
    } catch {}

    setLoad(false);
  }, [pathname]);

  const { columns, rows } = data(jsonData, setEditJsonData);
  const variables = dataV(jsonDataVariables, setEditJsonData);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MDTypography variant="subtitle" fontWeight="bold">
              Se permite configurar parámetros de comunicación para los siguientes procesos:
            </MDTypography>
            <MDTypography variant="subtitle2">-Consulta de documentos a DGI</MDTypography>
            <MDTypography variant="subtitle2">-Envío de documentos a DGI</MDTypography>
            <MDTypography variant="subtitle2">-Envío de eventos a DGI</MDTypography>
          </Grid>
          <Grid item xs={12}>
            <Grid
              item
              sm={12}
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              sx={{ "& > button": { m: 1 } }}
            >
              <MDButton
                variant="contained"
                onClick={() => scheduleShipping()}
                sx={{
                  background: `linear-gradient(45deg, #${colorSchedule} 30%, #${colorSchedule} 90%)`,
                  color: "#FFFFFF",
                }}
              >
                <Icon>{iconSchedule}</Icon>&nbsp;{nameSchedule}
              </MDButton>
              <MDButton
                variant="contained"
                onClick={() => resetValidator()}
                sx={{
                  background: "linear-gradient(45deg, #1971C2 30%, #1971C2 90%)",
                  color: "#FFFFFF",
                }}
              >
                <Icon>autorenew</Icon>&nbsp;Reset validador
              </MDButton>
              <MDButton
                variant="contained"
                onClick={() => resetPlugin()}
                sx={{
                  background: "linear-gradient(45deg, #1971C2 30%, #1971C2 90%)",
                  color: "#FFFFFF",
                }}
              >
                <Icon>autorenew</Icon>&nbsp;Reset plugin
              </MDButton>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox>
                <DataTable
                  table={{ columns, rows }}
                  noEndBorder
                  showTotalEntries={false}
                  hideEntriesPerPage
                  isSorted={false}
                />
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox>
                <DataTable
                  table={variables}
                  noEndBorder
                  showTotalEntries={false}
                  hideEntriesPerPage
                  isSorted={false}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {renderNotificacion}
      <Loading load={load} />
      <Footer />
    </DashboardLayout>
  );
}

export default ComunicacionPac;
