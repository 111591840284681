/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// Default Components
import MDTypography from "components/MDTypography";

export default function data(jsonData, deleteJsonData, setEditJsonData) {
  const getRows = () => {
    const elements = [];

    for (let index = 0; index < jsonData.length; index += 1) {
      let varImpuestos = "";
      for (let idx = 0; idx < jsonData[index].impuesto.length; idx += 1) {
        varImpuestos += jsonData[index].impuesto[idx].nombre;
        if (idx + 1 !== jsonData[index].impuesto.length) varImpuestos += "|";
      }
      elements.push({
        // partitionKey: jsonData[index].partitionKey,
        // funcionalidad: jsonData[index].funcionalidad,
        fechaRegistro: jsonData[index].fechaRegistro.toString().substring(0, 10),
        fechaInicio: jsonData[index].fechaDesde.toString().substring(0, 10),
        fechaFin: jsonData[index].fechaHasta.toString().substring(0, 10),
        estado: jsonData[index].estado.nombre,
        impuestos: varImpuestos,
        usuario: jsonData[index].usuario,
        action: (
          <MDTypography color="text">
            <Tooltip
              title="Deshabilitar Registro"
              disableInteractive
              onClick={() => deleteJsonData(jsonData[index].id)}
            >
              <Icon>delete</Icon>
            </Tooltip>{" "}
            /{" "}
            <Tooltip
              title="Editar Registro"
              disableInteractive
              onClick={() => {
                setEditJsonData(jsonData[index].id);
              }}
            >
              <Icon>edit</Icon>
            </Tooltip>
          </MDTypography>
        ),
      });
    }

    return elements;
  };

  return {
    columns: [
      // { Header: "", accessor: "icon", width: "0%", align: "center" },
      // { Header: "Partition Key", accessor: "partitionKey", align: "center" },
      // { Header: "Funcionalidad", accessor: "funcionalidad", align: "center" },
      { Header: "Fecha Registro", accessor: "fechaRegistro", align: "center" },
      { Header: "Fecha Inicio", accessor: "fechaInicio", align: "center" },
      { Header: "Fecha Fin", accessor: "fechaFin", align: "center" },
      { Header: "Estado", accessor: "estado", align: "center" },
      { Header: "Impuestos", accessor: "impuestos", align: "center" },
      { Header: "Usuario", accessor: "usuario", align: "center" },
      { Header: "Accion", accessor: "action", align: "center" },
    ],

    rows: getRows(),
  };
}

// Setting default values for the props of MDBadge
data.defaultProps = {
  jsonData: [],
};

// Typechecking props of the data
data.propTypes = {
  jsonData: PropTypes.objectOf(PropTypes.array),
  deleteJsonData: PropTypes.func.isRequired,
  setEditJsonData: PropTypes.func.isRequired,
};
