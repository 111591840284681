import { useState, useEffect } from "react";

function useTypeDteByCountry(country) {
  const [arrayTipoDTE, setArrayTipoDTE] = useState([]);
  useEffect(() => {
    const getDataCountry = async () => {
      try {
        const { default: response } = await import(
          `../../pool/crear-pool/arrayTipoDTE_${country.toUpperCase()}`
        );

        setArrayTipoDTE(response);
      } catch (error) {
        setArrayTipoDTE([]);
      }
    };

    getDataCountry();
  }, [country]);
  return arrayTipoDTE;
}
export default useTypeDteByCountry;
