/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Loading from "components/Loading";
import MDDatePicker from "components/MDDatePicker";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Format
import TimeMaskCustom from "views/varios/ComunicacionPAC/format";

// API Request
import APIRequest from "api/request";
import APIRequestGeneric from "api/requestGeneric";

function ConfiguracionPacProgramarEnvio() {
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  const host = process.env.REACT_APP_API_URL;
  const apiKeyAdmin = process.env.REACT_APP_API_KEY_PAC_ADMIN;
  const idFuncionalidad = 18;

  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  const [load, setLoad] = useState(false);
  // const [jsonData, setJsonData] = useState([]);
  const { pathname } = useLocation();
  const {
    state: { route, element },
  } = useLocation();
  const navigate = useNavigate();

  const [newValueFechaInicio, setNewValueFechaInicio] = useState("");
  const [newValueTimeInicio, setNewValueTimeInicio] = useState("00:00:00");
  const [newValueTimeInicioError, setNewValueTimeInicioError] = useState(false);
  const [newValueTimeInicioMessage, setNewValueTimeInicioMessage] = useState("");
  const [newValueFechaFin, setNewValueFechaFin] = useState("");
  const [newValueTimeFin, setNewValueTimeFin] = useState("00:00:00");
  const [newValueTimeFinError, setNewValueTimeFinError] = useState(false);
  const [newValueTimeFinMessage, setNewValueTimeFinMessage] = useState("");
  const [newValueFechaEnvio, setNewValueFechaEnvio] = useState("");
  const [newValueTimeEnvio, setNewValueTimeEnvio] = useState("00:00:00");
  const [newValueTimeEnvioError, setNewValueTimeEnvioError] = useState(false);
  const [newValueTimeEnvioMessage, setNewValueTimeEnvioMessage] = useState("");
  const [newValueMotivo, setNewValueMotivo] = useState("");

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };
  const showNotificationError = (message) => {
    configNotification("error", "warning", "Notificación", message, "", true);
  };
  const showNotificationSuccess = (message) => {
    configNotification("warning", "check", "Notificación", message, "", true);
  };

  const registerUser = async (vLabel, vValue, vAccion) => {
    const bodyUsuario = {
      Usuario: userInfo.correo,
      FuncionalidadId: idFuncionalidad,
      Label: vLabel,
      Value: vValue,
      Accion: vAccion,
      Data: "{}",
    };

    await (await APIRequest("POST", "/Api/Historial", bodyUsuario)).json();
  };
  const rangoTimeMax = (valor) => {
    const time = valor.split(":");

    if (parseInt(time[0], 10) > 23 || parseInt(time[1], 10) > 59 || parseInt(time[2], 10) > 59) {
      return true;
    }

    return false;
  };
  const rangoTimeMin = (valor) => {
    const time = valor.split(":");

    if (parseInt(time[0] * 60 * 60 + time[1] * 60 + time[2], 10) < 0) {
      return true;
    }

    return false;
  };
  const rangoTimeHora = (valor) => {
    const time = valor.split(":");

    if (parseInt(time[1] * 60 + time[2], 10) > 0) {
      return true;
    }

    return false;
  };
  const validatedData = () => {
    let withOutError = true;
    setNewValueTimeEnvioError(false);
    setNewValueTimeEnvioMessage("");
    setNewValueTimeInicioError(false);
    setNewValueTimeInicioMessage("");
    setNewValueTimeFinError(false);
    setNewValueTimeFinMessage("");
    if (newValueFechaInicio.length <= 0) {
      withOutError = false;
      showNotificationError("Los campos de fecha deben estar llenos");
    } else if (newValueFechaFin.length <= 0) {
      withOutError = false;
      showNotificationError("Los campos de fecha deben estar llenos");
    } else if (newValueFechaEnvio.length <= 0) {
      withOutError = false;
      showNotificationError("Los campos de fecha deben estar llenos");
    } else if (new Date(newValueFechaFin) < new Date(newValueFechaInicio)) {
      withOutError = false;
      showNotificationError("La fecha fin no puede ser menor a la fecha inicio");
    } else if (new Date(newValueFechaEnvio) < new Date(newValueFechaFin)) {
      withOutError = false;
      showNotificationError("La fecha de envío no puede ser menor a la fecha fin");
    }

    if (newValueTimeEnvio.length < 8) {
      withOutError = false;
      setNewValueTimeEnvioMessage("Formato Incorrecto");
      setNewValueTimeEnvioError(true);
    }
    if (rangoTimeMin(newValueTimeEnvio) || rangoTimeMax(newValueTimeEnvio)) {
      withOutError = false;
      setNewValueTimeEnvioMessage("Valor debe estar entre 00:00:00 y 23:59:59");
      setNewValueTimeEnvioError(true);
    }
    if (newValueTimeInicio.length < 8) {
      withOutError = false;
      setNewValueTimeInicioMessage("Formato Incorrecto");
      setNewValueTimeInicioError(true);
    }
    if (rangoTimeMin(newValueTimeInicio) || rangoTimeMax(newValueTimeInicio)) {
      withOutError = false;
      setNewValueTimeInicioMessage("Valor debe estar entre 00:00:00 y 23:59:59");
      setNewValueTimeInicioError(true);
    }
    if (newValueTimeFin.length < 8) {
      withOutError = false;
      setNewValueTimeFinMessage("Formato Incorrecto");
      setNewValueTimeFinError(true);
    }
    if (rangoTimeMin(newValueTimeFin) || rangoTimeMax(newValueTimeFin)) {
      withOutError = false;
      setNewValueTimeFinMessage("Valor debe estar entre 00:00:00 y 23:59:59");
      setNewValueTimeFinError(true);
    }

    if (rangoTimeHora(newValueTimeInicio)) {
      withOutError = false;
      setNewValueTimeInicioMessage(
        "Solo se aceptan horas exactas para la fecha de envío de los documentos programados. Especifique los minutos y segundos en cero."
      );
      setNewValueTimeInicioError(true);
    }
    if (rangoTimeHora(newValueTimeFin)) {
      withOutError = false;
      setNewValueTimeFinMessage(
        "Solo se aceptan horas exactas para la fecha de envío de los documentos programados. Especifique los minutos y segundos en cero."
      );
      setNewValueTimeFinError(true);
    }
    if (rangoTimeHora(newValueTimeEnvio)) {
      withOutError = false;
      setNewValueTimeEnvioMessage(
        "Solo se aceptan horas exactas para la fecha de envío de los documentos programados. Especifique los minutos y segundos en cero."
      );
      setNewValueTimeEnvioError(true);
    }

    return withOutError;
  };
  const DateToYYYYmmDD = (DateVar, TimeVar) => {
    if (DateVar) {
      const NewDate = new Date(DateVar);
      const time = TimeVar.split(":");
      const resultado = `${NewDate.getFullYear()}-${(NewDate.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${NewDate.getDate().toString().padStart(2, "0")}T${time[0]}:${time[1]}:${
        time[2]
      }Z`; // -06:00

      return resultado;
    }

    return "";
  };

  const saveDataModal = async () => {
    setLoad(true);
    if (validatedData()) {
      const urlComplete = `${host}/api/settings/integration/dgi?code=${apiKeyAdmin}`;

      const body = {
        enqueueMaxAttempts: element.enqueueMaxAttempts,
        enqueueMessageVisibilityDelayTime: element.enqueueMessageVisibilityDelayTime,
        taxAgencyService: element.taxAgencyService,
        taxAgencyScheduleOffline: {
          offlineReason: newValueMotivo,
          offlineStartAt: DateToYYYYmmDD(newValueFechaInicio, newValueTimeInicio),
          offlineEndAt: DateToYYYYmmDD(newValueFechaFin, newValueTimeFin),
          scheduleSendDocumentsAt: DateToYYYYmmDD(newValueFechaEnvio, newValueTimeEnvio),
        },
      };

      try {
        const apiResponse = await APIRequestGeneric("POST", urlComplete, body, true, null);
        if (apiResponse.status === 200 && apiResponse.ok) {
          registerUser("", "", 2);
          showNotificationSuccess("Se completó el registro exitosamente");
        } else {
          const responseData = await apiResponse.json();
          const errores = responseData.errors ? responseData.errors : [];
          showNotificationError(
            `Error: ${errores.length > 0 ? errores[0].message : responseData.message}`
          );
        }
      } catch {
        showNotificationError("Ocurrió un error inesperado.");
      }
    }
    setLoad(false);
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );

  const setEditJsonData = (jsonElement) => {
    if (element) {
      if (jsonElement.taxAgencyScheduleOffline) {
        setNewValueMotivo(jsonElement.taxAgencyScheduleOffline.offlineReason);
        setNewValueFechaInicio(jsonElement.taxAgencyScheduleOffline.offlineStartAt.split("T")[0]);
        setNewValueTimeInicio(
          jsonElement.taxAgencyScheduleOffline.offlineStartAt.split("T")[1].substring(0, 8)
        );
        setNewValueFechaFin(jsonElement.taxAgencyScheduleOffline.offlineEndAt.split("T")[0]);
        setNewValueTimeFin(
          jsonElement.taxAgencyScheduleOffline.offlineEndAt.split("T")[1].substring(0, 8)
        );
        setNewValueFechaEnvio(
          jsonElement.taxAgencyScheduleOffline.scheduleSendDocumentsAt.split("T")[0]
        );
        setNewValueTimeEnvio(
          jsonElement.taxAgencyScheduleOffline.scheduleSendDocumentsAt.split("T")[1].substring(0, 8)
        );
      }
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    saveDataModal();
  };

  useEffect(async () => {
    try {
      setLoad(true);
      setEditJsonData(element);
      // eslint-disable-next-line no-empty
    } catch {}

    setLoad(false);
  }, [pathname]);

  return (
    <DashboardLayout>
      <DashboardNavbar breadCrumb={route.split("/").slice(1)} />
      <MDBox pt={3} pb={3} component="form" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MDBox pb={4}>
              <MDTypography variant="subtitle" fontWeight="bold">
                <Icon
                  sx={{ cursor: "pointer", fontWeight: "bold" }}
                  onClick={() => navigate(route)}
                >
                  arrow_back
                </Icon>
                {"   "}
                Permite programar envíos a la DGI
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox sx={{ position: "relative", mx: 3, py: 2, px: 2 }}>
                <Grid container spacing={3} justifyContent="left" alignItems="left" height="100%">
                  <Grid item xs={12} sm={12} md={6}>
                    <MDDatePicker
                      input={{
                        variant: "standard",
                        label: "Fecha Inicio",
                        fullWidth: true,
                        required: true,
                        InputLabelProps: { shrink: true },
                      }}
                      value={newValueFechaInicio}
                      onChange={(e) => {
                        setNewValueFechaInicio(e[0]);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <MDInput
                      variant="standard"
                      error={newValueTimeInicioError}
                      helperText={newValueTimeInicioError ? newValueTimeInicioMessage : ""}
                      type="text"
                      label="Hora de inicio"
                      fullWidth
                      required
                      InputLabelProps={{ shrink: true }}
                      value={newValueTimeInicio}
                      onChange={(e) => {
                        if (rangoTimeMax(e.target.value)) {
                          setNewValueTimeInicio("23:59:59");
                        } else if (rangoTimeMin(e.target.value)) {
                          setNewValueTimeInicio("00:00:00");
                        } else {
                          setNewValueTimeInicio(e.target.value);
                        }
                      }}
                      InputProps={{
                        inputComponent: TimeMaskCustom,
                      }}
                    />
                    {/* <MDInput
                      variant="standard"
                      type="time"
                      label="Hora de inicio"
                      fullWidth
                      required
                      InputLabelProps={{ shrink: true }}
                      value={newValueTimeInicio}
                      onChange={(e) => {
                        setNewValueTimeInicio(e.target.value);
                      }}
                    /> */}
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <MDDatePicker
                      input={{
                        variant: "standard",
                        label: "Fecha Fin",
                        fullWidth: true,
                        required: true,
                        InputLabelProps: { shrink: true },
                      }}
                      value={newValueFechaFin}
                      onChange={(e) => {
                        setNewValueFechaFin(e[0]);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <MDInput
                      variant="standard"
                      error={newValueTimeFinError}
                      helperText={newValueTimeFinError ? newValueTimeFinMessage : ""}
                      type="text"
                      label="Hora de fin"
                      fullWidth
                      required
                      InputLabelProps={{ shrink: true }}
                      value={newValueTimeFin}
                      onChange={(e) => {
                        if (rangoTimeMax(e.target.value)) {
                          setNewValueTimeFin("23:59:59");
                        } else if (rangoTimeMin(e.target.value)) {
                          setNewValueTimeFin("00:00:00");
                        } else {
                          setNewValueTimeFin(e.target.value);
                        }
                      }}
                      InputProps={{
                        inputComponent: TimeMaskCustom,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <MDDatePicker
                      input={{
                        variant: "standard",
                        label: "Fecha para realizar el envío",
                        fullWidth: true,
                        required: true,
                        InputLabelProps: { shrink: true },
                      }}
                      value={newValueFechaEnvio}
                      onChange={(e) => {
                        setNewValueFechaEnvio(e[0]);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <MDInput
                      variant="standard"
                      error={newValueTimeEnvioError}
                      helperText={newValueTimeEnvioError ? newValueTimeEnvioMessage : ""}
                      type="text"
                      label="Hora para realizar el envío"
                      fullWidth
                      required
                      InputLabelProps={{ shrink: true }}
                      value={newValueTimeEnvio}
                      onChange={(e) => {
                        if (rangoTimeMax(e.target.value)) {
                          setNewValueTimeEnvio("23:59:59");
                        } else if (rangoTimeMin(e.target.value)) {
                          setNewValueTimeEnvio("00:00:00");
                        } else {
                          setNewValueTimeEnvio(e.target.value);
                        }
                      }}
                      InputProps={{
                        inputComponent: TimeMaskCustom,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <MDInput
                      variant="standard"
                      type="text"
                      label="Motivo de Programación"
                      fullWidth
                      required
                      inputProps={{ minLength: 50 }}
                      InputLabelProps={{ shrink: true }}
                      value={newValueMotivo}
                      onChange={(e) => {
                        setNewValueMotivo(e.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="left" alignItems="left" height="100%">
              <Grid item xs={12} sm={12} md={6} lg={4} xl={10} />
              <Grid item xs={12} sm={12} md={6} lg={4} xl={2} container justifyContent="flex-end">
                <MDButton
                  variant="contained"
                  type="submit"
                  sx={{
                    background: "linear-gradient(45deg, #099268 30%, #099268 90%)",
                    color: "#FFFFFF",
                  }}
                >
                  <Icon>save</Icon>&nbsp;Guardar
                </MDButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
      {renderNotificacion}
      <Loading load={load} />
      <Footer />
    </DashboardLayout>
  );
}

export default ConfiguracionPacProgramarEnvio;
