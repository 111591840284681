/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDDatePicker from "components/MDDatePicker";
import DModal from "components/DModal";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import GridTable from "views/dashboards/entidadTributaria/component/dataGrid";

// Anaytics dashboard components
import EntidadTributaria from "views/dashboards/entidadTributaria/data/dataEntidadTributaria";
import EntidadesTributarias from "views/dashboards/entidadTributaria/data/dataEntidadesTributarias";
import DataJson from "views/dashboards/entidadTributaria/data/data";

import MixedChart from "examples/Charts/MixedChart";

function EstadoEntidadesTributarias() {
  const [jsonData, setJsonData] = useState([]);
  const { pathname } = useLocation();
  const paises = ["Chile", "Colombia", "Costa rica", "Perú", "Uruguay", "Panamá"];
  const [inputValue, setInputValue] = useState("");
  const [dateValue, setDateValue] = useState("");
  const [modal, setModal] = useState(false);
  const SelectItem = (rows) => {
    const newValue = rows[0].key;
    setInputValue(newValue);
    setModal(true);
  };
  const getJsonData = async () => {
    const tempJsonData = DataJson;
    const date = new Date(2023, 4, 13);
    setDateValue(date);
    setJsonData(tempJsonData);
  };

  useEffect(() => {
    getJsonData();
  }, [pathname]);

  const closeModal = () => {
    setModal(false);
  };

  const dataAll = jsonData.find((elem) => elem.date === dateValue);
  let gridEntidades = [];
  gridEntidades = EntidadesTributarias(dataAll, dateValue);

  const dataOne = dataAll?.documentos?.filter((elem) => elem.country === inputValue);
  const { columns, rows } = EntidadTributaria(dataOne);
  const renderHoraChart = (
    <MixedChart
      icon={{ color: "info", component: "leaderboard" }}
      title="Gráfico por hora"
      description="Análisis de los documentos tratados por horas en el día"
      chart={{
        labels: rows.map((x) => x.hora),
        datasets: [
          {
            chartType: "default-line",
            label: "Aceptados",
            color: "success",
            data: rows.map((x) => x.aceptados),
          },
          {
            chartType: "default-line",
            label: "Rechazados",
            color: "error",
            data: rows.map((x) => x.rechazados),
          },
          {
            chartType: "thin-bar",
            label: "Enviados",
            color: "info",
            data: rows.map((x) => x.envios),
          },
        ],
      }}
    />
  );
  const renderModal = (
    <DModal closeModal={closeModal} open={modal}>
      <MDBox sx={{ position: "relative", mx: 3, py: 2, px: 2 }}>
        <Grid container spacing={3} justifyContent="left" alignItems="left" height="100%">
          <Grid item xs={12}>
            <MDBox display="flex" justifyContent="start" alignItems="center" p={3}>
              <MDBox mb={2} xs={12} width="30%">
                <Autocomplete
                  defaultValue="Chile"
                  value={inputValue}
                  onChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  options={paises}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <MDInput
                      variant="standard"
                      type="text"
                      label="País"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      {...params}
                    />
                  )}
                />
              </MDBox>
            </MDBox>
            <MDBox>
              <DataTable
                entriesPerPage={(false, { defaultValue: 10, entries: [5, 10, 25, 50] })}
                canSearch
                showTotalEntries
                table={{ columns, rows }}
                pagination={{ variant: "contained", color: "warning" }}
                isSorted
                noEndBorder
              />
            </MDBox>
          </Grid>
        </Grid>
        {renderHoraChart}
      </MDBox>
    </DModal>
  );
  const renderPaisChart = (
    <MixedChart
      icon={{ color: "info", component: "leaderboard" }}
      title="Gráfico por país"
      description="Análisis de los documentos tratados por día"
      chart={{
        labels: paises,
        datasets: [
          {
            chartType: "default-line",
            label: "Aceptados",
            color: "success",
            data: gridEntidades.rows.map((x) => x.aceptados),
          },
          {
            chartType: "gradient-line",
            label: "Rechazados",
            color: "error",
            data: gridEntidades.rows.map((x) => x.rechazados),
          },
          {
            chartType: "bar",
            label: "Enviados",
            color: "info",
            data: gridEntidades.rows.map((x) => x.envios),
          },
        ],
      }}
    />
  );
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {renderModal}
      <MDBox py={1}>
        <MDBox pt={1} pb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card>
                <MDBox display="flex">
                  <MDBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="4rem"
                    height="4rem"
                    variant="gradient"
                    bgColor="success"
                    color="white"
                    shadow="md"
                    borderRadius="xl"
                    ml={3}
                    mt={-2}
                  >
                    <Icon fontSize="medium" color="inherit">
                      language
                    </Icon>
                  </MDBox>
                  <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
                    Entidades Tributarias
                  </MDTypography>
                  <MDBox ml={20} mb={2} mt={1} xs={12} width="20%">
                    <MDDatePicker
                      options={{ dateFormat: "d/m/Y", maxDate: "today" }}
                      value={dateValue}
                      onChange={(event, newDateValue) => {
                        setDateValue(newDateValue);
                      }}
                      input={{ placeholder: "Select a date" }}
                    />
                  </MDBox>
                </MDBox>
                <GridTable SelectItem={SelectItem} rows={gridEntidades.rows} />
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox pt={6} pb={3}>
          <Grid container />
        </MDBox>
        {renderPaisChart}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EstadoEntidadesTributarias;
