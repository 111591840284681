/* eslint-disable no-eval */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import { isOk } from "views/admin/xpos/_helpers/requestHelpers";
import MDInput from "../../../../../../components/MDInput";
import MDTypography from "../../../../../../components/MDTypography";
import xPosApiRequest from "../../../../../../api/xPosApiRequest";

function Persistence({
  persistency,
  country,
  showNotificationError,
  showNotificationSuccess,
  setLoading,
}) {
  const [days, setDays] = useState(undefined);

  useEffect(() => {
    if (persistency) {
      setDays(persistency);
    }
  }, [persistency]);

  const handleDaysChange = (event) => {
    const { value } = event.target;
    if (value === "") {
      setDays("");
    } else if (parseInt(value, 10) >= 1 && parseInt(value, 10) <= 20) {
      setDays(+value);
    }
  };

  const handlePersistencia = async () => {
    setLoading(true);
    const body = {
      xPosLogsPersistencyDays: days,
    };

    const options = {
      method: "PUT",
      url: `/global-configuration/persistency`,
      headers: {
        "Content-Type": "application/json",
        countryId: country,
      },
      data: body,
    };

    try {
      const response = await xPosApiRequest(options);
      if (isOk(response.status)) {
        showNotificationSuccess("La persistencia se actualizó exitosamente");
      } else {
        showNotificationError(response.data);
      }
    } catch (error) {
      showNotificationError(`Ocurrio un error al actualizar la persistencia`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid container spacing={4} pb={2} px={1}>
      <Grid item xs={6}>
        <MDTypography fontWeight="bold" fontSize="14px" color="dark">
          Aquí se debe registrar la cantidad de días que el xPos persistira informacion en sus bases
          de datos locales.
        </MDTypography>
        <MDTypography fontWeight="bold" fontSize="14px" color="dark">
          Luego de estos días, su información será eliminada.
        </MDTypography>
      </Grid>
      <Grid item xs={6}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <MDInput
              label="Ingrese cantidad de días"
              placeholder="Entre 1 y 20"
              type="number"
              min={1}
              max={20}
              value={days === undefined ? persistency : days}
              onChange={handleDaysChange}
              size="medium"
              variant="outlined"
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="flex-end">
            <Grid
              backgroundColor="#099268"
              width="110px"
              borderRadius={5}
              style={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => {
                handlePersistencia();
              }}
            >
              <MDTypography
                fontWeight="bold"
                color="white"
                py={1}
                style={{ marginLeft: 5, paddingRight: 5, fontSize: 12 }}
              >
                Guardar
              </MDTypography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

Persistence.propTypes = {
  persistency: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  country: PropTypes.string.isRequired,
  showNotificationError: PropTypes.func.isRequired,
  showNotificationSuccess: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
};

export default Persistence;
