/* eslint-disable react/prop-types */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Data

// API Request
import APIRequest from "api/xDocApiRequest";

// Custom components
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

function VersionsSettings() {
  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  const [settings, setSettings] = useState({ majorVersionNumber: 0 });
  const {
    state: { route },
  } = useLocation();
  const navigate = useNavigate();

  const getSettings = async () => {
    const apiResponse = await (await APIRequest("GET", "/VersionSettings/Get", null)).json();
    setSettings(apiResponse);
  };

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );

  const onChangeMajorNumber = (e) => {
    const newValue = e.target.value;
    if (parseInt(newValue, 10) < 2 || newValue === "") {
      return;
    }
    setSettings({ ...settings, majorVersionNumber: newValue });
  };

  const updateSettings = async () => {
    try {
      const apiResponse = await APIRequest("POST", "/VersionSettings/Update", settings);

      if (apiResponse.status === 200) {
        configNotification(
          "warning",
          "check",
          "Notificación",
          "Se guardaron las configuraciones exitosamente.",
          "",
          true
        );
      } else {
        const errorMessage = {
          code: apiResponse.status,
          message: `Error: ${apiResponse.status}`,
        };
        throw errorMessage;
      }
    } catch (e) {
      configNotification(
        "error",
        "warning",
        "Notificación",
        `Ocurrió un error al guardar las configuraciones: ${e.message}`,
        "",
        true
      );
    }
  };

  useEffect(() => {
    getSettings();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar breadCrumb={route.split("/").slice(1)} />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                <MDBox>
                  <MDTypography variant="h4" gutterBottom color="warning">
                    Configuraciones de versionado
                  </MDTypography>
                </MDBox>
                <MDBox>
                  <MDTypography color="text" onClick={() => navigate(route)}>
                    <Tooltip title="Regresar" disableInteractive>
                      <Icon fontSize="large">arrow_back</Icon>
                    </Tooltip>
                  </MDTypography>
                </MDBox>
              </MDBox>
              <MDBox style={{ margin: "20px" }}>
                <MDBox>
                  <MDInput
                    label="Número principal"
                    type="number"
                    value={settings.majorVersionNumber}
                    onChange={onChangeMajorNumber}
                  />
                </MDBox>
                <MDBox style={{ marginTop: "20px" }}>
                  <MDButton
                    style={{ marginRight: "10px" }}
                    variant="outlined"
                    onClick={updateSettings}
                    color="success"
                  >
                    Guardar
                  </MDButton>
                  <MDButton variant="outlined" onClick={() => navigate(route)} color="warning">
                    Cancelar
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {renderNotificacion}
      <Footer />
    </DashboardLayout>
  );
}

export default VersionsSettings;
