/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// Default Components
import MDTypography from "components/MDTypography";

export default function data(jsonData, deleteJsonData, setEditJsonData) {
  const userAreaAdmin = JSON.parse(sessionStorage.getItem("userAreaAdmin"));
  const userType = JSON.parse(sessionStorage.getItem("userType"));

  const getRows = () => {
    const elements = [];

    for (let index = 0; index < jsonData.length; index += 1) {
      let apply = false;
      if (userType === 1) {
        apply = true;
      } else if (userType === 2) {
        for (let i = 0; i < userAreaAdmin.length; i += 1) {
          if (userAreaAdmin[i] === jsonData[index].id) {
            apply = true;
          }
        }
      }

      if (apply) {
        elements.push({
          icon: (
            <MDTypography
              component={Link}
              onClick={() => {
                sessionStorage.setItem("idAreaSelected", JSON.stringify(jsonData[index]));
              }}
              to="/PerfilFuncionalidad"
            >
              <Tooltip title="Asignar Funcionalidades" disableInteractive>
                <Icon
                  sx={{
                    fontWeight: "bold",
                    color: ({ palette: { warning } }) => warning.main,
                    mt: -0.5,
                  }}
                >
                  send
                </Icon>
              </Tooltip>
            </MDTypography>
          ),
          name: jsonData[index].nombre,
          // estate: jsonData[index].estado.nombre,
          action: (
            <>
              <MDTypography color="text" display="none">
                <Tooltip
                  title="Eliminar Area"
                  disableInteractive
                  onClick={() => deleteJsonData(jsonData[index].id)}
                >
                  <Icon>delete</Icon>
                </Tooltip>{" "}
                /{" "}
              </MDTypography>
              <MDTypography color="text">
                <Tooltip
                  title="Editar Area"
                  disableInteractive
                  onClick={() => {
                    setEditJsonData(jsonData[index].id);
                  }}
                >
                  <Icon>edit</Icon>
                </Tooltip>
              </MDTypography>
            </>
          ),
        });
      }
    }

    return elements;
  };

  return {
    columns: [
      { Header: "", accessor: "icon", width: "0%", align: "center" },
      { Header: "Nombre", accessor: "name", width: "60%", align: "left" },
      // { Header: "Estado", accessor: "estate", align: "center" },
      { Header: "Accion", accessor: "action", align: "center" },
    ],

    rows: getRows(),
  };
}

// Setting default values for the props of MDBadge
data.defaultProps = {
  jsonData: [],
};

// Typechecking props of the data
data.propTypes = {
  jsonData: PropTypes.objectOf(PropTypes.array),
  deleteJsonData: PropTypes.func.isRequired,
  setEditJsonData: PropTypes.func.isRequired,
};
