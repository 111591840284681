/* eslint-disable react/prop-types */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// @mui material components
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// Data
import data from "views/admin/xdoc/taxentity/data";

// API Request
import APIRequest from "api/xDocApiRequest";

function TaxEntities() {
  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  const [jsonData, setJsonData] = useState([]);
  const { pathname } = useLocation();
  const route = useLocation().pathname;
  const navigate = useNavigate();

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );

  const getJsonData = async () => {
    const tempJsonData = [];

    const apiResponse = await (await APIRequest("GET", "/TaxEntity/GetAll", null)).json();

    apiResponse.forEach((item) => {
      tempJsonData.push(item);
    });

    setJsonData(tempJsonData);
  };

  const [showConfirmDisable, setShowConfirmDisable] = useState(false);
  const [entityToDisable, setEntityToDisable] = useState({});
  const [disableReason, setDisableReason] = useState("");
  const setEnabled = async (taxEntity, disableConfirmed) => {
    const enable = !taxEntity.enabled;
    if (!enable && !disableConfirmed) {
      setEntityToDisable(taxEntity);
      setShowConfirmDisable(true);
      return;
    }

    try {
      const apiResponse = await APIRequest("POST", "/TaxEntity/SetEnabled", {
        taxId: taxEntity.taxId,
        country: taxEntity.country,
        enable,
        reason: disableReason,
      });

      if (apiResponse.status === 200) {
        getJsonData();
        configNotification(
          "warning",
          "check",
          "Notificación",
          enable
            ? `Se habilitó la entidad tributaria '${taxEntity.name}' exitosamente.`
            : `Se deshabilitó la entidad tributaria '${taxEntity.name}' exitosamente.`,
          "",
          true
        );
      } else {
        const errorMessage = {
          code: apiResponse.status,
          message: `Error: ${apiResponse.status}`,
        };
        throw errorMessage;
      }
    } catch (e) {
      configNotification(
        "error",
        "warning",
        "Notificación",
        enable
          ? `Ocurrió un error al habilitar la entidad tributaria '${e.message}'.`
          : `Ocurrió un error al deshabilitar la entidad tributaria '${e.message}'.`,
        "",
        true
      );
    }
  };
  const handleCancelDisable = () => {
    setShowConfirmDisable(false);
  };
  const handleConfirmDisable = () => {
    setEnabled(entityToDisable, true);
    setShowConfirmDisable(false);
  };

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [taxEntityToDelete, setTaxEntityToDelete] = useState("");
  const deleteJsonData = async (taxEntity, confirmed) => {
    if (!confirmed) {
      setTaxEntityToDelete(taxEntity);
      setShowConfirmDelete(true);
      return;
    }

    try {
      const apiResponse = await APIRequest(
        "DELETE",
        `/TaxEntity/Delete?taxId=${taxEntity.taxId}&country=${taxEntity.country}`,
        null
      );

      if (apiResponse.status === 200) {
        const index = jsonData.map((item) => item.taxId).indexOf(taxEntity.taxId);
        const elements = [...jsonData];
        elements.splice(index, 1);
        setJsonData(elements);
        configNotification(
          "warning",
          "check",
          "Notificación",
          `Se eliminó la entidad tributaria '${taxEntity.name}' exitosamente.`,
          "",
          true
        );
      } else {
        const errorMessage = {
          code: apiResponse.status,
          message: `Error: ${apiResponse.status}`,
        };
        throw errorMessage;
      }
    } catch (e) {
      configNotification(
        "error",
        "warning",
        "Notificación",
        `Ocurrió un error al eliminar el area: ${e.message}`,
        "",
        true
      );
    }
  };
  const handleCancelDelete = () => {
    setShowConfirmDelete(false);
  };
  const handleConfirmDelete = () => {
    deleteJsonData(taxEntityToDelete, true);
    setShowConfirmDelete(false);
  };

  const viewDetails = (taxEntity) =>
    navigate("/AdministracionXDoc/EntidadesTributarias/Detalles", { state: { taxEntity, route } });

  useEffect(() => {
    getJsonData();
  }, [pathname]);

  const { columns, rows } = data(jsonData, deleteJsonData, setEnabled, viewDetails);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                <MDBox>
                  <MDTypography variant="h4" gutterBottom color="warning">
                    Entidades Tributarias
                  </MDTypography>
                </MDBox>
              </MDBox>
              <MDBox>
                <DataTable
                  entriesPerPage={(false, { defaultValue: 10, entries: [5, 10, 25, 50] })}
                  canSearch
                  showTotalEntries
                  table={{ columns, rows }}
                  pagination={{ variant: "contained", color: "warning" }}
                  isSorted
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {renderNotificacion}
      <Dialog
        open={showConfirmDelete}
        onClose={handleCancelDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">¿Quiere eliminar el cliente?</DialogTitle>
        <DialogActions>
          <Button onClick={handleCancelDelete}>Cancelar</Button>
          <Button onClick={handleConfirmDelete} autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showConfirmDisable}
        onClose={handleCancelDisable}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">¿Quiere deshabilitar el cliente?</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Motivo (opcional)"
            fullWidth
            variant="standard"
            onChange={(e) => setDisableReason(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDisable}>Cancelar</Button>
          <Button onClick={handleConfirmDisable} autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
      <Footer />
    </DashboardLayout>
  );
}

export default TaxEntities;
