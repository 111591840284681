/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useMemo } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
// import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
// import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 PRO React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";

// RTL plugins
// import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Material Dashboard 2 PRO React routes
import routes from "routes";
import routesDB from "util/routesDBv2";

// Custom Views
import SignInBasic from "layouts/authentication/sign-in/basic";

// Material Dashboard 2 PRO React contexts
import {
  useMaterialUIController,
  setMiniSidenav,
  // setOpenConfigurator,
  setSidenavColor,
  setWhiteSidenav,
} from "context";

// Images
import LogoGosocket from "assets/images/logo-gosocket.png";

// Automatic LogOut
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import * as Global from "util/global";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    // openConfigurator,
    sidenavColor,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const [newRouteAccept, setNewRouteAccept] = useState(false);
  const [newRoutesDB, setNewRoutesDB] = useState(routes);
  const isAuthenticated = useIsAuthenticated();
  // eslint-disable-next-line no-unused-vars
  const { instance, accounts } = useMsal();
  const usNavigate = useNavigate();

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      // stylisPlugins: [rtlPlugin],
      stylisPlugins: [],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  const handleLogout = () => {
    instance
      .logoutPopup()
      .then(() => {
        usNavigate("/authentication/sign-in/basic");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(async () => {
    if (!newRouteAccept) {
      const tempRoutesBD = await routesDB();
      // console.log("tempRoutesBD", tempRoutesBD);

      setNewRoutesDB(tempRoutesBD);
      setNewRouteAccept(true);
    }
  }, [pathname]);

  // Change the openConfigurator state
  // const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    setSidenavColor(dispatch, "warning");
    setWhiteSidenav(dispatch, true);

    let expireDateToken = sessionStorage.getItem("expireDateToken");
    if (Global.closeSession && isAuthenticated) {
      handleLogout();
    } else if (expireDateToken) {
      const dateNow = new Date();
      expireDateToken = new Date(expireDateToken);
      if (dateNow > expireDateToken) {
        handleLogout();
      }
    } else if (!expireDateToken) {
      handleLogout();
    }
  }, [pathname]);

  useMemo(() => {
    if (Global.closeSession && isAuthenticated) {
      handleLogout();
    }
  }, [Global.closeSession]);

  const getRoutes = (allRoutes, access) => {
    if (allRoutes) {
      return allRoutes.map((route) => {
        if (route.collapse) {
          return getRoutes(route.collapse, access);
        }

        // if (route.route) {
        //   return <Route exact path={route.route} element={route.component} key={route.key} />;
        // }
        if (route.route && access && route.key !== "BasicLogin") {
          return <Route exact path={route.route} element={route.component} key={route.key} />;
        }
        if (route.route && !access && (route.key === "BasicLogin" || route.key === "AzureLogin")) {
          return <Route exact path={route.route} element={route.component} key={route.key} />;
        }
        return null;
      });
    }
    return (
      <Route
        exact
        path="/authentication/sign-in/basic"
        element={<SignInBasic />}
        key="BasicLogin"
      />
    );
  };

  return direction === "rtl" ? (
    <CacheProvider value={rtlCache}>
      <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={LogoGosocket}
              brandName="Material Dashboard PRO"
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to="/dashboards/entidadesTributarias" />} />
        </Routes>
      </ThemeProvider>
    </CacheProvider>
  ) : (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {isAuthenticated && (
        <>
          {layout === "dashboard" && (
            <>
              <Sidenav
                color={sidenavColor}
                brand={LogoGosocket}
                brandName="Administrador Gosocket"
                routes={newRoutesDB}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <Configurator />
            </>
          )}
          {layout === "vr" && <Configurator />}
          <Routes>
            {/* console.log("routes", JSON.stringify(routes)) */}
            {/* console.log("newRoutesDB", newRoutesDB) */}
            {/* console.log("routes === newRoutesDB", routes === newRoutesDB) */}
            {getRoutes(newRoutesDB, true)}
            <Route path="*" element={<Navigate to="/dashboards/entidadesTributarias" />} />
          </Routes>
        </>
      )}
      {!isAuthenticated && (
        <Routes>
          {/* console.log("getRoutes(newRoutesDB, false)", getRoutes(newRoutesDB, false)) */}
          {/* console.log("getRoutes(routes, false)", getRoutes(routes, false)) */}
          {/* console.log("Comparación", getRoutes(routes, false) === getRoutes(newRoutesDB, false)) */}
          {getRoutes(newRoutesDB, false)}
          <Route path="*" element={<Navigate to="/authentication/sign-in/basic" />} />
        </Routes>
      )}
    </ThemeProvider>
  );
}
