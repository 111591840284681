import * as Global from "util/global";

async function APIRequest(method, url, dataBody) {
  let response = null;

  try {
    const origin = "https://gosocket.net.react";
    const token = sessionStorage.getItem("token");
    const headers = new Headers();
    const bearer = `Bearer ${token}`;
    headers.append("Authorization", bearer);
    headers.append("Origin", origin);
    headers.append("Access-Control-Allow-Origin", origin);
    headers.append("Access-Control-Allow-Methods", "GET,PUT,POST,DELETE,PATCH,OPTIONS");
    if (dataBody) headers.append("Content-Type", "application/json");

    const options = {
      method,
      headers,
      body: dataBody ? JSON.stringify(dataBody) : null,
    };

    const host = process.env.REACT_APP_API_URL;
    const urlComplete = `${host}${url}`;

    response = await fetch(urlComplete, options);

    if (response.status === 401) {
      // console.log("Token de autorización desactivado");
      Global.setCloseSession(true);
    }
  } catch (error) {
    console.log("Error: ", error);
  }

  return response;
}

export default APIRequest;
