/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// @mui material components
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Loading from "components/Loading";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// API Request
import APIRequest from "api/request";

function XDocGlobalCargaXSLTForm() {
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  // const [jsonData, setJsonData] = useState([]);
  const [load, setLoad] = useState(false);
  const { pathname } = useLocation();
  const {
    state: { route, element },
  } = useLocation();
  const navigate = useNavigate();

  const [elementIsNew, setElementIsNew] = useState(true);
  const [newValueId, setNewValueId] = useState("");
  const [newValuePais, setNewValuePais] = useState(null);
  // const [newValueTaxId, setNewValueTaxId] = useState("");
  // const [newValueAPIKey, setNewValueAPIKey] = useState(null);
  const [newValueTipo, setNewValueTipo] = useState(null);
  const [newValueXSLT, setNewValueXSLT] = useState("");
  const [newValueFiles, setNewValueFiles] = useState([]);
  const [newValueFileBase64, setNewValueFileBase64] = useState("");

  const [selectData, setSelectData] = useState({
    pais: [
      { id: "ar", nombre: "Argentina" },
      { id: "gt", nombre: "Guatemala" },
    ],
    type: [
      { id: "api", nombre: "Api" },
      { id: "flip", nombre: "Flip" },
    ],
    /* apiKey: [
      {
        id: "3F2504E0-4F89-11D3-9A0C-0305E82C3301",
        nombre: "3F2504E0-4F89-11D3-9A0C-0305E82C3301",
      },
    ], */
  });

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };
  const showNotificationError = (message) => {
    configNotification("error", "warning", "Notificación", message, "", true);
  };
  const showNotificationSuccess = (message) => {
    configNotification("warning", "check", "Notificación", message, "", true);
  };

  const closeModal = () => {
    setNewValueId("");
    setNewValuePais(null);
    // setNewValueTaxId("");
    // setNewValueAPIKey(null);
    setNewValueXSLT("");
    setNewValueFiles([]);
  };

  const lector = new FileReader();
  lector.onload = async (file) => {
    if (file) {
      setNewValueFileBase64(
        file.target.result
          .toString()
          .replace("data:application/xml;base64,", "")
          .replace("data:text/xml;base64,", "")
      );
    } else {
      console.log("No se leyó ningun archivo");
    }
  };

  /* const otorgarPermiso = async () => {
    const response = { success: false, message: "" };
    const body = {
      XsltName: newValueXSLT.includes(".") ? newValueXSLT.split(".")[0] : newValueXSLT, // "11111111-2222-1111-2222-031111111114"
      CountryId: newValuePais.id, // "bo"
      User: userInfo.correo, // "ronald.piedra@gosocket.net"
    };

    const apiResponse = await (await APIRequest("POST", "/api/xslt/ReadGlobalXslt", body)).json();

    if (apiResponse.success) {
      const jsonResponse = JSON.parse(apiResponse.values);

      if (jsonResponse.UserNames.length > 0 && jsonResponse.Enable) {
        if (jsonResponse.UserNames.split("|").indexOf(body.User) === -1) {
          const tempNames = [];
          for (let index = 0; index < jsonResponse.UserNames.split("|").length; index += 1) {
            if (jsonResponse.UserNames.split("|")[index].trim().length > 0) {
              tempNames.push(jsonResponse.UserNames.split("|")[index].trim());
            }
          }
          tempNames.push(body.User);

          body.AccountCode = "";
          body.UserNames = tempNames;
          body.Delete = false;
          const apiResponseUpdate = await (
            await APIRequest("POST", "/api/xslt/UpdateGlobalXslt", body)
          ).json();

          response.success = apiResponseUpdate.success;
          response.message = apiResponseUpdate.message;
        } else {
          response.success = true;
          response.message = "El usuario ya está autorizado";
        }
      } else {
        response.message = "No hay registros enable";
      }
    } else if (
      apiResponse.message.toLowerCase().includes(`el registro ${body.XsltName} no existe`)
    ) {
      body.UserNames = [body.User];
      const apiResponseCreate = await (
        await APIRequest("POST", "/api/xslt/CreateGlobalXslt", body)
      ).json();

      response.success = apiResponseCreate.success;
      response.message = apiResponseCreate.message;
    } else {
      response.message = apiResponse.message;
    }

    return response;
  }; */
  const cargarPLantilla = async () => {
    const response = { success: false, message: "" };
    if (newValueFiles.length > 0) {
      // const responsePermiso = await otorgarPermiso();
      // if (responsePermiso.success) {
      // await lector.readAsDataURL(newValueFiles[0]);
      // await lector.readAsBinaryString(newValueFiles[0]);
      // await lector.readAsText(newValueFiles[0]);

      const body = {
        User: userInfo.correo,
        // TaxId: newValueTaxId,
        CountryId: newValuePais.id,
        NameMapping: newValueXSLT,
        // File: btoa(newValueFileBase64), // btoa: Sólo ASCII
        // File: btoa(unescape(encodeURIComponent(newValueFileBase64))), // Lee el UTF-8 pero mal las tildes
        File: newValueFileBase64,
        Type: newValueTipo.id,
      };

      // const urlComplete = `${host}/api/AccountSettings/UpdateXslt`; // Carga de plantilla
      const apiResponse = await (await APIRequest("POST", "/api/xslt/UpdateXslt", body)).json();

      response.success = apiResponse.success;
      response.message = apiResponse.message;
      // } else {
      //   response.success = responsePermiso.success;
      //   response.message = responsePermiso.message;
      // }
    } else {
      response.success = false;
      response.message = "Necesita cargar un archivo XSLT Primero";
    }

    return response;
  };
  const saveDataModal = async () => {
    if (elementIsNew && newValueFiles.length === 0) {
      showNotificationError("Por favor carge un archivo XSLT");
    } else {
      const fileUploaded = newValueFiles.length === 0 ? { success: true } : await cargarPLantilla();

      if (fileUploaded.success) {
        const body = {
          Pais: newValuePais.id,
          // TaxId: newValueTaxId,
          // ApiKey: newValueAPIKey.id,
          Xslt: newValueXSLT.includes(".") ? newValueXSLT.split(".")[0] : newValueXSLT,
          Usuario: userInfo.correo,
          Tipo: newValueTipo.id,
        };
        let apiResponse = null;

        try {
          if (elementIsNew) {
            apiResponse = await APIRequest("POST", "/Api/xslt", body);
          } else {
            apiResponse = await APIRequest("PATCH", `/Api/xslt/${newValueId}`, body);
          }

          if (apiResponse.status === 200) {
            let dataResponse = null;

            if (elementIsNew) {
              dataResponse = await apiResponse.json();
              setNewValueId(dataResponse);
            }

            /* const tempJsonData = jsonData;
            const today = new Date();
            const newTempElement = {
              id: elementIsNew ? dataResponse : newValueId,
              pais: newValuePais,
              taxId: newValueTaxId,
              syncPoint: newValueAPIKey,
              xslt: newValueXSLT,
              usuario: userInfo.correo,
              fechaRegistro: `${today.getFullYear()}-${(today.getMonth() + 1)
                .toString()
                .padStart(2, "0")}-${today.getDate().toString().padStart(2, "0")}`,
              urlDescarga: urlDownload,
            }; */

            if (elementIsNew) {
              if (dataResponse !== 0) {
                // tempJsonData.push(newTempElement);

                showNotificationSuccess("Se cargó el archivo exitosamente");
              } else {
                const errorMessage = {
                  code: apiResponse.status,
                  message: `No se guardó correctamente la información, status: ${apiResponse.status}`,
                };
                throw errorMessage;
              }
            } else {
              // const index = tempJsonData.map((item) => item.id).indexOf(newValueId);
              // tempJsonData[index] = newTempElement;

              showNotificationSuccess("Se actualizó la información exitosamente");
            }

            // setJsonData(tempJsonData);
            closeModal();
          } else {
            const errorMessage = {
              code: apiResponse.status,
              message: `Error: ${apiResponse.status}`,
            };
            throw errorMessage;
          }
        } catch (e) {
          showNotificationError(`Ocurrió un error en: ${e.message}`);
        }
      } else if (fileUploaded.message.length > 0) {
        showNotificationError(fileUploaded.message);
      } else {
        showNotificationError("Error inesperado al cargar la plantilla");
      }
    }
  };
  /* const getApiKeyData = async (countryVar, taxIdVar, countries) => {
    const country = countryVar != null ? countryVar : newValuePais;
    const taxID = taxIdVar != null ? taxIdVar : newValueTaxId;

    if (country !== null && taxID.length > 0) {
      const tempJsonData = [];
      const body = {
        User: userInfo.correo,
        TaxId: taxID,
        Country: country.id,
      };
      const urlComplete = `${host}/api/AccountSettings/GetApiKeysByAccount`;
      const apiResponse = await (
        await APIRequestGeneric("POST", urlComplete, body, false, basicAuthorization)
      ).json();

      if (apiResponse.Code === 200 && apiResponse.IsSuccessful) {
        const jsonResponse = JSON.parse(apiResponse.Data);

        for (let index = 0; index < jsonResponse.length; index += 1) {
          tempJsonData.push({
            id: jsonResponse[index].ApiUser,
            nombre: jsonResponse[index].ApiUser,
          });
        }

        setSelectData({
          pais: countries || selectData.pais,
          apiKey: tempJsonData,
        });
      }
    }
  }; */

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );

  const setEditJsonData = () => {
    if (element) {
      setNewValueId(element.id);
      setNewValuePais(element.pais);
      // setNewValueTaxId(element.taxId);
      // setNewValueAPIKey(element.syncPoint);
      setNewValueXSLT(element.xslt);
      // setNewValueFiles([]);
      setNewValueTipo(
        element.tipo
          ? selectData.type[selectData.type.map((item) => item.id).indexOf(element.tipo)]
          : null
      );

      // getApiKeyData(element.pais, element.taxId, countries);
    }
  };

  const getCountriesData = async () => {
    const tempJsonData = [];
    /* const apiResponse = await (await APIRequest("GET", "/api/Pais/Todos", null)).json();

    for (let index = 0; index < apiResponse.length; index += 1) {
      tempJsonData.push({
        id: apiResponse[index].codigo.toLowerCase(),
        nombre: apiResponse[index].nombre,
      });
    } */

    const urlComplete = `/api/Pais/GetXsltCountries/${userInfo.correo}`;
    const apiResponse = await (await APIRequest("GET", urlComplete, null)).json();

    for (let index = 0; index < apiResponse.length; index += 1) {
      tempJsonData.push({
        id: apiResponse[index].codigo.toLowerCase(),
        nombre: apiResponse[index].nombre,
      });
    }

    setSelectData({
      pais: tempJsonData,
      type: selectData.type,
      // apiKey: [],
    });

    return tempJsonData;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    saveDataModal();
  };

  useEffect(async () => {
    setLoad(true);
    try {
      await getCountriesData();
      setElementIsNew(!element);
      setEditJsonData();
    } catch {
      // Error
    }
    setLoad(false);
  }, [pathname]);

  return (
    <DashboardLayout>
      <DashboardNavbar breadCrumb={route.split("/").slice(1)} />
      <MDBox pt={3} pb={3} component="form" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MDBox pb={4}>
              <MDTypography variant="subtitle" fontWeight="bold">
                <Icon
                  sx={{ cursor: "pointer", fontWeight: "bold" }}
                  onClick={() => navigate(route)}
                >
                  arrow_back
                </Icon>
                {"   "}
                Permite cargar XSLT que será utilizado para la transformación
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox sx={{ position: "relative", mx: 3, py: 2, px: 2 }}>
                <Grid container spacing={3} justifyContent="left" alignItems="left" height="100%">
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <Autocomplete
                      value={newValuePais}
                      onChange={async (event, newValue) => {
                        setNewValuePais(newValue);
                        // await getApiKeyData(newValue, null);
                      }}
                      options={selectData.pais}
                      getOptionLabel={(option) => option.nombre}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderInput={(params) => (
                        <MDInput
                          variant="standard"
                          type="text"
                          label="Pais"
                          fullWidth
                          required
                          InputLabelProps={{ shrink: true }}
                          {...params}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <Autocomplete
                      value={newValueTipo}
                      onChange={async (event, newValue) => {
                        setNewValueTipo(newValue);
                      }}
                      options={selectData.type}
                      getOptionLabel={(option) => option.nombre}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      disabled={!elementIsNew}
                      renderInput={(params) => (
                        <MDInput
                          variant="standard"
                          type="text"
                          label="Tipo"
                          fullWidth
                          required
                          InputLabelProps={{ shrink: true }}
                          {...params}
                        />
                      )}
                    />
                  </Grid>
                  {/* <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <MDInput
                      variant="standard"
                      type="text"
                      label="Tax ID"
                      fullWidth
                      required
                      InputLabelProps={{ shrink: true }}
                      value={newValueTaxId}
                      onChange={(e) => {
                        setNewValueTaxId(e.target.value);
                      }}
                      onBlur={async (e) => {
                        await getApiKeyData(null, e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <Autocomplete
                      value={newValueAPIKey}
                      onChange={(event, newValue) => {
                        setNewValueAPIKey(newValue);
                      }}
                      options={selectData.apiKey}
                      getOptionLabel={(option) => option.nombre}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderInput={(params) => (
                        <MDInput
                          variant="standard"
                          type="text"
                          label="API Key"
                          fullWidth
                          required
                          InputLabelProps={{ shrink: true }}
                          {...params}
                        />
                      )}
                    />
                  </Grid> */}
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <MDInput
                      id="file-input"
                      variant="standard"
                      type="file"
                      label="Seleccionar Archivo"
                      sx={{ display: "none" }}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ accept: ".xslt" }}
                      onChange={async (e) => {
                        setNewValueFiles(e.target.files);
                        const tempValueName = e.target.files[0] ? e.target.files[0].name : "";
                        setNewValueXSLT(tempValueName.length > 0 ? tempValueName : newValueXSLT);
                        await lector.readAsDataURL(e.target.files[0]);
                      }}
                    />
                    <MDButton
                      variant="outlined"
                      autoFocus
                      color="warning"
                      onClick={() => document.getElementById("labelFile").click()}
                    >
                      <label id="labelFile" htmlFor="file-input" style={{ display: "none" }} />
                      Adjuntar Archivo <Icon>upload</Icon>
                    </MDButton>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <MDInput
                      variant="standard"
                      type="text"
                      label="XSLT"
                      fullWidth
                      required
                      disabled
                      InputLabelProps={{ shrink: true }}
                      value={newValueXSLT}
                      onChange={(e) => {
                        setNewValueXSLT(e.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="left" alignItems="left" height="100%">
              <Grid item xs={12} sm={12} md={6} lg={4} xl={10} />
              <Grid item xs={12} sm={12} md={6} lg={4} xl={2} container justifyContent="flex-end">
                <MDButton
                  variant="contained"
                  type="submit"
                  sx={{
                    background: "linear-gradient(45deg, #099268 30%, #099268 90%)",
                    color: "#FFFFFF",
                  }}
                >
                  <Icon>save</Icon>&nbsp;Guardar
                </MDButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
      {renderNotificacion}
      <Loading load={load} />
      <Footer />
    </DashboardLayout>
  );
}

export default XDocGlobalCargaXSLTForm;
