import { Autocomplete } from "@mui/material";
import PropTypes from "prop-types";
import MDInput from "components/MDInput";
import React, { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import xPosApiRequest from "api/xPosApiRequest";
import { isOk } from "views/admin/xpos/_helpers/requestHelpers";

function SearchOrganization({
  country,
  setTaxIDValue,
  setLoading,
  showNotificationError,
  required,
  disabled,
  variant,
  size,
  searchInInbox,
  setTaxNameValue,
  setGSValue,
}) {
  const [inputValue, setInputValue] = useState("");
  const [openAutocomplete, setOpenAutocomplete] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [debouncedInputValue] = useDebounce(inputValue, 500);

  const handleChangeAutocomplete = (newValue) => {
    setOpenAutocomplete(false);
    setTaxIDValue(newValue?.taxId ?? "");
    setGSValue(newValue?.gs ?? false);

    if (setTaxNameValue) {
      setTaxNameValue(newValue?.name ?? "");
    }

    if (newValue === null) {
      setOrganizations([]);
    }
  };

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const searchOrganizationUrl = searchInInbox ? "organizations/inbox" : "organizations";
      const param = searchInInbox ? "code" : "name";
      const response = await xPosApiRequest({
        method: "GET",
        url: `/${searchOrganizationUrl}?countryId=${country}&${param}=${debouncedInputValue}`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (isOk(response.status)) {
        setOrganizations(response.data);
        setOpenAutocomplete(true);
      } else {
        showNotificationError(`Error al descargar`);
        setOpenAutocomplete(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showNotificationError(`Error al descargar`);
      setOpenAutocomplete(false);
    }
  };

  useEffect(() => {
    if (!searchInInbox) {
      if (debouncedInputValue.length >= 3) {
        fetchData();
      } else {
        setOrganizations([]);
      }
    }
  }, [debouncedInputValue, searchInInbox]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && debouncedInputValue.length >= 3) {
      e.stopPropagation();
      fetchData();
    }
  };

  return (
    <Autocomplete
      freeSolo
      open={openAutocomplete}
      onClose={() => setOpenAutocomplete(false)}
      options={organizations}
      getOptionLabel={(option) => option.name || ""}
      onChange={(event, newValue) => handleChangeAutocomplete(newValue)}
      size={size}
      noOptionsText="Sin resultados"
      disabled={disabled}
      renderInput={(params) => (
        <MDInput
          {...params}
          label={` ${searchInInbox ? "TaxId" : "Nombre"} de la organización`}
          placeholder={`Ingrese ${
            searchInInbox ? "el TaxId y presiona enter" : "al menos 3 caracteres"
          }`}
          variant={variant}
          onChange={(event) => handleInputChange(event, event.target.value)}
          onKeyPress={handleKeyPress}
          fullWidth
          required={required}
          InputLabelProps={{ shrink: true }}
        />
      )}
    />
  );
}

SearchOrganization.defaultProps = {
  required: false,
  disabled: false,
  variant: "outlined",
  size: "medium",
  setLoading: () => {},
  searchInInbox: false,
  setTaxNameValue: null,
  setGSValue: () => {},
};

SearchOrganization.propTypes = {
  country: PropTypes.string.isRequired,
  setTaxIDValue: PropTypes.func.isRequired,
  showNotificationError: PropTypes.func.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
  size: PropTypes.string,
  setLoading: PropTypes.func,
  searchInInbox: PropTypes.bool,
  setTaxNameValue: PropTypes.func,
  setGSValue: PropTypes.func,
};

export default SearchOrganization;
