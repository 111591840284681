export default {
  AR: "Argentina",
  BO: "Bolivia",
  BR: "Brasil",
  CL: "Chile",
  CO: "Colombia",
  CR: "Costa Rica",
  DO: "República Dominicana",
  EC: "Ecuador",
  GT: "Guatemala",
  MX: "México",
  PA: "Panamá",
  PE: "Perú",
  PY: "Paraguay",
  SV: "El Salvador",
  UY: "Uruguay",
};
