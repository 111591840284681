import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import NewDataTable from "examples/Tables/NewDataTable";
import columns from "views/admin/xpos/_constants/configGlobal/transformation";
import ThreeDotsMenu from "../ThreeDotsMenu";

// TODO: props que se puede llevar a un estado global organizationConfigGuid, country,
// TODO: actions reducers que se pueden ser globales setLoading, showNotificationError, showNotificationSuccess

function Transformation({
  country,
  data,
  setLoading,
  showNotificationError,
  showNotificationSuccess,
  organizationConfigGuid,
  setConfigGlobal,
}) {
  const [rows, setRows] = useState([]);

  const handleUpdate = (body) => {
    setConfigGlobal((prevConfig) => ({
      ...prevConfig,
      ...body,
    }));
  };

  const addActionRow = (arrayData) =>
    arrayData.map((item) => {
      const { propsThreeDotsMenu, ...dataItem } = item;
      const props = {
        country,
        setLoading,
        showNotificationError,
        showNotificationSuccess,
        organizationConfigGuid,
        ...propsThreeDotsMenu,
      };
      return {
        ...dataItem,
        actions: (
          <ThreeDotsMenu
            setData={handleUpdate}
            documentVersion={dataItem?.version}
            processName={dataItem.fileName}
            {...props}
          />
        ),
      };
    });

  useEffect(() => {
    if (data.length > 0) {
      setRows(addActionRow(data));
    }
  }, [data]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <MDBox>
            <NewDataTable
              loadingTable={false}
              table={{ columns, rows }}
              pagination={{ variant: "contained", color: "warning" }}
              isSorted
            />
          </MDBox>
        </Card>
      </Grid>
    </Grid>
  );
}

Transformation.defaultProps = {
  organizationConfigGuid: "",
};

Transformation.propTypes = {
  country: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array.isRequired,
  showNotificationError: PropTypes.func.isRequired,
  showNotificationSuccess: PropTypes.func.isRequired,
  setConfigGlobal: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  organizationConfigGuid: PropTypes.string,
};

export default Transformation;
