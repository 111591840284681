/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Tooltip from "@mui/material/Tooltip";
import Switch from "@mui/material/Switch";

export default function data(jsonData) {
  const getRows = () => {
    const elements = [];

    for (let index = 0; index < jsonData.length; index += 1) {
      elements.push({
        rut: jsonData[index].code,
        razon: jsonData[index].name,
        admin: (
          <Tooltip disableInteractive>
            <Switch
              checked={jsonData[index].isAdmin}
              inputProps={{ "aria-label": "controlled" }}
              disabled
              color="info"
            />
          </Tooltip>
        ),
      });
    }

    return elements;
  };

  return {
    columns: [
      // { Header: "", accessor: "icon", width: "0%", align: "center" },
      { Header: "Rut de Empresa", accessor: "rut", align: "center" },
      { Header: "Razón Social", accessor: "razon", align: "center" },
      { Header: "Administrador", accessor: "admin", align: "center" },
    ],

    rows: getRows(),
  };
}

// Setting default values for the props of MDBadge
data.defaultProps = {
  jsonData: [],
};

// Typechecking props of the data
data.propTypes = {
  jsonData: PropTypes.objectOf(PropTypes.array),
  updateState: PropTypes.func.isRequired,
};
