import PropTypes from "prop-types";
import { ListItemIcon, MenuItem } from "@mui/material";
import xPosApiRequest from "api/xPosApiRequest";
import React from "react";
import { isOk } from "views/admin/xpos/_helpers/requestHelpers";
import SimCardDownloadOutlinedIcon from "@mui/icons-material/SimCardDownloadOutlined";

// TODO: props que se puede llevar a un estado global organizationConfigGuid, country,
// TODO: actions reducers que se pueden ser globales setLoading, showNotificationError, showNotificationSuccess
function DownloadFile({
  country,
  fileName,
  handleClose,
  setLoading,
  organizationConfigGuid,
  showNotificationError,
  showNotificationSuccess,
}) {
  const downloadJsonData = async () => {
    setLoading(true);
    handleClose();

    const headers = {
      "Content-Type": "application/json",
      ...(organizationConfigGuid ? { organizationConfigGuid } : { countryId: country }),
    };

    const url = organizationConfigGuid
      ? `/configuration/${fileName}`
      : `/global-configuration/${fileName}`;

    const options = {
      method: "GET",
      url,
      headers,
      responseType: "blob",
    };

    try {
      const response = await xPosApiRequest(options);

      if (isOk(response.status)) {
        const blob = new Blob([response.data]);
        const fileUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = fileUrl;
        link.setAttribute("download", fileName === "xsd" ? `${fileName}.zip` : `${fileName}.xslt`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(fileUrl);
        showNotificationSuccess(`Descarga exitosa`);
      } else {
        showNotificationError(`Error al descargar`);
      }
    } catch (error) {
      showNotificationError(`Error al descargar`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <MenuItem onClick={downloadJsonData}>
      <ListItemIcon>
        <SimCardDownloadOutlinedIcon fontSize="small" />
      </ListItemIcon>
      Descargar
    </MenuItem>
  );
}

DownloadFile.defaultProps = {
  organizationConfigGuid: "",
};

DownloadFile.propTypes = {
  country: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  fileName: PropTypes.string.isRequired,
  setLoading: PropTypes.func.isRequired,
  showNotificationError: PropTypes.func.isRequired,
  showNotificationSuccess: PropTypes.func.isRequired,
  organizationConfigGuid: PropTypes.string,
};

export default DownloadFile;
