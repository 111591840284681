/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";
import CardMedia from "@mui/material/CardMedia";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React base styles
import typography from "assets/theme/base/typography";

// Images
import logoGosocketFullColor from "assets/images/logo-gosocket-full-color.png";

// eslint-disable-next-line no-unused-vars
function Footer({ company, links }) {
  const { size } = typography;

  return (
    <MDBox alignItems="center">
      <hr />
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        color="text"
        fontSize={size.sm}
      >
        <CardMedia
          src={logoGosocketFullColor}
          component="img"
          sx={{
            maxWidth: "150px",
            margin: 0,
          }}
        />
      </MDBox>
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        color="text"
        fontSize={size.sm}
      >
        Desarrollado por Gosocket Corp.
      </MDBox>
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        color="text"
        fontSize={size.sm}
      >
        Copyright &copy; {new Date().getFullYear()} Gosocket Corp. Made with
        <MDBox fontSize={size.md} color="text" mb={-0.5} mx={0.25}>
          <Icon color="inherit" fontSize="inherit">
            favorite
          </Icon>
        </MDBox>
        .
      </MDBox>
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        color="text"
        fontSize={size.sm}
      >
        Producto liderado por Sergio Osses
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of Footer
Footer.defaultProps = {
  company: { href: "https://gosocket.net/", name: "Gosocket" },
  links: [
    { href: "https://gosocket.net/", name: "Gosocket" },
    { href: "https://gosocket.net/sobre-gosocket/", name: "Sobre Nosotros" },
  ],
};

// Typechecking props for the Footer
Footer.propTypes = {
  company: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.object),
};

export default Footer;
