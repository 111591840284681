/* eslint-disable react/prop-types */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import { Checkbox } from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// API Request

// Custom components
import MDEditor from "components/MDEditor";
import { useLocation, useNavigate } from "react-router-dom";
import Updates from "../updates";

function VersionDetails() {
  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  const {
    state: { version, releaseNotes, route },
  } = useLocation();
  const navigate = useNavigate();

  const formatNote = (culture) => {
    const text = releaseNotes.filter((x) => x.culture === culture)[0].content;
    const lines = text.split("\n");
    let output = "";
    lines.forEach((line) => {
      output += `<p>${line}</p>`;
    });
    return output;
  };

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                <MDBox>
                  <MDTypography variant="h4" gutterBottom color="warning">
                    Detalles
                  </MDTypography>
                </MDBox>
                <MDBox>
                  <MDTypography color="text" onClick={() => navigate(route)}>
                    <Tooltip title="Regresar" disableInteractive>
                      <Icon fontSize="large">arrow_back</Icon>
                    </Tooltip>
                  </MDTypography>
                </MDBox>
              </MDBox>
              <MDBox style={{ margin: "20px" }}>
                <MDBox>
                  <MDTypography display="flex" variant="h6" gutterBottom color="text">
                    <strong>Número: </strong>
                    <p style={{ color: "#a59f9f", marginLeft: "5px" }}>{version.number}</p>
                  </MDTypography>
                </MDBox>
                <MDBox>
                  <MDTypography variant="h6" gutterBottom color="text">
                    Mandatoria:
                    <Checkbox
                      checked={version.isMandatory}
                      color="primary"
                      fontSize="medium"
                      disabled
                    />
                  </MDTypography>
                </MDBox>
                <MDBox>
                  <MDBox>
                    <MDTypography variant="h6" gutterBottom color="text">
                      Notas en español (es-MX):
                    </MDTypography>
                    <MDEditor
                      modules={{ toolbar: false }}
                      defaultValue={formatNote("es-MX")}
                      readOnly
                    />
                  </MDBox>
                  <MDBox>
                    <MDTypography variant="h6" gutterBottom color="text">
                      Notas en portugués (pt-BR):
                    </MDTypography>
                    <MDEditor
                      modules={{ toolbar: false }}
                      defaultValue={formatNote("pt-BR")}
                      readOnly
                    />
                  </MDBox>
                </MDBox>
              </MDBox>

              <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                <MDBox>
                  <MDTypography variant="h5" gutterBottom color="warning">
                    Actualizaciones
                  </MDTypography>
                </MDBox>
              </MDBox>
              <Updates version={version} />
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {renderNotificacion}
      <Footer />
    </DashboardLayout>
  );
}

export default VersionDetails;
