/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import PropTypes from "prop-types";
// react-router components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// import Icon from "@mui/material/Icon";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

// Material Dashboard 2 React example components
import DashboardLayout from "../../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";
import Footer from "../../../../examples/Footer";
import NewDataTable from "../../../../examples/Tables/NewDataTable";

// Data
import data from "./data";

// Material Dashboard 2 React components
import MDBox from "../../../../components/MDBox";
import MDInput from "../../../../components/MDInput";
import MDTypography from "../../../../components/MDTypography";
import MDButton from "../../../../components/MDButton";
import BKModalConfirm from "../../../../components/BKModalConfirm";

// API
import xPosApiRequest from "../../../../api/xPosApiRequest";
import useNotification from "../_hooks/useNotification";
import Notification from "../_components/shared/Notification";
import { CountryCodesToNames } from "../_constants/general";
import { isOk } from "../_helpers/requestHelpers";

function Organizations({ country }) {
  const [jsonData, setJsonData] = useState([]);
  const [filteredData, setFilteredData] = useState(undefined);
  const [reloadTable, setReloadTable] = useState(0);

  const navigator = useNavigate();
  const [searchTaxID, setSearchTaxID] = useState("");
  const [searchOrganization, setSearchOrganization] = useState("");
  const [deleteOrg, setDeleteOrg] = useState();
  const [orgName, setOrgName] = useState("");

  const [deleteModal, setDeleteModal] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const { notification, showNotificationError, showNotificationSuccess, setNotification } =
    useNotification();

  const fetchData = async () => {
    setLoadingTable(true);
    const options = {
      method: "GET",
      url: `/organizations?countryId=${country}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await xPosApiRequest(options);
      if (isOk(response.status)) {
        const organizationData = await response.data;
        setJsonData(organizationData);
      } else {
        showNotificationError("Ocurrio un error al cargar las Organizaciones.");
      }
      setLoadingTable(false);
    } catch (error) {
      showNotificationError(error.message);
      setLoadingTable(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [reloadTable, country]);

  const handleSearchTaxID = (taxId) => {
    setSearchTaxID(taxId);
    const filteredOrganizations = jsonData.filter((orgs) =>
      orgs.taxId?.toLowerCase().includes(taxId.toLowerCase())
    );
    setFilteredData(filteredOrganizations);
  };

  const handleSearchOrganization = (organization) => {
    setSearchOrganization(organization);
    const filteredOrganizations = jsonData.filter((orgs) =>
      orgs.name?.toLowerCase().includes(organization.toLowerCase())
    );
    setFilteredData(filteredOrganizations);
  };

  const setEditJsonData = (organization) => {
    navigator(`/XPos/${CountryCodesToNames[country]}/Organizaciones/CrearOrganizacion`, {
      state: { organization },
    });
  };

  const closeConfirmDeleteModal = () => {
    setDeleteModal(false);
    setDeleteOrg(undefined);
  };

  const handleDeleteOrg = (pool) => {
    setDeleteOrg(pool);
    setOrgName(pool.name);
    setDeleteModal(true);
  };

  const setDeleteJsonData = async () => {
    if (deleteOrg !== undefined) {
      const { guid, name } = deleteOrg;

      const options = {
        method: "DELETE",
        url: `/organizations/${guid}`,
        headers: {
          "Content-Type": "application/json",
        },
      };

      try {
        const response = await xPosApiRequest(options);
        if (isOk(response.status)) {
          fetchData();
          showNotificationSuccess(`Se eliminó la organización '${name}' exitosamente.`);
          setReloadTable(reloadTable + 1);
        } else {
          showNotificationError(response.data);
        }
      } catch (error) {
        showNotificationError(`No se pudo eliminar la organización.`);
      }
      setDeleteModal(false);
    }
  };

  const { columns, rows } = data(
    filteredData || jsonData,
    setEditJsonData,
    handleDeleteOrg,
    country
  );

  const renderConfirmDeleteModal = (
    <BKModalConfirm
      closeModal={closeConfirmDeleteModal}
      saveChanges={setDeleteJsonData}
      open={deleteModal}
      title={`Eliminar organización ${orgName}`}
      titleButton="Confirmar"
    >
      <MDBox px={2} sx={{ position: "relative" }}>
        <Grid container justifyContent="center" alignItems="center" height="100%">
          <div style={{ marginTop: "2em", marginBottom: "1.5em" }}>
            ¿Esta seguro que desea eliminar esta organización?
          </div>
        </Grid>
      </MDBox>
    </BKModalConfirm>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {renderConfirmDeleteModal}
      <MDTypography fontWeight="bold" variant="h5" color="dark" noWrap>
        Listado de organizaciones
      </MDTypography>
      <MDBox py={3}>
        <MDBox pt={3} pb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} py={1}>
              <MDBox display="flex" justifyContent="space-between">
                <MDBox display="flex" alignItems="center" justifyContent="flex-start">
                  <MDBox width="12rem">
                    <MDInput
                      style={{ color: "#344767", borderColor: "#344767" }}
                      placeholder="Búsqueda por TaxID"
                      label="TaxID"
                      value={searchTaxID}
                      type="search"
                      size="small"
                      onChange={(event) => handleSearchTaxID(event.target.value)}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                    />
                  </MDBox>
                  <MDBox width="12rem" ml={2}>
                    <MDInput
                      placeholder="Búsqueda por Organización"
                      value={searchOrganization}
                      label="Organización"
                      type="search"
                      size="small"
                      onChange={(event) => handleSearchOrganization(event.target.value)}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                    />
                  </MDBox>
                </MDBox>
                <MDBox display="flex" alignItems="center" justifyContent="flex-end">
                  <MDButton
                    variant="contained"
                    color="primary"
                    component={Link}
                    to={`/XPos/${CountryCodesToNames[country]}/Organizaciones/CrearOrganizacion`}
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "110px",
                      height: "35.5px",
                      borderRadius: 8,
                      backgroundColor: "#1971c2",
                    }}
                  >
                    <AddCircleOutlineIcon style={{ marginRight: 5, color: "white" }} />
                    <MDTypography fontWeight="bold" color="white" py={1} style={{ fontSize: 12 }}>
                      AGREGAR
                    </MDTypography>
                  </MDButton>
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <MDBox>
                  <NewDataTable
                    entriesPerPage={(false, { defaultValue: 10 })}
                    canSearch
                    showTotalEntries
                    loadingTable={loadingTable}
                    table={{ columns, rows }}
                    pagination={{ variant: "contained", color: "warning" }}
                    isSorted
                  />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Notification notification={notification} setNotification={setNotification} />
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

Organizations.propTypes = {
  country: PropTypes.string.isRequired,
};

export default Organizations;
