/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// @mui material components
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDInput from "components/MDInput";
// import MDDatePicker from "components/MDDatePicker";
import MDButton from "components/MDButton";
import Loading from "components/Loading";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import base64ToArrayBuffer from "util/base64ToArrayBuffer";

// Data
import data from "views/comercial/Radian/data";

// API Request
import APIRequest from "api/request";
import APIRequestGeneric from "api/requestGeneric";

function Radian() {
  // const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  const host = process.env.REACT_APP_API_URL; // "https://administracion-api-sbx.gosocket.net"; // process.env.REACT_APP_API_URL;
  const basicAuth = `${process.env.REACT_APP_API_ENEX_USER}:${process.env.REACT_APP_API_ENEX_PASSWORD}`;
  const basicAuthorization = `Basic ${btoa(basicAuth)}`;

  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  const [load, setLoad] = useState(false);
  const [jsonData, setJsonData] = useState([]);
  const { pathname } = useLocation();

  const [newValueYear, setNewValueYear] = useState(null);
  const [newValueMonthStart, setNewValueMonthStart] = useState(null);
  const [newValueMonthEnd, setNewValueMonthEnd] = useState(null);

  const [selectData, setSelectData] = useState({
    pais: [
      { id: "cl", nombre: "Chile" },
      { id: "co", nombre: "Colombia" },
      { id: "gt", nombre: "Guatemala" },
    ],
    funcionality: [
      { id: "geteventfromauthority", nombre: "Document Get Events" },
      { id: "sendeventtoauthority", nombre: "Send Event To Authority" },
    ],
    years: [
      // { id: "2015", nombre: "2015" },
      // { id: "2016", nombre: "2016" },
    ],
    months: [
      { id: "01", nombre: "01" },
      { id: "02", nombre: "02" },
      { id: "03", nombre: "03" },
      { id: "04", nombre: "04" },
      { id: "05", nombre: "05" },
      { id: "06", nombre: "06" },
      { id: "07", nombre: "07" },
      { id: "08", nombre: "08" },
      { id: "09", nombre: "09" },
      { id: "10", nombre: "10" },
      { id: "11", nombre: "11" },
      { id: "12", nombre: "12" },
    ],
  });

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };
  const showNotificationError = (message) => {
    configNotification("error", "warning", "Notificación", message, "", true);
  };
  const showNotificationSuccess = (message) => {
    configNotification("warning", "check", "Notificación", message, "", true);
  };

  const getJsonData = async () => {
    const tempJsonData = [];
    const urlComplete = `${host}/api/AccountSettings/GetAccountLimit?dateFrom=${newValueYear.id}${newValueMonthStart.id}&dateTo=${newValueYear.id}${newValueMonthEnd.id}`;
    const apiResponse = await APIRequestGeneric(
      "GET",
      urlComplete,
      null,
      false,
      basicAuthorization
    );

    if (apiResponse.status === 200) {
      const jsonResponse = await apiResponse.json();

      if (jsonResponse.Code === 200 || jsonResponse.Code === 201) {
        const dataResponse = JSON.parse(jsonResponse.Data);

        for (let index = 0; index < dataResponse.Messages.length; index += 1) {
          const itemData = dataResponse.Messages[index].split(","); // JSON.parse(`{${dataResponse.Messages[index]}}`);
          // 0 - Country = co,
          // 1 - AccountCode = 860015753,
          // 2 - Funcionality = sendeventtoauthority,
          // 3 - YearMonth = 202401,
          // 4 - StartMonthlyLimit = 150,
          // 5 - EndMonthlyLimit = 150,
          // 6 - RequestCounter = 8

          tempJsonData.push({
            pais: selectData.pais[
              selectData.pais
                .map((item) => item.id)
                .indexOf(itemData[0].split("=")[1].replace(",").trim().toLowerCase()) // Country,
            ],
            accountCode: itemData[1].split("=")[1].replace(",").trim(), // AccountCode,
            funcionalityName: itemData[2].split("=")[1].replace(",").trim(),
            yearMonth: itemData[3].split("=")[1].replace(",").trim(), // YearMonth,
            startMonthlyLimit: itemData[4].split("=")[1].replace(",").trim(), // StartMonthlyLimit,
            endMonthlyLimit: itemData[5].split("=")[1].replace(",").trim(), // ,
            requestCounter: itemData[6].split("=")[1].replace(",").trim(), // RequestCounter,
          });
        }

        setJsonData(tempJsonData);
        showNotificationSuccess("Se completó la búsqueda exitosamente");
      } else {
        showNotificationError(`Error, estado Json: ${jsonResponse.Code}`);
      }
    } else if (apiResponse.status === 204) {
      showNotificationSuccess("No se encontro información");
    } else {
      showNotificationError(`Error, estado: ${apiResponse.status}`);
    }
  };
  const saveDataModal = async () => {
    if (newValueMonthEnd < newValueMonthStart) {
      showNotificationError("La fecha final no puede ser menor a la fecha de inicio.");
    } else {
      setLoad(true);
      try {
        await getJsonData();
      } catch {
        showNotificationError("Error inesperado al consultar la información");
      }
      setLoad(false);
    }
  };
  const getStringToCSV = () => {
    let varString = "";

    for (let index = 0; index < jsonData.length; index += 1) {
      varString += `${jsonData[index].accountCode},${jsonData[index].pais.nombre},${jsonData[index].funcionalityName},${jsonData[index].yearMonth},${jsonData[index].startMonthlyLimit},${jsonData[index].endMonthlyLimit},${jsonData[index].requestCounter}`;
      varString += jsonData.length - 1 === index ? "" : "\r\n";
    }

    return varString;
  };
  const descargarLista = async () => {
    try {
      const nameFile = "RadianList.csv"; // `${jsonData[index].id}${jsonData[index].tipo}`;
      const arrBuffer = base64ToArrayBuffer(window.btoa(getStringToCSV()));
      const blob = new Blob([arrBuffer], { type: "application/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = nameFile;
      document.body.appendChild(a);
      a.click();

      return true;
    } catch (exception) {
      showNotificationError(exception.message);
      return false;
    }
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );

  const getCountriesData = async () => {
    const tempYearData = [];
    const today = new Date();
    const year = today.getFullYear();

    for (let index = 2015; index <= year; index += 1) {
      tempYearData.push({
        id: `${index}`,
        nombre: `${index}`,
      });
    }

    const tempJsonData = [];
    const apiResponse = await (await APIRequest("GET", "/api/Pais/Todos", null)).json();

    if (apiResponse.length > 0) {
      for (let index = 0; index < apiResponse.length; index += 1) {
        tempJsonData.push({
          id: apiResponse[index].codigo.toLowerCase(),
          nombre: apiResponse[index].nombre,
        });
      }

      setSelectData({
        pais: tempJsonData,
        funcionality: selectData.funcionality,
        years: tempYearData,
        months: selectData.months,
      });
    }

    return tempJsonData;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    saveDataModal();
  };

  useEffect(async () => {
    await getCountriesData();
  }, [pathname]);

  const { columns, rows } = data(jsonData, descargarLista);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={3}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <MDTypography variant="subtitle" fontWeight="bold">
              Permite obtener cantidad de request asociados a productos RADIAN
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox
                sx={{ position: "relative", mx: 3, py: 2, px: 2 }}
                component="form"
                onSubmit={handleSubmit}
              >
                <Grid container spacing={3} justifyContent="left" alignItems="left" height="100%">
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <Autocomplete
                      value={newValueYear}
                      onChange={(event, newValue) => {
                        setNewValueYear(newValue);
                      }}
                      options={selectData.years}
                      getOptionLabel={(option) => option.nombre}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderInput={(params) => (
                        <MDInput
                          variant="standard"
                          type="text"
                          label="Year"
                          fullWidth
                          required
                          InputLabelProps={{ shrink: true }}
                          {...params}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <Autocomplete
                      value={newValueMonthStart}
                      onChange={(event, newValue) => {
                        setNewValueMonthStart(newValue);
                      }}
                      options={selectData.months}
                      getOptionLabel={(option) => option.nombre}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderInput={(params) => (
                        <MDInput
                          variant="standard"
                          type="text"
                          label="Mes Inicio"
                          fullWidth
                          required
                          InputLabelProps={{ shrink: true }}
                          {...params}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <Autocomplete
                      value={newValueMonthEnd}
                      onChange={(event, newValue) => {
                        setNewValueMonthEnd(newValue);
                      }}
                      options={selectData.months}
                      getOptionLabel={(option) => option.nombre}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderInput={(params) => (
                        <MDInput
                          variant="standard"
                          type="text"
                          label="Mes Final"
                          fullWidth
                          required
                          InputLabelProps={{ shrink: true }}
                          {...params}
                        />
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={8}
                    xl={3}
                    container
                    justifyContent="flex-end"
                  >
                    <MDButton
                      variant="contained"
                      type="submit"
                      sx={{
                        background: "linear-gradient(45deg, #1971C2 30%, #1971C2 90%)",
                        color: "#FFFFFF",
                      }}
                    >
                      <Icon>search</Icon>&nbsp;Buscar
                    </MDButton>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={12}
                    container
                    justifyContent="flex-end"
                  >
                    <MDButton
                      variant="contained"
                      onClick={() => descargarLista()}
                      sx={{
                        background: "linear-gradient(45deg, #1971C2 30%, #1971C2 90%)",
                        color: "#FFFFFF",
                      }}
                    >
                      <Icon>file_download</Icon>&nbsp;Exportar
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox>
                <DataTable
                  entriesPerPage={(false, { defaultValue: 10, entries: [5, 10, 25, 50] })}
                  canSearch
                  showTotalEntries
                  table={{ columns, rows }}
                  pagination={{ variant: "contained", color: "warning" }}
                  isSorted
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {renderNotificacion}
      <Loading load={load} />
      <Footer />
    </DashboardLayout>
  );
}

export default Radian;
