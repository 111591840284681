/* eslint-disable react/prop-types */

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Default Components
import { Checkbox } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormatDate from "../../_custom/functions/formatDate";

export default function data(jsonData, deleteJsonData, setEnabled, viewDetails) {
  const getRows = () => {
    const elements = [];

    for (let index = 0; index < jsonData.length; index += 1) {
      elements.push({
        version: jsonData[index].version,
        clientName: jsonData[index].clientName,
        installationName: jsonData[index].installationName,
        date: FormatDate(jsonData[index].date),
        enabled: (
          <Checkbox checked={jsonData[index].enabled} onClick={() => setEnabled(jsonData[index])} />
        ),
        action: (
          <MDBox style={{ display: "flex", justifyContent: "space-between" }}>
            <MDTypography color="text" onClick={() => viewDetails(jsonData[index])}>
              <Tooltip title="Ver detalles" disableInteractive>
                <Icon>description</Icon>
              </Tooltip>
            </MDTypography>
            <MDTypography color="text" onClick={() => deleteJsonData(jsonData[index].id)}>
              <Tooltip title="Eliminar Area" disableInteractive>
                <Icon>delete</Icon>
              </Tooltip>
            </MDTypography>
          </MDBox>
        ),
      });
    }

    return elements;
  };

  return {
    columns: [
      { Header: "Versión", accessor: "version", align: "left" },
      { Header: "Cliente", accessor: "clientName", align: "left" },
      { Header: "Instalación", accessor: "installationName", align: "left" },
      { Header: "date", accessor: "date", align: "left" },
    ],

    rows: getRows(),
  };
}

// Setting default values for the props of MDBadge
data.defaultProps = {
  jsonData: [],
};

// Typechecking props of the data
data.propTypes = {
  jsonData: PropTypes.objectOf(PropTypes.array),
  deleteJsonData: PropTypes.func.isRequired,
};
