/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// @mui material components
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Loading from "components/Loading";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import data from "views/varios/GestorFolio/Configuraciones/data";

// API Request
import APIRequest from "api/request";
import APIRequestGeneric from "api/requestGeneric";

function GestorFolioConfiguraciones() {
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  const host = process.env.REACT_APP_API_URL;
  const basicAuth = `${process.env.REACT_APP_API_ENEX_USER}:${process.env.REACT_APP_API_ENEX_PASSWORD}`;
  const basicAuthorization = `Basic ${btoa(basicAuth)}`;

  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  const [load, setLoad] = useState(false);
  const [jsonData, setJsonData] = useState([]);
  const { pathname } = useLocation();

  const [newValueTaxId, setNewValueTaxId] = useState("");
  const [newValuePais, setNewValuePais] = useState(null);
  const [newValuePropiedad, setNewValuePropiedad] = useState(null);
  const [newValueValor, setNewValueValor] = useState(null);

  const [selectData, setSelectData] = useState({
    pais: [
      { id: "cl", nombre: "Chile" },
      { id: "gt", nombre: "Guatemala" },
    ],
    propierties: [
      { id: "BillingPoint", nombre: "BillingPoint" },
      { id: "SalePoint", nombre: "SalePoint" },
      { id: "Series", nombre: "Series" },
      { id: "PrimaryRangeLoad", nombre: "PrimaryRangeLoad" },
    ],
    values: [
      { id: "true", nombre: "true" },
      { id: "false", nombre: "false" },
    ],
  });

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };
  const showNotificationError = (message) => {
    configNotification("error", "warning", "Notificación", message, "", true);
  };
  const showNotificationSuccess = (message) => {
    configNotification("warning", "check", "Notificación", message, "", true);
  };

  const saveDataModal = async () => {
    setLoad(true);

    const body = {
      AccountCode: newValueTaxId,
      CountryId: newValuePais.id.toLowerCase(),
      SettingName: newValuePropiedad.id,
      SettingValue: newValueValor.id,
      User: userInfo.correo,
    };

    const urlComplete = `${host}/api/FolioManagerSettings/SettingManage`;
    const apiResponse = await (
      await APIRequestGeneric("POST", urlComplete, body, false, basicAuthorization)
    ).json();

    if ((apiResponse.Code === 201 || apiResponse.Code === 200) && apiResponse.IsSuccessful) {
      const idElement = `${newValueTaxId}|${newValuePais.id}|${newValuePropiedad.id}`;
      const index = jsonData.map((item) => item.id).indexOf(idElement);

      const today = new Date();
      const newTempElement = {
        id: idElement,
        taxId: newValueTaxId,
        country: newValuePais,
        propierty: newValuePropiedad,
        value: newValueValor,
        user: userInfo.correo,
        date: `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, "0")}-${today
          .getDate()
          .toString()
          .padStart(2, "0")}`,
      };

      // console.log("idElement", idElement);
      // console.log("index", index);
      // console.log("newTempElement", newTempElement);

      if (index < 0) {
        setJsonData([newTempElement, ...jsonData]);
      } else {
        const tempJsonData = jsonData;
        tempJsonData[index] = newTempElement;

        setJsonData([...tempJsonData]);
      }

      showNotificationSuccess("Se registró Satisfactoriamente");
    } else {
      showNotificationError(`Error al consultar: ${apiResponse.Message}`);
    }
    setLoad(false);
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );

  const getJsonData = async () => {
    setLoad(true);

    try {
      const tempJsonData = [];
      const urlComplete = `${host}/api/FolioManagerSettings/GetSettings?countryId=${newValuePais.id}&accountCode=${newValueTaxId}`;
      const apiResponse = await (
        await APIRequestGeneric("GET", urlComplete, null, false, basicAuthorization)
      ).json();

      const dataResponse = JSON.parse(apiResponse.Data);

      if (apiResponse.Code === 200 && apiResponse.IsSuccessful) {
        for (let index = 0; index < dataResponse.length; index += 1) {
          try {
            tempJsonData.push({
              id: `${dataResponse[index].TaxId}|${dataResponse[index].CountryId}|${dataResponse[index].SettingName}`,
              taxId: dataResponse[index].TaxId,
              country:
                selectData.pais[
                  selectData.pais
                    .map((item) => item.id)
                    .indexOf(dataResponse[index].CountryId.toLowerCase())
                ],
              propierty: {
                id: dataResponse[index].SettingName,
                nombre: dataResponse[index].SettingName,
              },
              value: { id: dataResponse[index].Value, nombre: dataResponse[index].Value },
              date: dataResponse[index].Timestamp.substring(0, 10),
              user: userInfo.correo,
            });
          } catch {
            console.log(`error en linea: ${index}`);
          }
        }
      } else {
        showNotificationSuccess(apiResponse.Message);
      }

      setJsonData([...tempJsonData]);
    } catch {
      // abc
    }

    setLoad(false);
  };

  const getCountriesData = async () => {
    const tempJsonData = [];
    const apiResponse = await (await APIRequest("GET", "/api/Pais/Todos", null)).json();

    for (let index = 0; index < apiResponse.length; index += 1) {
      tempJsonData.push({
        id: apiResponse[index].codigo.toLowerCase(),
        nombre: apiResponse[index].nombre,
      });
    }

    setSelectData({
      pais: tempJsonData,
      propierties: selectData.propierties,
      values: selectData.values,
    });

    return tempJsonData;
  };

  const getValuesByPropierty = async (propierty) => {
    const tempJsonData = [];

    switch (propierty) {
      case "BillingPoint":
        tempJsonData.push({ id: "true", nombre: "true" });
        tempJsonData.push({ id: "false", nombre: "false" });
        break;
      case "SalePoint":
        tempJsonData.push({ id: "true", nombre: "true" });
        tempJsonData.push({ id: "false", nombre: "false" });
        break;
      case "Series":
        tempJsonData.push({ id: "true", nombre: "true" });
        tempJsonData.push({ id: "false", nombre: "false" });
        break;
      case "PrimaryRangeLoad":
        tempJsonData.push({ id: "file", nombre: "file" });
        tempJsonData.push({ id: "et", nombre: "et" });
        tempJsonData.push({ id: "form", nombre: "form" });
        tempJsonData.push({ id: "file|form", nombre: "file|form" });
        tempJsonData.push({ id: "file|et", nombre: "file|et" });
        tempJsonData.push({ id: "form|et", nombre: "form|et" });
        tempJsonData.push({ id: "all", nombre: "all" });
        break;
      default:
        break;
    }

    setSelectData({
      pais: selectData.pais,
      propierties: selectData.propierties,
      values: tempJsonData,
    });
    // sad
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    saveDataModal();
  };

  useEffect(async () => {
    setLoad(true);
    await getCountriesData();
    setLoad(false);
  }, [pathname]);

  const { columns, rows } = data(jsonData);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={3} component="form" onSubmit={handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <MDTypography variant="subtitle" fontWeight="bold">
              Permite agregar propiedades y configuraciones de folio
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3} justifyContent="left" alignItems="left" height="100%">
              <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                <Autocomplete
                  value={newValuePais}
                  onChange={(event, newValue) => {
                    setNewValuePais(newValue);
                  }}
                  options={selectData.pais}
                  getOptionLabel={(option) => option.nombre}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  renderInput={(params) => (
                    <MDInput
                      variant="standard"
                      type="text"
                      label="Pais"
                      fullWidth
                      required
                      InputLabelProps={{ shrink: true }}
                      {...params}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                <MDInput
                  variant="standard"
                  type="text"
                  label="Tax ID"
                  fullWidth
                  required
                  InputLabelProps={{ shrink: true }}
                  value={newValueTaxId}
                  onChange={(e) => {
                    setNewValueTaxId(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                <MDButton
                  variant="contained"
                  onClick={getJsonData}
                  sx={{
                    background: "linear-gradient(45deg, #1971C2 30%, #1971C2 90%)",
                    color: "#FFFFFF",
                  }}
                >
                  <Icon>search</Icon>&nbsp;Buscar
                </MDButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="subtitle" fontWeight="bold">
              Agregar Registro
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox sx={{ position: "relative", mx: 3, py: 2, px: 2 }}>
                <Grid container spacing={3} justifyContent="left" alignItems="left" height="100%">
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <Autocomplete
                      value={newValuePropiedad}
                      onChange={(event, newValue) => {
                        if (newValue != null) {
                          setNewValuePropiedad(newValue);
                          getValuesByPropierty(newValue.id);
                          setNewValueValor(null);
                        }
                      }}
                      options={selectData.propierties}
                      getOptionLabel={(option) => option.nombre}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderInput={(params) => (
                        <MDInput
                          variant="standard"
                          type="text"
                          label="Seleccione Propiedad"
                          fullWidth
                          required
                          InputLabelProps={{ shrink: true }}
                          {...params}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <Autocomplete
                      value={newValueValor}
                      onChange={(event, newValue) => {
                        setNewValueValor(newValue);
                      }}
                      options={selectData.values}
                      getOptionLabel={(option) => option.nombre}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderInput={(params) => (
                        <MDInput
                          variant="standard"
                          type="text"
                          label="Seleccione Valor"
                          fullWidth
                          required
                          InputLabelProps={{ shrink: true }}
                          {...params}
                        />
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={6}
                    container
                    justifyContent="flex-end"
                  >
                    <MDButton
                      variant="contained"
                      type="submit"
                      sx={{
                        background: "linear-gradient(45deg, #1971C2 30%, #1971C2 90%)",
                        color: "#FFFFFF",
                      }}
                    >
                      <Icon>add_circle_outline</Icon>&nbsp;Agregar
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox>
                <DataTable
                  entriesPerPage={
                    (false, { defaultValue: 10, entries: [5, 10, 25, 50], show: false })
                  }
                  // hideEntriesPerPage
                  canSearch
                  showTotalEntries // ={false}
                  table={{ columns, rows }}
                  pagination={{ variant: "contained", color: "warning" }}
                  isSorted // ={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {renderNotificacion}
      <Loading load={load} />
      <Footer />
    </DashboardLayout>
  );
}

export default GestorFolioConfiguraciones;
