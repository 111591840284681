/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Switch from "@mui/material/Switch";

// Default Components
import MDTypography from "components/MDTypography";

export default function data(jsonData, deleteJsonData, setEditJsonData, changeStateJsonData) {
  const getRows = () => {
    const elements = [];

    for (let index = 0; index < jsonData.length; index += 1) {
      elements.push({
        taxId: jsonData[index].taxId,
        pais: jsonData[index].pais.nombre,
        metodo: jsonData[index].metodo.nombre,
        parametro: jsonData[index].parametro,
        xPath: jsonData[index].xPath,
        estado: (
          <Tooltip
            title={jsonData[index].estado ? "Deshabilitar" : "Habilitar"}
            disableInteractive
            sx={{
              display: jsonData[index].metodo.id === 2 ? "flex" : "none",
            }}
          >
            <Switch
              checked={jsonData[index].estado}
              onChange={() => changeStateJsonData(jsonData[index].id)}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Tooltip>
        ),
        fecha: jsonData[index].fecha,
        usuario: jsonData[index].usuario,
        action: (
          <MDTypography color="text" display={jsonData[index].metodo.id === 1 ? "flex" : "none"}>
            <Tooltip
              title="Eliminar Registro"
              disableInteractive
              onClick={() => deleteJsonData(jsonData[index])}
            >
              <Icon>delete</Icon>
            </Tooltip>{" "}
            /{" "}
            <Tooltip
              title="Editar Registro"
              disableInteractive
              onClick={() => {
                setEditJsonData(jsonData[index]);
              }}
            >
              <Icon>edit</Icon>
            </Tooltip>
          </MDTypography>
        ),
      });
    }

    return elements;
  };

  return {
    columns: [
      // { Header: "", accessor: "icon", width: "0%", align: "center" },
      { Header: "Tax ID", accessor: "taxId", align: "center" },
      { Header: "Pais", accessor: "pais", align: "center" },
      { Header: "Método", accessor: "metodo", align: "center" },
      { Header: "Nombre Parametro", accessor: "parametro", align: "center" },
      { Header: "xPath", accessor: "xPath", align: "center" },
      { Header: "Estado", accessor: "estado", align: "center" },
      { Header: "Fecha Acción", accessor: "fecha", align: "center" },
      { Header: "Usuario", accessor: "usuario", align: "center" },
      { Header: "Accion", accessor: "action", align: "center" },
    ],

    rows: getRows(),
  };
}

// Setting default values for the props of MDBadge
data.defaultProps = {
  jsonData: [],
};

// Typechecking props of the data
data.propTypes = {
  jsonData: PropTypes.objectOf(PropTypes.array),
  deleteJsonData: PropTypes.func.isRequired,
  setEditJsonData: PropTypes.func.isRequired,
  changeStateJsonData: PropTypes.func.isRequired,
};
