import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import { InputAdornment } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import MDInput from "../../../../../components/MDInput";
import useCertificate from "./useCertificate";

function useCreateOrganizationGT({ organization, showNotificationError }) {
  const [APIkeyValue, setAPIkeyValue] = useState(organization?.ApiKeyPassword ?? "");
  const [showAPIkey, setshowAPIkey] = useState(false);
  const certificate = useCertificate(showNotificationError);

  const clearFields = () => {
    setAPIkeyValue("");
    certificate?.clearFields();
  };

  const organizationData = {
    ApiKeyPassword: APIkeyValue,
    ...certificate?.organizationData,
  };

  const buttonDisabled = APIkeyValue === "" || certificate?.buttonDisabled;

  const handleAPIkeyVisibility = () => {
    setshowAPIkey(!showAPIkey);
  };

  const endAdornmentSc = (
    <InputAdornment position="end">
      <IconButton onClick={handleAPIkeyVisibility}>
        {showAPIkey ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </InputAdornment>
  );

  const getComponent = () => (
    <>
      <Grid item xs={12} md={5.5}>
        <MDInput
          label="Contraseña API Key Megaprin"
          placeholder="Ingrese"
          type={showAPIkey ? "text" : "password"}
          onChange={(event) => setAPIkeyValue(event.target.value)}
          size="medium"
          variant="standard"
          fullWidth
          required
          InputLabelProps={{ shrink: true }}
          InputProps={{
            inputProps: {
              maxLength: 50,
            },
            endAdornment: endAdornmentSc,
          }}
        />
      </Grid>
      {certificate?.getComponent()}
    </>
  );

  return {
    organizationData,
    clearFields,
    getComponent,
    buttonDisabled,
  };
}

export default useCreateOrganizationGT;
