import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Grid, Autocomplete } from "@mui/material";
import { isOk } from "views/admin/xpos/_helpers/requestHelpers";
import { CUSTOM_UPLOAD_TITLE, NODE_ENV } from "../../../_constants/configGlobal/showContent";
import xPosApiRequest from "../../../../../../api/xPosApiRequest";
import MDTypography from "../../../../../../components/MDTypography";
import MDInput from "../../../../../../components/MDInput";

function CustomUpload({
  setLoading,
  showNotificationError,
  showNotificationSuccess,
  country,
  organizationConfigGuid,
  title,
  subtitle,
  isSynchronous,
  selectorOptions,
  data,
}) {
  const [platformValue, setPlatformValue] = useState("");
  const [endpointValue, setEndpointValue] = useState("");
  const [urlValue, setUrlValue] = useState("");
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

  useEffect(() => {
    if (data) {
      setPlatformValue(data.publishType);
      setEndpointValue(data?.endpointSDX || data?.endpointPRD);
      setUrlValue(data?.connectionStringSDX || data?.connectionStringPRD);
    }
  }, [data]);

  const handlePublicacion = async () => {
    setLoading(true);
    const body = {
      publishType: platformValue,
      endpointSDX: NODE_ENV === "SBX" ? endpointValue : "",
      connectionStringSDX: NODE_ENV === "SBX" ? urlValue : "",
      endpointPRD: NODE_ENV === "PRD" ? endpointValue : "",
      connectionStringPRD: NODE_ENV === "PRD" ? urlValue : "",
      user: userInfo.correo,
    };

    let options;

    if (organizationConfigGuid) {
      options = {
        method: "PUT",
        url: `/configuration/publish`,
        headers: {
          "Content-Type": "application/json",
          organizationConfigGuid,
        },
        data: body,
      };
    } else {
      options = {
        method: "PUT",
        url: `/global-configuration/publish`,
        headers: {
          "Content-Type": "application/json",
          countryId: country,
        },
        data: body,
      };
    }

    try {
      const response = await xPosApiRequest(options);
      if (isOk(response.status)) {
        showNotificationSuccess("La publicación se actualizó exitosamente");
      } else {
        showNotificationError(response.data);
      }
    } catch (error) {
      showNotificationError(`Ocurrio un error al actualizar la publicación`);
    } finally {
      setLoading(false);
    }
  };

  const renderURL = () => (
    <MDInput
      label="URL"
      placeholder="Ingrese URL"
      type="text"
      value={endpointValue}
      onChange={(event) => setEndpointValue(event.target.value)}
      size="medium"
      variant="outlined"
      fullWidth
      InputLabelProps={{ shrink: true }}
    />
  );

  const renderEndPoint = () => (
    <MDInput
      label="Endpoint"
      placeholder="Ingrese endpoint"
      type="text"
      value={urlValue}
      onChange={(event) => setUrlValue(event.target.value)}
      size="medium"
      variant="outlined"
      fullWidth
      InputLabelProps={{ shrink: true }}
    />
  );

  const renderInputs = () => {
    if (isSynchronous) {
      return (
        <>
          <Grid item xs={12}>
            {renderURL()}
          </Grid>
          {platformValue && platformValue !== "API" && (
            <Grid item xs={12}>
              {renderEndPoint()}
            </Grid>
          )}
        </>
      );
    }
    return (
      <Grid item xs={12}>
        {platformValue === "API" ? renderURL() : renderEndPoint()}
      </Grid>
    );
  };

  return (
    <Grid container spacing={4} pb={2} px={1}>
      <Grid item xs={6}>
        <MDTypography fontWeight="bold" fontSize="14px" color="dark">
          {title}
        </MDTypography>
        {subtitle && (
          <MDTypography fontSize="14px" color="dark">
            {/* eslint-disable-next-line react/no-danger */}
            <div dangerouslySetInnerHTML={{ __html: subtitle }} />
          </MDTypography>
        )}
      </Grid>
      <Grid item xs={6}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Autocomplete
              value={platformValue}
              options={["API", ...selectorOptions]}
              size="medium"
              onChange={(event, newValue) => setPlatformValue(newValue)}
              renderInput={(params) => (
                <MDInput
                  {...params}
                  label="Tipo de publicación"
                  placeholder="Seleccione"
                  variant="outlined"
                  fullWidth
                  required
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
          {renderInputs()}
          <Grid item xs={12} display="flex" justifyContent="flex-end">
            <Grid
              backgroundColor="#099268"
              width="110px"
              borderRadius={5}
              style={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => {
                handlePublicacion();
              }}
            >
              <MDTypography
                fontWeight="bold"
                color="white"
                py={1}
                style={{ marginLeft: 5, paddingRight: 5, fontSize: 12 }}
              >
                Guardar
              </MDTypography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

CustomUpload.defaultProps = {
  showNotificationError: () => {},
  showNotificationSuccess: () => {},
  organizationConfigGuid: "",
  isSynchronous: false,
  title: CUSTOM_UPLOAD_TITLE,
  subtitle: "",
  selectorOptions: ["Storage"],
  data: {},
};

CustomUpload.propTypes = {
  setLoading: PropTypes.func.isRequired,
  country: PropTypes.string.isRequired,
  showNotificationError: PropTypes.func,
  showNotificationSuccess: PropTypes.func,
  organizationConfigGuid: PropTypes.string,
  isSynchronous: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  selectorOptions: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.shape({
    connectionStringPRD: PropTypes.string,
    connectionStringSDX: PropTypes.string,
    endpointPRD: PropTypes.string,
    endpointSDX: PropTypes.string,
    publishType: PropTypes.string,
    publishUpdatedAt: PropTypes.string,
    publishUpdatedBy: PropTypes.string,
  }),
};
export default CustomUpload;
