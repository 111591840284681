/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";

export default function data(jsonData, deleteJsonData, setViewModalDetail) {
  const getRows = () => {
    const elements = [];

    for (let index = 0; index < jsonData.length; index += 1) {
      elements.push({
        aplicacion: jsonData[index].applicationName,
        empresa: jsonData[index].accountName,
        usuario: jsonData[index].userName,
        orden: jsonData[index].order,
        action_item: (
          <MDTypography color="text">
            <>
              {" "}
              <Tooltip
                title="Detalle Registro"
                disableInteractive
                onClick={() => {
                  setViewModalDetail(jsonData[index]);
                }}
              >
                <Icon color="info">info</Icon>
              </Tooltip>{" "}
              /{" "}
              <Tooltip
                title="Eliminar Registro"
                disableInteractive
                onClick={() => {
                  deleteJsonData(jsonData[index]);
                }}
              >
                <Icon color="error">delete</Icon>
              </Tooltip>
            </>
          </MDTypography>
        ),
      });
    }

    return elements;
  };

  return {
    columns: [
      // { Header: "", accessor: "icon", width: "0%", align: "center" },
      { Header: "Aplicacion", accessor: "aplicacion", align: "center" },
      { Header: "Empresa", accessor: "empresa", align: "center" },
      { Header: "Usuario", accessor: "usuario", align: "center" },
      { Header: "Orden", accessor: "orden", align: "center" },
      { Header: "Accion", accessor: "action_item", align: "center" },
    ],

    rows: getRows(),
  };
}

// Setting default values for the props of MDBadge
data.defaultProps = {
  jsonData: [],
};

// Typechecking props of the data
data.propTypes = {
  jsonData: PropTypes.objectOf(PropTypes.array),
  deleteJsonData: PropTypes.func.isRequired,
  setViewModalDetail: PropTypes.func.isRequired,
};
