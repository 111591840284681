/* eslint-disable no-undef */
/* eslint-disable no-loop-func */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// Default Components
import MDTypography from "components/MDTypography";

export default function data(jsonData, updateJsonData) {
  const getRows = () => {
    const elements = [];

    for (let index = 0; index < jsonData.length; index += 1) {
      elements.push({
        taxId: jsonData[index].taxId,
        pais: jsonData[index].pais.nombre,
        estado: jsonData[index].estado.nombre,
        cambiarEstado: (
          <>
            <MDTypography color="text" display={jsonData[index].estado.id === 1 ? "flex" : "none"}>
              <Tooltip
                title="Desbloquear"
                disableInteractive
                onClick={() => updateJsonData(jsonData[index].taxId, true)}
              >
                <Icon>lock</Icon>
              </Tooltip>
            </MDTypography>
            <MDTypography color="text" display={jsonData[index].estado.id === 1 ? "none" : "flex"}>
              <Tooltip
                title="Bloquear"
                disableInteractive
                onClick={() => updateJsonData(jsonData[index].taxId, false)}
              >
                <Icon>lock_open</Icon>
              </Tooltip>
            </MDTypography>
          </>
        ),
        fechaAccion: jsonData[index].fechaAccion,
        usuarioAccion: jsonData[index].usuarioAccion,
      });
    }

    return elements;
  };

  return {
    columns: [
      { Header: "Tax Id", accessor: "taxId", align: "center" },
      { Header: "Pais", accessor: "pais", align: "center" },
      { Header: "Estado", accessor: "estado", align: "center" },
      { Header: "Cambiar Estado", accessor: "cambiarEstado", align: "center" },
      { Header: "Fecha Acción", accessor: "fechaAccion", align: "center" },
      { Header: "Usuario Acción", accessor: "usuarioAccion", align: "center" },
    ],

    rows: getRows(),
  };
}

// Setting default values for the props of MDBadge
data.defaultProps = {
  jsonData: [],
};

// Typechecking props of the data
data.propTypes = {
  jsonData: PropTypes.objectOf(PropTypes.array),
  updateJsonData: PropTypes.func.isRequired,
};
