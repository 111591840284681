/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import BKModal from "components/BKModal";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Loading from "components/Loading";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import data from "views/soporte/ApiKeySandBox/data";

// API Request
import APIRequest from "api/request";
import APIRequestGeneric from "api/requestGeneric";

function ApiKeySandbox() {
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  const host = process.env.REACT_APP_API_URL;
  const basicAuth = `${process.env.REACT_APP_API_ADMIN_SBX_USER}:${process.env.REACT_APP_API_ADMIN_SBX_PASSWORD}`;
  const basicAuthorization = `Basic ${btoa(basicAuth)}`;
  const idFuncionalidad = 18;

  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  const [load, setLoad] = useState(false);
  const [modalComment, setModalComment] = useState(false);
  const [modalPassword, setModalPassword] = useState(false);
  const [modalAddTaxIds, setModalAddTaxIds] = useState(false);
  const [jsonData, setJsonData] = useState([]);
  const { pathname } = useLocation();
  const route = useLocation().pathname;
  const navigate = useNavigate();

  const [newValueId, setNewValueId] = useState("");
  const [newValueTaxId, setNewValueTaxId] = useState("");
  const [newValueComment, setNewValueComment] = useState("");
  const [newValuePassword, setNewValuePassword] = useState("");
  const [newValueTaxIds, setNewValueTaxIds] = useState([]);
  const [newValueTempTaxId, setNewValueTempTaxId] = useState("");
  const [selectData, setSelectData] = useState({
    autenticacion: [
      // { id: 0, nombre: "---" },
      { id: 1, nombre: "Basic Authentication" },
      { id: 2, nombre: "OAuth 2.0" },
    ],
    pais: [
      { id: "ar", nombre: "Argentina" },
      { id: "gt", nombre: "Guatemala" },
    ],
  });

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };
  const showNotificationError = (message) => {
    configNotification("error", "warning", "Notificación", message, "", true);
  };
  const showNotificationSuccess = (message) => {
    configNotification("warning", "check", "Notificación", message, "", true);
  };

  const closeModalComment = () => {
    setModalComment(false);
    setNewValueId("");
    setNewValueTaxId("");
    setNewValueComment("");
    setLoad(false);
  };
  const closeModalPassword = () => {
    setModalPassword(false);
    setNewValueId("");
    setNewValueTaxId("");
    setNewValuePassword("");
    setLoad(false);
  };
  const closeModalAddTaxIds = () => {
    setModalAddTaxIds(false);
    setNewValueId("");
    setNewValueTaxId("");
    setNewValueTaxIds([]);
    setLoad(false);
  };
  const getJsonData = async () => {
    const body = {
      User: userInfo.correo,
    };
    const urlComplete = `${host}/api/AccountSettings/GetApiKeys`;
    const apiResponse = await (
      await APIRequestGeneric("POST", urlComplete, body, false, basicAuthorization)
    ).json();

    if (apiResponse.Code === 200 && apiResponse.IsSuccessful) {
      const tempJsonData = [];
      const responseData = JSON.parse(apiResponse.Data);
      // console.log("responseData", responseData);

      const bodyUsuario = {
        Funcionalidad: idFuncionalidad,
        Label: "",
        Value: "",
      };
      const apiResponseUsers = await (
        await APIRequest("POST", "/Api/Historial/filterFunctionality", bodyUsuario)
      ).json();

      const colleccionUsers = new Map();
      for (let index = apiResponseUsers.length - 1; index >= 0; index -= 1) {
        colleccionUsers.set(
          apiResponseUsers[index].label + apiResponseUsers[index].value,
          `${apiResponseUsers[index].usuario.correo} ${apiResponseUsers[
            index
          ].fechaAccion.substring(0, 10)}`
        );
      }

      for (let index = 0; index < responseData.length; index += 1) {
        let authMethod = responseData[index].AuthMethod ? responseData[index].AuthMethod : "0";
        authMethod = authMethod === "Basic" ? "1" : authMethod; // 1: "Basic Authentication"
        authMethod = authMethod === "OAuth2" ? "2" : authMethod; // 2: "OAuth 2.0"
        const timeStamp = responseData[index].Timestamp ? responseData[index].Timestamp : "";
        const lastUse = responseData[index].LastUse ? responseData[index].LastUse : "";
        const accountCodes = responseData[index].AccountCodes
          ? responseData[index].AccountCodes
          : [];
        const accountCode = accountCodes.length > 0 ? accountCodes[0] : ""; // No Exisite Ningún Tax Id Principal
        accountCodes.splice(accountCodes.indexOf(accountCode), 1);
        const component = responseData[index].Fields ? responseData[index].Fields.Component : null;

        tempJsonData.push({
          id: responseData[index].id,
          taxId: accountCode,
          fecha: timeStamp.length > 10 ? timeStamp.toString().substring(0, 10) : timeStamp,
          autenticacion:
            authMethod === "0"
              ? null
              : selectData.autenticacion[
                  selectData.autenticacion.map((item) => item.id).indexOf(parseInt(authMethod, 10))
                ],
          usuario: responseData[index].ApiUser,
          password: atob(responseData[index].ApiPassword),
          verPassword: false,
          ip: responseData[index].LastUseIp,
          usado: lastUse.length > 10 ? lastUse.substring(0, 10) : lastUse,
          usuarioCrea:
            component === "xPOS" ? "xPOS" : colleccionUsers.get(`id${responseData[index].id}`), // "z@gosocket.net 2023-06-25"
          ultimaAccion: colleccionUsers.get(`idU${responseData[index].id}`), // "z@gosocket.net 2023-06-25"
          comentario: responseData[index].Description ? responseData[index].Description : "",
          taxIds: accountCodes,
          bloqueado: responseData[index].Lock,
          componente: component,
        });
      }

      // console.log("tempJsonData", tempJsonData);
      setJsonData(tempJsonData);
    }
  };
  const registerUser = async (vLabel, vValue, vAccion) => {
    const bodyUsuario = {
      Usuario: userInfo.correo,
      FuncionalidadId: idFuncionalidad,
      Label: vLabel,
      Value: vValue,
      Accion: vAccion,
      Data: "{}",
    };

    await (await APIRequest("POST", "/Api/Historial", bodyUsuario)).json();
  };

  const saveDataModalComment = async () => {
    setLoad(true);
    const urlComplete = `${host}/api/AccountSettings/UpdateApiKey`;
    const body = {
      Id: newValueId, // *****          Obligatorio
      Comentario: newValueComment, // *****          Opcional
      User: userInfo.correo, //       Obligatorio
    };

    try {
      const apiResponse = await (
        await APIRequestGeneric("POST", urlComplete, body, false, basicAuthorization)
      ).json();

      if (apiResponse.Code === 200 && apiResponse.IsSuccessful) {
        const tempJsonData = jsonData;
        const index = tempJsonData.map((item) => item.id).indexOf(newValueId);

        const today = new Date();
        const fechaActual = `${today.getFullYear()}-${(today.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${today.getDate().toString().padStart(2, "0")}`;

        registerUser("idU", newValueId, 2);

        tempJsonData[index].comentario = newValueComment;
        tempJsonData[index].ultimaAccion = `${userInfo.correo} ${fechaActual}`;
        setJsonData(tempJsonData);
        showNotificationSuccess("Se actualizó el comentario exitosamente");
      } else {
        showNotificationError(`Error: ${apiResponse.Data}`);
      }
    } catch {
      showNotificationError("Ocurrió un error inesperado.");
    }

    closeModalComment();
  };
  const saveDataModalPassword = async () => {
    setLoad(true);
    const urlComplete = `${host}/api/AccountSettings/UpdateApiKey`;
    const body = {
      Id: newValueId, // *****          Obligatorio
      PasswordApiKey: newValuePassword, // *****          Opcional
      User: userInfo.correo, //       Obligatorio
    };

    try {
      const apiResponse = await (
        await APIRequestGeneric("POST", urlComplete, body, false, basicAuthorization)
      ).json();

      if (apiResponse.Code === 200 && apiResponse.IsSuccessful) {
        const tempJsonData = jsonData;
        const index = tempJsonData.map((item) => item.id).indexOf(newValueId);

        const today = new Date();
        const fechaActual = `${today.getFullYear()}-${(today.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${today.getDate().toString().padStart(2, "0")}`;

        registerUser("idU", newValueId, 2);

        tempJsonData[index].password = newValuePassword;
        tempJsonData[index].ultimaAccion = `${userInfo.correo} ${fechaActual}`;
        setJsonData(tempJsonData);
        showNotificationSuccess("Se actualizó la contraseña exitosamente");
      } else {
        showNotificationError(`Error: ${apiResponse.Data}`);
      }
    } catch {
      showNotificationError("Ocurrió un error inesperado.");
    }

    closeModalPassword();
  };
  const saveDataModalAddTaxIds = async () => {
    setLoad(true);
    const tempJsonData = jsonData;
    const index = tempJsonData.map((item) => item.id).indexOf(newValueId);
    const taxIdsNuevos = [];

    for (let idx = 0; idx < newValueTaxIds.length; idx += 1) {
      if (tempJsonData[index].taxIds.indexOf(newValueTaxIds[idx]) < 0) {
        taxIdsNuevos.push(newValueTaxIds[idx]);
      }
    }

    const urlComplete = `${host}/api/AccountSettings/UpdateApiKey`;
    const body = {
      Id: newValueId, // *****          Obligatorio
      // TaxId: newValueTaxId, // *****          Opcional
      // TaxIds: newValueTaxIds, // *****          Opcional
      // TaxIds: [newValueTaxId, ...newValueTaxIds], // *****          Opcional
      TaxIds: taxIdsNuevos, // *****          Opcional
      User: userInfo.correo, //       Obligatorio
    };

    try {
      const apiResponse = await (
        await APIRequestGeneric("POST", urlComplete, body, false, basicAuthorization)
      ).json();

      if (apiResponse.Code === 200 && apiResponse.IsSuccessful) {
        const today = new Date();
        const fechaActual = `${today.getFullYear()}-${(today.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${today.getDate().toString().padStart(2, "0")}`;

        registerUser("idU", newValueId, 2);

        tempJsonData[index].taxIds = newValueTaxIds;
        tempJsonData[index].ultimaAccion = `${userInfo.correo} ${fechaActual}`;
        setJsonData(tempJsonData);
        showNotificationSuccess("Se agregaron los TaxIds exitosamente");
      } else {
        showNotificationError(`Error: ${apiResponse.Data}`);
      }
    } catch {
      showNotificationError("Ocurrió un error inesperado.");
    }

    closeModalAddTaxIds();
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );

  const renderModalComment = (
    <BKModal
      closeModal={closeModalComment}
      saveChanges={saveDataModalComment}
      open={modalComment}
      title="Actualizar Comentario"
    >
      <MDBox sx={{ position: "relative", mx: 3, py: 2, px: 2 }}>
        <Grid container spacing={3} justifyContent="left" alignItems="left" height="100%">
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <MDInput
              variant="standard"
              type="text"
              label="Tax ID"
              fullWidth
              disabled
              InputLabelProps={{ shrink: true }}
              value={newValueTaxId}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <MDInput
              // variant="standard"
              type="text"
              label="Comentario"
              fullWidth
              required
              multiline
              rows={5}
              InputLabelProps={{ shrink: true }}
              value={newValueComment}
              onChange={(e) => {
                setNewValueComment(e.target.value);
              }}
            />
          </Grid>
        </Grid>
      </MDBox>
    </BKModal>
  );
  const renderModalPassword = (
    <BKModal
      closeModal={closeModalPassword}
      saveChanges={saveDataModalPassword}
      open={modalPassword}
      title="Actualizar Contraseña"
    >
      <MDBox sx={{ position: "relative", mx: 3, py: 2, px: 2 }}>
        <Grid container spacing={3} justifyContent="left" alignItems="left" height="100%">
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <MDInput
              variant="standard"
              type="text"
              label="Tax ID"
              fullWidth
              disabled
              InputLabelProps={{ shrink: true }}
              value={newValueTaxId}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <MDInput
              variant="standard"
              type="password"
              label="Contraseña"
              fullWidth
              required
              InputLabelProps={{ shrink: true }}
              value={newValuePassword}
              onChange={(e) => {
                setNewValuePassword(e.target.value);
              }}
            />
          </Grid>
        </Grid>
      </MDBox>
    </BKModal>
  );
  const setValueTempTaxId = () => {
    if (newValueTempTaxId.length > 0) {
      const taxIds = [...newValueTaxIds];
      taxIds.push(newValueTempTaxId);
      setNewValueTaxIds(taxIds);
      setNewValueTempTaxId("");
    }
  };
  const showValueTempTaxId = (taxIds, limit) => {
    let response = "";

    if (taxIds) {
      for (let index = 0; index < taxIds.length; index += 1) {
        if (index < limit) {
          response = `${response} ${taxIds[index]};`;
        } else if (index === limit) {
          response = `${response} ...`;
        }
      }
    } /* else {
      console.log("taxIds", taxIds);
    } */

    response = response.trim();

    return response;
  };
  const renderModalAddTaxIds = (
    <BKModal
      closeModal={closeModalAddTaxIds}
      saveChanges={saveDataModalAddTaxIds}
      open={modalAddTaxIds}
      title="Aregar TaxIds"
    >
      <MDBox sx={{ position: "relative", mx: 3, py: 2, px: 2 }}>
        <Grid container spacing={3} justifyContent="left" alignItems="left" height="100%">
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <MDInput
              variant="standard"
              type="text"
              label="Agregar Tax ID"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={newValueTempTaxId}
              onChange={(e) => {
                setNewValueTempTaxId(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <MDButton
              variant="outlined"
              autoFocus
              color="warning"
              onClick={() => setValueTempTaxId()}
            >
              Agregar
            </MDButton>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={6} />
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <MDInput
              // variant="standard"
              type="text"
              label="TaxIds"
              fullWidth
              required
              disabled
              multiline
              rows={5}
              InputLabelProps={{ shrink: true }}
              value={showValueTempTaxId(newValueTaxIds, 100)}
            />
          </Grid>
        </Grid>
      </MDBox>
    </BKModal>
  );

  const deleteJsonData = async (id) => {
    if (window.confirm("¿Quiere eliminar el registro?")) {
      setLoad(true);
      try {
        const body = {
          Id: id,
          User: userInfo.correo,
        };
        const apiResponse = await (
          await APIRequestGeneric(
            "POST",
            `${host}/api/AccountSettings/DeleteApiKey`,
            body,
            false,
            basicAuthorization
          )
        ).json();
        // console.log("apiResponse", apiResponse);

        if (apiResponse.Code === 200 && apiResponse.IsSuccessful) {
          const index = jsonData.map((item) => item.id).indexOf(id);
          const elements = [...jsonData];
          const nombreElemento = elements[index].taxId;
          elements.splice(index, 1);
          setJsonData(elements);

          showNotificationSuccess(`Se eliminó el registro '${nombreElemento}' exitosamente.`);
        } else {
          showNotificationError(`Error: ${apiResponse.Data}`);
        }
      } catch (e) {
        showNotificationError(`Ocurrió un error al eliminar el registro: ${e.message}`);
      }
      setLoad(false);
    }
  };

  const setEditJsonData = (element) => {
    navigate("/ApiKey/ApiKeySBXForm", { state: { route, element } });
  };

  const getCountriesData = async () => {
    const tempJsonData = [];
    const apiResponse = await (await APIRequest("GET", "/api/Pais/Todos", null)).json();

    for (let index = 0; index < apiResponse.length; index += 1) {
      tempJsonData.push({
        id: apiResponse[index].codigo.toLowerCase(),
        nombre: apiResponse[index].nombre,
      });
    }

    setSelectData({
      pais: tempJsonData,
      autenticacion: selectData.autenticacion,
    });

    return tempJsonData;
  };

  const bloquearDesbloquearJsonData = async (valueId) => {
    setLoad(true);
    const tempJsonData = jsonData;
    const index = tempJsonData.map((item) => item.id).indexOf(valueId);

    const urlComplete = `${host}/api/AccountSettings/UpdateApiKey`;
    const body = {
      Id: valueId, // *****          Obligatorio
      Block: !tempJsonData[index].bloqueado, // *****          Opcional
      User: userInfo.correo, //       Obligatorio
    };

    try {
      const apiResponse = await (
        await APIRequestGeneric("POST", urlComplete, body, false, basicAuthorization)
      ).json();

      if (apiResponse.Code === 200 && apiResponse.IsSuccessful) {
        const today = new Date();
        const fechaActual = `${today.getFullYear()}-${(today.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${today.getDate().toString().padStart(2, "0")}`;
        // ***** ESCRIBIR EL USUARIO QUE ACTUALIZA EN NUESTRA BASE DE DATOS PARA LEERLO DESPUÉS

        tempJsonData[index].bloqueado = !tempJsonData[index].bloqueado;
        tempJsonData[index].ultimaAccion = `${userInfo.correo} ${fechaActual}`;
        setJsonData(tempJsonData);
        showNotificationSuccess("Se actualizó el estado de bloqueo exitosamente");
      } else {
        showNotificationError(`Error: ${apiResponse.Data}`);
      }
    } catch {
      showNotificationError("Ocurrió un error inesperado.");
    }
    setLoad(false);
  };

  const verPasswordJsonData = (valueId) => {
    const index = jsonData.map((item) => item.id).indexOf(valueId);
    const elements = [...jsonData];
    elements[index].verPassword = !elements[index].verPassword;
    setJsonData(elements);
  };

  const setEditCommentJsonData = (valueId) => {
    setNewValueId(valueId);

    const index = jsonData.map((item) => item.id).indexOf(valueId);
    setNewValueId(jsonData[index].id);
    setNewValueTaxId(jsonData[index].taxId);
    setNewValueComment(jsonData[index].comentario);

    setModalComment(true);
  };

  const setEditPasswordJsonData = (valueId) => {
    setNewValueId(valueId);

    const index = jsonData.map((item) => item.id).indexOf(valueId);
    setNewValueId(jsonData[index].id);
    setNewValueTaxId(jsonData[index].taxId);
    setNewValuePassword(jsonData[index].password);

    setModalPassword(true);
  };

  const addTaxIdsJsonData = (valueId) => {
    setNewValueId(valueId);

    const index = jsonData.map((item) => item.id).indexOf(valueId);
    setNewValueId(jsonData[index].id);
    setNewValueTaxId(jsonData[index].taxId);
    setNewValueTaxIds(jsonData[index].taxIds);

    setModalAddTaxIds(true);
  };

  useEffect(async () => {
    try {
      setLoad(true);
      await getCountriesData();
      await getJsonData();
      // eslint-disable-next-line no-empty
    } catch {}

    setLoad(false);
  }, [pathname]);

  const { columns, rows } = data(
    jsonData,
    deleteJsonData,
    setEditJsonData,
    bloquearDesbloquearJsonData,
    verPasswordJsonData,
    setEditCommentJsonData,
    setEditPasswordJsonData,
    addTaxIdsJsonData,
    showValueTempTaxId
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {renderModalComment}
      {renderModalPassword}
      {renderModalAddTaxIds}
      <MDBox pt={3} pb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MDTypography variant="subtitle" fontWeight="bold">
              ApiKey Sandbox
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="left" alignItems="left" height="100%">
              <Grid item xs={12} sm={12} md={6} lg={4} xl={10} />
              <Grid item xs={12} sm={12} md={6} lg={4} xl={2} container justifyContent="flex-end">
                <MDButton
                  variant="contained"
                  onClick={() => navigate("/ApiKey/ApiKeySBXForm", { state: { route } })}
                  sx={{
                    background: "linear-gradient(45deg, #1971C2 30%, #1971C2 90%)",
                    color: "#FFFFFF",
                  }}
                >
                  <Icon>add_circle_outline</Icon>&nbsp;Agregar
                </MDButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox>
                <DataTable
                  entriesPerPage={(false, { defaultValue: 10, entries: [5, 10, 25, 50] })}
                  canSearch
                  showTotalEntries
                  table={{ columns, rows }}
                  pagination={{ variant: "contained", color: "warning" }}
                  isSorted
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {renderNotificacion}
      <Loading load={load} />
      <Footer />
    </DashboardLayout>
  );
}

export default ApiKeySandbox;
