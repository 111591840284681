import PropTypes from "prop-types";
import MDSnackbar from "../../../../../../components/MDSnackbar";

function Notification({ notification, setNotification }) {
  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };

  return (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );
}

Notification.propTypes = {
  notification: PropTypes.shape({
    color: PropTypes.string,
    icon: PropTypes.string,
    title: PropTypes.string,
    content: PropTypes.string,
    dateTime: PropTypes.string,
    show: PropTypes.bool,
  }).isRequired,
  setNotification: PropTypes.func.isRequired,
};

export default Notification;
