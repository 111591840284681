/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// @mui material components
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDInput from "components/MDInput";
import MDDatePicker from "components/MDDatePicker";
import MDButton from "components/MDButton";
import Loading from "components/Loading";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Data
import { FormattDate } from "util/ramdomCode";

// API Request
import APIRequest from "api/request";
import APIRequestGeneric from "api/requestGeneric";

function ErroresID() {
  const host = process.env.REACT_APP_API_URL;
  const basicAuth = `${process.env.REACT_APP_API_ENEX_USER}:${process.env.REACT_APP_API_ENEX_PASSWORD}`;
  const basicAuthorization = `Basic ${btoa(basicAuth)}`;

  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  const [load, setLoad] = useState(false);
  const { pathname } = useLocation();

  const [pais, setPais] = useState({ id: "ar", nombre: "Argentina" });
  const [fecha, setFecha] = useState(new Date());
  const [idError, setIdError] = useState("");
  const [endPoint, setEndPoint] = useState({
    id: "changedocumentstatus",
    nombre: "Change Document Status",
  });
  const [descriptionError, setDescriptionError] = useState("");
  const [requestError, setRequestError] = useState("");
  const [selectData, setSelectData] = useState({
    pais: [
      { id: "ar", nombre: "Argentina" },
      { id: "gt", nombre: "Guatemala" },
    ],
    endpoint: [
      { id: "changedocumentstatus", nombre: "Change Document Status" },
      { id: "documentgetevents", nombre: "Document Get Events" },
      { id: "downloaddocumentpdf", nombre: "Download Document PDF" },
      { id: "downloaddocumentxml", nombre: "Download Document XML" },
      { id: "getdocument", nombre: "Get Document" },
      { id: "getdocumentkiosco", nombre: "Get Document Kiosco" },
      { id: "getdocumentxml", nombre: "Get Document XML" },
      { id: "senddocumenttoauthority", nombre: "Send Document To Authority" },
      { id: "uploaddocumentattachment", nombre: "Upload Document Attachment" },
    ],
  });

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };
  const showNotificationError = (message) => {
    configNotification("error", "warning", "Notificación", message, "", true);
  };
  const showNotificationSuccess = (message) => {
    configNotification("warning", "check", "Notificación", message, "", true);
  };
  const getJsonData = async () => {
    const body = {
      IdError: idError,
      Endpoint: endPoint.id,
      CountryId: pais.id,
      DateError: `${fecha.getFullYear()}-${FormattDate(fecha.getMonth() + 1)}-${FormattDate(
        fecha.getDate()
      )}`,
    };
    const urlComplete = `${host}/api/Document/GetErrorSendDocumentToAuthority `;
    try {
      const apiResponse = await (
        await APIRequestGeneric("POST", urlComplete, body, false, basicAuthorization)
      ).json();

      if (apiResponse.Code === 200 && apiResponse.IsSuccessful) {
        const responseData = JSON.parse(apiResponse.Data);
        if (responseData != null) {
          const request = responseData.Request;
          const errorDara = {
            Message: responseData.Message,
            StackTrace: responseData.StackTrace,
          };
          setRequestError(request);
          setDescriptionError(errorDara);
          showNotificationSuccess("Se completó la acción exitosamente");
        } else {
          showNotificationError("No hay data");
        }
      } else {
        showNotificationError(`Error: ${apiResponse.Data}`);
      }
    } catch {
      showNotificationError("Ocurrió un error inesperado.");
    }
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );

  const getCountriesData = async () => {
    const tempJsonData = [];
    const apiResponse = await (await APIRequest("GET", "/api/Pais/Todos", null)).json();

    for (let index = 0; index < apiResponse.length; index += 1) {
      tempJsonData.push({
        id: apiResponse[index].codigo.toLowerCase(),
        nombre: apiResponse[index].nombre,
      });
    }

    setSelectData({
      pais: tempJsonData,
      endpoint: selectData.endpoint,
    });

    return tempJsonData;
  };

  useEffect(async () => {
    try {
      setLoad(true);
      await getCountriesData();
    } catch {
      showNotificationError("Ocurrió un error inesperado.");
    }

    setLoad(false);
  }, [pathname]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MDTypography variant="subtitle" fontWeight="bold">
              Se permite buscar la descripción de error entregado por API
            </MDTypography>
          </Grid>
          <Grid
            item
            xs={12}
            container
            spacing={3}
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Grid item sm={12} md={6} lg={2} alignItems="center">
              <Autocomplete
                value={pais}
                onChange={async (event, newValue) => {
                  setPais(newValue);
                }}
                options={selectData.pais}
                getOptionLabel={(option) => option.nombre}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <MDInput
                    variant="standard"
                    type="text"
                    label="Seleccione país"
                    required
                    InputLabelProps={{ shrink: true }}
                    {...params}
                  />
                )}
              />
            </Grid>
            <Grid item sm={12} md={6} lg={2} alignItems="center">
              <Autocomplete
                value={endPoint}
                onChange={async (event, newValue) => {
                  setEndPoint(newValue);
                }}
                options={selectData.endpoint}
                getOptionLabel={(option) => option.nombre}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <MDInput
                    variant="standard"
                    type="text"
                    label="Seleccione el Endpoint"
                    required
                    InputLabelProps={{ shrink: true }}
                    {...params}
                  />
                )}
              />
            </Grid>
            <Grid item sm={12} md={6} lg={2} alignItems="center">
              <MDDatePicker
                options={{
                  enableTime: false,
                  dateFormat: "Y-m-d",
                }}
                input={{
                  fullWidth: true,
                  variant: "standard",
                  label: "Seleccione fecha error",
                  required: true,
                }}
                InputLabelProps={{ shrink: true }}
                value={fecha}
                onChange={(e) => {
                  setFecha(e[0]);
                }}
              />
            </Grid>
            <Grid item sm={12} md={6} lg={6} alignItems="center">
              <MDInput
                fullWidth
                variant="standard"
                type="text"
                label="Ingrese ID error"
                required
                InputLabelProps={{ shrink: true }}
                value={idError}
                onChange={(e) => {
                  setIdError(e.target.value);
                }}
              />
            </Grid>

            <Grid item sm={12} md={6} lg={2}>
              <Grid
                item
                sm={12}
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <MDButton
                  variant="contained"
                  onClick={() => getJsonData()}
                  sx={{
                    background: "linear-gradient(45deg, #1971C2 30%, #1971C2 90%)",
                    color: "#FFFFFF",
                    ml: "auto",
                  }}
                >
                  <Icon>search</Icon>&nbsp;Buscar
                </MDButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox sx={{ position: "relative", mx: 3, py: 2, px: 2 }}>
                <Grid container spacing={3} justifyContent="left" alignItems="left" height="100%">
                  <Grid item xs={12}>
                    <MDInput
                      variant="standard"
                      type="text"
                      label="Código error"
                      fullWidth
                      disabled
                      InputLabelProps={{ shrink: true }}
                      value={idError}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <MDInput fullWidth readOnly label="Request" multiline value={requestError} />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <MDInput
                      fullWidth
                      readOnly
                      label="Descripción error"
                      multiline
                      value={JSON.stringify(descriptionError)}
                    />
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {renderNotificacion}
      <Loading load={load} />
      <Footer />
    </DashboardLayout>
  );
}

export default ErroresID;
