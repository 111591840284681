/* eslint-disable react/prop-types */

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Default Components
import MDTypography from "components/MDTypography";
import FormatDate from "../../_custom/functions/formatDate";

export default function data(jsonData, deleteJsonData) {
  const getRows = () => {
    const elements = [];

    for (let index = 0; index < jsonData.length; index += 1) {
      elements.push({
        date: FormatDate(jsonData[index].date),
        comments: jsonData[index].comments,
        version: jsonData[index].version,
        action: (
          <MDTypography color="text" onClick={() => deleteJsonData(jsonData[index].id)}>
            <Tooltip title="Eliminar estado" disableInteractive>
              <Icon>delete</Icon>
            </Tooltip>
          </MDTypography>
        ),
      });
    }

    return elements;
  };

  return {
    columns: [
      { Header: "Fecha", accessor: "date", align: "left" },
      { Header: "Comentarios", accessor: "comments", align: "left" },
      { Header: "Versión", accessor: "version", align: "left" },
      { Header: "Accion", accessor: "action", align: "left" },
    ],

    rows: getRows(),
  };
}

// Setting default values for the props of MDBadge
data.defaultProps = {
  jsonData: [],
};

// Typechecking props of the data
data.propTypes = {
  jsonData: PropTypes.objectOf(PropTypes.array),
  deleteJsonData: PropTypes.func.isRequired,
};
