import React, { useState } from "react";
import PropTypes from "prop-types";
import { Grid, IconButton, InputAdornment, ListItemIcon, MenuItem } from "@mui/material";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import BKModal from "components/BKModal";
import MDInput from "components/MDInput";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { EXTENSIONS_FILE } from "views/admin/xpos/_constants/configGlobal/showContent";
import xPosApiRequest from "api/xPosApiRequest";
import { isOk } from "views/admin/xpos/_helpers/requestHelpers";

// TODO: props que se puede llevar a un estado global organizationConfigGuid, country,
// TODO: actions reducers que se pueden ser globales setLoading, showNotificationError, showNotificationSuccess, setData

function UpdateFile({
  handleClose,
  country,
  fileName,
  keyResponse,
  organizationConfigGuid,
  setLoading,
  showNotificationError,
  showNotificationSuccess,
  setData,
  fileExtension,
}) {
  const [modal, setModal] = useState(false);
  const [configDocumentValue, setConfigDocumentValue] = useState("");
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

  const handleOpenModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setConfigDocumentValue("");
    setModal(false);
    handleClose();
  };

  const handleFileUpload = (event) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const { XSLT, ZIP } = EXTENSIONS_FILE;
    const validExtensions = {
      [XSLT]: [`.${XSLT}`],
      [ZIP]: [`.${ZIP}`],
    };

    const extension = file.name.substring(file.name.lastIndexOf(".")).toLowerCase();
    const isExtensionValid = validExtensions[fileExtension]?.includes(extension);

    if (!isExtensionValid) {
      showNotificationError("La extensión del archivo es incorrecta");
    } else {
      setConfigDocumentValue(file);
    }
  };

  const handleSubmitDocument = async () => {
    setLoading(true);
    const formFile = new FormData();
    formFile.append("ConfigDocument", configDocumentValue);
    formFile.append("UserLastAction", userInfo.correo);

    const baseURL = organizationConfigGuid
      ? `/configuration/document/${fileName}`
      : `/global-configuration/document/${fileName}`;

    const headers = {
      "Content-Type": "multipart/form-data",
      countryId: country,
      ...(organizationConfigGuid && { organizationConfigGuid }),
    };

    const options = {
      method: "PUT",
      url: baseURL,
      headers,
      data: formFile,
    };

    const response = await xPosApiRequest(options);
    if (isOk(response.status)) {
      const { version, updatedAt, updatedBy } = response.data;
      const body = {
        [keyResponse]: {
          version,
          updatedBy,
          updatedAt,
        },
      };

      setConfigDocumentValue("");
      showNotificationSuccess("Actualizado exitosamente");
      setData(body);
    } else {
      const errorMessage =
        response.data && response.data.length < 100
          ? response.data
          : "Ocurrió un error al actualizar la configuración.";
      showNotificationError(errorMessage);
    }
    setLoading(false);
    closeModal();
  };

  return (
    <>
      <MenuItem onClick={handleOpenModal}>
        <ListItemIcon>
          <UploadFileOutlinedIcon fontSize="small" />
        </ListItemIcon>
        Actualizar
      </MenuItem>
      <BKModal
        closeModal={closeModal}
        saveChanges={handleSubmitDocument}
        open={modal}
        title="Actualizar archivo"
        maxWidth="xs"
        styleModal={{
          titleColor: "inherit",
          acceptButtonColor: "success",
          cancelButtonColor: "error",
          variantButton: "gradient",
        }}
        sxButton={{ textTransform: "none", borderRadius: "20px" }}
      >
        <Grid container spacing={3} mt={0}>
          <Grid item xs={12} md={12}>
            <MDInput
              label="Archivo"
              placeholder="Ingrese"
              size="medium"
              variant="outlined"
              value={configDocumentValue?.name ?? ""}
              fullWidth
              required
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: (
                  <>
                    <input
                      type="file"
                      accept=".xslt, .zip"
                      onChange={(event) => handleFileUpload(event)}
                      tabIndex={-1}
                      style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        opacity: 0,
                      }}
                    />
                    <InputAdornment position="end">
                      <IconButton edge="end">
                        <CloudUploadOutlinedIcon />
                      </IconButton>
                    </InputAdornment>
                  </>
                ),
              }}
            />
          </Grid>
        </Grid>
      </BKModal>
    </>
  );
}

UpdateFile.defaultProps = {
  organizationConfigGuid: "",
};

UpdateFile.propTypes = {
  country: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  fileName: PropTypes.string.isRequired,
  setLoading: PropTypes.func.isRequired,
  showNotificationError: PropTypes.func.isRequired,
  showNotificationSuccess: PropTypes.func.isRequired,
  setData: PropTypes.func.isRequired,
  keyResponse: PropTypes.string.isRequired,
  fileExtension: PropTypes.string.isRequired,
  organizationConfigGuid: PropTypes.string,
};

export default UpdateFile;
