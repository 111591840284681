/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Tooltip from "@mui/material/Tooltip";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

// Default Components
import MDTypography from "../../../../../../components/MDTypography";

export default function data(jsonData, handleDownload) {
  const getRows = () => {
    const elements = [];

    for (let index = 0; index < jsonData.length; index += 1) {
      elements.push({
        fecha: `${jsonData[index].date.split("T")[0]} ${jsonData[index].date.split("T")[1]}`,
        ambiente: jsonData[index].environment,
        guid: jsonData[index].guid,
        action: (
          <MDTypography
            color="text"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Tooltip
              style={{ cursor: "pointer" }}
              title="Descargar"
              onClick={() => {
                handleDownload([jsonData[index].guid]);
              }}
              disableInteractive
            >
              <FileDownloadIcon
                fontSize="20px"
                style={{ fontSize: "20px", cursor: "pointer", marginRight: 10 }}
              />
            </Tooltip>
          </MDTypography>
        ),
      });
    }

    return elements;
  };

  return {
    columns: [
      { Header: "Fecha", accessor: "fecha", align: "center" },
      { Header: "Ambiente", accessor: "ambiente", align: "center" },
      { Header: "Acciones", accessor: "action", align: "center" },
    ],

    rows: getRows(),
  };
}

// Setting default values for the props of MDBadge
data.defaultProps = {
  jsonData: [],
};

// Typechecking props of the data
data.propTypes = {
  jsonData: PropTypes.objectOf(PropTypes.array),
  setEditJsonData: PropTypes.func.isRequired,
  handleDownload: PropTypes.func.isRequired,
};
