/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import CircularProgress from "@mui/material/CircularProgress";

// @mui material components
// import Switch from "@mui/material/Switch";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import { FormattDate } from "util/ramdomCode";

export default function data(
  jsonData,
  setEditEstado,
  setEditJsonData,
  deleteJsonData,
  reprocesarDocumentos,
  estadoDocument,
  loadReprocess
) {
  const getRows = () => {
    const elements = [];

    for (let index = 0; index < jsonData.length; index += 1) {
      const startDate = new Date(jsonData[index].fechaInicio);
      const endDate = jsonData[index].fechaFin ? new Date(jsonData[index].fechaFin) : null;
      const actionDate = new Date(jsonData[index].fechaAccion);
      elements.push({
        estado: jsonData[index].estado?.nombre,
        fechaInicio: `${startDate.getDate()}/${
          startDate.getMonth() + 1
        }/${startDate.getFullYear()} ${FormattDate(startDate.getHours())}:${FormattDate(
          startDate.getMinutes()
        )}:${FormattDate(startDate.getSeconds())}`,
        fechaFin:
          endDate || endDate !== null
            ? `${endDate.getDate()}/${
                endDate.getMonth() + 1
              }/${endDate.getFullYear()} ${FormattDate(endDate.getHours())}:${FormattDate(
                endDate.getMinutes()
              )}:${FormattDate(endDate.getSeconds())}`
            : "",

        usuario: jsonData[index].usuario,
        fechaAccion: `${actionDate.getDate()}/${
          actionDate.getMonth() + 1
        }/${actionDate.getFullYear()} ${FormattDate(actionDate.getHours())}:${FormattDate(
          actionDate.getMinutes()
        )}:${FormattDate(actionDate.getSeconds())}`,
        action_item:
          // eslint-disable-next-line no-nested-ternary
          jsonData[index].estado?.id === 3 ||
          jsonData[index].estado?.id === 4 ||
          jsonData[index].estado?.id === 5 ? (
            <MDBox display="flex" justifyContent="space-between" alignItems="center">
              <MDButton
                variant="outlined"
                autoFocus
                color={jsonData[index].disabledReprocess ? "light" : "warning"}
                disabled={jsonData[index].disabledReprocess}
                onClick={() => {
                  reprocesarDocumentos(jsonData[index].id);
                }}
              >
                Reprocesar
              </MDButton>
              {jsonData[index].estado?.id === 4 && (
                <MDBox position="relative" display="inline-flex">
                  <CircularProgress color="info" />
                  <MDBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    position="absolute"
                    top="0"
                    left="0"
                    bottom="0"
                    right="0"
                  >
                    {loadReprocess < 30 ? (
                      <MDTypography
                        variant="caption"
                        component="div"
                        color="info"
                      >{`${loadReprocess}`}</MDTypography>
                    ) : (
                      <Icon color="success">update</Icon>
                    )}
                  </MDBox>
                </MDBox>
              )}
            </MDBox>
          ) : (
            <MDTypography color="text">
              {jsonData[index].estado?.id === 1 ? (
                <>
                  <Tooltip
                    title="Habilitar"
                    disableInteractive
                    onClick={() => {
                      setEditEstado(jsonData[index].id);
                    }}
                  >
                    <Icon
                      color={
                        jsonData[index].enCola || jsonData[index].disableActive
                          ? "light"
                          : "success"
                      }
                    >
                      check_circle
                    </Icon>
                  </Tooltip>{" "}
                  /{" "}
                  <Tooltip
                    title="Editar Registro"
                    disableInteractive
                    onClick={() => {
                      setEditJsonData(jsonData[index].id);
                    }}
                  >
                    <Icon color={jsonData[index].enCola ? "light" : "warning"}>edit</Icon>
                  </Tooltip>{" "}
                  /{" "}
                  <Tooltip
                    title="Eliminar Registro"
                    disableInteractive
                    onClick={() => {
                      deleteJsonData(jsonData[index].id);
                    }}
                  >
                    <Icon color={jsonData[index].enCola ? "light" : "error"}>delete</Icon>
                  </Tooltip>
                </>
              ) : (
                <Tooltip
                  title="Desabilitar"
                  disableInteractive
                  onClick={() => {
                    setEditEstado(jsonData[index].id);
                  }}
                >
                  <Icon
                    color={
                      jsonData[index].enCola || jsonData[index].disableActive ? "light" : "success"
                    }
                  >
                    cancel
                  </Icon>
                </Tooltip>
              )}
            </MDTypography>
          ),
        reproceso:
          // eslint-disable-next-line no-nested-ternary
          jsonData[index].estado?.id === 4 ? (
            <MDBox display="flex" justifyContent="space-between" alignItems="center">
              <MDTypography color="success">
                {estadoDocument ? estadoDocument.procesados : 0}
              </MDTypography>
              /<MDTypography color="info">{estadoDocument ? estadoDocument.total : 0}</MDTypography>
            </MDBox>
          ) : jsonData[index].estado?.id === 5 ? (
            <Icon color="success">done</Icon>
          ) : (
            ""
          ),
      });
    }

    return elements;
  };

  const getColumns = () => {
    const elements = [
      { Header: "Estado", accessor: "estado", align: "center" },
      { Header: "Fecha Inicio", accessor: "fechaInicio", align: "center" },
      { Header: "Fecha Fin", accessor: "fechaFin", align: "center" },
      { Header: "Usuario", accessor: "usuario", align: "center" },
      { Header: "Fecha Accion", accessor: "fechaAccion", align: "center" },
      { Header: "Accion", accessor: "action_item", align: "center" },
      { Header: "En Reproceso", accessor: "reproceso", align: "center" },
    ];

    return elements;
  };
  return {
    columns: getColumns(),
    rows: getRows(),
  };
}

// Setting default values for the props of MDBadge
data.defaultProps = {
  jsonData: [],
};

// Typechecking props of the data
data.propTypes = {
  jsonData: PropTypes.objectOf(PropTypes.array),
  setEditEstado: PropTypes.func.isRequired,
  setEditJsonData: PropTypes.func.isRequired,
  deleteJsonData: PropTypes.func.isRequired,
  reprocesarDocumentos: PropTypes.func.isRequired,
};
