/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// Default Components
import MDTypography from "components/MDTypography";

export default function data(
  jsonData,
  deleteJsonData,
  setEditJsonData,
  bloquearDesbloquearJsonData,
  verPasswordJsonData,
  setEditCommentJsonData,
  setEditPasswordJsonData,
  addTaxIdsJsonData,
  showValueTempTaxId
) {
  const getRows = () => {
    const elements = [];

    for (let index = 0; index < jsonData.length; index += 1) {
      elements.push({
        taxId: showValueTempTaxId([jsonData[index].taxId, ...jsonData[index].taxIds], 3),
        fecha: jsonData[index].fecha,
        autenticacion: jsonData[index].autenticacion ? jsonData[index].autenticacion.nombre : "",
        usuario: jsonData[index].usuario,
        password: (
          <MDTypography variant="subtitle2" fontWeight="light" color="text">
            {jsonData[index].verPassword ? jsonData[index].password : "*****"}{" "}
            <Tooltip
              title={jsonData[index].bloqueado ? "Ocultar" : "Mostrar"}
              disableInteractive
              onClick={() => verPasswordJsonData(jsonData[index].id, true)}
            >
              <Icon>{jsonData[index].verPassword ? "visibility_off" : "visibility"}</Icon>
            </Tooltip>
          </MDTypography>
        ),
        ip: jsonData[index].ip,
        usado: jsonData[index].usado,
        action: (
          <>
            {jsonData[index].componente === "xPOS" && (
              <MDTypography color="text">
                <Tooltip title="xPOS" disableInteractive>
                  <Icon>computer</Icon>
                </Tooltip>
              </MDTypography>
            )}
            {jsonData[index].componente !== "xPOS" && (
              <>
                <MDTypography color="text" display={jsonData[index].bloqueado ? "flex" : "none"}>
                  <Tooltip
                    title={jsonData[index].bloqueado ? "Desbloquear" : "Bloquear"}
                    disableInteractive
                    onClick={() => bloquearDesbloquearJsonData(jsonData[index].id, true)}
                  >
                    <Icon>{jsonData[index].bloqueado ? "lock" : "lock_open"}</Icon>
                  </Tooltip>
                  {" / "}
                  <Tooltip
                    title="Comentario"
                    disableInteractive
                    onClick={() => setEditCommentJsonData(jsonData[index].id)}
                  >
                    <Icon>forum</Icon>
                  </Tooltip>
                  {" / "}
                  <Tooltip
                    title="Agregar TaxID"
                    disableInteractive
                    onClick={() => addTaxIdsJsonData(jsonData[index].id)}
                  >
                    <Icon>add</Icon>
                  </Tooltip>
                </MDTypography>
                <MDTypography color="text" display={jsonData[index].bloqueado ? "none" : "flex"}>
                  <Tooltip
                    title={jsonData[index].bloqueado ? "Desbloquear" : "Bloquear"}
                    disableInteractive
                    onClick={() => bloquearDesbloquearJsonData(jsonData[index].id, true)}
                  >
                    <Icon>{jsonData[index].bloqueado ? "lock" : "lock_open"}</Icon>
                  </Tooltip>{" "}
                  /{" "}
                  <Tooltip
                    title="Comentario"
                    disableInteractive
                    onClick={() => setEditCommentJsonData(jsonData[index].id)}
                  >
                    <Icon>forum</Icon>
                  </Tooltip>{" "}
                  /{" "}
                  <Tooltip
                    title="Cambiar Contraseña"
                    disableInteractive
                    onClick={() => setEditPasswordJsonData(jsonData[index].id)}
                  >
                    <Icon>autorenew</Icon>
                  </Tooltip>{" "}
                  /{" "}
                  <Tooltip
                    title="Editar"
                    disableInteractive
                    onClick={() => {
                      setEditJsonData(jsonData[index]);
                    }}
                  >
                    <Icon>edit</Icon>
                  </Tooltip>{" "}
                  /{" "}
                  <Tooltip
                    title="Agregar TaxID"
                    disableInteractive
                    onClick={() => addTaxIdsJsonData(jsonData[index].id)}
                  >
                    <Icon>add</Icon>
                  </Tooltip>{" "}
                  /{" "}
                  <Tooltip
                    title="Eliminar"
                    disableInteractive
                    onClick={() => deleteJsonData(jsonData[index].id)}
                  >
                    <Icon>delete</Icon>
                  </Tooltip>
                </MDTypography>
              </>
            )}
          </>
        ),
        usuarioCrea: jsonData[index].usuarioCrea,
        ultimaAccion: jsonData[index].ultimaAccion,
      });
    }

    return elements;
  };

  return {
    columns: [
      // { Header: "", accessor: "icon", width: "0%", align: "center" },
      { Header: "Tax Id", accessor: "taxId", align: "center" },
      { Header: "Fecha", accessor: "fecha", align: "center" },
      { Header: "Método Autenticación", accessor: "autenticacion", align: "center" },
      { Header: "Usuario", accessor: "usuario", align: "center" },
      { Header: "Contraseña", accessor: "password", align: "center" },
      { Header: "IP", accessor: "ip", align: "center" },
      { Header: "Usado", accessor: "usado", align: "center" },
      { Header: "Gestión", accessor: "action", align: "center" },
      { Header: "Usuario Creación", accessor: "usuarioCrea", align: "center" },
      { Header: "Última Acción", accessor: "ultimaAccion", align: "center" },
    ],

    rows: getRows(),
  };
}

// Setting default values for the props of MDBadge
data.defaultProps = {
  jsonData: [],
};

// Typechecking props of the data
data.propTypes = {
  jsonData: PropTypes.objectOf(PropTypes.array),
  deleteJsonData: PropTypes.func.isRequired,
  setEditJsonData: PropTypes.func.isRequired,
  bloquearDesbloquearJsonData: PropTypes.func.isRequired,
  verPasswordJsonData: PropTypes.func.isRequired,
  setEditCommentJsonData: PropTypes.func.isRequired,
  setEditPasswordJsonData: PropTypes.func.isRequired,
  addTaxIdsJsonData: PropTypes.func.isRequired,
  showValueTempTaxId: PropTypes.func.isRequired,
};
