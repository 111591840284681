/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { useState } from "react";

// // react-router-dom components
// import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import LogoGosocketWhite from "assets/images/logo-gosocket-white.png";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "plugins/authConfig";
// import { PublicClientApplication } from "@azure/msal-browser";

import { useNavigate } from "react-router-dom";

// API Request
import APIRequest from "api/request";

import * as Global from "util/global";

function Basic() {
  const { instance } = useMsal();
  const navigate = useNavigate();

  // eslint-disable-next-line arrow-body-style, no-unused-vars
  const getIdFuncionalidad = (areaId, funcionalidadId) => {
    return parseInt(`${areaId.toString()}${funcionalidadId.toString().padStart(6, "0")}`, 10);
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    console.log("event", event);

    const email = event.target.email.value;

    instance
      .loginPopup({ loginHint: email, scopes: [...loginRequest.scopes] })
      .then(async (response) => {
        // console.log("La respuesta es correcta, Access token:", response.accessToken);
        sessionStorage.setItem("token", response.accessToken);
        Global.setCloseSession(false);
        sessionStorage.setItem("expireDateToken", response.expiresOn);

        let apiEmailRequest = await APIRequest(
          "GET",
          `/Api/Usuario/Correo/${response.account.username}`,
          null
        );

        if (apiEmailRequest.status === 204) {
          const bodyNewUser = {
            nombre: response.account.name,
            correo: response.account.username,
            AzureId: response.account.localAccountId,
          };

          const newUserRequest = await APIRequest("POST", "/Api/Usuario", bodyNewUser);

          if (newUserRequest.status === 200) {
            apiEmailRequest = await APIRequest(
              "GET",
              `/Api/Usuario/Correo/${response.account.username}`,
              null
            );
          }
        }

        if (apiEmailRequest.status === 200) {
          const apiResponse = await apiEmailRequest.json();
          sessionStorage.setItem("userInfo", JSON.stringify(apiResponse));

          let tipoPerfilUsuario = 3;

          for (let index = 0; index < apiResponse.perfiles.length; index += 1) {
            if (apiResponse.perfiles[index].tipoPerfil.id < tipoPerfilUsuario) {
              tipoPerfilUsuario = apiResponse.perfiles[index].tipoPerfil.id;
            }
          }

          // tipoPerfilUsuario = 2;
          sessionStorage.setItem("userType", tipoPerfilUsuario);

          const areaAdmin = [];

          if (tipoPerfilUsuario === 2) {
            /* const perfilesResponse = await (
              await APIRequest("GET", "/Api/Perfil/Todos", null)
            ).json(); */

            for (let index = 0; index < apiResponse.perfiles.length; index += 1) {
              if (apiResponse.perfiles[index].tipoPerfil.id === 2) {
                areaAdmin.push(apiResponse.perfiles[index].area.id);

                /* for (let i = 0; i < perfilesResponse.length; i += 1) {
                  if (apiResponse.perfiles[index].id === perfilesResponse[i].id) {
                    areaAdmin.push(perfilesResponse[i].area.id);
                  }
                } */
              }
            }
          }

          sessionStorage.setItem("userAreaAdmin", JSON.stringify(areaAdmin));

          const funcionalidades = [];

          for (let index = 0; index < apiResponse.perfiles.length; index += 1) {
            for (let i = 0; i < apiResponse.perfiles[index].funcionalidades.length; i += 1) {
              const funcionalidadId = getIdFuncionalidad(
                apiResponse.perfiles[index].area.id,
                apiResponse.perfiles[index].funcionalidades[i].funcionalidadId
              );
              // const funcionalidadId = apiResponse.perfiles[index].funcionalidades[i].funcionalidadId;

              if (funcionalidades.indexOf(funcionalidadId) < 0) {
                if (apiResponse.perfiles[index].funcionalidades[i].estado) {
                  funcionalidades.push(
                    funcionalidadId
                    // apiResponse.perfiles[index].funcionalidades[i].funcionalidadId
                  );
                }
              }
            }
          }

          for (let index = 0; index < areaAdmin.length; index += 1) {
            // eslint-disable-next-line no-await-in-loop
            const areaResponse = await // eslint-disable-next-line no-await-in-loop
            (await APIRequest("GET", `/Api/Area/${areaAdmin[index]}`, null)).json();

            for (let i = 0; i < areaResponse.funcionalidades.length; i += 1) {
              const funcionalidadId = getIdFuncionalidad(
                areaResponse.id,
                areaResponse.funcionalidades[i].id
              );
              // const funcionalidadId = areaResponse.funcionalidades[i].id;

              if (funcionalidades.indexOf(funcionalidadId) < 0) {
                if (areaResponse.funcionalidades[i].estado) {
                  funcionalidades.push(
                    funcionalidadId
                    // areaResponse.funcionalidades[i].id
                  );
                }
              }
            }
          }

          sessionStorage.setItem("userFunctionalities", JSON.stringify(funcionalidades));

          navigate("/dashboards/entidadesTributarias");
        } else {
          console.log("No se pudo iniciar sesión, error al obtener los datos internos.");
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <MDBox>
      <MDBox
        width="100%"
        py={1}
        bgColor="warning"
        coloredShadow="warning"
        textAlign="center"
        position="absolute"
        z-index="99"
      >
        <MDBox component="img" src={LogoGosocketWhite} alt="Logo" width="15rem" />
      </MDBox>
      <BasicLayout>
        <Card>
          <MDBox pt={4} pb={3} px={3}>
            <form onSubmit={handleLogin}>
              <MDBox mb={2}>
                <MDInput type="email" label="Email" name="email" fullWidth required />
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton variant="gradient" color="warning" type="submit" fullWidth>
                  sign in
                </MDButton>
              </MDBox>
            </form>
          </MDBox>
        </Card>
      </BasicLayout>
    </MDBox>
  );
}

export default Basic;
