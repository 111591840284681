/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// @mui material components
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Loading from "components/Loading";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import data from "views/producto/XMLHistoricos/data";
import BKModal from "views/producto/XMLHistoricos/modalRespaldo";

// API Request
import APIRequest from "api/request";
import APIRequestGeneric from "api/requestGeneric";

function XMLHistoricos() {
  const host = process.env.REACT_APP_API_URL;
  const basicAuth = `${process.env.REACT_APP_API_ENEX_USER}:${process.env.REACT_APP_API_ENEX_PASSWORD}`;
  const basicAuthorization = `Basic ${btoa(basicAuth)}`;

  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  const [modalDatosRespaldo, setModalDatosRespaldo] = useState(false);
  const [load, setLoad] = useState(false);
  const [jsonData, setJsonData] = useState([]);
  const { pathname } = useLocation();
  const { state } = useLocation();
  const route = pathname;
  const navigate = useNavigate();

  // const [elementToEdit, setElementToEdit] = useState(null);
  // eslint-disable-next-line no-unused-vars
  // const [newValuePais, setNewValuePais] = useState(null);
  // eslint-disable-next-line no-unused-vars
  // const [newValueTaxId, setNewValueTaxId] = useState("");
  const [newValueTipoRespaldo, setNewValueTipoRespaldo] = useState(null);
  const [newValueTipoComunicacion, setNewValueTipoComunicacion] = useState(null);
  const [newValueURL, setNewValueURL] = useState("");
  const [newValueContainer, setNewValueContainer] = useState("");

  const [selectData, setSelectData] = useState({
    pais: [
      { id: "ar", nombre: "Argentina" },
      { id: "gt", nombre: "Guatemala" },
    ],
    tipoRespaldo: [
      { id: "01", nombre: "Gosocket" }, // 1
      { id: "02", nombre: "Cliente" }, // 2
    ],
    tipoComunicacion: [
      { id: 1, nombre: "Azure" },
      { id: 2, nombre: "Amazon" },
      { id: 3, nombre: "SFTP" },
    ],
  });

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };
  const showNotificationError = (message) => {
    configNotification("error", "warning", "Notificación", message, "", true);
  };
  const showNotificationSuccess = (message) => {
    configNotification("warning", "check", "Notificación", message, "", true);
  };

  const closeModalDatosRespaldo = () => {
    setModalDatosRespaldo(false);
    setNewValueTipoRespaldo(null);
    setNewValueTipoComunicacion(null);
    setNewValueURL("");
    setNewValueContainer("");
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );

  const chekLogs = (element) => {
    if (element) {
      sessionStorage.setItem("jsonData", JSON.stringify(jsonData));
      navigate("/XMLHistoricos/Log", { state: { route, element } });
    }
  };

  const copyLinkToClipboard = (element) => {
    const aux = document.createElement("input");
    aux.setAttribute("value", element.url);
    document.body.appendChild(aux);
    aux.select();
    document.execCommand("copy");
    document.body.removeChild(aux);
  };

  const downloadZip = async (element) => {
    try {
      // const hostLocal = "https://localhost:7037";
      // const urlComplete = `${hostLocal}/api/RequestBackup/Download?requestId=930677fd-ece8-4acb-a960-ea618ae45045`;
      const urlQueryString =
        element.pais.id === "uy" ? `&taxId=${element.taxId}&countryCode=${element.pais.id}` : "";
      const urlComplete = `${host}/api/RequestBackup/Download?requestId=${element.id}${urlQueryString}`;
      const apiResponse = await APIRequestGeneric(
        "GET",
        urlComplete,
        null,
        false,
        basicAuthorization
      );

      if (!apiResponse.ok) {
        const errorMessage = {
          code: apiResponse.status,
          message: "La solicitud de descarga no pudo completarse con éxito",
        };
        throw errorMessage;
      }

      const blob = await apiResponse.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${element.id}.zip`;
      document.body.appendChild(a);
      a.click();

      return true;
    } catch (exception) {
      showNotificationError(exception.message);
      return false;
    }
  };

  const setViewModalDatosRespaldo = (element) => {
    // setElementToEdit(element);
    setNewValueTipoRespaldo(element.tipoRespaldo);
    setNewValueTipoComunicacion(element.tipoComunicacion);
    setNewValueURL(element.url);
    setNewValueContainer(element.contenedor);

    setModalDatosRespaldo(true);
  };

  const renderModalDatosRespaldo = (
    <BKModal saveChanges={closeModalDatosRespaldo} open={modalDatosRespaldo} title="Datos Respaldo">
      <MDBox sx={{ position: "relative", mx: 3, py: 2, px: 2 }}>
        <Grid container spacing={3} justifyContent="left" alignItems="left" height="100%">
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <Autocomplete
              value={newValueTipoRespaldo}
              onChange={(event, newValue) => {
                setNewValueTipoRespaldo(newValue);
              }}
              disabled
              options={selectData.tipoRespaldo}
              getOptionLabel={(option) => option.nombre}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <MDInput
                  variant="standard"
                  type="text"
                  label="Tipo Respaldo"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  {...params}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <Autocomplete
              value={newValueTipoComunicacion}
              onChange={(event, newValue) => {
                setNewValueTipoComunicacion(newValue);
              }}
              disabled
              options={selectData.tipoComunicacion}
              getOptionLabel={(option) => option.nombre}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <MDInput
                  variant="standard"
                  type="text"
                  label="Tipo Comunicacion"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  {...params}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <MDInput
              variant="standard"
              type="text"
              label="URL"
              fullWidth
              disabled
              InputLabelProps={{ shrink: true }}
              value={newValueURL}
              onChange={(e) => {
                setNewValueURL(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <MDInput
              variant="standard"
              type="text"
              label="Contenedor"
              fullWidth
              disabled
              InputLabelProps={{ shrink: true }}
              value={newValueContainer}
              onChange={(e) => {
                setNewValueContainer(e.target.value);
              }}
            />
          </Grid>
        </Grid>
      </MDBox>
    </BKModal>
  );

  const updateMonths = async (element, total, actual, newStatus) => {
    const body = {
      id: element.id,
      status: newStatus,
      taxId: element.taxId,
      pais: element.pais.toLowerCase(),
      mesesTotales: total,
      mesesActuales: actual,
    };
    const apiResponse = await APIRequest("PATCH", "/api/XmlHistoricos", body);

    if (apiResponse.status === 200 || apiResponse.ok) {
      return true;
    }

    return false;
  };
  const getTotalMonthData = async (element) => {
    try {
      // const urlComplete = `https://apihistoricaldocssbx.blackhill-79102964.centralus.azurecontainerapps.io/api/RequestBackup/RequestBackupTracking?id=${element.id}&country=${element.pais}&taxId=${element.taxId}&includeDays=false`;
      const urlComplete = `${host}/api/RequestBackup/RequestBackupTracking?id=${element.id}&country=${element.pais}&taxId=${element.taxId}&includeDays=false`;
      const apiResponse = await (
        await APIRequestGeneric("GET", urlComplete, null, false, basicAuthorization)
      ).json();

      const result = {
        total: apiResponse.data.months.length,
        actual: 0,
      };

      /*
        { id: 0, nombre: "En espera" },
        { id: 1, nombre: "En proceso" },
        { id: 2, nombre: "Completado" },
        { id: 3, nombre: "Error" },
        { id: 4, nombre: "Completado con error" },
      */

      for (let index = 0; index < apiResponse.data.months.length; index += 1) {
        if (
          apiResponse.data.months[index].status !== 0 &&
          apiResponse.data.months[index].status !== 1
        ) {
          result.actual += 1;
        }
      }

      updateMonths(element, result.total, result.actual, apiResponse.data.status);

      return result;
    } catch {
      return {
        total: 0,
        actual: 0,
      };
    }
  };

  /* const getSpecificJsonData = async () => {
    const tempJsonData = [];
    // const urlComplete = `https://apihistoricaldocssbx.blackhill-79102964.centralus.azurecontainerapps.io/api/RequestBackup/RequestBackupByTaxId?country=${newValuePais.id}&taxId=${newValueTaxId}`;
    const urlComplete = `${host}/api/RequestBackup/RequestBackupByTaxId?country=${newValuePais.id}&taxId=${newValueTaxId}`; // ?country=cl&taxId=77586860-0
    const apiResponse = await (
      await APIRequestGeneric("GET", urlComplete, null, false, basicAuthorization)
    ).json();

    for (let index = 0; index < apiResponse.data.length; index += 1) {
      // eslint-disable-next-line no-await-in-loop
      const monthData = await getTotalMonthData(apiResponse.data[index]);

      tempJsonData.push({
        id: apiResponse.data[index].id,
        status: apiResponse.data[index].status,
        taxId: apiResponse.data[index].taxId,
        pais: selectData.pais[
          selectData.pais
            .map((item) => item.id)
            .indexOf(apiResponse.data[index].country.toLowerCase())
        ],
        mesesTotales: monthData.total, // Math.round(diffTotal / (1000 * 60 * 60 * 24 * 30)),
        mesesActuales: monthData.actual, // Math.round(diffActual / (1000 * 60 * 60 * 24 * 30)),
        tipoRespaldo:
          apiResponse.data[index].backupProvider === 0
            ? { id: "01", nombre: "Gosocket" }
            : { id: "02", nombre: "Cliente" },
        tipoComunicacion:
          apiResponse.data[index].backupProvider === 0
            ? null
            : selectData.tipoComunicacion[
                selectData.tipoComunicacion
                  .map((item) => item.id)
                  .indexOf(apiResponse.data[index].backupProvider)
              ],
        fechaInicio: apiResponse.data[index].start.substring(0, 10),
        fechaFin: apiResponse.data[index].end.substring(0, 10),
        fechaCreacion: apiResponse.data[index].created
          ? apiResponse.data[index].created.substring(0, 10)
          : apiResponse.data[index].start.substring(0, 10), // No viene
        usuario: apiResponse.data[index].user ? apiResponse.data[index].user : "", // No viene
        url: apiResponse.data[index].backupUrl ? apiResponse.data[index].backupUrl : "",
        contenedor: apiResponse.data[index].backupContainer
          ? apiResponse.data[index].backupContainer
          : "",
        zip: apiResponse.data[index].zip,
      });
    }

    setJsonData(tempJsonData);
  }; */
  const getJsonData = async (countries) => {
    const tempJsonData = [];
    const apiResponse = await (await APIRequest("GET", "/api/XmlHistoricos/Todos", null)).json();

    for (let index = 0; index < apiResponse.length; index += 1) {
      const tempElement = {
        id: apiResponse[index].id,
        status: apiResponse[index].status,
        taxId: apiResponse[index].taxId,
        pais: countries[
          countries.map((item) => item.id).indexOf(apiResponse[index].pais.toLowerCase().trim())
        ],
        mesesTotales: apiResponse[index].mesesTotales,
        mesesActuales: apiResponse[index].mesesActuales,
        tipoRespaldo:
          apiResponse[index].tipoRespaldo === 1
            ? { id: "01", nombre: "Gosocket" }
            : { id: "02", nombre: "Cliente" },
        tipoComunicacion:
          apiResponse[index].tipoComunicacion === 0
            ? null
            : selectData.tipoComunicacion[
                selectData.tipoComunicacion
                  .map((item) => item.id)
                  .indexOf(apiResponse[index].tipoComunicacion)
              ],
        fechaInicio: apiResponse[index].fechaInicio.substring(0, 10),
        fechaFin: apiResponse[index].fechaFin.substring(0, 10),
        fechaCreacion: apiResponse[index].fechaCreacion,
        usuario: apiResponse[index].usuario,
        url: apiResponse[index].backupUrl,
        contenedor: apiResponse[index].contenedor,
        zip: apiResponse[index].zip,
      };

      if (
        apiResponse[index].mesesActuales < apiResponse[index].mesesTotales ||
        apiResponse[index].mesesTotales === 0
      ) {
        // eslint-disable-next-line no-await-in-loop
        const monthData = await getTotalMonthData(apiResponse[index]);
        tempElement.mesesTotales = monthData.total;
        tempElement.mesesActuales = monthData.actual;
      }

      tempJsonData.push(tempElement);
    }

    setJsonData(tempJsonData);
  };
  const refreshJsonData = async () => {
    setLoad(true);
    await getJsonData(selectData.pais);
    setLoad(false);
  };

  const deleteJsonData = async (element) => {
    if (window.confirm("¿Quiere eliminar el registro?")) {
      try {
        // const urlComplete = "https://apihistoricaldocssbx.blackhill-79102964.centralus.azurecontainerapps.io/api/RequestBackup/DeleteRequestBackup";
        const urlComplete = `${host}/api/RequestBackup/DeleteRequestBackup`;
        const body = {
          taxId: element.taxId,
          country: element.pais.id,
          id: element.id,
        };

        // eslint-disable-next-line prettier/prettier
        const apiResponse = await APIRequestGeneric("POST", urlComplete, body, false, basicAuthorization);

        if (apiResponse.status === 200 || apiResponse.status === 202) {
          // eslint-disable-next-line prettier/prettier
          const apiResponseLocal = await APIRequest("DELETE", `/api/XmlHistoricos/${element.id}`, null);

          if (apiResponseLocal.status === 200) {
            const index = jsonData.map((item) => item.id).indexOf(element.id);
            const elements = [...jsonData];
            elements.splice(index, 1);
            setJsonData(elements);
            showNotificationSuccess(`Se eliminó el registro '${element.taxId}' exitosamente.`);
          } else {
            const errorMessage = {
              code: apiResponseLocal.status,
              message: `Error inesperado al eliminar el registro local: ${apiResponseLocal.status}`,
            };
            throw errorMessage;
          }
        } else {
          const dataResponse = await apiResponse.json();
          const message = apiResponse.status === 500 ? dataResponse.detail : dataResponse.data;
          const errorMessage = {
            code: apiResponse.status,
            message,
          };
          throw errorMessage;
        }
      } catch (e) {
        showNotificationError(`Ocurrió un error al eliminar el registro: ${e.message}`);
      }
    }
  };

  const getCountriesData = async () => {
    const tempJsonData = [];
    const apiResponse = await (await APIRequest("GET", "/api/Pais/Todos", null)).json();

    for (let index = 0; index < apiResponse.length; index += 1) {
      tempJsonData.push({
        id: apiResponse[index].codigo.toLowerCase(),
        nombre: apiResponse[index].nombre,
      });
    }

    setSelectData({
      pais: tempJsonData,
      tipoRespaldo: selectData.tipoRespaldo,
      tipoComunicacion: selectData.tipoComunicacion,
    });

    return tempJsonData;
  };

  const getElementChanges = async (countries) => {
    const jsonDataXml = JSON.parse(sessionStorage.getItem("jsonData"));
    // console.log("jsonDataXml", jsonDataXml);

    if (jsonDataXml) {
      // console.log("Entró en el if");
      sessionStorage.setItem("jsonData", null);

      if (state) {
        if (state.elementChanges) {
          const index = jsonDataXml.map((item) => item.id).indexOf(state.elementChanges.id);
          jsonDataXml[index] = state.elementChanges;
          setJsonData([...jsonDataXml]);
        } else if (state.newElement) {
          setJsonData([state.newElement, ...jsonDataXml]);
        }
      } else if (jsonDataXml) {
        setJsonData([...jsonDataXml]);
      }
    } else {
      // console.log("No entró en el if");
      await getJsonData(countries);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoad(true);
    // await getSpecificJsonData();
    setLoad(false);
  };

  useEffect(async () => {
    setLoad(true);
    try {
      const countries = await getCountriesData();
      await getElementChanges(countries);
    } catch {
      showNotificationError("Ocurrió un error inesperado al buscar la información.");
    }
    setLoad(false);
  }, [pathname]);

  const { columns, rows } = data(
    jsonData,
    chekLogs,
    copyLinkToClipboard,
    downloadZip,
    setViewModalDatosRespaldo,
    deleteJsonData
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {renderModalDatosRespaldo}
      <MDBox pt={3} pb={3} component="form" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <MDBox>
                <MDTypography variant="subtitle" fontWeight="bold">
                  Se permite crear respaldo de XML históricos emitidos por clientes
                </MDTypography>
              </MDBox>
              <MDBox color="text" px={2}>
                <MDButton
                  variant="contained"
                  onClick={refreshJsonData}
                  sx={{
                    background: "linear-gradient(45deg, #1971C2 30%, #1971C2 90%)",
                    color: "#FFFFFF",
                  }}
                >
                  <Icon>refresh</Icon>&nbsp;Actualizar datos
                </MDButton>{" "}
                <MDButton
                  variant="contained"
                  onClick={() => {
                    sessionStorage.setItem("jsonData", JSON.stringify(jsonData));
                    navigate("/XMLHistoricos/Form", { state: { route } });
                  }}
                  sx={{
                    background: "linear-gradient(45deg, #1971C2 30%, #1971C2 90%)",
                    color: "#FFFFFF",
                  }}
                >
                  <Icon>add_circle_outline</Icon>&nbsp;Agregar
                </MDButton>
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3} justifyContent="left" alignItems="left" height="100%">
              {/* <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                <Autocomplete
                  value={newValuePais}
                  onChange={(event, newValue) => {
                    setNewValuePais(newValue);
                  }}
                  options={selectData.pais}
                  getOptionLabel={(option) => option.nombre}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  renderInput={(params) => (
                    <MDInput
                      variant="standard"
                      type="text"
                      label="Pais"
                      fullWidth
                      required
                      InputLabelProps={{ shrink: true }}
                      {...params}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                <MDInput
                  variant="standard"
                  type="text"
                  label="Buscar por TaxID"
                  fullWidth
                  required
                  InputLabelProps={{ shrink: true }}
                  value={newValueTaxId}
                  onChange={(e) => {
                    setNewValueTaxId(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                <MDButton
                  variant="contained"
                  type="submit"
                  sx={{
                    background: "linear-gradient(45deg, #1971C2 30%, #1971C2 90%)",
                    color: "#FFFFFF",
                  }}
                >
                  <Icon>search</Icon>&nbsp;Buscar
                </MDButton>
              </Grid> */}
              {/* <Grid item xs={12} sm={12} md={6} lg={4} xl={3} container justifyContent="flex-end">
                <MDButton
                  variant="contained"
                  onClick={() => {
                    sessionStorage.setItem("jsonData", JSON.stringify(jsonData));
                    navigate("/XMLHistoricos/Form", { state: { route } });
                  }}
                  sx={{
                    background: "linear-gradient(45deg, #1971C2 30%, #1971C2 90%)",
                    color: "#FFFFFF",
                  }}
                >
                  <Icon>add_circle_outline</Icon>&nbsp;Agregar
                </MDButton>
              </Grid> */}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox>
                <DataTable
                  entriesPerPage={(false, { defaultValue: 10, entries: [5, 10, 25, 50] })}
                  canSearch
                  showTotalEntries
                  table={{ columns, rows }}
                  pagination={{ variant: "contained", color: "warning" }}
                  isSorted
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {renderNotificacion}
      <Loading load={load} />
      <Footer />
    </DashboardLayout>
  );
}

export default XMLHistoricos;
