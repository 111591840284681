/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo, useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-table components
import { useTable, usePagination, useGlobalFilter, useSortBy } from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Autocomplete from "@mui/material/Autocomplete";
import Text from "@mui/material/InputLabel";
import IconButton from "@mui/material/IconButton";
import FileUploadIcon from "@mui/icons-material/FileUpload";

// Material Dashboard 2 PRO React examples
import DataTableHeadCell from "./DataTableHeadCell";
import DataTableBodyCell from "./DataTableBodyCell";

// Material Dashboard 2 PRO React components
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDInput from "../../../components/MDInput";
import MDPagination from "../../../components/MDPagination";

import SkeletonTable from "../../Skeleton/SkeletonTable";

function NewDataTable({
  entriesPerPage,
  // canSearch,
  checkbox,
  tableType,
  showTotalEntries,
  table,
  pagination,
  inputFile,
  downloadFile,
  isSorted,
  noEndBorder,
}) {
  const defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : 10;
  const entries = entriesPerPage.entries
    ? entriesPerPage.entries.map((el) => el.toString())
    : ["5", "10", "15", "20", "25"];
  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);
  const [selected, setSelected] = useState([]);

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = tableInstance;

  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

  // Set the entries per page value based on the select value
  const setEntriesPerPage = (value) => setPageSize(value);

  // Render the paginations
  const renderPagination = pageOptions.map((option) => (
    <MDPagination
      item
      key={option}
      onClick={() => gotoPage(Number(option))}
      active={pageIndex === option}
    >
      {option + 1}
    </MDPagination>
  ));

  // Handler for the input to set the pagination index
  const handleInputPagination = ({ target: { value } }) =>
    value > pageOptions.length || value < 0 ? gotoPage(0) : gotoPage(Number(value));

  // Customized page options starting from 1
  const customizedPageOptions = pageOptions.map((option) => option + 1);

  // Setting value for the pagination input
  const handleInputPaginationValue = ({ target: value }) => gotoPage(Number(value.value - 1));

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  // Setting the entries starting point
  const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

  // Setting the entries ending point
  let entriesEnd;

  if (pageIndex === 0) {
    entriesEnd = pageSize;
  } else if (pageIndex === pageOptions.length - 1) {
    entriesEnd = rows.length;
  } else {
    entriesEnd = pageSize * (pageIndex + 1);
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleFileUpload = (event, where) => {
    if (event.target.files) {
      const idsArray = [];
      selected.map((item) => idsArray.push(rows[item].original.id));
      const input = {
        file: event.target.files[0],
        where,
        idsArray,
      };
      inputFile(input);
    }
  };

  const handleDownload = () => {
    const idsArray = [];
    selected.map((item) => idsArray.push(rows[item].original.guid));
    downloadFile(idsArray);
  };

  return (
    <TableContainer sx={{ boxShadow: "none" }}>
      <Table {...getTableProps()}>
        <MDBox component="thead">
          {headerGroups.map((headerGroup) => {
            const isAllChecked = rows.length === selected.length;
            return (
              <TableRow style={{ verticalAlign: "initial" }} {...headerGroup.getHeaderGroupProps()}>
                {checkbox && (
                  <TableCell style={{ paddingRight: 0, width: 20 }}>
                    <Checkbox
                      color="primary"
                      checked={isAllChecked}
                      indeterminate={selected.length > 0 && selected.length < rows.length}
                      onChange={handleSelectAllClick}
                    />
                  </TableCell>
                )}
                {headerGroup.headers.map((column) => (
                  <DataTableHeadCell
                    {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                    width={column.width ? column.width : "auto"}
                    align="center"
                    sorted={setSortedValue(column)}
                  >
                    {column.render("Header")}
                  </DataTableHeadCell>
                ))}
              </TableRow>
            );
          })}
        </MDBox>
        {rows.length > 0 ? (
          <TableBody {...getTableBodyProps()}>
            {page.map((row, key) => {
              prepareRow(row);
              const isItemSelected = isSelected(row.id);
              const color = isSelected(row.id) ? "#FEEED2" : "white";
              return (
                <TableRow
                  style={{
                    backgroundColor: color,
                  }}
                  role="checkbox"
                  {...row.getRowProps()}
                >
                  {checkbox && (
                    <TableCell style={{ paddingRight: 0 }}>
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        onChange={(event) => handleClick(event, row.id)}
                      />
                    </TableCell>
                  )}

                  {row.cells.map((cell) => (
                    <DataTableBodyCell
                      noBorder={noEndBorder && rows.length - 1 === key}
                      align={cell.column.align ? cell.column.align : "left"}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </DataTableBodyCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        ) : (
          <TableRow>
            <TableCell
              colSpan={
                checkbox ? headerGroups[0].headers.length + 1 : headerGroups[0].headers.length
              }
            >
              <SkeletonTable />
            </TableCell>
          </TableRow>
        )}
      </Table>

      {selected.length === 0 && (
        <MDBox
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
          style={{ paddingTop: 15, paddingBottom: 19 }}
        >
          {showTotalEntries && (
            <MDBox mb={{ xs: 3, sm: 0 }}>
              <MDTypography variant="button" color="secondary" fontWeight="regular">
                Mostrando {entriesStart} a {entriesEnd} de {rows.length} elementos
              </MDTypography>
            </MDBox>
          )}
          {pageOptions.length > 1 && (
            <MDPagination
              variant={pagination.variant ? pagination.variant : "gradient"}
              color={pagination.color ? pagination.color : "info"}
            >
              {canPreviousPage && (
                <MDPagination item onClick={() => previousPage()}>
                  <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
                </MDPagination>
              )}
              {renderPagination.length > 6 ? (
                <MDBox width="5rem" mx={1}>
                  <MDInput
                    inputProps={{ type: "number", min: 1, max: customizedPageOptions.length }}
                    value={customizedPageOptions[pageIndex]}
                    onChange={(handleInputPagination, handleInputPaginationValue)}
                  />
                </MDBox>
              ) : (
                renderPagination
              )}
              {canNextPage && (
                <MDPagination item onClick={() => nextPage()}>
                  <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
                </MDPagination>
              )}
            </MDPagination>
          )}
          {entriesPerPage && (
            <MDBox display="flex" alignItems="center">
              <Autocomplete
                disableClearable
                value={pageSize.toString()}
                options={entries}
                onChange={(event, newValue) => {
                  setEntriesPerPage(parseInt(newValue, 10));
                }}
                size="small"
                sx={{ width: "5rem" }}
                renderInput={(params) => <MDInput {...params} />}
              />
              <MDTypography variant="caption" color="secondary">
                &nbsp;&nbsp;Elementos por pagina
              </MDTypography>
            </MDBox>
          )}
        </MDBox>
      )}

      {selected.length > 0 && (
        <MDBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{
            backgroundColor: "#fd7e14",
            paddingBottom: 19,
            paddingTop: 15,
          }}
        >
          <Grid
            style={{ alignItems: "center", display: "flex", marginRight: "1em", color: "white" }}
          >
            <p style={{ fontSize: "16px", fontWeight: "bold" }}>{selected.length} seleccionados</p>
            <br />
          </Grid>

          {tableType === "xPOS" && (
            <>
              <Grid style={{ marginRight: "1em" }}>
                <input
                  type="file"
                  accept=".xslt,.xsl"
                  id="file-inputToDte"
                  onChange={(event) => handleFileUpload(event, "inputToDte")}
                  hidden
                />
                <Text
                  htmlFor="file-inputToDte"
                  style={{
                    color: "white",
                    cursor: "pointer",
                    padding: 2,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <IconButton style={{ paddingLeft: 0, paddingRight: 2 }} disableRipple>
                    <FileUploadIcon
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontWeight: "bold",
                        color: "white",
                      }}
                      fontSize="small"
                    />
                  </IconButton>
                  input_to_dte
                </Text>
              </Grid>
              <Grid style={{ marginRight: "1em" }}>
                <input
                  type="file"
                  accept=".xslt,.xsl"
                  id="file-dteToFiscal"
                  onChange={(event) => handleFileUpload(event, "dteToFiscal")}
                  hidden
                />
                <Text
                  htmlFor="file-dteToFiscal"
                  style={{
                    color: "white",
                    cursor: "pointer",
                    padding: 2,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <IconButton style={{ paddingLeft: 0, paddingRight: 2 }} disableRipple>
                    <FileUploadIcon
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontWeight: "bold",
                        color: "white",
                      }}
                      fontSize="small"
                    />
                  </IconButton>
                  dte_to_fiscal
                </Text>
              </Grid>
              <Grid style={{ marginRight: "1em" }}>
                <input
                  type="file"
                  accept=".xslt,.xsl"
                  id="file-customResponse"
                  onChange={(event) => handleFileUpload(event, "customResponse")}
                  hidden
                />
                <Text
                  htmlFor="file-customResponse"
                  style={{
                    color: "white",
                    cursor: "pointer",
                    padding: 2,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <IconButton style={{ paddingLeft: 0, paddingRight: 2 }} disableRipple>
                    <FileUploadIcon
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontWeight: "bold",
                        color: "white",
                      }}
                      fontSize="small"
                    />
                  </IconButton>
                  custom_response
                </Text>
              </Grid>
              <Grid style={{ marginRight: "1em" }}>
                <input
                  type="file"
                  accept=".xslt,.xsl"
                  id="file-schematron"
                  onChange={(event) => handleFileUpload(event, "schematron")}
                  hidden
                />
                <Text
                  htmlFor="file-schematron"
                  style={{
                    color: "white",
                    cursor: "pointer",
                    padding: 2,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <IconButton style={{ paddingLeft: 0, paddingRight: 2 }} disableRipple>
                    <FileUploadIcon
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontWeight: "bold",
                        color: "white",
                      }}
                      fontSize="small"
                    />
                  </IconButton>
                  schematron
                </Text>
              </Grid>
            </>
          )}

          {tableType === "logs" && (
            <Grid
              display="flex"
              style={{ cursor: "pointer" }}
              justifyContent="center"
              alignItems="center"
              mr={1}
            >
              <Tooltip
                title="Descargar"
                disableInteractive
                onClick={(event) => handleDownload(event)}
              >
                <FileDownloadIcon
                  fontSize="20px"
                  style={{
                    fontSize: "20px",
                    cursor: "pointer",
                    marginRight: 5,
                    color: "white",
                    // border: "1px solid white",
                    // backgroundColor: "white",
                  }}
                />
              </Tooltip>
              <MDTypography fontSize="16px" variant="h6" color="white" noWrap>
                descargar
              </MDTypography>
            </Grid>
          )}
        </MDBox>
      )}
    </TableContainer>
  );
}

// Setting default values for the props of DataTable
NewDataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
  canSearch: false,
  showTotalEntries: true,
  checkbox: false,
  tableType: "",
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
  inputFile: null,
  downloadFile: null,
};

// Typechecking props for the DataTable
NewDataTable.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  checkbox: PropTypes.bool,
  tableType: PropTypes.string,
  showTotalEntries: PropTypes.bool,
  inputFile: PropTypes.func,
  downloadFile: PropTypes.func,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
};

export default NewDataTable;
