/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// @mui material components
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// import { v4 as uuidv4 } from "uuid";

// API Request
// import APIRequest from "api/request";
import APIRequestGeneric from "api/requestGeneric";

function SingleSingOnSBXForm() {
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  // const idFuncionalidad = 0;
  const host = process.env.REACT_APP_API_URL;
  const basicAuth = `${process.env.REACT_APP_API_ADMIN_SBX_USER}:${process.env.REACT_APP_API_ADMIN_SBX_PASSWORD}`;
  const basicAuthorization = `Basic ${btoa(basicAuth)}`;

  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  // const [jsonData, setJsonData] = useState([]);
  const { pathname } = useLocation();
  const {
    state: { route, element },
  } = useLocation();
  const navigate = useNavigate();

  const [newValueSingleSingOnSandbox, setNewValueSingleSingOnSandbox] = useState("");
  const [newValueAudienceRestrictionSandbox, setNewValueAudienceRestrictionSandbox] = useState("");

  const [elementIsNew, setElementIsNew] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [newValueId, setNewValueId] = useState("");
  const [newValueNombreOrga, setNewValueNombreOrga] = useState("");
  const [newValueDominios, setNewValueDominios] = useState("");
  const [newValueAmbiente, setNewValueAmbiente] = useState(null);
  const [newValueSaml2EntityId, setNewValueSaml2EntityId] = useState("");
  const [newValueSaml2MetadataUrl, setNewValueSaml2MetadataUrl] = useState("");
  const [newValueSaml2SingleSignOnServiceUrl, setNewValueSaml2SingleSignOnServiceUrl] =
    useState("");
  // eslint-disable-next-line no-unused-vars
  const [selectData, setSelectData] = useState({
    estado: [
      { id: 1, nombre: "Positivo" },
      { id: 2, nombre: "Negativo" },
    ],
    ambiente: [{ id: 1, nombre: "Sandbox" }],
  });

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };
  const showNotificationError = (message) => {
    configNotification("error", "warning", "Notificación", message, "", true);
  };
  const showNotificationSuccess = (message) => {
    configNotification("warning", "check", "Notificación", message, "", true);
  };

  const closeModal = () => {
    setNewValueId("");
    setNewValueNombreOrga("");
    setNewValueDominios("");
    setNewValueAmbiente(null);
    setNewValueSaml2EntityId("");
    setNewValueSaml2MetadataUrl("");
    setNewValueSaml2SingleSignOnServiceUrl("");
  };
  const saveDataModal = async () => {
    const body = {
      Domains: newValueDominios,
      RowKey: newValueNombreOrga,
      PartitionKey: "sso",
      Active: true,
      Ambiente: newValueAmbiente.id, // 1: Sandbox, 2: Producción
      Saml2EntityId: newValueSaml2EntityId,
      Saml2MetadataUri: newValueSaml2MetadataUrl,
      Saml2SingleSignOnServiceUrl: newValueSaml2SingleSignOnServiceUrl,
      UsuarioUltimaAccion: userInfo.correo,
      // Module: null,
      ReturnUrl: newValueSingleSingOnSandbox,
      ServerBaseUrl: newValueAudienceRestrictionSandbox,
    };

    const urlComplete = `${host}/api/ManagerSSO/UpdateInformationSSOByCompany`;
    const httpVerb = "PUT";

    const apiResponse = await (
      await APIRequestGeneric(httpVerb, urlComplete, body, false, basicAuthorization)
    ).json();

    if (apiResponse.Code === 200 && apiResponse.IsSuccessful) {
      /* const tempJsonData = jsonData;
      const newTempElement = {
        id: elementIsNew ? uuidv4() : newValueId,
        nombreOrga: newValueNombreOrga,
        dominios: newValueDominios,
        ambiente: newValueAmbiente,
        saml2EntityId: newValueSaml2EntityId,
        saml2MetadataUrl: newValueSaml2MetadataUrl,
        saml2SingleSignOnServiceUrl: newValueSaml2SingleSignOnServiceUrl,
        usuario: userInfo.correo,
      };

      if (elementIsNew) {
        tempJsonData.push(newTempElement);
      } else {
        const index = tempJsonData.map((item) => item.id).indexOf(newValueId);
        tempJsonData[index] = newTempElement;
      } */

      showNotificationSuccess("Se completó el registro exitosamente");
      // setJsonData(tempJsonData);
      closeModal();
    } else {
      showNotificationError(`Error: ${apiResponse.Message}`);
    }
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );

  const setEditJsonData = (jsonElement) => {
    if (element) {
      setNewValueId(jsonElement.id);
      setNewValueNombreOrga(jsonElement.nombreOrga);
      setNewValueDominios(jsonElement.dominios);
      setNewValueAmbiente(jsonElement.ambiente);
      setNewValueSaml2EntityId(jsonElement.saml2EntityId);
      setNewValueSaml2MetadataUrl(jsonElement.saml2MetadataUrl);
      setNewValueSaml2SingleSignOnServiceUrl(jsonElement.saml2SingleSignOnServiceUrl);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    saveDataModal();
  };

  useEffect(() => {
    setNewValueSingleSingOnSandbox("https://auth-sbx.gosocket.net/core/Saml2/Acs");
    setNewValueAudienceRestrictionSandbox("https://auth-sbx.gosocket.net");

    setElementIsNew(!element);
    setEditJsonData(element);
  }, [pathname]);

  return (
    <DashboardLayout>
      <DashboardNavbar breadCrumb={route.split("/").slice(1)} />
      <MDBox pt={3} pb={3} component="form" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MDBox pb={4}>
              <MDTypography variant="subtitle" fontWeight="bold">
                <Icon
                  sx={{ cursor: "pointer", fontWeight: "bold" }}
                  onClick={() => navigate(route)}
                >
                  arrow_back
                </Icon>
                {"   "}
                Permite habilitar Single Sing On para un TaxID
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox sx={{ position: "relative", mx: 3, py: 2, px: 2 }}>
                <Grid container spacing={3} justifyContent="left" alignItems="left" height="100%">
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <MDInput
                      variant="standard"
                      type="text"
                      label="Nombre Organización"
                      fullWidth
                      required
                      disabled={!elementIsNew}
                      InputLabelProps={{ shrink: true }}
                      value={newValueNombreOrga}
                      onChange={(e) => {
                        setNewValueNombreOrga(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <MDInput
                      variant="standard"
                      type="text"
                      label="Dominios"
                      fullWidth
                      required
                      InputLabelProps={{ shrink: true }}
                      value={newValueDominios}
                      onChange={(e) => {
                        setNewValueDominios(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <Autocomplete
                      value={newValueAmbiente}
                      onChange={(event, newValue) => {
                        setNewValueAmbiente(newValue);
                      }}
                      options={selectData.ambiente}
                      getOptionLabel={(option) => option.nombre}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderInput={(params) => (
                        <MDInput
                          variant="standard"
                          type="text"
                          label="Ambiente"
                          fullWidth
                          required
                          InputLabelProps={{ shrink: true }}
                          {...params}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <MDInput
                      variant="standard"
                      type="text"
                      label="Saml2 Entity Id"
                      fullWidth
                      required
                      InputLabelProps={{ shrink: true }}
                      value={newValueSaml2EntityId}
                      onChange={(e) => {
                        setNewValueSaml2EntityId(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <MDInput
                      variant="standard"
                      type="text"
                      label="Saml2 Metadata Url"
                      fullWidth
                      required
                      InputLabelProps={{ shrink: true }}
                      value={newValueSaml2MetadataUrl}
                      onChange={(e) => {
                        setNewValueSaml2MetadataUrl(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <MDInput
                      variant="standard"
                      type="text"
                      label="Saml2 Single Sign On Service Url"
                      fullWidth
                      required
                      InputLabelProps={{ shrink: true }}
                      value={newValueSaml2SingleSignOnServiceUrl}
                      onChange={(e) => {
                        setNewValueSaml2SingleSignOnServiceUrl(e.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="left" alignItems="left" height="100%">
              <Grid item xs={12} sm={12} md={6} lg={4} xl={10} />
              <Grid item xs={12} sm={12} md={6} lg={4} xl={2} container justifyContent="flex-end">
                <MDButton
                  variant="contained"
                  type="submit"
                  sx={{
                    background: "linear-gradient(45deg, #099268 30%, #099268 90%)",
                    color: "#FFFFFF",
                  }}
                >
                  <Icon>save</Icon>&nbsp;Guardar
                </MDButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
      {renderNotificacion}
      <Footer />
    </DashboardLayout>
  );
}

export default SingleSingOnSBXForm;
