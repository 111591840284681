/* eslint-disable jsx-a11y/label-has-associated-control */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// @mui material components
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import BKModal from "components/BKModal";
import BKShowModal from "components/BKShowModal";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Loading from "components/Loading";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import data from "views/producto/PlantillasJasperCrystalSBX/data";
import base64ToArrayBuffer from "util/base64ToArrayBuffer";

// API Request
import APIRequest from "api/request";
import APIRequestGeneric from "api/requestGeneric";

function PlantillasJasperCrystalSBX() {
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  const host = process.env.REACT_APP_API_URL;
  const basicAuth = `${process.env.REACT_APP_API_ADMIN_SBX_USER}:${process.env.REACT_APP_API_ADMIN_SBX_PASSWORD}`;
  const basicAuthorization = `Basic ${btoa(basicAuth)}`;

  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  const [load, setLoad] = useState(false);
  const [modalComment, setModalComment] = useState(false);
  const [modalUpload, setModalUpload] = useState(false);
  const [jsonData, setJsonData] = useState([]);
  const { pathname } = useLocation();
  const { state } = useLocation();

  const [elementToUpload, setElementToUpload] = useState(null);
  const [newValueTaxId, setNewValueTaxId] = useState("");
  const [newValuePais, setNewValuePais] = useState(null);
  const [newValueComment, setNewValueComment] = useState("");
  const [newValueXSLT, setNewValueXSLT] = useState("");
  const [newValueFiles, setNewValueFiles] = useState([]);
  const [newValueFileBase64, setNewValueFileBase64] = useState("");

  const [selectData, setSelectData] = useState({
    pais: [
      { id: "cl", nombre: "Chile" },
      { id: "gt", nombre: "Guatemala" },
    ],
  });

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };
  const showNotificationError = (message) => {
    configNotification("error", "warning", "Notificación", message, "", true);
  };
  const showNotificationSuccess = (message) => {
    configNotification("warning", "check", "Notificación", message, "", true);
  };

  const closeModalComment = () => {
    setModalComment(false);
    setNewValueComment("");
    setLoad(false);
  };
  const closeModalUpload = () => {
    setModalUpload(false);
    setElementToUpload(null);
    setNewValueXSLT("");
    setNewValueFiles([]);
    setNewValueFileBase64("");
    setLoad(false);
  };

  const lector = new FileReader();
  lector.onload = async (file) => {
    if (file) {
      setNewValueFileBase64(
        file.target.result
          .toString()
          .replace("data:application/xml;base64,", "")
          .replace("data:text/xml;base64,", "")
          .replace("data:application/x-zip-compressed;base64,", "")
          .replace("data:application/octet-stream;base64,", "")
      );
    } else {
      console.log("No se leyó ningun archivo");
    }
  };

  const saveCompareResult = async (element) => {
    const bodyPostgre = {
      TemplateId: element.templateId,
      TaxId: element.taxId,
      Pais: element.country.id,
      Aprobado: element.approved,
      Comentario: element.comment,
      UsuarioId: userInfo.id,
    };
    // eslint-disable-next-line no-await-in-loop
    const apiResponsePostgre = await APIRequest("POST", "/api/Plantillas", bodyPostgre);

    if (apiResponsePostgre.status === 200) {
      return false;
    }

    const errorMessage = {
      code: apiResponsePostgre.status,
      message: `Error al guardar el resultado de comparación: ${apiResponsePostgre.status}`,
    };
    throw errorMessage;
  };
  // eslint-disable-next-line no-unused-vars
  const saveDataModal = async (event) => {
    event.preventDefault();

    try {
      for (let index = 0; index < jsonData.length; index += 1) {
        if (jsonData[index].modified) {
          let newTemplatePath = "";

          // if (jsonData[index].modifiedType === "enable") {
          if (jsonData[index].approved) {
            if (jsonData[index].templatePath.includes("_preview")) {
              newTemplatePath = jsonData[index].templatePath;
            } else {
              const initialExtension = `.${
                jsonData[index].templatePath.split(".")[
                  jsonData[index].templatePath.split(".").length - 1
                ]
              }`;
              const newExtension = "_preview.jrxml";
              newTemplatePath = jsonData[index].templatePath.replace(
                initialExtension,
                newExtension
              ); // Agregar el preview
            }
          } else {
            // eslint-disable-next-line no-lonely-if
            if (jsonData[index].templatePath.includes("_preview")) {
              newTemplatePath = jsonData[index].templatePath.replace("_preview", ""); // Quitar
            } else {
              newTemplatePath = jsonData[index].templatePath;
            }
          }

          if (jsonData[index].approved) {
            // if (jsonData[index].approved || jsonData[index].modifiedType !== "") { // Para activar / desactivar (funcionalidad eliminada)
            const body = {
              CountryId: newValuePais.id,
              TaxId: newValueTaxId,
              CheckPreview: jsonData[index].approved,
              TemplatePath: newTemplatePath,
            };
            const urlComplete = `${host}/api/ManagerTemplate/RenameTemplatePreview`;
            // eslint-disable-next-line no-await-in-loop
            const apiResponse = await // eslint-disable-next-line no-await-in-loop
            (await APIRequestGeneric("POST", urlComplete, body, false, basicAuthorization)).json();

            if (apiResponse.Code === 200 && apiResponse.IsSuccessful) {
              // eslint-disable-next-line no-await-in-loop
              jsonData[index].modified = await saveCompareResult(jsonData[index]);
              // jsonData[index].modifiedType = "";
            } else {
              const errorMessage = {
                code: apiResponse.Message,
                message: `Error al aprobar una plantilla: ${apiResponse.Message}`,
              };
              throw errorMessage;
            }
          }

          if (jsonData[index].approved === false) {
            // eslint-disable-next-line no-await-in-loop
            jsonData[index].modified = await saveCompareResult(jsonData[index]);
            /* const bodyPostgre = {
              TemplateId: jsonData[index].templateId,
              TaxId: jsonData[index].taxId,
              Pais: jsonData[index].country.id,
              Aprobado: jsonData[index].approved,
              Comentario: jsonData[index].comment,
              UsuarioId: userInfo.id,
            };
            // eslint-disable-next-line no-await-in-loop
            const apiResponsePostgre = await APIRequest("POST", "/api/Plantillas", bodyPostgre);

            if (apiResponsePostgre.status === 200) {
              jsonData[index].modified = false;
            } else {
              const errorMessage = {
                code: apiResponsePostgre.status,
                message: `Error al guardar el resultado de comparación: ${apiResponsePostgre.status}`,
              };
              throw errorMessage;
            } */
          }
        }
      }

      showNotificationSuccess("Datos guardados exitosamente");
    } catch (error) {
      showNotificationError(`Error inesperado: ${error.message}`);
    }
  };

  const saveDataModalUpload = async () => {
    if (newValueFiles.length > 0) {
      setLoad(true);
      let newTemplatePath;
      if (elementToUpload.templatePath.includes("_preview")) {
        newTemplatePath = elementToUpload.templatePath;
      } else {
        const initialExtension = `.${
          elementToUpload.templatePath.split(".")[
            elementToUpload.templatePath.split(".").length - 1
          ]
        }`;
        const newExtension = "_preview.jrxml";
        newTemplatePath = elementToUpload.templatePath.replace(initialExtension, newExtension); // Agregar el preview
      }

      const body = {
        CountryId: elementToUpload.country.id,
        TaxId: elementToUpload.taxId,
        TemplatePath: newTemplatePath,
        CheckPreview: true,
        FileBase64String: newValueFileBase64,
      };
      const urlComplete = `${host}/api/ManagerTemplate/UploadTemplate`;

      try {
        const apiResponse = await (
          await APIRequestGeneric("POST", urlComplete, body, false, basicAuthorization)
        ).json();

        if (apiResponse.Code === 200 && apiResponse.IsSuccessful) {
          showNotificationSuccess("Se actualizó la plantilla exitosamente");
          closeModalUpload();
        } else {
          showNotificationError(`Error: ${apiResponse.Data}`);
        }
      } catch {
        showNotificationError("Ocurrió un error inesperado.");
      }
      setLoad(false);
    } else {
      showNotificationError("Primero debe cargar la plantilla.");
    }
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );

  const renderModalComment = (
    <BKShowModal closeModal={closeModalComment} open={modalComment} title="Comentario">
      <MDBox sx={{ position: "relative", mx: 3, py: 2, px: 2 }}>
        <Grid container spacing={3} justifyContent="left" alignItems="left" height="100%">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <MDInput
              // variant="standard"
              type="text"
              // label="Comentario"
              fullWidth
              // required
              multiline
              rows={5}
              // disabled
              InputLabelProps={{ shrink: true }}
              value={newValueComment}
              /* onChange={(e) => {
                setNewValueComment(e.target.value);
              }} */
            />
          </Grid>
        </Grid>
      </MDBox>
    </BKShowModal>
  );
  const renderModalUpload = (
    <BKModal
      closeModal={closeModalUpload}
      saveChanges={saveDataModalUpload}
      open={modalUpload}
      title="Cargar Plantilla"
    >
      <MDBox sx={{ position: "relative", mx: 3, py: 2, px: 2 }}>
        <Grid container spacing={3} justifyContent="left" alignItems="left" height="100%">
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <MDInput
              id="file-input"
              variant="standard"
              type="file"
              label="Seleccionar Archivo"
              sx={{ display: "none" }}
              fullWidth
              InputLabelProps={{ shrink: true }}
              // inputProps={{ accept: ".xslt" }}
              onChange={async (e) => {
                setNewValueFiles(e.target.files);
                const tempValueName = e.target.files[0] ? e.target.files[0].name : "";
                setNewValueXSLT(tempValueName.length > 0 ? tempValueName : newValueXSLT);
                // await lector.readAsText(e.target.files[0]);
                await lector.readAsDataURL(e.target.files[0]);
              }}
            />
            <MDButton
              variant="outlined"
              autoFocus
              color="warning"
              onClick={() => document.getElementById("labelFile").click()}
            >
              <label id="labelFile" htmlFor="file-input" style={{ display: "none" }} />
              Adjuntar Archivo <Icon>upload</Icon>
            </MDButton>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <MDInput
              variant="standard"
              type="text"
              label="Plantilla"
              fullWidth
              required
              disabled
              InputLabelProps={{ shrink: true }}
              value={newValueXSLT}
              onChange={(e) => {
                setNewValueXSLT(e.target.value);
              }}
            />
          </Grid>
        </Grid>
      </MDBox>
    </BKModal>
  );

  const getJsonData = async () => {
    /* const tempJsonData = [
      {
        partitionKey: "123|co",
        taxId: "123",
        docType: "1",
        templateId: "15",
        templateType: "Jasper",
        country:
          selectData.pais[
            selectData.pais.map((item) => item.id).indexOf("co") // (apiResponse[index].pais.toLowerCase())
          ],
        user: userInfo.correo,
        date: "2023-22/11",
        modified: false,
      },
      {
        partitionKey: "321|gt",
        taxId: "321",
        docType: "1",
        templateId: "17",
        templateType: "Crystal",
        country:
          selectData.pais[
            selectData.pais.map((item) => item.id).indexOf("gt") // (apiResponse[index].pais.toLowerCase())
          ],
        user: userInfo.correo,
        date: "2023-22/11",
        modified: false,
      },
    ];

    setJsonData([...tempJsonData]); */

    setLoad(true);
    try {
      const body = {
        CountryId: newValuePais.id,
        TaxId: newValueTaxId,
        TemplatePath: "",
        User: userInfo.correo,
      };
      const urlComplete = `${host}/api/ManagerTemplate/GetTemplateByTaxID`;
      const apiResponse = await (
        await APIRequestGeneric("POST", urlComplete, body, false, basicAuthorization)
      ).json();

      if (apiResponse.Code === 200 && apiResponse.IsSuccessful) {
        const tempJsonData = [];
        // const dataResponse = JSON.parse(apiResponse.Data);
        const dataResponse = apiResponse.Data;
        // console.log("dataResponse", dataResponse);

        const apiResponsePostgre = await (
          await APIRequest("GET", "/api/Plantillas/Todos", null)
        ).json();
        // console.log("apiResponsePostgre", apiResponsePostgre);

        const colleccionResult = new Map();
        for (let index = apiResponsePostgre.length - 1; index >= 0; index -= 1) {
          if (
            apiResponsePostgre[index].pais === newValuePais.id &&
            apiResponsePostgre[index].taxId === newValueTaxId
          ) {
            colleccionResult.set(apiResponsePostgre[index].templateId, {
              aprobado: apiResponsePostgre[index].aprobado,
              comentario: apiResponsePostgre[index].comentario,
            });
          }
        }

        for (let index = 0; index < dataResponse.length; index += 1) {
          if (
            dataResponse[index].IsActive &&
            dataResponse[index].ClasificationTemplate !== "Genérica"
          ) {
            // console.log("dataResponse[index]", dataResponse[index]);
            /* console.log(
              "JSON.parse(dataResponse[index].PdfSerializeObjectrequest)",
              JSON.parse(dataResponse[index].PdfSerializeObjectrequest)
            ); */
            const collectionElement = colleccionResult.get(dataResponse[index].TemplateId);

            tempJsonData.push({
              partitionKey: `${dataResponse[index].TaxId}|${dataResponse[index].Country}`,
              taxId: dataResponse[index].TaxId,
              docType: dataResponse[index].DocumentTypeId,
              templateId: dataResponse[index].TemplateId,
              templateType: dataResponse[index].Jasper ? "Jasper" : "Crystal",
              templatePreview: dataResponse[index].TemplatePreview,
              templatePath: dataResponse[index].TemplatePath,
              clasificationTemplate: dataResponse[index].ClasificationTemplate,
              country:
                selectData.pais[
                  selectData.pais
                    .map((item) => item.id)
                    .indexOf(dataResponse[index].Country.toLowerCase())
                ],
              documentId: dataResponse[index].DocumentId,
              senderCode: dataResponse[index].SenderCode,
              receiverCode: dataResponse[index].ReceiverCode,
              pdfSerializeObjectrequest: dataResponse[index].PdfSerializeObjectrequest,
              listTemplateSubReportsJasper: dataResponse[index].ListTemplateSubReportsJasper,
              crystal: dataResponse[index].Crystal,
              jasper: dataResponse[index].Jasper,
              user: dataResponse[index].UserLastAction,
              date: dataResponse[index].DateLastAction,
              approved: collectionElement ? collectionElement.aprobado : null,
              comment: collectionElement ? collectionElement.comentario : "",
              // modifiedType: "", // enable / disable
              modified: false,
            });
          }
        }

        // console.log("tempJsonData", tempJsonData);
        setJsonData([...tempJsonData]);
      } else {
        showNotificationError(apiResponse.Message);
      }
    } catch {
      // console.log(excepcion);
    }

    setLoad(false);
  };

  const getCountriesData = async () => {
    const tempJsonData = [];
    const apiResponse = await (await APIRequest("GET", "/api/Pais/Todos", null)).json();

    for (let index = 0; index < apiResponse.length; index += 1) {
      tempJsonData.push({
        id: apiResponse[index].codigo.toLowerCase(),
        nombre: apiResponse[index].nombre,
      });
    }

    setSelectData({
      pais: tempJsonData,
      autenticacion: selectData.autenticacion,
    });

    return tempJsonData;
  };

  const getElementChanges = async () => {
    if (sessionStorage.getItem("jsonDataPlantillasSBX")) {
      const jsonDataPlantillasSBX = JSON.parse(sessionStorage.getItem("jsonDataPlantillasSBX"));
      sessionStorage.setItem("jsonDataPlantillasSBX", null);
      // console.log("jsonDataPlantillasSBX", jsonDataPlantillasSBX);

      if (state) {
        if (state.elementChanges) {
          // console.log("state.elementChanges", state.elementChanges);

          const index = jsonDataPlantillasSBX
            .map((item) => item.templateId)
            .indexOf(state.elementChanges.templateId);

          jsonDataPlantillasSBX[index] = state.elementChanges;
        }
      }

      if (jsonDataPlantillasSBX) {
        setJsonData([...jsonDataPlantillasSBX]);
      }

      // console.log("jsonData", jsonData);
    }
  };

  const viewModalComment = (element) => {
    setNewValueComment(element.comment);
    setModalComment(true);
  };
  const viewUploadModal = (element) => {
    setElementToUpload(element);
    setModalUpload(true);
  };

  /* const setEnable = (valueId) => {
    const tempJsonData = [...jsonData];

    const index = jsonData.map((item) => item.templateId).indexOf(valueId);
    tempJsonData[index].modifiedType = "enable";
    tempJsonData[index].modified = true;

    setJsonData([...tempJsonData]);
  }; */

  /* const setDisable = (valueId) => {
    const tempJsonData = [...jsonData];

    const index = jsonData.map((item) => item.templateId).indexOf(valueId);
    tempJsonData[index].modifiedType = "disable";
    tempJsonData[index].modified = true;

    setJsonData([...tempJsonData]);
  }; */

  /* const base64ToArrayBuffer = (base64) => {
    const binaryString = window.atob(base64);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i += 1) {
      const ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }; */

  const downloadTemplate = async (element) => {
    setLoad(true);
    let newTemplatePath;
    if (element.templatePath.includes("_preview")) {
      newTemplatePath = element.templatePath;
    } else {
      const initialExtension = `.${
        element.templatePath.split(".")[element.templatePath.split(".").length - 1]
      }`;
      const newExtension = "_preview.jrxml";
      newTemplatePath = element.templatePath.replace(initialExtension, newExtension); // Agregar el preview
    }

    const body = {
      CountryId: element.country.id,
      TaxId: element.taxId,
      TemplatePath: newTemplatePath,
      CheckPreview: true,
      // FileBase64String: "string",
    };

    const urlComplete = `${host}/api/ManagerTemplate/DownloadTemplate`;
    const apiResponse = await (
      await APIRequestGeneric("POST", urlComplete, body, false, basicAuthorization)
    ).json();

    if (apiResponse.Code === 200 && apiResponse.IsSuccessful) {
      const nameFile = apiResponse.Data.NameFile;
      // const fileBase64 = apiResponse.Data.FileBase64;
      const arrBuffer = base64ToArrayBuffer(apiResponse.Data.FileBase64);

      const blob = new Blob([arrBuffer], {
        type: `application/${nameFile.split(".")[nameFile.split(".").length - 1]}`,
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = nameFile;
      // document.body.appendChild(a);
      a.click();
    } else {
      showNotificationError(`Error: ${apiResponse.Message}`);
    }
    setLoad(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    getJsonData();
  };

  useEffect(async () => {
    await getCountriesData();
    await getElementChanges();
  }, [pathname]);

  const { columns, rows } = data(jsonData, viewUploadModal, downloadTemplate, viewModalComment);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {renderModalComment}
      {renderModalUpload}
      <MDBox pt={3} pb={3} component="form" onSubmit={handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <MDTypography variant="subtitle" fontWeight="bold">
              Se permite buscar por TaxID y seleccionar entre plantillas Jasper y Crystal
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3} justifyContent="left" alignItems="left" height="100%">
              <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                <Autocomplete
                  value={newValuePais}
                  onChange={(event, newValue) => {
                    setNewValuePais(newValue);
                  }}
                  options={selectData.pais}
                  getOptionLabel={(option) => option.nombre}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  renderInput={(params) => (
                    <MDInput
                      variant="standard"
                      type="text"
                      label="Pais"
                      fullWidth
                      required
                      InputLabelProps={{ shrink: true }}
                      {...params}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                <MDInput
                  variant="standard"
                  type="text"
                  label="Buscar por TaxID"
                  fullWidth
                  required
                  // InputLabelProps={{ shrink: true }}
                  value={newValueTaxId}
                  onChange={(e) => {
                    setNewValueTaxId(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                <MDButton
                  variant="contained"
                  type="submit"
                  // onClick={getJsonData}
                  // color="info"
                  sx={{
                    // backgroundColor: "#1971C2",
                    background: "linear-gradient(45deg, #1971C2 30%, #1971C2 90%)",
                    color: "#FFFFFF",
                  }}
                >
                  <Icon>search</Icon>&nbsp;Buscar
                </MDButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox>
                <DataTable
                  entriesPerPage={
                    (false, { defaultValue: 10, entries: [5, 10, 25, 50], show: false })
                  }
                  hideEntriesPerPage
                  // canSearch
                  showTotalEntries // ={false}
                  table={{ columns, rows }}
                  pagination={{ variant: "contained", color: "warning" }}
                  isSorted // ={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
          {/* <Grid item xs={12}>
            <Grid container justifyContent="left" alignItems="left" height="100%">
              <Grid item xs={12} sm={12} md={6} lg={8} xl={10} />
              <Grid item xs={12} sm={12} md={6} lg={4} xl={2} container justifyContent="flex-end">
                <MDButton
                  variant="contained"
                  autoFocus
                  onClick={saveDataModal}
                  sx={{
                    background: "linear-gradient(45deg, #099268 30%, #099268 90%)",
                    color: "#FFFFFF",
                  }}
                >
                  <Icon>save</Icon>&nbsp;Guardar
                </MDButton>
              </Grid>
            </Grid>
          </Grid> */}
        </Grid>
      </MDBox>
      {renderNotificacion}
      <Loading load={load} />
      <Footer />
    </DashboardLayout>
  );
}

export default PlantillasJasperCrystalSBX;
