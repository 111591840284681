/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// @mui material components
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Box from "@mui/material/Box";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Loading from "components/Loading";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// API Request
import APIRequest from "api/request";

function ShortcutForm() {
  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  const [load, setLoad] = useState(false);
  const { pathname } = useLocation();
  const {
    state: { route, element },
  } = useLocation();
  const navigate = useNavigate();

  const [elementIsNew, setElementIsNew] = useState(true);
  const [newValuePais, setNewValuePais] = useState(null);
  const [newValueApplicationId, setNewValueApplicationId] = useState(null);
  const [newValueUserId, setNewValueUserId] = useState("");
  const [newValueAccountId, setNewValueAccountId] = useState("");
  const [newValueOrder, setNewValueOrder] = useState("");

  const [selectData, setSelectData] = useState({
    pais: [
      { id: "cr", nombre: "Costa rica" },
      { id: "ec", nombre: "Ecuador" },
      { id: "pe", nombre: "Perú" },
      { id: "cl", nombre: "Chile" },
      { id: "mx", nombre: "México" },
      { id: "gt", nombre: "Guatemala" },
      { id: "co", nombre: "Colombia" },
      { id: "pa", nombre: "Panamá" },
    ],
    application: [],
    account: [],
    user: [],
  });

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };
  const showNotificationError = (message) => {
    configNotification("error", "warning", "Notificación", message, "", true);
  };
  const showNotificationSuccess = (message) => {
    configNotification("warning", "check", "Notificación", message, "", true);
  };

  const saveData = async () => {
    setLoad(true);
    try {
      const HttpVerb = elementIsNew ? "POST" : "PATCH";
      const HttpUrl = elementIsNew
        ? "/api/Manager/UserGadget"
        : `/api/Manager/UserGadget?ShortcutId=${newValueApplicationId}`;

      const body = {
        ApplicationId: newValueApplicationId.id,
        EmailUser: newValueUserId,
        AccountCode: newValueAccountId,
        Order: newValueOrder,
        CountryId: newValuePais.id,
      };

      const apiResponse = await APIRequest(HttpVerb, HttpUrl, body);

      if (apiResponse.status === 200 || apiResponse.status === 201 || apiResponse.ok) {
        showNotificationSuccess("Se guardó el shortcut exitosamente");

        const tempElement = {
          applicationId: newValueApplicationId,
          userId: newValueUserId,
          accountId: newValueAccountId,
          order: newValueOrder,
          country: newValuePais.id,
        };

        navigate(route, { state: { newElement: tempElement } });
      } else {
        showNotificationError(`Error, estado: ${apiResponse.status}`);
      }
    } catch {
      showNotificationError("Error Inesperado");
    }

    setLoad(false);
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );

  const setEditJsonData = (jsonElement) => {
    if (element) {
      setNewValueApplicationId(jsonElement.applicationId);
      setNewValueUserId(jsonElement.userId);
      setNewValueAccountId(jsonElement.accountId);
      setNewValueOrder(jsonElement.order);
      // setNewValuePais(jsonElement.country);
    }
  };

  const getCountriesData = async () => {
    const tempJsonData = [];
    const apiResponse = await (await APIRequest("GET", "/api/Pais/Todos", null)).json();

    if (apiResponse.length > 0) {
      for (let index = 0; index < apiResponse.length; index += 1) {
        tempJsonData.push({
          id: apiResponse[index].codigo.toLowerCase(),
          nombre: apiResponse[index].nombre,
        });
      }

      setSelectData({
        pais: tempJsonData,
        application: selectData.application,
        account: selectData.account,
        user: selectData.user,
      });
    }

    return tempJsonData;
  };
  const getApplicationsByCountry = async (country) => {
    const tempJsonData = [];
    if (country) {
      const apiResponse = await (await APIRequest("GET", "/api/Manager/Gadget/Todos", null)).json();

      if (apiResponse.length > 0) {
        for (let index = 0; index < apiResponse.length; index += 1) {
          if (apiResponse[index].countryId === country.id) {
            tempJsonData.push({
              id: apiResponse[index].applicationId,
              nombre: apiResponse[index].name,
            });
          }
        }

        setSelectData({
          pais: selectData.pais,
          application: tempJsonData,
          account: selectData.account,
          user: selectData.user,
        });
      }
    }

    return tempJsonData;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    saveData();
  };

  useEffect(async () => {
    await getCountriesData();
    setElementIsNew(!element);
    setEditJsonData(element);
  }, [pathname]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={3}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <MDBox>
              <MDTypography variant="subtitle" fontWeight="bold">
                <Icon
                  sx={{ cursor: "pointer", fontWeight: "bold" }}
                  onClick={() => navigate(route)}
                >
                  arrow_back
                </Icon>
                {"   "}
                Manager - Shortcut
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <Box sx={{ width: "100%" }}>
                <Card>
                  <MDBox
                    sx={{ position: "relative", mx: 3, py: 2, px: 2 }}
                    component="form"
                    onSubmit={handleSubmit}
                  >
                    <Grid
                      container
                      spacing={3}
                      justifyContent="left"
                      alignItems="left"
                      height="100%"
                    >
                      <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                        <Autocomplete
                          value={newValuePais}
                          onChange={(event, newValue) => {
                            setNewValuePais(newValue);
                            getApplicationsByCountry(newValue);
                          }}
                          options={selectData.pais}
                          getOptionLabel={(option) => option.nombre}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          // disabled={!elementIsNew}
                          renderInput={(params) => (
                            <MDInput
                              variant="standard"
                              type="text"
                              label="Pais"
                              fullWidth
                              required
                              InputLabelProps={{ shrink: true }}
                              {...params}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                        <Autocomplete
                          value={newValueApplicationId}
                          onChange={(event, newValue) => {
                            setNewValueApplicationId(newValue);
                          }}
                          options={selectData.application}
                          getOptionLabel={(option) => option.nombre}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          renderInput={(params) => (
                            <MDInput
                              variant="standard"
                              type="text"
                              label="Gadget"
                              fullWidth
                              required
                              InputLabelProps={{ shrink: true }}
                              {...params}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                        <MDInput
                          variant="standard"
                          type="text"
                          label="Email Usuario"
                          fullWidth
                          // required
                          // disabled={!elementIsNew}
                          InputLabelProps={{ shrink: true }}
                          value={newValueUserId}
                          onChange={(e) => {
                            setNewValueUserId(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                        <MDInput
                          variant="standard"
                          type="text"
                          label="Account Code"
                          fullWidth
                          // required
                          // disabled={!elementIsNew}
                          InputLabelProps={{ shrink: true }}
                          value={newValueAccountId}
                          onChange={(e) => {
                            setNewValueAccountId(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                        <MDInput
                          variant="standard"
                          type="text"
                          label="Order"
                          fullWidth
                          required
                          // disabled={!elementIsNew}
                          inputProps={{ maxLength: 5 }}
                          InputLabelProps={{ shrink: true }}
                          value={newValueOrder}
                          onChange={(e) => {
                            let newValueNumber = "";
                            for (let i = 0; i < e.target.value.length; i += 1) {
                              if (
                                e.target.value.charCodeAt(i) >= 48 &&
                                e.target.value.charCodeAt(i) <= 57
                              ) {
                                newValueNumber += e.target.value.charAt(i);
                              }
                            }
                            setNewValueOrder(newValueNumber);
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={4}
                        lg={5}
                        xl={2}
                        container
                        justifyContent="flex-end"
                      >
                        <MDButton
                          variant="contained"
                          type="submit"
                          sx={{
                            background: "linear-gradient(45deg, #099268 30%, #099268 90%)",
                            color: "#FFFFFF",
                          }}
                        >
                          <Icon>save</Icon>&nbsp;Guardar Shortcut
                        </MDButton>
                      </Grid>
                    </Grid>
                  </MDBox>
                </Card>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {renderNotificacion}
      <Loading load={load} />
      <Footer />
    </DashboardLayout>
  );
}

export default ShortcutForm;
