/* eslint-disable no-undef */
/* eslint-disable no-loop-func */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// Default Components
import MDTypography from "components/MDTypography";

export default function data(jsonData, deleteJsonData, setEditJsonData) {
  const getRows = () => {
    const elements = [];

    for (let index = 0; index < jsonData.length; index += 1) {
      elements.push({
        taxId: jsonData[index].taxId,
        docType: jsonData[index].docType,
        pais: jsonData[index].pais ? jsonData[index].pais.nombre : "",
        email: jsonData[index].email,
        fechaAccion: jsonData[index].fechaAccion,
        usuario: jsonData[index].usuario,
        action: (
          <MDTypography color="text">
            <Tooltip
              title="Eliminar"
              disableInteractive
              onClick={() => deleteJsonData(jsonData[index].rowKey)}
            >
              <Icon>delete</Icon>
            </Tooltip>{" "}
            /{" "}
            <Tooltip
              title="Editar"
              disableInteractive
              onClick={() => {
                setEditJsonData(jsonData[index]);
              }}
            >
              <Icon>edit</Icon>
            </Tooltip>
          </MDTypography>
        ),
      });
    }

    return elements;
  };

  return {
    columns: [
      { Header: "Tax Id", accessor: "taxId", align: "center" },
      { Header: "Tipo Documento", accessor: "docType", align: "center" },
      { Header: "Pais", accessor: "pais", align: "center" },
      { Header: "Email", accessor: "email", align: "center" },
      { Header: "Fecha Acción", accessor: "fechaAccion", align: "center" },
      { Header: "Usuario", accessor: "usuario", align: "center" },
      { Header: "Acción", accessor: "action", align: "center" },
    ],

    rows: getRows(),
  };
}

// Setting default values for the props of MDBadge
data.defaultProps = {
  jsonData: [],
};

// Typechecking props of the data
data.propTypes = {
  jsonData: PropTypes.objectOf(PropTypes.array),
  deleteJsonData: PropTypes.func.isRequired,
  setEditJsonData: PropTypes.func.isRequired,
};
